import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-reports-video-conf-stats',
  templateUrl: './reports-video-conf-stats.component.html',
  styleUrls: ['./reports-video-conf-stats.component.scss']
})
export class ReportsVideoConfStatsComponent implements OnInit,OnChanges {
  @Input() videoConferSite: any;
  @Input() statsLabel: any;
  @Input() healthNum: any = 90;
  @Input() isIot: any = false;
  @Input() fullBarStatus: number = 0;
  left: number = 0;
  val: number = 90;
  curval: number = 0;
  preval: number = 0;
  reportCircle: any = 'red';
  restore_time_trend:any = 0;
  
  constructor() { }

  ngOnInit() {


  
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.videoConferSite && this.videoConferSite) {
      // console.log('this.videoConferSite: ', this.videoConferSite)
        if (changes.isIot && this.isIot) {
          this.setData();
        }
  
    }


  }


  calculateDifference = (triggerCount, pastTrigger) => {
    
    let total = 0;

    if (triggerCount > pastTrigger) {
      
      let sum = triggerCount - pastTrigger;

      return  '+' + sum.toString()
      
    }

    return total
  }
  
  setData = () => {


    const newData = { ...this.videoConferSite, trigger_counts: this.doSomething(this.videoConferSite.trigger_counts) } 
    
    this.videoConferSite = newData;

    console.log('final: ', this.videoConferSite)


  }



  doSomething = ({ total_trigger_counts,  total_trigger_counts_diff } ) => {
    let newTriggerCounts = {
        display: 0,
        server: 0,
        web: 0,
        accesspoint: 0,
        power: 0,
        camera: 0,
        device: 0,
        cloud: 0,
    }
    
    let newTriggerCountsDiff = {
        display: 0,
        server: 0,
        web: 0,
        accesspoint: 0,
        power: 0,
        camera: 0,
        device: 0,
        cloud: 0,
    }
    


    const objectKeys1 = Object.keys(newTriggerCounts);
    const objectKeys2 = Object.keys(newTriggerCountsDiff);
      
    

    if (total_trigger_counts) {
      
      for (let i = 0; i < total_trigger_counts.length; i++) {
        // if(Object.keys(newTriggerCounts))
        for (let j = 0; j < objectKeys1.length; j++) {

          if (total_trigger_counts[i].iot_type.toLowerCase() == objectKeys1[j])
          newTriggerCounts[objectKeys1[j]] = total_trigger_counts[i].incident_count;
          }
      }

    }
    
    if (total_trigger_counts_diff) {
      
      for (let i = 0; i < total_trigger_counts_diff.length; i++) {
        for (let j = 0; j < objectKeys2.length; j++) {

          if (total_trigger_counts_diff[i].iot_type.toLowerCase() == objectKeys2[j])
          newTriggerCountsDiff[objectKeys2[j]] = total_trigger_counts_diff[i].incident_count;
          }
      }

    }

    return {  newTriggerCounts, newTriggerCountsDiff }

    /*
    check trigger if its not null
    check if the values are complete it should
    should be 7 loops
    */
 
  }

}
