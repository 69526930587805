<!-- ROOM AND USER -->
<div class="iotlite-admin">
    <div class="iotlite-admin__button-container">
        <button class="iotlite-admin__btn" (click)="showPanel(1)">
            Documents
        </button>
        <button class="iotlite-admin__btn" (click)="showPanel(4)">
            Edit Iot Name
        </button>
        <button class="iotlite-admin__btn" (click)="showPanel(2)">
            Rescan Device
        </button>
        <button class="iotlite-admin__btn" (click)="showPanel(3)">
            Delete Device
        </button>
    </div>
    <div class="iotlite-admin__comp-container">
        <app-documents class="admin__components" *ngIf="shouldShowDocuments" [propData]="propData">
        </app-documents>

        <!-- <div *ngIf="showRescanDevice"> -->
        <div *ngIf="showRescanDevice">
            <div class="iotlite-admin__right-column">
                <div *ngIf="showErrMsg" class="iotlite-admin__error-message">
                    <h3>{{errorMsg}}</h3>
                </div>

                <div *ngIf="showSearching" class="iotlite-admin__right-column-item">
                    <ul class="bottom">
                        <li>
                            <h3>Searching</h3>
                        </li>
                        <li>
                            <div class="dot1"></div>
                        </li>
                        <li>
                            <div class="dot2"></div>
                        </li>
                        <li>
                            <div class="dot3"></div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="showMsg30Sec && !showErrMsg">
                    <p style="font-size: larger;">It’s taking longer than expected, but we’ll get there as fast as we
                        can...</p>
                </div>
                <div *ngIf="showErrorMessage">
                    <h3 style="color:red;">{{errorMessage}}</h3>
                </div>
                <!-- <div>Note: Updating will repla</div> -->
                <div *ngIf="showAddDevice" class="iotlite-admin__right-column-item">

                    <div class="iotlite-admin__ports-container">
                        <div class="iotlite-admin__ports-container-left">
                            <div class="iotlite-admin__ports-container-current-ports">
                                <h5 class="iotlite-admin__ports-container-current-ports-header">
                                    Current Ports
                                </h5>

                                <div class="iot-component">
                                    <table class="table">
                                        <thead>
                                            <tr></tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of oldPorts">
                                                <td>
                                                    <div class="circle-stat circle-green"></div>
                                                </td>
                                                <td>
                                                    <div class="iotlite-admin__ports-container-current-ports-value">
                                                        {{ item.port_name}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="iotlite-admin__ports-container-current-ports-value">
                                                        {{ item.port_id}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="iotlite-admin__ports-container-current-ports-value">
                                                        <input type="checkbox" [checked]="item.is_monitored === 1"
                                                            [disabled]="true">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="iotlite-admin__ports-container-left">
                            <div class="iotlite-admin__ports-container-new-ports">
                                <h5>
                                    New Ports
                                </h5>

                                <div class="iot-component iotlite-admin__ports-container-new-ports-controls">
                                    <table class="table">
                                        <thead>
                                            <tr></tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of devicePorts; let indexOfelement=index;">
                                                <td>
                                                    <div class="circle-stat circle-green"></div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <!-- {{ item.service.name}} -->
                                                        <input class="iot-lite-add-device__input form-control"
                                                            [(ngModel)]="item?.service.name"
                                                            (ngModelChange)="onChangePortName($event, indexOfelement)"
                                                            type="text" placeholder="Rename Port" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {{ item.portid}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input type="checkbox"
                                                            [disabled]="iotDeviceDetails&&iotDeviceDetails.pause"
                                                            [checked]="item.is_monitored === 1"
                                                            (change)="onChangePortMonitored($event, indexOfelement)">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="showAddDevice">
                    <div>
                        <h5 class="" [ngClass]="{'iotlite-admin__message': disableUpdatePortButton}">
                            {{showUpdatePortsMessage}}
                        </h5>
                        <h6>Note: Special characters and spaces are not allowed. You can use "_" ex. port_name </h6>

                    </div>
                    <div class="iotlite-admin__btn2">
                        <button [disabled]="disableUpdatePortButton" (click)="updatePorts()">Update Port</button>
                        <button style="background:grey" (click)="resetAll()">Cancel</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="iotlite-admin__right-column-item" *ngIf="showDeleteDevice">
            <div class="form-group">
                <div class="row m-2">
                    <h6 class="margin-left:auto;margin-right:auto;">
                        Are you sure you want to delete this device?
                    </h6>
                </div>
                <div class="row mt-2">
                    <div class="form-group">
                        <button (click)="deleteDevice()" class="iotlite-admin__btn" style="margin-top: 10px;">
                            Delete Device
                        </button>
                    </div>
                    <div class="form-group pl-2">
                        <button (click)="cancelDelete()" class="iotlite-admin__btn_grey" style="margin-top: 10px;">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="iotlite-admin__right-column-item" *ngIf="showRenameDevice">
            <div class="iotlite-admin__edit-name">
                <div class="iotlite-admin__edit-name-inner">
                    <label class="iotlite-admin__edit-name-label">Please enter new IOT name</label>
                    <input type="text" value="" name="rename" id="rename" #rename
                        class="iotlite-admin__edit-name-input">
                </div>
                <button class="iotlite-admin__btn_grey iotlite-admin__rename-btn"
                    (click)="editIotName(rename.value)">{{alreadyEditingInProcess ? "Submitting..." : "Submit"
                    }}</button>
            </div>
        </div>
    </div>
</div>