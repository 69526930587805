<div class="reporting-container">
  <!-- <p>reporting-container works!</p> -->
  <div class="reporting-container__btn-container">
    <div>
      <button class="btn btn-primary" (click)="chooseOption('inventory')">
        Inventory
      </button>
    </div>

    <div>
      <button class="btn btn-primary" (click)="chooseOption('usage')">
        Usage
      </button>
    </div>

    <div>
      <button class="btn btn-primary" (click)="chooseOption('incidents')">
        Incidents
      </button>
    </div>
  </div>

  <div class="report-content">
    <ng-container [ngSwitch]="selectedReport">
      <ng-container *ngSwitchCase="'inventory'">
        <!-- Replace with actual component -->
        <!-- <app-inventory-reporting></app-inventory-reporting> -->
        <app-inventory-reporting [popUpState]="popUpState"></app-inventory-reporting>
      </ng-container>

      <ng-container *ngSwitchCase="'usage'">
        <p>Usage reporting UI coming soon.</p>
      </ng-container>

      <ng-container *ngSwitchCase="'incidents'">
        <p>Incidents reporting UI coming soon.</p>
      </ng-container>
    </ng-container>
  </div>

</div>