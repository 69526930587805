import { Component, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pexip-info',
  templateUrl: './pexip-info.component.html',
  styleUrls: ['./pexip-info.component.scss']
})
export class PexipInfoComponent implements OnInit {
  faIcon = faUser;
  constructor() { }

  ngOnInit(): void {
  }

}
