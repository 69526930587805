<div class="collector-alert-panel">
  <div class="collector-alert-panel__container">
    <div class="collector-alert-panel__header">
      <div class="collector-alert-panel__icon">
        <fa-icon [icon]="isAccess ? faShieldHalved : faShield" [class.text-success]="isAccess"
          [class.text-muted]="!isAccess" size="lg">
        </fa-icon>
      </div>
      <h5>Remote Access Control</h5>
    </div>

    <div class="collector-alert-panel__content">
      <div class="collector-alert-panel__item">
        <div class="collector-alert-panel__label">
          <h6>Remote Access</h6>
          <span class="collector-alert-panel__status" [ngClass]="{
            'collector-alert-panel__status--enabled': isAccess,
            'collector-alert-panel__status--disabled': !isAccess
          }">
            {{ isAccess ? 'Access Enabled' : 'Access Disabled' }}
          </span>
        </div>
        <label class="collector-alert-panel__switch">
          <input type="checkbox" [(ngModel)]="isAccess" (ngModelChange)="remoteAccess()" [disabled]="showDownloading">
          <span class="collector-alert-panel__slider"></span>
        </label>
      </div>
      <button class="collector-alert-panel__view-btn" (click)="togglePopup()">
        <fa-icon [icon]="faEye"></fa-icon>
        View History
      </button>
    </div>
  </div>
</div>

<!-- Popup -->
<div class="collector-alert-panel__popup" *ngIf="showPopup">
  <div class="collector-alert-panel__popup-content">
    <div class="collector-alert-panel__popup-header">
      <h6>Access History</h6>
      <button class="collector-alert-panel__popup-close" (click)="togglePopup()">×</button>
    </div>
    <div class="collector-alert-panel__popup-body">
      <table class="collector-alert-panel__table">
        <thead>
          <tr>
            <th>Tunnel Name</th>
            <th>User Ip</th>
            <th>Proto</th>
            <th>Region</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let log of logList">
            <td>
              {{log.tunnel_name}}
            </td>
            <td>
              {{log.user_ip}}
            </td>
            <td>
              {{log.proto}}
            </td>
            <td>
              {{log.region}}
            </td>
            <td>
              <span class="collector-alert-panel__status-badge">{{log.active==1 ? 'RUNNING' :'STOPPED'}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="collector-logging">
  <div class="text-center">
    <ul class="bottom">
      <li [innerHTML]="snapshotMessage" class="collector-logging__message"></li>
      <ng-container *ngIf="showDownloading">
        <li>
          <div class="dot1"></div>
        </li>
        <li>
          <div class="dot2"></div>
        </li>
        <li>
          <div class="dot3"></div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>