import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'

// import { Component, Inject, EventEmitter, AfterViewInit } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { environment } from '../../../../environments/environment'
import { GetDesktopPluginService } from '../../../services/get-desktop-plugin.service'
import { VsMenuDesktopClientService } from '../../../services/vs-menu-desktop-client.service'

@Component({
	selector: 'app-teams-room-link-desktop-plugin',
	templateUrl: './teams-room-link-desktop-plugin.component.html',
	styleUrls: ['./teams-room-link-desktop-plugin.component.scss'],
})
export class TeamsRoomLinkDesktopPluginComponent implements OnInit, AfterViewInit {
	modalList: any
	filteredData: any
	showLinkClientUser: boolean = false
	clientToLink: any = ''
	hostName: any = ''
	desktopPluginId: any
	zoomUserId: any
	isLoading: any = false
	userActive: any
	sendPopupType: EventEmitter<any>
	searchKey: any
	btnLoader: string = environment.config.dynamicImage.loader
	serviceType: any = 'zoom'
	action: string
	eventEmitterDesktop: any
	totalTeamsCount: any
	totalZoomCount: any
	numbers: any
	pluginEnabled: any
	setTimer: any
	subscription = new Subscription()
	dPlugin = environment.config.dynamicText.dPlugin
	doneAction: boolean = false
	actionResultMessage: String = ''
	getRoomsList = () => {}

	constructor(
		private desktopPluginService: GetDesktopPluginService,
		private Auth: AuthService,
		public vsMenuDesktopClientService: VsMenuDesktopClientService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<TeamsRoomLinkDesktopPluginComponent>
	) {
		if (data) {
			console.log('Host name IP Address and Mac Address:', data)
			console.log("One host's IP address and mac address:", data.config[0].ip_address, data.config[0].mac_address)
			const {
				config,
				user_plugin_host_name,
				user: {
					plugin_active,
					firstname,
					lastname,
					plugin_id,
					host_name,
					service_type,
					zoom_user_id,
					host_info,
					active,
				},
				sendPopupType,
				getRoomsList,
			} = data
			this.pluginEnabled = plugin_active === 1
			this.clientToLink = firstname + lastname
			this.hostName = user_plugin_host_name
			this.desktopPluginId = plugin_id
			this.zoomUserId = zoom_user_id
			this.sendPopupType = sendPopupType
			this.serviceType = service_type
			this.modalList = config.map(e => {
				return {
					...e,
					host_name: this.getHostName(e.host_info) || '--',
					ip_address_and_mac_address: this.get_ipAddress_and_macAddress({
						ip_address: e.ip_address,
						mac_address: e.mac_address,
					}),
				}
			})
			this.filteredData = [...this.modalList]

			this.getRoomsList = getRoomsList

			// console.log('data: ', data)
		}
	}

	ngOnInit() {}

	ngAfterViewInit() {}

	ngOnDestroy(): void {
		this.clearTimeout()
	}

	clearTimeout = () => {
		clearTimeout(this.setTimer)
	}

	unlinkDesktopPlugin = () => {
		this.isLoading = true
		this.Auth.unlinkPluginToRoom(this.desktopPluginId, this.serviceType).subscribe((data: any) => {
			if (data.response[0].status === 'Ok') {
				this.actionResultMessage = 'Successful in ' + this.dPlugin + ' unlinking, you may close this dialog.'
			} else if (data.response[0].status === 'Error') {
				this.actionResultMessage =
					this.dPlugin +
					' unlinking has failed. Either ' +
					this.dPlugin +
					' is offline or not active, you may close this dialog.'
			}
			this.doneAction = true
			this.setTimer = setTimeout(() => {
				this.sendPopupType.emit('teamsRoomList')
				this.closeDialog()
			}, 10000)
			this.getRoomsList()
		})
	}

	linkDesktopPlugin = () => {
		this.isLoading = true
		this.Auth.linkPluginToRoom(this.desktopPluginId, this.zoomUserId).subscribe((data: any) => {
			if (data.response[0].status === 'Ok') {
				this.actionResultMessage = 'Successful in ' + this.dPlugin + ' linking, you may close this dialog.'
			} else if (data.response[0].status === 'Error') {
				this.actionResultMessage =
					this.dPlugin +
					' linking has failed. Either ' +
					this.dPlugin +
					' is offline or not active, you may close this dialog.'
			}
			this.doneAction = true
			this.setTimer = setTimeout(() => {
				this.sendPopupType.emit('teamsRoomList')
				this.closeDialog()
			}, 10000)
			this.getRoomsList()
		})
	}

	getPluginTypeImage = type => {
		return type === 'zoom' ? './assets/img/zoom_logo.png' : './assets/img/logo-microsoft-teams-2.png'
	}

	openLinkClient = ({ host_name, plugin_id }) => {
		this.hostName = host_name
		this.desktopPluginId = plugin_id
		this.showLinkClientUser = true
	}

	closeLinkClient = () => {
		this.showLinkClientUser = false
		this.dialogRef.close(true)
	}

	closeDialog = () => {
		this.isLoading = false
		this.dialogRef.close(true)
	}

	getModalTitle = () => {
		if (this.pluginEnabled) {
			return 'UN-LINK ROOM TO ' + this.dPlugin.toUpperCase()
		}
		if (!this.pluginEnabled) {
			return 'LINK ROOM TO ' + this.dPlugin.toUpperCase()
		}
		return 'title'
	}

	getHostName = params => {
		if (params) {
			const hostname = JSON.parse(params)
			return hostname[0].hostname
		}
		return '--'
	}

	// Function to filter based on the searchKey
	filterData() {
		const searchTerm = this.searchKey.toLowerCase()

		this.filteredData = this.modalList.filter(item => {
			// Ensure that IP address and MAC address are always strings for comparison
			const ipAddress = item.ip_address || '--' // Default to '--' if IP address is missing
			const macAddress = item.mac_address || '--' // Default to '--' if MAC address is missing

			// Search across all three fields (host_name, ip_address, mac_address)
			return (
				(item.host_name && item.host_name.toLowerCase().includes(searchTerm)) ||
				ipAddress.toLowerCase().includes(searchTerm) ||
				macAddress.toLowerCase().includes(searchTerm)
			)
		})
	}

	get_ipAddress_and_macAddress(params): string {
		try {
			if (params) {
				const { ip_address, mac_address } = params

				// Check if both ip_address and mac_address are present
				if (ip_address && mac_address) {
					return `IP address: ${ip_address}\nMAC address: ${mac_address}`
				}

				if (!ip_address && !mac_address) {
					return 'N/A'
				}

				// Return either one if the other is null
				if (ip_address == null) return `MAC address: ${mac_address}`
				if (mac_address == null) return `IP address: ${ip_address}`

				// Return params object as a fallback
				return params
			}
		} catch (err) {
			throw err
		}
	}

	getUsersToLinkName = ({ firstname }) => {
		return firstname
	}

	onNoClick(): void {
		this.dialogRef.close()
	}

	reduceEmailLength = string => {
		return string
	}
}
