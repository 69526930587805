import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GetTeamsService } from '../../services/get-teams.service';
import { Subscription } from 'rxjs';
import { RefreshApiService } from '../../services/refresh-api.service';
import { AuthService } from '../../services/auth.service';
import { GetCloudService } from 'src/app/services/get-cloud.service';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-teams-main-menu',
  templateUrl: './teams-main-menu.component.html',
  styleUrls: ['./teams-main-menu.component.scss'],
})
export class TeamsMainMenuComponent implements OnInit {
  @Input() desktopClientDownloadLink: any;
  @ViewChild('yourElement', { static: false }) yourElement: ElementRef;
  //users
  //rooms
  //service
  tableSelected: any = 'service';
  componentName: any = 'teams';
  teamsUserList: any = [];
  siteSummary: any = [];
  dataError: any;
  refreshData: any;
  filterListner: Subscription;
  hostToLink: any;
  totalPageLimit = 500;
  pageToLoad = 0
  showLoader: any = false;
  searchKey: any = '';
  totalUserCount: any = 0;
  teamsRoomList: any;
  spaceStatus: boolean = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  showServiceLoader: boolean = true;
  constructor(
    private getTeams: GetTeamsService,
    private refreshApiService: RefreshApiService,
    private Auth: AuthService,
    private desktopPlugin: GetDesktopPluginService,
    private getCloudService: GetCloudService,
  ) {

  }

  ngOnInit() {
    this.totalUserCount = this.totalPageLimit;
    this.getUserList();
    this.getListCollector();
    this.getDesktopPluginList();
    this.getRoomsList();
    this.getTeamsSpaceStatus();
  }

  ngAfterViewInit(): void {
    //read about pipes, view child, rxjs, elemetref
    fromEvent(this.yourElement.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(data => this.notTypingStuff());

  }

  notTypingStuff = () => {
    // console.log('hey')
    if (this.tableSelected == 'users') {
      // console.log('load users')
      this.getUserList(500, 0, this.searchKey)

    }

    if (this.tableSelected == 'rooms') {
      // console.log('load rooms')
      this.getRoomsList(2000, 0, this.searchKey)
    }
  }

  radioChange(event) {
    this.resetDefault();
    this.searchKey = ''
    if(event === 'users') this.getUserList();
    else if (event === 'rooms') this.getRoomsList();

  }
  

  authorizeTeams = () => {
    this.getTeams.authorizeTeams().subscribe(
      (data) => {
        // // console.log('authorizeTeams: ', data);
        // // console.log;
      },
      (err) => { }
    );
  };

  getListCollector = async () => {
    this.Auth.getListCollector().subscribe((data: any) => {
      const { site_summary } = data.response;
      this.siteSummary = site_summary[0] || [];
    });
  };

  sendPopupType = (emiterEvent) => {
    if (emiterEvent === 'loadMoreUsers') {
      this.loadMoreUsers();
    }
    if (emiterEvent === 'loadMoreRooms') {
      this.getRoomsList();
    }
    if (emiterEvent === 'desktopClientList') {
      //for now when linking it will all reset
      this.resetDefault()
      this.getUserList();
    }
    if (emiterEvent === 'teamsRoomList') {
      this.getRoomsList();
    }
    if (emiterEvent === 'getListCollector') {
      this.getListCollector();
    }
  };

  resetDefault = () => {
    this.totalPageLimit = 500;
    this.totalUserCount = this.totalPageLimit;
    this.teamsUserList = []
    this.pageToLoad = 0
  }

  getDesktopPluginList = () => {
    this.desktopPlugin.getDesktopPluginList().subscribe((data: any) => {
      const newArr = data;
      this.hostToLink = newArr.filter((e) => !e.is_link_teams);
    });
  };

  getTeamsSpaceStatus = () => {
    this.getTeams.getTeamsSpaceStatus().subscribe(
      (data: any) => {
        if (data.is_teams_space) {
          this.spaceStatus = true;
        }
        this.showServiceLoader = false;
      },
      (err) => { }
    );
  };

  removeSpaceAndToLowerSearch = (key) => {
    if (this.tableSelected == 'users') {
      return key !== '' ? key.replace(/\s+/g, '').toLowerCase() : key;
    }
    return key !== '' ? key.toLowerCase() : key;

  }

  getUserList = (endIndex = 500, startIndex = 0, searchKey = '') => {
    this.showLoader = true
    const postData = {
      list_limit: this.checkIfSearchKeyIsEmpty() ? endIndex : this.siteSummary.total_teams_users,
      off_set: this.checkIfSearchKeyIsEmpty() ? startIndex : 0,
      search_key: this.removeSpaceAndToLowerSearch(searchKey)
    }
    this.getTeams.getUserList(postData).subscribe(
      (data: any) => {
        let newArr = data.response;
        // // console.log('getUserList: ', newArr)

        if (this.teamsUserList.length == 0) {
          this.teamsUserList = newArr;
        } else {
          this.teamsUserList = this.teamsUserList.concat(newArr);
        }

        if (!this.checkIfSearchKeyIsEmpty()) {
          this.resetDefault();
          this.teamsUserList = newArr;
        }
        this.showLoader = false
      },

      (err) => { }
    );
  };

  getRoomsList = (endIndex = 2000, startIndex = 0, searchKey = '') => {
    // this.showLoader = true;
    this.showLoader = true
    const postData = {
      list_limit: this.checkIfSearchKeyIsEmpty() ? endIndex : this.siteSummary.total_teams_users,
      off_set: this.checkIfSearchKeyIsEmpty() ? startIndex : 0,
      search_key: this.removeSpaceAndToLowerSearch(searchKey),
      service_type: 'teams'
    }

    this.getCloudService.fetchApiCloudAllRooms(postData).subscribe((data) => {
      let newArr = data.response;
      this.teamsRoomList = newArr;

      if (!this.checkIfSearchKeyIsEmpty()) {
        this.resetDefault();
        this.teamsRoomList = newArr;
      }
      // console.log('gerRoomslist: ', this.teamsRoomList)
      this.showLoader = false
    });
  };

  triggerGetRoomsList = () => {
    this.getRoomsList();
  }

  checkForNullObject = (obj) => {
    let myObj = {};
    for (var prop in obj) myObj[prop] = this.checkIfNull(obj[prop], prop);
    return myObj;
  };

  checkIfNull = (obj, prop) => {
    return obj;
  };

  loadMoreUsers = () => {

    this.pageToLoad = this.pageToLoad + this.totalPageLimit;
    this.totalUserCount = this.totalUserCount + this.totalPageLimit
    this.getUserList(this.totalPageLimit, this.pageToLoad);
  }

  checkIfSearchKeyIsEmpty = () => {
    if (this.searchKey === '') {
      return true;
    }
    return false;
  }


}
