import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-device-plugin-status',
  templateUrl: './device-plugin-status.component.html',
  styleUrls: ['./device-plugin-status.component.scss'],
})
export class DevicePluginStatusComponent implements OnChanges, OnInit {
  @Input() popupState: any;
  @Input() hostInfo: any;
  @Input() cpuInfo: any;
  @Input() networkAdapters: any;
  @Input() diskInfo: any[];
  @Input() memoInfo: any;
  @Input() lastConnect: string;
  @Input() version: string;
  @Input() pluginId: number;
  @Input() pluginEnabled: boolean;
  @Input() pluginStatus: any;
  @Input() isRoom: boolean;
  @Input() serviceType: any;

  hostName: String = '';
  lConnect: String = '';
  networkStatus: String = '';
  cpuTotalUsage: any = 0;
  cpuUsagePlugin: any = 0;
  memoryTotal: number = 0;
  memoryFree: number = 0;
  memoryTotalUsage: number = 0;
  memoryTotalUsagePercentage: any = 0;
  memoryPlugintUsage: number = 0;
  memoryPluginUsagePercentage: any = 0;

  showRebootPC: boolean;
  showRebootZoom: boolean;
  showDevicePluginDynamicContainer: boolean;

  accessLevel: any;
  isSuperAdmin: boolean;
  hasAccess: boolean;

  dPlugin: String = environment.config.dynamicText.dPlugin;

  constructor(
    private getPluginService: GetPluginService,
    private Auth: AuthService,
    private desktopPlugin: GetDesktopPluginService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.popupState.currentValue == 'open') {

      if (this.hostInfo && changes.hostInfo) {
        this.setData()
        // console.log('hostInfo: ', this.hostInfo)
      }
      this.lConnect = new Date(this.lastConnect).toLocaleString();
    }
  }

  setData = () => {
    const { hostname } = this.hostInfo

    this.hostName = hostname;
    if (this.hostInfo.wifi_ssid == "" && this.hostInfo.wifi_signal_strength == "") {
      this.networkStatus = "Ethernet";
    } else {
      this.networkStatus = "WiFi";
    }

    if (this.cpuInfo) {
      let cpuInfo = [];
      if (this.serviceType === 'teams') {
        this.cpuInfo = this.cpuInfo[0]
      }
      this.cpuTotalUsage = this.cpuInfo.cpu_total ? this.cpuInfo.cpu_total : this.cpuTotalUsage;
      this.cpuUsagePlugin = this.cpuInfo.cpu_v1plugin >= 100 ? 100 : this.cpuInfo.cpu_v1plugin
    }

    if (this.memoInfo) {
      this.memoryTotal = this.memoInfo.mem_total;
      this.memoryFree = this.memoInfo.mem_free;
      this.memoryTotalUsage = this.memoryTotal - this.memoryFree;
      this.memoryTotalUsagePercentage = Math.ceil((this.memoryTotalUsage / this.memoryTotal) * 100);
      this.memoryPlugintUsage = this.memoInfo.mem_v1plugin;
      this.memoryPluginUsagePercentage = Math.ceil((this.memoInfo.mem_v1plugin / this.memoryTotal) * 100);

    }
  }

  convertBytesToGb(memoryBytes: number): number {
    return _.round(memoryBytes / 1073741824, 2);
  }

  displayMemmory(memoryBytes: number): string {
    if (memoryBytes >= 1000000000) {
      const gb = _.round(memoryBytes / 1073741824, 2);
      return gb + ' GB';
    } else {
      const mb = _.round(memoryBytes / 1000000, 2);
      return mb + ' MB';
    }
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperAdmin = this.Auth.accessLevel === 'SUPERADMIN';
    console.log(this.accessLevel);
    this.hasAccess = this.getHasAccess();
    this.setRebootButton(false);
    this.setShowDevicePluginDynamicContainer(true);
  }

  showRebootPCFunc = (bool) => {
    this.setRebootButton(!bool);
    this.showRebootPC = bool;
  };

  showRebootZoomFunc = (bool) => {
    this.setRebootButton(!bool);
    this.showRebootZoom = bool;
  };

  setRebootButton = (bool) => {
    this.showRebootPC = bool;
    this.showRebootZoom = bool;
    this.setShowDevicePluginDynamicContainer(bool);
  };

  setShowDevicePluginDynamicContainer = (bool) => {
    this.showDevicePluginDynamicContainer = bool;
    // this.showRebootPCFunc(!bool)
  };

  rebootPc = () => {
    this.showRebootPC = false;
    // if (this.isRoom) {
    const actionParams = {};
    const actionName = 'Reboot';
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        this.setShowDevicePluginDynamicContainer(true);
      });
    // } else {
    //   const actionParams = null;
    //   const actionName = 'Reboot';
    //   this.desktopPlugin
    //     .createAction(this.pluginId, actionParams, actionName)
    //     .subscribe((data) => {});
    // }
  };

  restartZoom = () => {
    this.showRebootZoom = false;
    const actionParams = {};
    const actionName = 'RestartZoomRoomsApp';
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        this.setShowDevicePluginDynamicContainer(true);
      });
  };

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER' || this.isSuperAdmin) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };


  getNetworkImg = (img) => {
    return img == 'WiFi' ? './assets/img/vs_img/wifi_gray.png' : './assets/img/icon_ethernet_02.png';
  }
  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
}
