import { NgModule } from '@angular/core'
import { Route, RouterModule, Routes } from '@angular/router'

import { environment } from '../environments/environment'
import { AddPhoneNumberComponent } from './add-phone-number/add-phone-number.component'
import { AuthGuard } from './auth.guard'
import { CollectorListContainerComponent } from './collector/collector-list-container/collector-list-container.component'
//Collector
import { CollectorPanelComponent } from './collector/collector-panel/collector-panel.component'
import { AudioMainComponent } from './direct-access/audio/audio-main/audio-main.component'
import { CloudMainComponent } from './direct-access/cloud/cloud-main/cloud-main.component'
import { VideoMainComponent } from './direct-access/video/video-main/video-main.component'
// Document Center
import { ViewDocumentComponent } from './document-center/view-document/view-document.component'
import { HomepageComponent } from './homepage/homepage.component'
import { IotAllAgentsComponent } from './iot/iot-all-agents/iot-all-agents.component'
import { IotAllDevicesComponent } from './iot/iot-all-devices/iot-all-devices.component'
// IOT SPACES 
import { IotAllSpacePanelComponent } from './iot/iot-all-space-panel/iot-all-space-panel.component'
/* IOT */
import { IotDevicePanelComponent } from './iot/iot-device-panel/iot-device-panel.component'
import { IotLiteDevicePanelComponent } from './iot/iot-lite-device-panel/iot-lite-device-panel.component'
import { IotSpacePanelComponent } from './iot/iot-space-panel/iot-space-panel.component'
import { AudioAllDevicesPanelComponent } from './panels/audio/audio-all-devices-panel/audio-all-devices-panel.component'
import { AudioAllsitesPanelComponent } from './panels/audio/audio-allsites-panel/audio-allsites-panel.component'
import { AudioDevicePanelComponent } from './panels/audio/audio-device-panel/audio-device-panel.component'
import { AudioSitePanelComponent } from './panels/audio/audio-site-panel/audio-site-panel.component'
import { CloudAllRoomsPanelComponent } from './panels/cloud/cloud-all-rooms-panel/cloud-all-rooms-panel.component'
import { CloudAllUsersPanelComponent } from './panels/cloud/cloud-all-users-panel/cloud-all-users-panel.component'
import { CloudRoomPanelComponent } from './panels/cloud/cloud-room-panel/cloud-room-panel.component'
import { CloudUserPanelComponent } from './panels/cloud/cloud-user-panel/cloud-user-panel.component'
import { ComponentsAllComponent } from './panels/components-all/components-all.component'
import { AdminComponent } from './panels/login/admin/admin.component'
import { ForgotPasswordComponent } from './panels/login/forgot-password/forgot-password.component'
import { InstantLoginComponent } from './panels/login/instant-login/instant-login.component'
import { LoginComponent } from './panels/login/login/login.component'
import { LogoutComponent } from './panels/login/logout/logout.component'
import { NewHomeLoginComponent } from './panels/login/new-home-login/new-home-login.component'
import { NewUserRegistrationComponent } from './panels/login/newuserregistration/newuserregistration.component'
import { RegistrationConfirmComponent } from './panels/login/registration-confirm/registration-confirm.component'
import { RegistrationComponent } from './panels/login/registration/registration.component'
import { ResetPasswordComponent } from './panels/login/reset-password/reset-password.component'
import { TokenLoginAdminComponent } from './panels/login/token-login-admin/token-login-admin.component'
import { TokenLoginDirectAccessComponent } from './panels/login/token-login-direct-access/token-login-direct-access.component'
import { TokenLoginComponent } from './panels/login/token-login/token-login.component'
import { TableStylesComponent } from './panels/table-styles/table-styles.component'
import { VideoAlldevicesPanelComponent } from './panels/video/video-alldevices-panel/video-alldevices-panel.component'
import { VideoAllsitesPanelComponent } from './panels/video/video-allsites-panel/video-allsites-panel.component'
import { VideoDevicePanelComponent } from './panels/video/video-device-panel/video-device-panel.component'
import { VideoSitePanelComponent } from './panels/video/video-site-panel/video-site-panel.component'
import { TeamsRoomPanelComponent } from './teams/teams-room-panel/teams-room-panel.component'
// Teams
import { TeamsUserPanelComponent } from './teams/teams-user-panel/teams-user-panel.component'
//Two factor Auth
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component'
import { PexipDnaComponent } from './pexip/pexip-dna/pexip-dna.component'

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'two-factor-auth', component: TwoFactorAuthComponent },
	{ path: 'add-phone-number', component: AddPhoneNumberComponent },
	{ path: 'registration', component: RegistrationComponent },
	{ path: 'forgotpassword', component: ForgotPasswordComponent },
	{ path: 'admin', component: AdminComponent },
	{ path: 'newuserregistration', component: LoginComponent },
	{ path: 'newuserregistration/:id', component: NewUserRegistrationComponent },
	{ path: 'activateRegistration', component: LoginComponent },
	{ path: 'activateRegistration/:id', component: RegistrationConfirmComponent },
	{ path: 'resetpassword/:id', component: ResetPasswordComponent },
	{ path: 'resetpassword', component: LoginComponent },
	{ path: 'tokenlogin/:id', component: TokenLoginComponent },
	{ path: 'instantLogin/:id', component: InstantLoginComponent },
	{ path: 'tokenloginadmin/:id/:loginType', component: TokenLoginAdminComponent },
	{ path: 'home', component: HomepageComponent },
	{
		path: '',
		component: HomepageComponent,
		canActivateChild: [AuthGuard],
		children: [
			// Video Routes
			{ path: 'video/allsites', component: VideoAllsitesPanelComponent },
			{ path: 'video/impactedsites', component: VideoAllsitesPanelComponent },
			{ path: 'video/site/:id', component: VideoSitePanelComponent },
			{ path: 'video/site/:id/videodevice/:id', component: VideoDevicePanelComponent },
			{ path: 'video/device/:id', component: VideoDevicePanelComponent },
			{ path: 'video/alldevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/healthydevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/impacteddevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/warningdevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/pauseddevices', component: VideoAlldevicesPanelComponent },

			// Audio Routes
			{ path: 'audio/allsites', component: AudioAllsitesPanelComponent },
			{ path: 'audio/impactedsites', component: AudioAllsitesPanelComponent },
			{ path: 'audio/site/:id', component: AudioSitePanelComponent },
			{ path: 'audio/site/:id/audiodevice/:id', component: AudioDevicePanelComponent },
			{ path: 'audio/device/:id', component: AudioDevicePanelComponent },
			{ path: 'audio/alldevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/healthydevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/impacteddevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/warningdevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/pauseddevices', component: AudioAllDevicesPanelComponent },

			// Cloud Routes
			{ path: 'cloud/allrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/impactedvipuser', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/impacteduser', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/livemeetings', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/vipusers', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/userswithdesktopplugin', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/room/:id', component: CloudRoomPanelComponent },
			{ path: 'cloud/allusers', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/user/:id', component: CloudUserPanelComponent },
			{ path: 'cloud/activeusers', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/healthyrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/warningrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/redrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/pausedrooms', component: CloudAllRoomsPanelComponent },
			// { path: 'components/all', component: ComponentsAllComponent },
			// { path: 'tablestyles/all', component: TableStylesComponent },

			// IoT Routes
			{ path: 'iot/allagents', component: IotAllAgentsComponent },
			{ path: 'iot/impactedagents', component: IotAllAgentsComponent },
			{ path: 'iot/device/:id', component: IotDevicePanelComponent },
			{ path: 'iot/lite/device/:id', component: IotLiteDevicePanelComponent },
			{ path: 'iot/alldevices', component: IotAllDevicesComponent },
			{ path: 'iot/onlinedevices', component: IotAllDevicesComponent },
			{ path: 'iot/downdevices', component: IotAllDevicesComponent },
			{ path: 'iot/offlinedevices', component: IotAllDevicesComponent },
			{ path: 'iot/impacteddevices', component: IotAllDevicesComponent },
			{ path: 'iot/pauseddevices', component: IotAllDevicesComponent },
			{ path: 'iot/agentdevices/:agentId/:agentName', component: IotAllDevicesComponent },
			
			// IoT Spaces 
			{ path: 'iot/allspaces', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/spaces/:id', component: IotAllSpacePanelComponent },
			{ path: 'iot/healthy', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/warning', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/impacted', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/pause', component: IotAllSpacePanelComponent }, 

			{ path: 'collector/site/:id', component: CollectorPanelComponent },
			{ path: 'collectors/:state', component: CollectorListContainerComponent },

			// Teams User
			{ path: 'teams/user/:id', component: TeamsUserPanelComponent },

			//Teams Room
			{ path: 'teams/room/:id', component: TeamsRoomPanelComponent },
			
		
			
		],
	},
	{ path: 'direct-access/video/:id', component: VideoMainComponent },
	{ path: 'direct-access/audio/:id', component: AudioMainComponent },
	{ path: 'direct-access/zoom/:id', component: CloudMainComponent },
	{ path: 'direct-access-login/:token/:type/:id', component: TokenLoginDirectAccessComponent },
	{ path: 'document-center-view/:id/:type', component: ViewDocumentComponent },
	{ path: 'logout', component: LogoutComponent },
]

//temporary fix
export const routes2: Routes = [
	{
		path: '',
		component: LoginComponent,
		children: [
			{ path: 'registration', component: RegistrationComponent },
			{ path: 'forgotpassword', component: ForgotPasswordComponent },
			{ path: 'newuserregistration', component: LoginComponent },
			{ path: 'newuserregistration/:id', component: NewUserRegistrationComponent },
			{ path: 'login', component: NewHomeLoginComponent },
			{ path: 'resetpassword/:id', component: ResetPasswordComponent },
			{ path: 'resetpassword', component: LoginComponent },
			{ path: 'activateRegistration', component: LoginComponent },
			{ path: 'activateRegistration/:id', component: RegistrationConfirmComponent },
			// { path: '**', component: LoginComponent },
		],
	},
	{ path: 'two-factor-auth', component: TwoFactorAuthComponent },
	{ path: 'add-phone-number', component: AddPhoneNumberComponent },
	{ path: 'admin', component: AdminComponent },
	{ path: 'tokenlogin/:id', component: TokenLoginComponent },
	{ path: 'instantLogin/:id', component: InstantLoginComponent },
	{ path: 'tokenloginadmin/:id/:loginType', component: TokenLoginAdminComponent },
	{ path: 'home', component: HomepageComponent },
	{
		path: '',
		component: HomepageComponent,
		canActivateChild: [AuthGuard],
		children: [
			// Video Routes
			{ path: 'video/allsites', component: VideoAllsitesPanelComponent },
			{ path: 'video/impactedsites', component: VideoAllsitesPanelComponent },
			{ path: 'video/site/:id', component: VideoSitePanelComponent },
			{ path: 'video/site/:id/videodevice/:id', component: VideoDevicePanelComponent },
			{ path: 'video/device/:id', component: VideoDevicePanelComponent },
			{ path: 'video/alldevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/healthydevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/impacteddevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/warningdevices', component: VideoAlldevicesPanelComponent },
			{ path: 'video/pauseddevices', component: VideoAlldevicesPanelComponent },

			// Audio Routes
			{ path: 'audio/allsites', component: AudioAllsitesPanelComponent },
			{ path: 'audio/impactedsites', component: AudioAllsitesPanelComponent },
			{ path: 'audio/site/:id', component: AudioSitePanelComponent },
			{ path: 'audio/site/:id/audiodevice/:id', component: AudioDevicePanelComponent },
			{ path: 'audio/device/:id', component: AudioDevicePanelComponent },
			{ path: 'audio/alldevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/healthydevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/impacteddevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/warningdevices', component: AudioAllDevicesPanelComponent },
			{ path: 'audio/pauseddevices', component: AudioAllDevicesPanelComponent },

			// Cloud Routes
			{ path: 'cloud/allrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/impactedvipuser', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/impacteduser', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/livemeetings', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/vipusers', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/userswithdesktopplugin', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/room/:id', component: CloudRoomPanelComponent },
			{ path: 'cloud/allusers', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/user/:id', component: CloudUserPanelComponent },
			{ path: 'cloud/activeusers', component: CloudAllUsersPanelComponent },
			{ path: 'cloud/healthyrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/warningrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/redrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'cloud/pausedrooms', component: CloudAllRoomsPanelComponent },
			{ path: 'components/all', component: ComponentsAllComponent },
			{ path: 'tablestyles/all', component: TableStylesComponent },

			// IoT Routes
			{ path: 'iot/allagents', component: IotAllAgentsComponent },
			{ path: 'iot/impactedagents', component: IotAllAgentsComponent },
			{ path: 'iot/device/:id', component: IotDevicePanelComponent },
			{ path: 'iot/lite/device/:id', component: IotLiteDevicePanelComponent },
			{ path: 'iot/allsites', component: IotAllDevicesComponent }, //new
			{ path: 'iot/impactedsites', component: IotAllDevicesComponent }, //new
			{ path: 'iot/alldevices', component: IotAllDevicesComponent },
			{ path: 'iot/onlinedevices', component: IotAllDevicesComponent },
			{ path: 'iot/impacteddevices', component: IotAllDevicesComponent },
			{ path: 'iot/downdevices', component: IotAllDevicesComponent },
			{ path: 'iot/offlinedevices', component: IotAllDevicesComponent },
			{ path: 'iot/pauseddevices', component: IotAllDevicesComponent },
			{ path: 'iot/agentdevices/:agentId/:agentName', component: IotAllDevicesComponent },
						// IoT Spaces 
			// IoT Spaces 
			{ path: 'iot/allspaces', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/spaces/:id', component: IotAllSpacePanelComponent },
			{ path: 'iot/healthy', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/warning', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/impacted', component: IotAllSpacePanelComponent }, 
			{ path: 'iot/pause', component: IotAllSpacePanelComponent }, 

			// Teams
			{ path: 'teams/user/:id', component: TeamsUserPanelComponent },
			{ path: 'teams/room/:id', component: TeamsRoomPanelComponent },

			// Collector
			{ path: 'collector/site/:id', component: CollectorPanelComponent },
			{ path: 'collectors/:state', component: CollectorListContainerComponent },

			// Pexip

			{ path: 'pexip/:quadrant', component: PexipDnaComponent },
			
			
			
			// { path: 'teams/room/:id', component: TeamsRoomPanelComponent },
		],
	},
	{ path: 'direct-access/video/:id', component: VideoMainComponent },
	{ path: 'direct-access/audio/:id', component: AudioMainComponent },
	{ path: 'direct-access/zoom/:id', component: CloudMainComponent },
	{
		path: 'direct-access-login/:token/:type/:id',
		component: TokenLoginDirectAccessComponent,
	},
	{ path: 'document-center-view/:id/:type', component: ViewDocumentComponent },
	{ path: 'logout', component: LogoutComponent },
]

const deepCopy = [...routes2]
const checkEnv =
	environment.config.title === 'VisibilityOne' ||
	environment.config.title === 'vCare' ||
	environment.config.title === 'Vision Point' ||
	environment.config.title === 'Videlio' ||
	environment.config.title === 'DEKOM'
const routes3 = [...deepCopy.map(toRoute)]

@NgModule({
	// imports: [RouterModule.forRoot(routes)],
	imports: [RouterModule.forRoot(checkEnv ? routes2 : routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

export function toRoute(params): Route {
	return params
}
