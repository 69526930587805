import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthV2Service {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  server_url: string = environment.serverUrl;
  constructor(
    private _keycloakService: KeycloakService,
    private _httpClient: HttpClient,
    private _authService: AuthService
  ) {}

  login(): void {
    this._keycloakService.login({
      redirectUri: window.location.origin + "/oauthCallback",
    });
  }

  logout(): void {
    this._keycloakService.logout();
  }

  getToken(): Promise<string> {
    return this._keycloakService.getToken();
  }

  authenticate(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-keycloak-token": token,
      }),
    };
    // this.httpOptions.headers.append('x-keycloak-token',token)
    return this._httpClient
      .get<any>(this.server_url + "/api/v1/oauth/authenticate", httpOptions)
      .pipe(
        map((data) => {
          this._authService.checkForUserInactivity();
          return this._authService.onSuccessfullLogin(data);
        })
      );
  }
  checkUserser({email}) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this._httpClient
      .post<any>(this.server_url + "/api/v1/oauth/check-user",{email}, httpOptions)
  }

  changePassword(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this._httpClient
      .post<any>(this.server_url + "/api/v1/oauth/change-password",body, httpOptions)
  }
}
