import { environment } from 'src/environments/environment'

import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-reporting-container',
  templateUrl: './reporting-container.component.html',
  styleUrls: ['./reporting-container.component.scss']
})
export class ReportingContainerComponent implements OnInit {
  @Input() popUpState: any
  selectedReport: 'inventory' | 'usage' | 'incidents' = 'inventory';
  loader = environment.config.dynamicImage.loader

  constructor() { }

  ngOnInit(): void {
  }

  chooseOption(option: 'inventory' | 'usage' | 'incidents') {
    this.selectedReport = option
  }

}
