// import { GetCompanyService } from 'src/app/services/get-company.service'
import { PopupTypeService } from 'src/app/services/popupType.service'
import { environment } from 'src/environments/environment'

import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { AuthService } from '../../services/auth.service'
import { GetIotService } from '../../services/get-iot.service'
import { IotLiteDomotzErrorMessageComponent } from '../dialogs/iot-lite-domotz-error-message/iot-lite-domotz-error-message.component'

@Component({
	selector: 'app-iot-lite-main-menu',
	templateUrl: './iot-lite-main-menu.component.html',
	styleUrls: ['./iot-lite-main-menu.component.scss'],
})
export class IotLiteMainMenuComponent implements OnInit {
	activePanel: Number = 1
	iotLiteEnabled: any = false
	iotLiteAlertsEnabled: any = false
	isDomotzEnabled: any = false
	company: any
	disableToggle = false
	displayOnlyMonitored: boolean = false;

	constructor(
		private Auth: AuthService,
		private GetIot: GetIotService,
		public dialog: MatDialog,
		// private companyService: GetCompanyService,
		public ds: PopupTypeService,
	) {}

	ngOnInit() {
		this.getIotLiteStatus()
		this.getDomotzAccountByCompanyId()
		// this.companyService.apiGetCompanyInfo().subscribe(data => {
		this.Auth.getCompanyInfo().subscribe(data => {
			this.company = data.response[0]
		})
		this.getIotAlerts()
	}

	selectActivePanel = opt => {
		this.activePanel = opt
		this.displayOnlyMonitored = opt === 3 ? true : false; 
	}

	getIotLiteStatus = () => {
		this.GetIot.getIotLiteStatus().subscribe((data: any) => {
			let [res] = data.response
			this.iotLiteEnabled = res.is_iot_lite

			if (this.iotLiteEnabled == 0) {
				this.setAlertDisable()
			}

			// console.log('this.iotLiteEnabled: ', this.iotLiteEnabled)
		})
	}

	getIotAlerts = () => {
		this.GetIot.getIotAlerts().subscribe((data: any) => {
			const [res] = data.response
			this.disableToggle = false
			this.iotLiteAlertsEnabled = res.iot_alerts_enabled
			// console.log('this.iotLiteEnabled: ', this.iotLiteEnabled)
		})
	}

	// setEnabled = () => {
	//   const flag = this.iotLiteEnabled == 1 ? "0" : "1";

	//   if (this.isDomotzEnabled) {
	//     this.openModalErrorMessage();
	//     this.iotLiteEnabled = false;
	//   } else {
	//     this.GetIot.iotLiteEnabled(flag).subscribe((data) => {
	//       let [res] = data.response;
	//       this.getIotLiteStatus();
	//     });
	//   }

	// }

	setEnabled = e => {
		e.preventDefault()
		const flag = this.iotLiteEnabled ? '0' : '1'
		if (
			environment.config.domotzIntegration &&
			this.company?.has_selected_iot_not_domotz === null &&
			this.iotLiteEnabled === 0
		)
			this.openModalWarnMessage()
		else this.updateIotEnabledStatus(flag)
	}

	updateIotEnabledStatus(flag) {
		this.disableToggle = true
		this.GetIot.iotLiteEnabled(flag).subscribe(() => {
			this.iotLiteEnabled = +flag;
			this.iotLiteAlertsEnabled = +flag;
			this.disableToggle = false
		})
	}

	setAlertEnabled = () => {
		const flag = this.iotLiteAlertsEnabled ? '0' : '1'

		this.disableToggle = true

		this.GetIot.setIotAlerts(flag).subscribe((data: any) => {
			let [res] = data.response
			this.getIotAlerts()
		})
	}

	setAlertDisable = () => {
		const flag = '0'

		this.GetIot.setIotAlerts(flag).subscribe((data: any) => {
			let [res] = data.response
			this.getIotAlerts()
		})
	}

	getDomotzAccountByCompanyId = () => {
		this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
			const { enabled } = data

			this.isDomotzEnabled = enabled
		})
	}

	// openModalErrorMessage = () => {
	//   const config = 'Disable Domotz account to enable IoT'
	//   const webportal = this.dialog.open(IotLiteDomotzErrorMessageComponent, {
	//     panelClass: 'custom-dialog-container',
	//     data: {
	//       config,
	//     },
	//   });
	// };

	openModalWarnMessage = () => {
		// const config = 'Disable Domotz account to enable IoT'
		const config =
			'Enabling IoT will disable the Domotz service permanently. You will no longer be able to use Domotz. Do you want to proceed?'
		const dialogRef = this.dialog.open(IotLiteDomotzErrorMessageComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				config,
				showButton: true,
			},
		})

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.GetIot.setHasSelectedIotNotDomotz('1').subscribe(() => {
					this.company.has_selected_iot_not_domotz = 1
					this.updateIotEnabledStatus('1')
				})
			}
		})
	}
	openPopup(p) {
    this.ds.sendPopupType(p,{
			isDomotz:false,
			iotLiteEnabled:this.iotLiteEnabled
		});
  }
}
