import { Component, Input, OnInit } from '@angular/core'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: 'app-cloud-all-rooms-info',
  templateUrl: './cloud-all-rooms-info.component.html',
  styleUrls: ['./cloud-all-rooms-info.component.scss']
})
export class CloudAllRoomsInfoComponent implements OnInit {
  @Input() cloudRoomSummary: any;
  data: any;

  currentFilter: string;

  constructor(private ds: PopupTypeService, public Auth: AuthService) {

  }
  isSuperAdmin: any;
  server_url: string = environment.serverUrl;
  mspLogo: string = environment.config.logo;
  companyLogo: any;


  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    this.getCompanyInfo();
    this.isSuperAdmin = this.Auth.accessLevel == "SUPERADMIN";
  }

  getCompanyInfo() {
    this.Auth.getCompanyInfo().subscribe((data) => {
      const [newData] = data.response;
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
    });
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p)
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

}