<!-- Category Filters -->
<div class="inventory-container__filter-generate-btn-group">
  <div class="inventory-container__filter-options">
    <div class="inventory-container__filter-item" *ngFor="let category of categories">
      <label>
        <input type="checkbox" [checked]="category.selected" (change)="onCategorySelect(category)">
        {{ category.name }}
      </label>
    </div>
  </div>

  <div class="button-group">
    <button class="inventory-container__btn inventory-container__btn-primary" (click)="generate()">
      Generate
    </button>
    <button class="inventory-container__btn inventory-container__btn-primary" [disabled]="!rawData.length"
      (click)="printDiv('printableInventoryReport')">
      <i class="fa fa-print fa-2x menu-icon"></i>
    </button>
  </div>
</div>

<div id="printableInventoryReport" class="inventory-container__filter-section">

  <!-- Loading State -->
  <div *ngIf="isLoading" class="loading-state">
    Loading inventory data...
  </div>

  <!-- Reports Title Panel After Filters -->
  <app-reports-title-panel *ngIf="fromDate && toDate" [fromdate]="fromDate" [todate]="toDate"
    [siteTitle]="'Inventory Report'" [siteSubTitle]="'Device Inventory'" [siteName]="selectedSite"
    [siteType]="'inventory'" [isDevice]="false">
  </app-reports-title-panel>

  <!-- Table Section -->
  <div class="inventory-table-container">
    <table>
      <thead>
        <tr>
          <th class="text-left">Site Name</th>
          <th class="text-left">Room Name</th>
          <!-- <th class="text-center">Device Name</th> -->
          <th class="text-center">IP Address</th>
          <th class="text-center">Serial Number</th>
          <th class="text-center">System Type</th>
          <th class="text-center">Mic</th>
          <th class="text-center">Camera</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of filteredData">
          <td class="text-left">{{device.site_name || '-'}}</td>
          <td class="text-left">{{device.room_name || '-'}}</td>
          <!-- <td class="text-center">
            <span class="device-name">{{device.device_name || '-'}}</span>
          </td> -->
          <td class="text-center">
            <span class="monospace">{{device.ip_address || '-'}}</span>
          </td>
          <td class="text-center">
            <span class="monospace">{{device.serial_number || '-'}}</span>
          </td>
          <td class="text-center">
            <span class="system-type">{{device.system_type || '-'}}</span>
          </td>
          <td class="text-center">
            <span class="status-indicator" [ngClass]="{
                    'active': device.mic === 1,
                    'inactive': device.mic === 0,
                    'unknown': device.mic === null
                  }">
              {{ device.mic === 1 ? 'Active' :
              device.mic === 0 ? 'Inactive' :
              'N/A' }}
            </span>
          </td>
          <td class="text-center">
            <span class="status-indicator" [ngClass]="{
                    'active': device.cam === 1,
                    'inactive': device.cam === 0,
                    'unknown': device.cam === null
                  }">
              {{ device.cam === 1 ? 'Active' :
              device.cam === 0 ? 'Inactive' :
              'N/A' }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- No Data State -->
  <div class="inventory-container__no-data" *ngIf="!isLoading && (!filteredData || filteredData.length === 0)">
    No inventory data available for selected filters.
  </div>
</div>