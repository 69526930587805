import { Component, Inject, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { fromEvent, Subject, merge, Subscription, interval } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GuacamoleService } from 'src/app/services/guacamole.service';
import { RemoteAccessService } from 'src/app/services/remote-access.service';

@Component({
  selector: 'app-remote-session',
  templateUrl: './remote-session.component.html',
  styleUrls: ['./remote-session.component.scss']
})
export class RemoteSessionComponent implements OnInit, OnDestroy, AfterViewInit {
  sessionUrl: string;
  sessionStartTime: Date;
  forward_url: string;
  remainingTimeDisplay: string = '';
  @ViewChild('guacDisplay') guacDisplay!: ElementRef;

  @Output() guca_id: string = '';
  
  private readonly TIMEOUT_DURATION = 600000; // 10 minutes in milliseconds
  private timeoutId: any;
  private destroy$ = new Subject<void>();
  private activitySubscription: Subscription;
  private timerSubscription: Subscription;
  private remainingTime: number;
  private lastActivityTime: number;
  private client: any;
  proto: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<RemoteSessionComponent>,
    private clipboard: Clipboard,
    public _guacamoleService: GuacamoleService,
    public _remoteService: RemoteAccessService
  ) {
    if (this.data) {
      console.log(this.data);
      this.sessionUrl = this.data?.ngres?.tunnel?.url || "";
      this.proto = this.data?.ngres?.tunnel?.proto;
      this.sessionStartTime = new Date();
      this.forward_url = "";
    }
    this.lastActivityTime = Date.now();
    this.remainingTime = this.TIMEOUT_DURATION;
    this.updateRemainingTimeDisplay();
  }

  isIframeVisible: boolean = true;

  refreshIframe() {
    this.isIframeVisible = false;  // Hide iframe
    setTimeout(() => {
      this.isIframeVisible = true;  // Show iframe again to trigger re-mount
    }, 100);  // Short delay to ensure it remounts
  }

  ngOnInit() {
    this.initializeActivityTracking();
    this.startInactivityTimer();
    this.startCountdownDisplay();
  }

  ngAfterViewInit(): void {
    this.connectToRemote();
  }
  connectToRemote(){
    if(this.proto === "tcp"){
      //url tcp://13.57.132.120:22
      const [hostname, port] = this.sessionUrl.split('://')[1].split(':');
      // 4.tcp.us-cal-1.ngrok.io:19474 
      //2.tcp.us-cal-1.ngrok.io:14096 
      // const [hostname, port] = ["2.tcp.us-cal-1.ngrok.io",14096 ]
      this._remoteService.getToken({hostname:hostname,port:port,protocol:this.data?.proto,name:this.data?.tunnel_name,tunnel_id:this.data?.tunnel_id}).subscribe((x)=>{
      if(x.success){
        localStorage.setItem('guac_token',x.data.token);
        this.client = this._guacamoleService.connect(x.data.token,x.data.guac_id);
        this.guca_id = x.data.guac_id;
      }
    })
    }
  }
  reconnectToRemote(){
    const token = localStorage.getItem('guac_token');
    if(token&& this.guca_id){
      this.client = this._guacamoleService.connect(token,this.guca_id);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this._guacamoleService.disconnect();
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  openSessionInNewTab() {
    window.open(this.sessionUrl, '_blank');
  }

  private initializeActivityTracking() {
    const mouseMove$ = fromEvent(document, 'mousemove');
    const mouseClick$ = fromEvent(document, 'click');
    const keyPress$ = fromEvent(document, 'keypress');

    this.activitySubscription = merge(mouseMove$, mouseClick$, keyPress$)
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.onUserActivity();
      });
  }

  private startCountdownDisplay() {
    this.timerSubscription = interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateRemainingTimeDisplay();
      });
  }

  private updateRemainingTimeDisplay() {
    const currentTime = Date.now();
    const elapsed = currentTime - this.lastActivityTime;
    this.remainingTime = Math.max(0, this.TIMEOUT_DURATION - elapsed);

    const minutes = Math.floor(this.remainingTime / 60000);
    const seconds = Math.floor((this.remainingTime % 60000) / 1000);

    this.remainingTimeDisplay = `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  private onUserActivity() {
    this.lastActivityTime = Date.now();
    this.remainingTime = this.TIMEOUT_DURATION;
    this.updateRemainingTimeDisplay();
    this.resetInactivityTimer();
  }

  private startInactivityTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - this.lastActivityTime;

      if (inactiveTime >= this.TIMEOUT_DURATION) {
        this.handleClose();
      } else {
        this.remainingTime = this.TIMEOUT_DURATION - inactiveTime;
        this.startInactivityTimer();
      }
    }, Math.min(60000, this.remainingTime));
  }

  private resetInactivityTimer() {
    this.remainingTime = this.TIMEOUT_DURATION;
    this.startInactivityTimer();
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.sessionUrl);
    this.onUserActivity();
  }

  handleClose(): void {
    this.dialogRef.close();
  }
  getToken(){

  }

}