<button type="button" class="not-button btn-long d-flex py-2 zoom-micrphone">
  <!-- <div class="zoom-micrphone_image" (click)="ds.sendPopupType(46, { data: data })">
    <img src="{{ icon }}" (mouseenter)="iconOnHover()" (mouseleave)="iconOnLeave()" />
  </div> -->

  <div class="zoom-micrphone_image position-relative">
    <img src="{{ icon }}" />
    <span class="badge" *ngIf="isLogitechData && getStatusIcon(inuseMic) === iconTriangleRed " [matTooltip]="'Disconnected mic: ' + ConnectionState">
      {{ ConnectionState }}
    </span>
  </div>

  <div class="zoom-micrphone_details">
    <div class="zoom-micrphone_mute">
      Primary
      <img src="{{ getStatusIcon(inuseMic) }}" />
    </div>
    <div class="tooltip-container" (click)="ds.sendPopupType(48, { data: data })">
      <span>
        {{ inuseMic.name }}
      </span>
      <div *ngIf="inuseMic.name" class="tooltip-text">
        <strong>Device Name:</strong> {{ inuseMic.name }}<br />
        <strong *ngIf="firmwareVersion">Firmware Version:</strong> {{ firmwareVersion }}
    </div>
    </div>
  </div>
</button>