import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-reports-incident-sheet',
  templateUrl: './reports-incident-sheet.component.html',
  styleUrls: ['./reports-incident-sheet.component.scss']
})
export class ReportsIncidentSheetComponent implements OnChanges {
  @Input() incidentList: any;
  @Input() listTitle: any;
  @Input() isZoom:boolean = false;
  
  constructor() { }



  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.incidentList && this.incidentList) {
      this.setData();
    }
  }
  


  setData = () => {
    const incidentList = this.incidentList.map(e => {
      return {
        ...e,
        description: this.tempFix(e.description)
      }
    })

    this.incidentList = incidentList;

  }

  tempFix = (text) => {
    if (text && text.indexOf("HELPLINE") > -1) {
      return 'SIP IS DOWN.'
    }      
    return !text ? 'Unknown Trigger' : text
  }

}
