<div *ngIf="showLoader" class="company-documents__loader loader-custom">
  <img src="{{ loader }}" alt="" />
</div>
<div class="teams-room-info">
  <div class="teams-room-info__logo-img">
    <img src="./assets/img/logo-microsoft-teams-2.png" />
  </div>

  <div class="teams-room-info__text">
    <div class="teams-room-info__text-item">
      <strong>Host:</strong> {{ hostname | displayEmpty }}
    </div>
    <div class="teams-room-info__text-item">
      <strong>Site:</strong> {{ teamsDetails?.site_name | displayEmpty }}
    </div>
    <div class="teams-room-info__text-item">
      <strong>Room Name:</strong> {{ teamsDetails?.room_name | displayEmpty }}
    </div>
    <div class="teams-room-info__text-item">
      <strong>Booking Email ID:</strong>
      {{ teamsDetails?.email | displayEmpty }}
    </div>
    <div class="teams-room-info__text-item">
      <strong>IP address:</strong> {{ ipAddress | displayEmpty }} <app-table-cog-icon *ngIf="ipAddress&&cloudRoomInfo?.is_remote_access === 1" (click)="openRemote()"></app-table-cog-icon>
    </div>
    <div class="teams-room-info__text-item">
      <strong>MAC address:</strong> {{ mac | displayEmpty }}
    </div>
    <div class="teams-room-info__text-item"><strong>Serial no:</strong> {{ serialNo | displayEmpty}}</div>
  </div>

  <div class="teams-room-info__button-container">
    <!-- Will disable later -->
    <div class="teams-room-info__uptime-button-container">
      <!--Uptime & Button Containers-->
      <div class="teams-room-info__uptime">
        <app-info-uptime [uptimeDays]="uptimeDays" [uptimeHours]="uptimeHours"
          [uptimeMins]="uptimeMins"></app-info-uptime>
      </div>
      <!--END-->

      <div class="teams-room-info__gray-button-container">
        <app-btn-info label="admin" [faName]="faIcon" (click)="ds.sendPopupType(66, { data: teamsAdmin })">
        </app-btn-info>
        <app-btn-info label="{{ teamsDetails?.pause == 1 ? 'unpause' : 'pause' }}"
          [faName]="teamsDetails?.pause == 1 ? faPauseIcon : faPlayIcon" (click)="setRoomPause(teamsDetails?.pause)">
        </app-btn-info>
        <app-btn-info [ngClass]="
              teamsDetails?.plugin_id !== null && teamsDetails?.zoom_plugin_enabled
                ? ''
                : 'disable'
            " label="reboot" [faName]="faIcon" (click)="
              ds.sendPopupType(73, {
                alertsId: teamsDetails?.zoom_user_id,
                alertsType: 'teams',
                data: teamsDetails?.plugin_id
              })
            "></app-btn-info>
      </div>
    </div>

    <div class="teams-room-info__button--" [ngClass]="{
        'teams-room-info__button-online': userStatus == 'Online',
        'teams-room-info__button-offline': userStatus == 'Offline',
        'teams-room-info__button-unknown': userStatus == 'Unknown',
        'teams-room-info__button-pause': userStatus == 'Paused'
      }">
      {{ userStatus == "Unknown" ? "Undetected" : userStatus }}
    </div>

    <hr *ngIf="showLogitechTap" />
    <app-logitech-tap-panel *ngIf="showLogitechTap" [logitechTap]="logitechTap"></app-logitech-tap-panel>

    <hr *ngIf="shouldShowIot" />
    <app-iot *ngIf="shouldShowIot" [iotData]="iotData" [iotEvent]="cloudEvent"></app-iot>

    <hr />
    <div class="teams-room-info__network-utilization">
      <!-- <hr *ngIf="true" />
            <app-iot *ngIf="true" [iotData]="iotData" [iotEvent]="cloudEvent"></app-iot>
            <hr /> -->
      <h5>Network Utilization</h5>
      <hr />
      <app-chart-teams-mbps [teamsDetails]="teamsDetails"></app-chart-teams-mbps>
    </div>
  </div>
</div>

<div class="teams-room-info-outer">
  <app-teams-room-console [isDisable]="!isTeamsPluginEnabled || deskPluginStatus !== 1"
    [pluginId]="teamsDetails?.plugin_id" [desktopPluginMessage]="desktopPluginMessage" [originationIp]="ipAddress">
  </app-teams-room-console>
  <div class="teams-room-info-outer__additional">
    <h5>Additional</h5>
    <div>
      <strong>Network Connection Type:</strong>
      {{ teamsDetails?.timezone | displayEmpty }}
    </div>
    <div>
      <strong>Network Type:</strong>
      {{ teamsDetails?.plugin_network_type | displayEmpty }}
    </div>
  </div>
</div>