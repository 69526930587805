import { Subscription } from 'rxjs'
import { TableFilterService } from 'src/app/services/table-filter.service'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'

import { GetVideoService } from '../../../services/get-video.service'
// import { LocalDataSource } from 'ng2-smart-table';
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-video-alldevices-panel',
  templateUrl: './video-alldevices-panel.component.html',
  styleUrls: ['./video-alldevices-panel.component.scss'],
})
export class VideoAlldevicesPanelComponent implements OnInit, OnDestroy {
  PopupTitle: string = 'Video Devices';
  public videoSitesSummary;
  public videositesInfo;
  // public videositesStats;
  id: number;
  videoApiData: any = [];
  videoDeviceApiSummary: any = [];
  videoDevicesListData: MatTableDataSource<any>;

  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  // Settings and Parameters for the the Smart Filter Tables
  baseFilter: any = 'all';
  // videoDeviceData: LocalDataSource;
  loading: any = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private refreshApiService: RefreshApiService,
    private tableFilterService: TableFilterService
  ) {
    // this.videoDeviceData = new LocalDataSource();
    this.videoDevicesListData = new MatTableDataSource();

    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });

    if (this.router.url == '/video/healthydevices') {
      this.baseFilter = 'healthy';
    } else if (this.router.url == '/video/warningdevices') {
      this.baseFilter = 'warning';
    } else if (this.router.url == '/video/impacteddevices') {
      this.baseFilter = 'impacted';
    } else if (this.router.url == '/video/pauseddevices') {
      this.baseFilter = 'paused';
    } else if (this.router.url == '/video/alldevices') {
      this.baseFilter = 'all';
    }

    this.filterListner = this.tableFilterService.listen().subscribe((m: any) => {
      if (m == '') {
        if (this.router.url == '/video/healthydevices') {
          this.baseFilter = 'healthy';
        } else if (this.router.url == '/video/warningdevices') {
          this.baseFilter = 'warning';
        } else if (this.router.url == '/video/pauseddevices') {
          this.baseFilter = 'paused';
        } else if (this.router.url == '/video/impacteddevices') {
          this.baseFilter = 'impacted';
        } else if (this.router.url == '/video/alldevices') {
          this.baseFilter = 'all';
        }
      } else {
        this.baseFilter = m;
      }
      this.videoDevicesListData.filter = '-'.trim().toLocaleLowerCase();
    });
    this.videoDevicesListData.filterPredicate = (data: any, filter: string) => {
      // ipv4 need to be edited?
      var dataStr =
        data.ipv4 +
        data.site_name +
        data.health +
        data.liveMeetings +
        data.company_name +
        data.room_name;

      if (this.baseFilter == 'impacted') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health <= 50 && data.paused != 1;
        } else {
          return (
            data.health <= 50 &&
            data.paused != 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'warning') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health >= 51 && data.health < 76 && data.paused === 0;
        } else {
          return (
            data.health >= 51 &&
            data.health < 76 &&
            data.paused === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'paused') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.paused == 1;
        } else {
          return (
            data.paused == 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'healthy') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health >= 76 && data.paused === 0;
        } else {
          return (
            data.health >= 76 &&
            data.paused === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'all') {
        if (filter == '-' || filter == '' || filter == null) {
          return true;
        } else {
          return (
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'oncall') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.oncall == 1;
        } else {
          return (
            data.oncall == 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'impactedOnly') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health < 76 && data.paused != 1;
        } else {
          return (
            data.health < 76 &&
            data.paused != 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }
    };
  }

  // This function gets the API data for the server
  // This function handle pulling the API data from the server every 30 seconds
  getApiData() {
    if (this.refreshData) {
      this.getVideoApiData();
    }
  }

  ngOnInit() {
    // this.getStats();
    this.getVideoApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
    // This will clear timers that are pulling data from API Server
    // clearInterval(this.apiTimers);
  }

  // This function pulls All Video Device data from API Server
  getVideoApiData() {
    //GET Request for Company Info
    this.getVideoDataService.apiGetVideoCompanyInfo().subscribe((data) => {
      this.videositesInfo = data['response'];
    });

    // this.videoApiData = [];

    //GET Request for All Video Device Detailed List
    this.getVideoDataService.apiGetAllVideoDevices().subscribe((data) => {
      this.videoApiData = data['response'];

      // This is to load data into an array for in order to create a searchable filter table
      let arr = [];
      for (let d in this.videoApiData) {
        arr.push(this.videoApiData[d]);
      }
      // this.videoDeviceData.load(arr);
      this.videoDevicesListData.data = this.videoApiData;




      this.loading = false;
    });

    //GET Request for Video Device Stats Summary by Site
    this.getVideoDataService.apiGetVideoDeviceSummary().subscribe((data) => {
      this.videoSitesSummary = data['response'][0];


    });
  }

  getStats() {
    this.getVideoDataService.fetchVideoSummary().subscribe((data) => {
      this.videoSitesSummary = data[0];

    });

    this.getVideoDataService.fetchVideoDevices().subscribe((data) => {
      // This is to load data into an array for in order to create a searchable filter table
      let arr = [];
      for (let d in data) {
        arr.push(data[d]);
      }
      // this.videoDeviceData.load(arr);
    });
  }
}
