<div class="allow-access-panel">
    <div class="allow-access-body">
        <span class="access-text">Allow Access</span>
        <label>
            <input type="checkbox" [(ngModel)]="is_pexip_access" (ngModelChange)="allowAccess()" />
            Enabled
        </label>

    </div>
</div>
<img [src]="loader" class="show-loader" *ngIf="isShow">