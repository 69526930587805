import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-pexip',
  templateUrl: './btn-pexip.component.html',
  styleUrls: ['./btn-pexip.component.scss']
})
export class BtnPexipComponent implements OnInit {
  @Input('icon') icon :string = '';
  @Input('title') title : string = '';
  @Input('counts') counts : number = 0;
  constructor() { }

  ngOnInit(): void {
  }

}
