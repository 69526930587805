import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RemoteAccessService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  server_url: string = environment.serverUrl;
  constructor(private http: HttpClient) {}
  collectorAction = (body) => {
    return this.http.post<any>(
      this.server_url + "/api/v1/remote-access/collector-action",
      body,
      this.httpOptions
    );
  };
  allowAccess = (body) => {
    return this.http.post<any>(
      this.server_url + "/api/v1/remote-access",
      body,
      this.httpOptions
    );
  };
  addingTunnel = (body) => {
    return this.http.post<any>(
      this.server_url + "/api/v1/remote-access/adding-tunnel",
      body,
      this.httpOptions
    );
  };
  updateTunnel = (body) => {
    return this.http.post<any>(
      this.server_url + "/api/v1/remote-access/update-tunnel",
      body,
      this.httpOptions
    );
  };
  getTunnelLog = (collector_id) => {
    return this.http.get<any>(
      this.server_url + "/api/v1/remote-access/log/"+collector_id,
      this.httpOptions
    );
  };
  getDomain = () => {
    return this.http.get<any>(
      this.server_url + "/api/v1/remote-access/get-domain",
      this.httpOptions
    );
  };
  getToken = (body) => {
    return this.http.post<any>(
      this.server_url + "/api/v1/remote-access/get-token",
      body,
      this.httpOptions
    );
  };
  gucaTunnel = (body) => {
    return this.http.post<any>(
      this.server_url + "/api/v1/remote-access/guca-tunnel",
      body,
      this.httpOptions
    );
  };
}
