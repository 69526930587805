<div style="
    max-height: 100%;
    min-width: 100%;
    position: fixed;
    /* bottom: -16px; */
    bottom: 60px;
    z-index: 1120;
    transition: all 1500ms;
  " [@popupOpenClose]="popupTypeState">
  <div class="panel-group">
    <div *ngIf="zoomPluginAction.visibility_one_loader || ds.loader">
      <app-loader></app-loader>
    </div>
    <div class="panel panel-default">
      <span *ngIf="isShowQosChartandList == false; else closeCollectorInfo" class="close float-right"
        (click)="popupTypeState = 'close'">
        <img style="cursor: pointer !important;" src="./assets/img/btn close_default.png" />
      </span>
      <ng-template #closeCollectorInfo>
        <span class="close float-right" (click)="isShowQosChartandList = false">
          <img style="cursor: pointer !important;" src="./assets/img/btn close_default.png" *ngIf="runTutorial != 1" />
        </span>
      </ng-template>

      <div class="panel-body" *ngIf="popupType == 100" title="app-not-available">
        <div class="panel-heading"></div>
        <app-not-available></app-not-available>
      </div>
      <div class="panel-body" *ngIf="popupType == 1" title="scheduled meetings">
        <div class="panel-heading">Scheduled meetings</div>
        <app-scheduled-meetings [popData]="data" [popupState]="popupTypeState"></app-scheduled-meetings>
      </div>
      <div class="panel-body" *ngIf="popupType == 2" title="edit profile">
        <div class="panel-heading">Edit company profile</div>
        <app-edit-profile></app-edit-profile>
      </div>
      <div class="panel-body" *ngIf="popupType == 3" title="qos test">
        <div class="panel-heading">QoS site to site health</div>
        <app-qos-test [deviceId]="deviceId" [siteId]="data" (isShowQosChartandListChange)="receiveMessage($event)"
          [isShowQosChartandList]="isShowQosChartandList"></app-qos-test>
      </div>
      <div class="panel-body" *ngIf="popupType == 4" title="chart audio 24hours">
        <div class="panel-heading">Chart audio</div>
        <app-chart-audio></app-chart-audio>
      </div>
      <div class="panel-body" *ngIf="popupType == 5" title="polycom">
        <div class="panel-heading">Add a device</div>
        <app-polycom-popup></app-polycom-popup>
      </div>
      <div class="panel-body" *ngIf="popupType == 6" title="chart audio 24hours">
        <div class="panel-heading">Chart site</div>
        <app-chart-site></app-chart-site>
      </div>
      <div class="panel-body" *ngIf="popupType == 7" title="chart audio 24hours">
        <div class="panel-heading">Reboot device</div>
        <app-reboot-device [deviceId]="deviceId" [mediaType]="mediaType"></app-reboot-device>
      </div>
      <div class="panel-body" *ngIf="popupType == 8" title="add room">
        <div class="panel-heading">Add room</div>
        <app-add-room></app-add-room>
      </div>
      <div class="panel-body" *ngIf="popupType == 9" title="add user">
        <div class="panel-heading">Add user</div>
        <app-add-user></app-add-user>
      </div>
      <div class="panel-body" *ngIf="popupType == 10" title="cloud room recordings">
        <div class="panel-heading">Cloud room recordings</div>
        <app-cloud-recordings [popData]="data" [popupState]="popupTypeState"></app-cloud-recordings>
      </div>
      <div class="panel-body" *ngIf="popupType == 11" title="verify pin">
        <div class="panel-heading">Verify pin</div>
        <app-enter-pin [popUpEmitter]="popUpEmitter"></app-enter-pin>
      </div>
      <div class="panel-body" *ngIf="popupType == 12" title="verify pin">
        <div class="panel-heading">Total calls this month</div>
        <app-cloud-calls-month></app-cloud-calls-month>
      </div>
      <div class="panel-body" *ngIf="popupType == 13" title="verify pin">
        <div class="panel-heading">Suport</div>
        <app-cloud-calls-month></app-cloud-calls-month>
      </div>
      <div class="panel-body" *ngIf="popupType == 20" title="create">
        <div class="panel-heading">Create a site</div>
        <app-create-site [popUpEmitter]="popUpEmitter"></app-create-site>
      </div>
      <div class="panel-body" *ngIf="popupType == 21" title="virtual">
        <div class="panel-heading">Create a virtual site</div>
        <app-create-virtual-site [popUpEmitter]="popUpEmitter"></app-create-virtual-site>
      </div>
      <div id="chartVideoDnaChart" class="panel-body" *ngIf="popupType == 31" style="cursor: default !important;">
        <div class="panel-heading">Network Performance</div>
        <!-- <app-chart-video-dna [deviceId]="deviceId" [data]="data" [deviceType]="deviceType"
          [popupState]="popupTypeState"></app-chart-video-dna> -->
        <app-echart-video-dna [deviceId]="deviceId" [data]="data" [deviceType]="deviceType"
          [popupState]="popupTypeState"></app-echart-video-dna>
      </div>
      <div class="panel-body" *ngIf="popupType == 32">
        <div class="panel-heading">{{ popUpTitle }} Admin</div>
        <app-rescan (showLinkSiteEvent)='linkSiteEvent($event)' [isShouldShowLinkSite]="isShouldShowLinkSite"
          [deviceId]="deviceId" [data]="data" [popupState]="popupTypeState" [alertsId]="alertsId"
          [alertType]="alertType" [oldPorts]="oldPorts"></app-rescan>
      </div>
      <div class="panel-body" *ngIf="popupType == 33" title="Admin Tools">
        <div class="panel-heading">Admin Tools</div>
        <app-admin-tools></app-admin-tools>
      </div>
      <div class="panel-body" *ngIf="popupType == 34" title="edit site">
        <div class="panel-heading">Edit a site</div>
        <app-edit-site [siteId]="data.siteId" [styleProp]="data.styleProp" [popupState]="popupTypeState">
        </app-edit-site>
      </div>
      <div class="panel-body" *ngIf="popupType == 35" title="scheduled meetings">
        <div class="panel-heading">Scheduled meetings</div>
        <app-scheduled-meetings-site [popData]="data" [popupState]="popupTypeState"></app-scheduled-meetings-site>
      </div>
      <div class="panel-body" *ngIf="popupType == 36" title="device history">
        <div class="panel-heading">Device History</div>
        <app-device-health-history [popData]="data" [popupState]="popupTypeState"></app-device-health-history>
      </div>
      <div class="panel-body" *ngIf="popupType == 37" title="call logs">
        <div class="panel-heading">Call Logs</div>
        <app-device-call-history [popData]="data" [popupState]="popupTypeState"></app-device-call-history>
      </div>
      <div class="panel-body" *ngIf="popupType == 38" title="scheduled meetings">
        <div class="panel-heading">Scheduled Meetings</div>
        <app-scheduled-meetings-zoom [popData]="data" [popupState]="popupTypeState"></app-scheduled-meetings-zoom>
      </div>
      <div class="panel-body" *ngIf="popupType == 39" title="calls this month">
        <div class="panel-heading">Calls This Month</div>
        <app-calls-this-month [popData]="data" [popupState]="popupTypeState"></app-calls-this-month>
      </div>
      <div class="panel-body" *ngIf="popupType == 40" title="chart cloud dna">
        <div class="panel-heading">Network Performance</div>
        <app-chart-cloud-dna [deviceId]="deviceId" [data]="data" [popupState]="popupTypeState"></app-chart-cloud-dna>
      </div>
      <div id="audioNetworkPerformance" class="panel-body" *ngIf="popupType == 41" title="chart auido dna"
        style="cursor: default !important;">
        <div class="panel-heading">Network Performance</div>
        <app-chart-audio-dna [deviceId]="deviceId" [data]="data" [deviceType]="deviceType"
          [popupState]="popupTypeState"></app-chart-audio-dna>
      </div>
      <div class="panel-body" *ngIf="popupType == 42" title="call logs">
        <div class="panel-heading">Call Logs</div>
        <app-audio-device-call-history [popData]="data" [popupState]="popupTypeState"></app-audio-device-call-history>
      </div>
      <div class="panel-body" *ngIf="popupType == 43" title="Device and Plugin Status">
        <div class="panel-heading">Device and {{ dPlugin }} Status</div>
        <app-device-plugin-status [popupState]="popupTypeState" [hostInfo]="data.plugin_host_info"
          [cpuInfo]="data.plugin_cpu_info" [networkAdapters]="data.plugin_network_adapters"
          [diskInfo]="data.plugin_disk_info" [memoInfo]="data.plugin_memo_info" [lastConnect]="data.plugin_last_connect"
          [version]="data.plugin_version" [pluginId]="data.plugin_id" [pluginEnabled]="data.zoom_plugin_enabled === 1"
          [pluginStatus]="data.plugin_status" [isRoom]="data.is_room === 1" [serviceType]="data.service_type">
        </app-device-plugin-status>
      </div>

      <div class="panel-body" *ngIf="popupType == 45" title="Reboot Cloud">
        <div class="panel-heading">Reboot Cloud</div>
        <app-cloud-reboot-plug-in-button [popupState]="popupTypeState" [pluginId]="data">
        </app-cloud-reboot-plug-in-button>
      </div>

      <div class="panel-body" *ngIf="popupType == 44" title="Device and Plugin Status">
        <div class="panel-heading">Camera Failover</div>
        <app-camera-plugin-in-status [cloudRoomVideoStatsData]="data.connectedCameras" [pluginId]="data.pluginId"
          [cameraFailOver]="data.failOver">
        </app-camera-plugin-in-status>
      </div>

      <div class="panel-body" *ngIf="popupType == 46" title="">
        <div class="panel-heading">Microphone Failover</div>
        <app-audio-mic-plugin-status [cloudRoomAudioStatsData]="data.connectedAudioMic" [pluginId]="data.pluginId"
          [micFailOver]="data.failOver">
        </app-audio-mic-plugin-status>
      </div>

      <div class="panel-body" *ngIf="popupType == 47" title="">
        <div class="panel-heading">Speaker Failover</div>
        <app-audio-speaker-plugin-status [cloudRoomSpeakerStatsData]="data.connectedAudioSpeaker"
          [pluginId]="data.pluginId" [speakerFailOver]="data.failOver">
        </app-audio-speaker-plugin-status>
      </div>

      <div class="panel-body" *ngIf="popupType == 48" title="">
        <div class="panel-heading">Microphone Selection</div>
        <app-audio-mic-list [connectedAudioMic]="data.connectedAudioMic" [serviceType]="data.serviceType"
          [pluginId]="data.pluginId">
        </app-audio-mic-list>
      </div>

      <div class="panel-body" *ngIf="popupType == 49" title="Video Camera List">
        <div class="panel-heading">Camera Selection</div>
        <app-video-camera-list [connectedCameras]="data.connectedCameras" [pluginId]="data.pluginId">
        </app-video-camera-list>
      </div>

      <div class="panel-body" *ngIf="popupType == 50" title="Video Camera List">
        <div class="panel-heading">Speaker Selection</div>
        <app-speaker-list [connectedAudioSpeaker]="data.connectedAudioSpeaker" [serviceType]="data.serviceType"
          [pluginId]="data.pluginId">
        </app-speaker-list>
      </div>

      <div class="panel-body" *ngIf="popupType == 51" title="Speaker Confirmation">
        <div class="panel-heading">Speaker Confirmation</div>
        <app-speaker-confirmation [sliderValue]="data" [pluginId]="data.pluginId"></app-speaker-confirmation>
      </div>

      <div class="panel-body" *ngIf="popupType == 52" title="Zoom Plugin">
        <div class="panel-heading">Zoom {{ dPlugin }}</div>
        <app-install-zoom-plugin [message]="data"></app-install-zoom-plugin>
      </div>

      <div class="panel-body" *ngIf="popupType == 53" title="Reboot device Iot">
        <div class="panel-heading">Reboot device Iot</div>
        <app-iot-reboot-device [rebootData]="data"></app-iot-reboot-device>
      </div>

      <div class="panel-body" *ngIf="popupType == 54" title="Zoom Incidents (Last 30 Days)">
        <div class="panel-heading">Zoom Incidents (Last 30 Days)</div>
        <app-zoom-incident [popData]="data" [popupState]="popupTypeState"></app-zoom-incident>
      </div>

      <div class="panel-body" *ngIf="popupType == 56" title="Past 30 days IoT Device Events">
        <div class="panel-heading">Past 30 days IoT Device Events</div>
        <app-iot-device-history [deviceId]="data.iot_device_id" [agentId]="data.agent_id"
          [accountUrl]="data.account_url" [apiKey]="data.api_key"></app-iot-device-history>
      </div>

      <div class="panel-body" *ngIf="popupType == 57">
        <div class="panel-heading">Historic Ping Status</div>
        <app-chart-historic-ping-status [deviceId]="data.iotDeviceID" [agentId]="data.agentId"
          [accountUrl]="data.accountUrl" [apiKey]="data.apiKey" [paused]="data.paused" [dateRange]="data.dateRange"
          [viewType]="data.viewType" [popupState]="popupTypeState"></app-chart-historic-ping-status>
      </div>

      <div class="panel-body" *ngIf="popupType == 58">
        <div class="panel-heading">IoT Admin</div>
        <app-iot-admin-pop-up [iotDeviceDetails]="data" [popUpEmitter]="popUpEmitter"></app-iot-admin-pop-up>
      </div>

      <div class="panel-body" *ngIf="popupType == 59" title="deskPlugin">
        <div class="panel-heading">Zoom {{ deskPlugin }}</div>
        <app-desktop-plugin-display-message [message]="data"></app-desktop-plugin-display-message>
      </div>

      <div class="panel-body" *ngIf="popupType == 60" title="Device and Plugin Status">
        <div class="panel-heading">Device and {{ deskPlugin }} Status</div>
        <app-device-plugin-status [popupState]="popupTypeState" [hostInfo]="data.plugin_host_info"
          [cpuInfo]="data.plugin_cpu_info" [networkAdapters]="data.plugin_network_adapters"
          [diskInfo]="data.plugin_disk_info" [memoInfo]="data.plugin_memo_info" [lastConnect]="data.plugin_last_connect"
          [version]="data.plugin_version" [pluginId]="data.plugin_id" [pluginEnabled]="data.zoom_plugin_enabled === 1"
          [pluginStatus]="data.plugin_status" [isRoom]="data.is_room === 1">
        </app-device-plugin-status>
      </div>

      <div class="panel-body" *ngIf="popupType == 61" title="Device Heal">
        <div class="panel-heading">{{ data.device }} Remote Heal</div>
        <app-cloud-user-actions [data]="data" [popupState]="popupTypeState">
        </app-cloud-user-actions>
      </div>

      <div class="panel-body" *ngIf="popupType == 62" title="IoT Device Incidents">
        <div class="panel-heading">IoT Device Incidents (Last 30 Days)</div>
        <app-iot-device-health-history [data]="data" [popupState]="popupTypeState"></app-iot-device-health-history>
      </div>
      <div class="panel-body" *ngIf="popupType == 63" title="Zoom Reboot">
        <div class="panel-heading">Zoom Reboot</div>
        <app-cloud-user-reboot [data]="data"></app-cloud-user-reboot>
      </div>

      <div class="panel-body" *ngIf="popupType == 64" title="Past Meetings & Calls">
        <div class="panel-heading">Past Meetings & Calls</div>
        <app-teams-past-meetings-and-calls [deviceId]="deviceId"></app-teams-past-meetings-and-calls>
      </div>

      <div class="panel-body" *ngIf="popupType === 65" title="Video Device Incidents">
        <div class="panel-heading">Video Device Incidents (Last 30 Days)</div>
        <app-video-device-incidents [popData]="data" [popupState]="popupTypeState"></app-video-device-incidents>
      </div>

      <div class="panel-body" *ngIf="popupType === 66" title="Teams Admin">
        <div class="panel-heading">Teams Admin</div>
        <!-- <app-video-device-incidents [popData]="data" [popupState]="popupTypeState"></app-video-device-incidents> -->
        <app-teams-admin (showLinkSiteEvent)='linkSiteEvent($event)' [data]="data"></app-teams-admin>
      </div>

      <div class="panel-body" *ngIf="popupType === 67" title="Reboot Teams">
        <div class="panel-heading">Reboot Teams</div>
        <app-teams-reboot-admin [data]="data"></app-teams-reboot-admin>
      </div>

      <div class="panel-body" *ngIf="popupType === 68" title="Logitech Update">
        <div class="panel-heading">Logitech Update</div>
        <app-logitech-firmware-update [logitechData]="data"> </app-logitech-firmware-update>
      </div>

      <div class="panel-body" *ngIf="popupType === 69" title="Teams Network Performance">
        <div class="panel-heading">Teams Network Performance</div>
        <app-chart-teams-dna [deviceId]="deviceId" [data]="data" [popupState]="popupTypeState"></app-chart-teams-dna>
      </div>

      <div class="panel-body" *ngIf="popupType === 70">
        <div class="panel-heading">{{data.headerTitle}}</div>
        <app-teams-room-device-list-popup [data]="data"></app-teams-room-device-list-popup>
      </div>

      <div class="panel-body" *ngIf="popupType === 71">
        <div class="panel-heading">Teams Incidents (Last 30 Days)</div>
        <app-teams-incident [popData]="data" [popupState]="popupTypeState"></app-teams-incident>
      </div>

      <div class="panel-body" *ngIf="popupType == 72" title="Teams Reboot">
        <div class="panel-heading">Teams Reboot</div>
        <app-cloud-user-reboot [data]="data"></app-cloud-user-reboot>
      </div>

      <div class="panel-body" *ngIf="popupType == 73" title="Reboot Cloud">
        <div class="panel-heading">Reboot Cloud</div>
        <app-cloud-reboot-plug-in-button [checkTeamsRoom]="true" [popupState]="popupTypeState" [pluginId]="data">
        </app-cloud-reboot-plug-in-button>
      </div>

      <div class="panel-body" *ngIf="popupType == 74" title="IoT Device Incidents">
        <div class="panel-heading">IoT Device Incidents </div>
        <app-iot-lite-device-health-history [data]="data" [popupState]="popupTypeState">
        </app-iot-lite-device-health-history>
      </div>

      <div class="panel-body" *ngIf="popupType == 75">
        <div class="panel-heading">IoT Admin</div>
        <app-iot-lite-admin [iotDeviceDetails]="data" [oldPorts]="oldPorts"></app-iot-lite-admin>
      </div>

      <div class="panel-body" *ngIf="popupType === 76">
        <div class="panel-heading">Teams Room Incidents (Last 30 Days)</div>
        <app-teams-room-incident [popData]="data" [popupState]="popupTypeState"></app-teams-room-incident>
      </div>


      <div class="panel-body" *ngIf="popupType === 77">
        <div class="panel-heading">Wellness Check</div>
        <app-video-wellness-check [propDetails]="data"></app-video-wellness-check>
      </div>


      <div class="panel-body" *ngIf="popupType === 78">
        <div class="panel-heading">Teams Call History </div>
        <app-teams-call-records [deviceId]="deviceId" [data]="data"></app-teams-call-records>
      </div>


      <div class="panel-body" *ngIf="popupType === 79">
        <div class="panel-heading">Iot Ping Chart </div>
        <app-iot-lite-device-chart [data]="data" [popupState]="popupTypeState">
        </app-iot-lite-device-chart>
      </div>

      <div class="panel-body" *ngIf="popupType === 80">
        <div class="panel-heading">Collector Incident List</div>
        <app-collector-incident-list [data]="data"></app-collector-incident-list>
      </div>

      <div class="panel-body" *ngIf="popupType === 81" title="">
        <div class="panel-heading">Collector Admin</div>
        <app-collector-admin [data]="data"></app-collector-admin>
      </div>

      <div class="panel-body" *ngIf="popupType === 82">
        <div class="panel-heading">Collector Reboot</div>
        <app-collector-reboot [data]="data"></app-collector-reboot>
      </div>

      <div class="panel-body" *ngIf="popupType === 83" title="Video Device Incidents">
        <div class="panel-heading">Video Device Incidents (Last 30 Days)</div>
        <app-video-logitech-incidents [data]="data" [popupState]="popupTypeState"></app-video-logitech-incidents>
      </div>

      <div class="panel-body" *ngIf="popupType === 84" title="Create Space">
        <div class="panel-heading">{{ data?.spaceToBeEdited? 'Update' : 'Create' }} Space</div>
        <app-add-space-domotz-dialog [data]="data"></app-add-space-domotz-dialog>
      </div>

    </div>
  </div>