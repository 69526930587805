import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimals'
})
export class TwoDecimalsPipe implements PipeTransform {

  transform(value: number): any {
    return value.toFixed(2);
  }

}
