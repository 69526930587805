import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GetIotService } from '../../../services/get-iot.service';
import { MatDialog } from '@angular/material/dialog';
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../../../iot/dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component';
import { environment } from '../../../../environments/environment';
import { NgrokProtocolComponent } from 'src/app/ngrok-protocol/ngrok-protocol.component';

@Component({
  selector: 'app-audio-all-devices-list-table',
  templateUrl: './audio-all-devices-list-table.component.html',
  styleUrls: ['./audio-all-devices-list-table.component.scss'],
})
export class AudioAllDevicesListTableComponent implements OnInit {
  @Input() audioDevicesListData: MatTableDataSource<any>;

  constructor(
    private router: Router,
    public Auth: AuthService,
    private GetIot: GetIotService,
    public dialog: MatDialog
  ) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? ['company', 'name', 'user', 'health', 'qos', 'site', 'oncall', 'model', 'ip']
      : ['name', 'user', 'health', 'qos', 'site', 'oncall', 'model', 'ip'];
  Math: any = Math;
  isDomotzEnabled: boolean = false;

  isShowDomotzIntegration = environment.config.domotzIntegration;


  ngOnInit(): void {
    if (this.isShowDomotzIntegration) {
      this.getDomotzAccountByCompanyId();
    }
  }

  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.isDomotzEnabled = enabled === 1;
      // // console.log('isDomotzEnabled: ', this.isDomotzEnabled);
    });
  };

  applyFilter(filterValue: string) {
    if (filterValue == '') this.audioDevicesListData.filter = '-'.trim().toLowerCase();
    else this.audioDevicesListData.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.audioDevicesListData.filter = '-'.trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.audioDevicesListData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'company': {
          return item.company_name.toLocaleLowerCase();
        }
        case 'name': {
          return item.room_name.toLocaleLowerCase();
        }
        case 'user': {
          return item.room_name.toLocaleLowerCase();
        }
        case 'health': {
          return item.health;
        }
        case 'qos': {
          return item.qos;
        }
        case 'site': {
          return item.site_name.toLocaleLowerCase();
        }
        case 'oncall': {
          return item.on_call;
        }
        case 'model': {
          return item.model.toLocaleLowerCase();
        }
        case 'ip': {
          return item.ipv4;
        }
        default:
          return item[property];
      }
    };

    this.audioDevicesListData.sort = this.sort;
  }

  clickRow(row) {
    this.router.navigate(['audio/site/', row.site_id, 'audiodevice', row.audio_device_id]);
  }

  openRemoteIp = async (element) => {
    const {ipv4, mac} =element;
    const url = `http://${ipv4}`;
    const iotDevice: any = await this.getIotDeviceIpAndMacMatch(ipv4, mac);
    if (this.isDomotzEnabled && this.checkIotIsMonitored(iotDevice)) {
      const iotDeviceId = iotDevice.iot_device_id;
      const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
        panelClass: 'custom-dialog-container',
        data: {
          iotDeviceId,
        },
      });
    } else {
      if (element?.is_remote_access === 1) {
        element.other_data=element.iot_components?this.getIotPort(element.iot_components,element.ipv4):null;
        this.dialog.open(NgrokProtocolComponent, {
          data: element,
        });
      } else {
        //old code
        window.open(url);
      }
    }
  };

  getIotPort(iot_components,ip){
    const iots = typeof iot_components === 'string' ? JSON.parse(iot_components) : [...iot_components];
    return iots.find((x)=>x.iot_ip == ip)?.other_data;
  }

  checkIotIsMonitored = (iot) => {
    if (iot) {
      return iot.is_monitored === 1;
    }
    return false;
  };

  getIotDeviceIpAndMacMatch = async (ip, mac) => {
    return new Promise((resolve) => {
      this.GetIot.getIotDeviceIpAndMacMatch(ip, mac).subscribe((data: any) => {
        const [response] = data;
        resolve(response);
      });
    });
  };
}
