import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pexip-dna',
  templateUrl: './pexip-dna.component.html',
  styleUrls: ['./pexip-dna.component.scss']
})
export class PexipDnaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
