import { UserIdleService } from 'angular-user-idle'
import moment from 'moment-timezone'
import phone from 'phone'
import { of, Subscription } from 'rxjs'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../environments/environment'
import { AlertService } from '../_alert'
import { UserIdleDialogComponent } from '../dialogs/user-idle-dialog/user-idle-dialog.component'
import { IntegratorService } from '../services/integrator.service'
import { GetCompanyService } from './get-company.service'

interface myData {
  response: {
    token: string;
    error: string;
  };
}

interface statusMessagerData {
  response: {
    status: string;
    message: string;
  };
}

interface newDeviceData {
  response: {
    status: string;
    message: string;
    action_id: number;
  };
}

@Injectable()
export class Globals {
  role: string = 'test';
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  shouldShowCreateNewAccount: boolean = true;
  getUrlPath: Function = environment.apiServicePathBuilder('zoom');
  get2faUrlPath: Function = environment.apiServicePathBuilder('two-factor');
	getUrlPathSSO: Function = environment.apiServicePathBuilder('ssogam')
  
  server_url: string = environment.serverUrl;
  logo: string = '';
  companyName: string = '';
  firstName: string = '';
  runTutorial: any;
  accessLevel: any;
  companyId: any;
  userId: any;
  authOption: any = '';
  user: any;
  profilePicture: string = '';
  timezone: string = moment.tz.guess();

  timerCountBehaviorSubject: BehaviorSubject<number> = new BehaviorSubject(0)
  userIdleSubscription1: Subscription;
  userIdleSubscription2: Subscription;

  private companyInfo;
  decodedToken;
  isTrialExpired: boolean;

  constructor(
    private http: HttpClient,
    private integrator: IntegratorService,
    private companyService: GetCompanyService,
    private router: Router,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    private alertService: AlertService
  ) {
    if (!this.decodedToken)
      this.setDecodedToken()
    if (this.isAuthenticated()) {
      this.checkForUserInactivity()
    }
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return !helper.isTokenExpired(token);
  }

  private handleError(error: Response | any) {
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  getCompanyId = () => {
    return this.decodedToken?.company_id
    // const token = localStorage.getItem('token');
    // const helper = new JwtHelperService();
    // const decodedToken = helper.decodeToken(token);
    // return decodedToken.company_id;
  };

  getUserId = () => {
    return this.decodedToken?.user_id
    // const token = localStorage.getItem('token');
    // const helper = new JwtHelperService();
    // const decodedToken = helper.decodeToken(token);
    // return decodedToken.user_id;
  };

  async sendVerificationCode(serviceId, to, channel = 'email') {
    const url = this.get2faUrlPath(`sendVerificationCode`);
    const body = {
      service_id: serviceId,
      to: to,
      channel: channel,
    };
    const response = await new Promise((resolve, reject) => {
      return this.http
        .post<any>(url, body, this.httpOptions)
        .subscribe((data) => {
          resolve(data);
        });
    });
    return response;
  }

  async checkVerificationCode(serviceId, to, code) {
    const url = this.get2faUrlPath(`checkVerificationCode`);
    const body = {
      service_id: serviceId,
      to: to,
      code: code,
    };
    const response = await new Promise((resolve, reject) => {
      return this.http
        .post<any>(url, body, this.httpOptions)
        .subscribe((data) => {
          resolve(data);
        });
    });
    return response;
  }

  getUserDetails(usernamebody, passwordbody) {
    // never used
    // const user = 'visibility';
    // const pass = '07c6141ddbc53242bf413fb2427e5552';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({
      username: usernamebody,
      password: passwordbody,
    });
    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/user/login';
    return this.http.post<myData>(url, body, { headers: headers }).pipe(map((data) => {
      this.checkForUserInactivity()
      return this.onSuccessfullLogin(data)
    }))
  }

  onSuccessfullLogin(data: any) {
    localStorage.setItem('token', data.response.token)
    if (data.response[0] === undefined) {
      // localStorage.setItem('token', data.response.token)
      const token = data.response.token
      const helper = new JwtHelperService()
      this.decodedToken = helper.decodeToken(token)
      this.authOption = this.decodedToken.two_factor_auth_option
      // const expirationDate = helper.getTokenExpirationDate(token)
      const isExpired = helper.isTokenExpired(token)
      if (!isExpired) {
        localStorage.setItem('retryAttempts', '0')
        localStorage.setItem('phone', this.decodedToken.phone)
        if (this.decodedToken.enable_two_factor_auth) {
          localStorage.setItem('verified', 'false')
          const phoneLib = phone(this.decodedToken.phone, {
            validateMobilePrefix: true,
          })
          if (this.decodedToken.two_factor_auth_option === 'sms' && !phoneLib.isValid) {
            this.router.navigate(['/add-phone-number'])
          } else {
            localStorage.setItem('verified', 'false')
            const sendAddress =
              this.decodedToken.two_factor_auth_option === 'email' ? this.decodedToken.email : this.decodedToken.phone
            this.router.navigate(['/two-factor-auth'])
            this.sendVerificationCode(
              environment.config.twilioServiceId,
              sendAddress,
              this.decodedToken.two_factor_auth_option
            )
          }
        } else {
          localStorage.setItem('verified', 'true')
          // localStorage.setItem('token', data.response.token)
          this.router.navigate(['/'])
        }
      }
    } else {
      if (data.response[0].status === 'Error') {
        // this.setMessageForLogin(data.response[0], true)
        throw new Error(data.response[0]?.message)
      } else {
        // ? axe
        localStorage.setItem('successReg', '0')
      }
    }
  }

  loginTokenUser(userToken) {
    // never used
    // const user = 'visibility';
    // const pass = '07c6141ddbc53242bf413fb2427e5552';

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({ token: userToken });
    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/user/loginToken';

    // console.log('loginTokenUser:', url)

    return this.http.post<myData>(url, body, { headers: headers }).pipe(
			map(resp => {
				this.checkForUserInactivity()
				return resp
			})
		)
  }

  loginTokenAdmin(userToken, loginType: string = null) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({ token: userToken, loginType });

    const url = this.server_url + '/api/v1/user/loginTokenAdmin';
    return this.http.post<myData>(url, body, { headers: headers }).pipe(
			map(resp => {
				this.checkForUserInactivity()
				return resp
			})
		)
  }

  loginDirectAccess(userToken, type: string = null) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({ token: userToken, type });
    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/user/loginTokenDirectAccess';
    return this.http.post<myData>(url, body, { headers: headers }).pipe(
			map(resp => {
				this.checkForUserInactivity()
				return resp
			})
		)
  }

  logginOut(message: string) {
    this.messageSource.next(message);
  }

  //This function send user a Reset Password email
  sendPasswordReset(userEmail: any): Observable<Object> {
    let postData = {
      email: userEmail,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/user/sendPasswordReset',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // return this.http.post<any>( 'http://127.0.01:8080/api/v1/user/sendPasswordReset', postData, this.httpOptions).map(this.extractData)
    // .catch(this.handleError)
  }

  //This function gets info from reset password code
  getResetCodeInfo(resetCode: any): Observable<Object> {
    let postData = {
      code: resetCode,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/user/getPasswordResetInfo',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // return this.http.post<any>( 'http://127.0.01:8080/api/v1/user/getPasswordResetInfo', postData, this.httpOptions).map(this.extractData)
    // .catch(this.handleError)
  }

  //This function send user a Reset Password email
  changePassword(resetCode: any): Observable<Object> {
    let postData = {
      ...resetCode,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/user/resetPassword',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // return this.http.post<any>( 'http://127.0.01:8080/api/v1/user/resetPassword', postData, this.httpOptions).map(this.extractData)
    // .catch(this.handleError)
  }

  //Creates VsOne Admin User - Company registration
  createVSOneUser(userDetails: any) {
    return this.http.post<statusMessagerData>(
      this.server_url + '/api/v1/user/registration',
      userDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This routes creates New Site for a company
  createNewSite(siteDetails: any) {
    return this.http.post<statusMessagerData>(
      this.server_url + '/api/v1/company/newSite',
      siteDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route verifies the collector pin information
  verifyCollector(siteDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/verify',
      siteDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user contact list for new site registration
  getContactList(userDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/getContactList',
      userDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  fetchContactList(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token') as any;
    const helper = new JwtHelperService();
    //
    // const decodedToken = helper.decodeToken(token);
    // const companyId = decodedToken.company_id;
    // const userId = decodedToken.user_id;

    let postData = {
			company_id: this.decodedToken?.company_id,
		}

    return this.http
      .post<any>(
        this.server_url + '/api/v1/user/getContactList',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // .catch(this.handleError);
  }

  // This route gets all the sites for a company - used for the add devices forms
  getSiteList(): Observable<Object> {
    //Grabs Token from Local Storage

    let postData = {
      company_id: this.getCompanyId(),
    };
    return this.http
      .post<any>(
        this.server_url + '/api/v1/company/getAllSites',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // .catch(this.handleError);
  }

  // This route adds a Video device to the database and collector
  findDevice = (deviceDetails: any) => {
    const company_id = this.getCompanyId();
    const user_id = this.getUserId();

    const params = {
      ...deviceDetails,
      company_id,
      user_id,
    };

    const url =
      deviceDetails.type === 'audio'
        ? '/api/v1/audio/testAudioDevice'
        : '/api/v1/video/testVideoDevice';

    console.log('findDevice: ', params);

    return this.http.post<newDeviceData>(
      this.server_url + url,
      params,
      this.httpOptions
    );
  };

  testDeviceIp(deviceDetails: any) {
    const testDeviceUrl =
      deviceDetails.htype === 'audio'
        ? '/api/v1/audio/testDeviceIp'
        : '/api/v1/video/testDeviceIp';
    return this.http.post<newDeviceData>(
      this.server_url + testDeviceUrl,
      deviceDetails,
      this.httpOptions
    );
  }

  updateDeviceIp(deviceDetails: any) {
    const testDeviceUrl =
      deviceDetails.htype === 'audio'
        ? '/api/v1/audio/updateDeviceIp'
        : '/api/v1/video/updateDeviceIp';
    return this.http.post<newDeviceData>(
      this.server_url + testDeviceUrl,
      deviceDetails,
      this.httpOptions
    );
  }

  // This route gets the action id for the added device
  postDeviceActionId(deviceDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getAction',
      // 'http://localhost:8080/api/v1/collector/getAction',
      deviceDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets all the sites for a company - used for the add devices forms
  getAllSiteList() {
    const company_id = this.integrator.isIntegrator()
      ? this.getCompanyId() * -1
      : this.getCompanyId();

    return this.http.post<any>(
      this.server_url + '/api/v1/company/getAllVerifiedSites',
      { company_id },
      this.httpOptions
    );
    // return this.http.post<any>(this.server_url + '/api/v1/company/getAllSites', company_id, this.httpOptions)
    // .catch(this.handleError);
  }

  // This route gets all the countries for a company
  getAllCountryList() {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getCountriesList',
      this.httpOptions
    );
  }

  // This route gets all the countries for a company
  getAllStatesList(country_id: any) {
    let postData = {
      country_id: country_id,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getStatesList',
      postData,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route send the added device information to the database
  sendAddDeviceData(deviceDetails: any) {
    const company_id = this.getCompanyId();

    const params = {
      ...deviceDetails,
      company_id,
    };

    console.log('params: ', params)

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/addDevice',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  sendUpdateDeviceData(action_id: any, type: any) {
  
  const company_id = this.getCompanyId();

    const params = {
      action_id,
      type,
      company_id
    };
    
    
    console.log(params)
    
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/updateDevice',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route send the added device information to the database
  getAllDeviceList(deviceDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getDevicesByType',
      deviceDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route get device information for a site
  getAllDeviceBySiteList(site_id: any) {

    const params = {
      site_id,
      company_id: this.getCompanyId()

    }

    return this.http.post<any>(
      this.server_url + '/api/v1/video/getDevicesBySite',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getIotSpaceListbySite(site_id: any) {

    const params = {
      site_id,
      company_id: this.getCompanyId()

    }

    return this.http.post<any>(
      this.server_url + '/api/v1/report/iot_get_space_list',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  getCloudListbySite(site_id: any) {

    const params = {
      site_id,
      company_id: this.getCompanyId()

    }

    return this.http.post<any>(
      this.server_url + '/api/v1/report/cloud_get_list',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route send the added device information to the database
  addNewVirtualSite(deviceDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/newVirtualSite',
      deviceDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route send the added device information to the database
  getAllPhysicalSites(deviceDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getAllPhysicalSites',
      deviceDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route send the added device information to the database
  getAllVideoDevices(deviceDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getAllDevices',
      deviceDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  setDecodedToken = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    this.decodedToken = helper.decodeToken(token);
  };

  fetchUser() {
    // const decodedToken = this.getDecodedToken()
    const userDetails = {
      company_id: this.decodedToken?.company_id,
      user_id: this.decodedToken?.user_id,
    }
    return this.http.post<any>(this.server_url + '/api/v1/user/getUserInfo', userDetails, this.httpOptions)
  }

  getUserInfo() {
    if (this.user)
      return of(this.user)
    else
      return this.fetchUser().pipe(map((data) => {
        this.user = data
        if (data.response[0]) {
          if (data.response[0].timezone) {
							this.timezone = data.response[0].timezone
						}
						
          this.user.response[0].timezone = this.timezone
        }
        return data
      }))
  }

  logout() {
    const { enabled, clientId, clientSecret, redirectURI, GAMBaseUri } = environment.config['SSO'] || {}
    
    const GAMToken = localStorage.getItem('GAMToken')
    const GAMState = localStorage.getItem('ssoGAMLoginState')
    if (enabled && GAMToken) {
			// const body = {
			//   GAM_token: GAMToken,
			//   GAM_state: GAMState
			// }
			// const url: string = this.getUrlPathSSO(`logoutGAM`)
			// this.http.post<any>(url, body).pipe(
			//   finalize(() => {
			//     // Code to execute when the HTTP request completes (whether success or error)
			//     this.clearStorageAndNavigate(true)
			//   })
			// ).subscribe(() => {})
      const params = new HttpParams()
        .set('oauth', 'signout')
        // .set('client_id', "IorwKqY0aCJLInBXUiLyBC1nEPzTWXYga6rW18wO")
        // .set('client_secret', "593b285a620840e1bfa2344e5e1870")
        // .set('redirect_uri', "https://cxdetect-dash.visibility.one/login")
        .set('client_id', clientId)
        .set('client_secret', clientSecret)
        .set('redirect_uri', redirectURI)
        .set('server_ip', 1)
        .set('token', GAMToken)
        .set('first_call', 1)
        .set('state', GAMState);
      
      console.log('GAM logout endpoint: ', `${GAMBaseUri}/signout?${params.toString()}`)
			// this.http
			// 	.get<any>(`${baseURL}/oauth/gam/signout`, { params })
			// 	.pipe(
			// 		finalize(() => {
			// 			// Code to execute when the HTTP request completes (whether success or error)
			// 			this.clearStorageAndNavigate(true)
			// 		})
			// 	)
      // 	.subscribe(() => {})
      this.clearStorageAndNavigate(false)
      window.location.href = `${GAMBaseUri}/signout?${params.toString()}`
		} else this.clearStorageAndNavigate(true)
  }

  clearStorageAndNavigate(shouldNavigate) {
    this.user = null;
    this.setCompany(null)
    this.userIdle.stopTimer()
    this.userIdle.stopWatching()
    this.userIdleSubscription1?.unsubscribe()
    this.userIdleSubscription2?.unsubscribe()
    localStorage.removeItem('token')
    localStorage.removeItem('GAMToken')
    localStorage.removeItem('GAMState')
    localStorage.removeItem('token')
    localStorage.removeItem('phone')
    localStorage.setItem('verified', 'false')
    localStorage.setItem('keyDownload', '0')
    localStorage.setItem('reloadSiteData', '0')
    localStorage.setItem('showCongrats', '0')
    localStorage.setItem('runTut', '0')
    localStorage.setItem('successReg', '0')
    localStorage.setItem('overLayMembersMove', '0')
    sessionStorage.removeItem('collectorOfflineNotification')
    sessionStorage.removeItem('teamsBanner')
    if (shouldNavigate) this.router.navigate(['login'])
  }

  // This route send the added device information to the database
  // getCompanyInfo(companyDetails?: any) {
  getCompanyInfo() {
    // return this.http.post<any>(
    //   this.server_url + '/api/v1/company/getCompanyInfo',
    //   companyDetails,
    //   this.httpOptions
    // );
    if (this.companyInfo) return of({response:[this.companyInfo]});
    // else return this.companyService.apiGetCompanyInfo().pipe(
    else return this.companyService.fetchCompany().pipe(
      map(resp => {
				// adding companyInfo check to ensure callTrialDate is called only ONCE, else it is called multiple times due to multile subscribers
				if (!this.companyInfo) {
					this.setCompany(resp?.response[0])
					this.calTrialDate()
				}
				return resp
			})
		)
    // .catch(this.handleError);
  }

  setCompany(data) {
    this.companyInfo = data
  }

  calTrialDate() {
    const loginType = this?.decodedToken.loginType
    if ((this.companyInfo?.status === 'TRIAL' || this.companyInfo?.status == 'INACTIVE') && loginType !== 'superAdmin') {
			let trialInfo = this.companyInfo?.trial_info
			let nowDate = moment().format()
			let nowDateM = moment(nowDate)
			let endTrailDate = moment(trialInfo.end_date)
			const trialEndDay = endTrailDate.diff(nowDateM, 'days')
			const options = {
				// autoClose: true,
				keepAfterRouteChange: false,
			}
			let message
      if (trialEndDay < 0) {
        message = 'Trial expired'
        this.isTrialExpired = true
      }
      else
        message=`Trial expires in ${trialEndDay} day(s)`
        
      this.alertService.error(message, options)
		}
  }

  // This route send the added device information to the database
  sendRegisterInvite(inviteDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/sendUserRegistration',
      inviteDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  sendRegisterInviteOtherDomain(inviteDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/sendUserRegistrationOtherDomain',
      inviteDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  resendRegisterInvite(inviteDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/resendUserRegistration',
      inviteDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  // This route send the added device information to the database
  registerUser(userDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/userRegistration',
      userDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user contact list for new site registration
  getUserList(userDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getUserList',
      userDetails,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user account Active
  setUserActive(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/setUserActive',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user account Inactive
  setUserInActive(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/setUserinActive',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route deletes the user account
  delUser(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/delUser',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user account Notify on
  setUserNotifyOn(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/enableUser',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user account Notify off
  setUserNotifyOff(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/disableUser',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user alert staus
  getUserAlertStatus(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/getAlertStatus',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  userSetAccountNotification(user_id, flag: any) {
    const params = {
      user_id,
      flag,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/user/userSetAccountNotification',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the comp device type alert monitor status
  getDeviceAlertMonitorStatus(cmpDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getDeviceTypeStatus',
      cmpDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the site  Notify on
  setSiteNotifyOn(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/enableSite',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the site  Notify off
  setSiteNotifyOff(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/disableSite',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  toggleSiteMonitor(sid, pauseState) {
    const siteData2 = {
      company_id: this.companyId,
      site_id: sid,
    };

    if (pauseState === 0)
      return this.setSiteMonitorOn(siteData2)
    else if (pauseState == 1)
      return this.setSiteMonitorOff(siteData2)
  }

  // This route gets the site monitor on
  setSiteMonitorOn(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/unpauseSite',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the site monitor off
  setSiteMonitorOff(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/pauseSite',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the deviceType  Notify on
  setDeviceTypeNotifyOn(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/enableDeviceType',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the deviceType  Notify off
  setDeviceTypeNotifyOff(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/disableDeviceType',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the deviceType monitor on
  setDeviceTypeMonitorOn(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/unpauseDeviceType',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the deviceType monitor off
  setDeviceTypeMonitorOff(userDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/pauseDeviceType',
      userDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user contact list for new site registration
  getUserRegistrationInfo(newRegUserDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/getUserRegistrationInfo',
      newRegUserDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the user contact list for new site registration
  activeUserRegistration(newRegUserDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/activateRegistration',
      newRegUserDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // // This route gets the user contact list for new site registration
  // getVideoDeviceSummary(company_id: any){
  // 	return this.http.post<any>(this.server_url + '/api/v1/video/getSummary', company_id, this.httpOptions)
  // 	// .catch(this.handleError);
  // }

  // This route gets the video device notes updated for dna pages
  updateNotes(notesDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/updateNotes',
      notesDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  updateAudioNotes(notesDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/updateNotes',
      notesDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device chart data on the video device page for Mbps
  getMbpsChartData(deviceDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getMbpsChartData',
      deviceDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device chart data on the video device page for packetloss
  getPacketlossChartData(deviceDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getPacketlossChartData',
      deviceDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device chart data on the video device page for all
  getAllChartData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getAllChartData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  /**
   * This method makes sure the api which does not round off the data is called
   * @param deviceInput
   * @returns
   */
  getAllChartDataNoRound(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getAllChartDataNoRound',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  getAllAudioDeviceChartData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/getAllChartData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getMosCharData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/getMosChartData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  getAllInsChartListDataNoRound(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getVideoChartRawDataNoRound',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  // This route gets the video device Inscidents chart List data on the video device page for all
  getAllInsChartListData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getVideoChartRawData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device Inscidents chart List data on the video device page for all
  getAllAudioChartListData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/getAudioChartRawData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getQosAllChartData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getQosChartData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  getQosAllChartRawData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getQosChartRawData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getZoomAllInsChartListData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getZoomChartRawData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  getZoomMbpsChartData(deviceDtls: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getMbpsChartData',
      deviceDtls,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device chart data on the video device page for all
  getZoomAllChartData(deviceInput: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getAllChartData',
      deviceInput,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device notes updated for dna pages
  updateProfile(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/updateInfo',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route gets the video device notes updated for dna pages
  updateUserProfile(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/updateInfo',
      data,
      this.httpOptions
    ).pipe(map((resp) => {
      this.user.response[0] = data.data
      this.timezone = data.data.timezone
      return resp
    }))
    // .catch(this.handleError);
  }

  async updateUserProfileAsync(data: any) {
    const response = await new Promise((resolve, reject) => {
      return this.http
        .post<any>(
          this.server_url + '/api/v1/user/updateInfo',
          data,
          this.httpOptions
        )
        .subscribe((data) => {
          resolve(data);
        });
    });
    return response;
  }
  // This route set the tutorial
  getTutorial(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/getTutorial',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the tutorial
  setTutorial(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/setTutorial',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the tutorial
  setTutorialDownload(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/setTutorialDownload',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports sites
  getTopFiveSite(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/top_five/site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getTopFiveHuddle(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/zoom/top_five/all_rooms',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  // This route set the reports device
  getTopFiveDevice(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/top_five/device',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports video_conferencing site
  getVideoConferSite(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/video_conferencing/site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports video_conferencing for staff all site
  getVideoConferAllSite = (data: any) => {

    const params = {
      ...data,
      company_id: this.getCompanyId()
    }

    return this.http.post<any>(
      this.server_url + '/api/v1/report/video_conferencing/site_all',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  
  getIotConferAllSite = (data: any) => {
    const params = {
      ...data,
      company_id: this.getCompanyId()
    }
    return this.http.post<any>(
      this.server_url + '/api/v1/report/iot_conferencing/site_all',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  getCloudConferAllSite = (data: any) => {
    const params = {
      ...data,
      company_id: this.getCompanyId()
    }
    return this.http.post<any>(
      this.server_url + '/api/v1/report/cloud_conferencing/site_all',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  

  getAudioConferAllSite = (data: any) => {
    const params = {
      ...data,
      company_id: this.getCompanyId()
    }
    return this.http.post<any>(
      this.server_url + '/api/v1/report/audio_conferencing/site_all',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  getConferAllSite = (data: any) => {

    const params = {
      ...data,
      company_id: this.getCompanyId()
    }

    return this.http.post<any>(
      this.server_url + '/api/v1/report/audio_conferencing/site_all',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getVideoConferAllHuddle(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/zoom/video_conferencing/all_rooms',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  getVideoConferSingleHuddle(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/zoom/video_conferencing/single_room',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  // This route set the reports video_conferencing for staff all site
  getVideoConferDevices(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/video_conferencing/single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getAudioConferDevices(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/audio_conferencing/single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  getIotConferDevices(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/iot_conferencing/single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  getCloudConferDevices(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/cloud_conferencing/single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports inventory device
  getInventoryDevice(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/inventory/device',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports incident device
  getIncidentList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports incident staff all sites
  getIncidentAllList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident_all',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  getAudioIncidentAllList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident_all_audio',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  

  genIotIncidentsAllList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident_all_iot',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }



  genCloudIncidentsAllList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident_all_cloud',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }



  


  getIncidentAllHuddles(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/zoom/incident/all_rooms',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  getIncidentSingleHuddles(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/zoom/incident/single_room',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports incident staff all devices
  getIncidentAllDeviceList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident/single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getIncidentAudioAllDeviceList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident/audio_single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getIncidentIotAllDeviceList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident/iot_single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getIncidentCloudAllDeviceList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/incident/cloud_single_site',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports site names for staff all sites
  getAllSiteNameList(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/site_names',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports site names for staff all sites
  genAllDeviceNames(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/device_names',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }



  

  // genAllIotDevic(data: any) {
  //   return this.http.post<any>(
  //     this.server_url + '/api/v1/report/iot_get_space_list',
  //     data,
  //     this.httpOptions
  //   );
  //   // .catch(this.handleError);
  // }



  genAudioAllDeviceName(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/audio_device_names',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }


  genIotAllDeviceName(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/iot_device_names',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  genCloudAllDeviceName(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/cloud_device_names',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  // This route set the reports average health
  getAverageHealth(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/report/avg_health',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  deactivateSite(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/deactivateSite',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  deactivateDevice(data: any) {
    const deactivateUrl =
      data.aType === 'video'
        ? '/api/v1/video/deactivateDevice'
        : '/api/v1/audio/deactivateDevice';
    return this.http.post<any>(
      this.server_url + deactivateUrl,
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getCollectorInfo(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getBySite',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  getOfflineCollector(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getOffline',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  updateAccesslevel(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/updateAccesslevel',
      data,
      this.httpOptions
    );
    // .catch(this.handleError);
  }
  companySetInactive(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/setInActive',
      data,
      this.httpOptions
    );
  }
  // This route set the reports incident device
  getDeviceIncidentList(data: any) {
    
    const endpointMap = {
      collector: 'getIncidentsBySiteId',
      logitech: 'getLogitechIncidents',
    }
    
    const explicitEndpoint = endpointMap[data.htype]

    const endpoint = explicitEndpoint ? explicitEndpoint : 'getDeviceIncidents'

    data.htype = data.htype === 'logitech' ? 'video' : data.htype

    const getIncidentsUrl = `/api/v1/${data.htype}/${endpoint}`;

    data.htype = undefined;

    return this.http.post<any>(
      this.server_url + getIncidentsUrl,
      data,
      this.httpOptions
    );
  }

  getVideoWellnessCheck = (data: any) => {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getVideoWellnessCheck',
      data,
      this.httpOptions
    );
  };

  getZoomIncidentList(zoom_user_id) {
    const url: string = this.getUrlPath(`getZoomIncidentList/${zoom_user_id}`);
    return this.http.get<any>(url);
  }

  getZoomIncident(incident_id) {
    const url: string = this.getUrlPath(`getZoomIncident/${incident_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData));
  }

  getDeviceCallLogs(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/getCallLogs',
      data,
      this.httpOptions
    );
  }
  getAudioDeviceCallLogs(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/getCallLogs',
      data,
      this.httpOptions
    );
  }
  getCalls60MinChartData(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/getCalls60MinChartData',
      data,
      this.httpOptions
    );
  }

  setDirectAccess(
    company_id: number,
    device_id: number,
    flag: number,
    type: string
  ) {
    const data = {
      company_id,
      device_id,
      flag,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/' + type + '/setDirectAccess',
      data,
      this.httpOptions
    );
  }

  setDirectAccessToken(id: number, company_id: number, type: string) {
    const data = {
      id,
      company_id,
      type,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/user/directAccess',
      data,
      this.httpOptions
    );
  }

  setDirecAccessSupportUser(data: any, type: string) {
    return this.http.post<any>(
      this.server_url + '/api/v1/' + type + '/setDirecAccessSupportUser',
      data,
      this.httpOptions
    );
  }

  getCollectorLatestVersion(type: string) {
    return this.http.post<any>(
      this.server_url + '/api/v1/' + type + '/getLatestVersion',
      this.httpOptions
    );
  }
  
  latestCollector(body: any) {
		return this.http.post<any>(this.server_url + '/api/v1/collector' + '/latest-collector', body, this.httpOptions)
	}
  setCollectorUpdateVersionSchedule(
    collector_id: number,
    collector_update_schedule: any
  ) {
    const data = {
      collector_id,
      collector_update_schedule,
    };
    // console.log(data);
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/setCollectorUpdateVersionSchedule',
      data,
      this.httpOptions
    );
  }

  collectorCheckScheduleUpdateByCompany(company_id) {
    const data = {
      company_id,
    };
    return this.http.post<any>(
      this.server_url +
        '/api/v1/collector/collectorCheckScheduleUpdateByCompany',
      data,
      this.httpOptions
    );
  }

  verifyIoT = (collector_id, quadrant_group, device_id, ip_address) => {
    // const token = localStorage.getItem('token');
    // const helper = new JwtHelperService();
    // const decodedToken = helper.decodeToken(token);
    const userId = this.decodedToken?.user_id;

    const postData = {
      collector_id,
      quadrant_group,
      device_id,
      ip_address,
      user_id: userId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/iot/verifyIot',
      postData,
      this.httpOptions
    );
  };

  addIot = (
    collector_id,
    device_id,
    quadrant_group,
    iot_type,
    iot_name,
    iot_ip,
    iot_status,
    iot_url_status,
    iot_url
  ) => {
    const postData = {
      collector_id,
      device_id,
      quadrant_group,
      iot_type,
      iot_name,
      iot_ip,
      iot_status,
      iot_url_status,
      iot_url,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/iot/add',
      postData,
      this.httpOptions
    );
  };

  deleteIot = (iot_id) => {
    const postData = {
      iot_id,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/iot/setInActive',
      postData,
      this.httpOptions
    );
  };

  getAllSitesDetailsCollector = (site_ids: number[]) => {
    const postData = { site_ids };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getAllSitesDetails',
      postData,
      this.httpOptions
    );
  };

  // TODO: Remove this method once the above method is in production
  getSiteDetailsCollector = (site_id: string) => {
    const postData = { site_id };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getSiteDetails',
      postData,
      this.httpOptions
    );
  };

  getSiteListCollector = () => {
    const company_id = this.integrator.isIntegrator()
      ? this.getCompanyId() * -1
      : this.getCompanyId();
    const postData = { company_id };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getSiteList',
      postData,
      this.httpOptions
    );
  };

  getSitesSummaryCollector = () => {
    const company_id = this.integrator.isIntegrator()
      ? this.getCompanyId() * -1
      : this.getCompanyId();
    const postData = { company_id };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getSitesSummary',
      postData,
      this.httpOptions
    );
  };

  getListCollector = () => {
    const company_id = this.integrator.isIntegrator()
      ? this.getCompanyId() * -1
      : this.getCompanyId();
    const postData = { company_id };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getList',
      postData,
      this.httpOptions
    );
  };

  getListPlugin = (filter_type) => {
    const company_id = this.integrator.isIntegrator()
      ? this.getCompanyId() * -1
      : this.getCompanyId();
    const postData = {
      company_id,
      filter_type,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/plugin/getList',
      postData,
      this.httpOptions
    );
  };
  // This route gets the list of plugins to be linked
  getListPluginToLink = () => {
    const company_id = this.getCompanyId();
    const postData = {
      company_id,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/plugin/getListToLink',
      postData,
      this.httpOptions
    );
  };
  // This route links the plugin to a room
  linkPluginToRoom = (plugin_id, zoom_user_id) => {
    const postData = {
      plugin_id,
      zoom_user_id,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/plugin/newLinkZoomPlugin',
      postData,
      this.httpOptions
    );
  };
  // This route unlinks the plugin to a room
  unlinkPluginToRoom = (plugin_id, service_type) => {
    // const company_id = this.getCompanyId();
    const postData = {
      plugin_id,
      service_type,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/plugin/newUnlinkZoomPlugin',
      postData,
      this.httpOptions
    );
  };

  deactivateRoomPlugin = (plugin_id) => {
    const company_id = this.getCompanyId();
    const postData = {
      company_id,
      plugin_id,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/plugin/deactivate',
      postData,
      this.httpOptions
    );
  };

  // IOT LITE
  getNmap = (collector_id, ip, flag, site_id) => {
    const postData = {
      ip,
      collector_id,
      flag,
      company_id: this.getCompanyId(),
      site_id,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getNmap',
      postData,
      this.httpOptions
    );
  };

  updateIntegrationInfo(data) {
    const company_id = this.getCompanyId();
    const body = JSON.stringify({ ...data, company_id });
    const url = this.server_url + '/api/v1/company/updateIntegrationInfo';
    return this.http.post<myData>(url, body, this.httpOptions);
  }
  
    updatePublicIntegrationInfo(data) {
    const company_id = this.getCompanyId();
    const body = JSON.stringify({ ...data, company_id });
    const url = this.server_url + '/api/v1/company/updatePublicIntegrationInfo';
    return this.http.post<myData>(url, body, this.httpOptions);
  }

  getIntegrationInfo() {
    const company_id = this.getCompanyId();
    const url =
      this.server_url +
      '/api/v1/company/integrationInfo?company_id=' +
      company_id;
    return this.http.get<myData>(url, this.httpOptions);
  }

  //WELLNESS

  // getSiteWellnessSchedule = (site_id) => {
  //   const company_id = this.getCompanyId();
  //   const body = JSON.stringify({...data , company_id });
  //   const url = this.server_url + '/api/v1/collector/getSiteWellnessSchedule';
  //   return this.http.post<myData>(url, body, this.httpOptions);
  // }

  getSiteWellnessSchedule = (site_id) => {
    const postData = {
      site_id,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getSiteWellnessSchedule',
      postData,
      this.httpOptions
    );
  };

  getVideoWellnessDetails = (device_id) => {
    const postData = {
      device_id,
      company_id: this.getCompanyId(),
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/getVideoWellnessDetails',
      postData,
      this.httpOptions
    );
  };

  setVideoWellnessCheck = (
    device_id,
    wellness_schedule,
    interval_schedule,
    wellness_week,
    wellness_month,
    enabled,
    allow_site_overwrite
  ) => {
    const postData = {
      device_id,
      company_id: this.getCompanyId(),
      wellness_schedule,
      interval_schedule,
      wellness_week,
       wellness_month,
      enabled,
      allow_site_overwrite
    };

    console.log('setVideoWellnessCheck: ', postData);

    return this.http.post<any>(
      this.server_url + '/api/v1/video/setVideoWellnessCheck',
      postData,
      this.httpOptions
    );
  };
  
   // SSO 
   
   	isGamEnabled = () => {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPathSSO(`isGamEnabled?company_id=${company_id}`)
		
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

   

  checkForUserInactivity() {
		//Start watching for user inactivity.
		this.userIdle.startWatching()

    if (!this.userIdleSubscription1 || this.userIdleSubscription1?.closed) {
      // Start watching when user idle is starting.
      this.userIdleSubscription1 = this.userIdle.onTimerStart().subscribe(count => {
        // console.log('count', count)
        this.timerCountBehaviorSubject.next(count)
        if (count === 1) this.openUserIdleModal()
      })
    }
  
    if (!this.userIdleSubscription2 || this.userIdleSubscription2?.closed) {
      // Start watch when time is up.
      this.userIdleSubscription2 = this.userIdle.onTimeout().subscribe(() => {
        this.userIdle.stopTimer()
        this.logout()
        this.dialog.closeAll()
      })
    }
  }
  
  openUserIdleModal = () => {
    // const config = {};
    const dialog = this.dialog.open(UserIdleDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        // config,
      },
    });
  };

  setLogitechAlerts(alerts, video_device_id) {
    const body = { alerts, video_device_id };
    const url = this.server_url + '/api/v1/video/setLogitechAlerts'
    return this.http.post(url, body, this.httpOptions);
  }
}
