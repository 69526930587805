<div class="polycom-add-device-dialog">
    <div class="polycom-add-device-dialog__header">
        <div>
            ADD DEVICE DIALOG
        </div>
        <div (click)="closeDialog()" class="polycom-add-device-dialog__header-close-button">
            <img src="{{closeButtonImage}}" alt="" />
        </div>
    </div>

    <div class="polycom-add-device-dialog__container">
        <div class="iot-lite-add-device__right-column">
            <div *ngIf="showMsg30Sec && !showErrMsg">
                <p style="font-size: larger;">It’s taking longer than expected, but we’ll get there as fast as we
                    can...</p>
            </div>
            <div *ngIf="showErrMsg" class="polycom-add-device-dialog__error-message">
                <h3>{{errorMsg}}</h3>
            </div>

            <div *ngIf="showSearching" class="polycom-add-device-dialog__right-column-search">
                <ul class="bottom">
                    <li>
                        <h3>Searching</h3>
                    </li>
                    <li>
                        <div class="dot1"></div>
                    </li>
                    <li>
                        <div class="dot2"></div>
                    </li>
                    <li>
                        <div class="dot3"></div>
                    </li>
                </ul>
            </div>

            <div *ngIf="showSearching" style="margin-left: 15px;">
                Note: Please avoid clicking outside dialog box to continue searching for device.
            </div>

            <div *ngIf="showErrorMessage" class="polycom-add-device-dialog__error-message">
                <h3>{{errorMessage}}</h3>
            </div>

            <div *ngIf="showSuccessMsg" class="polycom-add-device-dialog__success-message">
                <h3>{{successMessage}}</h3>
            </div>

            <!-- <div *ngIf="showAddDevice"
                class="polycom-add-device-dialog__device-details-and-table-container"> -->


            <div *ngIf="showAddDevice" class="polycom-add-device-dialog__device-details-and-table-container">
                <div class="polycom-add-device-dialog__device-details-container" style="display: flex">
                    <div>
                        <h3>Device Details</h3>
                        <div class="polycom-add-device-dialog__device-details-text">
                            <p>Room Name: {{deviceRoomName | displayEmpty }}</p>
                            <p>Hostname: {{deviceName | displayEmpty }}</p>
                            <p>Model: {{deviceModel | displayEmpty}}</p>
                            <p>Serial Number: {{serialNumber | displayEmpty}} </p>
                            <p>IPV4: {{deviceIP | displayEmpty}}</p>
                            <p>MAC: {{deviceMac | displayEmpty}}</p>
                            <p>Firmware: {{firmware | displayEmpty}}</p>
                            <p>Active Tap: {{activeTap | displayEmpty}}</p>
                            <p>Active Remote: {{activeRemote | displayEmpty}}</p>
                        </div>

                        <div *ngIf="!iotIp && !iotDevicePorts" class="polycom-add-device-dialog__btn2">
                            <button (click)="addDevice()">Add Device</button>
                            <button (click)="closeDialog()"
                                class="polycom-add-device-dialog__btn2--cancel">Cancel</button>
                        </div>
                    </div>

                    <div class="polycom-add-device-dialog__img-container">
                        <img src="../../../assets/img/icon_llama.png" />
                    </div>
                </div>
            </div>

            <div [ngClass]="{'polycom-add-device-dialog__iot-column-search-container': iotDevicePorts }">
                <!-- <hr> -->
                <!-- iot -->
                <!-- <div *ngIf="showSearchingIot" class="polycom-add-device-dialog__right-column-search"> -->
                <div *ngIf="showSearchingIot" class="polycom-add-device-dialog__iot-column-search">
                    <ul class="bottom">
                        <li>
                            <h4>Searching For Ports</h4>
                        </li>
                        <li>
                            <div class="dot1"></div>
                        </li>
                        <li>
                            <div class="dot2"></div>
                        </li>
                        <li>
                            <div class="dot3"></div>
                        </li>
                    </ul>
                </div>

                <!-- <div *ngIf="showSearchingIot" style="margin-left: 15px;"> -->
                <div *ngIf="showSearchingIot" style="margin-left: 15px;">
                    Note: Please avoid clicking outside dialog box to continue searching for device.
                </div>

                <div *ngIf="showErrorMessageIot" class="polycom-add-device-dialog__error-message">
                    <h3>{{errorMessageIot}}</h3>
                </div>


            </div>

            <div *ngIf="showAddDeviceIot" class="polycom-add-device-dialog__device-details-and-table-container">
                <div class="iot-lite-add-device-with-ports-dialog__table-container">
                    <table class="table">
                        <thead>
                            <tr></tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of devicePorts;let i = index">
                                <td>
                                    <input class="iot-lite-add-device__input form-control"
                                        [(ngModel)]="item.devicePortRename" type="text" placeholder="Rename Port" />
                                <td>
                                    <div>
                                        {{ item.portname}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ item.portid}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input type="checkbox" [checked]="item.is_monitored === 1"
                                            (change)="onChangeMonitoring($event,item)">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin: 10px 0 0 40px;">
                        <h6>Note: Special characters and spaces are not allowed. You can use "_" ex. port_name
                        </h6>
                    </div>
                </div>
                <div *ngIf="devicePorts.length" class="polycom-add-device-dialog__btn2">
                    <button (click)="addDeviceAndLink()">Add Device & Ports</button>
                    <button (click)="closeDialog()" class="polycom-add-device-dialog__btn2--cancel">Cancel</button>
                </div>
            </div>

        </div>
    </div>

</div>