<div class="all-sites-collectors">
  <div style="overflow: auto; height: 215px;">
    <table class="table table-stripped">
      <thead>
        <tr class="black">
          <th scope="col">Site Type</th>
          <th scope="col">Site name</th>
          <th scope="col">Host IP</th>
          <th scope="col">{{ dCollector }} Status</th>
          <th scope="col">Video Devices</th>
          <th scope="col">Audio Devices</th>
          <th scope="col">Cloud Rooms</th>
          <!-- <th scope="col">Cloud Users</th> -->
          <th scope="col">IoT Devices</th>
          <th *ngIf="showWellnessInProd" scope="col">Wellness Check Schedule</th>
          <th scope="col">Alerts</th>
          <!-- <th scope="col">Site Monitoring</th>             hide site monitoring as per Frankie -->
          <th scope="col">
            <div *ngIf="isShowCollectorEdit">Edit</div>
          </th>
          <th *ngIf="isShowCollectorEdit" scope="col">
            <div>Delete</div>
          </th>
          <th *ngIf="isShowCollectorEdit && showUnVerified" scope="col">
            <div>
              Verify
            </div>
          </th>
          <th *ngIf="isShowCollectorEdit && showUpdateCenter" scope="col">
            <div>
              Updates
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="">
        <tr scope="row" *ngFor="let item of siteList | mainMenuFilter: searchKey:'collector'">
          <td colspan="1">
            <img src="{{ getSiteTypeIcon(item.site_type === 0) }}" style="height: 30px;" />
          </td>

          <td>
            <div [ngClass]="{
                'all-sites-collectors__link ': siteNameConditions(item),
                'all-sites-collectors__no-event': !siteNameConditions(item)
              }" (click)="toggleCollectorInfo(item.site_id)">
              {{ item.site_name }}
            </div>
          </td>

          <td>
            {{ item.ip_address }}
          </td>

          <td>
            <div class="all-sites-collectors__status {{ getCollectorStatusClass(item) }}"></div>
          </td>

          <td>
            {{ siteDetailsLoading ? '...' : item.video_device_count }}
          </td>

          <td>
            {{ siteDetailsLoading ? '...' : item.audio_device_count }}
          </td>

          <td>
            {{ siteDetailsLoading ? '...' : item.cloud_rooms_count }}
          </td>

          <!-- <td>
            {{ item.cloud_users_count }}
          </td> -->

          <!-- <td>
            {{
            siteDetailsLoading ? '...' :
            !domotzEnabled
            ? calculateIotDeviceCount(
            item.iot_lite_audio_count,
            item.iot_lite_cloud_count,
            item.iot_lite_video_count
            )
            : calculateIotDeviceCount(
            item.iot_domotz_audio_count,
            item.iot_domotz_cloud_count,
            item.iot_domotz_video_count
            )
            }}
          </td> -->
          <td>
            {{
            siteDetailsLoading ? '...' :
            !domotzEnabled
            ? item.iot_lite_count
            : calculateIotDeviceCount(
            item.iot_domotz_audio_count,
            item.iot_domotz_cloud_count,
            item.iot_domotz_video_count
            )
            }}
          </td>

          <td *ngIf="showWellnessInProd">
            {{item?.wellness_check_time | displayEmpty }}
          </td>

          <td *ngIf="isShowCollectorEdit">
            <div>
              <label class="switch">
                <input #inputToggleSiteAlerts type="checkbox"
                  (change)="toggleSiteAlerts(inputToggleSiteAlerts.checked, item.site_id)"
                  [checked]="item.collector_alerts" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>

          <td *ngIf="isShowCollectorEdit">
            <div class="fa fa-edit fa-lg" (click)="
                ds.sendPopupType(34, {
                  data: {
                    siteId: item.site_id,
                    styleProp: 'signup1'
                  }
                })
              "></div>
          </td>

          <td *ngIf="isShowCollectorEdit">
            <div class="fa fa-trash fa-lg" (click)="openDeleteModal(item)"></div>
          </td>

          <td *ngIf="showUnVerified">
            <div *ngIf="isShowCollectorEdit">
              <div *ngIf="item.verified !== 1" class="all-sites-collectors__verify" (click)="sendData(item.site_id, 1)">
                Verify
              </div>
            </div>
          </td>

          <td *ngIf="showUpdateCenter">
            <div class="new-update" *ngIf="checkCollectorVersionThatNeedUpdates(item)" (click)="
                openNewUpdateModal(
                  item.collector_id,
                  item.site_name,
                  item.collector_update_schedule
                )
              ">
              <i class="fa fa-circle-o-notch"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>
    <table>
      <!-- <thead>
        <tr class="black" style="opacity: 0;">
          <th scope="col">Site Type</th>
          <th scope="col">Site name</th>
          <th scope="col">Host Ip</th>
          <th scope="col">Collector Status</th>
          <th scope="col">Video Devices</th>
          <th scope="col">Audio Devices</th>
          <th scope="col">Cloud Rooms</th>
          <th scope="col">Cloud Users</th>
          <th scope="col">IoT Devices</th>
          <th scope="col">Alerts</th>
          <th scope="col">Site Monitoring</th>
          <th scope="col">
            <div *ngIf="isShowCollectorEdit">Edit</div>
          </th>
          <th scope="col">
            <div *ngIf="isShowCollectorEdit"></div>
          </th>
          <th scope="col">
            <div *ngIf="showUpdateCenter">
              Updates
            </div>
          </th>
        </tr>
      </thead>
      <tbody class=""></tbody> -->

      <tr class="bottom">
        <td colspan="4">
          <div class="all-sites-collectors__un-assigned">
            Total # of un-assigned devices & services
          </div>
        </td>
        <td>{{ siteSummaryLoading ? '...' : (siteSummary?.unassigend_video_devices || '0')}}</td>
        <td>{{ siteSummaryLoading ? '...' : (siteSummary?.unassigend_audio_devices || '0') }}</td>
        <td>
          {{ siteSummaryLoading ? '...' : ((siteSummary?.total_unassigned_zoom_rooms +
          siteSummary?.total_unassigned_teams_rooms ) || '0') }}
        </td>
        <!-- <td>{{ siteSummary.total_unassigned_zoom_users }}</td> -->
        <td>
          {{
          siteSummaryLoading ? '...' :
          (domotzEnabled
          ? siteSummary?.total_unassigned_iot_domotz || '0'
          : siteSummary?.total_unassigend_iot_lite || '0')
          }}
        </td>
        <td *ngIf="isShowCollectorEdit && showUnVerified"></td>
        <td *ngIf="showUpdateCenter && showUpdateCenter"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="bottom2">
        <td colspan="4">
          <div class="all-sites-collectors__un-assigned-2">
            Total # of all assigned & un-assigned devices & services
          </div>
        </td>
        <td>{{ siteSummaryLoading ? '...' : (siteSummary?.total_video_devices || '0') }}</td>
        <td>{{ siteSummaryLoading ? '...' : (siteSummary?.total_audio_devices || '0')}}</td>
        <td>{{ siteSummaryLoading ? '...' : (siteSummary?.total_zoom_rooms || '0') }}</td>
        <!-- <td>{{ siteSummary.total_zoom_users }}</td> -->
        <td>{{ siteSummaryLoading ? '...' : (domotzEnabled ? siteSummary?.total_iot_domotz || '0' :
          siteSummary?.total_iot_lite || '0') }}</td>
        <td *ngIf="isShowCollectorEdit && showUnVerified"></td>
        <td *ngIf="showUpdateCenter && showUpdateCenter"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>