<div class="iot-all-spaces-panel">
  <div class="iot-all-spaces-panel__header">
    <app-panel-tools [PopupTitle]="summaryTitle"></app-panel-tools>
  </div>
  <div class="iot-all-spaces-panel__body">
    <div class="iot-all-spaces-panel__body_info">
      <app-info-details [siteInformation]="companyInfo"></app-info-details>
    </div>
    <div class="iot-all-spaces-panel__body_stats_health">
      <app-system-health [isCursorPointer]="true" [healthNum]="testHealth"
        [showBlackPointer]="true"></app-system-health>
      <app-iot-summary-stats [agentSummaryList]="agentSummaryList" [showIotLiteSite]="iotLiteEnabled"
        [isSpacesEnabled]="true" (summaryEvent)="onSummaryEvent($event)">
      </app-iot-summary-stats>
    </div>
  </div>
  <div class="iot-all-spaces-panel__table">
    <app-iot-space-summary-list *ngIf="showAllSpacesList" [filterRoute]="filterRoute"
      [iotLiteEnabled]="iotLiteEnabled"></app-iot-space-summary-list>

    <app-iot-space-panel *ngIf="!showAllSpacesList" [filterStatus]="filterStatus"
      [iotLiteEnabled]="iotLiteEnabled"></app-iot-space-panel>

  </div>
</div>