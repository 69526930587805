<div class="row">
  <div class="col-12 pl-1">
    <h5>Live Call Stats</h5>
  </div>
  <!-- temporarily hiding scheduled meetings for zoom room -->
  <div class="pl-0 pr-1" [class]="cloudRoomStats?.is_room === 1? 'col-12' : 'col-6'">
    <app-btn-stat-call [count]="cloudRoomStats?.liveMeetings" label="Status" color="gray"></app-btn-stat-call>
  </div>
  <!-- temporarily hiding scheduled meetings for zoom room -->
  <div class="col-6 pl-0 pr-1" *ngIf="cloudRoomStats?.is_room !== 1">
    <app-btn-reg-stats [count]="cloudRoomStats?.scheduledMeetings" label="Scheduled Meetings" color="gray" (click)="
        ds.sendPopupType(
          38,
          {
            deviceId: id,
            meetings: cloudRoomStats?.scheduled
          },
          hasAccess
        )
      " [isbutton]="isNotButton"></app-btn-reg-stats>
  </div>
</div>

<div class="row">
  <div *ngIf="cloudRoomStats?.liveMeetings !==2" class="col-3 pl-0 pr-1">
    <app-btn-bitrate [count]="calMbps()" label="Live Mbps"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'mbps' })" color="{{ colorBit() }}"></app-btn-bitrate>
  </div>
  <div *ngIf="cloudRoomStats?.liveMeetings !== 2" class="col-3 pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudRoomStats?.liveMeetings === 0 ? 0 : cloudRoomStats?.total_jitter"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'jitter' })" label="Jitter" color="{{ colorJit() }}">
    </app-btn-reg-stats>
  </div>
  <div *ngIf="cloudRoomStats?.liveMeetings !== 2" class="col-3 pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudRoomStats?.liveMeetings === 0 ? 0 : cloudRoomStats?.avg_packet_loss"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'packetloss' })" label="Packet Loss (%)"
      color="{{ colorPac() }}">
    </app-btn-reg-stats>
  </div>
  <div *ngIf="cloudRoomStats?.liveMeetings !== 2" class="col-3 pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudRoomStats?.liveMeetings === 0 ? 0 : cloudRoomStats?.frame_rate"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'framerate' })" label="Frame Rate"
      color="{{ colorFrame() }}">
    </app-btn-reg-stats>
  </div>
  <div *ngIf="cloudRoomStats?.liveMeetings === 2" class="col-3 pl-0 pr-1">
    <app-btn-bitrate [count]="calMbps()" label="Live Mbps" color="btnbacklite"></app-btn-bitrate>
  </div>
  <div *ngIf="cloudRoomStats?.liveMeetings ===  2" class="external-col pl-0 pr-1">
    <app-btn-stat-call [count]="3" label="External Call In Progress" color="gray">
    </app-btn-stat-call>
  </div>
</div>

<!-- Jabra -->
<div class="row" *ngIf="isShowPanacast()">
  <div class="col-6 btn-col pl-0 pr-1">
    <div>
      <img src="../../assets/img/video/jabra_logo.png@1X.png" class="video-btn-icon" />
    </div>
  </div>
  <div class="col-6 btn-col pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudRoomStats?.people_count"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'people_count' })" label="People Count"
      color="{{ colorFrame() }}">
    </app-btn-reg-stats>
  </div>
</div>

<div *ngIf="cloudRoomStats?.liveMeetings !== 2" class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>

<div *ngIf="cloudRoomStats?.liveMeetings !== 2" class="row">
  <div class="col-12 pl-0 pr-2">
    <app-cloud-live-call-list
      [liveParticipants]="cloudRoomStats?.liveMeetings === 0 ? 0 : cloudRoomStats?.participants">
    </app-cloud-live-call-list>
  </div>
</div>
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>


<div class="row">
  <div class="col-12 pl-0 pr-2">
    <app-teams-room-path-detection [cloudUserStats]="cloudRoomStats">
    </app-teams-room-path-detection>
  </div>
</div>

<div class="row">
  <div class="col-5 btn-col pl-0 pr-1 float-right">
    <h5 style="margin-top: 6px;">Device(s) Health</h5>
  </div>
  <div class="col-2">
    <label class="switch">
      <input type="checkbox" [checked]="checkSelfHealing(cloudRoomStats)" (change)="updateSelfHealing($event)" />
      <span class="slider round">
        <span class="paused"></span>
      </span>
    </label>
  </div>
  <div class="col-5">
    <strong class="self-healing-on">Self-Healing {{ checkSelfHealing(cloudRoomStats) ? 'On' : 'Off' }}</strong>
  </div>
</div>

<div class="row">
  <div class="col-12 pl-0 pr-1">
    <app-btn-device-screen [pluginStatus]="pluginStatus" [pluginEnabled]="pluginEnabled"
      (click)="ds.sendPopupType(43, { data: cloudRoomStats })">-
    </app-btn-device-screen>
  </div>
</div>

<div class="row">
  <app-plugin-cloud-zoom-disable [isDisabled]="isPluginDisableByZaapi()" [message]="getZaapiIssueMessage()">
  </app-plugin-cloud-zoom-disable>
  <div class="col-6 pl-0 pr-1" [ngClass]="{ 'disabled-plugin-status': disablePluginFunction }">
    <app-btn-device-zoom [connectedAudioMic]="connectedMic" [pluginId]="cloudRoomStats?.plugin_id"
      [failOver]="getFailOver()" [defaultDevice]="defaultMic" [pluginStatus]="pluginStatus" [logitechDevice]="getLogitechDevices()">
    </app-btn-device-zoom>
  </div>
  <div class="col-6 pl-1 pr-0" [ngClass]="{ 'disabled-plugin-status': disablePluginFunction }">
    <app-btn-device-camera-zoom [connectedCameras]="connectedCam" [failOver]="getFailOver()"
      [pluginId]="cloudRoomStats?.plugin_id" [defaultDevice]="defaultCam" [logitechDevice]="getLogitechDevices()" [pluginStatus]="pluginStatus">
    </app-btn-device-camera-zoom>
  </div>
</div>

<div class="row margin-top5">
  <div class="col-12" [ngClass]="{ 'disabled-plugin-status': disablePluginFunction }">
    <app-btn-volume [connectedAudioSpeaker]="connectedSpeaker" [failOver]="getFailOver()"
      [pluginId]="cloudRoomStats?.plugin_id" [defaultDevice]="defaultSpeaker" [pluginStatus]="pluginStatus"
      [cloudRoomStats]="cloudRoomStats">
    </app-btn-volume>
  </div>
</div>

<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>

<div class="row">
  <div class="col-12">
    <h5>Utilization</h5>
  </div>
  <div class="col-12 pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudRoomStats?.totalMeetings" label="Total Calls This Month" color="gray" (click)="
        ds.sendPopupType(
          39,
          {
            deviceId: id,
            meetings: cloudRoomStats?.past_meetings
          },
          hasAccess
        )
      " [isbutton]="isNotButton"></app-btn-reg-stats>
  </div>

  <!-- <div class="col-6 pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudRoomStats?.totalRecordings" label="Recordings" color="gray" (click)="
        ds.sendPopupType(
          10,
          {
            deviceId: id,
            meetings: cloudRoomStats?.recordings
          },
          hasAccess
        )
      " [isbutton]="isNotButton"></app-btn-reg-stats>
  </div> -->

  <div class="row">
    <div class="col-12 pl-0 pr-1">
      <hr />
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>

<div class="row">
  <div class="col-12">
    <h5>Updates</h5>
  </div>
</div>

<div class="row">
  <div class="col-12 pl-0 pr-1">

    <!-- <app-btn-device-logitech *ngIf="cloudRoomStats?.zoom_plugin_data !== null" -->

    <app-btn-device-logitech *ngIf="false" [logitechData]="cloudRoomStats?.zoom_plugin_data.logitech"
      [pluginStatus]="cloudRoomStats?.plugin_status" [pluginEnabled]="cloudRoomStats?.zoom_plugin_enabled"
      [pluginId]="cloudRoomStats?.plugin_id">
    </app-btn-device-logitech>
  </div>
</div>

<!-- Note Section -->
<div class="row mt-2">
  <div class="col-12 px-0">
    <form action="">
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="7 " maxlength="4000" placeholder="Notes:"
          [disabled]="!hasAccess">{{ cloudRoomStats?.notes }}</textarea>
        <span>maximum 4000 characters</span>
      </div>
      <div>
        <input *ngIf="hasAccess" type="button" class="btn btn-primary float-right doNotPrint" value="Save"
          (click)="saveNotes()" />
      </div>
    </form>
  </div>
</div>