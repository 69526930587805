<!-- copying from app-zoom-popup -->
<div class="pexip">
	<!-- <app-download-icons
		[componentName]="componentName"
		[cloudRadioProp]="tableSelected"
		[desktopClientDownloadLink]="desktopClientDownloadLink"
		[siteSummary]="siteSummary"
		(sendPopupType)="sendPopupType($event)"
	></app-download-icons> -->

	<div [ngClass]="{ 'pexip__margin-top': tableSelected === 'service' }" class="pexip__radio-button-and-search">
		<div class="pexip__radio-button">
			<mat-radio-group [(ngModel)]="tableSelected" (change)="radioChange($event.value)">
				<mat-radio-button value="service">Integrate & Manage Service</mat-radio-button>
				<mat-radio-button value="rooms">List of VMRs</mat-radio-button>
			</mat-radio-group>
		</div>

		<div *ngIf="tableSelected !== 'service'" class="pexip__search">
			<div class="pexip__icon">
				<i class="fa fa-search"></i>
			</div>
			<input
				type="text"
				class="search-default"
				type="text"
				placeholder=""
				[(ngModel)]="searchKey"
				(ngModelChange)="search()"
			/>
		</div>
	</div>
	<div class="pexip__table-container">
		<app-pexip-manage-service></app-pexip-manage-service>
		<!-- <app-pexip-manage-service
			*ngIf="tableSelected === 'service'"
			(sendPopupType)="sendPopupType($event)"
			[pexipInfoResult]="pexipInfoResult"
		></app-pexip-manage-service>

		<app-pexip-rooms
			*ngIf="tableSelected === 'rooms'"
			[pexipRoomList]="pexipRoomList"
			(sendPopupType)="sendPopupType($event)"
			[showLoader]="showLoader"
		>
		</app-pexip-rooms>

		<app-pexip-users
			*ngIf="tableSelected === 'users'"
			[pexipUserList]="pexipUserList"
			(sendPopupType)="sendPopupType($event)"
			[hostToLink]="hostToLink"
			[showLoader]="showLoader"
		>
		</app-pexip-users> -->
	</div>
</div>
