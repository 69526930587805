import { Component, Input, OnInit } from '@angular/core'
import { AlertService } from 'src/app/_alert'
import { PexipService } from 'src/app/services/pexip.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-pexip-enable-service',
	templateUrl: './pexip-enable-service.component.html',
	styleUrls: ['./pexip-enable-service.component.scss'],
})
export class PexipEnableServiceComponent implements OnInit {
	url: string
	username: string
	password: string
	@Input() pexipAccount: any
	isLoading: boolean = false
	// loader: String = environment.config.dynamicImage.loader

	constructor(private pexipService: PexipService, private alertService: AlertService) {}

	ngOnInit(): void {
		// this.getPexipAccount()
	}

	// getPexipAccount(): void {
	// 	this.isLoading = true
	// 	this.pexipService.getPexipAccount().subscribe(data => {
	// 		const { url, username, password } = data
	// 		this.url = url
	// 		this.username = username
	// 		this.password = password
	// 		this.isLoading = false
	// 	})
	// }

	onAuthorize(): void {
		this.pexipService
			.addPexipAccount(this.pexipAccount?.url, this.pexipAccount?.username, this.pexipAccount?.password)
			.subscribe(
				result => {
					debugger
				},
				err => {
					this.alertService.error(err?.error?.message || err?.error || 'Something went wrong', {
						autoClose: true,
						keepAfterRouteChange: false,
					})
				}
			)
	}
}
