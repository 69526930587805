import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { VideoAudioSSLDialogComponent } from '../dialog/video-audio-ssl-dialog/video-audio-ssl-dialog.component'

@Component({
  selector: 'app-video-additional',
  templateUrl: './video-additional.component.html',
  styleUrls: ['./video-additional.component.scss']
})
export class VideoAdditionalComponent implements  OnChanges, OnInit {
  @Input() videoDeviceData: any;
  filterGateKeeperArray: any[] = [];
  filterSipKeeperArray: any[] = [];
  checkDeviceState1: any;
  showUcBoardHealth: any = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.videoDeviceData && this.videoDeviceData) {
      this.setData();
    }
  }
  
  
  setData = () => {
    this.showUcBoardHealth = this.videoDeviceData.device_type !== 'hdx';
    this.checkDeviceState1 = this.checkDeviceState();
    const {
      gatekeeper,
      sip_info
    } = this.videoDeviceData;
    
    this.filterGateKeeperAndSip(gatekeeper,sip_info);
    
  }


  filterGateKeeperAndSip = (gatekeeper,sip_info) => {
      this.filterGateKeeperArray =
      gatekeeper !== null ? this.filterGateKeeper(gatekeeper) : [];
    this.filterSipKeeperArray =
      sip_info !== null ? this.filterGateKeeper([sip_info]) : [];
      
  }
  
  
  filterGateKeeper = (arr) => {
    if (arr.length > 0 && arr[0].address  && arr[0].address != '' ) {
      const filterArr = arr.filter((gate) => gate.address.length != 0);
      if (filterArr.length > 0) {
        const storeArry = [];
        for (let i = 0; i < filterArr.length; i++) {
          const { address: regx } = filterArr[i];
          const r = /\d\d+/;
          const regMatch = regx.match(r);
          if (regMatch) {
            const addressReg = regx
              .substring(regx.match(r).index)
              .split(/[<;]/)[0];
            storeArry.push({ value: filterArr[i].value, address: addressReg });
          }
        }
        return storeArry.length > 0 ? storeArry : [];
      }
    }
    return [];
  };
  
  
  checkDeviceState = () => {
    if (this.videoDeviceData) {
      const { firmwares, update_action_id, device_state } =
        this.videoDeviceData;
      if (device_state === 'UPDATING') {
        return '( Device updating )';
      }

      if (firmwares !== null && firmwares.length !== 0) {
        const { updateRequired: checkForUpdate } = firmwares.filter(
          (e) => e.modUid === 'local-system'
        )[0];
        if (checkForUpdate == '1') {
          return '( Device update available )';
        } else if (checkForUpdate == '0') {
          return '( Device up to date )';
        } else {
          return '( -- )';
        }
      }
    }
    return '( -- )';
  };

  openDialog() {
  let dialogRef = this.dialog.open(VideoAudioSSLDialogComponent, {
    panelClass: 'custom-dialog-container',
    data: {
      deviceId: this.videoDeviceData?.video_device_id,
      deviceType: 'video'
    }
	})
}

}
