import { ToastrService } from 'ngx-toastr'
import { GetCloudService } from 'src/app/services/get-cloud.service'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons'

import { environment } from '../../../environments/environment'
import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'
import { NgrokProtocolComponent } from 'src/app/ngrok-protocol/ngrok-protocol.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
	selector: 'app-teams-room-info',
	templateUrl: './teams-room-info.component.html',
	styleUrls: ['./teams-room-info.component.scss'],
})
export class TeamsRoomInfoComponent implements OnInit, OnChanges {
	@Input() teamsDetails: any
	@Output() cloudEvent: EventEmitter<any> = new EventEmitter<any>()
	@Input() cloudRoomInfo: any
	@Input() showLoader: boolean = true
	@Input() logitechTap: any

	checkVytecEnvironment = environment.config.title
	loader: String = environment.config.dynamicImage.loader
	deskPlugin: String = environment.config.dynamicText.deskPlugin

	isDisableDesktopClientPluginToggle: boolean = true //disable for now
	userStatus: any = 'Unknown'
	isTeamsPluginEnabled: boolean = false
	desktopPluginMessage: string
	deskPluginStatus: number
	iotData: any
	hostname: any
	ipAddress: any
	mac: any
	serialNo: string

	teamsAdmin: any
	shouldShowIot: any = false
	faIcon = faUser
	faPauseIcon = faPause
	faPlayIcon = faPlay
	hasVersionWarning: boolean = false

	iotLiteEnabled: any
	iotDeviceIdList: any = []
	dCollector: String = environment.config.dynamicText.dCollector
	iotLoader: String = environment.config.dynamicImage.iotLoader
	iotDomotzList: any
	iotDomotzDeviceId: any
	iotUnlinkedDeviceList: any
	isDomotzEnabled: boolean = false
	showLogitechTap: boolean = false

	// Author: ShahzaibSE - Uptime was missing.
	uptimeDays: any
	uptimeHours: any
	uptimeMins: any

	constructor(
		public ds: PopupTypeService,
		private toastrService: ToastrService,
		private getCloudService: GetCloudService,
		private GetIot: GetIotService,
		public dialog: MatDialog
	) {}

	ngOnInit() {
		this.shouldShowIot = environment.config.showIoT
	}

	ngOnChanges(changes: SimpleChanges) {
		this.showLogitechTap = this.logitechTap?.Name === 'Tap'
		if (changes.teamsDetails && this.teamsDetails) {
			this.setData()
		}
	}

	getDesktopPluginMessage = () => {
		if (this.isDisableDesktopClientPluginToggle) {
			return `To enable advance features, please download and install the ${this.deskPlugin}.`
		}
		if (!this.isDisableDesktopClientPluginToggle && this.isTeamsPluginEnabled && this.deskPluginStatus !== 1) {
			return `${this.deskPlugin} is offline.`
		}
		return ''
	}

	getUserProfilePicture = () => {
		return '../../../../assets/img/user-in-a-square.png'
	}

	openRebootPopUp = () => {
		// const data = {
		//   zoom_plugin_enabled: this.isTeamsPluginEnabled,
		//   desktop_plugin_message: this.desktopPluginMessage,
		//   plugin_id: this.cloudUserInfo.plugin_id,
		// };
		// this.ds.sendPopupType(63, { data });
	}

	setData = () => {
		this.showLoader = false
		const {
			plugin_host_info,
			zoom_user_id,
			room_name,
			is_room,
			collector_id,
			iot_components,
			iot_domotz_components,
			collector_version,
			collector_status,
			site_id,
			plugin_application,
			plugin_status,
			pause,
		} = this.teamsDetails

		console.log('teamsDetails: ', this.teamsDetails)

		if (plugin_host_info) {
			const { hostname, ip_address, mac, bios_sn } = plugin_host_info[0]
			// // console.log('ipAddress', hostname)

			this.hostname = hostname
			this.ipAddress = ip_address
			this.mac = mac
			this.serialNo = bios_sn

			console.log('teamsAdmin: ', this.teamsAdmin)

			this.iotData = {
				collector_id,
				device_id: zoom_user_id,
				quadrant: 3,
				iot_components,
				iot_domotz_components,
				collector_version,
				status: this.checkPluginEnabledAndStatus(collector_status, site_id),
				room_name,
				site_id,
				mac: '',
				ipv4: ip_address,
			}
		}

		this.teamsAdmin = {
			quadrant_group: 'CLOUD',
			device_id: zoom_user_id,
			description: room_name,
			offset: 0,
			is_room,
			ipAddress: this.ipAddress,
			details: this.teamsDetails,
		}

		if (this.teamsDetails && this.teamsDetails.plugin_version) {
			const versionRequirement = '1.0.58'
			const versionComparison = this.teamsDetails.plugin_version.localeCompare(versionRequirement, undefined, {
				numeric: true,
				sensitivity: 'base',
			})
			if (versionComparison == -1 && !this.hasVersionWarning) {
				this.hasVersionWarning = true
				this.toastrService.warning(`Please upgrade to latest ${versionRequirement} version or above`)
			}
		}
		//soem
		if (this.teamsDetails.plugin_id !== null) {
			this.isDisableDesktopClientPluginToggle = false
			this.deskPluginStatus = this.teamsDetails.plugin_status
			if (this.teamsDetails.zoom_plugin_enabled === 1) {
				this.isTeamsPluginEnabled = true
			}
		} else {
			this.deskPluginStatus = 0
			this.isTeamsPluginEnabled = false
			this.isDisableDesktopClientPluginToggle = true
		}

		// this.isTeamsPluginEnabled = this.teamsDetails.isTeamsEnabled
		this.desktopPluginMessage = this.getDesktopPluginMessage()

		// this.userStatus = pause ? 'Paused' : this.teamsDetails.status

		if (pause) this.userStatus = 'Paused'
		else if (+plugin_status === -1) this.userStatus = 'Unknown'
		else if (+plugin_status === 1) {
			if (+plugin_application[0].team_room === 1) this.userStatus = 'Online'
			else if (+plugin_application[0].team_room === 0) this.userStatus = 'Offline'
		}

		// if (String(this.userStatus).toLowerCase() === 'unknown' && +plugin_status === 1) {
		// 	if (+plugin_application[0].team_room === 1) this.userStatus = 'Online'
		// 	else if (+plugin_application[0].team_room === 0) this.userStatus = 'Offline'
		// }

		this.getDomotzAccountByCompanyId()
		this.getUptime()
	}

	getDomotzAccountByCompanyId = () => {
		this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
			const { enabled } = data
			this.isDomotzEnabled = enabled === 1
			if (this.isDomotzEnabled) {
				this.setIoTComponenets(true)
			} else {
				this.getIotLiteStatus()
			}
		})
	}

	getIotLiteStatus = () => {
		this.GetIot.getIotLiteStatus().subscribe((data: any) => {
			let [res] = data.response
			this.iotLiteEnabled = res.is_iot_lite
			this.setIoTComponenets(false)
		})
	}

	setIoTComponenets = isdomotzEnabled => {
		if (isdomotzEnabled) {
			this.getLinkedIotDomotzDevices()
		} else {
			if (this.iotLiteEnabled) {
				this.getLinkedIotDevices()
			}
		}
	}

	getLinkedIotDevices = () => {
		if (this.iotData.iot_components !== null) {
			this.iotDeviceIdList = [...this.iotData.iot_components].map(e => e.iot_id)
		}
	}

	getLinkedIotDomotzDevices = () => {
		const { iot_domotz_components } = this.iotData || { iot_domotz_components: null }
		if (iot_domotz_components !== null) {
			this.iotDeviceIdList = iot_domotz_components.map(i => {
				const status = i.status
				const paused = i.paused
				const iot_device_id = i.iot_id
				const iot_type = i.group_type
				const iot_name = i.display_name
				const iot_ip = i.ip_address

				return { iot_device_id, status, iot_type, iot_name, iot_ip, paused }
			})
		} else {
			this.iotDeviceIdList = []
		}
	}

	setRoomPause = flag => {
		const pauseFlag = flag ? 0 : 1
		this.cloudRoomInfo.pause = pauseFlag
		let i = 0

		this.getCloudService.pauseZoom(this.cloudRoomInfo.zoom_user_id, pauseFlag).subscribe((data: any) => {
			const { status } = data
			if (status == 200) {
				console.log(status)
				while (i < this.iotDeviceIdList.length) {
					console.log(i)
					this.setPaused(this.iotDeviceIdList[i], pauseFlag)
					i++
				}
				this.refreshCloud()
			}
		})
	}

	setPaused = (iot_id, flag) => {
		this.GetIot.setIotDevicePaused(iot_id, flag).subscribe(
			(data: any) => {
				// this.eventEmitter.emit('callGetIotDeviceDetails');
				console.log('setIotDevicePaused: ', data)
			},
			error => {}
		)
	}

	refreshCloud = () => {
		this.cloudEvent.emit('refreshCloudData')
	}

	checkPluginEnabledAndStatus = (collector_status, site_id) => {
		if (collector_status !== null && site_id !== null) {
			if (collector_status === 1) {
				return true
			}
		}
		return false
	}

	// Author: ShahzaibSE
	getUptime = () => {
		const { plugin_host_info } = this.teamsDetails
		const { last_reboot } = this.teamsDetails

		// console.log('plugin_host_info:', plugin_host_info)
		// console.log('plugin_host_info[0].last_reboot:', plugin_host_info[0].last_reboot)

		// if (!last_reboot) return
		if (plugin_host_info.length <= 0 && plugin_host_info[0].last_reboot === undefined) return

		const uptime = new Date().getTime() - new Date(plugin_host_info[0].last_reboot).getTime()

		this.uptimeDays = Math.floor(uptime / (1000 * 60 * 60 * 24))
		this.uptimeHours = Math.floor((uptime / (1000 * 60 * 60)) % 24)
		this.uptimeMins = Math.floor((uptime / (1000 * 60)) % 60)
	}
	 openRemote() {
		if (this.cloudRoomInfo?.is_remote_access === 1) {
		  this.dialog.open(NgrokProtocolComponent, {
			data: this.cloudRoomInfo,
		  });
		}
	  }
}
