import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEye, faShield, faShieldHalved, faWarning } from "@fortawesome/free-solid-svg-icons";
import { finalize } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { GetCollectorService } from "src/app/services/get-collector.service";
import { RemoteAccessService } from "src/app/services/remote-access.service";
import { environment } from "src/environments/environment";
type CollectorAction = {
  collector_id: number;
  label: string;
  action: string;
  params?: any;
};
@Component({
  selector: "app-remote-access",
  templateUrl: "./remote-access.component.html",
  styleUrls: ["./remote-access.component.scss"],
})
export class RemoteAccessComponent implements OnInit,OnChanges {
  isAccess: boolean = false;
  snapshotMessage: string = "";
  showDownloading: boolean = false;
  setTimer: any;
  actionCount: number = 0;
  credential_id:string = '';
  faShield = faShield as IconDefinition;
  faShieldHalved = faShieldHalved as IconDefinition;
  faEye = faEye as IconDefinition;
  faWarning = faWarning as IconDefinition;
  showPopup = false;
  logList = [];
  showConfirmation = false;
  loader: String = environment.config.dynamicImage.loader;
  shouldShowLoader: boolean = false;
  @Input("data") data: any;
  constructor(
    private _remoteService: RemoteAccessService,
    private Auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getTunnelLog();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data && this.data){
      this.isAccess = !!this.data?.is_remote_access
    }
  }
  remoteAccess(){
    if(!this.isAccess){
      this.showConfirmation =true;
      return;
    }
    this.showDownloading = true;
    const body = {
      collector_id: this.data?.collector_id,
      is_remote_access: this.isAccess
    }
    this._remoteService.allowAccess(body)
    .subscribe((data) => {
      if(data.success){
        this.showDownloading = false;
        this.snapshotMessage = data.message;
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  resetFields = (message) => {
    this.actionCount = 0;
    this.showDownloading = false;
    this.isAccess = !this.isAccess;
    this.snapshotMessage = message;
    this.credential_id = '';
  };
  togglePopup() {
    this.showPopup = !this.showPopup;
  }
  getTunnelLog(){
    this._remoteService.getTunnelLog(this.data.collector_id)
    .subscribe((resp) => {
      if(resp.success){
        this.logList = [...resp.data]
      }
    },(err)=>{
    });
  }
  confirmDisable() {
    const body = {
      collector_id: this.data?.collector_id,
      is_remote_access: this.isAccess
    }
    this._remoteService.allowAccess(body)
    .subscribe((data) => {
      if(data.success){
        this.showDownloading = false;
        this.snapshotMessage = data.message;
        this.showConfirmation = false;
        this.shouldShowLoader = false;
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }

  cancelDisable() {
    this.isAccess = true;
    this.showConfirmation = false;
  }
  stopAgent(){
    this.shouldShowLoader = true;
    const body = {
      collector_id: this.data?.collector_id,
      label: 'stop ngrok',
      action: 'stop ngrok',
      params: { },
    };
    this._remoteService.collectorAction(body)
    .subscribe((data) => {
      if(data.success){
        this.checkStatus(data?.result?.action_id)
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  checkStatus = (action_id) => {
    console.log("action_id: ", action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log("postDeviceActionId: ", actionData);
      console.log("actionCount: ", this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
         this.resetFields("Something went wrong. Please try again.");

        return;
      }
      if (
        actionData.response[0].status !== "COMPLETE" &&
        actionData.response[0].status !== "FAILED"
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === "FAILED") {
         this.resetFields("Something went wrong. Please try again.");
        return;
      } else if (actionData.response[0].status === "COMPLETE") {
        const [newData] = actionData.response;
        this.confirmDisable()
       
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  };
}
