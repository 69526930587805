<!-- remote-session.component.html -->
<div class="remote-session-dialog">
  <div class="card shadow-lg">
    <div class="card-header bg-danger text-white position-relative d-flex align-items-center">
      <h3 class="mb-0">Active Remote Session</h3>
      <i class="fa fa-times px-3 text-dark" style="cursor: pointer;" (click)="handleClose()"></i>   
      </div>
    <div class="card-body">
      <div class="session-info">
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label fw-bold">Session URL:</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [value]="sessionUrl"
                readonly
              />
              <button
                class="btn btn-outline-primary"
                (click)="copyToClipboard()"
              >
              <i class="fa fa-copy"></i>

              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="form-label fw-bold">Session Start Time:</label>
            <p class="mb-0">{{ sessionStartTime | date : "medium" }}</p>
          </div>
        </div>
        <div class="status-alert alert alert-warning d-flex align-items-center waring-card">
          <i class="bi bi-exclamation-circle me-2"></i>
          <div>Session is active - Keep this window open</div><br>
          <div>Session will expire in 10-15 minutes if not used.</div>
        </div>
      </div>
    </div>
  </div>
</div>
