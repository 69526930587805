// validation.service.ts
import { Injectable } from '@angular/core'
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { postcodeValidator } from 'postcode-validator'

@Injectable({
	providedIn: 'root',
})
export class InputValidationService {
	customPhoneValidator(control: AbstractControl): ValidationErrors | null {
		const pattern1 = /^(?:\+\d{1,3})?\d{7,15}$/
		const pattern2 = /^\d{3}-\d{3}-\d{4}$/
		const pattern3 = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/

		if (pattern1.test(control.value) || pattern2.test(control.value) || pattern3.test(control.value)) {
			return null
		}

		return { invalidPhone: true }
	}

	postalCodeValidator(control): ValidationErrors | null {
		const isValid = postcodeValidator(control.value, 'INTL')
		return isValid ? null : { invalidPostalCode: true }
	}
}
