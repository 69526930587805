<!-- Main container - always visible -->
<div class="remote-session-dialog">
  <div class="card shadow-lg">
    <div class="card-header bg-danger text-white d-flex align-items-center justify-content-between">
      <h3 class="mb-0">Active Remote Session</h3>
      <i class="fa fa-times px-3 text-dark cursor-pointer" (click)="handleClose()"></i>
    </div>
    <div class="card-body">
      <div class="session-info">
        <!-- Session URL - only for HTTP/HTTPS -->
        <div class="row mb-3" *ngIf="proto === 'http' || proto === 'https'">
          <div class="col-12">
            <label class="form-label fw-bold">Session URL:</label>
            <div class="input-group">
              <input type="text" class="form-control cursor-pointer text-primary text-decoration-underline" [value]="sessionUrl" readonly
                (click)="openSessionInNewTab()" title="Click to open in new tab" />
              <button class="btn btn-outline-primary" (click)="copyToClipboard()">
                <i class="fa fa-copy"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Session time info - for all protocols -->
        <div class="row mb-3">
          <div class="col-12 d-flex align-items-center">
            <label class="form-label fw-bold mb-0 me-2">Session Start Time:</label>
            <span>{{ sessionStartTime | date : "medium" }}</span>
          </div>
        </div>

        <!-- Warning alert - for all protocols -->
        <div class="alert alert-warning d-flex align-items-center flex-wrap gap-2">
          <div class="d-flex align-items-center">
            <i class="bi bi-exclamation-circle me-2"></i>
            <div>Session is active - Keep this window open</div>
          </div>
          <div class="d-flex align-items-center">
            <span class="me-2"> - Session will expire in:</span>
            <span class="badge bg-warning text-dark" style="width: 43px;">{{ remainingTimeDisplay }}</span>
          </div>
        </div>

        <div class="d-flex align-items-center p-3 mt-3 border rounded bg-light" *ngIf="_guacamoleService.isFailed">
          <span class="btn btn-primary mr-3" (click)="reconnectToRemote()">Reconnect</span>
          <div class="text-danger ms-3">
            <i class="bi bi-exclamation-triangle-fill me-1"></i>
            Failed. If you entered wrong credentials, reconnect.
          </div>
        </div>

        <!-- Guacamole container - only for TCP -->
        <div id="guac-container" *ngIf="proto === 'tcp'" class="w-100" style="height: 500px;"></div>
      </div>
    </div>
  </div>
</div>