import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'

import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

interface Category {
  id: string
  name: string
  selected: boolean
}

interface InventoryItem {
  id: string
  category: string
  // Add other fields based on API schema
}

interface DeviceData {
  cam: number | null
  mic: number | null
  room_name: string
  site_name: string
  ip_address: string
  device_name: string
  system_type: string
  serial_number: string
}

@Component({
  selector: 'app-inventory-reporting',
  templateUrl: './inventory-reporting.component.html',
  styleUrls: ['./inventory-reporting.component.scss']
})
export class InventoryReportingComponent implements OnInit {
  loader = environment.config.dynamicImage.loader
  inventoryDeviceApiData: any
  loading: number = 0
  @Input() popUpState: any

  categories: Category[] = [
    { id: 'video', name: 'Video', selected: false },
    { id: 'cloud', name: 'Cloud Services', selected: false },
    { id: 'audio', name: 'Audio and Hybrid', selected: false },
    { id: 'iot', name: 'IoT', selected: false }
  ];

  rawData: DeviceData[] = []; // Your API data
  filteredData: DeviceData[] = [];

  isLoading = false;
  inventoryData: any[] = [];
  tableHeaders: string[] = [];

  // Report Title Panel Properties
  fromDate: any
  toDate: any
  selectedSite: string = '';
  siteTitle: string = 'Inventory Report';
  siteSubTitle: string = 'Device Inventory';
  siteType: string = 'inventory';
  isDevice: boolean = false;

  listTitle: string = 'Inventory Report';

  dummyInventoryData: DeviceData[] = [
    {
      cam: 1,
      mic: 1,
      room_name: "Executive Room A",
      site_name: "Headquarters",
      ip_address: "192.168.1.100",
      device_name: "Cisco Webex Room Kit",
      system_type: "cisco",
      serial_number: "CSC123456789"
    },
    {
      cam: 0,
      mic: 1,
      room_name: "Conference Room B",
      site_name: "Headquarters",
      ip_address: "192.168.1.101",
      device_name: "Poly Studio X50",
      system_type: "polystudio",
      serial_number: "PLY987654321"
    },
    {
      cam: null,
      mic: null,
      room_name: "Meeting Room C",
      site_name: "Branch Office",
      ip_address: "192.168.1.102",
      device_name: "Logitech Rally Bar",
      system_type: "logitech",
      serial_number: "LOG456789123"
    },
    {
      cam: 1,
      mic: 1,
      room_name: "Training Room",
      site_name: "Branch Office",
      ip_address: "192.168.1.103",
      device_name: "Zoom Room System",
      system_type: "zoom",
      serial_number: "ZRM789123456"
    },
    {
      cam: 0,
      mic: 1,
      room_name: "Huddle Space 1",
      site_name: "Innovation Center",
      ip_address: "192.168.1.104",
      device_name: "Teams Room System",
      system_type: "teams",
      serial_number: "TMS321654987"
    },
    {
      cam: 1,
      mic: 0,
      room_name: "Board Room",
      site_name: "Innovation Center",
      ip_address: "192.168.1.105",
      device_name: "Polycom HDX",
      system_type: "hdx",
      serial_number: "HDX147258369"
    },
    {
      cam: null,
      mic: null,
      room_name: "IoT Lab",
      site_name: "R&D Center",
      ip_address: "192.168.1.106",
      device_name: "Environmental Sensor",
      system_type: "iot",
      serial_number: "IOT963852741"
    },
    {
      cam: 1,
      mic: 1,
      room_name: "Webex Room",
      site_name: "R&D Center",
      ip_address: "192.168.1.107",
      device_name: "Webex Board",
      system_type: "cisco",
      serial_number: "WXB741852963"
    },
    {
      cam: 0,
      mic: 1,
      room_name: "Audio Conference Room",
      site_name: "Sales Office",
      ip_address: "192.168.1.108",
      device_name: "Audio System",
      system_type: "audio",
      serial_number: "AUD159753468"
    },
    {
      cam: 1,
      mic: 1,
      room_name: "Hybrid Meeting Space",
      site_name: "Sales Office",
      ip_address: "192.168.1.109",
      device_name: "Hybrid System",
      system_type: "polycom",
      serial_number: "HYB852963741"
    }
  ];

  isPrint: number = 0;

  constructor(private Auth: AuthService) {
    this.filteredData = []
    this.rawData = []
  }

  ngOnInit(): void {
    console.log('Component initialized')
    // this.filterData()
    // this.generate()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['popUpState']) {
      console.log('Inventory Reporting: popUpState changed:', this.popUpState)
      if (this.popUpState === 'close') {
        this.reset()
      }
    }
  }

  onCategorySelect(category: Category) {
    category.selected = !category.selected
    this.filterData()
  }

  getSelectedCategories(): Category[] {
    return this.categories.filter(category => category.selected)
  }

  async fetchInventoryData() {
    this.isLoading = true
    try {
      // Your API call here
      // const response = await this.inventoryService.getInventory(...)
      // this.inventoryData = response;
      // this.tableHeaders = Object.keys(this.inventoryData[0] || {});
    } catch (error) {
      console.error('Error fetching inventory:', error)
    } finally {
      this.isLoading = false
    }
  }

  genInventory = () => {
    this.isLoading = true
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService()
    const decodedToken = helper.decodeToken(token)
    const bodyInventoryDevice = {
      company_id: decodedToken.company_id,
      site_id_list: '*',
    }

    this.Auth.getInventoryDevice(bodyInventoryDevice).subscribe({
      next: (data: any) => {
        console.log('API Response:', data)

        // Correctly access the nested data array
        if (data?.response?.[0]?.data) {
          const deviceData = data.response[0].data
          console.log('Device Data:', deviceData)

          // Transform and load the data
          const transformedData = this.transformApiData(deviceData)
          this.rawData = transformedData
          this.filterData()
        } else {
          console.error('Invalid data structure:', data)
          this.rawData = []
          this.filteredData = []
        }
      },
      error: (error) => {
        console.error('Error:', error)
        this.rawData = []
        this.filteredData = []
      },
      complete: () => {
        this.isLoading = false
      }
    })
  };

  // Add this method to transform API data
  private transformApiData(apiData: any[]): DeviceData[] {
    return apiData.map(item => ({
      cam: item.cam !== undefined ? Number(item.cam) : null,
      mic: item.mic !== undefined ? Number(item.mic) : null,
      room_name: String(item.room_name || ''),
      site_name: String(item.site_name || ''),
      ip_address: String(item.ip_address || ''),
      device_name: String(item.device_name || ''),
      system_type: String(item.system_type || ''),
      serial_number: String(item.serial_number || '')
    }))
  }

  filterData() {
    console.log('filterData called with rawData:', this.rawData)
    const selectedCategories = this.categories.filter(cat => cat.selected).map(cat => cat.id)
    console.log('Selected categories:', selectedCategories)

    if (selectedCategories.length === 0) {
      console.log('No categories selected, using all rawData')
      this.filteredData = [...this.rawData] // Create new array reference
      return
    }

    this.filteredData = this.rawData.filter(device => {
      console.log('Filtering device:', device) // Log each device being processed
      let matches = false

      // Video devices (Cisco, Polycom, etc.)
      if (selectedCategories.includes('video') &&
        ['cisco', 'polycom', 'polystudio', 'logitech', 'hdx'].includes(device.system_type.toLowerCase())) {
        matches = true
      }

      // Cloud services
      if (selectedCategories.includes('cloud') &&
        ['zoom', 'teams', 'webex'].includes(device.system_type.toLowerCase())) {
        matches = true
      }

      // Audio and Hybrid
      if (selectedCategories.includes('audio') &&
        device.mic !== null) {
        matches = true
      }

      // IoT devices
      if (selectedCategories.includes('iot') &&
        ['sensor', 'iot'].includes(device.system_type.toLowerCase())) {
        matches = true
      }

      console.log(`Device ${device.device_name} matches: ${matches}`) // Log match result
      return matches
    })

    console.log('Final filteredData:', this.filteredData)
  }

  // When you receive data from API
  loadData(data: DeviceData[]) {
    this.rawData = data
    this.filterData()
  }

  generate() {
    this.updateDates()
    this.genInventory()
  }

  private updateDates(): void {
    const today = new Date()
    const sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(today.getDate() - 7)

    // Format dates as objects that match the panel's expected format
    this.toDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1, // JavaScript months are 0-based
      day: today.getDate()
    }

    this.fromDate = {
      year: sevenDaysAgo.getFullYear(),
      month: sevenDaysAgo.getMonth() + 1,
      day: sevenDaysAgo.getDate()
    }

    console.log('Updated Date Range:', {
      from: this.fromDate,
      to: this.toDate
    })
  }

  // Add these methods to help debug
  private logDataState() {
    console.log('Current State:', {
      rawDataLength: this.rawData.length,
      filteredDataLength: this.filteredData.length,
      isLoading: this.isLoading,
      selectedCategories: this.getSelectedCategories()
    })
  }

  getTagsHtml(tagName: string) {
    const tags = document.getElementsByTagName(tagName)
    let html = ''
    for (let i = 0; i < tags.length; i++) {
      html += tags[i].outerHTML
    }
    return html
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  printDiv(divId: string) {
    let printContents: string,
      stylesHtml: string,
      linksHtml: string

    printContents = document.getElementById(divId).innerHTML
    stylesHtml = this.getTagsHtml('style')
    linksHtml = this.getTagsHtml('link')

    // Create an iframe (hidden)
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    document.body.appendChild(iframe)

    // Write the print content to the iframe
    const iframeDoc = iframe.contentWindow.document
    iframeDoc.open()
    iframeDoc.write(`
      <html>
        <head>
          ${stylesHtml}
          ${linksHtml}
          <title>Print Report</title>
          <style>
            @media print {
              body { padding: 20px; }
              table { width: 100%; border-collapse: collapse; }
              th, td { padding: 8px; border: 1px solid #ddd; }
              .status-indicator {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              @page { size: landscape; margin: 0.5in; }
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `)
    iframeDoc.close()

    // Wait for content to load then print
    iframe.onload = () => {
      iframe.contentWindow.print()
      // Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe)
      }, 500)
    }
  }

  reset(): void {
    // Reset all data
    this.rawData = []
    this.filteredData = []
    this.isLoading = false
    this.fromDate = null
    this.toDate = null
    this.selectedSite = ''

    // Reset all categories to unselected
    this.categories.forEach(category => {
      category.selected = false
    })
  }
}

