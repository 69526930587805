<!--The content below is only a placeholder and can be replaced.-->
<alert></alert>
<div
  *ngIf="authLoggedon == true && loginType !== 'directAccess' && isInAddPhoneNumberRoute == false && isTwoFactorAuthRoute == false"
  class="dashboard">
  <app-slack-slideout [(slackState)]="slackState"
    (newNotificationsCountChange)="newNotificationsCount = $event"></app-slack-slideout>

  <div class="ddashboard-main-app-container">
    <div class="ddashboard-main-app-container-menu">
      <app-main-menu [runTutorial]="Auth.runTutorial" (slackStateEvent)="onChangeSlackState()"
        [newNotificationsCount]="newNotificationsCount"> </app-main-menu>

      <app-main-menu-membership [runTutorial]="Auth.runTutorial" [keyDown]="keyDown">
      </app-main-menu-membership>

      <div id="main-overlay" class="tut-show1" *ngIf="closeTutType != 0 && Auth.runTutorial == 1"></div>

      <app-popup-container [runTutorial]="Auth.runTutorial"> </app-popup-container>

      <div class="row dashboard__content" [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
        <div class="{{
            ScaleRouterOutletState === 'hide' ? 'health-blocks' : 'health-blocks--leftSide'
          }}">
          <div class="border doNotPrint {{
              ScaleRouterOutletState === 'hide' ? 'health-block--inline' : 'health-block--first'
            }}">
            <app-health-card [data]="videoDeviceSummaryCard" mainTitle="Video Devices"
              subTitle="Health of all video devices" iconLink="./assets/img/icon_video_default.png@1X.png">
              <!-- this app-badge is a temporary solution for now -->
              <app-badge [polycomDevices]="polycomDevices" [ciscoDevices]="ciscoDevices"
                [logitechDevices]="logitechDevices" [quadrantType]="'video'">
              </app-badge>
            </app-health-card>
          </div>
          <div class="border doNotPrint {{
              ScaleRouterOutletState === 'hide'
                ? 'health-block--inline-rightSide border'
                : 'health-block border'
            }}">
            <app-health-card [data]="cloudRoomSummaryCard" mainTitle="Cloud Services" subTitle="Health of cloud rooms"
              iconLink="./assets/img/icon_cloud_services_default.png@1X.png">
              <!-- this app-badge is a temporary solution for now -->
              <!-- <img
                *ngIf="shouldShowZoomImg"
                [src]="'../assets/img/zoom_logo.png'"
                class="zoom-img"
                alt="Zoom"
              /> -->
              <app-badge [zoomEnabled]="zoomEnabled" [teamsEnabled]="teamsEnabled" [quadrantType]="'cloud'">
              </app-badge>
            </app-health-card>
          </div>
          <div class="border doNotPrint {{
              ScaleRouterOutletState === 'hide'
                ? 'health-block--inline border'
                : 'health-block border'
            }}">
            <app-health-card [data]="audioDeviceSummaryCard" mainTitle="Audio & Hybrid Devices"
              subTitle="Health of all audio devices & hybrid devices"
              iconLink="./assets/img/vs_img/audio_quadrant_icon.png">
            </app-health-card>
          </div>
          <div class="border doNotPrint {{
              ScaleRouterOutletState === 'hide'
                ? 'health-block--inline-rightSide border'
                : 'health-block border'
            }}">
            <app-health-card [data]="iotDeviceSummaryCard" mainTitle="{{iotQuadrantMainTitle}}"
              subTitle="Health of IoT Devices" iconLink="./assets/img/icon_network_devices_default.png@1X.png">
              <!-- <img *ngIf="domotzAccountEnabled && isShowDomotzIntegration"
                [src]="'../assets/img/ctx_img/domotzLogo.png'" class="zoom-img" alt="Zoom" /> -->
              <div class="dashboard__iot-spaces-button" (click)="routeToIotSpaces()">
                ALL SPACES
              </div>
              <div class="box-summary">
                <div class="box-summary__boxes-count box-summary__boxes-count--grey-border"
                  [ngClass]="{'box-summary__boxes-count--grey': allSpaces !== 0 }" (click)="navigateToSpaces('all')">
                  {{ allSpaces }}
                </div>
                <div>
                  :
                </div>
                <div class="box-summary__boxes-count box-summary__boxes-count--green-border"
                  [ngClass]="{'box-summary__boxes-count--green': healthySpaces !== 0 }"
                  (click)="navigateToSpaces('healthy')">

                  {{ healthySpaces }}

                </div>
                <div class="box-summary__boxes-count box-summary__boxes-count--yellow-border"
                  [ngClass]="{'box-summary__boxes-count--yellow': warningSpaces !== 0 }"
                  (click)="navigateToSpaces('warning')">
                  {{ warningSpaces }}

                </div>
                <div class="box-summary__boxes-count box-summary__boxes-count--red-border"
                  [ngClass]="{'box-summary__boxes-count--red': impactedSpaces !== 0 }"
                  (click)="navigateToSpaces('impacted')">
                  {{ impactedSpaces }}

                </div>
                <div class="box-summary__boxes-count box-summary__boxes-count--blue-border"
                  [ngClass]="{'box-summary__boxes-count--blue': pauseSpaces !== 0 }"
                  (click)="navigateToSpaces('pause')">
                  {{ pauseSpaces }}

                </div>

              </div>
            </app-health-card>
          </div>
        </div>

        <div class="rt-panel outlet" *ngIf="ScaleRouterOutletState !== 'hide'">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showPoweredBy" class="poweredby">
    Powered by VisibilityOne
  </div>
</div>

<div *ngIf="authLoggedon != true || loginType === 'directAccess'">
  <router-outlet></router-outlet>
</div>

<div *ngIf="isInAddPhoneNumberRoute == true || isTwoFactorAuthRoute == true">
  <router-outlet></router-outlet>
</div>