import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

import { GetAudioService } from '../../services/get-audio.service'
import { GetCloudService } from '../../services/get-cloud.service'
import { GetVideoService } from '../../services/get-video.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-alerts-panel',
	templateUrl: './alerts-panel.component.html',
	styleUrls: ['./alerts-panel.component.scss'],
})
export class AlertsPanelComponent implements OnChanges, OnDestroy {
	@Input() alertsTriggerData: any
	@Output() alertsTriggerDataChange = new EventEmitter<any>()
	@Input() alertType: any

	bitWarningValue: any
	bitAlertValue: any
	bitLimitMin: any
	bitLimitMax: any

	showSuccesMessage: boolean = false
	setTimer: any

	tab = 1

	constructor(
		private getCloudDataService: GetCloudService,
		private getAudioDataService: GetAudioService,
		private getVideoDataService: GetVideoService,
		public ds: PopupTypeService
	) {}

	ngOnDestroy(): void {
		this.clearTimeout()
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.refreshTab()

		if (this.alertsTriggerData && this.alertType === 'cloud') {
			this.bitWarningValue = this.alertsTriggerData.triggers.bitrate.warning / (1024 * 1024)
			this.bitAlertValue = this.alertsTriggerData.triggers.bitrate.alert / (1024 * 1024)
			this.bitLimitMin = this.alertsTriggerData.trigger_limits.bitrate.min / (1024 * 1024)
			this.bitLimitMax = this.alertsTriggerData.trigger_limits.bitrate.max / (1024 * 1024)
			
		}
		
	if(this.alertsTriggerData && changes.alertsTriggerData) {
		console.log('this.alertsTriggerData : ', this.alertsTriggerData )
		
		//here i create i injection for NEW defautlttrigger we will remove this after 1 month 
		this.injectNewDefaultTriggers()
	}
		
		// not sure why the hell is this for testing?
		// this.injectDefaultTriggers()
		// this.injectEnabledFlag()
	}



	refreshTab = async () => {
		const prevTab = this.tab

		this.tab++

		setTimeout(() => {
			this.tab = prevTab
		}, 100)
	}

	clearTimeout = () => {
		clearTimeout(this.setTimer)
	}

	jitterWarningChangeRx(warning: any, stream: string) {
		this.alertsTriggerData.triggers[stream].jitter.rx.warning = warning

		const alert = this.alertsTriggerData.triggers[stream].jitter.rx.alert
		if (alert <= warning) this.alertsTriggerData.triggers[stream].jitter.rx.alert = warning + 1
	}

	jitterWarningChangeTx(warning: any, stream: string) {
		this.alertsTriggerData.triggers[stream].jitter.tx.warning = warning
		this.alertsTriggerData.triggers[stream].jitter.tx.alert =
			this.alertsTriggerData.triggers[stream].jitter.tx.alert - warning < 1
				? warning + 1
				: this.alertsTriggerData.triggers[stream].jitter.tx.alert
	}

	jitterAlertChangeRx(alert: any, stream: string) {
		this.alertsTriggerData.triggers[stream].jitter.rx.alert = alert
		this.alertsTriggerData.triggers[stream].jitter.rx.warning =
			alert - this.alertsTriggerData.triggers[stream].jitter.rx.warning < 1
				? alert - 1
				: this.alertsTriggerData.triggers[stream].jitter.rx.warning
	}

	jitterAlertChangeTx(alert: any, stream: string) {
		this.alertsTriggerData.triggers[stream].jitter.tx.alert = alert
		this.alertsTriggerData.triggers[stream].jitter.tx.warning =
			alert - this.alertsTriggerData.triggers[stream].jitter.tx.warning < 1
				? alert - 1
				: this.alertsTriggerData.triggers[stream].jitter.tx.warning
	}

	packetLossWarningChangeRx(warning: any, stream: string) {
		this.alertsTriggerData.triggers[stream].packetsloss.rx.warning = warning
		this.alertsTriggerData.triggers[stream].packetsloss.rx.alert =
			this.alertsTriggerData.triggers[stream].packetsloss.rx.alert - warning < 0.01
				? warning + 0.01
				: this.alertsTriggerData.triggers[stream].packetsloss.rx.alert
	}

	packetLossWarningChangeTx(warning: any, stream: string) {
		this.alertsTriggerData.triggers[stream].packetsloss.tx.warning = warning
		this.alertsTriggerData.triggers[stream].packetsloss.tx.alert =
			this.alertsTriggerData.triggers[stream].packetsloss.tx.alert - warning < 0.01
				? warning + 0.01
				: this.alertsTriggerData.triggers[stream].packetsloss.tx.alert
	}

	packetLossAlertChangeRx(alert: any, stream: string) {
		this.alertsTriggerData.triggers[stream].packetsloss.rx.alert = alert
		this.alertsTriggerData.triggers[stream].packetsloss.rx.warning =
			alert - this.alertsTriggerData.triggers[stream].packetsloss.rx.warning < 0.01
				? alert - 0.01
				: this.alertsTriggerData.triggers[stream].packetsloss.rx.warning
	}

	packetLossAlertChangeTx(alert: any, stream: string) {
		this.alertsTriggerData.triggers[stream].packetsloss.tx.alert = alert
		this.alertsTriggerData.triggers[stream].packetsloss.tx.warning =
			alert - this.alertsTriggerData.triggers[stream].packetsloss.tx.warning < 0.01
				? alert - 0.01
				: this.alertsTriggerData.triggers[stream].packetsloss.tx.warning
	}

	cloudJitterWarningChange(warning: any) {
		this.alertsTriggerData.triggers.jitter.warning = warning

		const alert = this.alertsTriggerData.triggers.jitter.alert

		if (alert <= warning) this.alertsTriggerData.triggers.jitter.alert = warning + 1
	}

	cloudJitterAlertChange(alert: any) {
		this.alertsTriggerData.triggers.jitter.alert = alert

		const warning = this.alertsTriggerData.triggers.jitter.warning

		if (alert <= warning) this.alertsTriggerData.triggers.jitter.warning = alert - 1
	}

	cloudPacketlossWarningChange(warning: any) {
		this.alertsTriggerData.triggers.packetsloss.warning = warning

		const alert = this.alertsTriggerData.triggers.packetsloss.alert

		if (alert <= warning) this.alertsTriggerData.triggers.packetsloss.alert = warning + 0.1
	}

	cloudPacketlossAlertChange(alert: any) {
		this.alertsTriggerData.triggers.packetsloss.alert = alert

		const warning = this.alertsTriggerData.triggers.packetsloss.warning

		if (alert <= warning) this.alertsTriggerData.triggers.packetsloss.warning = alert - 0.1
	}

	frameRateWarningChange(warning: any) {
		this.alertsTriggerData.triggers.framerate.warning = warning

		const alert = this.alertsTriggerData.triggers.framerate.alert

		// if (alert <= warning) this.alertsTriggerData.triggers.framerate.alert = warning + 1
		// in case of framerate, alert should be less than warning
		if (alert >= warning) this.alertsTriggerData.triggers.framerate.alert = warning - 1
	}

	frameRateAlertChange(alert: any) {
		this.alertsTriggerData.triggers.framerate.alert = alert

		const warning = this.alertsTriggerData.triggers.framerate.warning

		// if (alert <= warning) this.alertsTriggerData.triggers.framerate.warning = alert - 1
		// in case of framerate, alert should be less than warning
		if (alert >= warning) this.alertsTriggerData.triggers.framerate.warning = alert + 1
	}

	latencyWarningChange(warning: any, stream: string) {
		this.alertsTriggerData.triggers[stream].latency.rx.warning = warning
		this.alertsTriggerData.triggers[stream].latency.rx.alert =
			this.alertsTriggerData.triggers[stream].latency.rx.alert - warning < 1
				? warning + 1
				: this.alertsTriggerData.triggers[stream].latency.rx.alert

		this.alertsTriggerData.triggers[stream].latency.tx.warning = warning
		this.alertsTriggerData.triggers[stream].latency.tx.alert =
			this.alertsTriggerData.triggers[stream].latency.tx.alert - warning < 1
				? warning + 1
				: this.alertsTriggerData.triggers[stream].latency.tx.alert
	}

	latencyAlertChange(alert: any, stream: string) {
		this.alertsTriggerData.triggers[stream].latency.rx.alert = alert
		this.alertsTriggerData.triggers[stream].latency.rx.warning =
			alert - this.alertsTriggerData.triggers[stream].latency.rx.warning < 1
				? alert - 1
				: this.alertsTriggerData.triggers[stream].latency.rx.warning

		this.alertsTriggerData.triggers[stream].latency.tx.alert = alert
		this.alertsTriggerData.triggers[stream].latency.tx.warning =
			alert - this.alertsTriggerData.triggers[stream].latency.tx.warning < 1
				? alert - 1
				: this.alertsTriggerData.triggers[stream].latency.tx.warning
	}

	bitrateWarningChange(warning: any) {
		this.bitWarningValue = warning

		const alert = this.alertsTriggerData.triggers.bitrate.alert / (1024 * 1024)

		// if (alert <= warning) this.bitAlertValue = warning + 0.1
		// in case of bitrate, alert should be less than warning
		if (alert >= warning) this.bitAlertValue = warning - 0.1

		// this.alertsTriggerData.triggers.bitrate.warning = this.bitWarningValue * 1000000
		// this.alertsTriggerData.triggers.bitrate.alert = this.bitAlertValue * 1000000
		this.alertsTriggerData.triggers.bitrate.warning = this.bitWarningValue * 1024 * 1024
		this.alertsTriggerData.triggers.bitrate.alert = this.bitAlertValue * 1024 * 1024
	}

	bitrateAlertChange(alert: any) {
		this.bitAlertValue = alert

		const warning = this.alertsTriggerData.triggers.bitrate.warning / (1024 * 1024)

		// if (alert <= warning) this.bitWarningValue = alert - 0.1
		// in case of bitrate, alert should be less than warning
		if (alert >= warning) this.bitWarningValue = alert + 0.1

		// this.alertsTriggerData.triggers.bitrate.warning = this.bitWarningValue * 1000000
		// this.alertsTriggerData.triggers.bitrate.alert = this.bitAlertValue * 1000000
		this.alertsTriggerData.triggers.bitrate.warning = this.bitWarningValue * 1024 * 1024
		this.alertsTriggerData.triggers.bitrate.alert = this.bitAlertValue * 1024 * 1024
	}

	temperatureWarningChange(warning: any) {
		this.alertsTriggerData.triggers.temperature.warning = warning
		this.alertsTriggerData.triggers.temperature.alert =
			this.alertsTriggerData.triggers.temperature.alert - warning < 1
				? warning + 1
				: this.alertsTriggerData.triggers.temperature.alert
	}

	temperatureAlertChange(alert: any) {
		this.alertsTriggerData.triggers.temperature.alert = alert
		this.alertsTriggerData.triggers.temperature.warning =
			alert - this.alertsTriggerData.triggers.temperature.warning < 1
				? alert - 1
				: this.alertsTriggerData.triggers.temperature.warning
	}

	resetAlertTriigers() {
		this.ds.loader = true
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)

		switch (this.alertType) {
			case 'video': {
				const alertTriggersData = {
					company_id: decodedToken.company_id,
					video_device_id: this.alertsTriggerData.video_device_id,
				}
				this.getVideoDataService.resetAlertTriggers(alertTriggersData).subscribe(data => {
					this.ds.loader = false
					this.successMessage()

					this.alertsTriggerDataChange.emit('reset')
				})

				break
			}

			case 'audio': {
				const alertTriggersData = {
					company_id: decodedToken.company_id,
					audio_device_id: this.alertsTriggerData.audio_device_id,
				}
				this.getAudioDataService.resetAlertTriggers(alertTriggersData).subscribe(data => {
					this.ds.loader = false
					this.successMessage()

					this.alertsTriggerDataChange.emit('reset')
				})
				break
			}

			case 'cloud': {
				const alertTriggersData = {
					company_id: decodedToken.company_id,
					zoom_user_id: this.alertsTriggerData.zoom_user_id,
				}
				this.getCloudDataService.resetAlertTriggers(alertTriggersData).subscribe(data => {
					this.ds.loader = false
					this.successMessage()
					this.alertsTriggerDataChange.emit('reset')
				})
				break
			}
		}
	}

	updateAlertTriigers = () => {
		this.ds.loader = true
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		
		switch (this.alertType) {
			case 'video': {
				const alertTriggersData = {
					company_id: decodedToken.company_id,
					video_device_id: this.alertsTriggerData.video_device_id,
					triggers: this.alertsTriggerData.triggers,
				}
				
				console.log('this.alertsTriggerData.triggers: ', this.alertsTriggerData.triggers)
				
				this.getVideoDataService.updateAlertTriggers(alertTriggersData).subscribe(data => {
					this.ds.loader = false
					this.successMessage()
					this.alertsTriggerDataChange.emit(this.alertsTriggerData) 
				})

				break
			}

			case 'audio': {
				const alertTriggersData = {
					company_id: decodedToken.company_id,
					audio_device_id: this.alertsTriggerData.audio_device_id,
					triggers: this.alertsTriggerData.triggers,
				}
				this.getAudioDataService.updateAlertTriggers(alertTriggersData).subscribe(data => {
					this.ds.loader = false
					this.successMessage()

					this.alertsTriggerDataChange.emit(this.alertsTriggerData)
				})
				break
			}

			case 'cloud': {
				const alertTriggersData = {
					company_id: decodedToken.company_id,
					zoom_user_id: this.alertsTriggerData.zoom_user_id,
					triggers: this.alertsTriggerData.triggers,
				}
				this.getCloudDataService.updateAlertTriggers(alertTriggersData).subscribe(data => {
					this.ds.loader = false
					this.successMessage()
					this.alertsTriggerDataChange.emit(this.alertsTriggerData)
				})
				break
			}
		}
	}

	successMessage = () => {
		this.showSuccesMessage = true
		this.setTimer = setTimeout(() => {
			this.showSuccesMessage = false
		}, 3000)
	}
	
		injectEnabledFlag() {
		if (this.alertType === 'cloud') return

		const flag = this.alertsTriggerData.triggers.video.jitter.rx?.enabled === undefined

		if (!flag) return

		const str = JSON.stringify(this.alertsTriggerData.triggers, null, 2)
		const regex = /"([rt]x)":\s*{/g
		const newStr = str.replace(regex, `"$1": {"enabled": true, `)
		this.alertsTriggerData.triggers = JSON.parse(newStr)
	}

	injectDefaultTriggers() {
		if (this.alertType === 'cloud') return

		const flag1 = !!this.alertsTriggerData?.triggers?.video
		const flag2 = Object.keys(this.alertsTriggerData?.triggers.video).length === 3

		if (flag1 && flag2) return

		const enOb = { enabled: true }
		const default_triggers = {
			video: {
				jitter: {
					rx: { ...this.alertsTriggerData.triggers.jitter, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.jitter, ...enOb },
				},
				packetsloss: {
					rx: { ...this.alertsTriggerData.triggers.packetsloss, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.packetsloss, ...enOb },
				},
				latency: {
					rx: { ...this.alertsTriggerData.triggers.latency, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.latency, ...enOb },
				},
			},
			audio: {
				jitter: {
					rx: { ...this.alertsTriggerData.triggers.jitter, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.jitter, ...enOb },
				},
				packetsloss: {
					rx: { ...this.alertsTriggerData.triggers.packetsloss, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.packetsloss, ...enOb },
				},
				latency: {
					rx: { ...this.alertsTriggerData.triggers.latency, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.latency, ...enOb },
				},
			},
			content: {
				jitter: {
					rx: { ...this.alertsTriggerData.triggers.jitter, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.jitter, ...enOb },
				},
				packetsloss: {
					rx: { ...this.alertsTriggerData.triggers.packetsloss, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.packetsloss, ...enOb },
				},
				latency: {
					rx: { ...this.alertsTriggerData.triggers.latency, ...enOb },
					tx: { ...this.alertsTriggerData.triggers.latency, ...enOb },
				},
			},
			
		}
		
	
		Object.assign(this.alertsTriggerData.triggers, default_triggers)
	}
	
	injectNewDefaultTriggers = () => {
		console.log('check')
		
		// const flag1 = !!this.alertsTriggerData?.triggers?.additional
		// const flag2 = Object.keys(this.alertsTriggerData?.triggers?.additional)
		

		// if (flag1) return
		// const enOb = { enabled: true }

		// coz audio triggers don't have 'additional' field right now
		if (this.alertType === 'audio')
			return
		
		const injectTrigger = {
			additional: {
				sip: {
					enabled: this.alertsTriggerData?.triggers?.additional?.sip?.enabled ?? true,
				},
				camera: {
					enabled: this.alertsTriggerData?.triggers?.additional?.camera?.enabled ?? true,
				},
				mic: {
					enabled: this.alertsTriggerData?.triggers?.additional?.mic?.enabled ?? true,
				},
				temperature: {
					enabled: this.alertsTriggerData?.triggers?.additional?.temperature?.enabled ?? true,
				},
				gatekeeper: {
					enabled: this.alertsTriggerData?.triggers?.additional?.gatekeeper?.enabled ?? true,
				},
				SSL: {
					selfSignedAlert: {
						enabled: this.alertsTriggerData?.triggers?.additional?.SSL?.selfSignedAlert?.enabled ?? true,
					},
					expiryAlert: {
						enabled: this.alertsTriggerData?.triggers?.additional?.SSL?.expiryAlert?.enabled ?? true,
						daysBeforeExpiry: this.alertsTriggerData?.triggers?.additional?.SSL?.expiryAlert?.daysBeforeExpiry ?? 30,
					},
				},
			},
		}
		
		console.log('before: ',this.alertsTriggerData.triggers)
		
		Object.assign(this.alertsTriggerData.triggers, injectTrigger)
		
		console.log('after: ',this.alertsTriggerData.triggers)
		
		
	}
}
