import { Subscription } from 'rxjs'
import { TableFilterService } from 'src/app/services/table-filter.service'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'

import { GetVideoService } from '../../../services/get-video.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-video-site-panel',
  templateUrl: './video-site-panel.component.html',
  styleUrls: ['./video-site-panel.component.scss'],
})
export class VideoSitePanelComponent implements OnInit, OnDestroy {
  PopupTitle: string = 'Video Site';
  public videoSitesData: any;
  public videositesStats: any;
  public videositesInfo: any;
  id: number;
  videoApiData: any = [];
  videoDeviceApiSummary: any = [];
  videoDevicesListData: MatTableDataSource<any>;
  apiTimers: any;
  refreshData: any;
  // listData: LocalDataSource;
  refreshListner: Subscription;
  filterListner: Subscription;
  baseFilter: any = 'all';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private refreshApiService: RefreshApiService,
    private tableFilterService: TableFilterService
  ) {
    // this.listData = new LocalDataSource();
    this.videoDevicesListData = new MatTableDataSource();

    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    this.refreshListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });

    this.filterListner = this.tableFilterService.listen().subscribe((m: any) => {
      if (m === '') {
        this.baseFilter = 'all';
      } else {
        this.baseFilter = m;
      }
      this.videoDevicesListData.filter = '-'.trim().toLocaleLowerCase();
    });

    this.videoDevicesListData.filterPredicate = (data: any, filter: string) => {
      let dataStr =
        data.ipv4 +
        data.site_name +
        data.health +
        data.liveMeetings +
        data.company_name +
        data.room_name;

      if (this.baseFilter === 'impacted') {
        if (filter === '-' || filter === '' || filter === null) {
          return data.health < 50;
        } else {
          return (
            data.health < 50 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) !== -1
          );
        }
      } else if (this.baseFilter === 'all') {
        if (filter === '-' || filter === '' || filter == null) {
          return true;
        } else {
          return (
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter === 'oncall') {
        if (filter === '-' || filter === '' || filter == null) {
          return data.oncall == 1;
        } else {
          return (
            data.oncall === 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter === 'impactedOnly') {
        if (filter === '-' || filter === '' || filter == null) {
          return data.health < 76;
        } else {
          return (
            data.health < 76 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }
    };
  }

  getApiData() {
    if (this.refreshData) {
      this.getVideoApiData();
    }
  }

  ngOnInit() {
    // console.log('i am inside video site panel component')
    this.getVideoApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getVideoApiData() {
    this.getVideoDataService.apiGetVideoCompanySiteInfo(this.id).subscribe((data) => {
      this.videositesInfo = data['response'];


      console.log('this.videositesInfo: ', this.videositesInfo)
    });

    this.getVideoDataService.apiGetVideoSiteDeviceSummary(this.id).subscribe((data) => {
      this.videoSitesData = data['response'][0];

      console.log('this.videoSitesData: ', this.videoSitesData)

    });

    this.getVideoDataService.apiGetVideoSiteDevicebySite(this.id).subscribe((data) => {
      this.videoApiData = data['response'];

      console.log('this.videoApiData: ', this.videoApiData)

      let arr = [];
      for (let d in this.videoApiData) {
        arr.push(this.videoApiData[d]);
      }
      // this.listData.load(arr);
      this.videositesStats = data;
      this.videoDevicesListData.data = this.videoApiData;
    });
  }

  getStats() {
    this.getVideoDataService.fetchVideoSites().subscribe((data) => {
      let arr = [];
      for (let d in data) {
        arr.push(data[d]);
      }

      this.videoSitesData = arr.filter((obj) => {
        return obj.site_id === this.id;
      })[0];
    });

    this.getVideoDataService.fetchVideoDevices().subscribe((data) => {
      let arr = [];
      for (let d in data) {
        arr.push(data[d]);
      }
      this.videositesStats.load(arr);
    });
  }
}
