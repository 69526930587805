<div class="pexipform">
	<div class="pexipform__container">
		<div class="pexipform__label">
			URL
			<!-- <span class="pexipform__disable">
        <a href="{{ href1 }}" target="_blank">
          <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span> -->
		</div>
		<input class="pexipform__input" value="url" [(ngModel)]="pexipAccount.url" type="text" placeholder="URL" />
	</div>

	<div class="pexipform__container">
		<div class="pexipform__label">
			Username
			<!-- <span class="pexipform__disable">
        <a href="{{ href2 }}" target="_blank">
          <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span> -->
		</div>
		<input class="pexipform__input" value="username" [(ngModel)]="pexipAccount.username" type="text" placeholder="Username" />
	</div>

	<div class="pexipform__container">
		<div class="pexipform__label">
			Password
			<!-- <span class="pexipform__disable">
        <a href="{{ href2 }}" target="_blank">
          <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span> -->
		</div>
		<input class="pexipform__input" value="password" [(ngModel)]="pexipAccount.password" type="password" placeholder="Password" />
	</div>

	<div class="pexipform__button-blank-container">
    <div class="pexipform__blank-container"></div>
    <div class="pexipform__button-container">
      <!-- <div class="d-flex flex-column">
        <div class="pexipform__other-info">
          <strong>Event Notification Endpoint URL:</strong>
          <div>{{ pexipWebHook }}</div>
        </div>
  
        <div class="pexipform__other-info">
          <strong>OAuth Redirect URL:</strong>
          <div>{{ oAuthRedirectURL }}</div>
        </div>
        
      </div> -->

      <div *ngIf="!isLoading">
        <!-- <div class="pexipform__btn" *ngIf="showButtonForAuthLink">
          <button (click)="updateApiKey()" class="loader-img" [disabled]="isSaveLoader" [style]="isSaveLoader?{'opacity':0.5}:{}"><img [src]="loader" *ngIf="isSaveLoader"> Save</button>
        </div> -->
  
        <div class="pexipform__btn" *ngIf="pexipAccount">
          <button (click)="onAuthorize()">Authorize</button>
        </div>
      </div>
    </div>
  </div>
</div>
