import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { GetVideoService } from 'src/app/services/get-video.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-allow-access',
  templateUrl: './allow-access.component.html',
  styleUrls: ['./allow-access.component.scss']
})
export class AllowAccessComponent implements OnInit, OnChanges {
  @Input('data') data ?:any;
  @Input('device_type') device_type : string = '';
  loader: String = environment.config.dynamicImage.loader;
  isShow: boolean = false;
  is_pexip_access: boolean = false;

  constructor(private _pexipService:GetVideoService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data && this.data){
      this.is_pexip_access = this.data?.is_pexip_access
    }
  }
  allowAccess() {
    this.isShow = true;
    const body = {
      device_id : this.data?.video_device_id,
      device_type : this.device_type?.toLocaleUpperCase(),
      is_access : this.is_pexip_access
    }
    this._pexipService.allowAccess(body)
    .pipe(finalize(() => this.isShow = false))
    .subscribe((resp)=>{
      if(!resp?.error){
      }
      else{
        this.is_pexip_access =  !!this.data?.is_pexip_access
      }
    })
  }

}
