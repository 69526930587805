<div class="teams-room-device-health-button">
  <div class="teams-room-device-health-button__image-text-container" *ngIf="quadrantType !== 'speaker'">
    <div class="position-relative">
      <img class="teams-room-device-health-button__image-text-container__clickable" src="{{ getQuadrantImage() }}"
      alt="Click to view device heal records." /> 
      <span class="badge" *ngIf="isLogitechInfo && deviceStatus === RED" [matTooltip]="'Disconnected ' + quadrantType + 's: ' + ConnectionState">
        {{ ConnectionState }}
      </span>

    </div>
    <div class="teams-room-device-health-button__image-text-container-two-rows">
      <div class="teams-room-device-health-button__inner-image-text-container">
        <div class="teams-room-device-health-button__inner-text-1">
          <h6>
            Primary
          </h6>
        </div>
        <img *ngIf="quadrantType !== 'zoom' && quadrantType !== 'teams'" src="{{deviceStatus}}" alt="" />
      </div>
      <div class="teams-room-device-health-button__inner-image-text-container">
        <div class="teams-room-device-health-button__inner-text-2 teams-room-device-health-button__inner-lower-text zoom-camera-device2">
          <h6>
            {{ device ? device : 'Primary' }}
          </h6>
          <div *ngIf="device" class="tooltip">
              <strong>Device Name:</strong> {{device}}<br />
              <span *ngIf="firmwareVersion"><strong>Firmware Version:</strong> {{firmwareVersion}} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- speaker -->
  <div class="teams-room-device-health-button__image-text-container" *ngIf="quadrantType == 'speaker'"
    style="margin-top: 5px">
    <div class="teams-room-device-health-button__speaker-container">
      <div style="display: flex; justify-content: center; 
             padding-left: 50px;height:1px;">{{masterVolume === -1? 'N/A': masterVolume}}</div>
      <div class="teams-room-device-health-button__speaker">
        <img class="teams-room-device-health-button__image-text-container__clickable" src="{{ getQuadrantImage() }}"
          alt="Click to view device heal records." />
        <div class="teams-room-device-health-button__speaker-slider">
          <div class="btn_volume__i">
            <i class="fa fa-minus"></i>
          </div>
          <input #sliderInput class="slider1" type="range" id="btnVolumeSlider" min="0" max="100"
            value="{{masterVolume === -1? 0: masterVolume}}" (change)="handleSliderValue(sliderInput.value)" />
          <div class="btn_volume__i">
            <i class="fa fa-plus"></i>
          </div>
        </div>
      </div>
      <div class="teams-room-device-health-button__speaker-text_container">
        <div class="teams-room-device-health-button__speaker-text">
          <div class=" teams-room-device-health-button__arrow">
            <img src="{{deviceStatus}}" alt="">
          </div>
          {{ device ? device : 'Primary' }}
        </div>
        <div *ngIf="logitechSync && deviceTwo.length" class="teams-room-device-health-button__speaker-text">
          <div class=" teams-room-device-health-button__arrow">
            <img src="{{deviceTwoStatus}}" alt="">
          </div>
          {{ deviceTwo ? deviceTwo : 'Primary' }}
        </div>
      </div>
    </div>
  </div>
</div>
