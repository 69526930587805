import * as Chart from 'chart.js'
import * as echarts from 'echarts'
import * as moment from 'moment-timezone'
import { AuthService } from 'src/app/services/auth.service'
import { GetVideoService } from 'src/app/services/get-video.service'

import { DatePipe } from '@angular/common'
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

@Component({
	selector: 'app-chart-video-dna',
	templateUrl: './chart-video-dna.component.html',
	styleUrls: ['./chart-video-dna.component.scss'],
})
export class ChartVideoDnaComponent implements OnChanges {
	toggleValue: any = 0
	activeBtn: any = 1
	areNumbersRounded = true
	printMode: boolean = false
	isLoading: boolean = false
	printTableData = []
	currentChart = '24h'
	@Input() deviceId: any
	@Input() popupState: any
	@Input() data: any
	@Input() deviceType: any
	public constructor(
		private _detector: ChangeDetectorRef,
		private Auth: AuthService,
		private datePipe: DatePipe,
		private _videoService: GetVideoService
	) {}
	filterType: string = 'all'
	@ViewChild('valueTooltip', { static: true })
	public valueTooltip: TemplateRef<object>
	includedProperties = []
	canvas: any
	tempPopupState: any
	myChartSite: any
	ctx: any
	chartApiData: any
	graphData: any
	datasetFirstAllFilter: any
	datasetSecondAllFilter: any
	datasetThirdAllFilter: any
	chartData = {
		datasets: [
			{
				label: 'incident',
				data: [],
				backgroundColor: 'rgb(184, 58, 36, 1)',
				pointBackgroundColor: 'rgb(184, 58, 36, 1)',
				pointBorderColor: 'rgb(184, 58, 36, 1)',
				borderWidth: 1,
				showLine: false,
				hidden: false,
			},
			{
				label: 'mbps (mb/s)',
				data: [],
				backgroundColor: 'rgb(255, 184, 0, 1)',
				pointBackgroundColor: 'rgb(211,211,211, 1)',
				pointBorderColor: 'rgb(211,211,211, 1)',
				borderColor: 'rgb(211,211,211, 1)',
				borderWidth: 1,
				hidden: false,
				fill: false,
			},
			{
				label: 'jitter (msec)',
				data: [],
				backgroundColor: 'rgb(173,216,230, 1)',
				pointBackgroundColor: 'rgb(173,216,230, 1)',
				pointBorderColor: 'rgb(173,216,230, 1)',
				borderColor: 'rgb(173,216,230, 1)',
				borderWidth: 0,
				showLine: true,
				fill: false,
				hidden: false,
			},
			{
				label: 'latency (msec)',
				data: [],
				backgroundColor: 'rgb(144,238,144, 1)',
				pointBackgroundColor: 'rgb(144,238,144, 1)',
				pointBorderColor: 'rgb(144,238,144, 1)',
				borderColor: 'rgb(142, 204, 80, 1)',
				borderWidth: 1,
				showLine: true,
				fill: false,
				hidden: false,
			},
		],
	}
	// --- E-Chart Configuration --------------------------------//
	@ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef

	chart!: echarts.ECharts
	fullData: any[] = []
	timeMode: string = '60m'

	dummyData: any = {
		datasets: [
			{
				label: 'Incident',
				color: 'rgb(184, 58, 36)',
				data: this.generateData(60),
			},
			{
				label: 'Mbps (mb/s)',
				color: 'rgb(255, 184, 0)',
				data: this.generateData(60),
			},
			{
				label: 'Jitter (msec)',
				color: 'rgb(173, 216, 230)',
				data: this.generateData(60),
			},
			{
				label: 'Latency (msec)',
				color: 'rgb(144, 238, 144)',
				data: this.generateData(60),
			},
		],
	}

	ngAfterViewInit() {
		this.chart = echarts.init(this.chartContainer.nativeElement)
		this.fullData = this.data.datasets
		this.updateChart(this.timeMode)
	}

	updateChart(mode: string) {
		this.timeMode = mode
		const now = new Date()
		let filteredData = this.fullData.map((dataset: any) => ({
			...dataset,
			data: dataset.data.filter(([timestamp]: [string, number]) => {
				const time = new Date(timestamp)
				if (mode === '60m') return time >= new Date(now.getTime() - 60 * 60 * 1000)
				if (mode === '24h') return time >= new Date(now.getTime() - 24 * 60 * 60 * 1000)
				if (mode === '7d') return time >= new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
				return false
			}),
		}))

		let timeFormat = '{HH}:{mm}'
		if (mode === '24h') timeFormat = '{HH}:00'
		if (mode === '7d') timeFormat = '{MM}-{dd}'

		const series = filteredData.map((dataset: any) => ({
			name: dataset.label,
			type: 'scatter', // Ensure scatter plot
			data: dataset.data,
			symbolSize: 8,
			itemStyle: { color: dataset.color },
		}))

		const options = {
			tooltip: { trigger: 'axis' },
			xAxis: { type: 'time', axisLabel: { formatter: timeFormat } },
			legend: { data: this.data.datasets.map((d: any) => d.label) },
			yAxis: { type: 'value' },
			series,
		}

		this.chart.setOption(options)
	}

	generateData(minutes: number) {
		const now = new Date()
		return Array.from({ length: minutes }, (_, i) => {
			const time = new Date(now.getTime() - i * 60 * 1000)
			return [time.toISOString(), Math.floor(Math.random() * 100)]
		}).reverse()
	}

	// ----------------------------------------------------------------//
	ngOnInit(): void {
		// this.data = this.dummyData
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.popupState) {
			this.tempPopupState = changes
		} else {
			changes = this.tempPopupState
			this.myChartSite.destroy()
		}
		if (changes.popupState.currentValue == 'open') {
			this.canvas = document.getElementById('myChartSite')
			this.ctx = this.canvas.getContext('2d')
			this.myChartSite = new Chart(this.ctx, {
				type: 'line',
				// data: this.data24h,
				options: {
					responsive: false,
					display: true,
					fontSize: '6px',
					padding: '0px',
					maintainAspectRatio: false,
					elements: {
						line: {
							tension: 0, // disables bezier curves
						},
					},
				},
			})

			this.currentChart = '60m'
			this.activeBtn = 0
			this.toggleValue = 0
			this.toggleBtn(1)
			this.includedProperties = ['x', this.data.section, 'incident', 'video', 'audio', , 'content']
			if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
				this.chartData.datasets[1].label = 'mbps Total'
				this.chartData.datasets[2].label = 'mbps RX'
				this.chartData.datasets[3].label = 'mbps TX'
			} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
				this.chartData.datasets[1].label = 'Jitter (msec) Total'
				this.chartData.datasets[2].label = 'Jitter (msec) RX'
				this.chartData.datasets[3].label = 'Jitter (msec) TX'
			} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
				this.chartData.datasets[1].label = 'Packet Loss(%) Total'
				this.chartData.datasets[2].label = 'Packet Loss(%) RX'
				this.chartData.datasets[3].label = 'Packet Loss(%) TX'
			} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
				this.chartData.datasets[1].label = 'Latency Total'
				this.chartData.datasets[2].label = 'Latency RX'
				this.chartData.datasets[3].label = 'Latency TX'
			}
			this.fetchChartData(1)
		} else {
			setTimeout(() => {
				this.myChartSite.destroy()
			}, 500)
		}
	}
	setNewFilter(filterType: string) {
		this.filterType = filterType
		if (filterType === 'all')
			this.includedProperties = ['x', this.data.section, 'incident', 'video', 'audio', 'content']
		else if (filterType === 'video') this.includedProperties = ['x', this.data.section, 'video', 'incident']
		else if (filterType === 'audio') this.includedProperties = ['x', this.data.section, , 'audio', 'incident']
		else if (filterType === 'content') this.includedProperties = ['x', this.data.section, , 'content', 'incident']

		let dataSection = ''
		if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
			dataSection = 'mbps'
		} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
			dataSection = 'jitter'
		} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
			dataSection = 'packetsloss'
		} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
			dataSection = 'latency'
		}
		if (filterType !== 'all') {
			let datasetFirstFiltered: any[] = []
			let datasetSecondFiltered: any[] = []
			let datasetThirdFiltered: any[] = []
			this.graphData[filterType].forEach(e => {
				let total = 0
				let rx = 0
				let tx = 0
				if (e[filterType + 'Rx'] !== null && e[filterType + 'Tx'] !== null) {
					if (dataSection === 'mbps') {
						total = (e[filterType + 'Rx'][dataSection] + e[filterType + 'Tx'][dataSection]) / 1048576
						rx = e[filterType + 'Rx'][dataSection] / 1048576
						tx = e[filterType + 'Tx'][dataSection] / 1048576
						total = parseFloat(total.toFixed(2))
						rx = parseFloat(rx.toFixed(2))
						tx = parseFloat(tx.toFixed(2))
					} else {
						total = e[filterType + 'Rx'][dataSection] + e[filterType + 'Tx'][dataSection]
						rx = e[filterType + 'Rx'][dataSection]
						tx = e[filterType + 'Tx'][dataSection]
					}
					datasetFirstFiltered.push({
						x: e.x,
						y: total !== 0 ? total : null,
					})
					datasetSecondFiltered.push({
						x: e.x,
						y: rx !== 0 ? rx : null,
					})
					datasetThirdFiltered.push({
						x: e.x,
						y: tx !== 0 ? tx : null,
					})
				} else {
					datasetFirstFiltered.push({
						x: e.x,
						y: null,
					})
					datasetSecondFiltered.push({
						x: e.x,
						y: null,
					})
					datasetThirdFiltered.push({
						x: e.x,
						y: null,
					})
				}
			})

			this.chartData.datasets[1].data = datasetFirstFiltered
			this.chartData.datasets[2].data = datasetSecondFiltered
			this.chartData.datasets[3].data = datasetThirdFiltered
			this.loadChart()

			// });
		} else {
			this.chartData.datasets[1].data = this.datasetFirstAllFilter
			this.chartData.datasets[2].data = this.datasetSecondAllFilter
			this.chartData.datasets[3].data = this.datasetThirdAllFilter
			this.loadChart()
		}
	}
	toggleView(n) {
		if (this.toggleValue == n) {
			this.toggleValue = 0
		} else {
			this.toggleValue = n
		}
	}
	toggleBtn(n) {
		if (this.activeBtn == n) {
			this.activeBtn = 0
		} else {
			this.activeBtn = n
		}
	}

	toggleNumberView() {
		this.areNumbersRounded = !this.areNumbersRounded
		this._videoService.showFullNumbers.next(!this.areNumbersRounded)
	}
	getPrintData(data) {
		this.printTableData = data
	}
	fetchChartData(d) {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)

		let deviceDetails = {
			company_id: decodedToken.company_id,
			device_id: this.data.deviceId,
			duration: d,
		}

		// this.chartApiData = [];
		this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
			this.chartApiData = data['response']

			// this.chartApiData = [];
			this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
				this.graphData = data.response[0]
				let datasetFirst
				let datasetSecond
				let datasetThird
				if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
					datasetFirst = data['response'][0].mbps_total
					datasetSecond = data['response'][0].mbps_in
					datasetThird = data['response'][0].mbps_out
				} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
					datasetFirst = data['response'][0].total_jitter
					datasetSecond = data['response'][0].jitter_in
					datasetThird = data['response'][0].jitter_out
				} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
					datasetFirst = data['response'][0].total_packetloss
					datasetSecond = data['response'][0].packetloss_in
					datasetThird = data['response'][0].packetloss_out
				} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
					datasetFirst = data['response'][0].total_latency
					datasetSecond = data['response'][0].latency_in
					datasetThird = data['response'][0].latency_out
				}
				datasetFirst.sort(this.custom_sort)
				datasetSecond.sort(this.custom_sort)
				datasetThird.sort(this.custom_sort)
				let latency = data['response'][0].total_latency
				latency.sort(this.custom_sort)
				let jitter = data['response'][0].total_jitter
				jitter.sort(this.custom_sort)
				let mbps = data['response'][0].mbps_total
				mbps.sort(this.custom_sort)
				let packetloss = data['response'][0].total_packetloss
				packetloss.sort(this.custom_sort)
				let incidents = data['response'][0].incidents
				// incidents.sort(this.custom_sort);

				for (let x = 0; x < datasetFirst.length; x++) {
					if (datasetFirst[x].y === 0) {
						datasetFirst[x].y = null
					}
				}
				for (let x = 0; x < datasetSecond.length; x++) {
					if (datasetSecond[x].y === 0) {
						datasetSecond[x].y = null
					}
				}
				for (let x = 0; x < datasetThird.length; x++) {
					if (datasetThird[x].y === 0) {
						datasetThird[x].y = null
					}
				}
				// this.chartData.datasets[0].data = data['response'][0].incident;
				this.chartData.datasets[0].data = incidents
				this.chartData.datasets[1].data = datasetFirst
				this.chartData.datasets[2].data = datasetSecond
				this.chartData.datasets[3].data = datasetThird
				this.datasetFirstAllFilter = datasetFirst
				this.datasetSecondAllFilter = datasetSecond
				this.datasetThirdAllFilter = datasetThird
				// OLD IMPLEMENTATION START
				// this.chartData.datasets[1].data = mbps;
				// this.chartData.datasets[2].data = jitter;
				// this.chartData.datasets[3].data = latency;
				// this.chartData.datasets[4].data = packetloss;
				// OLD IMPLEMENTATION END
				if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
					this.chartData.datasets[1].hidden = !('mbps' == this.data.section)
					this.chartData.datasets[2].hidden = !('mbps_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('mbps_out' == this.data.section)
				} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
					this.chartData.datasets[1].hidden = !('jitter' == this.data.section)
					this.chartData.datasets[2].hidden = !('jitter_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('jitter_out' == this.data.section)
				} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
					this.chartData.datasets[1].hidden = !('packetloss' == this.data.section)
					this.chartData.datasets[2].hidden = !('packetloss_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('packetloss_out' == this.data.section)
				} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
					this.chartData.datasets[1].hidden = !('latency' == this.data.section)
					this.chartData.datasets[2].hidden = !('latency_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('latency_out' == this.data.section)
				}
				// OLD IMPLEMENTATION START
				// this.chartData.datasets[1].hidden = !('mbps' == this.data.section);
				// this.chartData.datasets[2].hidden = !('jitter' == this.data.section);
				// this.chartData.datasets[3].hidden = !('latency' == this.data.section);
				// this.chartData.datasets[4].hidden = !('packetloss' == this.data.section);
				// OLD IMPLEMENTATION END
				this.loadChart()
			})
		})
		// this.allInsListData = [];
		// this.Auth.getAllInsChartListData(deviceDetails).subscribe((data) => {
		//   let myResponse = data['response'];
		//   let arr3 = [];
		//   for (let k = 0; k < myResponse.length; k++) {
		//     let sectionData = this.getSectionDataForTable(myResponse[k]);
		//     arr3.push({
		//       timestamp: new Date(myResponse[k].timestamp),
		//       totalSectionData: sectionData,
		//       totalRx: this.getRxForTable(myResponse[k]),
		//       totalTx: this.getTxForTable(myResponse[k]),
		//       videoTotal: this.getTypeContentTotal(myResponse[k], 'video_'),
		//       videoRx: this.getTypeContent(myResponse[k], 'video_', 'rx'),
		//       videoTx: this.getTypeContent(myResponse[k], 'video_', 'tx'),
		//       audioTotal: this.getTypeContentTotal(myResponse[k], 'audio_'),
		//       audioRx: this.getTypeContent(myResponse[k], 'audio_', 'rx'),
		//       audioTx: this.getTypeContent(myResponse[k], 'audio_', 'tx'),
		//       contentTotal: this.getTypeContentTotal(myResponse[k], 'content_'),
		//       contentRx: this.getTypeContent(myResponse[k], 'content_', 'rx'),
		//       contentTx: this.getTypeContent(myResponse[k], 'content_', 'tx'),
		//       incident_id: myResponse[k].video_incident_id
		//     });
		//   }
		//   //Loads the API json data into the company list data source
		//   this.printTableData = arr3;
		// });
	}
	loadChart() {
		// this.myChartSite.reset();
		this.myChartSite.options.scales = {
			xAxes: [
				{
					type: 'time',
					display: true,
					scaleLabel: {
						display: true,
						labelString: this.currentChart == '7d' ? 'Date' : 'Time',
					},
					time: {
						unit: this.currentChart == '7d' ? 'day' : this.currentChart == '24h' ? 'hour' : 'minute',
					},
				},
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		}

		this.myChartSite.options.tooltips = {
			callbacks: {
				label: function (tooltipItem, chart) {
					if (tooltipItem.datasetIndex == 0) {
						return 'ID: ' + chart.datasets[0].data[tooltipItem.index].incident_id
					} else {
						return tooltipItem.yLabel
					}
				},
				beforeLabel: function (tooltipItem, chart) {
					switch (tooltipItem.datasetIndex) {
						case 0:
							return 'Incidents'
						case 1:
							return chart.datasets[1].label
						// return "mbps";
						case 2:
							return chart.datasets[2].label
						// return "Jitter";
						case 3:
							return chart.datasets[3].label
						// return "Latency";
						case 3:
							return 'Packetloss'
					}
				},
				afterLabel: function (tooltipItem, chart) {
					if (tooltipItem.datasetIndex == 0) {
						let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(' - ')
						triggers.splice(0, 1)
						return triggers
					} else {
						return ''
					}
				},
				title: function (tooltipItem, chart) {
					return moment(tooltipItem[0].xLabel)
						.utc()
						.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
						.format('MM-DD-YYYY hh:mma')
				},
			},
		}
		this.myChartSite.data.datasets = this.chartData.datasets
		this.myChartSite.update({ duration: 500 })
	}
	custom_sort(a, b) {
		return new Date(a.x).getTime() - new Date(b.x).getTime()
	}
	getMax(arr, prop) {
		var max
		for (var i = 0; i < arr.length; i++) {
			if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop])) max = arr[i]
		}
		return max
	}
	public onSeriesAdded(e: any) {
		if (e.args.series) {
			e.args.series.tooltipTemplate = this.valueTooltip
		}
	}
	getDateObject(object: string, chartData: string) {
		let date = new Date(object)
		if (chartData === '7d') return this.datePipe.transform(date, 'MMM d')
		else if (chartData === '24h') return this.datePipe.transform(date, 'hh:mm a')
		else return this.datePipe.transform(date, 'hh:mm a')
	}
	set60m() {
		if (this.currentChart != '60m') {
			this.currentChart = '60m'
			this.toggleBtn(1)
			this.fetchChartData(1)
		}
	}
	set24h() {
		if (this.currentChart != '24h') {
			this.currentChart = '24h'
			this.toggleBtn(2)
			this.fetchChartData(24)
		}
	}
	set7d() {
		if (this.currentChart != '7d') {
			this.currentChart = '7d'
			this.toggleBtn(3)
			this.fetchChartData(7)
		}
	}

	print() {
		var divId
		if (this.toggleValue == 0) {
			// chart
			this.printCanvas()
		} else {
			// list
			this.printList()
		}
	}

	printCanvas() {
		let printContents = (<HTMLCanvasElement>document.getElementById('myChartSite')).toDataURL()
		let popupWin
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
		popupWin.document.open()
		popupWin.document.write(`
      <html>
        <head>
          <title>Print Chart</title>
        </head>
        <body onload="window.print(); window.close()">
          <img style='width: 100%;' src='${printContents}'/>
        </body>
      </html>
    `)
		popupWin.document.close()
	}

	printList() {
		this.printMode = true
		setTimeout(() => {
			let printContents, stylesHtml, linksHtml, popupWin
			printContents = document.getElementById('printTable').innerHTML
			popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
        <html>
          <head>
            <title>Print Report</title>
            ${stylesHtml}
            ${linksHtml}
          </head>
          <body onload="window.print(); window.close()">
            ${printContents}
          </body>
        </html>
      `)
			popupWin.document.close()
			this.printMode = false
		}, 500)
	}
}
