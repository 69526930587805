<div *ngIf="isLoading" style="height: 600px">
  <app-loader></app-loader>
</div>
<div id="chartParent" class="info-chartSite pt-3 mb-3 chart-container"
  [ngStyle]="{ display: isLoading == false ? 'block' : 'none' }" style="cursor: auto !important">
  <div>
    <div style="margin-left: 600px">
      <div [ngStyle]="{ display: toggleValue == 0 ? 'block' : 'none' }"
        style="margin-left: -300px; margin-bottom: -55px">
        <button style="width: 65px" (click)="setNewFilter_EChart('all')"
          [ngClass]="{ btnpressed: filterType == 'all' }">
          <img style="height: 40px" src="../../assets/img/video/all_filter.png" />
        </button>
        <button style="width: 65px" (click)="setNewFilter_EChart('video')"
          [ngClass]="{ btnpressed: filterType == 'video' }">
          <img style="height: 40px" src="../../assets/img/video/video_filter.png" />
        </button>
        <button style="width: 65px" (click)="setNewFilter_EChart('audio')"
          [ngClass]="{ btnpressed: filterType == 'audio' }">
          <img style="height: 40px" src="../../assets/img/video/audio_filter.png" />
        </button>
        <button style="width: 65px" (click)="setNewFilter_EChart('content')"
          [ngClass]="{ btnpressed: filterType == 'content' }">
          <img style="height: 40px" src="../../assets/img/video/content_filter.png" />
        </button>
      </div>
      <!-- <button (click)="set60m()" [ngClass]="{ btnpressed: activeBtn == 1 }"> -->
      <button (click)="set60m_EChart()" [ngClass]="{ btnpressed: activeBtn == 1 }">
        60m
      </button>
      <!-- <button (click)="set24h()" [ngClass]="{ btnpressed: activeBtn == 2 }"> -->
      <button (click)="set24h_EChart()" [ngClass]="{ btnpressed: activeBtn == 2 }">
        24h
      </button>
      <!-- <button (click)="set7d()" [ngClass]="{ btnpressed: activeBtn == 3 }"> -->
      <button (click)="set7d_EChart()" [ngClass]="{ btnpressed: activeBtn == 3 }">
        7d
      </button>
      <div style="padding-left: 50px !important; display: inline">
        <label class="switch">
          <input type="checkbox" [checked]="toggleValue == 0" (click)="toggleView(1)" />
          <span class="slider round">
            <span class="paused">chart</span>
            <span class="enabled" style="padding-right: 200px">list</span>
          </span>
        </label>
      </div>
      <div style="padding-left: 100px !important; display: inline" *ngIf="toggleValue == 1">
        <label class="switch">
          <input type="checkbox" [checked]="areNumbersRounded" (click)="toggleNumberView()" />
          <span class="slider round">
            <span class="paused" style="left: 99px">Rounded</span>
            <span class="enabled" style="padding-right: 210px">Full</span>
          </span>
        </label>
      </div>
      <div class="printer-border print-btn" style="float: right">
        <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
      </div>
    </div>
  </div>
  <div class="list-view" style="display: none; cursor: pointer !important"
    [ngStyle]="{ display: toggleValue == 1 ? 'block' : 'none' }">
    <div class="row">
      <app-video-all-incidents-list [data]="data" [deviceType]="deviceType" [currentChart]="currentChart"
        (printData)="getPrintData($event)"></app-video-all-incidents-list>
    </div>
  </div>
  <!-- <canvas id="myChartSite" width="1150px" height="350px" style="padding-left: 150px"
    [ngStyle]="{ display: toggleValue == 0 ? 'block' : 'none' }"></canvas> -->
  <div #chartContainer id="echartContainer" class="echart-video-dna-container">

  </div>

  <div class="wrap" id="printTable" *ngIf="printMode">
    <table class="head">
      <tr style="font-weight: bold">
        <td style="width: 10%">Time Stamp</td>
        <td style="width: 10%">Live Mbps</td>
        <td style="width: 5%" class="details">Rx</td>
        <td style="width: 5%" class="details">Tx</td>
        <td style="width: 10%">Latency</td>
        <td style="width: 5%" class="details">Rx</td>
        <td style="width: 5%" class="details">Tx</td>
        <td style="width: 10%">Jitter</td>
        <td style="width: 5%" class="details">Rx</td>
        <td style="width: 5%" class="details">Tx</td>
        <td style="width: 10%">Packet Loss</td>
        <td style="width: 5%" class="details">Rx</td>
        <td style="width: 5%" class="details">Tx</td>
        <td style="width: 10%">Incidents Id</td>
      </tr>
    </table>
    <div class="inner_table">
      <table *ngFor="let data of printTableData" style="border-bottom-style: solid">
        <tr>
          <td style="width: 10%">
            {{ data.timestamp | date : "yy/MM/dd, h:mm a" }}
          </td>
          <td style="width: 9%">{{ data.totalSectionData.mbps }}</td>
          <td style="width: 5%" class="details">{{ data.totalRx.mbps }}</td>
          <td style="width: 5%" class="details">{{ data.totalTx.mbps }}</td>

          <td style="width: 9%">{{ data.totalSectionData.latency }}</td>
          <td style="width: 5%" class="details">{{ data.totalRx.latency }}</td>
          <td style="width: 5%" class="details">{{ data.totalTx.latency }}</td>

          <td style="width: 9%">{{ data.totalSectionData.jitter }}</td>
          <td style="width: 5%" class="details">{{ data.totalRx.jitter }}</td>
          <td style="width: 5%" class="details">{{ data.totalTx.jitter }}</td>

          <td style="width: 9%">{{ data.totalSectionData.packetloss }}</td>
          <td style="width: 5%" class="details">
            {{ data.totalRx.packetloss }}
          </td>
          <td style="width: 5%" class="details">
            {{ data.totalTx.packetloss }}
          </td>

          <td style="width: 9%">{{ data.incident_id }}</td>
        </tr>
        <tr>
          <td>Video</td>
          <td>{{ data.videoTotal.mbps }}</td>
          <td class="details">{{ data.videoRx.mbps }}</td>
          <td class="details">{{ data.videoTx.mbps }}</td>
          <td>{{ data.videoTotal.mbps }}</td>
          <td class="details">{{ data.videoRx.latency }}</td>
          <td class="details">{{ data.videoTx.latency }}</td>
          <td>{{ data.videoTotal.jitter }}</td>
          <td class="details">{{ data.videoRx.jitter }}</td>
          <td class="details">{{ data.videoTx.jitter }}</td>
          <td>{{ data.videoTotal.packetloss }}</td>
          <td class="details">{{ data.videoRx.packetloss }}</td>
          <td class="details">{{ data.videoTx.packetloss }}</td>
          <td></td>
        </tr>
        <tr>
          <td>Audio</td>
          <td>{{ data.audioTotal.mbps }}</td>
          <td class="details">{{ data.audioRx.mbps }}</td>
          <td class="details">{{ data.audioTx.mbps }}</td>
          <td>{{ data.audioTotal.mbps }}</td>
          <td class="details">{{ data.audioRx.latency }}</td>
          <td class="details">{{ data.audioTx.latency }}</td>
          <td>{{ data.audioTotal.jitter }}</td>
          <td class="details">{{ data.audioRx.jitter }}</td>
          <td class="details">{{ data.audioTx.jitter }}</td>
          <td>{{ data.audioTotal.packetloss }}</td>
          <td class="details">{{ data.audioRx.packetloss }}</td>
          <td class="details">{{ data.audioTx.packetloss }}</td>
          <td></td>
        </tr>
        <tr>
          <td>Content</td>
          <td>{{ data.contentTotal.mbps }}</td>
          <td class="details">{{ data.contentRx.mbps }}</td>
          <td class="details">{{ data.contentTx.mbps }}</td>
          <td>{{ data.contentTotal.mbps }}</td>
          <td class="details">{{ data.contentRx.latency }}</td>
          <td class="details">{{ data.contentTx.latency }}</td>
          <td>{{ data.contentTotal.jitter }}</td>
          <td class="details">{{ data.contentRx.jitter }}</td>
          <td class="details">{{ data.contentTx.jitter }}</td>
          <td>{{ data.contentTotal.packetloss }}</td>
          <td class="details">{{ data.contentRx.packetloss }}</td>
          <td class="details">{{ data.contentTx.packetloss }}</td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</div>

<ng-template let-series="series" let-item="item" #valueTooltip>
  <div class="tooltipVertical">
    <ng-container *ngIf="item.incidentVals.incident_id !== undefined">
      <span class="tooltipTitle">Incident<br /></span>
      <span class="tooltipLbl">ID: {{ item.incidentVals.incident_id }}<br /></span>
      <span class="tooltipLbl">Trigger: {{ item.incidentVals.triggers }}<br /></span>
    </ng-container>
    <ng-container *ngIf="item.incidentVals.incident_id === undefined">
      <span class="tooltipLbl">Date: {{ item.dateObject | date : "MM-dd-yyyy, hh:mm a" }}<br /></span>
      <span class="tooltipLbl">{{ data.section }}: {{ item[data.section] }}<br /></span>
      <span class="tooltipLbl" *ngIf="filterType === 'all' || filterType === 'video'">Video: {{ item.video
        < 0 ? 0 : item.video }}<br />
      </span>
      <span class="tooltipLbl" *ngIf="filterType === 'all' || filterType === 'audio'">Audio: {{ item.audio
        < 0 ? 0 : item.audio }}<br />
      </span>
      <span class="tooltipLbl" *ngIf="filterType === 'all' || filterType === 'content'">Content: {{ item.content
        < 0 ? 0 : item.content }}<br />
      </span>
    </ng-container>
  </div>
</ng-template>