import { AuthService } from 'src/app/services/auth.service'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { GetIotService } from '../../services/get-iot.service'

@Component({
  selector: 'app-iot-all-space-panel',
  templateUrl: './iot-all-space-panel.component.html',
  styleUrls: ['./iot-all-space-panel.component.scss']
})
export class IotAllSpacePanelComponent implements OnInit {
  filterRoute: String;
  filterStatus: String;
  filterAgent: number;
  agentName: String;
  summaryTitle: String;
  shouldShowAgent: Boolean;
  companyInfo: any;
  agentSummaryList: any = false;
  iotLiteEnabled: any = false;
  showIotLiteSite: any = false;
  showIotLiteSummary: any;
  testHealth: any = 0;



  showAllSpacesList: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    // private getCopmany: GetCompanyService,
    private getIot: GetIotService
  ) {
    this.route.params.subscribe((params) => {
      this.filterAgent = params['agentId'];
    });
    this.setSummaryConfig();
  }

  setSummaryConfig = () => {
  
    this.getIotLiteStatus();
  

    if (this.router.url == '/iot/healthy') {
      this.filterRoute = 'healthy';
      this.summaryTitle = 'IoT Spaces';
      this.showAllSpacesList = true;
      this.getAllIotSpacesSummaryList('healthy');
      return;
    }
    
    if (this.router.url === '/iot/warning') {
      this.filterRoute = 'warning';
      this.summaryTitle = 'IoT Spaces';
      this.showAllSpacesList = true;
      this.getAllIotSpacesSummaryList('warning');
      return;
    }
    
    if (this.router.url === '/iot/impacted') {
      this.filterRoute = 'impacted';
      this.summaryTitle = 'IoT Spaces';
      this.showAllSpacesList = true;
      this.getAllIotSpacesSummaryList('impacted');
      return;
    }
    
    if (this.router.url === '/iot/pause') {
      this.filterRoute = 'pause';
      this.summaryTitle = 'IoT Spaces';
      this.showAllSpacesList = true;
      this.getAllIotSpacesSummaryList('pause');
      return;
    }


    if (this.router.url == '/iot/allspaces') {
      this.filterRoute = 'all';
      this.summaryTitle = 'IoT Spaces';
      this.showAllSpacesList = true;
      this.getAllIotSpacesSummaryList('all');
      return;
    }
    

      if (this.router.url.indexOf('/iot/spaces') >= 0) {
      const arr = this.router.url.split('/')
      const getLastNumber = arr[arr.length-1]
      this.summaryTitle = `Iot Spaces: Sample Space`;
      this.shouldShowAgent = false;
      this.filterStatus = 'ALL';
      this.getAgentLinkToSpaces(getLastNumber)
      return;
    }
    

  }
  


  ngOnInit() {
    this.getCompanyInfo();
  }
  
  
  getAgentLinkToSpaces = (id) => {
    this.getIot
    .getAgentLinkToSpaces(
     '',
      id,
     100,
     0,
      0,
    )
    .subscribe((data: any) => {
    const [res] = data;
    const spaceList = data.map( e => {
      return {
      ...e,
        health: e.status == "DOWN" ? 0 : 100
      }
    }).filter( e =>  e.active);
      
    const getSpaceLength = spaceList.length;
    const getTotalHealth = spaceList.map( e => {
    if(e.paused || e.pause || e.paused) {
      return 0;
    }
    return e.health
    })
    
    console.log('spaceList: ', spaceList)
    
    console.log('getTotalHealth: ', getTotalHealth)
    
    const calculateHealth = getTotalHealth.reduce((total, num) => {
      return (total + num)
    }) / getSpaceLength;
    
    
    // console.log('here 135', calculateHealth)
    
    
    this.testHealth = Math.ceil(calculateHealth) || 0;
    this.summaryTitle = res.space_name || "Spaces Name";
      // this.dataSource = new MatTableDataSource(this.deviceList);
      // this.dataSource.sort = this.sort;
    });
  };
  
  // lets improve next time :)
  getAllIotSpacesSummaryList = (filter) => {
  
    this.getIot
    .getAllIotSpacesSummaryList(
    'null',
    100,
   0,
    'ALL',
    )
    .subscribe((data: any) => {
    let spaceList = data.map( e => {
      return {
        ...e,
        health: e.health == null ? 0 :  e.health
      
      }
    });
    
    console.log('spaceList: ', spaceList)
    
  
    if (filter == 'healthy') {
     spaceList = spaceList.filter( e => e.health > 99)    
    }
    
    if (filter == 'warning') {
     spaceList = spaceList.filter( e => e.health >= 51 &&  e.health < 99 && e.is_paused == 0)    
    }
  
    if (filter == 'impacted') {
     spaceList = spaceList.filter( e => e.health <= 50 && e.is_paused == 0)    
    }
    
    if (filter == 'pause') {
     spaceList = spaceList.filter( e => e.is_paused)    
    }
    
    const getSpaceLength = spaceList.length;
    const getTotalHealth = spaceList.map( e => {
    if(e.paused || e.pause || e.paused) {
      return 0;
    }
    return  e.health
    })
    
    console.log('getTotalHealth: ', getTotalHealth)
    
    
    const calculateHealth = getTotalHealth.reduce((total, num) => {
      return (total + num)
    }) / getSpaceLength;
    
  
    
    
    this.testHealth = Math.ceil(calculateHealth) ||  0;
    
    if (filter == 'pause') {
      this.testHealth = 0;
    }


    });
  };

  getCompanyInfo = () => {
    // this.getCopmany.apiGetCompanyInfo().subscribe((data) => {
    this.auth.getCompanyInfo().subscribe((data) => {
      if (data.status === 200) {
        this.companyInfo = data.response;
      }
    });
  };

  getAllIotAgentsSummary = () => {
    const filterAgent = this.filterAgent ? this.filterAgent : -1;
    this.getIot.getAllIotAgentsSummary(filterAgent).subscribe((data) => {
      this.agentSummaryList = data;
    });
  };

  getAllIotLiteSummary = () => {
    this.getIot.getAllIotLiteSummary().subscribe((data) => {
      this.agentSummaryList = data;
    });
  };

  onSummaryEvent = ($event) => {
    const { eventType, data } = $event;
    if (eventType === 'device') {
      this.filterStatus = data;
    }
  };

  getIotLiteStatus = () => {
    this.getIot.getIotLiteStatus().subscribe((data) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;

    });
  }
}
