<!-- <alert></alert> -->

<div class="admin">
  <!-- <div class="row lower-row"> -->

  <!------- BUTTONS ------->
  <div class="admin__left-column" *ngIf="highlightNum == 1">
    <div class="admin__button-container">
      <button type="button" class="btn btn-primary" (click)="showPexip()" [ngClass]="{ active: isPexip }" *ngIf="alertType === 'video'">
        Pexip
      </button>

      <button type="button" class="btn btn-primary" (click)="showAlerts()" [ngClass]="{ active: showData4 }">
        Alerts
      </button>

      <button *ngIf="!(alertType == 'cloud' && data.is_room === 0)" type="button" class="btn btn-primary"
        (click)="showDocumentCenter()" [ngClass]="{ active: shouldShowDocuments }">
        Documents
      </button>
      <button type="button" [ngClass]="{ active: isOnClickRoomUpdate }" class="btn btn-primary"
        (click)="editRoomName()">
        Edit room name
      </button>
      <!-- VIDEO AUDIO -->
      <div *ngIf="alertType == 'video' && data?.device_type !== 'cisco' && data?.device_type !== 'logitech'">
        <button class="btn btn-primary" (click)="showFirmwareUpdate()">
          Firmware Update
        </button>
      </div>

      <div *ngIf="alertType == 'video'  || alertType == 'audio'">
        <button class="btn btn-primary" (click)="rescanDevice()">
          ReScan Device
        </button>
      </div>

      <div *ngIf="alertType == 'video'">
        <button type="button" class="btn btn-primary" (click)="showUpdate()">
          Update Device Credentials
        </button>
      </div>
      <div *ngIf="alertType == 'video' || alertType == 'audio'">
        <button type="button" class="btn btn-primary" (click)="showDelete()">
          Delete Device
        </button>
      </div>

      <div *ngIf="alertType == 'video'">
        <button type="button" class="btn btn-primary" (click)="showUpdateIp()">
          Update IP Address
        </button>
      </div>


      <div *ngIf="alertType == 'video' && showWellnessComponent">
        <button type="button" class="btn btn-primary" (click)="showWellnessCheck()">
          Wellness Check Schedule
        </button>
      </div>

      <button *ngIf="
          alertType == 'cloud' &&
            data.plugin_id &&
            !data.site_id &&
            data.is_room === 1 &&
            !isDoneLinkingSite &&
            isShouldShowLinkSite;
          else editSiteButton
        " type="button" class="btn btn-primary" (click)="showLinkSite(true)">
        Link Site
      </button>
      <ng-template #editSiteButton>
        <button *ngIf="
            alertType == 'cloud' &&
            data.plugin_id &&
            data.is_room === 1 &&
            data.site_id !== null
          " type="button" class="btn btn-primary" (click)="showEditSite(true)">
          Edit Site
        </button>
      </ng-template>

      <button *ngIf="
          alertType == 'cloud' &&
          data.zoom_plugin_enabled === 1 &&
          data.is_room === 1
        " type="button" class="btn btn-primary" [ngClass]="{
          btn_disable: alertType == 'cloud' && data.plugin_status === -1
        }" (click)="showRebootPC(true)">
        Reboot PC
      </button>

      <button *ngIf="
          alertType == 'cloud' &&
          data.zoom_plugin_enabled === 1 &&
          data.is_room === 1
        " type="button" class="btn btn-primary" [ngClass]="{
          btn_disable: alertType == 'cloud' && data.plugin_status === -1
        }" (click)="showRebootZoom(true)">
        Restart Zoom
      </button>

      <button *ngIf="
          alertType == 'cloud' &&
          data.plugin_id &&
          data.zoom_plugin_enabled === 1 &&
          data.is_room === 1
        " type="button" class="btn btn-primary" (click)="showSetZoomSetting(true)">
        Set Zoom Setting
      </button>

      <button *ngIf="
          alertType == 'cloud' &&
          data.plugin_id &&
          data.zoom_plugin_enabled === 1 &&
          data.is_room === 1
        " type="button" class="btn btn-primary" (click)="onClickTakeRoomSnapshot()">
        Take Room Snapshot
      </button>

      <button *ngIf="
          alertType == 'cloud' &&
          data.plugin_id &&
          data.zoom_plugin_enabled === 0 &&
          data.is_room === 1
        " type="button" class="btn btn-primary" (click)="showEnablePlugin(true)">
        Enable {{ dPlugin }}
      </button>

      <button *ngIf="
          alertType == 'cloud' &&
          data.plugin_id &&
          data.zoom_plugin_enabled === 1 &&
          data.is_room === 1
        " type="button" class="btn btn-primary" (click) = "showLogging(true)">
        {{ dPlugin }} Logging
      </button>
      <!-- change for connecting plugin -->
      <!-- axe changes for edit / connect site start -->
      <!-- <button *ngIf="
          alertType == 'cloud' &&
          data.plugin_id &&
          data.zoom_plugin_enabled === 1 &&
          data.is_room === 1
        " type="button" class="btn btn-primary">
        {{ data.site_id !== null ? 'Edit Site' : 'Connect Site' }}
      </button> -->
      <!-- axe changes for edit / connect site end -->
      <!-- CLOUD  -->

      <div *ngIf="data.is_direct_access === 1 && isTeleportivityAllowed">
        <button type="button" class="btn btn-primary" (click)="showTeleportivity()">
          Teleportivity
        </button>
      </div>
    </div>
    <!-- </form> -->
  </div>

  <!------- BUTTONS ------->

  <!------- COLUMNS ------->

  <div class="admin__right-column" *ngIf="highlightNum == 1">
    <ng-template [ngIf]="isOnClickRoomUpdate">
      <div class="formMain">
        <div>
          <div class="heading">
            <strong>Please enter new room name to update.</strong>
          </div>
          <form [formGroup]="updateForm" (ngSubmit)="submitForm()">
            <input formControlName="roomName" type="text" placeholder="New Name" class="style" />
            <button type="submit" class="btn1">
              {{ isRoomBeingUpdated == true ? "Updating . . ." : "Update" }}
            </button>
          </form>
        </div>
      </div>
    </ng-template>

    <!-- NEW RESCAN  -->

    <div class="admin__container" [ngClass]="{'admin__container-audio': alertType == 'audio' }" *ngIf="showData">
      <div class="admin__right-column">
        <div *ngIf="showErrMsg" class="admin__error-message">
          <h3>{{errorMsg}}</h3>
        </div>

        <div *ngIf="showSearching" class="admin__right-column-search">
          <ul class="bottom">
            <li>
              <h5>Searching</h5>
            </li>
            <li>
              <div class="dot1"></div>
            </li>
            <li>
              <div class="dot2"></div>
            </li>
            <li>
              <div class="dot3"></div>
            </li>
          </ul>
        </div>
        <div *ngIf="showMsg30Sec && !showErrMsg">
          <p style="font-size: larger;">It’s taking longer than expected, but we’ll get there as fast as we can...</p>
        </div>
        <!-- <div *ngIf="showSearching" style="margin-left: 15px;">
          Note: Please avoid clicking outside dialog box to continue searching for device.
        </div> -->

        <div *ngIf="showErrorMessage" class="admin__error-message">
          <h3>{{errorMessage}}</h3>
        </div>

        <div *ngIf="showSuccessMsg" class="admin__success-message">
          <h3>Device Update Successfull </h3>
        </div>

        <!-- <div *ngIf="showAddDevice"
          class="admin__device-details-and-table-container"> -->


        <div *ngIf="showAddDevice" class="admin__device-details-and-table-container">
          <div class="admin__device-details-container" style="display: flex">
            <div class="col-5">
              <h3>Device Details</h3>
              <div class="admin__device-details-text">
                <p>Device Name: {{deviceRoomName | displayEmpty }}</p>
                <p>Model: {{deviceModel | displayEmpty}}</p>
                <p>Serial Number: {{serialNumber | displayEmpty}} </p>
                <p>IPV4: {{deviceIP | displayEmpty}}</p>
                <p>MAC: {{deviceMac | displayEmpty}}</p>
                <p>Firmware: {{firmware | displayEmpty}}</p>
                <!-- <p>Software Release: {{softwareRelease | displayEmpty}}</p> -->
                <p *ngIf="activeMic != 0 && alertType !== 'audio'">Active Mic: {{activeMic | displayEmpty}}</p>
                <p>Active Camera: {{activeCamera | displayEmpty}}</p>
              </div>

              <div *ngIf="!iotIp && !iotDevicePorts" class="admin__btn2">
                <button (click)="sendUpdateDeviceData()">Update Device</button>
                <button (click)="cancelRescanUpdate()" class="admin__btn2--cancel">Cancel</button>
              </div>
            </div>

            <div class="admin__img-container">
              <img src="../../../assets/img/icon_llama.png" />
            </div>
          </div>
        </div>

        <div [ngClass]="{'admin__iot-column-search-container': iotDevicePorts }">
          <!-- <hr> -->
          <!-- iot -->
          <!-- <div *ngIf="showSearchingIot" class="admin__right-column-search"> -->
          <div *ngIf="showSearchingIot" class="admin__iot-column-search">
            <ul class="bottom">
              <li>
                <h5>Searching Iot Device</h5>
              </li>
              <li>
                <div class="dot1"></div>
              </li>
              <li>
                <div class="dot2"></div>
              </li>
              <li>
                <div class="dot3"></div>
              </li>
            </ul>
          </div>

          <div *ngIf="showSearchingIot" style="margin-left: 15px;">
            Note: Please avoid clicking outside dialog box to continue searching for device.
          </div>

          <div *ngIf="showErrorMessageIot" class="admin__error-message mt-5">
            <h3>{{errorMessageIot}}</h3>
          </div>

          <!-- <div *ngIf="showErrorMessageIot" style="margin-left: 15px;">
              Note: Please avoid clicking outside dialog box to continue searching for device.
          </div> -->

          <div *ngIf="showSuccessMsgIot" class="admin__success-message">
            <h3>Device Successfull Added</h3>
          </div>


        </div>

        <div *ngIf="showAddDeviceIot" class="admin__device-details-and-table-container">
          <div class="admin__device-details-container" style="display: flex">
            <div>
              <h3>IoT Device Details</h3>
              <div class="admin__device-details-text">
                <p>Device Name: {{iotDeviceName | displayEmpty}}</p>
                <p>Device Mac: {{iotDeviceMac | displayEmpty}}</p>
                <p>Device IP: {{iotDeviceIP | displayEmpty}}</p>
                <p>Device Make: {{iotDeviceMake | displayEmpty}}</p>
                <p>Hostname: {{iotHostName | displayEmpty}}</p>

              </div>
              <div *ngIf="iotDevicePorts" class="admin__btn2">
                <button (click)="addDeviceAndLink()">Update Devices</button>
                <button (click)="cancelRescanUpdate()" class="admin__btn2--cancel">Cancel</button>
              </div>
            </div>

            <!-- <div class="admin__img-container">
                  <img src="../../../assets/img/icon_llama.png" />
              </div> -->
          </div>
        </div>

      </div>
    </div>

    <!-- NEW RESCAN  -->
    <!-- LOGITECH RESCAN -->
    <app-logitech-rescan *ngIf="isLogitechRescan" [data]="data" [isRefresh]="isRefresh" (resetEvent)="resetButton()">
    </app-logitech-rescan>
    <!-- END -->


    <div *ngIf="showDeleteDevice">
      <div class="form-group">
        <div class="row m-2">
          <h6 class="margin-left:auto;margin-right:auto;">
            Are you sure you want to delete this device?
          </h6>
        </div>
        <div class="row mt-2">
          <div class="form-group">
            <button (click)="deleteDevice()" class="form-control btn btn-primary float-right" style="margin-top: 10px">
              Delete Device
            </button>
          </div>
          <div class="form-group pl-2">
            <button (click)="cancelDelete()" class="form-control btn btn-dark float-left" style="margin-top: 10px">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div > -->
    <div *ngIf="isShowFirmwareUpdate" class="admin__confirm-message-container">
      <div *ngIf="isShowFirmwareUpdateMessage" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
        {{ firmwareSuccessMessage }}
      </div>

      <div *ngIf="isShowPolyMessage" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
        {{ polyMessage }}
      </div>

      <div class="admin__confirm-container" *ngIf="isShowFirmwareUpdateConfirm">
        Are you sure you want to update this device?

        <div *ngIf="data.device_type === 'polycom'" style="margin-top: 15px">
          Enter License Key : <input type="text" />
        </div>

        <div class="admin__confirm">
          <button class="admin__btn" (click)="firmwareUpdate()">Confirm</button>
          <button class="admin__btn" style="background-color: #808080" (click)="isShowFirmwareUpdate = false">
            Cancel
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <div *ngIf="showData3">
      <!-- EDIT DEVICE FORM -->
      <div class="form-group">
        <div *ngIf="!showUpdatedLoginMessage && !searchingLogin">
          <input *ngIf="alertType !== 'audio'" type="text" id="deviceUsername" class="form-control"
            placeholder="Device Admin Username" />
          <!-- <input type="text" id="devicePassword" class="form-control" placeholder="Device Admin Password" /> -->
          <div class="input-group mb-3">
            <input [type]="hidePassword ? 'password' : 'text'" id="devicePassword" class="form-control"
              placeholder="Device Admin Password">
            <div class="input-group-append pointer" (click)="hidePassword = !hidePassword">
              <span class="input-group-text">
                <i class="fa fa-eye" *ngIf="hidePassword"></i>
                <i class="fa fa-eye-slash" *ngIf="!hidePassword"></i>
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group">
              <button (click)="changeDeviceDetails()" class="form-control btn btn-primary float-right"
                style="margin-top: 10px">
                Update Credentials
              </button>
            </div>
            <div class="form-group pl-2">
              <button (click)="cancelUpdate()" class="form-control btn btn-dark float-left" style="margin-top: 10px">
                Cancel
              </button>
            </div>
          </div>
          <div *ngIf="showLoginMessage">
            <div>
              <p class="text-danger text-center" *ngIf="deviceLogindetails?.status === 'FAILED'">
                {{ deviceLogindetails?.results_msssage }}
              </p>
            </div>
          </div>
          <div *ngIf="showTimeoutError">
            <div>
              <p class="text-danger text-center" *ngIf="loginErrorMessage">
                {{ loginErrorMessage }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="!showUpdatedLoginMessage && searchingLogin">
          <ul class="bottom" style="display: block">
            <li>
              <h5>Searching</h5>
            </li>
            <li>
              <div class="dot1"></div>
            </li>
            <li>
              <div class="dot2"></div>
            </li>
            <li>
              <div class="dot3"></div>
            </li>
          </ul>
        </div>
        <div *ngIf="showUpdatedLoginMessage">
          <p class="text-success text-center" *ngIf="updatedLoginMessage?.status === 'Ok'">
            {{ updatedLoginMessage?.message }}
          </p>
          <p class="text-danger text-center" *ngIf="updatedLoginMessage?.status === 'Error'">
            {{ updatedLoginMessage?.message }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="showData4">
      <app-alerts-panel *ngIf="!isLogitech" [alertsTriggerData]="alertsTriggerData"
        (alertsTriggerDataChange)="receiveMessage($event)" [alertType]="alertType"></app-alerts-panel>
      <app-logitech-alert-panel *ngIf="isLogitech" [data]="data"></app-logitech-alert-panel>
    </div>

    <div *ngIf="showCredentialButton">
      <div>showCredentialButton</div>
    </div>

    <div *ngIf="showCallButton">
      <div>showCallButton</div>
    </div>

    <!-- CLOUD -->
    <div *ngIf="showCloud" class="admin__confirm-message-container">
      <div class="admin__confirm-container" *ngIf="shoudShowRebootPC">
        Are you sure you wanted to Reboot PC?

        <div class="admin__confirm">
          <button class="admin__btn" (click)="rebootPc()">Confirm</button>
          <button class="admin__btn" (click)="resetButton()">Cancel</button>
        </div>
      </div>

      <div class="admin__confirm-container" *ngIf="shouldShowRebootZoom">
        Are you sure you wanted to Reboot Zoom?

        <div class="admin__confirm">
          <button class="admin__btn" (click)="restartZoom()">Confirm</button>
          <button class="admin__btn" (click)="resetButton()">Cancel</button>
        </div>
      </div>

      <div class="admin__confirm-container" *ngIf="shouldShowEnablePlugin">
        Are you sure you wanted to enable {{ dPlugin }}?
        <div class="admin__confirm">
          <button class="admin__btn" (click)="enableZoomPlugin()">
            Confirm
          </button>
          <button class="admin__btn" (click)="resetButton()">Cancel</button>
        </div>
      </div>

      <div class="admin__confirm-container" *ngIf="shouldShowDisablePlugin">
        Are you sure you wanted to disable {{ dPlugin }}?
        <div class="admin__confirm">
          <button class="admin__btn" (click)="disableZoomPlugin()">
            Confirm
          </button>
          <button class="admin__btn" (click)="resetButton()">Cancel</button>
        </div>
      </div>

      <div *ngIf="shouldShowSetZoomSettings">
        <div *ngIf="shouldShowPluginMessage" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
          {{ pluginMessage }}
        </div>

        <div class="admin__confirm-container">
          Update Zoom Setting

          <div style="margin-top: 15px">
            Enter Password :
            <input type="password" value="updatePassword" [(ngModel)]="updatePassword" style="width: 350px" />
          </div>

          <div class="admin__confirm">
            <button class="admin__btn" (click)="setPluginSetting()">
              Update
            </button>
            <button class="admin__btn" (click)="resetButton()">Cancel</button>
          </div>
        </div>
      </div>

      <div *ngIf="shouldShowLinkPlugin">
        <div *ngIf="shouldShowPluginMessage" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
          {{ pluginMessage }}
        </div>

        <div *ngIf="!isDoneLinkingPlugin" class="admin__confirm-container admin__enablePlugin">
          <div class="admin__enablePlugin-container">
            <div class="admin__enablePlugin-label">
              <label>Select a site: </label>
            </div>
            <select [(ngModel)]="linkPluginSelect" required>
              <option *ngFor="let site of allSiteListData" value="{{ site.site_id }}">
                {{ site.site_name }}
              </option>
            </select>
          </div>

          <div class="admin__enablePlugin-container">
            <div class="admin__enablePlugin-label">
              <label>Enter Password: </label>
            </div>
            <input type="password" value="linkPluginPassword" [(ngModel)]="linkPluginPassword" />
          </div>

          <div class="admin__confirm">
            <button class="admin__btn" (click)="linkZoomPlugin()">
              Link {{ dPlugin }}
            </button>
            <button class="admin__btn" (click)="resetButton()">Cancel</button>
          </div>
        </div>
      </div>

      <!-- Link Site Implementation Start-->
      <div *ngIf="shouldShowLinkSite">
        <div *ngIf="shouldShowSiteMessage" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
          {{ siteMessage }}
        </div>

        <div *ngIf="!isDoneLinkingSite" class="admin__confirm-container admin__enablePlugin">
          <div class="admin__enablePlugin-container">
            <div class="admin__enablePlugin-label">
              <label>Select a site: </label>
            </div>
            <select [(ngModel)]="linkSiteSelect" required>
              <option *ngFor="let site of allSiteListData" value="{{ site.site_id }}">
                {{ site.site_name }}
              </option>
            </select>
          </div>

          <div class="admin__confirm">
            <button class="admin__btn" (click)="linkRoomToSite()">
              Link to Site
            </button>
            <button class="admin__btn" (click)="resetButton()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Link Site Implementation End-->

      <!-- Edit Site Implementation Start -->
      <div *ngIf="shouldShowEditSite">
        <div *ngIf="shouldShowSiteMessage" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
          {{ siteMessage }}
        </div>

        <div *ngIf="!isDoneLinkingSite" class="admin__confirm-container admin__enablePlugin">
          <div class="admin__enablePlugin-container">
            <div class="admin__enablePlugin-label">
              <label>Select a site: </label>
            </div>
            <select [(ngModel)]="linkSiteSelect" required>
              <option *ngFor="let site of allSiteListData" value="{{ site.site_id }}">
                {{ site.site_name }}
              </option>
            </select>
          </div>

          <div class="admin__confirm">
            <button class="admin__btn" (click)="linkRoomToSite()">
              Link to Site
            </button>
            <button class="admin__btn" (click)="resetButton()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Edit Site Implementation End -->

    </div>
    <!-- end -->
    <!-- CLOUD -->

    <!-- NEW WELLNESS -->

    <div *ngIf="isShowWellness" class="admin__confirm-message-container">
      <div *ngIf="isShowWellness" class=" admin__confirm-message {{ firmwareSuccessMessageClass }}">
        {{ firmwareSuccessMessage }}
      </div>

      <div class="admin__confirm-container-wellness" *ngIf="isShowWellness">
        <div [ngClass]="{ admin__disable: !enableWellnessCheckBox }" class="admin__confirm-container-wellness-items">
          Schedule Interval :
          <select id="index" (change)="intervalOnChange($event)" style="width: 150px !important"
            [(ngModel)]="wellnessInterval">
            <option value="1" selected>Daily</option>
            <option value="7">Weekly</option>
            <option value="14"> Bi-Weekly</option>
          </select>
        </div>

        <div *ngIf="wellnessInterval == 7 || wellnessInterval == 14" class="edit-site__confirm-container-wellness-items"
          [ngClass]="{ admin__disable: !enableWellnessCheckBox }">
          <label for="">Select Day :</label>
          <select id="index" (change)="intervalEveryWeekOnChange($event)" [(ngModel)]="wellnessNameOfDay"
            [ngModelOptions]="{ standalone: true }" style="width: 150px !important">
            <option value="1" selected> Sunday</option>
            <option value="2"> Monday</option>
            <option value="3"> Tuesday </option>
            <option value="4"> Wednesday </option>
            <option value="5"> Thursday </option>
            <option value="6"> Friday </option>
            <option value="7"> Saturday </option>
          </select>
        </div>

        <div class="admin__confirm-container-wellness-items" style="display: flex; justify-content: center">
          <ngb-timepicker [ngClass]="{ admin__disable: !enableWellnessCheckBox }" class="changeSize"
            [(ngModel)]="wellnessCheckTime" id="endTime" [spinners]="false" [meridian]="true"
            [ngModelOptions]="{ standalone: true }" (ngModelChange)="onWellnessTimeChange()"></ngb-timepicker>
        </div>

        <div class="admin__confirm-container-wellness-items">
          <input class="default" type="checkbox" [(ngModel)]="enableWellnessCheckBox"
            (change)="enableWellness(checkboxEnableWellness.checked)" #checkboxEnableWellness />
          <span> Enable wellness check </span>
        </div>


        <div class="admin__confirm-container-wellness-items">
          <input class="default" type="checkbox" [(ngModel)]="allowSiteOverwrite"
            (change)="allowSiteOverwriteEvent(checkboxAllowSiteOverwrite.checked)" #checkboxAllowSiteOverwrite />
          <span> Allow site to overwrite schedule </span>
        </div>

        <div class="admin__confirm-container-wellness-items">
          Wellness Timezone: {{wellnessLocaltimezone}} UTC
        </div>
        <div class="admin__confirm-container-wellness-items" *ngIf="enableWellnessCheckBox">
          Wellness Time (Local): {{wellnessCheckTimeWithColon | transformTimeInGivenZoneToAnother :
          wellnessLocaltimezone}}
        </div>

        <div>Note: Default value is based on site wellness schedule.</div>

        <div *ngIf="showWellnessSuccess" class="success-msg" style="margin-top: 10px;">Welness Schedule Updated</div>


        <div class="admin__confirm">
          <button class="admin__btn" (click)="setVideoWellnessCheck()">
            Confirm
          </button>
          <button class="admin__btn" style="background-color: #808080" (click)="isShowWellness = false">
            Cancel
          </button>
        </div>
      </div>
    </div>


    <!-- NEW WELLNESS -->


    <div *ngIf="showData5">
      <div class="row rescan-reboot-plugin-dynamic-button-title">Update IP</div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <!-- fix later -->
            <input style="width: 300px; margin-left: 35px" type="text" id="device-ip"
              placeholder="{{ updateIpPlaceHolder }}" class="form-control" />
          </div>
          <div class="row rescan-reboot-plugin-dynamic-button-body__message error">
            <p [ngClass]="{
                'error-msg': isUpdateIPError,
                'success-msg': !isUpdateIPError
              }">
              {{ updateIpMsg }}
            </p>
          </div>
        </div>
      </div>
      <div class="row rescan-reboot-plugin-dynamic-button-child">
        <div class="form-group" *ngIf="isShowVerifyIp">
          <button (click)="verifyIp()" class="form-control btn btn-primary">
            Verify IP
          </button>
        </div>
        <div class="form-group" *ngIf="isShowUpdateIp">
          <button (click)="updateIP()" class="form-control btn btn-primary">
            Update IP
          </button>
        </div>
        <div class="form-group pl-2">
          <button *ngIf="isShowCancelUpdateIp" (click)="cancelDelete()" class="form-control btn btn-dark">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isShowTeleportivity">
      <div class="teleportivity">
        <div class="link-display">
          <div class="icon-area">
            <img />
          </div>
          <div class="toggle-area">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox" [checked]="isEnableTeleportivity"
                  (change)="setTeleporivity(!this.isEnableTeleportivity)" />
                <span class="slider round"> </span>
              </label>
              <label class="config-display">
                {{ directAccessConfigText }}
              </label>
              <button [disabled]="!isEnableTeleportivity" (click)="setTeleporivity(true)">
                Regenerate url
              </button>
            </div>
            <div class="diplay-url">
              <input type="text" #accessUrl placeholder="" [value]="directAccessUrl" class="form-control" />
              <span class="fa fa-clipboard" (click)="copyDirectAccesUrl(accessUrl)"></span>
            </div>
          </div>
        </div>
        <div class="user-list">
          <label>Select or Add a New Support Contact</label>
          <select id="userSupportList" class="form-control" [(ngModel)]="userSupportId"
            (change)="onUserSupportChange($event)">
            <option *ngFor="let user of userSupportList" [value]="user.user_id">
              {{ user.full_name }}
            </option>
          </select>
        </div>
        <div class="user-notif-name">
          <input type="text" id="notif-first-name" value="{{ userSupport.first_name }}" placeholder="First Name"
            class="form-control" />
          <input type="text" id="notif-last-name" value="{{ userSupport.last_name }}" placeholder="Last Name"
            class="form-control" />
        </div>
        <div class="user-notif-email">
          <input type="text" id="notif-email" value="{{ userSupport.email }}" placeholder="Email"
            class="form-control" />
        </div>
        <div class="user-notif-phone">
          <input type="text" id="notif-phone" value="{{ userSupport.phone }}" placeholder="User Phone"
            class="form-control" />
          <button class="btn-cancel" (click)="resetButton()">Cancel</button>
          <button class="btn-save" (click)="saveDirectAccessSupportUser()" [disabled]="!isEnableTeleportivity">
            Save
          </button>
        </div>
        <div class="direct-access-notifs">
          {{ directAccessNotifs }}
        </div>
      </div>
    </div>

    <app-documents class="admin__components" *ngIf="shouldShowDocuments" [propData]="propData"></app-documents>

    <div class="text-center" *ngIf="showSnapshotPanel">
      <ul class="bottom">
        <li [innerHTML]="snapshotMessage">
        </li>
        <ng-container *ngIf="showSnapshotLoader">
          <li>
            <div class="dot1"></div>
          </li>
          <li>
            <div class="dot2"></div>
          </li>
          <li>
            <div class="dot3"></div>
          </li>
        </ng-container>
      </ul>
    </div>

    <app-plugin-logging *ngIf="showPluginLogging" [refresh]="isRefresh"  [pluginId]="pluginId" [data]="data"></app-plugin-logging>
    
    <app-allow-access *ngIf="isPexip && alertType === 'video'" [device_type]="alertType" [data]="data"></app-allow-access>
  </div>
  <!------- COLUMNS ------->

  <!-- </div> -->
  <!-- <div style="position: absolute; top: 200px; width: 100%;" *ngIf="isLoading">
    <div class="loader-icon">
      <img style="width: 100px;" [src]="loader" />
    </div>
  </div> -->
</div>