<div class="reports">

  <!-- METH CODE -->
  <div *ngIf="loading == 1" class="loading-img">
    <img src="{{loader}}" />
  </div>


  <!-- ---------------------------------------------------------------- LEFT SIDE COLUMN -->

  <div class="reports__left-panel">


    <div class="reports__radio-container">
      <h5>Report Users</h5>

      <label class="container">Staff
        <input type="radio" name="radio" id="staff" value="staff" (change)="reportUserChange($event)" />
        <span class="checkmark"></span>
      </label>
    </div>




    <div class="reports__radio-container" *ngIf="isStaff">
      <h5>Report Type</h5>
      <label class="container">Top 5 Trends
        <input type="radio" name="radio2" id="top-five-trends" value="top5" (change)="reportTypeChange($event)" />
        <span class="checkmark2"></span>
      </label>
      <label class="container">Video Devices
        <input type="radio" name="radio2" id="video-conf" value="video" (change)="reportTypeChange($event)" />
        <span class="checkmark2"></span>
      </label>
      <label class="container">Cloud Services
        <input type="radio" name="radio2" id="cloudId" value="cloudId" (change)="reportTypeChange($event)" />
        <span class="checkmark2"></span>
      </label>
      <label class="container">Audio Hybrid
        <input type="radio" name="radio2" id="audioId" value="audioId" (change)="reportTypeChange($event)" />
        <span class="checkmark2"></span>
      </label>
      <label class="container">IoT
        <input type="radio" name="radio2" id="iotId" value="iotId" (change)="reportTypeChange($event)" />
        <span class="checkmark2"></span>
      </label>
      <label class="container">Inventory
        <input type="radio" name="radio2" id="inventory" value="inventory" (change)="reportTypeChange($event)" />
        <span class="checkmark2"></span>
      </label>
    </div>


    <div class="reports__radio-container" *ngIf="isTopFiveTrend || isVideo || isAudio || isIot || isCloud">
      <h5>Report Details</h5>
      <label class="container">Sites
        <input type="radio" name="radio3" id="sites" value="sites" (change)="reportDetailsChange($event)" />
        <span class="checkmark3"></span>
      </label>

    </div>




    <div class="reports__radio-container" *ngIf="isSites">
      <h5>Sites</h5>
      <label class="container">All Sites
        <input (change)="siteIdIndex($event)" id="site-list1" type="radio" name="radio4" value="-1" />
        <span class="checkmark4"></span>
      </label>
      <label *ngFor="let allData of siteApiData; index as n" class="container" style="word-wrap: break-word">{{
        allData.site_name }}
        <input (change)="siteIdIndex($event)" id="site-list2" type="radio" name="radio4" value="{{ n }}" />
        <span class="checkmark4"></span>
      </label>
    </div>



    <div class="reports__radio-container"
      *ngIf="isStaff && isSites && isSingleSite && ( isVideo || isAudio || isIot || isCloud)">
      <h5>Devices</h5>
      <label class="container">{{isIot ? 'All Spaces' : isCloud ? 'All Rooms' : 'All Devices'}}
        <input (change)="deviceIdIndex($event)" id="device-list1" type="radio" name="radio5" value="-1" />
        <span class="checkmark4"></span>
      </label>

      <label *ngFor="let allDData of deviceApiData; index as m" class="container">{{
        allDData.device_name }}
        <input (change)="deviceIdIndex($event)" id="device-list2" type="radio" name="radio5" value="{{ m }}" />
        <span class="checkmark4"></span>
      </label>
    </div>



    <!-- <div class="reports__radio-container" *ngIf="isShowDateRange">-->
    <div class="reports__radio-container" *ngIf="isShowDateRange">


      <div class="form-group reports__date-select">
        <select (change)="setShowCalender($event)" class="form-control" id="date-range" required>
          <option value="" selected disabled>Select Range</option>
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="quarter">Quarter</option>
        </select>
      </div>


      <div class="reports__date-option-container">
        <div class="reports__date-option-item">From: {{ fromDate.month }}/{{ fromDate.day }}/{{ fromDate.year }} </div>
        <div class="reports__date-option-item">To:{{ toDate.month }}/{{ toDate.day }}/{{ toDate.year }}</div>

      </div>

      <ngb-datepicker *ngIf="isShowCalender" #dp [(ngModel)]="model" (navigate)="date = $event.next"
        (dateSelect)="onDateSelection($event)"></ngb-datepicker>


    </div>



    <div>
      <!-- ERROR MESSAGE -->
      <p id=" feedback" *ngIf="showMissingMsg == 1" class="feedback text-center text-danger">
        {{errorMessage}}
      </p>


    </div>

    <form role="form" *ngIf="isDateSelected || isInventory" class="report-form" name="report-form" id="report-form7"
      novalidate>
      <div class="text-center mb-3">
        <button type="submit" class="btn btn-primary" (click)="genReport()">Generate</button>
      </div>
    </form>

  </div>
  <!-- ---------------------------------------------------------------- LEFT SIDE COLUMN -->


  <div class="reports__right-panel">
    <div *ngIf="showRepFlg == 1" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]=" reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
        [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
        [todate]="genToDate"></app-reports-title-panel>

      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[0]"
            [listColTitle]="colAllTitle[0]"
            [allSiteList]="topFiveSiteApiData?.most_incidents"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[1]"
            [listColTitle]="colAllTitle[0]"
            [allSiteList]="topFiveSiteApiData?.least_incidents"></app-reports-top-five-list>
        </div>
      </div>


      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="2" [listTitle]="listAllTitle[2]"
            [listColTitle]="colAllTitle[1]"
            [allSiteList]="topFiveSiteApiData?.most_utilized"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="2" [listTitle]="listAllTitle[3]"
            [listColTitle]="colAllTitle[1]"
            [allSiteList]="topFiveSiteApiData?.least_utilized"></app-reports-top-five-list>
        </div>
      </div>


      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[4]"
            [listColTitle]="colAllTitle[2]"
            [allSiteList]="topFiveSiteApiData?.most_network_incidents"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[5]"
            [listColTitle]="colAllTitle[2]"
            [allSiteList]="topFiveSiteApiData?.least_network_incidents"></app-reports-top-five-list>
        </div>
      </div>

      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="3" [listTitle]="listAllTitle[6]"
            [listColTitle]="colAllTitle[3]" [trendTitle]="''"
            [allSiteList]="topFiveSiteApiData?.most_uptime"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="3" [listTitle]="listAllTitle[7]"
            [listColTitle]="colAllTitle[3]" [trendTitle]="''"
            [allSiteList]="topFiveSiteApiData?.least_uptime"></app-reports-top-five-list>
        </div>
      </div>

      <!-- <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div> -->

      <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>

      <div class="reports__container">
        <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
      </div>

    </div>

    <div *ngIf="showRepFlg == 2" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData?.data"></app-reports-cover-sheet>


      <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
        [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
        [todate]="genToDate"></app-reports-title-panel>


      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1" [listTitle]="listSingleTitle[0]"
            [listColTitle]="colSingleTitle[0]"
            [allSiteList]="topFiveDeviceApiData?.most_incidents"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1" [listTitle]="listSingleTitle[1]"
            [listColTitle]="colSingleTitle[0]"
            [allSiteList]="topFiveDeviceApiData?.least_incidents"></app-reports-top-five-list>
        </div>
      </div>

      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="2" [listTitle]="listSingleTitle[2]"
            [listColTitle]="colSingleTitle[1]"
            [allSiteList]="topFiveDeviceApiData?.most_utilized"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="2" [listTitle]="listSingleTitle[3]"
            [listColTitle]="colSingleTitle[1]"
            [allSiteList]="topFiveDeviceApiData?.least_utilized"></app-reports-top-five-list>
        </div>
      </div>

      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1" [listTitle]="listSingleTitle[4]"
            [listColTitle]="colSingleTitle[2]"
            [allSiteList]="topFiveDeviceApiData?.most_network_incidents"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1" [listTitle]="listSingleTitle[5]"
            [listColTitle]="colSingleTitle[2]"
            [allSiteList]="topFiveDeviceApiData?.least_network_incidents"></app-reports-top-five-list>
        </div>
      </div>

      <div class="reports__container">
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="3" [listTitle]="listSingleTitle[6]"
            [listColTitle]="colSingleTitle[3]" [trendTitle]="''"
            [allSiteList]="topFiveDeviceApiData?.most_uptime"></app-reports-top-five-list>
        </div>
        <div class="reports__container-item">
          <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="3" [listTitle]="listSingleTitle[7]"
            [listColTitle]="colSingleTitle[3]" [trendTitle]="''"
            [allSiteList]="topFiveDeviceApiData?.least_uptime"></app-reports-top-five-list>
        </div>
      </div>

      <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>

      <div class="reports__container">
        <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
      </div>
    </div>


    <!-- VIDEO -->



    <div *ngIf="showRepFlg == 3" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllList]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Video Devices'" [siteName]="siteNameData" [fromdate]="genFromDate" [todate]="genToDate"
        [companyInfo]="companyApiData" [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>


      <div *ngFor="let vodConf of videoConferAllSiteApiData; let i = index">

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferAllSiteApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-rooms>
        </div>

        <hr />
        <div class="reports__container-no-division">

          <app-reports-video-conf-devices
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division">
          <app-reports-incident-sheet [incidentList]="incidentAllDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>
      </div>


    </div>


    <div *ngIf="showRepFlg == 4" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Video Devices'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>


        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <div class="reports__container-no-division">

          <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>

      </div>
    </div>




    <div *ngIf="showRepFlg == 5" id="report-main-panel" class="reports__main-panel">
      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Video Devices'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
      </div>
    </div>

    <!-- VIDEO -->





    <!-- CLOUD -->

    <div *ngIf="showRepFlg == 6" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllList]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Cloud Services'" [siteName]="siteNameData" [fromdate]="genFromDate" [todate]="genToDate"
        [companyInfo]="companyApiData" [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>


      <div *ngFor="let vodConf of videoConferAllSiteApiData; let i = index">

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Cloud Services'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Cloud Services'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferAllSiteApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-rooms>
        </div>

        <hr />
        <div class="reports__container-no-division">

          <app-reports-video-conf-devices
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division">
          <app-reports-incident-sheet [incidentList]="incidentAllDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>
      </div>


    </div>


    <div *ngIf="showRepFlg == 7" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Cloud Services'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Cloud Services'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>


        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <div class="reports__container-no-division">

          <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>

      </div>
    </div>




    <div *ngIf="showRepFlg == 8" id="report-main-panel" class="reports__main-panel">
      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Cloud Services'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Cloud Services'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
      </div>
    </div>

    <!-- CLOUD -->

    <!-- AUDIO -->

    <div *ngIf="showRepFlg == 9" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllList]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Video Devices'" [siteName]="siteNameData" [fromdate]="genFromDate" [todate]="genToDate"
        [companyInfo]="companyApiData" [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>


      <div *ngFor="let vodConf of videoConferAllSiteApiData; let i = index">

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferAllSiteApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-rooms>
        </div>

        <hr />
        <div class="reports__container-no-division">

          <app-reports-video-conf-devices
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division">
          <app-reports-incident-sheet [incidentList]="incidentAllDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>
      </div>


    </div>


    <div *ngIf="showRepFlg == 10" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Video Devices'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>


        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <div class="reports__container-no-division">

          <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>

      </div>
    </div>




    <div *ngIf="showRepFlg == 11" id="report-main-panel" class="reports__main-panel">
      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'Video Devices'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Devices'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
      </div>
    </div>

    <!-- AUDIO -->


    <!-- IOT -->

    <div *ngIf="showRepFlg == 12" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllList]="true" [siteTitle]="reportUser" [siteSubTitle]="'IoT'"
        [siteName]="siteNameData" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>


      <div *ngFor="let vodConf of videoConferAllSiteApiData; let i = index">

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'IoT Devices'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'IoT'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferAllSiteApiData[i]"
            [statsLabel]="'Wellness Check total incidents'" [isIot]="'true'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-rooms>
        </div>

        <hr />
        <div class="reports__container-no-division">

          <app-reports-video-conf-devices
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division">
          <app-reports-incident-sheet [incidentList]="incidentAllDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>
      </div>


    </div>


    <div *ngIf="showRepFlg == 13" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'IoT'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'IoT'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'" [isIot]="'true'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>


        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <div class="reports__container-no-division">

          <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>

      </div>
    </div>

    <div *ngIf="showRepFlg == 14" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'IoT'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'IoT'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'" [isIot]="'true'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>


        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <div class="reports__container-no-division">

          <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>

      </div>
    </div>





    <div *ngIf="showRepFlg == 15" id="report-main-panel" class="reports__main-panel">
      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'IoT'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'IoT'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'" [isIot]="'true'"></app-reports-video-conf-stats>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
      </div>
    </div>

    <!-- IOT -->


    <!-- DOMOTZ -->

    <div *ngIf="showRepFlg == 16" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllList]="true" [siteTitle]="reportUser" [siteSubTitle]="'IoT'"
        [siteName]="siteNameData" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>


      <div *ngFor="let vodConf of videoConferAllSiteApiData; let i = index">

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'IoT'" [siteType]="genSitetype"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'IoT'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferAllSiteApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-rooms>
        </div>

        <hr />
        <div class="reports__container-no-division">

          <app-reports-video-conf-devices
            [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
          [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division">
          <app-reports-incident-sheet [incidentList]="incidentAllDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>
      </div>


    </div>


    <div *ngIf="showRepFlg == 17" id="report-main-panel" class="reports__main-panel">

      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'IoT'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'IoT'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>

        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>


        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>

        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <div class="reports__container-no-division">

          <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
        </div>

      </div>
    </div>




    <div *ngIf="showRepFlg == 18" id="report-main-panel" class="reports__main-panel">
      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
        [siteSubTitle]="'IoT'" [siteName]="genSitename" [deviceName]="genDevicename" [fromdate]="genFromDate"
        [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData.data"></app-reports-cover-sheet>

      <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'IoT'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>

        <div class="reports__container-no-division reports__container--space-between">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-stats [videoConferSite]="videoConferDeviceApiData[i]"
            [statsLabel]="'Wellness Check total incidents'"></app-reports-video-conf-stats>
        </div>
        <div class="reports__container-no-division">
          <app-reports-video-conf-rooms [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="reports__container-no-division">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
        </div>
        <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
          [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename" [fromdate]="genFromDate"
          [todate]="genToDate"></app-reports-title-panel>
        <app-reports-incident-sheet [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
      </div>
    </div>

    <!-- DOMOTZ -->



    <!-- INVENTORY -->

    <div *ngIf="showRepFlg == 19" id="report-main-panel" class="reports__main-panel">
      <div class="reports__print-button">
        <i class="fa fa-print fa-2x menu-icon" (click)="setPrintMode()"></i>
      </div>

      <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Inventory Report'"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
        [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
      <div class="pagebreak"></div>

      <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Inventory Report'" [siteType]="genSitetype"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>


      <div class="reports__container-no-division">
        <app-reports-inventory *ngIf="inventoryDeviceApiData?.length"
          [reportInventory]="inventoryDeviceApiData[0].data"></app-reports-inventory>
      </div>

      <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
        [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>

      <div *ngIf="report_user == 2 && isPrint == 1" class="reports__container-no-division">
        <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
      </div>
    </div>




  </div>



</div>