<div class="user-idle">
	<div class="user-idle__header">
		User Inactive
		<div class="user-idle__close px-2" (click)="closeDialog()">X</div>
	</div>

	<div class="user-idle__body">
		<!-- <div class="user-idle__image">
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
      <img *ngIf="!isLoading && !hasDeviceConnectedMessage" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <i *ngIf="!isLoading && hasDeviceConnectedMessage" class="fa fa-exclamation-circle fa-5x p-4 text-danger" alt="" ></i>
    </div> -->

		<div class="user-idle__text-button-container">
			<ng-container>
				<div>
					<strong>Are you still there?</strong>
					<br />
					If not, we'll close the session in {{ timerCount }} seconds
				</div>

				<div class="user-idle__button-container">
					<div class="user-idle__button-item user-idle__button-item--yes" (click)="onContinueSession()">I'm here</div>
					<div class="user-idle__button-item user-idle__button-item--cancel" (click)="onCloseSession()">Sign out</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
