<div class="plugin-logging">
    <div class="text-center">
        <ul class="bottom">
            <li [innerHTML]="message">
            </li>
            <ng-container *ngIf="showLoader">
                <li>
                    <div class="dot1"></div>
                </li>
                <li>
                    <div class="dot2"></div>
                </li>
                <li>
                    <div class="dot3"></div>
                </li>
            </ng-container>
        </ul>
    </div>

</div>