import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthV2Service } from 'src/app/services/auth-v2.service';

@Component({
  selector: 'app-keycloak-callback',
  templateUrl: './keycloak-callback.component.html',
  styleUrls: ['./keycloak-callback.component.scss']
})
export class KeycloakCallbackComponent implements OnInit {

  constructor(private _authService:AuthV2Service,private  router:Router) { }

  ngOnInit(): void {
    this._authService.getToken().then(token=>{
      console.log('token',token);
      this._authService.authenticate(token).subscribe((resp)=>{
        localStorage.setItem('oauth-token',token);
      },(err)=>{
        this.router.navigateByUrl('/login')
      })
    })
  }

}
