import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

import { AuthService } from '../../../services/auth.service'
import { GetAudioService } from '../../../services/get-audio.service'
import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: "app-audio-device-stats",
  templateUrl: "./audio-device-stats.component.html",
  styleUrls: ["./audio-device-stats.component.scss"]
})
export class AudioDeviceStatsComponent implements OnInit,  OnChanges{
  @Input() audioDeviceData: any;
  @Input() audioUserData: any;
  id: any;
  notesInfo = [];
  accessLevel: any;
  isNotButton: string;
  hasAccess: boolean;
  mrPairCameraConnectedText: any;
  showMrPairCameraConnected: any = false;
  audioDeviceId: any;
  audioDeviceType: any;
  audioPresence: any;
  audioTotalMosByMonth: any;

  constructor(
    public ds: PopupTypeService,
    private getAudioDataService: GetAudioService,
    private Auth: AuthService
  ) { }

  ngOnInit() {
  //   const token = localStorage.getItem("token");
  //   const helper = new JwtHelperService();
  //   const decodedToken = helper.decodeToken(token);
  //   this.accessLevel = decodedToken.accesslevel;
  //   this.hasAccess = this.getHasAccess();
  //   this.isNotButton = !this.hasAccess ? "not" : "yes";

  //   // send message to subscribers via observable subject
  //   this.ds.sendPopupType(0);

  //   this.setData();
  }
  
  
  
  ngOnChanges(changes: SimpleChanges) {
    if (this.audioDeviceData && changes.audioDeviceData) {
      let isFirstLoad = true;
      console.log(this.audioDeviceData)
      this.setAccess();
      this.setData();
    } 
  }



  setData = () => {
    const { mr_pair, audio_device_id, device_type, presence, total_mos_by_month } = this.audioDeviceData;
    //MR PAIR
    this.audioDeviceId = audio_device_id;
    this.audioDeviceType = device_type; 
    this.audioPresence = presence;
    this.audioTotalMosByMonth = total_mos_by_month;
    
    this.shouldShowMrPairCameraConnected(mr_pair);
  }
  
  setAccess = () => {
    const token = localStorage.getItem("token");
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.hasAccess = this.getHasAccess();
    this.isNotButton = !this.hasAccess ? "not" : "yes";

    // send message to subscribers via observable subject
    // this.ds.sendPopupType(0);
  }

  shouldShowMrPairCameraConnected = (mrpairinfo) => {
    if (mrpairinfo) {
      let mrPairIsCameraConnected = mrpairinfo.mrpairinfo_camera_connected;
      const mrPairIsDevicePaired = mrpairinfo?.mrpairinfo_is_paired_triovisual || '';

      if (mrpairinfo.mrpairinfo_raw_data) {
        if (mrpairinfo.mrpairinfo_raw_data.data) {
          // if (Array.isArray(mrpairinfo.mrpairinfo_raw_data.data.ConnectedDeviceList)) {
          var [getPairedDeviceList] = mrpairinfo.mrpairinfo_raw_data.data.PairedDeviceList;

        }
      }

      if (mrPairIsCameraConnected == 'unknown' || mrPairIsCameraConnected == 'true') {
        this.showMrPairCameraConnected = true;
        if (mrPairIsCameraConnected == 'unknown') {
          if (getPairedDeviceList) {
            this.mrPairCameraConnectedText = 'Unknown';
          } else {
            this.showMrPairCameraConnected = false;
          }
        } else {
          if(mrPairIsDevicePaired === 'true') this.mrPairCameraConnectedText = 'Connected';
          else this.mrPairCameraConnectedText = 'Unknown';
        }
      } else {
        console.log('here')
        this.showMrPairCameraConnected = true;
        this.mrPairCameraConnectedText = 'Disconnected';
      }
      // if(mrPairIsDevicePaired === 'true' && mrPairIsCameraConnected === 'true'){
      //     this.showMrPairCameraConnected = true;
      //     this.mrPairCameraConnectedText = 'Connected';
      // }
      // else{
      //   this.showMrPairCameraConnected = false;
      //   this.mrPairCameraConnectedText = 'Disconnected';
      // }

    }
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }
  colorJit() { }

  colorPac() { }

  colorLat() { }

  muteUnMuteMic() {
    this.audioDeviceData.mic_muted = this.audioDeviceData.mic_muted
      ? false
      : true;
    this.getAudioDataService
      .audioMuteUnMute(
        this.audioDeviceData.audio_device_id,
        this.audioDeviceData.mic_muted
      )
      .subscribe(data => { });
  }

  saveNotes() {
    const token = localStorage.getItem("token");
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const companyId = {
      company_id: decodedToken.company_id
    };
    const notesDtls = {
      company_id: decodedToken.company_id,
      device_id: this.audioDeviceData.audio_device_id,
      notes: (<HTMLInputElement>(
        document.getElementById("exampleFormControlTextarea1")
      )).value
    };

    this.Auth.updateAudioNotes(notesDtls).subscribe(data => {
      this.notesInfo = data.response;
    });
  }

  covertToHrs(seconds) {
    let timeDisplay = "TalkTime: --";
    if (seconds !== null) {
      seconds = Number(seconds);
      let h = Math.floor(seconds / 3600);
      let m = Math.floor((seconds % 3600) / 60);
      let s = Math.floor((seconds % 3600) % 60);

      let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? " second " : " seconds") : "";
      timeDisplay = "TalkTime: " + hDisplay + mDisplay + "<br/>" + sDisplay;
    }
    return timeDisplay;
  }

  openCallLog() {
    this.ds.sendPopupType(42, { data: this.audioDeviceData }, this.hasAccess);
  }

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === "USER") {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
}
