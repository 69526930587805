<div class="forgot" [ngStyle]="visibilityBackground">
  <div class="forgot__col">
    <div class="forgot__box" *ngIf="!sentRequest && valid == 1">
      <div class="forgot__text">
        <h3>Password change.</h3>
      </div>
      <hr />
      <div class="forgot__text">
        <h5>{{responseMessage}}</h5>
      </div>

      <div class="forgot__input-ctr">
        <input
          class="{{ formControl.email.style }}"
          disabled="true"
          type="text"
          placeholder="Email"
          value="email"
          [(ngModel)]="email"
          (keyup)="emailKeyEvent($event)"
        />
      </div>

      <div class="forgot__input-ctr">
        <input
          class="{{ formControl.old_password.style }} register__input-sm"
          type="password"
          placeholder="Old Password"
          value="password"
          [(ngModel)]="old_password"
          (keyup)="keyEvent($event, 'password')"
        />
      </div>

      <div class="forgot__input-ctr">
        <input
          class="{{ formControl.password.style }} register__input-sm"
          type="password"
          placeholder="New Password"
          value="password"
          [(ngModel)]="password"
          (keyup)="keyEvent($event, 'password')"
        />
      </div>

      <div class="forgot__input-ctr">
        <input
          class="{{ formControl.confirm.style }}  register__input-sm"
          type="Password"
          placeholder="Confirm Password"
          value="confirm"
          [(ngModel)]="confirm"
          (keyup)="keyEvent($event, 'confirm')"
        />

        <div *ngIf="formControl.confirm.flag" class="reset-image">
          <img src="../../../../assets/img/vs_img/redcheck.png" alt="" />
        </div>

        <div *ngIf="password === confirm && password.length !== 0" class="reset-image">
          <img src="../../../../assets/img/vs_img/greencheck.png" alt="" />
        </div>
      </div>

      <div class="forgot__input-ctr">
        <button
          [ngClass]="{
            'disable-btn': disableBtn
          }"
          class="forgot__btn-primary"
          (click)="validateForm()"
        >
          Submit
        </button>
      </div>
    </div>

    <!--  -->

    <div class="forgot__box" *ngIf="sentRequest && valid == 1">
      <div *ngIf="resetResponseFailed == 1 && resetResponseData?.status == 200">
        <div>
          <div class="forgot__text">
            <h3>Oh, no!</h3>
          </div>
          <hr />
          <div class="forgot__text forgot__link-fa">
            <i class="fa fa-frown-o register__icon"></i>
            <h5>Reset code expired</h5>
          </div>

          <div class="forgot__input-ctr">
            <button
              [ngClass]="{
                'disable-btn': disableBtn
              }"
              class="forgot__btn-primary"
              routerLink="/login"
            >
              Login
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="resetResponseData?.status != 200">
        <div>
          <div class="forgot__text">
            <h3>Oh, no!</h3>
          </div>
          <hr />
          <div class="forgot__text forgot__link-fa">
            <i class="fa fa-frown-o register__icon"></i>
            <h5>Change password code expired</h5>
          </div>

          <div class="forgot__input-ctr">
            <button
              [ngClass]="{
                'disable-btn': disableBtn
              }"
              class="forgot__btn-primary"
              routerLink="/login"
            >
              Login
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="resetResponseData?.status == 200 && resetResponseFailed == 0">
        <div>
          <div class="forgot__text">
            <h3>Great!</h3>
          </div>
          <hr />
          <div class="forgot__text forgot__link-fa">
            <i class="fa fa-smile-o register__icon"></i>
            <h5>Password has been changed</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="forgot__box" *ngIf="valid == 0">
      <div>
        <div class="forgot__text">
          <h3>Oh, no!</h3>
        </div>
        <hr />
        <div class="forgot__text forgot__link-fa">
          <i class="fa fa-frown-o register__icon"></i>
          <h5>Reset code expired</h5>
        </div>

        <div class="forgot__input-ctr">
          <button
            [ngClass]="{
              'disable-btn': disableBtn
            }"
            class="forgot__btn-primary"
            routerLink="/login"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
