import { GetAudioService } from 'src/app/services/get-audio.service'

import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { GetVideoService } from '../../services/get-video.service'
import { PopupSendDataService } from '../../services/popup-send-data.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-reboot-device',
  templateUrl: './reboot-device.component.html',
  styleUrls: ['./reboot-device.component.scss'],
})
export class RebootDeviceComponent implements OnInit {
  @Input() videoDeviceId: any;
  id: number;
  @Input() deviceId: any;
  @Input() mediaType: any;

  constructor(
    private route: ActivatedRoute,
    private getVideoService: GetVideoService,
    private getAudioService: GetAudioService,
    private router: Router,
    private sendPopupDataService: PopupSendDataService,
    private ds: PopupTypeService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }

  ngOnInit() {}

  rebootDevice() {
    if (this.mediaType === 'audio') {
      this.getAudioService.rebootAudioDevice(this.deviceId).subscribe((data: any) => {
        console.log('This is the reboot response data', data);
        this.sendPopupType(99);
      });
    } else {
      this.getVideoService.rebootVideoDevice(this.deviceId).subscribe((data) => {
        // console.log('This is the reboot response data', data);
        this.sendPopupType(99);
      });
    }
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }
}
