<button type="button" class="not-button btn-long d-flex py-2 zoom-camera">
  <!-- <div class="zoom-camera-image" (click)="ds.sendPopupType(44, { data: data })" (mouseenter)="iconOnHover()"
    (mouseleave)="iconOnLeave()">
    <img src="{{ icon }}" />
  </div> -->
  <div class="zoom-camera-image position-relative">
    <img src="{{ icon }}" />
    <span class="badge" *ngIf="isLogitechData && getStatusIcon(inuseCam) === iconTriangleRed " [matTooltip]="'Disconnected camera: ' + ConnectionState">
      {{ ConnectionState }}
    </span>
  </div>
  <div class="zoom-camera_details">
    <div class="zoom-camera_mute">
      Primary
      <img src="{{ getStatusIcon(inuseCam) }}" alt="" />
    </div>
    <div class="tooltip-container" (click)="ds.sendPopupType(49, { data: data })">
      <h6>
        {{ inuseCam.name }}
      </h6>
      <div *ngIf="inuseCam.name" class="tooltip-text">
          <strong>Device Name:</strong> {{ inuseCam.name }}<br />
          <strong *ngIf="firmwareVersion">Firmware Version:</strong> {{ firmwareVersion }}
      </div>
    </div>
  </div>
</button>

