<!-- <div class="teams-admin">
    <div class="teams-admin__btn_ctr">
        <button class="teams-admin__btn" (click)="showPanel(1)">
            Documents
        </button>
        <button class="teams-admin__btn" (click)="showPanel(2)">
            Link to Site
        </button>
    </div>
    <div class="teams-admin__comp-container">

        <app-documents style="width: 85%;" class="admin__components" *ngIf="showDocuments"
            [propData]="data"></app-documents>

        <div *ngIf="showLinkToSite">
            <div *ngIf="nag " class="teams-user-admin__sync-message {{ messageclass }}">
                {{ succesMessage }}
            </div>

            <div class="teams-user-admin__confirmation-container" *ngIf="showConfirmation">
                <div class="">
                    Are you sure you want to reboot your computer?
                </div>
                <div class="teams-user-admin__confirmation">
                    <button class="teams-user-admin__btn" (click)="rebootDesktopPlugin()">
                        Confirm
                    </button>
                    <button class="teams-user-admin__btn" (click)="showConfirmation = false">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="teams-admin">
  <div class="teams-admin__left-container">
    <button class="teams-admin__btn" (click)="showPanel(1)">
      Documents
    </button>
    <button class="teams-admin__btn" (click)="showPanel(2)">
      Link to Site
    </button>
    <button class="teams-admin__btn" (click)="showPanel(3)">
      Take Room Snapshot
    </button>
    <button class="teams-admin__btn" (click)="showPanel(4)">
      Flush DNS
    </button>
    <button class="teams-admin__btn" (click)="showPanel(5)">
      Clear Team Cache
    </button>
    <button class="teams-admin__btn" (click)="showPanel(6)">
      {{dPlugin}} Logging
    </button>
  </div>

  <div class="teams-admin__right-container">

    <app-documents style="width: 85%;" class="admin__components" *ngIf="showDocuments"
      [propData]="data"></app-documents>


    <div *ngIf="showLinkToSite" class="teams-admin__panel">


      <div class="teams-admin__enablePlugin ">


        <div class="teams-admin__enablePlugin-container">
          <div class="teams-admin__enablePlugin-label">
            <label>Select a site: </label>
          </div>
          <select [(ngModel)]="siteId" required>
            <option *ngFor="let item of siteList; index as n" value="{{item.site_id}}">
              {{ item.site_name }}</option>
          </select>
        </div>

      </div>

      <div *ngIf="shouldShowSiteMessage" class="teams-admin__success-message">
        <!-- <div *ngIf="true" class="teams-admin__success-message"> -->
        <!-- {{ message }} -->
        Update Sucess
      </div>


      <div class="teams-admin__panel-button-container">
        <button class=" teams-admin__btn" (click)="linkRoomToSite()">
          {{ isLoading ? 'Confirming...' : 'Confirm' }}
        </button>
        <button class="teams-admin__btn" (click)="showLinkToSite = false">
          Cancel
        </button>
      </div>
    </div>

    <div class="text-center" *ngIf="showSnapshotPanel">
      <ul class="bottom">
        <li [innerHTML]="snapshotMessage">
        </li>
        <ng-container *ngIf="showSnapshotLoader">
          <li>
            <div class="dot1"></div>
          </li>
          <li>
            <div class="dot2"></div>
          </li>
          <li>
            <div class="dot3"></div>
          </li>
        </ng-container>
      </ul>
    </div>

    <app-flush-dns *ngIf="showFlushDNS" [refresh]="isRefresh" [pluginId]="pluginId"></app-flush-dns>

    <app-clear-team-cache *ngIf="showClearTeamCache" [refresh]="isRefresh"  [pluginId]="pluginId"></app-clear-team-cache>

    <app-plugin-logging *ngIf="showPluginLogging" [refresh]="isRefresh"  [pluginId]="pluginId" [data]="data?.details"></app-plugin-logging>

  </div>
</div>