<div class="video-additional">
  <div class="video-additional__text">
    <div class="video-additional__text-item" *ngIf="videoDeviceData?.ssl_certificate">
      <strong>SSL: </strong>
      {{ videoDeviceData?.ssl_certificate?.is_self_signed? 'Self-Signed' : 'Company-Signed'}}
      <i class="fa fa-warning" *ngIf="videoDeviceData?.ssl_certificate?.is_self_signed"></i>
      <a class="sm-font pl-2" (click)="openDialog()">(Show SHA256 Public Key and Certificate)</a>
    </div>
    <div class="video-additional__text-item">
      <strong>Software info:</strong>{{
      videoDeviceData?.softwareinfo | displayEmpty
      }}
      <span style="color: black"> {{ checkDeviceState() }}</span>
    </div>

    <div class="video-additional__text-item">
      <strong>Exchange: </strong>
      {{ videoDeviceData?.exchange | displayEmpty}}
    </div>

    <div class="video-additional__text-item">
      <strong>Nic mac address: </strong>
      {{ videoDeviceData?.mac | displayEmpty }}
    </div>

    <div class="video-additional__text-item">
      <strong>Duplex: </strong>
      {{ videoDeviceData?.duplex | displayEmpty }}
    </div>


    <div *ngIf="showUcBoardHealth" class="video-additional__text-item">
      <strong>Uc board health: </strong>
      {{ videoDeviceData?.ucboardhealth | displayEmpty }}
    </div>

    <div class="video-additional__text-item">
      <strong>Gatekeeper: </strong>
      <span *ngIf="filterGateKeeperArray.length == 0"> -- </span>

      <ul *ngIf="filterGateKeeperArray.length != 0" class="gate-keeper-ul">
        <li *ngFor="let gate of filterGateKeeperArray" class="gate-keeper-li">
          {{
          gate.address == ""
          ? "--"
          : gate.address + " : " + (gate.value ? gate.value : "")
          }}
        </li>
      </ul>
    </div>

    <div class="video-additional__text-item">
      <strong>SIP: </strong>
      <span *ngIf="filterSipKeeperArray.length !== 0">
        {{
        filterSipKeeperArray[0].address === ""
        ? "--"
        : filterSipKeeperArray[0].address +
        " : " +
        (filterSipKeeperArray[0].value
        ? filterSipKeeperArray[0].value
        : "")
        }}
      </span>
      <span *ngIf="filterSipKeeperArray.length === 0"> -- </span>
    </div>


    <div class="video-additional__text-item">
      <strong>Enabled licenses:  </strong>
      <span *ngIf="videoDeviceData?.licenses.length <= 0">None</span>

      <div *ngFor="let license of videoDeviceData?.licenses" class="license">
        {{ license }}
      </div>
    </div>

  </div>
</div>