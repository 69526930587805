<div class="devicetable">
    <input #searchDataInput (keyup)="searchData(searchDataInput.value)" placeholder="Search..." class="input-class" />
    <div class="mat-elevation-z8 child mat-table">
        <table class="devicetable__data-table" mat-table [dataSource]="dataSource" matSort>
            <ng-container *ngIf="isShowCompanyName()" matColumnDef="company_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Company</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                    <span (click)="clickRow(row.iot_id)">
                        {{ row.company_name | displayEmpty }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="iot_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Device Name</strong>
                </th>
                <td mat-cell *matCellDef="let row" title="{{ row.iot_name | displayEmpty }}">
                    <span (click)="clickRow(row.iot_id)">
                        {{ row.iot_name | displayEmpty }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="health">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Healths</strong>
                </th>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <app-table-health-icon [value]="row.health" [paused]="row.pause"></app-table-health-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="space_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Space</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                    <span (click)="clickRow(row.iot_id)" class="inner-text">
                        <div class="butu">
                            {{ row.space_names | displayEmpty }}
                        </div>
                        <div class="tooltip">
                            {{ row.space_names | displayEmpty }}
                        </div>

                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="mac_address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Mac Address</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                    <span (click)="clickRow(row.iot_id)">
                        {{ row.mac_address | displayEmpty }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="iot_ip">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>IP</strong>
                </th>
                <td mat-cell *matCellDef="let row">{{ row.iot_ip | displayEmpty }}</td>
            </ng-container>



            <ng-container matColumnDef="make">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Make</strong>
                </th>
                <td mat-cell *matCellDef="let row">{{ row.make | displayEmpty }}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="webportal">
                <th mat-header-cell *matHeaderCellDef><strong>Remote Control</strong></th>
                <td mat-cell *matCellDef="let row">
                    <fa name="cog" size="2" (click)="openWebPortalOptions(row.iot_id)" class="cog-icon"></fa>
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
            </tr>
        </table>
    </div>
</div>