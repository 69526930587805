import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../services/auth.service';
import { NotificationsService } from '../../services/notifications.service'
// import { GetCompanyService } from '../../services/get-company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slack-popup',
  templateUrl: './slack-popup.component.html',
  styleUrls: ['./slack-popup.component.scss']
})
export class SlackPopupComponent implements OnInit {

  isADMIN: number = 0;
  compId = "";
  userId: any;
  companyId = 0;
  slackAvailable

  clientID = environment.config.slackClientId;
  dbserver = environment.serverUrl;

  openNewWindow(url) {
    // console.log(url)
    var win = window.open(url, '_blank');
    win.focus();
  }
  constructor(
    private authService: AuthService,
    private notificationService: NotificationsService,
    // private getCompanyService: GetCompanyService
  ) {

  }

  ngOnInit() {
    this.checkAccessPermissions()
    this.checkSlack()
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
  }

  disableNotifications() {
    this.notificationService.disableSlackNotificatios().subscribe((data) => {
      this.slackAvailable = 0;
    });
  }

  checkSlack() {
    // this.getCompanyService.apiGetCompanyInfo().subscribe((data) => {
    this.authService.getCompanyInfo().subscribe((data) => {
      this.slackAvailable = data.response[0].slack_info.enabled;
    })
  }

  toggleUserNotify(enableflg) {
    const usrData = {
      company_id: this.compId,
      user_id: this.userId
    }

    if (enableflg == 1) {
      this.notificationService.disableSlackNotificatios().subscribe((data) => {
        this.slackAvailable = 0;
      });
    } else if (enableflg == 0) {
      this.authService.setUserNotifyOff(usrData).subscribe((data) => {
      });
    }

  }

  // This function checks admin access from the token
  checkAccessPermissions() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const accesslevel = decodedToken.accesslevel;

    if (accesslevel == "ADMIN") {
      this.isADMIN = 1;

    } else if (accesslevel == "MANAGER") {
      this.isADMIN = 1;

    } else if (accesslevel == "USER") {
      this.isADMIN = 0;
    }
  }

}
