<div class="reports-video-conf-stats">
  <div class="reports-video-conf-stats__left-panel">


    <h3>Sites</h3>

    <div class="reports-video-conf-stats__left-panel-item">

      <div class="reports-video-conf-stats__left-panel-item-text">
        <h1 class="text-center report-bold">{{videoConferSite?.total_sites}}</h1>
        <h4 class="text-center">Total sites</h4>
      </div>

      <div class="reports-video-conf-stats__text-circle-container">
        <div class="reports-video-conf-stats__text-circle">
          <h1 class="text-center report-bold">{{videoConferSite?.impacted_sites?.impacted}}</h1>
          <div class="reports-video-conf-stats__circle-container">
            <div *ngIf="videoConferSite?.impacted_sites?.difference <= -100"
              class="report-oval float-right text-success">
              <h5 class="text-center report-oval-number">{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div
              *ngIf="videoConferSite?.impacted_sites?.difference < 0 && videoConferSite?.impacted_sites?.difference >-100"
              class="report-circle float-right text-success">
              <h5 class="text-center report-circle-number">{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div
              *ngIf="videoConferSite?.impacted_sites?.difference > 0 && videoConferSite?.impacted_sites?.difference < 100"
              class="report-circle float-right text-danger">
              <h5 class="text-center report-circle-number">+{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite?.impacted_sites?.difference > 100" class="report-oval float-right text-danger">
              <h5 class="text-center report-oval-number">+{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite?.impacted_sites?.difference == 0" class="report-circle float-right text-dark">
              <h5 class="text-center report-circle-number">--</h5>
            </div>
          </div>

        </div>
        <h4 class="text-center">Impacted</h4>
      </div>
    </div>

    <div class="reports-video-conf-stats__border-bottom"></div>


    <h4>Conference Rooms</h4>

    <div class="reports-video-conf-stats__left-panel-item">

      <div class="reports-video-conf-stats__left-panel-item-text">
        <h1 class="text-center report-bold">{{videoConferSite?.total_rooms}}</h1>

        <h4 class="text-center">{{ isIot ? 'Total devices' : 'Total rooms' }}</h4>
      </div>

      <div class="reports-video-conf-stats__text-circle-container">
        <div class="reports-video-conf-stats__text-circle">
          <h1 class="text-center report-bold">{{videoConferSite?.impacted_rooms.impacted}}</h1>
          <div class="reports-video-conf-stats__circle-container">
            <div *ngIf="videoConferSite.impacted_rooms.difference <=-100" class="report-oval float-right text-success">
              <h5 class="text-center report-oval-number">{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100"
              class="report-circle float-right text-success">
              <h5 class="text-center report-circle-number">{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100"
              class="report-circle float-right text-danger">
              <h5 class="text-center report-circle-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference>=100" class="report-oval float-right text-danger">
              <h5 class="text-center report-oval-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference==0" class="report-circle float-right text-dark">
              <h5 class="text-center report-circle-number">--</h5>
            </div>
          </div>

        </div>
        <h4 class="text-center">Impacted</h4>
      </div>
    </div>

    <div class="reports-video-conf-stats__border-bottom"></div>


    <!-- AXE FIX -->

    <div class="reports-video-conf-stats__left-panel-item-separator" *ngIf="!isIot">
      <h4>Registration</h4>

      <div class="reports-video-conf-stats__left-panel-item">

        <div class="reports-video-conf-stats__left-panel-item-text">
          <h1 class="text-center report-bold">{{videoConferSite?.trigger_counts?.total_trigger_counts?.sip}}
          </h1>

          <h4 class="text-center">Total incidents</h4>
        </div>

        <div class="reports-video-conf-stats__text-circle-container">
          <div class="reports-video-conf-stats__text-circle">
            <h1 class="text-center report-bold">
              {{videoConferSite?.trigger_counts?.total_trigger_counts?.gatekeeper}}</h1>
            <div class="reports-video-conf-stats__circle-container">
              <div *ngIf="videoConferSite.impacted_rooms.difference <=-100"
                class="report-oval float-right text-success">
                <h5 class="text-center report-oval-number">{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div
                *ngIf="videoConferSite.impacted_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100"
                class="report-circle float-right text-success">
                <h5 class="text-center report-circle-number">{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div
                *ngIf="videoConferSite.impacted_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100"
                class="report-circle float-right text-danger">
                <h5 class="text-center report-circle-number">+{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div *ngIf="videoConferSite.impacted_rooms.difference>=100" class="report-oval float-right text-danger">
                <h5 class="text-center report-oval-number">+{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div *ngIf="videoConferSite.impacted_rooms.difference==0" class="report-circle float-right text-dark">
                <h5 class="text-center report-circle-number">--</h5>
              </div>
            </div>

          </div>
          <h4 class="text-center" style="opacity: 0;">Impacted</h4>
        </div>
      </div>
    </div>


    <div class="reports-video-conf-stats__left-panel-item-separator" *ngIf="isIot">
      <h4>Network Devices</h4>

      <div class="reports-video-conf-stats__left-panel-item">

        <div class="reports-video-conf-stats__left-panel-item-text">
          <h1 class="text-center report-bold">{{videoConferSite?.trigger_counts?.total_trigger_counts?.sip || 0}}
          </h1>

          <h4 class="text-center">Total incidents</h4>
        </div>

        <div class="reports-video-conf-stats__text-circle-container">
          <div class="reports-video-conf-stats__text-circle">
            <h1 class="text-center report-bold">
              {{videoConferSite?.trigger_counts?.total_trigger_counts?.gatekeeper}}</h1>
            <div class="reports-video-conf-stats__circle-container">
              <div *ngIf="videoConferSite.impacted_rooms.difference <=-100"
                class="report-oval float-right text-success">
                <h5 class="text-center report-oval-number">{{videoConferSite.impacted_rooms?.difference }}</h5>
              </div>
              <div
                *ngIf="videoConferSite.impacted_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100"
                class="report-circle float-right text-success">
                <h5 class="text-center report-circle-number">{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div
                *ngIf="videoConferSite.impacted_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100"
                class="report-circle float-right text-danger">
                <h5 class="text-center report-circle-number">+{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div *ngIf="videoConferSite.impacted_rooms.difference>=100" class="report-oval float-right text-danger">
                <h5 class="text-center report-oval-number">+{{videoConferSite.impacted_rooms?.difference}}</h5>
              </div>
              <div *ngIf="videoConferSite.impacted_rooms.difference==0" class="report-circle float-right text-dark">
                <h5 class="text-center report-circle-number">--</h5>
              </div>
            </div>

          </div>
          <h4 class="text-center" style="opacity: 0;">Impacted</h4>
        </div>
      </div>
    </div>


  </div>

  <!-- LEFT -->


  <div *ngIf="!isIot" class="reports-video-conf-stats__right-panel">

    <h4 class="reports-video-conf-stats__right-label">Total number of incidents by type</h4>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h4>Camera(s)</h4>
      </div>



      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>{{videoConferSite?.trigger_counts?.total_trigger_counts.camera}}</h1>
        </div>

        <div class="reports-video-conf-stats__circle-container">
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera <=-100"
            class="report-oval float-right text-success">
            <h5 class="text-center report-oval-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera <0 && videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera >-100"
            class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera>0 && videoConferSite?.total_trigger_counts_diff?.total_trigger_counts_diff.camera  < 100"
            class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera>=100"
            class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.camera==0"
            class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
        </div>

      </div>

    </div>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h4>Mic(s)</h4>
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>{{videoConferSite?.trigger_counts?.total_trigger_counts.mic}}</h1>
        </div>

        <!-- <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic}}</h5>
        </div> -->

        <div class="reports-video-conf-stats__circle-container">
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic <=-100"
            class="report-oval float-right text-success">
            <h5 class="text-center report-oval-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic <0 && videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic >-100"
            class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic>0 && videoConferSite?.total_trigger_counts_diff?.total_trigger_counts_diff.mic  < 100"
            class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic>=100"
            class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.mic==0"
            class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
        </div>

      </div>
    </div>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h4>Speaker(s)</h4>
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>{{videoConferSite?.trigger_counts?.total_trigger_counts.speaker}}</h1>
        </div>

        <!-- <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
        </div> -->

        <div class="reports-video-conf-stats__circle-container">
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker <=-100"
            class="report-oval float-right text-success">
            <h5 class="text-center report-oval-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker <0 && videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker >-100"
            class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker>0 && videoConferSite?.total_trigger_counts_diff?.total_trigger_counts_diff.speaker  < 100"
            class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker>=100"
            class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker==0"
            class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
        </div>

      </div>
    </div>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h4>Network</h4>
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__circle-container">
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker <=-100"
            class="report-oval float-right text-success">
            <h5 class="text-center report-oval-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker <0 && videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker >-100"
            class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">
              {{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div
            *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker>0 && videoConferSite?.total_trigger_counts_diff?.total_trigger_counts_diff.speaker  < 100"
            class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker>=100"
            class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">
              +{{videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker}}</h5>
          </div>
          <div *ngIf="videoConferSite?.trigger_counts?.total_trigger_counts_diff.speaker==0"
            class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="reports-video-conf-stats__border-bottom"></div>


    <h4 class="reports-video-conf-stats__right-label">{{statsLabel}}</h4>


    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <img src="../../../../assets/img/vs_img/reports-wellness.png" alt="">
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>
    </div>


  </div>

  <div *ngIf="isIot" class="reports-video-conf-stats__right-panel">

    <h4 class="reports-video-conf-stats__right-label">Total number of incidents by IoT devices in the space</h4>


    <!-- DIVIDE -->

    <div class="reports-video-conf-stats__iot-divider-container">


      <div class="reports-video-conf-stats__iot-divider">
        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Display</h4>
          </div>



          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.display}}</h1>
            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>

        </div>

        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Server</h4>
          </div>

          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.server}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Web</h4>
          </div>

          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.web}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Access Point</h4>
          </div>

          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.accesspoint}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>
        </div>

      </div>


      <div class="reports-video-conf-stats__iot-divider">
        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Power</h4>
          </div>



          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.power}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>

        </div>

        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Camera</h4>
          </div>

          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.camera}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Device</h4>
          </div>

          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.device}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-iot">
          <div class="reports-video-conf-stats__right-panel-item-text-iot">
            <h4>Cloud</h4>
          </div>

          <div class="reports-video-conf-stats__right-panel-item-text-circle-iot">

            <div class="reports-video-conf-stats__right-panel-item-text-iot">
              <h1>{{videoConferSite?.trigger_counts?.newTriggerCounts.cloud}}</h1>

            </div>

            <div class="reports-video-conf-stats__right-panel-item-text-iot reports-video-conf-stats__circle-incidents">
              <h5>--</h5>
            </div>

          </div>
        </div>

      </div>






    </div>


    <!-- DIVIDE -->



    <div class="reports-video-conf-stats__border-bottom"></div>


    <h4 class="reports-video-conf-stats__right-label">{{statsLabel}}</h4>


    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <img src="../../../../assets/img/vs_img/reports-wellness.png" alt="">
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>
    </div>


  </div>

</div>