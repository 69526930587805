import { map } from 'rxjs/operators'
import { Subscription } from 'rxjs/Subscription'
import { AlertService } from 'src/app/_alert'
import { GetPluginService } from 'src/app/services/get-plugin.service'

import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { EventEmitterGlobalService } from '../../services/event-emitter-global.service'
import { GetAudioService } from '../../services/get-audio.service'
import { GetCloudService } from '../../services/get-cloud.service'
import { GetIotService } from '../../services/get-iot.service'
import { GetVideoService } from '../../services/get-video.service'
import { PopupSendDataService } from '../../services/popup-send-data.service'
import { PopupTypeService } from '../../services/popupType.service'
import { RefreshApiService } from '../../services/refresh-api.service'
import { TableFilterService } from '../../services/table-filter.service'
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service'

@Component({
  selector: 'app-rescan',
  templateUrl: './rescan.component.html',
  styleUrls: ['./rescan.component.scss'],
})
export class RescanComponent implements OnInit {
  @Input() deviceId: any;
  @Input() data: any;
  @Input() popupState: any;
  @Input() alertType: any;
  @Input() alertsId: any;
  @Input() runTutorial: any;
  @Input() pluginId: number;
  @Input() isShouldShowLinkSite: any;
  @Output() showLinkSiteEvent = new EventEmitter<boolean>();
  @Input() cloudRoomData: any;
  @Input() oldPorts: any;

  isLogitech = false;

  links = environment.config.links;
  loader = environment.config.dynamicImage.loader;
  dPlugin: String = environment.config.dynamicText.dPlugin;
  dCollector: String = environment.config.dynamicText.dCollector;
  isTeleportivityAllowed: boolean = environment.config.teleportivityEnabled;
  //room update var
  isOnClickRoomUpdate: boolean = false;
  highlightNum: number = 1;

  isdisable: number = 0;
  isEdit: number = 0;
  isDelete: number = 0;
  showLoginMessage: any = false;
  showUpdatedLoginMessage: any = false;
  updatedLoginMessage: any;
  searchingLogin: any = false;
  showTimeoutError: any = false;
  loginErrorMessage: any;
  isRoomBeingUpdated: boolean = false;
  isLogitechRescan: boolean = false;

  subscription: Subscription;
  actionCount: number = 0;
  deviceActionData: any;
  deviceDetails: any;
  allSiteListData: any;
  allSiteList: any;
  pluginSiteIds: [];
  deviceActionId: number;
  addDeviceResponse = [];
  errorMessage: string;
  showData: boolean = false;
  showDeleteDevice: boolean = false;
  isShowFirmwareUpdate: boolean = false;
  isShowFirmwareUpdateMessage: boolean = false;
  isShowFirmwareUpdateConfirm: boolean = false;

  showData3: boolean = false;
  showData4: boolean = false;
  showData5: boolean = false;
  isPexip: boolean = false;
  showCloud: boolean = false;
  isShowTeleportivity: boolean = false;
  alertsTriggerData: any;
  showMsg30Sec: boolean = false;

  collectorId: '';
  siteId: -1;
  index: '';
  ipAddress: '';
  userIndex: any;
  // runTut = localStorage.getItem('runTut');
  showAddDevice: any = 0;
  showTryAgain: any = 0;
  findDevice: any = 0;
  showConfirm: any = 0;
  showLoginDetails: boolean;
  deviceLogindetails: any;
  videodeviceDetails: any;
  audioDeviceDetails: any;
  sid: -1;
  updateIpPlaceHolder: string;
  isUpdateIPError: boolean;
  updateIpMsg: string;
  isLoading: boolean = false;
  isShowVerifyIp: boolean = true;
  isShowUpdateIp: boolean = false;
  isShowWellness: boolean = false;
  isShowCancelUpdateIp: boolean = true;
  shouldShowLinkPlugin: boolean = false; //
  shouldShowLinkSite: boolean = false;
  shouldShowEditSite: boolean = false;
  shoudShowRebootPC: boolean = false;
  shouldShowRebootZoom: boolean = false;
  shouldShowDisablePlugin: boolean = false;
  shouldShowEnablePlugin: boolean = false;
  shouldShowSetZoomSettings: boolean = false;
  //---- snapshot variables-----
  showSnapshotPanel: boolean = false;
  showSnapshotLoader: boolean = false;
  snapshotMessage: string;
  //----------------------------
  showMessage: boolean;
  message: string;
  pluginMessage: string;
  siteMessage: string;
  isPluginError: boolean = false;
  isShowPluginDownload: boolean = false;
  isShowPluginBtn: boolean = true;
  showCredentialButton: boolean = false;
  showCallButton: boolean = false;
  //Teleportivity
  isEnableTeleportivity: boolean = false;
  userSupportList: any;
  userSupport: any = {
    user_id: -1,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    full_name: 'Add New User',
  };
  directAccessUrl: string = '';
  userSupportId: number = -1;
  directAccessConfigText: string = 'Disabled';
  directAccessNotifs: string = '';
  control: FormControl;
  siteForm: FormGroup;

  submitted = false;
  model: any = {};

  shouldShowDocuments: boolean = false;
  propData: any;

  succesMessage: any = 'Update Sucess';
  isShowPolyMessage: any = false;
  polyMessage: any = 'Invalid License Key';
  firmwareSuccessMessage: any = '';
  firmwareSuccessMessageClass: any;
  setTimer: any;
  updatePassword: any = '';
  linkPluginPassword: any = '';
  linkPluginSelect: any = '';
  linkSiteSelect: any = '';
  shouldShowPluginMessage: boolean = false;
  shouldShowSiteMessage: boolean = false;
  isDoneLinkingPlugin: boolean = false;
  isDoneLinkingSite: boolean = false;
  // isRoomBeingUpdated: boolean = false;
  // isOnClickRoomUpdate: boolean = false;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  deviceModel: any;
  serialNumber: any;
  firmware: any;
  deviceMac: any;
  softwareRelease: any;
  activeMic: any;
  activeCamera: any;
  iotIp: any;
  deviceRoomName: any;
  deviceIP: any;

  showWellnessSuccess: any = false;
  showWellnessComponent: any = false;

  enableInProduction: any = environment.config.enableInProduction;
  showWellnessInProd: any = false;
  companyId: any;

  collectorID: any = '';
  iotDevicePorts: any;
  deviceName: any = '';
  devicePassword: any = '';
  deviceFlag: any;
  iotName: any;

  aDeviceIsFound: any = false;

  showSuccessMsg: any = false;
  showSuccessMsgIot: any = false;
  successMessage: any = 'Device Successfully Added';

  errorMsg: any = 'Device Not Found';
  showErrMsg: any = false;
  showErrorMessageIot: any = false;

  errorMessageIot: any = 'Could not find Iot device. Please try again.';

  showSearching: any = false;
  showSearchingIot: any = false;

  showAddDeviceIot: any = false;
  showErrorMessage: any = false;
  closeButtonImage: any = './assets/img/iot/delete-btn.png';

  iotDeviceIP: any;
  iotDeviceMac: any;
  iotDeviceName: any;
  iotDeviceMake: any;

  discoverIpFromIot: any = false;
  iotPortsHolder: any;
  iotDeviceIPFound: any = false;
  isDomotzIntegration = environment.config.domotzIntegration;
  iotHostName: any;

  iotDeviceId: any;
  hidePassword: boolean = true;
  isRefresh: boolean = false;
  showPluginLogging: boolean = false;

  rescanDevice = () => {
    // this.submitted = true;
    this.resetButton();
    if (this.data && this.data.device_type === 'logitech') {
      this.isLogitechRescan = true;
      this.isRefresh = !this.isRefresh;
    }
    else {
      if (!this.siteForm.invalid) {
        this.addDevice();
      }
    }
  };

  iotComponents: any;
  canUpdateIotDevice: any = false;
  iotIpAlreadyExist: any = false;
  audioRoomName: any;

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  constructor(
    private _alertService: AlertService,
    private tableFilterService: TableFilterService,
    private EventEmitterGlobal: EventEmitterGlobalService,
    public http: HttpClient,
    private Auth: AuthService,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    private getVideoService: GetVideoService,
    private route: ActivatedRoute,
    private router: Router,
    private getAudioService: GetAudioService,
    private getVideoDataService: GetVideoService,
    private getIot: GetIotService,
    private getCloudUserService: GetCloudService,
    private refreshApiService: RefreshApiService,
    private getPluginService: GetPluginService,
    public zoomPluginAction: ZoomPluginActionService
  ) {}

  ngOnInit() {
    this.resetButton();
    this.resetFields();
    this.resetFieldsForIot();

    this.runTutorial = 0;
    this.showAddDevice = 0;
    this.showTryAgain = 0;
    this.findDevice = 0;
    this.showConfirm = 0;
    // }
    this.getAllSiteDetail();
    this.checkForm();

    if (this.alertType === 'video') {
      this.getVideoApiData();
    } else if (this.alertType === 'cloud') {
      this.getcloudApiData();
    } else if (this.alertType === 'audio') {
      this.getAudioApiData();
    } else {
      //for future implementation;
    }
  }

  ngOnChanges(changes: SimpleChanges) {    
    if (changes?.popupState) this.isLogitechRescan = false;
    this.isLogitech = this.data?.device_type === 'logitech'
    if (changes?.popupState) this.isLogitechRescan = false;
    if (this.alertType === 'video') {
      this.getVideoApiData();

      // this.checkVideoUpdateAvailability()
    } else if (this.alertType === 'cloud') {
      // this.setRebootButton(false);
      this.pluginId = this.data?.plugin_id;
      this.getcloudApiData();
    } else if (this.alertType === 'audio') {
      this.getAudioApiData();
    }
    this.getCompanyId();
    //teleportivity
    if (this.data.direct_access_info && this.data.direct_access_info !== null) {
      this.isEnableTeleportivity = this.data.direct_access_info.isEnable === 1;
      if (this.isEnableTeleportivity) {
        const aType = this.alertType === 'cloud' ? 'zoom' : this.alertType;
        this.directAccessUrl =
          location.origin +
          '/direct-access-login/' +
          this.data.direct_access_token +
          '/' +
          aType +
          '/' +
          this.deviceId;
        this.directAccessConfigText = 'Enabled';
      } else {
        this.directAccessUrl = '';
        this.directAccessConfigText = 'Disabled';
      }

      if (this.data.direct_access_info.supportUser !== null) {
        this.userSupport = { ...this.data.direct_access_info.supportUser };
      } else {
        this.userSupport = {
          user_id: -1,
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          full_name: 'Add New User',
        };
      }
      this.userSupportId = this.userSupport.user_id;
    } else {
      this.isEnableTeleportivity = false;
    }
    // }

    //wellness
    // if (this.alertType == 'video') {
    //   this.getVideoWellnessDetails();
    // }
  }

  setWellnessProd = (enableInProd, companyId) => {
    return enableInProd && (companyId == 15 || companyId == 36);
  };
  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
  };

  get f() {
    return this.siteForm.controls;
  }

  checkForm() {
    this.siteForm = this.formBuilder.group({
      collectorId: [''],
      siteId: [''],
      index: [''],
      ipAddress: [''],
      roomName: [''],
      username: [''],
    });
  }

  setShowLinkSite(value: boolean) {
    this.showLinkSiteEvent.emit(value);
  }
  updateForm = new FormGroup({
    roomName: new FormControl('', [
      Validators.required,
      Validators.maxLength(45),
    ]),
  });

  async submitForm() {
    try {
      if (this.isRoomBeingUpdated) {
        return;
      }
      this.isRoomBeingUpdated = true;
      let formObj = this.updateForm.value;
      let roomId = this.data.room_id;
      if ((formObj.roomName || '').trim().length == 0) {
        throw 'Please enter a room name';
      }
      if ((formObj.roomName || '').trim().length > 70) {
        throw "Room name can't be more then 70 characters long";
      }
      let postData: any = {
        room_name: formObj.roomName,
        old_room_name: this.data.room_name,
      };

      if (this.alertsTriggerData && this.alertsTriggerData.audio_device_id) {
        postData.audio_device_id = this.alertsTriggerData.audio_device_id;
      } else if (
        this.alertsTriggerData &&
        this.alertsTriggerData.video_device_id
      ) {
        postData.video_device_id = this.alertsTriggerData.video_device_id;
      } else if (this.data.room_id) {
        postData.room_id = this.data.room_id;
      }
      console.log(postData);
      const res = await this.getCloudUserService
        .updateRoomName(postData)
        .toPromise();
      console.log('Response ', res);
      if (res.status != 200) {
        throw res.error;
      }
      this._alertService.success(res.data, this.options);
      this.updateForm.reset();
    } catch (err) {
      console.log('Error occ', err);
      const message =
        typeof err == 'string' ? err : 'Something went wrong while updating';
      this._alertService.error(message, this.options);
    } finally {
      this.isRoomBeingUpdated = false;
    }
  }

  showDelete() {
    this.resetButton();
    this.isShowCancelUpdateIp = true;
    this.showDeleteDevice = true;
    this.isShowVerifyIp = true;
    this.isShowTeleportivity = false;
  }

  showUpdate() {
    this.resetButton();
    this.showData3 = true;
    this.isShowCancelUpdateIp = true;
    this.isShowVerifyIp = true;
  }

  showAlerts() {
    this.resetButton();
    // this.setRebootButton(false);

    this.isShowCancelUpdateIp = true;
    this.showData4 = true;
  }

  showPexip() {
    this.resetButton();
    this.isPexip = true;
  }
  // update room name
  //

  editRoomName() {
    this.resetButton();
    this.isOnClickRoomUpdate = true;
  }
  showUpdateIp() {
    switch (this.alertType) {
      case 'audio':
        this.updateIpPlaceHolder = 'Audio Device Ip';
        break;
      case 'video':
        this.updateIpPlaceHolder = 'Video Device Ip';
        break;
    }

    this.resetButton();

    this.showData5 = true;
    this.isShowVerifyIp = true;
    this.isShowCancelUpdateIp = true;
  }

  showWellnessCheck = () => {
    this.resetButton();
    this.isShowWellness = true;
  };

  showCredentialButtonFunc = () => {
    this.resetButton();
    this.showCredentialButton = true;
  };

  showCallButtonFunc = () => {
    this.resetButton();
    this.showCallButton = true;
  };

  resetButton = () => {
    this.isOnClickRoomUpdate = false;
    this.showData = false;
    this.showDeleteDevice = false;
    this.showData3 = false;
    this.isOnClickRoomUpdate = false;
    this.showData4 = false;
    this.showData5 = false;
    this.showCloud = false;
    this.isShowTeleportivity = false;
    this.isUpdateIPError = false;
    this.updateIpMsg = ' ';
    this.isShowVerifyIp = false;
    this.isShowUpdateIp = false;
    this.isShowWellness = false;
    this.isShowCancelUpdateIp = false;
    this.showCredentialButton = false;
    this.showCallButton = false;
    this.shouldShowDocuments = false;
    this.isShowFirmwareUpdate = false;
    this.isShowFirmwareUpdateMessage = false;
    this.isShowFirmwareUpdateConfirm = false;
    this.isShowPolyMessage = false;
    this.shouldShowLinkPlugin = false;
    this.shoudShowRebootPC = false;
    this.shouldShowRebootZoom = false;
    this.shouldShowDisablePlugin = false;
    this.shouldShowEnablePlugin = false;
    this.shouldShowSetZoomSettings = false;
    this.showSuccessMsg = false;
    this.showSnapshotPanel = false;
    this.isLogitechRescan = false;
    this.shouldShowLinkSite = false;
    this.showPluginLogging = false;
    this.isPexip = false;
  };

  setHighlight(n) {
    //check this
    this.highlightNum = n;
    this.toDisable(0);
  }

  toDisable(n) {
    if (this.isdisable == n) {
      this.isdisable = 0;
    } else {
      this.isdisable = n;
    }
  }

  getVideoApiData = () => {
    this.getVideoDataService
      .apiGetVideoDeviceDetails(this.alertsId)
      .subscribe((data: any) => {
        const [res] = data.response;
        // console.log('getVideoApiData: ', res)
        this.alertsTriggerData = res;
        this.showWellnessComponent = res.device_type != 'cisco' && res.device_type != 'logitech' ;
        this.setWellnessSchedule(res)
      });
  }

  getcloudApiData() {
    this.getCloudUserService
      .fetchApiCloudUserDetails(this.alertsId)
      .subscribe((data: any) => {
        this.alertsTriggerData = data;

      });
  }

  getAudioApiData() {
    this.getAudioService
      .apiGetAudioDeviceDetails(this.alertsId)
      .subscribe((data) => {
        this.alertsTriggerData = data['response'];
      });
  }

  receiveMessage($event) {
    if ($event == 'reset') {
      if (this.alertType === 'video') {
        this.getVideoApiData();
      } else if (this.alertType === 'cloud') {
        this.getcloudApiData();
      } else if (this.alertType === 'audio') {
        this.getAudioApiData();
      } else {
      }
    } else {
      console.log('Else unreset', $event);
      this.alertsTriggerData = $event;
    }
  }

  // This function gets a list of sites for this company
  getAllSiteDetail() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = {
      company_id: decodedToken.company_id,
    };
    this.companyId = decodedToken.company_id;
    this.isLoading = true;

    this.Auth.getAllSiteList().subscribe((data) => {
      const allSiteData = data.response;
      if (allSiteData) {
        this.allSiteListData = [...allSiteData];
        this.linkPluginSelect =
          allSiteData.length > 0 ? allSiteData[0].site_id : 0;
        this.linkSiteSelect =
          allSiteData.length > 0 ? allSiteData[0].site_id : 0;
      }
      this.isLoading = false;
    });
  }

  toEdit(n) {
    if (this.isEdit == n) {
      this.isEdit = 0;
    } else {
      this.isEdit = n;
    }
  }

  toDelete(n) {
    if (this.isDelete == n) {
      this.isDelete = 0;
    } else {
      this.isDelete = n;
    }
  }

  //This function continues to check wether the found device process status is  completed or failed
  // checkStatus(id) {
  //   const idData = {
  //     action_id: id,
  //   };

  //   this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
  //     if (this.actionCount == 45) {
  //       this.actionCount = 0;
  //       this.errorMessage = 'Could not find device. Please try again.';
  //       this.showAddDevice = 0;
  //       this.showTryAgain = 1;
  //       this.findDevice = 0;
  //       this.showConfirm = 0;
  //     }
  //     if (
  //       actionData.response[0].status !== 'COMPLETE' &&
  //       actionData.response[0].status !== 'FAILED'
  //     ) {
  //       const rk = this;
  //       setTimeout(function () {
  //         rk.checkStatus(id);
  //       }, 1000);
  //       this.actionCount++;
  //     } else if (actionData.response[0].status === 'FAILED') {
  //       this.deviceDetails.push(actionData.response[0]);
  //       return;
  //     } else if (actionData.response[0].status === 'COMPLETE') {
  //       this.deviceDetails.push(actionData.response[0]);
  //       // this.resetForm();
  //       this.showAddDevice = 0;
  //       this.showTryAgain = 0;
  //       this.findDevice = 0;
  //       this.showConfirm = 1;
  //       return;
  //     }
  //   });
  // }

  resetForm() {
    (<HTMLInputElement>document.getElementById('collectorId')).value = '';
    (<HTMLInputElement>document.getElementById('siteId')).value = '';
    (<HTMLInputElement>document.getElementById('roomName')).value = '';
    (<HTMLInputElement>document.getElementById('ipAddress')).value = '';
  }


  cancelRescanUpdate() {
    this.deviceDetails = [];
    this.showData = false;
    this.showDeleteDevice = false;
    this.showData3 = false;
    this.showData4 = false;
    this.showData5 = false;
    this.isShowTeleportivity = false;
  }

  cancelDelete() {
    this.showData = false;
    this.showDeleteDevice = false;
    this.showData3 = false;
    this.showData4 = false;
    this.showData5 = false;
    this.isShowTeleportivity = false;
    this.isUpdateIPError = false;
    this.updateIpMsg = ' ';
    this.isShowVerifyIp = true;
    this.isShowUpdateIp = false;
    this.isShowWellness = false;
    this.isShowCancelUpdateIp = true;
  }

  cancelUpdate() {
    this.showData = false;
    this.showDeleteDevice = false;
    this.showData3 = false;
    this.showData4 = false;
    this.showData5 = false;
    this.isShowTeleportivity = false;
  }

  // This find the device that will be added to the database
  addDeviceOld = () => {
    this.deviceActionData = [];
    this.deviceDetails = [];
    this.deviceActionId = undefined;
    this.addDeviceResponse = [];
    this.errorMessage = undefined;

    this.showData = false;
    event.preventDefault();
    const target = event.target;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const deviceData = {
      company_id: decodedToken.company_id,
      device_id: this.deviceId,
    };

    if (this.alertType === 'video') {
      this.getVideoService.rescanDeviceData(deviceData).subscribe((data) => {
        this.deviceActionData = data.response[0];
        this.deviceActionId = data.response[0].action_id;
        const actionData = {
          // action_id: data.response[0].action_id
          action_id: this.deviceActionId,
        };
        if (this.deviceActionData['status'] !== 'Error') {
          this.Auth.postDeviceActionId(actionData).subscribe((reponseData) => {
            this.checkStatus(reponseData.response[0].action_id);
          });
        }
      });
    } else if (this.alertType === 'audio') {
      this.getAudioService.rescanDeviceData(deviceData).subscribe((data) => {
        this.deviceActionData = data.response[0];
        this.deviceActionId = data.response[0].action_id;
        const actionData = {
          // action_id: data.response[0].action_id
          action_id: this.deviceActionId,
        };
        if (this.deviceActionData['status'] !== 'Error') {
          this.Auth.postDeviceActionId(actionData).subscribe((reponseData) => {
            this.checkStatus(reponseData.response[0].action_id);
          });
        }
      });
    } else {
    }
    this.showData = true;
    this.showDeleteDevice = false;
    this.showData3 = false;
    this.showData4 = false;
    this.showData5 = false;
    this.isShowTeleportivity = false;
  };

  setIndex(n) {
    this.userIndex = n;
    if (n >= 0) {
      this.collectorId = this.allSiteListData[n].collector_id;
      this.siteId = this.allSiteListData[n].site_id;
    }
  }

  deleteDevice() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const data = {
      company_id: decodedToken.company_id,
      device_id: this.deviceId,
      aType: this.alertType,
    };
    this.Auth.deactivateDevice(data).subscribe((data) => {
      if (data['response'][0]['status'] == 'OK') {
        const routeUrl =
          this.alertType === 'video' ? 'video/alldevices' : 'audio/alldevices';
        this.router.navigate([routeUrl]);
        this.sendPopupType(99);
      }
    });
  }
  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  verifyIp() {
    this.isUpdateIPError = false;
    this.updateIpMsg = ' ';
    const newIp = (<HTMLInputElement>document.getElementById('device-ip'))
      .value;
    if (newIp.length === 0) {
      this.isUpdateIPError = true;
      this.updateIpMsg = 'Please provide ip new address.';
      this.isShowVerifyIp = true;
      this.isShowUpdateIp = false;
      this.isShowCancelUpdateIp = true;
    } else {
      const target = event.target;
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);

      const deviceData = {
        company_id: decodedToken.company_id,
        device_id: this.alertsId,
        htype: this.alertType,
        ip: newIp,
        user_id: decodedToken.user_id,
      };
      this.isLoading = true;
      this.Auth.testDeviceIp(deviceData).subscribe((data) => {
        const result = data.response[0];
        if (result.status === 1) {
          this.actionCount = 0;
          this.checkActionVerifyIP(result.action_id);
        } else {
          this.isLoading = false;
          this.isUpdateIPError = true;
          this.isShowVerifyIp = true;
          this.isShowUpdateIp = false;
          this.updateIpMsg = result.msg;
          this.isShowCancelUpdateIp = true;
        }
      });
    }
  }

  checkActionVerifyIP(actionId) {
    const idData = {
      action_id: actionId,
    };

    this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
      this.isLoading = true;
      if (this.actionCount == 90) {
        this.isLoading = false;
        this.isUpdateIPError = true;
        this.updateIpMsg = 'Could not sync device with IP.';
        this.isShowVerifyIp = true;
        this.isShowUpdateIp = false;
        this.isShowCancelUpdateIp = true;
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkActionVerifyIP(actionId);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.isLoading = false;
        this.isUpdateIPError = true;
        this.updateIpMsg = 'Could not sync device with IP.';
        this.isShowVerifyIp = true;
        this.isShowUpdateIp = false;
        this.isShowCancelUpdateIp = true;
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.isLoading = false;
        this.isUpdateIPError = false;
        this.isShowVerifyIp = false;
        this.isShowUpdateIp = true;
        this.isShowCancelUpdateIp = true;
        this.updateIpMsg = 'IP verification success!';
      }
    });
  }

  updateIP() {
    this.isLoading = true;
    this.isUpdateIPError = false;
    this.isShowCancelUpdateIp = false;
    const deviceData = {
      device_id: this.alertsId,
      htype: this.alertType,
      ip: (<HTMLInputElement>document.getElementById('device-ip')).value,
    };
    this.Auth.updateDeviceIp(deviceData).subscribe((data) => {
      const result = data.response[0];
      if (result.status === 1) {
        this.isLoading = false;
        this.isUpdateIPError = false;
        this.updateIpMsg = 'Successfully updated IP.';
        this.isShowVerifyIp = false;
        this.isShowUpdateIp = false;
        this.isShowCancelUpdateIp = false;
      } else {
        this.isLoading = false;
        this.isUpdateIPError = true;
        this.updateIpMsg = 'Error on updating IP, please try again.';
        this.isShowVerifyIp = true;
        this.isShowUpdateIp = false;
        this.isShowCancelUpdateIp = false;
      }
    });
  }

  showRebootPC = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shoudShowRebootPC = bool;
  };

  showRebootZoom = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowRebootZoom = bool;
  };

  showEnablePlugin = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowEnablePlugin = bool;
  };

  showLogging = (bool) => {
    this.resetButton();
    this.isRefresh = !this.isRefresh;
    this.showPluginLogging = bool;
  }

  showDisablePlugin = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowDisablePlugin = bool;
  };

  showSetZoomSetting = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowSetZoomSettings = bool;
  };

  showLinkPlugin = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowLinkPlugin = bool;
  };

  showLinkSite = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowLinkSite = bool;
  };

  showEditSite = (bool) => {
    this.resetButton();
    this.showCloud = bool;
    this.shouldShowEditSite = bool;
  };

  cancel = () => {
    this.shouldShowRebootZoom = false;
    this.showMessage = false;
    this.shouldShowLinkPlugin = false;
    this.shouldShowDisablePlugin = false;
    this.shouldShowEnablePlugin = false;
    this.shouldShowSetZoomSettings = false;
    this.isShowTeleportivity = false;
  };

  linkRoomToSite = () => {
    this.siteMessage = '';
    const siteId = this.linkSiteSelect;
    const ipAddress = this.getComputerIp(this.data.connected_devices.device_ip);

    this.isLoading = true;
    this.isShowPluginDownload = false;
    this.zoomPluginAction.visibility_one_loader = true;
    const siteInfo = this.allSiteListData.find((s) => {
      return s.site_id === parseInt(siteId);
    });

    if (siteInfo.collector_status == -1) {
      this.siteMessage = `Site ${this.dCollector} is offline.`;
      this.isLoading = false;
      this.shouldShowSiteMessage = true;
      this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
      this.resetSiteMessage();
    } else if (ipAddress) {
      const pluginId = this.data.plugin_id;
      const serviceType = this.data.service_type;

      let gotResponse = false;

      setTimeout(() => {
        if (gotResponse) return

        this.siteMessage = `Operation is taking longer than expected. It will continue in the background, check again after 10min.`;
        this.isLoading = false;
        this.shouldShowSiteMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetSiteMessage();
      }, 60000)
      
      this.getPluginService
        .linkPluginToSiteUpdate(siteId, pluginId, serviceType)
        .subscribe((data) => {
          gotResponse = true;
          if (data.response[0].status == 1) {
            this.setShowLinkSite(false);
            this.siteMessage = `Successful in Site ${this.dCollector} linking `;
            this.shouldShowSiteMessage = true;
            this.firmwareSuccessMessageClass = ' admin__confirm-message--green';
            this.isDoneLinkingSite = true;
            this.resetSiteMessage();
            this.setTimer = setTimeout(() => {
              this.ds.sendPopupType(0);
            }, 3000);
          } else {
            this.siteMessage = `${this.dPlugin} does not exist.`;
            this.isLoading = false;
            this.shouldShowSiteMessage = true;
            this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
            this.resetSiteMessage();
          }
        });
    } else {
      this.siteMessage = 'Room has no IP address.';
      this.shouldShowSiteMessage = true;
      this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
      this.resetSiteMessage();
      this.isLoading = false;
    }
  };

  linkZoomPlugin = () => {
    this.pluginMessage = '';
    const siteId = this.linkPluginSelect;
    const password = this.linkPluginPassword;
    const ipAddress = this.getComputerIp(this.data.connected_devices.device_ip);
    this.isLoading = true;
    this.isShowPluginDownload = false;
    this.isPluginError = false;
    this.isShowPluginBtn = false;
    this.zoomPluginAction.visibility_one_loader = true;
    const siteInfo = this.allSiteListData.find((s) => {
      return s.site_id === parseInt(siteId);
    });

    if (siteInfo.collector_status === -1) {
      this.pluginMessage = `Site ${this.dCollector} is offline.`;
      this.isLoading = false;
      this.isPluginError = true;
      this.isShowPluginBtn = true;
      this.shouldShowPluginMessage = true;
      this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
      this.resetPluginMessage();
    } else if (ipAddress && password.trim().length > 0) {
      const postData = {
        ip: ipAddress,
        site_id: siteId,
        company_id: this.data.company_id,
        room_name: this.data.room_name,
        zoom_user_id: this.data.zoom_user_id,
        ping_period: 10,
        get_action_list_period: 10,
        data_send_period: 30,
      };

      this.getPluginService.linkZoomPlugin(postData).subscribe((data) => {
        const response = data.response[0];
        if (response.result === 'Error') {
          this.isLoading = false;
          this.isPluginError = true;
          this.isShowPluginDownload = true;
          this.isShowPluginBtn = true;
          this.pluginMessage = response.message;
          this.shouldShowPluginMessage = true;
          this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
          this.resetPluginMessage();
        } else {
          this.checkLinkPlugin(response.action_id, postData);
        }
      });
    } else {
      if (password.length === 0) {
        this.pluginMessage = 'Please provide zoom password';
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetPluginMessage();
      } else {
        this.pluginMessage = 'Room room has no IP address.';
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetPluginMessage();
      }
      this.isLoading = false;
      this.isPluginError = true;
      this.isShowPluginBtn = true;
    }
  };

  getComputerIp(deviceIp: string): string {
    const len = deviceIp.length;
    let searhKey = 'Computer';
    if (len > 0) {
      const cPosition = deviceIp.search(searhKey);
      if (cPosition >= 0) {
        const startIndex = cPosition + searhKey.length + 2;
        const ips = deviceIp.substring(startIndex)?.split(';');
        return ips[0].trim();
      }

      searhKey = 'Digital Signage Only';
      const dPosition = deviceIp.search(searhKey);
      if (dPosition >= 0) {
        const startIndex = cPosition + searhKey.length + 2;
        const ips = deviceIp.substring(startIndex)?.split(';');
        return ips[0].trim();
      }
      return '';
    }
    return '';
  }

  setPluginSetting = () => {
    const password = this.updatePassword;
    this.pluginMessage = '';
    this.zoomPluginAction.visibility_one_loader = true;
    this.isShowPluginBtn = false; // disable button? after click
    if (password.length > 0) {
      const actionParams = JSON.stringify({
        host: 'localhost',
        password,
      });
      const actionName = 'SetZoomSettings';
      this.getPluginService
        .createAction(this.data.plugin_id, actionParams, actionName)
        .subscribe((result) => {
          if (result.response[0].status === 'OK') {
            this.checkSetZoomSettings(result.response[0].action_id);
          } else {
            this.zoomPluginAction.visibility_one_loader = false;
          }
        });
    } else {
      this.pluginMessage = 'Please provide Zoom Password.';
      this.zoomPluginAction.visibility_one_loader = false;
      this.shouldShowPluginMessage = true;
      this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
      this.resetPluginMessage();
    }
  };

  checkLinkPlugin(actionId, postData) {
    this.isLoading = true;
    const idData = {
      action_id: actionId,
    };

    this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
      if (this.actionCount == 90) {
        this.pluginMessage = `Unable to communicate with zoom ${this.dPlugin}. Please restart or install zoom ${this.dPlugin} in IP ${postData.ip}.`;
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetPluginMessage();
        return (this.isLoading = false);
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkLinkPlugin(actionId, postData);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.pluginMessage = `Unable to communicate with zoom ${this.dPlugin}. Please restart or install zoom ${this.dPlugin} in IP ${postData.ip}.`;
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetPluginMessage();
        return (this.isLoading = false);
      } else if (actionData.response[0].status === 'COMPLETE') {
        const password = this.linkPluginPassword;
        this.getPluginService
          .linkZoomPluginUpdate(actionId, password)
          .subscribe((data) => {
            const response = data.response[0];
            if (response.status === -1) {
              this.shouldShowPluginMessage = true;
              this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
              this.resetPluginMessage();
              this.pluginMessage = response.message;
            } else {
              this.pluginMessage = `Successful in ${this.dPlugin} linking `;
              this.shouldShowPluginMessage = true;
              this.isDoneLinkingPlugin = true;
              this.firmwareSuccessMessageClass =
                ' admin__confirm-message--green';
              this.resetPluginMessage();
              this.setTimer = setTimeout(() => {
                this.ds.sendPopupType(0);
              }, 3000);
            }
            return (this.isLoading = false);
          });
      }
    });
  }

  //only for update?
  checkSetZoomSettings(actionId) {
    const idData = {
      action_id: actionId,
    };
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService.getPluginAction(idData).subscribe((actionData) => {
      if (this.actionCount == 90) {
        this.pluginMessage = `Unable to update zoom setting.`;
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetPluginMessage();
        return (this.zoomPluginAction.visibility_one_loader = false);
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkSetZoomSettings(actionId);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.pluginMessage = `Unable to update zoom setting.`;
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetPluginMessage();

        return (this.zoomPluginAction.visibility_one_loader = false);
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.updatePassword = '';
        this.pluginMessage = 'Successfull in updating zoom setting.';
        this.shouldShowPluginMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--green';
        this.resetPluginMessage();
        return (this.zoomPluginAction.visibility_one_loader = false);
      }
    });
  }

  resetPluginMessage = () => {
    this.setTimer = setTimeout(() => {
      this.shouldShowPluginMessage = false;
    }, 3000);
    this.zoomPluginAction.visibility_one_loader = false;
  };

  resetSiteMessage = () => {
    this.setTimer = setTimeout(() => {
      this.shouldShowSiteMessage = false;
    }, 3000);
    this.zoomPluginAction.visibility_one_loader = false;
  };

  rebootPc = () => {
    const actionParams = {};
    const actionName = 'Reboot';
    this.zoomPluginAction.visibility_one_loader = true; // i think this is not working?

    this.getPluginService
      .createAction(this.data.plugin_id, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          // mesage is needed?
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
    this.resetButton();
  };

  restartZoom = () => {
    const actionParams = {};
    const actionName = 'RestartZoomRoomsApp';
    this.zoomPluginAction.visibility_one_loader = true;

    this.getPluginService
      .createAction(this.data.plugin_id, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
    this.resetButton();
  };

  enableZoomPlugin = () => {
    this.shouldShowEnablePlugin = false;
    this.data.zoom_plugin_enabled = 1;
    this.resetButton();
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService
      .enableZoomPlugin(this.data.plugin_id, this.data.zoom_user_id)
      .subscribe((result) => {
        this.zoomPluginAction.visibility_one_loader = false;
        this.ds.sendPopupType(0);
      });
  };

  disableZoomPlugin = () => {
    this.shouldShowDisablePlugin = false;
    this.data.zoom_plugin_enabled = 0;
    this.resetButton();
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService
      .disableZoomPlugin(this.data.plugin_id, this.data.zoom_user_id)
      .subscribe((result) => {
        this.zoomPluginAction.visibility_one_loader = false;
        this.ds.sendPopupType(0);
      });
  };

  showTeleportivity = () => {
    this.resetButton();
    this.isShowTeleportivity = true;
    this.directAccessNotifs = '';
    const companyId = {
      company_id: this.data.company_id,
    };
    this.isLoading = true;
    this.Auth.getUserList(companyId).subscribe((data) => {
      const users = [...data.response];
      this.userSupportList = users.map((user) => {
        const { user_id, first_name, last_name, email, phone, company_id } =
          user;
        return {
          user_id,
          first_name,
          last_name,
          email,
          phone,
          company_id,
          full_name: first_name + ' ' + last_name,
        };
      });
      this.userSupportList = [
        { user_id: -1, full_name: 'Add New User' },
        ...this.userSupportList,
      ];
      this.isLoading = false;
    });
  };

  onUserSupportChange = (event) => {
    const userId = parseInt(event.target.value);

    const user = this.userSupportList.find((u) => {
      return u.user_id === userId;
    });

    this.userSupport = { ...user };
  };

  setTeleporivity = (value) => {
    this.isLoading = true;
    this.isEnableTeleportivity = value;
    const flag = this.isEnableTeleportivity ? 1 : 0;
    const aType = this.alertType === 'cloud' ? 'zoom' : this.alertType;
    this.Auth.setDirectAccess(
      this.data.company_id,
      this.deviceId,
      flag,
      aType
    ).subscribe((result) => {
      if (flag) {
        this.directAccessConfigText = 'Enabled';
        this.Auth.setDirectAccessToken(
          this.deviceId,
          this.data.company_id,
          aType
        ).subscribe((result) => {
          if (result.response.token) {
            this.directAccessUrl =
              location.origin +
              '/direct-access-login/' +
              result.response.token +
              '/' +
              aType +
              '/' +
              this.deviceId;
          } else {
            this.directAccessUrl = '';
          }
          this.isLoading = false;
        });
      } else {
        this.directAccessUrl = '';
        this.directAccessConfigText = 'Disabled';
        this.isLoading = false;
      }
    });
  };

  saveDirectAccessSupportUser = () => {
    this.directAccessNotifs = '';
    this.isLoading = true;
    const user_id = this.userSupport.user_id;
    const first_name = (<HTMLInputElement>(
      document.getElementById('notif-first-name')
    )).value;
    const last_name = (<HTMLInputElement>(
      document.getElementById('notif-last-name')
    )).value;
    const email = (<HTMLInputElement>document.getElementById('notif-email'))
      .value;
    const phone = (<HTMLInputElement>document.getElementById('notif-phone'))
      .value;
    if (first_name.length <= 0) {
      this.directAccessNotifs = 'Please enter first name!';
      this.isLoading = false;
      return;
    }
    if (last_name.length <= 0) {
      this.directAccessNotifs = 'Please enter last name name!';
      this.isLoading = false;
      return;
    }
    if (email.length <= 0) {
      this.directAccessNotifs = 'Please enter email!';
      this.isLoading = false;
      return;
    }
    if (phone.length <= 0) {
      this.directAccessNotifs = 'Please enter phone!';
      this.isLoading = false;
      return;
    }

    const params = {
      user_id,
      first_name,
      last_name,
      email,
      phone,
      is_delete: 0,
      device_id: this.deviceId,
      company_id: this.data.company_id,
    };
    const aType = this.alertType === 'cloud' ? 'zoom' : this.alertType;

    this.Auth.setDirecAccessSupportUser(params, aType).subscribe((result) => {
      this.isLoading = false;
      this.directAccessNotifs = 'Done saving support user!';
    });
  };

  copyDirectAccesUrl = (accessUrl) => {
    accessUrl.select();
    document.execCommand('copy');
    accessUrl.setSelectionRange(0, 0);
  };

  //

  changeDeviceDetails = () => {
    this.showLoginDetails = true;
    const username =
      this.alertType === 'video'
        ? (<HTMLInputElement>document.getElementById('deviceUsername')).value
        : '';
    const password = (<HTMLInputElement>(
      document.getElementById('devicePassword')
    )).value;
    const deviceId = this.deviceId;

    if (this.alertType === 'video') {
      this.getVideoService
        .getDeviceLoginActionId(username, password, deviceId)
        .subscribe((data) => {
          this.videodeviceDetails = data.response[0];
          this.deviceActionId = data.response[0].action_id;
          if (this.videodeviceDetails['status'] !== 'Error') {
            this.checkDeviceLoginStatus(data.response[0].action_id);
          }
        });
    } else if (this.alertType === 'audio') {
      this.getAudioService
        .getDeviceLoginActionId(username, password, deviceId)
        .subscribe((data) => {
          this.audioDeviceDetails = data.response[0];
          this.deviceActionId = data.response[0].action_id;

          if (this.audioDeviceDetails['status'] !== 'Error') {
            this.checkDeviceLoginStatus(data.response[0].action_id);
          }
        });
    } else {
    }
  };

  checkDeviceLoginStatus = (id) => {
    const idData = {
      action_id: id,
    };

    this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
      this.showLoginMessage = true;
      this.searchingLogin = true;
      if (this.actionCount === 90) {
        this.searchingLogin = false;
        this.actionCount = 0;
        this.showTimeoutError = true;
        this.loginErrorMessage = 'Could not find device. Please try again.';
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkDeviceLoginStatus(id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.searchingLogin = false;
        this.deviceLogindetails = actionData.response[0];
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.searchingLogin = false;
        this.deviceLogindetails = actionData.response[0];
        this.getVideoService
          .updateDeviceLogin(this.deviceLogindetails.action_id)
          .subscribe((reponseData) => {
            this.showUpdatedLoginMessage = true;
            this.updatedLoginMessage = reponseData.response[0];
          });
        return;
      }
    });
  };

  showDocumentCenter = () => {
    //edit depending  on what column
    this.resetButton();

    //video
    //cloud
    this.propData = this.determineQuadrant(this.alertType);
    // console.log('this.propData: ', this.propData)
    // console.log('this.alertType: ', this.alertType)
    this.shouldShowDocuments = true;
  };

  determineQuadrant = (type) => {
    if (type == 'video') {
      return {
        quadrant_group: 'VIDEO',
        device_id: this.data.video_device_id,
        offset: 0,
        description: this.data.device_name,
      };
    }

    if (type == 'cloud') {
      return {
        quadrant_group: 'CLOUD',
        device_id: this.data.zoom_user_id,
        description: this.data.room_name,
        offset: 0,
      };
    }

    if (type == 'audio') {
      return {
        quadrant_group: 'AUDIO',
        device_id: this.data.audio_device_id,
        description: this.data.room_name,
        offset: 0,
      };
    }

    return {};
  };

  showFirmwareUpdate = () => {
    this.resetButton();
    this.isShowFirmwareUpdate = true;
    this.isShowFirmwareUpdateConfirm = true;
  };

  getUserInfo = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id, user_id } = helper.decodeToken(token);
    return { company_id, user_id };
  };

  firmwareUpdate = () => {
    this.resetButton();
    this.isShowFirmwareUpdate = true;
    this.checkDeviceState();

    this.isShowFirmwareUpdateMessage = true;
    this.setTimer = setTimeout(() => {
      this.resetButton();
    }, 3000);
  };

  checkDeviceState = () => {
    const { firmwares, device_state, update_action_id } = this.data;

    if (device_state === 'UPDATING' || update_action_id !== null) {
      this.firmwareSuccessMessage = 'Firmware update currently in progress';
    }

    if (firmwares !== null && firmwares.length !== 0) {
      if (device_state === 'UPDATING' || update_action_id !== null) {
        this.firmwareSuccessMessage = 'Firmware update currently in progress';
      } else {
        const { updateRequired: checkForUpdate } = firmwares.filter(
          (e) => e.modUid === 'local-system'
        )[0];
        if (
          checkForUpdate == '0' ||
          (checkForUpdate == '0' && update_action_id != null)
        ) {
          this.firmwareSuccessMessage =
            'Device is up to date. No update available';
        } else {
          if (this.data.device_type === 'polycom') {
            this.polyMessage = 'Invalid License Key';
            this.isShowPolyMessage = true;
          } else {
            this.firmwareSuccessMessage =
              'Request to update firmware successfully initiated';
            this.firmwareSuccessMessageClass = ' admin__confirm-message--green';
            this.EventEmitterGlobal.watcherParams('callVideoApiData');
            this.videoCreateAction();
          }
        }
      }
    } else {
      if (this.data.device_type === 'polycom') {
        this.isShowFirmwareUpdate = true;
        this.polyMessage = 'Invalid License Key';
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';

        this.isShowPolyMessage = true;
      }
    }
  };

  videoCreateAction = () => {
    const { company_id, user_id } = this.getUserInfo();
    const label = 'Update firmware';
    const action_name = 'update-firmware';
    const ip = this.data.ipv4;
    const type = this.data.device_type;
    const password = this.data.password.toString();
    const username = this.data.username.toString();
    const collector_id = this.data.collector_id;
    const video_device_id = this.data.video_device_id;
    const params = {
      ip,
      type,
      password,
      username,
      collector_id,
      video_device_id,
      company_id,
    };

    this.getVideoDataService
      .videoCreateAction(label, action_name, user_id, params)
      .subscribe(
        (data: any) => {
          // // console.log('videoCreateAction: ', data)
          this.EventEmitterGlobal.watcherParams('callVideoApiData');
        },
        (err) => {}
      );
  };

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

 
  onWellnessTimeChange() {
    this.wellnessCheckTimeWithColon = Object.values(this.wellnessCheckTime).join(':')
  }

  setData = (params) => {
    console.log('setData for video: ', params);
    
    
    
    
    if (params) {
      const {
        model,
        serialnumber,
        mac,
        softwareinfo,
        active_mic,
        active_camera,
        mrpairinfo,
        firmware,
        ipv4,
        device_name,
        status_load,
      } = params;
      
      
      this.deviceModel = model;
      this.serialNumber = this.checkValueIfEmpty(serialnumber);
      this.firmware = softwareinfo? softwareinfo : (firmware? firmware : '--')
      this.deviceMac = mac;
      this.deviceIP = ipv4;
      this.softwareRelease = this.checkValueIfEmpty(softwareinfo);
      this.activeMic = this.checkValueIfEmpty(active_mic);
      this.activeCamera = this.checkValueIfEmpty(active_camera);
      
      
      let isModelCisco = model.toLowerCase().indexOf('cisco');
      let isCiscoVersionReady = softwareinfo?.split('.')[0]
      
      console.log('getNewModel: ', isModelCisco)
      console.log('isCiscoVersionReady: ', isCiscoVersionReady)
      
      if (isModelCisco > -1) {
        if ( isCiscoVersionReady == 'ce10' ||  isCiscoVersionReady == 'ce11' || isCiscoVersionReady == 'ce12' || isCiscoVersionReady == 'ce13' || isCiscoVersionReady == 'ce14') {
          this.activeMic = this.getNewCiscoMic(status_load);
          this.activeCamera = this.getNewCiscoCamera(status_load);
        }
      }
      
      // IF IOT Domotz integration is enabled we have to disable searching and auto adding
      // if(!this.isDomotzIntegration){
      if (mrpairinfo) {
        if (mrpairinfo.mrpairinfo_raw_data) {
          if (mrpairinfo.mrpairinfo_raw_data.data) {
            // if (Array.isArray(mrpairinfo.mrpairinfo_raw_data.data.ConnectedDeviceList)) {
            const [res] =
              mrpairinfo.mrpairinfo_raw_data.data.ConnectedDeviceList;
            console.log('ConnectedDeviceList: ', res);
            if (res) {
              this.iotIp = res.mrIpAddress;
              // }
              this.activeCamera = 1;
            }
          }
        }
      }
      // }
    }
    //  return error
  };
  
  getNewCiscoMic = (params) => {
    let newMic = 0;
    let i = 0;
    const getMic = params.Status.Audio.Input.Connectors.Microphone;
    
    if(getMic.length > 0) {
      while ( i < getMic.length) {
        console.log(getMic[i].ConnectionStatus.Value)
        if(getMic[i].ConnectionStatus.Value == 'Connected') {
         newMic++
        }
        i++
    }
    console.log('newMic: ', newMic)
    return newMic;
    
    } else {
      return 0;
    }
    
  }
  
   getNewCiscoCamera = (params) => {
    let newCam = 0;
    let i = 0;
    
    const getCam = params.Status.Cameras.Camera
    
    if(getCam.length > 0) {
      while ( i < getCam.length) {
        console.log(getCam[i].Connected.Value)
        if(getCam[i].Connected.Value == 'True') {
          newCam++
        }
      i++
    }
    console.log('newCam: ', newCam)
    return newCam;
    
     } else {
      return 0;
    }
  }
  
   

  checkValueIfEmpty = (value) => (value ? value : '--');

  sendUpdateDeviceData = () => {
    this.Auth.sendUpdateDeviceData(this.deviceActionId, this.alertType).subscribe((data: any) => {
      console.log('sendUpdateDeviceData: ', data);
      // const [res] = data.response;
      // if (res.status == 'Ok') {
      this.resetFields();
      this.resetFieldsForIot();
      if (data.error === null)
        this.showSuccessMsg = true;
      else
        this.handleError(data.error)
    });
  };

  addDevice = () => {
    console.log('data: ', this.data);

    const { site_id, iot_components, collector_id, room_name } = this.data;
    this.siteId = site_id;
    this.iotComponents = iot_components;
    this.collectorID = collector_id;
    this.deviceRoomName = room_name;
    
    this.resetFields();
    this.resetFieldsForIot();
    this.showData = true;

    if (this.alertType == 'video') {
      this.getVideoService
        .rescanDeviceData(this.deviceId)
        .subscribe((data: any) => {
          console.log('getVideoService: ', data);

          let [res] = data.response;
          if (res.status == 'OK') {
            this.deviceActionId = res.action_id;
            this.checkStatus(res.action_id);
            this.showSearching = true;
          } else this.handleError(res.message)
        });
    }

    if (this.alertType == 'audio') {
      this.getAudioService
        .rescanDeviceData(this.deviceId)
        .subscribe((data: any) => {
          console.log('getAudioService: ', data);

          let [res] = data.response;
          if (res.status == 'OK') {
            this.deviceActionId = res.action_id;
            this.checkStatus(res.action_id);
            this.showSearching = true;
          } else this.handleError(res.message)
        });
    }
  };

  checkStatus = (action_id) => {
    console.log('action_id: ', action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe(async(actionData) => {
      console.log('postDeviceActionId: ', actionData);
      console.log('actionCount: ', this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 120) {
        //resets field if device not found
        if (!this.aDeviceIsFound) {
          console.log('reset!');
          this.resetFields();
          this.showErrMsg = true;
          this.setTimer = setTimeout(() => {
            this.showErrMsg = false
        }, 4000);
          return;
        }

        this.resetFieldsForIot();
        this.showErrMsg = true;
        this.setTimer = setTimeout(() => {
            this.showErrMsg = false
        }, 4000);
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
        if (this.actionCount == 30) {
          this.showMsg30Sec = true;
          setTimeout(() => {
            this.showMsg30Sec = false;
          }, 70*1000);
       }
      } else if (actionData.response[0].status === 'FAILED') {
        this.resetFields();
        const [newData] = actionData.response;
        this.errorMsg = 'Could not find device. Please try again.';
        if (newData.results.message != 'device not found') {
          this.errorMsg = newData.results.message;
        }
        this.showErrMsg = true;
        
         this.setTimer = setTimeout(() => {
              this.showErrMsg = false
          }, 4000);
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        console.log('COMPLETE!!!');
        const [newData] = actionData.response;

        if (!this.discoverIpFromIot) {
          //for audio
          this.resetFields();
          this.showAddDevice = true;
          this.aDeviceIsFound = true;
          this.setData(newData.results);
          if (this.iotIp && !this.iotDeviceIPFound) {
            this.discoverIotDevice(this.iotIp);
          } else {
            console.log('no iot ip is present');
            return;
          }
        } else {
          this.resetFieldsForIot();
          console.log('iot IP found');
          this.setDataIot(newData.results);
          return;
        }
      }
    });
  };

  discoverIotDevice = (iotIp) => {
    //edit later
    console.log('discoverIotDevice: ', iotIp);
    console.log('collectorID: ', this.collectorID);
    console.log('siteId: ', this.siteId);

    this.Auth.getNmap(this.collectorID, iotIp, 'add', this.siteId).subscribe(
      (data) => {
        let [res] = data.response;
        if (res.status == 'OK') {
          this.iotDeviceIPFound = true;
          this.discoverIpFromIot = true;
          this.checkStatus(res.action_id);
          this.showSearchingIot = true;
        } else {
          this.errorMessageIot = res.message;
          this.iotIp = 0;
          this.showErrorMessageIot = true;
        }
      },
      (err) => {
        this.showSearching = false;
      }
    );
  };


  resetFieldsForIot = () => {
    this.actionCount = 0;
    this.showSearchingIot = false;
    this.showErrorMessageIot = false;
    this.showSearchingIot = false;
    this.showAddDeviceIot = false;
    this.showMsg30Sec = false;
  };

  resetFields = () => {
    this.actionCount = 0;
    this.showSearching = false;
    this.showErrorMessage = false;
    this.showErrMsg = false;
    this.showAddDevice = false;
    this.showSnapshotPanel = false;
    this.showMsg30Sec = false;
  };

  setDataIot = (params) => {
    const res2 = JSON.parse(params.results);
    const {
      nmap: { host },
    } = res2;
    console.log('res2: ', res2);
    console.log('host: ', host);

    if (host) {
      this.iotDeviceIP = this.getDeviceIp(host);
      this.iotDeviceMac = this.getDeviceMac(host);
      this.iotDeviceName = `${this.deviceRoomName} VISUAL+`;
      this.iotDeviceMake = this.getDeviceMake(host);
      this.iotHostName = this.getHostname(host);
      // else we can also say that no ports available?

      if (host.ports) {
        if (host.ports.port) {
          this.iotPortsHolder = host.ports.port.map((e) => {
            return {
              portid: e.portid,
              portname: e.service.name,
              is_monitored: 1,
            };
          }); //ask if its no empty?
        } else {
          this.iotPortsHolder = [];
        }
      } else {
        this.iotPortsHolder = [];
      }

      // this.iotPortsHolder = [] // remove

      this.iotDevicePorts = JSON.stringify(this.iotPortsHolder);

      this.showAddDeviceIot = true;
    } else {
      //display device not found
      this.resetFieldsForIot();
      this.iotIp = 0;
      this.showErrorMessageIot = true;
    }
  };

  getDeviceIp = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[0].addr) {
        return host.address[0].addr;
      }
    } else {
      if (host.address.addr) {
        return host.address.addr;
      }
    }
    return 'null';
  };

  getDeviceMac = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[1].addr) {
        return host.address[1].addr;
      }
    }
    return 'null';
  };

  getHostname = (host: any) => {
    if (host.hostnames) {
      if (host.hostnames.hostname) {
        if (host.hostnames.hostname.name) {
          return host.hostnames.hostname.name;
        }
      }
    }
    return 'null';
  };

  getDeviceMake = (host: any) => {
    //check if its in the address
    if (Array.isArray(host.address)) {
      if (host.address[1].vendor) {
        return host.address[1].vendor;
      }
    }
    return 'Unknown';
  };

  // audioDeviceId: any;

  addDeviceAndLink = () => {

    this.resetFields();
    //after adding the audio we can add iot
    this.resetFieldsForIot();
    this.addIotDeviceAndLink();
  };

  addIotDeviceAndLink = () => {
    this.getIot
      .iotLiteAdd(
        this.collectorID,
        this.iotDeviceName,
        'host',
        this.iotIp,
        this.iotDevicePorts,
        this.iotDeviceMac,
        '8',
        this.iotDeviceMake,
        this.siteId
      )
      .subscribe((data: any) => {
        console.log('this.data: ', data);
        const res = data.response;
        this.iotDeviceId = res.iot_id;
        if (this.deviceId && this.iotDeviceId) {
          console.log(this.deviceId);
          console.log(this.iotDeviceId);
          this.linkIotToAudioDevice();
        } else {
          this.showErrorMessageIot = true;
          this.errorMessageIot = 'Both Device Added but Device Linking failed';
        }
      });
  };

  linkIotToAudioDevice = () => {
    this.getIot
      .iotLiteLinkDevice(this.iotDeviceId, this.deviceId, 'audio')
      .subscribe(
        (data: any) => {
          console.log('linkIotToAudioDevice: ', data);
          this.successMessage = 'Both Device Successfull and Linked';
          this.showSuccessMsg = true;
          this.iotIp = 0;
        },
        (error) => {
          // this.displayMessage = 'Someting went wrong when linking IoT Domotz Device.';
          //another error
          this.showErrorMessageIot = true;
          console.log('showErrorMessageIot: ', this.showErrorMessageIot);
          this.errorMessageIot = 'Both Device Added but Device Linking failed';
        }
      );
  };

  getMediaTypeAgain = () => (this.deviceFlag ? 'audio' : 'video');
  
  
  
  // WELLNESS ---------------------- optimize this shitr
  
  allowSiteOverwrite: any = false;
  wellnessCheckTime: any = { hour: 1, minute: 30, second: 0 };
  wellnessCheckTimeWithColon: string = '01:30:00';
  wellnessLocaltimezone: string;
  wellnessInterval: any = '1';
  
  wellnessNameOfDay: any = "1";
  wellnessDate: any;
  enableWellnessCheckBox: any = false;
  

  intervalEveryWeekOnChange = (event: any) => {
    let nameOfDays = event.target.value;
    this.wellnessNameOfDay = nameOfDays;
  };
  
  
  getSiteWellnessSchedule = () => {
    this.Auth.getSiteWellnessSchedule(this.data.site_id).subscribe(
      (data: any) => {
        const [res] = data.response;
        // console.log('getSiteWellnessSchedule: ', res)        
        // this.setWellnessSchedule(res)
        this.wellnessInterval = res.schedule_interval;
        this.wellnessNameOfDay = res.wellness_week;

        this.wellnessLocaltimezone = res.local_utc
        this.wellnessCheckTimeWithColon = res.wellness_check_time
        
        if(res.wellness_check_time  != null ) {
          let formatedTime = res.wellness_check_time?.split(':');
          this.wellnessCheckTime = {
          hour: parseInt(formatedTime[0]),
          minute: parseInt(formatedTime[1]),
          second: parseInt(formatedTime[2]),
        };
      
        
        }

      }
    );
  };
      
  setWellnessSchedule = ({schedule_interval, wellness_check_time, wellness_month, 
  wellness_week, wellness_enabled, allow_site_overwrite, local_utc}) => {
    
    this.enableWellnessCheckBox = wellness_enabled;
    this.allowSiteOverwrite = allow_site_overwrite;

    if (!wellness_enabled) {
       this.getSiteWellnessSchedule();
    } else {
        this.wellnessInterval = schedule_interval || 1;
        this.wellnessNameOfDay = wellness_week || 1;
        this.wellnessDate = wellness_month;
      
        this.wellnessLocaltimezone = local_utc
        this.wellnessCheckTimeWithColon = wellness_check_time
      
        let formatedTime = wellness_check_time?.split(':');
        this.wellnessCheckTime = {
        hour: parseInt(formatedTime[0]),
        minute: parseInt(formatedTime[1]),
        second: parseInt(formatedTime[2]),
      };
    
    }

  };

  intervalOnChange = (event: any) => {
    let wellnessInterval = event.target.value;
    if (wellnessInterval == 7) {
      console.log('show Days')
    }
    if (wellnessInterval == 30) {
      console.log('show Calendar')
    }
    
    this.wellnessInterval = wellnessInterval;
  };

  enableWellness = (event) => {
    this.enableWellnessCheckBox = event ? 1 : 0;
    console.log('this.enableWellnessCheckBox: ', this.enableWellnessCheckBox);
  };

  allowSiteOverwriteEvent = (event) => {
    this.allowSiteOverwrite = event ? 1 : 0;
    console.log('this.allowSiteOverwrite: ', this.allowSiteOverwrite);
  };

  setVideoWellnessCheck = () => {
    const formattedWellness = Object.values(this.wellnessCheckTime).join(':');

    this.Auth.setVideoWellnessCheck(
      this.data.video_device_id,
      formattedWellness,
      this.wellnessInterval,
      this.wellnessNameOfDay,
      this.wellnessDate,
      this.enableWellnessCheckBox,
      this.allowSiteOverwrite
    ).subscribe((data: any) => {
      console.log(data);
      const [res] = data.response;

      console.log('setVideoWellnessCheck: ', res);
      this.showWellnessSuccess = true;

      this.setTimer = setTimeout(() => {
        this.showWellnessSuccess = false;
      }, 1000);
    });
  };

  onClickTakeRoomSnapshot = () => {
    this.resetButton()
    this.showSnapshotPanel = true;
    this.takeSnapshot()
  }

  takeSnapshot = () => {
    const actionParams = {};
    const actionName = 'TakeImagefromWebCam';
    this.snapshotMessage = `<h5>Capturing Snapshot<h5/>`
    this.toggleSnapshotLoader(true)
    
    this.getPluginService.createAction(this.data?.plugin_id, actionParams, actionName).subscribe(result => {
      if (result.status === 200) {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id, this.processActionResults)
        this.zoomPluginAction.setActionCount();
      }
      else {
        this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
        this.toggleSnapshotLoader(false)
      }
    }, err => {
      this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
      this.toggleSnapshotLoader(false)
    });
  }

  processActionResults = (status, results) => {
    if (status === 'COMPLETE')
      this.uploadDeviceSnapshotToS3(results)
    else {
      this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
      this.toggleSnapshotLoader(false)
    }
  }

  uploadDeviceSnapshotToS3 = (results) => {
    if (results?.result?.status == "Failed") {
      this.snapshotMessage = results?.result?.message;
      this.toggleSnapshotLoader(false);
      return;
    }
    const { company_id, zoom_user_id } = this.data;
    this.snapshotMessage = `<h5>Snapshot successfully captured. Please wait while we securely upload it to the Document Centre</h5>`
    this.getPluginService.uploadSnapshotToS3(company_id, zoom_user_id, results?.result?.data).subscribe(resp => {
      if (resp && resp.response) {
        this.snapshotMessage = `<h5>Snapshot successfully uploaded to the Document Centre</h5>
        You can download it here: <a href="${this.docPath+resp?.response?.file_id+'/'+1}">${resp?.response?.file_name}</a>`
        this.toggleSnapshotLoader(false);
      }
      else {
        this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
        this.toggleSnapshotLoader(false);
      }
    }, err => {
      this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
      this.toggleSnapshotLoader(false)
    })
  }

  toggleSnapshotLoader = (show) => {
		this.showSnapshotLoader = show
	}
  
  handleError = (message) => {
    this.errorMsg = message
    this.showErrMsg = true
    this.setTimer = setTimeout(() => {
      this.showErrMsg = false
    }, 3000)
  }  
// --------------- WELLNESSS


}
