<!-- ROOM AND USER -->
<div class="collector-admin">
	<div class="collector-admin__button-container">
		<button class="collector-admin__btn" (click)="showPanel(6)">Remote Access</button>
		<button class="collector-admin__btn" (click)="showPanel(1)">Alerts</button>
		<button class="collector-admin__btn" (click)="showPanel(2)">Documents</button>
		<button class="collector-admin__btn" (click)="showPanel(3)">Edit Site Name</button>
		<button class="collector-admin__btn" (click)="showPanel(4)">Delete Site</button>

		<button class="collector-admin__btn" (click)="showPanel(5)">Collector Logging</button>
	</div>

	<div class="collector-admin__comp-container">
		<div class="collector-admin__right-column-item" *ngIf="showRemoteAccess">
			<app-remote-access [data]="data"></app-remote-access>
		</div>

		<div class="collector-admin__right-column-item" *ngIf="showCollectorAlert">
			<app-collector-alert-panel [data]="data"></app-collector-alert-panel>
		</div>

		<app-documents class="admin__components" *ngIf="shouldShowDocuments" [propData]="propData"> </app-documents>

		<div class="collector-admin__right-column-item" *ngIf="showDeleteDevice">
			<app-delete-site-dialog
				[dataWhenComponentIsNotUsedAsDialog]="{ config }"
				(hideComponent)="showDeleteDevice = false"
			></app-delete-site-dialog>
			<!-- <div class="form-group">
        <div class="row m-2">
          <h6 class="margin-left:auto;margin-right:auto;">
            Are you sure you want to delete this device?
          </h6>
        </div>
        <div class="row mt-2">
          <div class="form-group">
            <button (click)="deleteDevice()" class="collector-admin__btn" style="margin-top: 10px;">
              Delete Device
            </button>
          </div>
          <div class="form-group pl-2">
            <button (click)="cancelDelete()" class="collector-admin__btn_grey" style="margin-top: 10px;">
              Cancel
            </button>
          </div>
        </div>
      </div> -->
		</div>

		<div class="collector-admin__right-column-item" *ngIf="showRenameDevice">
			<div class="collector-admin__edit-name">
				<div class="collector-admin__edit-name-inner">
					<label class="collector-admin__edit-name-label">{{editSiteText}}</label>
					<input type="text" value="" name="rename" id="rename" #rename class="collector-admin__edit-name-input" />
				</div>
				<button class="collector-admin__btn_grey collector-admin__rename-btn" (click)="editSiteName(rename.value)">
					{{alreadyEditingInProcess ? "Submitting..." : "Submit"}}
				</button>
			</div>
		</div>

		<div class="collector-admin__right-column-item" *ngIf="showLogging">
			<!-- <app-collector-logging [data]="data"></app-collector-logging> -->
			<div class="collector-admin__logging">
				<div class="text-center">
					<ul class="bottom">
						<li [innerHTML]="snapshotMessage"></li>
						
						<ng-container *ngIf="showDownloading">
							<li>
								<div class="dot1"></div>
							</li>
							<li>
								<div class="dot2"></div>
							</li>
							<li>
								<div class="dot3"></div>
							</li>
						</ng-container>
					</ul>
					<div class="taking-longer" *ngIf="showMsg30Sec && !showErrMsg">
						<p style="font-size: larger;">It’s taking longer than expected, but we’ll get there as fast as we
							can...</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
