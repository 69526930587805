<div class="alerts">
  <div *ngIf="showSuccesMessage">
    <h4>Configuration Saved!</h4>

  </div>
  <table *ngIf="alertsTriggerData">
    <tbody class="alerts-table">
      <tr *ngIf="alertType == 'cloud'">
        <th>Bitrate</th>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td>warning</td>
        <td class="alerts__bar-width">
          <ng5-slider [(value)]="bitWarningValue" [options]="{ floor: bitLimitMin + 0.1, ceil: bitLimitMax, step: 0.1 }"
            (valueChange)="bitrateWarningChange($event)">
          </ng5-slider>
        </td>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td>alert</td>
        <td class="alerts__bar-width">
          <ng5-slider [(value)]="bitAlertValue" [options]="{ floor: bitLimitMin, ceil: bitLimitMax - 0.1, step: 0.1 }"
            (valueChange)="bitrateAlertChange($event)">
          </ng5-slider>
        </td>
      </tr>

      <!-- VIDEO -->
      <div *ngIf="alertType == 'video'" class="tabs">
        <div class="index">
          <div tabindex="0" role="tab" (click)="tab=1" (keyup.enter)="tab=1" [ngClass]="{active: tab===1}">Video</div>
          <div tabindex="0" role="tab" (click)="tab=2" (keyup.enter)="tab=2" [ngClass]="{active: tab===2}">Audio</div>
          <div tabindex="0" role="tab" (click)="tab=3" (keyup.enter)="tab=3" [ngClass]="{active: tab===3}">Content</div>
          <div tabindex="0" role="tab" (click)="tab=4" (keyup.enter)="tab=4" [ngClass]="{active: tab===4}">Additional
          </div>
          <div tabindex="0" role="tab" (click)="tab=5" (keyup.enter)="tab=5" [ngClass]="{active: tab===5}">Peripherals
          </div>
        </div>
        <div class="contents">
          <div *ngIf="tab === 1">
            <tr>
              <th>Jitter (Rx)</th>
            </tr>

            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.video.jitter.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.jitter.rx.warning" [options]="{
                          floor: alertsTriggerData?.trigger_limits.video.jitter.rx.min,
                          ceil: alertsTriggerData?.trigger_limits.video.jitter.rx.max - 1,
                          disabled: !this.alertsTriggerData?.triggers.video.jitter.rx.enabled
                        }" (valueChange)="jitterWarningChangeRx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <ng5-slider id="jitterAlertSlider" [(value)]="this.alertsTriggerData?.triggers.video.jitter.rx.alert"
                  [options]="{
                          floor: alertsTriggerData?.trigger_limits.video.jitter.rx.min + 1,
                          ceil: alertsTriggerData?.trigger_limits.video.jitter.rx.max,
                          disabled: !this.alertsTriggerData?.triggers.video.jitter.rx.enabled
                        }" (valueChange)="jitterAlertChangeRx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>


            <tr>
              <th>Jitter (Tx)</th>
            </tr>

            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.video.jitter.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.jitter.tx.warning" [options]="{
                                floor: alertsTriggerData?.trigger_limits.video.jitter.tx.min,
                                ceil: alertsTriggerData?.trigger_limits.video.jitter.tx.max - 1,
                                disabled: !this.alertsTriggerData?.triggers.video.jitter.tx.enabled
                              }" (valueChange)="jitterWarningChangeTx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td></td>
              <td>
                <ng5-slider id="jitterAlertSlider" [(value)]="this.alertsTriggerData?.triggers.video.jitter.tx.alert"
                  [options]="{
                                floor: alertsTriggerData?.trigger_limits.video.jitter.tx.min + 1,
                                ceil: alertsTriggerData?.trigger_limits.video.jitter.tx.max,
                                disabled: !this.alertsTriggerData?.triggers.video.jitter.tx.enabled
                              }" (valueChange)="jitterAlertChangeTx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>

            <tr>
              <th nowrap>Packet Loss (Rx)</th>
            </tr>
            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.video.packetsloss.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.packetsloss.rx.warning" [options]="{
                          floor: alertsTriggerData?.trigger_limits.video.packetsloss.rx.min,
                          ceil: alertsTriggerData?.trigger_limits.video.packetsloss.rx.max - 0.1,
                          step: 0.01,
                          disabled: !this.alertsTriggerData?.triggers.video.packetsloss.rx.enabled
                        }" (valueChange)="packetLossWarningChangeRx($event, 'video')"></ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.packetsloss.rx.alert" [options]="{
                            floor: alertsTriggerData?.trigger_limits.video.packetsloss.rx.min + 0.1,
                            ceil: alertsTriggerData?.trigger_limits.video.packetsloss.rx.max,
                            step: 0.01,
                            disabled: !this.alertsTriggerData?.triggers.video.packetsloss.rx.enabled
                          }" (valueChange)="packetLossAlertChangeRx($event, 'video')"></ng5-slider>
                </div>
              </td>
            </tr>

            <tr>
              <th nowrap>Packet Loss (Tx)</th>
            </tr>
            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.video.packetsloss.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.packetsloss.tx.warning" [options]="{
                                floor: alertsTriggerData?.trigger_limits.video.packetsloss.tx.min,
                                ceil: alertsTriggerData?.trigger_limits.video.packetsloss.tx.max - 0.1,
                                step: 0.01,
                                disabled: !this.alertsTriggerData?.triggers.video.packetsloss.tx.enabled
                              }" (valueChange)="packetLossWarningChangeTx($event, 'video')"></ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.packetsloss.tx.alert" [options]="{
                                  floor: alertsTriggerData?.trigger_limits.video.packetsloss.tx.min + 0.1,
                                  ceil: alertsTriggerData?.trigger_limits.video.packetsloss.tx.max,
                                  step: 0.01,
                                  disabled: !this.alertsTriggerData?.triggers.video.packetsloss.tx.enabled
                                }" (valueChange)="packetLossAlertChangeTx($event, 'video')"></ng5-slider>
                </div>
              </td>
            </tr>

            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <th>Latency (Rx)</th>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.video.latency.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.latency.rx.warning" [options]="{
                            floor: alertsTriggerData?.trigger_limits.video.latency.rx.min,
                            ceil: alertsTriggerData?.trigger_limits.video.latency.rx.max - 1,
                            disabled: !this.alertsTriggerData?.triggers.video.latency.rx.enabled
                          }" (valueChange)="latencyWarningChange($event, 'video')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.latency.rx.alert" [options]="{
                            floor: alertsTriggerData?.trigger_limits.video.latency.rx.min + 1,
                            ceil: alertsTriggerData?.trigger_limits.video.latency.rx.max,
                            disabled: !this.alertsTriggerData?.triggers.video.latency.rx.enabled
                          }" (valueChange)="latencyAlertChange($event, 'video')">
                  </ng5-slider>
                </div>
              </td>
            </tr>

            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <th>Latency (Tx)</th>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.video.latency.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.latency.tx.warning" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.video.latency.tx.min,
                                        ceil: alertsTriggerData?.trigger_limits.video.latency.tx.max - 1,
                                        disabled: !this.alertsTriggerData?.triggers.video.latency.tx.enabled
                                      }" (valueChange)="latencyWarningChange($event, 'video')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td></td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.latency.tx.alert" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.video.latency.tx.min + 1,
                                        ceil: alertsTriggerData?.trigger_limits.video.latency.tx.max,
                                        disabled: !this.alertsTriggerData?.triggers.video.latency.tx.enabled
                                      }" (valueChange)="latencyAlertChange($event, 'video')">
                  </ng5-slider>
                </div>
              </td>
            </tr>

          </div>

          <div *ngIf="tab === 2">
            <tr>
              <th>Jitter (Rx)</th>
            </tr>

            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.audio.jitter.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.jitter.rx.warning" [options]="{
                                    floor: alertsTriggerData?.trigger_limits.audio.jitter.rx.min,
                                    ceil: alertsTriggerData?.trigger_limits.audio.jitter.rx.max - 1,
                                    disabled: !this.alertsTriggerData?.triggers.audio.jitter.rx.enabled
                                  }" (valueChange)="jitterWarningChangeRx($event, 'audio')">
                </ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <ng5-slider id="jitterAlertSlider" [(value)]="this.alertsTriggerData?.triggers.audio.jitter.rx.alert"
                  [options]="{
                              floor: alertsTriggerData?.trigger_limits.audio.jitter.rx.min + 1,
                              ceil: alertsTriggerData?.trigger_limits.audio.jitter.rx.max,
                              disabled: !this.alertsTriggerData?.triggers.audio.jitter.rx.enabled
                            }" (valueChange)="jitterAlertChangeRx($event, 'audio')">
                </ng5-slider>
              </td>
            </tr>


            <tr>
              <th>Jitter (Tx)</th>
            </tr>

            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.audio.jitter.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.jitter.tx.warning" [options]="{
                                          floor: alertsTriggerData?.trigger_limits.audio.jitter.tx.min,
                                          ceil: alertsTriggerData?.trigger_limits.audio.jitter.tx.max - 1,
                                          disabled: !this.alertsTriggerData?.triggers.audio.jitter.tx.enabled
                                        }" (valueChange)="jitterWarningChangeTx($event, 'audio')">
                </ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td></td>
              <td>
                <ng5-slider id="jitterAlertSlider" [(value)]="this.alertsTriggerData?.triggers.audio.jitter.tx.alert"
                  [options]="{
                              floor: alertsTriggerData?.trigger_limits.audio.jitter.tx.min + 1,
                              ceil: alertsTriggerData?.trigger_limits.audio.jitter.tx.max,
                              disabled: !this.alertsTriggerData?.triggers.audio.jitter.tx.enabled
                            }" (valueChange)="jitterAlertChangeTx($event, 'audio')">
                </ng5-slider>
              </td>
            </tr>

            <tr>
              <th nowrap>Packet Loss (Rx)</th>
            </tr>
            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.audio.packetsloss.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.packetsloss.rx.warning" [options]="{
                                    floor: alertsTriggerData?.trigger_limits.audio.packetsloss.rx.min,
                                    ceil: alertsTriggerData?.trigger_limits.audio.packetsloss.rx.max - 0.1,
                                    step: 0.01,
                                    disabled: !this.alertsTriggerData?.triggers.audio.packetsloss.rx.enabled
                                  }" (valueChange)="packetLossWarningChangeRx($event, 'audio')"></ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.packetsloss.rx.alert" [options]="{
                                      floor: alertsTriggerData?.trigger_limits.audio.packetsloss.rx.min + 0.1,
                                      ceil: alertsTriggerData?.trigger_limits.audio.packetsloss.rx.max,
                                      step: 0.01,
                                      disabled: !this.alertsTriggerData?.triggers.audio.packetsloss.rx.enabled
                                    }" (valueChange)="packetLossAlertChangeRx($event, 'audio')"></ng5-slider>
                </div>
              </td>
            </tr>

            <tr>
              <th nowrap>Packet Loss (Tx)</th>
            </tr>
            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.audio.packetsloss.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.packetsloss.tx.warning" [options]="{
                                          floor: alertsTriggerData?.trigger_limits.audio.packetsloss.tx.min,
                                          ceil: alertsTriggerData?.trigger_limits.audio.packetsloss.tx.max - 0.1,
                                          step: 0.01,
                                          disabled: !this.alertsTriggerData?.triggers.audio.packetsloss.tx.enabled
                                        }" (valueChange)="packetLossWarningChangeTx($event, 'audio')"></ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.packetsloss.tx.alert" [options]="{
                                            floor: alertsTriggerData?.trigger_limits.audio.packetsloss.tx.min + 0.1,
                                            ceil: alertsTriggerData?.trigger_limits.audio.packetsloss.tx.max,
                                            step: 0.01,
                                            disabled: !this.alertsTriggerData?.triggers.audio.packetsloss.tx.enabled
                                          }" (valueChange)="packetLossAlertChangeTx($event, 'audio')"></ng5-slider>
                </div>
              </td>
            </tr>

            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <th>Latency (Rx)</th>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.audio.latency.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.latency.rx.warning" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.audio.latency.rx.min,
                                        ceil: alertsTriggerData?.trigger_limits.audio.latency.rx.max - 1,
                                        disabled: !this.alertsTriggerData?.triggers.audio.latency.rx.enabled
                                      }" (valueChange)="latencyWarningChange($event, 'audio')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.latency.rx.alert" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.audio.latency.rx.min + 1,
                                        ceil: alertsTriggerData?.trigger_limits.audio.latency.rx.max,
                                        disabled: !this.alertsTriggerData?.triggers.audio.latency.rx.enabled
                                      }" (valueChange)="latencyAlertChange($event, 'audio')">
                  </ng5-slider>
                </div>
              </td>
            </tr>

            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <th>Latency (Tx)</th>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.audio.latency.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.latency.tx.warning" [options]="{
                                          floor: alertsTriggerData?.trigger_limits.audio.latency.tx.min,
                                          ceil: alertsTriggerData?.trigger_limits.audio.latency.tx.max - 1,
                                          disabled: !this.alertsTriggerData?.triggers.audio.latency.tx.enabled
                                        }" (valueChange)="latencyWarningChange($event, 'audio')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td>
              </td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.audio.latency.tx.alert" [options]="{
                                                    floor: alertsTriggerData?.trigger_limits.audio.latency.tx.min + 1,
                                                    ceil: alertsTriggerData?.trigger_limits.audio.latency.tx.max,
                                                    disabled: !this.alertsTriggerData?.triggers.audio.latency.tx.enabled
                                                  }" (valueChange)="latencyAlertChange($event, 'audio')">
                  </ng5-slider>
                </div>
              </td>
            </tr>
          </div>


          <div *ngIf="tab === 3">
            <tr>
              <th>Jitter (Rx)</th>
            </tr>

            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.content.jitter.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.jitter.rx.warning" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.content.jitter.rx.min,
                                        ceil: alertsTriggerData?.trigger_limits.content.jitter.rx.max - 1,
                                        disabled: !this.alertsTriggerData?.triggers.content.jitter.rx.enabled
                                      }" (valueChange)="jitterWarningChangeRx($event, 'content')">
                </ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td>
              </td>
              <td>
                <ng5-slider id="jitterAlertSlider" [(value)]="this.alertsTriggerData?.triggers.content.jitter.rx.alert"
                  [options]="{
                              floor: alertsTriggerData?.trigger_limits.content.jitter.rx.min + 1,
                              ceil: alertsTriggerData?.trigger_limits.content.jitter.rx.max,
                              disabled: !this.alertsTriggerData?.triggers.content.jitter.rx.enabled
                            }" (valueChange)="jitterAlertChangeRx($event, 'content')">
                </ng5-slider>
              </td>
            </tr>


            <tr>
              <th>Jitter (Tx)</th>
            </tr>

            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.content.jitter.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.jitter.tx.warning" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.content.jitter.tx.min,
                                        ceil: alertsTriggerData?.trigger_limits.content.jitter.tx.max - 1,
                                        disabled: !this.alertsTriggerData?.triggers.content.jitter.tx.enabled
                                      }" (valueChange)="jitterWarningChangeTx($event, 'content')">
                </ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td></td>
              <td>
                <ng5-slider id="jitterAlertSlider" [(value)]="this.alertsTriggerData?.triggers.content.jitter.tx.alert"
                  [options]="{
                              floor: alertsTriggerData?.trigger_limits.content.jitter.tx.min + 1,
                              ceil: alertsTriggerData?.trigger_limits.content.jitter.tx.max,
                              disabled: !this.alertsTriggerData?.triggers.content.jitter.tx.enabled
                            }" (valueChange)="jitterAlertChangeTx($event, 'content')">
                </ng5-slider>
              </td>
            </tr>

            <tr>
              <th nowrap>Packet Loss (Rx)</th>
            </tr>
            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox"
                    [(ngModel)]="this.alertsTriggerData?.triggers.content.packetsloss.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.packetsloss.rx.warning" [options]="{
                                              floor: alertsTriggerData?.trigger_limits.content.packetsloss.rx.min,
                                              ceil: alertsTriggerData?.trigger_limits.content.packetsloss.rx.max - 0.1,
                                              step: 0.01,
                                              disabled: !this.alertsTriggerData?.triggers.content.packetsloss.rx.enabled
                                            }"
                  (valueChange)="packetLossWarningChangeRx($event, 'content')"></ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td></td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.packetsloss.rx.alert" [options]="{
                                          floor: alertsTriggerData?.trigger_limits.content.packetsloss.rx.min + 0.1,
                                          ceil: alertsTriggerData?.trigger_limits.content.packetsloss.rx.max,
                                          step: 0.01,
                                          disabled: !this.alertsTriggerData?.triggers.content.packetsloss.rx.enabled
                                        }" (valueChange)="packetLossAlertChangeRx($event, 'content')"></ng5-slider>
                </div>
              </td>
            </tr>

            <tr>
              <th nowrap>Packet Loss (Tx)</th>
            </tr>
            <tr>
              <td>warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox"
                    [(ngModel)]="this.alertsTriggerData?.triggers.content.packetsloss.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.packetsloss.tx.warning" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.content.packetsloss.tx.min,
                                        ceil: alertsTriggerData?.trigger_limits.content.packetsloss.tx.max - 0.1,
                                        step: 0.01,
                                        disabled: !this.alertsTriggerData?.triggers.content.packetsloss.tx.enabled
                                      }" (valueChange)="packetLossWarningChangeTx($event, 'content')"></ng5-slider>
              </td>
            </tr>
            <tr>
              <td>alert</td>
              <td></td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.packetsloss.tx.alert" [options]="{
                                                      floor: alertsTriggerData?.trigger_limits.content.packetsloss.tx.min + 0.1,
                                                      ceil: alertsTriggerData?.trigger_limits.content.packetsloss.tx.max,
                                                      step: 0.01,
                                                      disabled: !this.alertsTriggerData?.triggers.content.packetsloss.tx.enabled
                                                    }"
                    (valueChange)="packetLossAlertChangeTx($event, 'content')"></ng5-slider>
                </div>
              </td>
            </tr>

            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <th>Latency (Rx)</th>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.content.latency.rx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.latency.rx.warning" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.content.latency.rx.min,
                                        ceil: alertsTriggerData?.trigger_limits.content.latency.rx.max - 1,
                                        disabled: !this.alertsTriggerData?.triggers.content.latency.rx.enabled
                                      }" (valueChange)="latencyWarningChange($event, 'content')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td></td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.latency.rx.alert" [options]="{
                                        floor: alertsTriggerData?.trigger_limits.content.latency.rx.min + 1,
                                        ceil: alertsTriggerData?.trigger_limits.content.latency.rx.max,
                                        disabled: !this.alertsTriggerData?.triggers.content.latency.rx.enabled
                                      }" (valueChange)="latencyAlertChange($event, 'content')">
                  </ng5-slider>
                </div>
              </td>
            </tr>

            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <th>Latency (Tx)</th>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.content.latency.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.latency.tx.warning" [options]="{
                                                    floor: alertsTriggerData?.trigger_limits.content.latency.tx.min,
                                                    ceil: alertsTriggerData?.trigger_limits.content.latency.tx.max - 1,
                                                    disabled: !this.alertsTriggerData?.triggers.content.latency.tx.enabled
                                                  }"
                    (valueChange)="latencyWarningChange($event, 'content')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td></td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.latency.tx.alert" [options]="{
                                          floor: alertsTriggerData?.trigger_limits.content.latency.tx.min + 1,
                                          ceil: alertsTriggerData?.trigger_limits.content.latency.tx.max,
                                          disabled: !this.alertsTriggerData?.triggers.content.latency.tx.enabled
                                        }" (valueChange)="latencyAlertChange($event, 'content')">
                  </ng5-slider>
                </div>
              </td>
            </tr>
          </div>

          <!-- TAB 4 -->


          <div *ngIf="tab === 4">
            <!-- <tr>
              <th style="opacity: 0;">Jitter (Rx)</th>
            </tr> -->

            <tr>
              <td style="width: 201px;">SIP</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.additional.sip.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width" style="opacity: 0;">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.jitter.rx.warning" [options]="{
                                      floor: alertsTriggerData?.trigger_limits.video.jitter.rx.min,
                                      ceil: alertsTriggerData?.trigger_limits.video.jitter.rx.max - 1,
                                      disabled: !this.alertsTriggerData?.triggers.video.jitter.rx.enabled
                                    }" (valueChange)="jitterWarningChangeRx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>

            <tr>
              <td style="width: 201px;">Gatekeeper</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.additional.gatekeeper.enabled" />
                  Enabled
                </label>
              </td>
              <!-- <td class="alerts__bar-width" style="opacity: 0;">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.jitter.rx.warning" [options]="{
                                                              floor: alertsTriggerData?.trigger_limits.video.jitter.rx.min,
                                                              ceil: alertsTriggerData?.trigger_limits.video.jitter.rx.max - 1,
                                                              disabled: !this.alertsTriggerData?.triggers.video.jitter.rx.enabled
                                                            }" (valueChange)="jitterWarningChangeRx($event, 'video')">
                </ng5-slider>
              </td> -->
            </tr>

            <tr>
              <td style="width: 201px;">SSL Self-Signed</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers?.additional?.SSL.selfSignedAlert.enabled" />
                  Enabled
                </label>
              </td>
            </tr>
            <tr>
              <td style="width: 201px;">SSL Expiry</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers?.additional?.SSL.expiryAlert.enabled" />
                  Enabled
                </label>
              </td>
              <td>
                <label>Days before Expiry</label>
                <input type="number" class="form-control" [(ngModel)]="this.alertsTriggerData?.triggers?.additional?.SSL.expiryAlert.daysBeforeExpiry" [disabled]="!this.alertsTriggerData?.triggers?.additional?.SSL.expiryAlert.enabled"/>
              </td>
            </tr>
          </div>
          <!-- TAB 4 -->


          <!-- TAB 5 -->

          <div *ngIf="tab === 5">
            <tr>
              <th style="opacity: 0;">Jitter (Rx)</th>
            </tr>

            <tr>
              <td style="width: 201px;">Camera</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.additional.camera.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width" style="opacity: 0;">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.jitter.rx.warning" [options]="{
                                                                                                  floor: alertsTriggerData?.trigger_limits.video.jitter.rx.min,
                                                                                                  ceil: alertsTriggerData?.trigger_limits.video.jitter.rx.max - 1,
                                                                                                  disabled: !this.alertsTriggerData?.triggers.video.jitter.rx.enabled
                                                                                                }"
                  (valueChange)="jitterWarningChangeRx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>

            <tr>
              <td style="width: 201px;">Mic</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.additional.mic.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width" style="opacity: 0;">
                <ng5-slider [(value)]="this.alertsTriggerData?.triggers.video.jitter.rx.warning" [options]="{
                                                                                                              floor: alertsTriggerData?.trigger_limits.video.jitter.rx.min,
                                                                                                              ceil: alertsTriggerData?.trigger_limits.video.jitter.rx.max - 1,
                                                                                                              disabled: !this.alertsTriggerData?.triggers.video.jitter.rx.enabled
                                                                                                            }"
                  (valueChange)="jitterWarningChangeRx($event, 'video')">
                </ng5-slider>
              </td>
            </tr>
          </div>
          <!-- TAB 5 -->
        </div>
      </div>

      <tr *ngIf="alertType == 'cloud'">
        <th>Jitter</th>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td style="padding-right: 80px;">warning</td>
        <td class="alerts__bar-width">
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.jitter.warning" [options]="{
                      floor: alertsTriggerData?.trigger_limits.jitter.min,
                      ceil: alertsTriggerData?.trigger_limits.jitter.max - 1
                    }" (valueChange)="cloudJitterWarningChange($event)"></ng5-slider>
          </div>
        </td>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td>alert</td>
        <td>
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.jitter.alert" [options]="{
                      floor: alertsTriggerData?.trigger_limits.jitter.min + 1,
                      ceil: alertsTriggerData?.trigger_limits.jitter.max
                    }" (valueChange)="cloudJitterAlertChange($event)"></ng5-slider>
          </div>
        </td>
      </tr>

      <tr *ngIf="alertType == 'cloud'">
        <th>Packet Loss</th>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td style="padding-right: 80px;">warning</td>
        <td class="alerts__bar-width">
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.packetsloss.warning" [options]="{
                            floor: alertsTriggerData?.trigger_limits.packetsloss.min,
                            ceil: alertsTriggerData?.trigger_limits.packetsloss.max - 0.1,
                            step: 0.1
                          }" (valueChange)="cloudPacketlossWarningChange($event)"></ng5-slider>
          </div>
        </td>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td>alert</td>
        <td>
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.packetsloss.alert" [options]="{
                            floor: alertsTriggerData?.trigger_limits.packetsloss.min + 0.1,
                            ceil: alertsTriggerData?.trigger_limits.packetsloss.max,
                            step: 0.1
                          }" (valueChange)="cloudPacketlossAlertChange($event)"></ng5-slider>
          </div>
        </td>
      </tr>

      <tr *ngIf="alertType == 'cloud'">
        <th>Frame Rate</th>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td style="padding-right: 80px;">warning</td>
        <td class="alerts__bar-width">
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.framerate.warning" [options]="{
                floor: alertsTriggerData?.trigger_limits.framerate.min + 1,
                ceil: alertsTriggerData?.trigger_limits.framerate.max
              }" (valueChange)="frameRateWarningChange($event)"></ng5-slider>
          </div>
        </td>
      </tr>
      <tr *ngIf="alertType == 'cloud'">
        <td>alert</td>
        <td>
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.framerate.alert" [options]="{
                floor: alertsTriggerData?.trigger_limits.framerate.min,
                ceil: alertsTriggerData?.trigger_limits.framerate.max - 1
              }" (valueChange)="frameRateAlertChange($event)"></ng5-slider>
          </div>
        </td>
      </tr>
      <tr *ngIf="alertType == 'video' && alertsTriggerData?.device_type =='cisco'">
        <th>
          Temperature
        </th>
      </tr>

      <!-- 
                  <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td style="padding-right: 80px;">warning</td>
              <td class="tdEnabled">
                <label>
                  <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.content.latency.tx.enabled" />
                  Enabled
                </label>
              </td>
              <td class="alerts__bar-width">
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.latency.tx.warning" [options]="{
                                                    floor: alertsTriggerData?.trigger_limits.content.latency.tx.min,
                                                    ceil: alertsTriggerData?.trigger_limits.content.latency.tx.max - 1,
                                                    disabled: !this.alertsTriggerData?.triggers.content.latency.tx.enabled
                                                  }"
                    (valueChange)="latencyWarningChange($event, 'content')"></ng5-slider>
                </div>
              </td>
            </tr>
            <tr *ngIf="alertType == 'video' || alertType === 'audio'">
              <td>alert</td>
              <td></td>
              <td>
                <div class="slidecontainer">
                  <ng5-slider [(value)]="this.alertsTriggerData?.triggers.content.latency.tx.alert" [options]="{
                                          floor: alertsTriggerData?.trigger_limits.content.latency.tx.min + 1,
                                          ceil: alertsTriggerData?.trigger_limits.content.latency.tx.max,
                                          disabled: !this.alertsTriggerData?.triggers.content.latency.tx.enabled
                                        }" (valueChange)="latencyAlertChange($event, 'content')">
                  </ng5-slider>
                </div>
              </td>
            </tr>
      -->

      <tr *ngIf="alertType == 'video' && alertsTriggerData?.device_type =='cisco'">
        <td style="padding-right: 80px;">
          warning
        </td>
        <td class="tdEnabled">
          <label>
            <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers.additional.temperature.enabled" />
            Enabled
          </label>
        </td>
        <td class="alerts__bar-width">
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.temperature.warning" [options]="{
                                floor: alertsTriggerData?.trigger_limits.temperature.min,
                                ceil: alertsTriggerData?.trigger_limits.temperature.max - 1
                              }" (valueChange)="temperatureWarningChange($event)">
            </ng5-slider>
          </div>

        </td>
      </tr>
      <tr *ngIf="alertType == 'video' && alertsTriggerData?.device_type =='cisco'">
        <td>
          alert
        </td>
        <td></td>
        <td>
          <div class="slidecontainer">
            <ng5-slider [(value)]="this.alertsTriggerData?.triggers.temperature.alert" [options]="{
                                  floor: alertsTriggerData?.trigger_limits.temperature.min + 1,
                                  ceil: alertsTriggerData?.trigger_limits.temperature.max
                                }" (valueChange)="temperatureAlertChange($event)"></ng5-slider>
          </div>

        </td>
      </tr>

<!-- adding SSL stuff for audio here for now. I don't know why other audio stuff is hidden/removed -->
      <tr *ngIf="alertType == 'audio'">
        <td style="width: 201px;">SSL Self-Signed</td>
        <td class="tdEnabled">
          <label>
            <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers?.SSL.selfSignedAlert.enabled" />
            Enabled
          </label>
        </td>
      </tr>
      <tr *ngIf="alertType == 'audio'">
        <td style="width: 201px;">SSL Expiry</td>
        <td class="tdEnabled">
          <label>
            <input type="checkbox" [(ngModel)]="this.alertsTriggerData?.triggers?.SSL.expiryAlert.enabled" />
            Enabled
          </label>
        </td>
        <td>
          <label>Days before Expiry</label>
          <input type="number" class="form-control" [(ngModel)]="this.alertsTriggerData?.triggers?.SSL.expiryAlert.daysBeforeExpiry" [disabled]="!this.alertsTriggerData?.triggers?.SSL.expiryAlert.enabled"/>
        </td>
      </tr>
      <div class="actionBar" align="right">
        <button type="button" class="btn btn-primary" (click)="updateAlertTriigers()">Save</button>
        &nbsp;
        <button type="button" class="btn btn-primary" (click)="resetAlertTriigers()">
          reset to default
        </button>
      </div>
    </tbody>
  </table>
</div>