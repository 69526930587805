import apiServicePathBuilder from './apiServicePathBuilder'

const visibilityone = {
	logo: '../assets/img/vs_img/dashboardLogo.png',
	title: 'VisibilityOne',
	enableInProduction: true,
	enableInDevelopment: true,
	showPoweredBy: false,
	twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
	showIoT: true,
	domotzIntegration: true,
	teleportivityEnabled: false,
	webhookUrl: null,
	links: {
		terms: 'https://www.visibility.one/terms-of-service',
		gdpr: 'https://www.visibility.one/gdpr',
		legal: 'https://www.visibility.one/legal',
		faq: 'https://www.visibility.one/faqs',
		collector: 'https://dev-db.visibility.one/api/v1/download/collector',
		macCollector: 'https://dev-db.visibility.one/api/v1/download/macCollector',
		plugin: 'https://dev-db.visibility.one/api/v1/download/plugin',
		tutorial: 'https://www.visibility.one/support/tutorial-10-step-setup',
		zoom: 'https://www.visibility.one/post/zoom-api-how-to-get-your-api-from-the-zoom-marketplace',
		teams: 'https://www.visibility.one/mst-desktop-monitoring',
	},

	slackClientId: '417904801508.625299211730',
	dynamicImage: {
		dashboardLogo: '../assets/img/flarekite/FLARE KITE LOGO WHT BACK.png',
		membershipSubscriptionLogo: '../assets/img/flarekite/FLARE KITE LOGO WHT BACK.png',
		viewTutorial: '../assets/img/vs_img/viewTutorial.png',
		downloadCollector: '../assets/img/vs_img/downloadCollector.png',
		downloadCollectorMac: '../assets/img/vs_img/downloadCollectorMac.png',
		createNewSite: '../assets/img/vs_img/createNewSite.png',
		createVirtualSite: '../assets/img/vs_img/createVirtualSite.png',
		pluginZoom: '../assets/img/vs_img/pluginZoom.png',
		pluginMac: '../assets/img/vs_img/pluginMac.png',
		desktopPlugin: '../assets/img/vs_img/desktopPlugin.png',
		bgImage: '/assets/img/vs_img/bgImage.jpg',
		bgImageNew: '/assets/img/vs_img/bgImageNew.jpg',
		bgImageNew2: '/assets/img/vs_img/bgImageNew2.jpg',
		notifImage: '../assets/img/vs_img/notifImage.png',
		loader: '../assets/img/vs_img/loader.gif',
		iconAddSite: '../assets/img/vs_img/iconAddSite.png',
		iconCollector: '../assets/img/vs_img/iconCollector.png',
		iconVirtual: '../assets/img/vs_img/iconVirtual.png',
		scrollCollector: '../assets/img/vs_img/scrollCollector.png',
		navImage: '../assets/img/vs_img/icon_menu.png',
		navImageHover: '../assets/img/vs_img/icon_menu_pressed.png',
		iotLoader: '../assets/img/vs_img/btn_loader.gif',
		iconPlugin: '../assets/img/vs_img/pluginIcon.png',
		desktopIcon: '../assets/img/vs_img/desktopIcon.png',
		mainMenuIotLogo: '../assets/img/ctx_img/domotzLogo.png',
	},
	dynamicText: {
		dTerms: 'VisibilityOne terms and conditions',
		vsOnePopup: 'This is a list of all your sites that have Visibility installed.',
		dCollector: 'Collector',
		dPlugin: 'Plugin',
		deskPlugin: 'Desktop Plugin',
	},
}

const cxdetect = {
	logo: '../assets/img/ctx_img/dashboardLogo.png',
	title: 'CXDetect',
	enableInProduction: true,
	enableInDevelopment: false,
	showPoweredBy: false,
	twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
	showIoT: true,
	domotzIntegration: true,
	teleportivityEnabled: true,
	webhookUrl: 'https://cxdetect-api.visibility.one/',
	links: {
		terms: 'https://www.cxunify.com/termsandconditions',
		gdpr: 'https://www.cxdetect.net/gdpr',
		legal: 'https://www.cxdetect.net/legal',
		faq: 'https://www.cxdetect.net/faqs',
		collector: 'https://service.cxdetect.net/api/v1/download/collector',
		macCollector: 'https://service.cxdetect.net/api/v1/download/macCollector',
		plugin: 'https://service.cxdetect.net/api/v1/download/plugin',
		tutorial: 'https://www.visibility.one/support/tutorial-10-step-setup',
		zoom: 'https://www.visibility.one/post/zoom-api-how-to-get-your-api-from-the-zoom-marketplace',
		teams: 'https://www.visibility.one/mst-desktop-monitoring',
	},
	slackClientId: '417904801508.625299211730',
	dynamicImage: {
		dashboardLogo: '../assets/img/ctx_img/dashboardLogo.png',
		membershipSubscriptionLogo: '../assets/img/ctx_img/membershipSubscriptionLogo.png',
		viewTutorial: '../assets/img/ctx_img/viewTutorial.png',
		downloadCollector: '../assets/img/ctx_img/downloadCollector.png',
		downloadCollectorMac: '../assets/img/ctx_img/downloadCollectorMac.png',
		createNewSite: '../assets/img/ctx_img/createNewSite.png',
		createVirtualSite: '../assets/img/ctx_img/createVirtualSite.png',
		bgImageNew: '/assets/img/vs_img/bgImageNew.jpg',
		bgImageNew2: '/assets/img/vs_img/bgImageNew2.jpg',
		pluginZoom: '../assets/img/ctx_img/pluginZoom.png',
		pluginMac: '../assets/img/ctx_img/pluginMac.png',
		desktopPlugin: '../assets/img/ctx_img/desktopPlugin.png',
		bgImage: '/assets/img/ctx_img/bgImage.jpg',
		notifImage: '../assets/img/ctx_img/notifImage.png',
		loader: '../assets/img/ctx_img/loader.gif',
		iconAddSite: '../assets/img/ctx_img/iconAddSite.png',
		iconCollector: '../assets/img/ctx_img/iconCollector.png',
		iconVirtual: '../assets/img/ctx_img/iconVirtual.png',
		scrollCollector: '../assets/img/ctx_img/scrollCollector.png',
		navImage: '../assets/img/ctx_img/icon_menu.png',
		navImageHover: '../assets/img/ctx_img/icon_menu_pressed.png',
		iotLoader: '../assets/img/ctx_img/btn_loader.gif',
		iconPlugin: '../assets/img/ctx_img/pluginIcon.png',
		desktopIcon: '../assets/img/vs_img/desktopIcon.png',
		mainMenuIotLogo: '../assets/img/ctx_img/domotzLogo.png',
	},
	dynamicText: {
		dTerms: 'Dashboard terms and conditions',
		vsOnePopup: 'This is a list of all your sites that have Dashboard installed.',
		dCollector: 'Bridge',
		dPlugin: 'Monitor',
		deskPlugin: 'Desktop Monitor',
	},
	SSO: {
		enabled: true,
		GAMBaseUri: 'https://app.cxunify.com/oauth/gam',
		clientId: 'IorwKqY0aCJLInBXUiLyBC1nEPzTWXYga6rW18wO',
		clientSecret: '593b285a620840e1bfa2344e5e1870',
		redirectURI: 'https://dashboard.cxdetect.net/login',
	},
}

const vytec = {
	logo: '../assets/img/vs_img/dashboardLogo.png',
	title: 'vCare',
	enableInProduction: true,
	enableInDevelopment: false,
	twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
	showPoweredBy: false,
	showIoT: true,
	domotzIntegration: false,
	teleportivityEnabled: true,
	webhookUrl: null,

	links: {
		terms: 'https://www.vcareanz.com/terms-and-conditions',
		gdpr: 'https://www.vcareanz.com/gdpr',
		legal: 'https://www.vcareanz.com/legal',
		faq: 'https://www.vcareanz.com/faqs',
		collector: 'https://service.vcareanz.com/api/v1/download/collector',
		macCollector: 'https://service.vcareanz.com/api/v1/download/macCollector',
		plugin: 'https://service.vcareanz.com/api/v1/download/plugin',
		tutorial: 'https://www.vcareanz.com/support/tutorial-10-step-setup',
		zoom: 'https://www.vcareanz.com/post/zoom-api-how-to-get-your-api-from-the-zoom-marketplace',
		teams: 'https://www.vcareanz.com/mst-desktop-monitoring',
	},
	slackClientId: '534855442912.3287438226727',
	dynamicImage: {
		dashboardLogo: '../assets/img/vytec_img/dashboardLogo.png',
		membershipSubscriptionLogo: '../assets/img/vytec_img/membershipSubscriptionLogo.png',
		viewTutorial: '../assets/img/vs_img/viewTutorial.png',
		downloadCollector: '../assets/img/vytec_img/downloadCollector.png',
		downloadCollectorMac: '../assets/img/vytec_img/downloadCollectorMac.png',
		createNewSite: '../assets/img/vytec_img/createNewSite.png',
		createVirtualSite: '../assets/img/vytec_img/createVirtualSite.png',
		pluginZoom: '../assets/img/vytec_img/pluginZoom.png',
		pluginMac: '../assets/img/vytec_img/pluginMac.png',
		desktopPlugin: '../assets/img/vytec_img/desktopPlugin.png',
		bgImageNew: '/assets/img/vytec_img/bgImageNew.jpg',
		bgImageNew2: '/assets/img/vytec_img/bgImageNew2.jpg',
		bgImage: '/assets/img/vs_img/bgImage.jpg',
		notifImage: '../assets/img/vytec_img/notifImage.png',
		loader: '../assets/img/vs_img/loader.gif',
		iconAddSite: '../assets/img/vs_img/iconAddSite.png',
		iconCollector: '../assets/img/vs_img/iconCollector.png',
		iconVirtual: '../assets/img/vs_img/iconVirtual.png',
		scrollCollector: '../assets/img/vs_img/scrollCollector.png',
		navImage: '../assets/img/vs_img/icon_menu.png',
		navImageHover: '../assets/img/vs_img/icon_menu_pressed.png',
		iotLoader: '../assets/img/vs_img/btn_loader.gif',
		iconPlugin: '../assets/img/vs_img/pluginIcon.png',
		desktopIcon: '../assets/img/vs_img/desktopIcon.png',
		mainMenuIotLogo: '../assets/img/ctx_img/domotzLogo.png',
	},
	dynamicText: {
		dTerms: 'vCare terms and conditions',
		vsOnePopup: 'This is a list of all your sites that have vCare installed.',
		dCollector: 'Collector',
		dPlugin: 'Plugin',
		deskPlugin: 'Desktop Plugin',
	},
}

const visionpoint = {
	logo: '../assets/img/visionpoint_img/dashboardLogo.png',
	title: 'Vision Point',
	enableInProduction: true,
	enableInDevelopment: false,
	twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
	showPoweredBy: false,
	showIoT: true,
	domotzIntegration: false,
	teleportivityEnabled: true,
	webhookUrl: null,
	links: {
		terms: 'https://visionpointllc.com/tc_unifyplus/',
		gdpr: 'https://unifyplus-service.visionpointllc.com/',
		legal: 'https://unifyplus-service.visionpointllc.com/',
		faq: 'https://unifyplus-service.visionpointllc.com/',
		collector: 'https://unifyplus-service.visionpointllc.com/api/v1/download/collector',
		macCollector: 'https://unifyplus-service.visionpointllc.com/api/v1/download/macCollector',
		plugin: 'https://unifyplus-service.visionpointllc.com/api/v1/download/plugin',
		tutorial: 'https://unifyplus-service.visionpointllc.com/support/tutorial-10-step-setup',
		zoom: 'https://unifyplus-service.visionpointllc.com/post/zoom-api-how-to-get-your-api-from-the-zoom-marketplace',
		teams: 'https://unifyplus-service.visionpointllc.com/mst-desktop-monitoring',
	},

	slackClientId: '417904801508.625299211730',
	dynamicImage: {
		dashboardLogo: '../assets/img/visionpoint_img/dashboardLogo.png',
		membershipSubscriptionLogo: '../assets/img/visionpoint_img/membershipSubscriptionLogo.png',
		viewTutorial: '../assets/img/visionpoint_img/viewTutorial.png',
		downloadCollector: '../assets/img/visionpoint_img/downloadCollector.png',
		downloadCollectorMac: '../assets/img/visionpoint_img/downloadCollectorMac.png',
		createNewSite: '../assets/img/visionpoint_img/createNewSite.png',
		createVirtualSite: '../assets/img/visionpoint_img/createVirtualSite.png',
		pluginZoom: '../assets/img/visionpoint_img/pluginZoom.png',
		pluginMac: '../assets/img/visionpoint_img/pluginMac.png',
		desktopPlugin: '../assets/img/visionpoint_img/desktopPlugin.png',
		bgImage: '/assets/img/vs_img/bgImage.jpg',
		bgImageNew: '/assets/img/visionpoint_img/bgImageNew.jpg',
		bgImageNew2: '/assets/img/visionpoint_img/bgImageNew2.jpg',
		notifImage: '../assets/img/visionpoint_img/notifImage.png',
		loader: '../assets/img/visionpoint_img/loader.gif',
		iconAddSite: '../assets/img/vs_img/iconAddSite.png',
		iconCollector: '../assets/img/vs_img/iconCollector.png',
		iconVirtual: '../assets/img/vs_img/iconVirtual.png',
		scrollCollector: '../assets/img/vs_img/scrollCollector.png',
		navImage: '../assets/img/vs_img/icon_menu.png',
		navImageHover: '../assets/img/vs_img/icon_menu_pressed.png',
		iotLoader: '../assets/img/vs_img/btn_loader.gif',
		iconPlugin: '../assets/img/vs_img/pluginIcon.png',
		desktopIcon: '../assets/img/vs_img/desktopIcon.png',
		mainMenuIotLogo: '../assets/img/ctx_img/domotzLogo.png',
	},
	dynamicText: {
		dTerms: 'Vision Point terms and conditions',
		vsOnePopup: 'This is a list of all your sites that have Vision Point installed.',
		dCollector: 'Collector',
		dPlugin: 'Plugin',
		deskPlugin: 'Desktop Plugin',
	},
}

const videlio = {
	logo: '../assets/img/visionpoint_img/dashboardLogo.png',
	title: 'Videlio',
	enableInProduction: true,
	enableInDevelopment: false,
	twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
	showPoweredBy: false,
	showIoT: true,
	domotzIntegration: false,
	teleportivityEnabled: true,
	webhookUrl: null,
	links: {
		terms: 'https://www.klood.io/conditions-generales-de-vente/',
		gdpr: 'https://www.videlio.com/mentions-legales/',
		legal: 'https://www.klood.io/en/legal-notices/',
		faq: 'http://support-client@videlio.com',
		collector: 'https://service.videlio.com/api/v1/download/collector',
		macCollector: 'https://service.videlio.com/api/v1/download/macCollector',
		plugin: 'https://service.videlio.comapi/v1/download/plugin',
		tutorial: 'https://service.videlio.com/support/tutorial-10-step-setup',
		zoom: 'https://service.videlio.compo/st/zoom-api-how-to-get-your-api-from-the-zoom-marketplace',
		teams: 'https://service.videlio.com/mst-desktop-monitoring',
	},

	slackClientId: '417904801508.625299211730',
	dynamicImage: {
		dashboardLogo: '../assets/img/videlio_img/dashboardLogo.png',
		membershipSubscriptionLogo: '../assets/img/videlio_img/membershipSubscriptionLogo.png',
		viewTutorial: '../assets/img/videlio_img/viewTutorial.png',
		downloadCollector: '../assets/img/videlio_img/downloadCollector.png',
		downloadCollectorMac: '../assets/img/videlio_img/downloadCollectorMac.png',
		createNewSite: '../assets/img/videlio_img/createNewSite.png',
		createVirtualSite: '../assets/img/videlio_img/createVirtualSite.png',
		pluginZoom: '../assets/img/videlio_img/pluginZoom.png',
		pluginMac: '../assets/img/videlio_img/pluginMac.png',
		desktopPlugin: '../assets/img/videlio_img/desktopPlugin.png',
		bgImage: '/assets/img/vs_img/bgImage.jpg',
		bgImageNew: '/assets/img/videlio_img/bgImageNew.jpg',
		bgImageNew2: '/assets/img/videlio_img/bgImageNew2.jpg',
		notifImage: '../assets/img/videlio_img/notifImage.png',
		loader: '../assets/img/videlio_img/loader.gif',
		iconAddSite: '../assets/img/videlio_img/iconAddSite.png',
		iconCollector: '../assets/img/vs_img/iconCollector.png',
		iconVirtual: '../assets/img/videlio_img/iconVirtual.png',
		scrollCollector: '../assets/img/vs_img/scrollCollector.png',
		navImage: '../assets/img/vs_img/icon_menu.png',
		navImageHover: '../assets/img/vs_img/icon_menu_pressed.png',
		iotLoader: '../assets/img/vs_img/btn_loader.gif',
		iconPlugin: '../assets/img/vs_img/pluginIcon.png',
		desktopIcon: '../assets/img/vs_img/desktopIcon.png',
		mainMenuIotLogo: '../assets/img/ctx_img/domotzLogo.png',
	},
	dynamicText: {
		dTerms: 'Vision Point terms and conditions',
		vsOnePopup: 'This is a list of all your sites that have Vision Point installed.',
		dCollector: 'Collector',
		dPlugin: 'Plugin',
		deskPlugin: 'Desktop Plugin',
	},
}

const dekom = {
	logo: '../assets/img/vs_img/dashboardLogo.png',
	title: 'DEKOM',
	enableInProduction: true,
	enableInDevelopment: false,
	showPoweredBy: false,
	twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
	showIoT: true,
	domotzIntegration: false,
	teleportivityEnabled: false,
	webhookUrl: null,
	links: {
		terms: 'https://vchelpdesk.com/terms',
		gdpr: 'https://vchelpdesk.com/gdpr',
		legal: 'https://vchelpdesk.com/privacy',
		faq: 'https://vchelpdesk.com/support',
		collector: 'https://service.vchelpdesk.com/api/v1/download/collector',
		macCollector: 'https://service.vchelpdesk.com/api/v1/download/macCollector',
		plugin: 'https://service.vchelpdesk.com/api/v1/download/plugin',
		tutorial: 'https://www.visibility.one/support/tutorial-10-step-setup',
		zoom: 'https://www.visibility.one/post/zoom-api-how-to-get-your-api-from-the-zoom-marketplace',
		teams: 'https://www.visibility.one/mst-desktop-monitoring',
	},

	slackClientId: '417904801508.625299211730',
	dynamicImage: {
		dashboardLogo: '../assets/img/dekom_img/dashboardLogo.png',
		membershipSubscriptionLogo: '../assets/img/dekom_img/membershipSubscriptionLogo.png',
		viewTutorial: '../assets/img/dekom_img/dekom_img.png',
		downloadCollector: '../assets/img/dekom_img/downloadCollector.png',
		downloadCollectorMac: '../assets/img/dekom_img/downloadCollectorMac.png',
		createNewSite: '../assets/img/dekom_img/createNewSite.png',
		createVirtualSite: '../assets/img/dekom_img/createVirtualSite.png',
		pluginZoom: '../assets/img/dekom_img/pluginZoom.png',
		pluginMac: '../assets/img/dekom_img/pluginMac.png',
		desktopPlugin: '../assets/img/dekom_img/desktopPlugin.png',
		bgImage: '/assets/img/dekom_img/bgImage.jpg',
		bgImageNew: '/assets/img/dekom_img/bgImageNew.jpg',
		bgImageNew2: '/assets/img/dekom_img/bgImageNew2.jpg',
		notifImage: '../assets/img/dekom_img/notifImage.png',
		loader: '../assets/img/dekom_img/loader.gif',
		iconAddSite: '../assets/img/dekom_img/iconAddSite.png',
		iconCollector: '../assets/img/vs_img/iconCollector.png',
		iconVirtual: '../assets/img/dekom_img/iconVirtual.png',
		scrollCollector: '../assets/img/vs_img/scrollCollector.png',
		navImage: '../assets/img/vs_img/icon_menu.png',
		navImageHover: '../assets/img/vs_img/icon_menu_pressed.png',
		iotLoader: '../assets/img/vs_img/btn_loader.gif',
		iconPlugin: '../assets/img/vs_img/pluginIcon.png',
		desktopIcon: '../assets/img/vs_img/desktopIcon.png',
		mainMenuIotLogo: '../assets/img/ctx_img/domotzLogo.png',
	},
	dynamicText: {
		dTerms: 'DEKOM terms and conditions',
		vsOnePopup: 'This is a list of all your sites that have DEKOM installed.',
		dCollector: 'Collector',
		dPlugin: 'Plugin',
		deskPlugin: 'Desktop Plugin',
	},
}

export const environment = {
	production: false,
	// serverUrl: 'http://127.0.0.1:8080', // localhost - for testing
	// serverUrl: 'https://service.vchelpdesk.com', // dekom
	serverUrl: 'https://dev-db.visibility.one', // dev
	// serverUrl: 'https://fe-service.cxdetect.net/', // cxdetect
	// serverUrl: 'https://api-db.visibilitygov.one',
	// serverUrl: 'https://fe-krypton.visibility.one', // prod
	// serverUrl: 'https://service.vcareanz.com', // vytec
	// serverUrl: 'https://unifyplus-service.visionpointllc.com', //visionpoint
	// serverUrl: 'https://service.videlio.com', //videlio
	apiServicePathBuilder: apiServicePathBuilder({
		// apiUrl: 'http://localhost:3080',
		// // apiUrl: ' https://api-db.visibilitygov.one',
		apiUrl: 'https://dev-api.visibility.one', // dev
		// apiUrl: 'https://api.vchelpdesk.com', // dekom
		// apiUrl: 'https://api.visibility.one', // prod
		// apiUrl: 'https://cxdetect-api.visibility.one', // cxdetect
		// apiUrl: 'https://api.vcareanz.com', // vytec
		// apiUrl: 'https://unifyplus-api.visionpointllc.com', //visionpoint
		// apiUrl: 'https://api.videlio.com', //videlio
	}),
	// config: cxdetect,
	// config: vytec,
	// config: videlio,
	// config: visionpoint,
	config: visibilityone,
	// config: dekom,
	// config: gov,
	// config: ucc,
	dev_server: false,
	show_build_version: true,
}

//172.30.86.77

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
