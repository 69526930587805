import { ElementRef, Injectable } from "@angular/core";
// Import Guacamole manually
import Guacamole from 'guacamole-common-js';

@Injectable({
  providedIn: "root",
})
export class GuacamoleService {

  private tunnel: any;
  private client: any;
  public isFailed:boolean = false;

  // Connects to Guacamole with an authToken
  connect(Token: string, guac_id: string) {
    // WebSocket connection URL (use string URL, not WebSocket object)
    // Extract the connection ID from your URL
    const url =  `wss://guacamole.linkinstantly.io/guacamole/websocket-tunnel?token=${Token}&GUAC_DATA_SOURCE=mysql&GUAC_ID=${guac_id}&GUAC_TYPE=c&GUAC_WIDTH=1920&GUAC_HEIGHT=868&GUAC_DPI=120&GUAC_TIMEZONE=Asia%2FBaghdad&GUAC_AUDIO=audio%2FL8&GUAC_AUDIO=audio%2FL16&GUAC_IMAGE=image%2Fjpeg&GUAC_IMAGE=image%2Fpng&GUAC_IMAGE=image%2Fwebp`
    this.tunnel = new Guacamole.WebSocketTunnel(`${url}`);

    // Create the Guacamole client
    this.client = new Guacamole.Client(this.tunnel);

    this.tunnel.onstatechange = (state: number) => {
      console.log('Tunnel State Changed:', state);
      if (state === Guacamole.Tunnel.State.CLOSED) {
        console.error('WebSocket Tunnel closed unexpectedly.');
      }
    };

    this.tunnel.onerror = (error: any) => {
      console.error('WebSocket Tunnel Error:', error);
    };

    this.client.onerror = (error: any) => {
      this.isFailed = true
      console.error('Guacamole Client Error:', error);
    };


    // Connection parameters
    // const params = `token=${Token}&hostname=${'localhost'}&port=${22}&protocol=${'ssh'}`;
    
    // Connect to Guacamole
    this.client.connect();

    // Add the display to the document
    const display:any = document.querySelector('#guac-container');
    if (display) {
      display.innerHTML = ''; // Clear any previous session
      const element = this.client.getDisplay().getElement()
      display.appendChild(element);

      // Make sure display is focused for keyboard input
      display.tabIndex = 0;
      display.focus();
      this.addEventListeners(display,element);

      // // Ensure focus on click
      // display.addEventListener('click', () => display.focus());
    }

    window.addEventListener('unload', () => this.client.disconnect());
}
addEventListeners(display,element): void {
  // Mouse events
  const sink = new Guacamole.InputSink();
  display.appendChild(sink.getElement());
    sink.focus();

    const keyboard = new Guacamole.Keyboard(sink.getElement());

    keyboard.onkeydown = (keysym) => {
      this.client.sendKeyEvent(1, keysym);
    };
    keyboard.onkeyup = (keysym) => {
      this.client.sendKeyEvent(0, keysym);
    };
    const mouse = new Guacamole.Mouse(element);

    mouse.onmousedown = mouse.onmouseup = function(mouseState) {
      sink.focus();
    };

    mouse.onmousemove = function(mouseState) {
      sink.focus();
      mouseState.x = mouseState.x / display.getScale();
      mouseState.y = mouseState.y / display.getScale();
    };
    const touch = new Guacamole.Mouse.Touchscreen(element);

    touch.onmousedown = touch.onmousemove = touch.onmouseup = function(state) {
    };
    window.addEventListener("resize", this.handleResize);
}
 handleResize = () => {
  const width = document.body.offsetWidth
  const height = document.body.offsetHeight
  this.client.sendSize(width, height);
}

  // Disconnect the SSH session
  disconnect() {
    if (this.client) {
      this.client.disconnect();
    }
  }
}
