<!-- <p>zoom-rooms-link-plugin works!</p> -->

<div class="link-desktop">
    <div class="link-desktop__header">
        <div>
            {{ getModalTitle() }}
        </div>
        <div (click)="closeDialog()" class="link-desktop__header-close-button">
            <img src="./assets/img/iot/delete-btn.png" alt="" />
        </div>
    </div>

    <div *ngIf="!showLinkClientUser && !pluginEnabled && pluginId === null" class="link-desktop__body">
        <div class="link-desktop__search">
            <div class="link-desktop__icon">
                <i class="fa fa-search"></i>
            </div>
            <input #searchKey type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchQuery"
                (input)="filterData()" />
        </div>
        <div id="scroll-bar" style="overflow: auto; overflow-x: hidden; height: 280px;">
            <table class="table">
                <thead>
                    <tr class="black">
                        <th scope="col">HOST NAME</th>
                        <th scope="col">IP ADDRESS</th>
                        <th scope="col">MAC ADDRESS</th>
                        <th scope="col" style="width: 50px;">SELECT {{dPlugin.toUpperCase()}}</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr scope="row"
                        *ngFor="let item of filteredData | mainMenuFilter: searchKey.value:'linkDialogHost'"> -->
                    <tr scope="row" *ngFor="let item of filteredData">
                        <td>
                            {{getHostName(item.host_info)}}
                        </td>
                        <td>
                            {{item.ip_address === null ? '--' : item.ip_address}}
                        </td>
                        <td>
                            {{item.mac_address === null ? '--' : item.mac_address}}
                        </td>
                        <td>
                            <div (click)="openLinkClient(item)" class="link-desktop__body-add-button">
                                <img src="./assets/img/vs_img/dialog_add_button.png" alt="" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="showLinkClientUser && !pluginEnabled" class="link-desktop__body link-desktop__body-2">
        <div class="link-desktop__image">
            <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
            <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
        </div>
        <div class="link-desktop__text-button-container">
            <div *ngIf="!showPluginMessage" class="">
                Enter Zoom Room Password :
                <input [(ngModel)]="zoomRoomPassword" style="width: 215px;">
            </div>
            <div *ngIf="showPluginMessage" class="">
                {{pluginMessage}}
            </div>
            <div *ngIf="!showPluginMessage" class="link-desktop__button-container">
                <div class="link-desktop__button-item link-desktop__button-item--yes" (click)="linkPlugin()">
                    Yes, Link Them!
                </div>
                <div class="link-desktop__button-item link-desktop__button-item--cancel" (click)="closeLinkClient()">
                    X Cancel
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="pluginEnabled && pluginId !== null" class="link-desktop__body link-desktop__body-2">
        <div class="link-desktop__image">
            <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
            <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
        </div>
        <div class="link-desktop__text-button-container">
            <div *ngIf="!showPluginMessage" class="">
                Un-Link <strong>{{ clientToLink }}</strong> to <br />
                Host: <strong>{{ hostName }}</strong> ?
            </div>
            <div *ngIf="showPluginMessage" class="">
                {{pluginMessage}}
            </div>
            <div *ngIf="!showPluginMessage" class="link-desktop__button-container">
                <div class="link-desktop__button-item link-desktop__button-item--yes-unlink" (click)="unlinkPlugin()">
                    Yes, Un-Link Them!
                </div>
                <div class="link-desktop__button-item link-desktop__button-item--cancel-unlink" (click)="closeDialog()">
                    X Cancel
                </div>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="showPluginMessage" class="link-desktop__body link-desktop__body-2">
        <div class="link-desktop__image">
            <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
            <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
        </div>
        <div class="link-desktop__text-button-container">
            <div class="">
                {{pluginMessage}}
            </div>
        </div>
    </div> -->
</div>