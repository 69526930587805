<div class="cloud-room-info-ctr">
  <div class="first-child">
    <div class="zoom-image">
      <img src="../../assets/img/zoom_logo.png" alt="video-device-brand" />
    </div>
    <div class="upper-card-text">
      <p>
        Site:
        {{
        cloudRoomInfo?.site_name !== null ? cloudRoomInfo?.site_name : "--"
        }}
      </p>
      <p matTooltip="{{ cloudRoomInfo?.room_name }}">
        Room Name:
        {{
        cloudRoomInfo?.room_name !== null ? cloudRoomInfo?.room_name : "--"
        }}
      </p>
      <p>
        Booking Email ID:
        {{ cloudRoomInfo?.email !== "null" ? cloudRoomInfo?.email : "--" }}
      </p>
      <p>IP address: {{ getCloudRoomIP() }} <app-table-cog-icon *ngIf="ipAddress&&cloudRoomInfo?.is_remote_access === 1" (click)="openRemote()"></app-table-cog-icon></p>
      <p>Serial no: {{ getCloudRoomSerialNumber() }}</p>
    </div>

    <div class="timer-and-button-container" *ngIf="hasAccess">
      <app-info-uptime [uptimeDays]="upTimeDays" [uptimeHours]="upTimeHours" [uptimeMins]="upTimeMins" label="PC"
        *ngIf="isShowUptime"></app-info-uptime>
      <div class="cloud-room-info-button">
        <app-btn-info label="{{ cloudRoomInfo?.pause == 1 ? 'unpause' : 'pause' }}"
          [faName]="cloudRoomInfo?.pause == 1 ? faPauseIcon : faPlayIcon" (click)="setRoomPause(cloudRoomInfo?.pause)">
        </app-btn-info>
        <!-- <app-btn-info label="unpause" [faName]="faIcon" (click)="setUnpause()" *ngIf="cloudRoomInfo?.pause == 1">
        </app-btn-info> -->
        <app-btn-info label="reboot" *ngIf="cloudRoomInfo?.zoom_plugin_enabled == 1" (click)="
            ds.sendPopupType(45, {
              alertsId: cloudRoomInfo?.zoom_user_id,
              alertType: 'cloud',
              data: cloudRoomInfo.plugin_id
            })
          " [faName]="faIcon">
        </app-btn-info>
        <app-btn-info label="admin" (click)="
            ds.sendPopupType(32, {
              alertsId: cloudRoomInfo?.zoom_user_id,
              alertType: 'cloud',
              deviceId: cloudRoomInfo.zoom_user_id,
              data: cloudRoomInfo
            })
          " [faName]="faIcon">
        </app-btn-info>
      </div>
    </div>

    <div *ngIf="cloudRoomInfo" class="status-btn">
      <span *ngIf="checkIfOnline(cloudRoomInfo)" class="btn btn-block mt-2 online-btn disable">Online</span>
      <span *ngIf="checkOffline(cloudRoomInfo)" class="btn btn-block mt-2 offline-btn online-btn disable">Offline</span>
      <span *ngIf="checkPause(cloudRoomInfo)"
        class="btn btn-block mt-2 online-btn disable bg-primary border-0">Paused</span>

      <span *ngIf="
          cloudRoomInfo?.is_direct_access === 1 &&
          cloudRoomInfo?.direct_access_info?.isEnable === 1
        " class="btn btn-block mt-2 online-btn disable">Teleportivity
      </span>
    </div>

    <hr *ngIf="shouldShowIot" />
    <app-iot *ngIf="shouldShowIot" [iotData]="iotData" [iotEvent]="cloudEvent"></app-iot>
    <hr />
    <h5>Network Utilization</h5>

    <div class="row" class="network-utilization">
      <app-chart-cloud-mbps></app-chart-cloud-mbps>
    </div>
  </div>

  <div class="console-outer">
    <app-teams-room-console [isDisable]="!isZoomPluginEnabled || deskPluginStatus !== 1"
      [pluginId]="cloudRoomInfo?.plugin_id" [desktopPluginMessage]="desktopPluginMessage" [originationIp]="ipAddress">
    </app-teams-room-console>
  </div>

  <div class="add-info">
    <h5>Additional</h5>

    <div class="add-first-child">
      <p *ngIf="shouldShowDisplayAndTableHub">
        <strong>Display Hub: </strong> {{ tableHub }}
      </p>
      <p *ngIf="shouldShowDisplayAndTableHub">
        <strong>Table Hub: </strong> {{ displayHub }}
      </p>
      <p>
        <strong>Network Connection Type: </strong>
        {{ connectionType ? connectionType : "--" }}
      </p>
      <p>
        <strong>Network Type: </strong> {{ networkType ? networkType : "--" }}
      </p>
    </div>
  </div>
</div>