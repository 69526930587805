import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pexip-live-call-status',
  templateUrl: './pexip-live-call-status.component.html',
  styleUrls: ['./pexip-live-call-status.component.scss']
})
export class PexipLiveCallStatusComponent implements OnInit {
  icon: string = 'assets/img/icon_call_available.png'
  constructor() { }

  ngOnInit(): void {
  }

}
