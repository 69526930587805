import { phone } from 'phone'

import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { AuthV2Service } from 'src/app/services/auth-v2.service'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: any;
  password: any;

  message: any = { text: 'Email address not found', flag: false };
  message2: any = { text: 'Thankyou for register', flag: false, type: 1 };
  setTImer: any;
  loginEnv: any;
  isLoader: boolean = false;

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private AuthV2: AuthV2Service
  ) {
    this.checkRouterUrl();
  }

  ngOnInit() {
    this.setData();
  }

  setData = () => {
    if (
      environment.config.title === 'VisibilityOne' ||
      environment.config.title === 'vCare' ||
      environment.config.title === 'Vision Point' ||
      environment.config.title === 'Videlio' ||
      environment.config.title === 'DEKOM'
    ) {
      this.loginEnv = true;
    } else {
      this.loginEnv = false;
    }
  };

  checkRouterUrl = () => {
    this.route.queryParams.subscribe((params) => {
      const message = params.message
      const flag = params.flag
      const type = params.type
      if (flag) {
        this.setMessageForLogin({ message }, false, type)
      }
    });
  };

  loginUser = async (username, password) => {
    // this.Auth.getUserDetails(username, password).subscribe((data: any) => {
		// 	// moved the logic here to 'onSuccessfullLogin' method of Auth service so that it can be reused in login with SSO
		// }, error => {
    //   this.setMessageForLogin(error, true)
    // });
    this.isLoader = true
    this.AuthV2.checkUserser({email:username})
    .pipe(finalize(()=> this.isLoader = false))
    .subscribe((resp: any) => {
      if(resp.response[0] === undefined){
       this.loginToOauthV2(resp.response);
      }
      else {
        this.setMessageForLogin(resp?.response[0], true)
      }
      	// moved the logic here to 'onSuccessfullLogin' method of Auth service so that it can be reused in login with SSO
      }, error => {
        this.setMessageForLogin(error, true)
      });
  };

  loginToOauthV2(data){
    if(data.is_oauth){
      this.AuthV2.login();
    }
    else {
      this.router.navigateByUrl('/changePassword/'+data.code)
    }
  }

  loginEmitEvent = ({ email, password }) => {
    this.loginUser(email, password);
  };

  setMessageForLogin = ({ message }, flag, type = 1) => {
    if (message) {
      if (flag) {
        this.message = {
          text: message,
          flag: true,
        };
      } else {
        this.message2 = {
          flag: true,
          type,
        };
      }
    }
  };
}