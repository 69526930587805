import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';
import { PrintDataService } from 'src/app/services/print-data.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IncidentMessagesComponent } from '../dialog/incident-messages/incident-messages.component'
import { MatDialog } from '@angular/material/dialog'
import { IncidentType } from 'src/app/services/incident-messages.service'

@Component({
  selector: 'app-zoom-incident',
  templateUrl: './zoom-incident.component.html',
  styleUrls: ['./zoom-incident.component.scss'],
})
export class ZoomIncidentComponent implements OnInit, OnChanges {
  @Input() popData: any;
  @Input() popupState: any;
  isShowTableData: boolean = true;
  shouldShowLoaderBtn: boolean = false;
  filterListner: Subscription;
  deskPluginText: String = environment.config.dynamicText.deskPlugin;
  pluginText: String = environment.config.dynamicText.dPlugin;

  incidentsData = [];
  constructor(
    private Auth: AuthService,
    private printData: PrintDataService,
    private refreshApiService: RefreshApiService,
    private dialog: MatDialog
  ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      //this might destroy the code but this is proper implentation
      // let isFirstLoad = true;
      // if (m && !isFirstLoad) {
      // console.log('fresh from zoom incident')
      this.getData();
      // }
      // isFirstLoad = false;
    });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue === 'open') {
      this.getData();
      this.incidentsData = [];
    }
  }

  getData = () => {
    this.shouldShowLoaderBtn = true;
    this.Auth.getZoomIncidentList(this.popData.deviceId).subscribe((data) => {
      if (data.length < 1) {
        this.isShowTableData = false;
      }

      this.incidentsData = data.map((i) => {
        const notification_info = JSON.parse(i.notification_info);
        const issue = notification_info.message.split(':');
        const eventsIssues = { ...JSON.parse(i.events) };
        const eventDetails = [];


        // console.log('eventIssues: ', eventsIssues)
        
        for (const event in eventsIssues) {
          eventDetails.push({
            trigger: this.translateTrigger(event,eventsIssues),
            pulls: eventsIssues[event].pulls,
            // last_pull: this.printTime(eventsIssues[event].last_pull),
            // end_time: this.printTime(eventsIssues[event].end_date),
            // start_date: this.printTime(eventsIssues[event].start_date),
            last_pull: eventsIssues[event].last_pull,
            end_time: eventsIssues[event].end_date,
            start_date: eventsIssues[event].start_date,
          });
        }
        const incident = {
          incident_id: i.zoom_incident_id,
          actioned: i.actioned,
          // start_time: this.printTime(i.start_time),
          // end_time: this.printTime(i.end_time),
          start_time: i.start_time,
          end_time: i.end_time,
          summary: issue[issue.length - 1].trim(),
          event_details: eventDetails,
        };
        return incident;
      });
      this.shouldShowLoaderBtn = false;
    });
  };

  translateTrigger(trigger,eventsIssues) {
    switch (trigger) {
      case 'video_disconnection':
        return 'Camera(s) disconnected.';
      case 'audio_mic_disconnection':
        return 'Microphone(s) disconnected.';
      case 'audio_speaker_disconnection':
        return 'Speaker(s) disconnected.';
      case 'iot_component_offline':
        return 'Iot not resonding.';
      case 'iot_webservice_offline':
        return `Iot webservice not responding.`;
      case 'latency':
        return 'Exceeded Latency alert threshold.';
      case 'jitter':
        return 'Exceeded Jitter alert threshold.';
      case 'temperature':
        return 'Exceeded Temperature alert threshold.';
      case 'packetsloss':
        return 'Exceeded Packetloss alert threshold.';
      case 'logitech_error':
          return eventsIssues[trigger].value;
      case 'plugin_offline':
        return this.popData.is_room
          ? `${this.pluginText} offline.`
          : `${this.deskPluginText} offline.`;
      case 'room_offline':
        return 'Room offline.';
      case 'zaapi':
        return 'Zaapi issue.';
      default:
        return '';
    }
  }

  // printTime(t) {
  //   if (t == null || !t) {
  //     return '--';
  //   } else {
  //     return moment(t)
  //       .utc()
  //       .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       .format('MM-DD-YYYY hh:mma');
  //   }
  // }

  printIncidentsToPdf = () => {
    this.printData.printToPdf('incident-history-list-container', 'Incident Report');
  };

  onShowMessages = (incidentId, idx) => {
    const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
        incidentId,
        incidentType: IncidentType.CLOUD
				// showButton: true,
			},
			// height: '80%',
      // width: '60%',
			// minHeight: 'calc(100vh - 90px)',
      width: '80%',
			height: 'auto',
		})

    dialogRef.afterClosed().subscribe(isActioned => {
      if (isActioned) {
        this.incidentsData[idx].actioned = 1
      }
    })
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }
}
