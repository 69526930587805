<div class="pexip-service" *ngIf="!isLoading">
	<div class="pexip-service__left-column">
		<div class="pexip-service__checkbox">
			<div class="checkbox_label">Monitoring Enabled</div>
			<div class="checkbox_switch">
				<label class="switch" [ngClass]="{ disable: !pexipAccount.active }">
					<input type="checkbox" [(checked)]="pexipAccount.active" (click)="setEnabled()" />
					<span class="slider round"></span>
				</label>
			</div>
			<!-- <div class="checkbox_label">Alert Notification Enabled</div>
			<div class="checkbox_switch">
				<label class="switch" [ngClass]="{ disable: !enableCheckbox }">
					<input type="checkbox" [(checked)]="notification" (click)="setNotification()" />
					<span class="slider round"></span>
				</label>
			</div> -->
		</div>
	</div>
	<div class="pexip-service__right-column">
		<!-- <div class="pexip-service__success-msg {{ msgColor }}">
      <h3 *ngIf="shouldShowMsg">
        {{ msg }}
      </h3>
    </div> -->
		<!-- <app-pexip-enable-service (boolEvent)="emitterEvent($event)" [pexipAccountInfo]="pexipInfoResult">
    </app-pexip-enable-service> -->
		<app-pexip-enable-service [pexipAccount]="pexipAccount"></app-pexip-enable-service>
	</div>
</div>
