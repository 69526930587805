<div class="ssl-dialog">
	<div class="ssl-dialog__header">
		SHA256 Public Key and Certificate
		<div class="ssl-dialog__close" (click)="closeDialog()">X</div>
	</div>

	<div class="ssl-dialog__body">
		<div class="ssl-dialog__image">
			<img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
		</div>

		<div class="ssl-dialog__text-button-container" *ngIf="sslCertificate">
			<div class="sm-font text-break">
				<p><strong>Public Key:</strong> {{ sslCertificate?.publicKeyHash }}</p>
				<p><strong>Certificate:</strong> {{ sslCertificate?.certificateHash }}</p>
			</div>
		</div>
	</div>
</div>
