import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopupTypeService } from '../../../services/popupType.service';
import { GetAudioService } from '../../../services/get-audio.service';
import { PopupSendDataService } from '../../../services/popup-send-data.service';
import { environment } from './../../../../environments/environment';
import { GetIotService } from '../../../services/get-iot.service';
import { MatDialog } from '@angular/material/dialog';
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../../../iot/dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component';
import { faCog, faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons';
import { RefreshApiService } from 'src/app/services/refresh-api.service'
import { VideoAudioSSLDialogComponent } from 'src/app/video/dialog/video-audio-ssl-dialog/video-audio-ssl-dialog.component'

@Component({
  selector: 'app-audio-device-info',
  templateUrl: './audio-device-info.component.html',
  styleUrls: ['./audio-device-info.component.scss'],
})
export class AudioDeviceInfoComponent implements OnInit, OnChanges {
  @Output() not: any = 'not';
  @Output() audioEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() audioDeviceData: any;
  @Input() audioDeviceType: any;
  @Input() isShowLincese: any;
  @Input() showLoader: boolean = false;
  faPauseIcon = faPause;
  faPlayIcon = faPlay;
  siteApiData: any;
  id: number;
  allSiteListData: any;
  mins: number = 0;
  days: number = 0;
  hrs: number = 0;
  accessLevel: any;
  companyName: any;
  mspLogo: string = '../../assets/img/logo/' + environment.config.logo;
  hasAccess: boolean;
  shouldShowIot: any;
  isDomotzEnabled: any;
  iotData: any;
  faIcon = faUser;
  faCogIcon: any = faCog;
  videoPaused: any = 0;
  isSuperUser: any;
  isShowLicense = false;
  
  iotLiteEnabled: any;
  iotDeviceIdList: any = [];
  dCollector: String = environment.config.dynamicText.dCollector;
  iotLoader: String = environment.config.dynamicImage.iotLoader;
  isShowDomotzIntegration = environment.config.domotzIntegration;
  loader: string = environment.config.dynamicImage.loader;
  iotDomotzList: any;
  iotDomotzDeviceId: any;
  iotUnlinkedDeviceList: any;

  audioDeviceStatus:string;
  showWarningStatus: any = false;
  
  constructor(
    private route: ActivatedRoute,
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private getAudioService: GetAudioService,
    private GetIot: GetIotService,
    public dialog: MatDialog,
    private refreshApiData: RefreshApiService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.audioDeviceData && this.audioDeviceData) {
      this.setData();
    }
  }
  
  ngOnInit() {
    this.getCompanyInfo();
  }
  
  ngAfterContentChecked() {
    if (this.audioDeviceData && this.audioDeviceData.licenses) {
      for (let li of this.audioDeviceData.licenses) {
        if (li.value == 'True') {
          this.isShowLicense = true;
        }
      }
    }
  }
  
  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  setData = () => {
    this.days = this.audioDeviceData.uptime?.days;
    this.mins = this.audioDeviceData.uptime?.mins;
    this.hrs = this.audioDeviceData.uptime?.hrs;
    this.setDeviceStatus(this.audioDeviceData);
    // // console.log(this.audioDeviceData);
    const {
      collector_id,
      audio_device_id,
      collector_version,
      collector_status,
      room_name,
      site_id,
      mac,
      ipv4,
      iot_components,
      iot_domotz_components,
      paused,
    } = this.audioDeviceData;

    this.iotData = {
      collector_id,
      device_id: audio_device_id,
      quadrant: 2,
      iot_components,
      iot_domotz_components,
      collector_version,
      status: collector_status == 1,
      room_name,
      site_id,
      mac,
      ipv4,
      paused
    };
    
    this.getDomotzAccountByCompanyId();
  }

  getCompanyInfo = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperUser = this.Auth.accessLevel == 'SUPERADMIN';
    this.hasAccess = this.getHasAccess();

    this.Auth.getCompanyInfo().subscribe((data) => {
      this.companyName = data.response[0].company_name; // why?
    });

  }

  sendPopupType = (p?) => {
    this.paramData();
    this.ds.sendPopupType(p, { mediaType: 'audio', deviceId: this.id });
  }


  paramData = () => {
    this.sendPopupDataService.sendPopupData(this.id);
  }


  sipInfoTrim = (sip: string): string  =>{
    if (sip === null || sip.length === 0) {
      return '--';
    }

    return sip.split('@')[0];
  }

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER' || this.isSuperUser) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  refreshAudio = () => {
    this.audioEvent.emit('refreshAudioData');
  };

  openRemoteIp = async () => {
    const url = `http://${this.iotData.ipv4}`;
    const iotDevice: any = await this.getIotDeviceIpAndMacMatch(
      this.iotData.ipv4,
      this.iotData.mac
    );
    if (this.isDomotzEnabled && this.checkIotIsMonitored(iotDevice)) {
      const iotDeviceId = iotDevice.iot_device_id;

      const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
        panelClass: 'custom-dialog-container',
        data: {
          iotDeviceId,
        },
      });
    } else {
      window.open(url);
    }
  };

  checkIotIsMonitored = (iot) => {
    if (iot) {
      return iot.is_monitored === 1;
    }
    return false;
  };

  getIotDeviceIpAndMacMatch = async (ip, mac) => {
    return new Promise((resolve) => {
      this.GetIot.getIotDeviceIpAndMacMatch(ip, mac).subscribe((data: any) => {
        const [response] = data;
        resolve(response);
      });
    });
  };
  
  validateIot: any = {
    flag: true,
    message: 'message',
  };
  
  setDevicePause = (flag) => {
    const pauseFlag = flag ? 0 : 1;
    this.audioDeviceData.paused = pauseFlag;
    let i = 0;
    console.log(this.iotDeviceIdList)
    

    this.getAudioService.pauseAudioDevice(this.id, pauseFlag).subscribe((data: any) => {
      console.log('pauseAudioDevice', data);
      const [res] = data.response;
      if(res.status == 'OK') {
        console.log(res.status)
        while ( i < this.iotDeviceIdList.length) {
          console.log(i)
          this.setPaused(this.iotDeviceIdList[i], pauseFlag);
          i++;
        }
        this.refreshAudio();
        this.refreshApiData.refreshStatsEvent.emit('audio')
      }
    });
  } 
  
  setPaused = (iot_id,flag) => {
    this.GetIot.setIotDevicePaused(iot_id, flag).subscribe(
      (data: any) => {
        // this.eventEmitter.emit('callGetIotDeviceDetails');
        console.log('setIotDevicePaused: ', data)
        
      },
      (error) => { }
    );
  };
  

  setDeviceStatus = (data) => {
    if( data.online == 1 && data.paused == 0 && data.collector_status != -1 ) {
      this.audioDeviceStatus = 'Online'
    }
    
    if( data.online == -1 && data.paused == 0 && data.collector_status != -1 ) {
      this.audioDeviceStatus = 'Sleep'
    }
    
    if( data.online == 0 && data.paused == 0 && data.collector_status != -1 ) {
      this.audioDeviceStatus = 'Offline'
    }
    
    if( data.collector_status == -1 ) {
      this.audioDeviceStatus = 'Collector Offline'
    }
    
    if(data.collector_status == 2 ) {
      this.audioDeviceStatus = 'Collector Offline'
      this.showWarningStatus = true;
    }
    
    if( data.is_direct_access  == 1 &&  data.direct_access_info?.isEnable == 1 ) {
      this.audioDeviceStatus = 'Teleportivity'
      this.showWarningStatus = true;
    }

    if(data.paused === 1){
      this.audioDeviceStatus = 'Paused'
    }
  
  }
  
  
  setFlag = (flag) => (flag ? 0 : 1);
  
  //asumming cxdetect has this
  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.isDomotzEnabled = enabled === 1;
      if (this.isDomotzEnabled) {
        this.setIoTComponenets(true);
      } else {
        this.getIotLiteStatus();
      }
    });
  };
  
  getIotLiteStatus = () => {
    this.GetIot.getIotLiteStatus().subscribe((data: any) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;
      this.setIoTComponenets(false);
    });
  }
  
  setIoTComponenets = (isdomotzEnabled) => {
      if (isdomotzEnabled) {
        this.getLinkedIotDomotzDevices();
      } else {
        if (this.iotLiteEnabled) {
          this.getLinkedIotDevices();
        } 
      }
  };
  

  getLinkedIotDevices = () => {
    if (this.iotData.iot_components !== null) {
      this.iotDeviceIdList = [...this.iotData.iot_components].map( e => e.iot_id);
    }
  };

  getLinkedIotDomotzDevices = () => {
    const { iot_domotz_components } = this.iotData;
    if (iot_domotz_components !== null) {
      this.iotDeviceIdList = iot_domotz_components.map((i) => {
        const status = i.status;
        const paused = i.paused;
        const iot_device_id = i.iot_id;
        const iot_type = i.group_type;
        const iot_name = i.display_name;
        const iot_ip = i.ip_address;

        return { iot_device_id, status, iot_type, iot_name, iot_ip, paused };
      });
    } else {
      this.iotDeviceIdList = [];
    }
  };

	openDialog() {
		let dialogRef = this.dialog.open(VideoAudioSSLDialogComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				deviceId: this.audioDeviceData?.audio_device_id,
				deviceType: 'audio'
			},
		})
	}
  

}

