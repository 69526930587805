<div class="iot-lite-add-device-with-ports-dialog">
    <div class="iot-lite-add-device-with-ports-dialog__header">
        <div>
            IoT DIALOG
        </div>
        <div (click)="closeDialog()" class="iot-lite-add-device-with-ports-dialog__header-close-button">
            <img src="{{closeButtonImage}}" alt="" />
        </div>
    </div>

    <div class="iot-lite-add-device-with-ports-dialog__container">
        <div class="iot-lite-add-device__right-column">
            <div *ngIf="showErrMsg" class="iot-lite-add-device-with-ports-dialog__error-message">
                <h3>{{errorMsg}}</h3>
            </div>

            <div *ngIf="showSearching" class="iot-lite-add-device-with-ports-dialog__right-column-search">

                <ul class="bottom">
                    <li>
                        <h3>Searching</h3>
                    </li>
                    <li>
                        <div class="dot1"></div>
                    </li>
                    <li>
                        <div class="dot2"></div>
                    </li>
                    <li>
                        <div class="dot3"></div>
                    </li>
                </ul>
            </div>
            <div *ngIf="showSearching" style="margin-left: 15px;">
                Note: Please avoid clicking outside dialog box to continue searching for device.
            </div>

            <div *ngIf="showErrorMessage" class="iot-lite-add-device-with-ports-dialog__error-message">
                <h3>{{errorMessage}}</h3>
            </div>

            <div *ngIf="showSuccessMsg" class="iot-lite-add-device-with-ports-dialog__success-message">
                <h3>IoT Device Successfull Added</h3>
            </div>

            <!-- <div *ngIf="showAddDevice" class="iot-lite-add-device-with-ports-dialog__right-column-item"> -->
            <div *ngIf="showAddDevice"
                class="iot-lite-add-device-with-ports-dialog__device-details-and-table-container">
                <div class="iot-lite-add-device-with-ports-dialog__device-details-container" style="display: flex">
                    <div>
                        <h3>Device Details</h3>
                        <div class="iot-lite-add-device-with-ports-dialog__device-details-text">
                            <p>Device Name: {{deviceName | displayEmpty }}</p>
                            <p>MAC: {{deviceMac | displayEmpty}}</p>
                            <p>IPV4: {{deviceIP | displayEmpty}}</p>
                            <p>Device Make: {{deviceMake | displayEmpty}}</p>
                            <p>Hostname: {{hostName | displayEmpty}}</p>
                        </div>

                        <div class="iot-lite-add-device-with-ports-dialog__btn2">
                            <button (click)="addDevice()">Monitor IoT Device</button>
                            <button (click)="closeDialog()"
                                class="iot-lite-add-device-with-ports-dialog__btn2--cancel">Cancel</button>
                        </div>


                    </div>


                    <div class="iot-lite-add-device-with-ports-dialog__img-container">
                        <img src="../../../assets/img/icon_llama.png" />
                    </div>

                </div>

                <div>
                    <div class="iot-lite-add-device-with-ports-dialog__table-container">
                        <table class="table">
                            <thead>
                                <tr></tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of devicePorts;let i = index">
                                    <td>
                                        <input class="iot-lite-add-device__input form-control"
                                            [(ngModel)]="devicePortRename[i]" type="text" placeholder="Rename Port" />
                                    <td>
                                        <div>
                                            {{ item.portname}}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {{ item.portid}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin: 10px 0 0 40px;">
                            <h6>Note: Special characters and spaces are not allowed. You can use "_" ex. port_name
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>