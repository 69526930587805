import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class PexipService {
	server_url: string = environment.serverUrl
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	}

	constructor(private http: HttpClient, private auth: AuthService) {}

	addPexipAccount = (url, username, password): Observable<any> => {
		const postData = {
			company_id: this.auth.companyId,
			url,
			username,
			password,
		}

		return this.http.post<any>(this.server_url + '/api/v1/pexip', postData, this.httpOptions)
	}

	getPexipAccount = (): Observable<any> => {
		const url = `${this.server_url}/api/v1/pexip/${this.auth.companyId}`

		return this.http.get<any>(url, this.httpOptions)
	}

	activateDeactivatePexipAccount = (active): Observable<any> => {
		const postData = {
			company_id: this.auth.companyId,
			active,
		}

		return this.http.post<any>(this.server_url + '/api/v1/pexip/activateDeactivate', postData, this.httpOptions)
	}
}
