<div class="row">
  <div class="col-12 pl-1">
    <h6>Live Stats</h6>
  </div>
  <div class="col-6 pl-0 pr-1">
    <app-btn-stat-call [count]="audioDeviceData?.on_call" label="Impacted" color="gray"></app-btn-stat-call>
  </div>
  <div class="col-6 btn-col pl-1 pr-0">
    <app-btn-call-duration [count]="audioDeviceData?.duration" label="Call Duration" color="gray"
      isbutton="not"></app-btn-call-duration>
  </div>
</div>

<div class="row mt-2 pl-1">
  <div class="col-3 btn-col pl-0 pr-1">
    <app-btn-reg-stats-twodec [count]="audioDeviceData?.total_mos" label="MOS" color="gray" (click)="
        ds.sendPopupType(41, {
          deviceId: audioDeviceId,
          section: 'mos',
          type: audioDeviceType
        })
      ">
    </app-btn-reg-stats-twodec>
  </div>
  <div class="col-3 btn-col pl-0 pr-1">
    <app-btn-reg-stats [count]="audioDeviceData?.total_jitter" label="Jitter (msec)" color="{{ colorJit() }}" (click)="
        ds.sendPopupType(41, {
          deviceId: audioDeviceId,
          section: 'jitter',
          type: audioDeviceType
        })
      ">
    </app-btn-reg-stats>
  </div>
  <div class="col-3 btn-col pl-0 pr-1">
    <app-btn-reg-stats-twodec [count]="audioDeviceData?.total_packetloss" label="Packet Loss(%)"
      color="{{ colorPac() }}" (click)="
        ds.sendPopupType(41, {
          deviceId: audioDeviceId,
          section: 'packetloss',
          type: audioDeviceType
        })
      ">
    </app-btn-reg-stats-twodec>
  </div>
  <div class="col-3 btn-col pl-0 pr-0">
    <app-btn-reg-stats [count]="audioDeviceData?.total_latency" label="Latency" color="{{ colorLat() }}" (click)="
        ds.sendPopupType(41, {
          deviceId: audioDeviceId,
          section: 'latency',
          type: audioDeviceType
        })
      ">
    </app-btn-reg-stats>
  </div>
</div>

<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>
<!-- FIX THIS SOME DAY -->
<div class="row">
  <div class="col-12 pl-1">
    <h6>Device Status</h6>
  </div>
  <div class="col-3 pl-0 pr-1">
    <app-btn-mic [muteStatus]="audioDeviceData?.mic_muted" (click)="muteUnMuteMic()"
      [deviceStatus]="audioDeviceData?.on_call"></app-btn-mic>
  </div>

  <div class="col-3 pl-0 pr-1">
    <app-btn-signal *ngIf="!showMrPairCameraConnected" [signal]="audioDeviceData?.signal" color="gray">
    </app-btn-signal>

    <div *ngIf="showMrPairCameraConnected">
      <button type="button" class="btn-block not-button btn-reg">
        <div>
          <img style="height: 2.6em;" src="../../../../assets/img/icon_camera.png"
            [ngClass]="{'color-burn': mrPairCameraConnectedText !== 'Connected'}" alt="audio" />
          <p class="pt-1">{{mrPairCameraConnectedText}}</p>
        </div>
      </button>
    </div>

  </div>

  <div class="col-3 pl-0 pr-1">
    <app-btn-wire [networkInfo]="audioDeviceData?.network_info" color="gray"> </app-btn-wire>
  </div>
  <div class="col-3 pl-0 pr-1">
    <app-btn-service color="gray" [presence]="audioPresence"> </app-btn-service>
  </div>
</div>

<div class="row" style="padding-top: 20px;" *ngIf="audioDeviceType !== 'trioDevice'">
  <div class="col-12 pl-0 pr-1">
    <app-btn-handset style="width: inherit;">No handset</app-btn-handset>
  </div>
</div>
<!-- hr line space -->
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>

<div class="col-12 pl-1">
  <h6>30 Day Usages</h6>
</div>
<div class="row">
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-softphone [count]="audioDeviceData?.total_calls_by_month" label="Call" color="gray"
      (click)="openCallLog()" [isbutton]="isNotButton">
    </app-btn-reg-softphone>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [count]="audioDeviceData?.total_incidents_by_month" label="Incident" color="gray"
      (click)="openCallLog()" [isbutton]="isNotButton">
    </app-btn-reg-stats>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats-twodec [count]="audioTotalMosByMonth" label="MOS" color="gray" (click)="openCallLog()"
      [isbutton]="isNotButton">
    </app-btn-reg-stats-twodec>
  </div>
</div>
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <button class="not-button" style="width: inherit;"
      [innerHTML]="covertToHrs(audioDeviceData?.total_duration_by_month)"></button>
  </div>
</div>

<div class="row">
  <div class="col-12 pl-0 pr-1 py-2">
    <hr />
  </div>
</div>
<div class="col-12 pl-1">
  <h6>Compatability</h6>
</div>
<div class="row">
  <div class="col-4 pl-0 pr-1">
    <img src="../../assets/img/audio/team.png" alt="teams" class="compatibility-icons" />
    <app-btn-check-cross [isCompatible]="audioDeviceData?.microsoft_team_service === 1 ? true : false"
      style="width: inherit;"></app-btn-check-cross>
  </div>

  <div class="col-4 pl-0 pr-1 text-center">
    <img src="../../assets/img/zoom_logo.png@1X.png" alt="zoom" class="compatibility-icons--zoom" />
    <app-btn-check-cross [isCompatible]="audioDeviceData?.zoom_service === 1 ? true : false"
      style="width: inherit;"></app-btn-check-cross>
  </div>

  <div class="col-4 pl-0 pr-1">
    <img src="../../assets/img/audio/webex_teams.png" alt="webex" class="compatibility-icons" />
    <app-btn-check-cross [isCompatible]="audioDeviceData?.webex_service === 1 ? true : false"
      style="width: inherit;"></app-btn-check-cross>
  </div>
</div>
<!-- Note Section -->
<div class="row mt-2">
  <div class="col-12 px-0">
    <form action="">
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="7 " maxlength="4000" placeholder="Notes:"
          [disabled]="!hasAccess">{{ audioDeviceData?.notes }}</textarea>
        <span>maximum 4000 characters</span>
      </div>
      <div>
        <input type="button" *ngIf="hasAccess" class="btn btn-primary float-right doNotPrint" value="Save"
          (click)="saveNotes()" />
      </div>
    </form>
  </div>
</div>