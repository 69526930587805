import * as moment from 'moment-timezone';

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-teams-room-device-health-button',
  templateUrl: './teams-room-device-health-button.component.html',
  styleUrls: ['./teams-room-device-health-button.component.scss'],
})
export class TeamsRoomDeviceHealthButtonComponent implements OnInit, OnChanges {
  @ViewChild('sliderInput', { static: false }) sliderInput: ElementRef;

  @Input() quadrantType: string;
  @Input() cloudUserStats: any;
  @Input() logitechSync = false;

  ConnectionState: number = 0;

  camera: any = '../../../../../assets/img/cloud/icon_camera.png';
  mic: any = '../../../../../assets/img/cloud/icon_microphone.png';
  speaker: any = '../../../../../assets/img/cloud/icon_volume.png';
  zoom: any = '../../../../../assets/img/cloud/desktop_app.png';
  teamsImg: any = '../../../../../assets/img/cloud/desktop_app.png';
  isOnCall: boolean = false;
  zoomPluginEnabled: boolean = true;
  pluginId: number;
  zoomUserId: number;
  pluginStatus: number;
  zoomApiConnectedDevice: any;
  pluginConnectedDevice: any = null;
  deviceStatus: string = '../../../../../assets/img/shared/triangle_grey.png';
  deviceTwoStatus: string =
    '../../../../../assets/img/shared/triangle_grey.png';
  firmwareVersion: string = '';
  device: string = '';
  deviceTwo: string = '';
  GREEN = '../../../../../assets/img/shared/triangle_green.png';
  YELLOW = '../../../../../assets/img/shared/traingle_orange.png';
  RED = '../../../../../assets/img/shared/triangle_red.png';
  GREY = '../../../../../assets/img/shared/triangle_grey.png';
  isLogitechInfo: boolean = false;
  shouldShowLoader: boolean = false;
  loader: String = environment.config.dynamicImage.iotLoader;
  dPluginText: String = environment.config.dynamicText.deskPlugin;
  resultMessage: String;
  resultStatus: String = 'OK';

  sliderValue: number;
  masterVolume: number = -1;

  constructor(
    private desktopPlugin: GetDesktopPluginService,
    public zoomPluginAction: ZoomPluginActionService,
    public ds: PopupTypeService
  ) {}

  ngOnInit() {
    this.sliderValue = 0;
    this.sliderOriginalValue = 10;
    this.initSliderValue(this.sliderValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isReady()) {
      this.zoomPluginEnabled =
        this.cloudUserStats.zoom_plugin_enabled === 1 ? true : false;
      this.pluginId = this.cloudUserStats.plugin_id;
      this.zoomUserId = this.cloudUserStats.zoom_user_id;
      this.isOnCall = this.cloudUserStats.liveMeetings === 1 ? true : false;
      this.pluginStatus = this.cloudUserStats.plugin_status;

      this.setConnectedDevices();
    }

    if (changes.cloudUserStats && this.cloudUserStats) {
      if (this.cloudUserStats.plugin_host_info) {
        this.masterVolume =
          this.cloudUserStats.plugin_host_info[0].master_volume;
      }
      this.getOfflineCount();
    }
  }

  getQuadrantImage = () => {
    if (this.quadrantType === 'mic') {
      return this.mic;
    }
    if (this.quadrantType === 'camera') {
      return this.camera;
    }
    if (this.quadrantType === 'speaker') {
      return this.speaker;
    }

    return this.zoom;
  };

  getOfflineCount(){
    const devices = this.getLogitechDevices();
    if(devices?.length){
      const device_name = [this.device]
      const device = devices.filter((x)=> device_name.includes(x.Name));
      if (device?.length){
        this.isLogitechInfo = true;
        this.getFirmware(device);
        const totalOffline = device?.reduce((pV,cV)=>pV+cV.ConnectionState,0) || 0;
        this.ConnectionState = this.device ? totalOffline: 0;
      }
    }
  }

  getLogitechDevices(){
   try {
    const { zoom_plugin_data: { logitech }} = this.cloudUserStats;
    if(logitech){
      const { AllProducts:{ Products } } = logitech;
      if(Products?.length){
        const rallyDevice = Products.find((x)=>x.Name === 'Rally');
        if(rallyDevice){
          return rallyDevice?.Devices || []
        }
      }
    }
    return [];
   } catch (error) {
    return [];
   }
  }

  getFirmware(device) {
    if(device?.length && device[0]?.Information?.length) this.firmwareVersion = device[0].Information[0]?.FirmwareVersion;
  }



  setConnectedDevices = () => {
    if (
      !this.cloudUserStats.connected_devices ||
      this.cloudUserStats.connected_devices === null
    ) {
      this.cloudUserStats.connected_devices = {
        microphone: '',
        camera: '',
        speaker: '',
      };
    }

    if (this.quadrantType === 'mic') {
      this.zoomApiConnectedDevice =
        this.cloudUserStats.connected_devices.microphone || '';
      //change this logic soon
      if (
        this.zoomPluginEnabled &&
        this.pluginId !== null &&
        this.cloudUserStats.zoom_plugin_connected_devices.length !== 0
      ) {
        this.zoomConnectedDevices =
          this.cloudUserStats.zoom_plugin_connected_devices.audio_mic;
        this.pluginConnectedDevice =
          this.cloudUserStats.zoom_plugin_connected_devices.audio_mic.filter(
            (a) => a.inuse_teams
          );
        this.setDeviceParameter(this.zoomConnectedDevices);
      }
    }

    if (this.quadrantType === 'camera') {
      this.zoomApiConnectedDevice =
        this.cloudUserStats.connected_devices.video || '';
      if (
        this.zoomPluginEnabled &&
        this.pluginId !== null &&
        this.cloudUserStats.zoom_plugin_connected_devices.length !== 0
      ) {
        this.zoomConnectedDevices =
          this.cloudUserStats.zoom_plugin_connected_devices.video;
        this.pluginConnectedDevice =
          this.cloudUserStats.zoom_plugin_connected_devices.video.filter(
            (v) => v.inuse_teams
          );
        this.setDeviceParameter(this.zoomConnectedDevices);
      }
    }

    if (this.quadrantType === 'speaker') {
      this.zoomApiConnectedDevice =
        this.cloudUserStats.connected_devices.speaker || '';
      if (
        this.zoomPluginEnabled &&
        this.pluginId !== null &&
        this.cloudUserStats.zoom_plugin_connected_devices.length !== 0
      ) {
        this.zoomConnectedDevices =
          this.cloudUserStats.zoom_plugin_connected_devices.audio_speaker;
        this.pluginConnectedDevice =
          this.cloudUserStats.zoom_plugin_connected_devices.audio_speaker.filter(
            (s) => s.inuse_teams
          );
        this.setDeviceParameter(this.zoomConnectedDevices);
      }
    }

    if (
      this.quadrantType === 'mic' ||
      this.quadrantType === 'speaker' ||
      this.quadrantType === 'camera'
    ) {
      if (
        this.zoomPluginEnabled &&
        this.cloudUserStats.zoom_plugin_connected_devices.length !== 0
      ) {
        const connectedDevice = this.pluginConnectedDevice[0]
          ? this.pluginConnectedDevice[0]
          : null;

        if (connectedDevice != null) {
          this.device = connectedDevice.name;
          if (connectedDevice.status === 1) {
            this.deviceStatus = this.GREEN;
          } else {
            this.deviceStatus = this.RED;
          }
        } else {
          this.deviceStatus = this.RED;
        }

        if (
          this.logitechSync &&
          this.quadrantType === 'speaker' &&
          this.pluginConnectedDevice[1]
        ) {
          const connectedDeviceTwo = this.pluginConnectedDevice[1]
            ? this.pluginConnectedDevice[1]
            : null;

          if (connectedDeviceTwo != null) {
            this.deviceTwo = connectedDeviceTwo.name;
            if (connectedDeviceTwo.status === 1) {
              this.deviceTwoStatus = this.GREEN;
            } else {
              this.deviceTwoStatus = this.RED;
            }
          } else {
            this.deviceTwoStatus = this.RED;
          }
        }
      } else {
        this.device = this.zoomApiConnectedDevice;
        if (this.device.length > 0 && this.isOnCall) {
          this.deviceStatus = this.GREEN;
        } else if (this.device.length === 0 && this.isOnCall) {
          this.deviceStatus = this.RED;
        } else {
          this.deviceStatus = this.RED;
        }
      }
    }
  };

  data: any;
  zoomConnectedDevices: any;
  sliderOriginalValue: number;

  setDeviceParameter = (device: any) => {
    // not dynamic yet T_T change this huhu
    if (device.length > 0) {
      if (this.quadrantType === 'mic') {
        this.data = {
          connectedAudioMic: device,
          pluginId: this.pluginId,
          failOver: false,
          serviceType: 'teams',
        };
      }

      if (this.quadrantType === 'camera') {
        this.data = {
          connectedCameras: device,
          pluginId: this.pluginId,
          failOver: false,
          serviceType: 'teams',
        };
      }
      if (this.quadrantType === 'speaker') {
        this.data = {
          connectedAudioSpeaker: device,
          pluginId: this.pluginId,
          failOver: false,
          serviceType: 'teams',
        };
      }
    }
  };

  isReady = () => {
    if (!this.cloudUserStats) {
      return false;
    }
    if (this.cloudUserStats.length === 0) {
      return false;
    }
    return true;
  };

  // executeAction = () => {
  //   this.resultMessage = '';
  //   this.zoomPluginAction.clearActionResult();
  //   let actionParams = null;
  //   let actionName = '';
  //   // // console.log('this.pluginConnectedDevice.id:', this.pluginConnectedDevice);
  //   if (this.pluginStatus !== 1) {
  //     this.resultMessage = `${this.dPluginText} is offline.`;
  //     this.resultStatus = 'Failed';
  //   } else if (
  //     this.pluginConnectedDevice === null &&
  //     this.quadrantType !== 'zoom'
  //   ) {
  //     this.resultMessage = 'Device not present.';
  //     this.resultStatus = 'Failed';
  //   } else {
  //     if (this.quadrantType === 'zoom') {
  //       actionName = 'StartZoomClient';
  //     } else if (this.quadrantType === 'mic') {
  //       actionParams = { id: this.pluginConnectedDevice.id };
  //       actionName = 'RemoteHealMicrophone';
  //     } else if (this.quadrantType === 'camera') {
  //       actionParams = { id: this.pluginConnectedDevice.id };
  //       actionName = 'RemoteHealCamera';
  //     } else if (this.quadrantType === 'speaker') {
  //       actionParams = { id: this.pluginConnectedDevice.id };
  //       actionName = 'RemoteHealSpeaker';
  //     }
  //     this.zoomPluginAction.deviceButtonLoader = true;
  //     this.shouldShowLoader = true;

  //     this.desktopPlugin
  //       .createAction(this.pluginId, actionParams, actionName)
  //       .subscribe((data) => {
  //         if (data.status === 'Ok') {
  //           this.zoomPluginAction.checkDesktopPluginActionStatus(
  //             data.action_id
  //           );
  //           this.zoomPluginAction.setActionCount();
  //         } else {
  //           this.zoomPluginAction.clearActionResult();
  //           this.zoomPluginAction.deviceButtonLoader = false;
  //         }
  //       });
  //   }
  // };

  getLoader() {
    if (this.zoomPluginAction.deviceButtonLoader) {
      return true;
    }
    this.shouldShowLoader = false;
    if (!this.shouldShowLoader) {
      if (this.zoomPluginAction.actionResult.message) {
        if (this.zoomPluginAction.actionResult.status === 'OK') {
          const dDate = moment(this.zoomPluginAction.actionResult.datetime)
            .utc()
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('MM-DD-YYYY hh:mma');
          this.resultMessage = `${this.zoomPluginAction.actionResult.message} Date and time: ${dDate}`;
        } else {
          this.resultMessage = `${this.zoomPluginAction.actionResult.message}`;
        }
      }
      this.resultStatus = this.zoomPluginAction.actionResult.status;
    }
    return false;
  }

  displayDeviceHealList() {
    let data = {
      headerTitle: this.popUpHeaderTitle(this.quadrantType),
      deviceList: this.getDeviceList(this.quadrantType),
    };

    this.ds.sendPopupType(70, { data });
  }

  popUpHeaderTitle = (device) => {
    if (device === 'mic') {
      return 'Microphone';
    }
    if (device === 'camera') {
      return 'Camera';
    }
    return 'Speaker';
  };

  getDeviceList = (device) => {
    //assuming connected device has any data
    if (device === 'mic') {
      return this.cloudUserStats.zoom_plugin_connected_devices.audio_mic;
    }
    if (device === 'camera') {
      return this.cloudUserStats.zoom_plugin_connected_devices.video;
    }
    return this.cloudUserStats.zoom_plugin_connected_devices.audio_speaker;
  };

  getQuadrantTypeLogo = (servicetype) => {
    return servicetype === 'zoom'
      ? '../../../../../assets/img/vs_img/zoom_logo.png'
      : '../../../../../assets/img/vs_img/teams_logo_2.png';
  };

  initSliderValue = (val) => {
    if (this.sliderInput) {
      this.sliderInput.nativeElement.value = val;
      this.sliderValue = val;
    }
  };

  handleSliderValue = (event) => {
    // // console.log("slidervalue event ", event)
    this.setSliderValue(event);
  };

  setSliderValue = (val) => {
    this.sliderInput.nativeElement.value = val;
    // // console.log("thisSlider", this.sliderInput)
    this.sliderValue = val;
    const data = {
      sliderValue: this.sliderValue,
      pluginId: this.pluginId,
      roomsSliderService: this.cloudUserStats.service_type,
      // failOver: this.failOver,
    };

    this.ds.sendPopupType(51, { data });
  };

  // getStatusIcon(primary) {
  //   const { status, name } = primary;
  //   if (status === 1 || name !== 'No Data') {
  //     return this.iconTriangleGreen;
  //   } else {
  //     return this.iconTriangleRed;
  //   }
  // }

  // iconOnHover() {
  //   '../../assets/img/cloud/icon_microphone_blue.png';
  // }

  // iconOnLeave() {
  //   this.icon = '../../assets/img/cloud/icon_microphone.png';
  // }
  // openPopUp = () => {
  //   let popUp = 0;

  //   if (this.quadrantType === 'mic') {
  //     popUp = 48;
  //   }

  //   if (this.quadrantType === 'camera') {
  //     popUp = 49;
  //   }
  //   if (this.quadrantType === 'speaker') {
  //     popUp = 50;
  //   }

  //   if (this.pluginConnectedDevice != null) {
  //     console.log(this.data);
  //     console.log(popUp);
  //     this.ds.sendPopupType(popUp, { data: this.data });
  //   }
  // };
}
