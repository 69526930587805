import { Component, Input, OnInit } from '@angular/core'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { GetCloudService } from '../../../services/get-cloud.service'
import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: 'app-cloud-all-users-info',
  templateUrl: './cloud-all-users-info.component.html',
  styleUrls: ['./cloud-all-users-info.component.scss'],
})
export class CloudAllUsersInfoComponent implements OnInit {
  @Input() cloudUserSummary: Array<any> = [];
  data: any;
  isSuperAdmin: any;
  currentFilter: string;
  mspLogo: string = environment.config.logo;
  server_url: string = environment.serverUrl;
  companyLogo: any;

  constructor(private ds: PopupTypeService, public Auth: AuthService,
    private getCloudDataService: GetCloudService,
  ) { }


  ngOnInit() {
    // send message to subscribers via observable subject
    this.isSuperAdmin = this.Auth.accessLevel == 'SUPERADMIN';
    this.ds.sendPopupType(0);
    this.getCompanyInfo()
    this.checkForEnabledService()
  }

  checkForEnabledService = () => {
    // console.log('check for enabled service')
  }

  getCompanyInfo() {
    this.Auth.getCompanyInfo().subscribe((data) => {
      const [newData] = data.response
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
      // // console.log(this.companyLogo)
    });
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }
}
