import { ProgressBarModule } from 'angular-progress-bar'
import { UserIdleModule } from 'angular-user-idle'
import { MomentTimezonePickerModule } from 'moment-timezone-picker'
import { Ng5SliderModule } from 'ng5-slider'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { ToastContainerModule, ToastrModule } from 'ngx-toastr'

import { CommonModule, DatePipe } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
// material table
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatStepperModule } from '@angular/material/stepper'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { JwtModule } from '@auth0/angular-jwt'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { AlertModule } from './_alert'
import { AddPhoneNumberComponent } from './add-phone-number/add-phone-number.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthGuard } from './auth.guard'
import { CollectorAdminComponent } from './collector/collector-admin/collector-admin.component'
import { CollectorAlertPanelComponent } from './collector/collector-alert-panel/collector-alert-panel.component'
import { CollectorConsoleComponent } from './collector/collector-console/collector-console.component'
import { CollectorDeleteSiteComponent } from './collector/collector-delete-site/collector-delete-site.component'
import { CollectorDocumentCenterComponent } from './collector/collector-document-center/collector-document-center.component'
import { CollectorEditSiteComponent } from './collector/collector-edit-site/collector-edit-site.component'
import { CollectorHostUtilizationComponent } from './collector/collector-host-utilization/collector-host-utilization.component'
import { CollectorIncidentListComponent } from './collector/collector-incident-list/collector-incident-list.component'
import { CollectorInfoComponent } from './collector/collector-info/collector-info.component'
import { CollectorListContainerComponent } from './collector/collector-list-container/collector-list-container.component'
import { CollectorListComponent } from './collector/collector-list/collector-list.component'
import { CollectorLiveStatsComponent } from './collector/collector-live-stats/collector-live-stats.component'
import { CollectorLoggingComponent } from './collector/collector-logging/collector-logging.component'
import { CollectorNotesComponent } from './collector/collector-notes/collector-notes.component'
import { CollectorPanelComponent } from './collector/collector-panel/collector-panel.component'
import { CollectorPathTestComponent } from './collector/collector-path-test/collector-path-test.component'
import { CollectorRebootComponent } from './collector/collector-reboot/collector-reboot.component'
import { CollectorStatsComponent } from './collector/collector-stats/collector-stats.component'
import { BadgeComponent } from './components/badge/badge.component'
import { BtnApiComponent } from './components/buttons/btn-api/btn-api.component'
import { BtnAudioFirmwareDevicesComponent } from './components/buttons/btn-audio-firmware-devices/btn-audio-firmware-devices.component'
import { BtnAudioFirmwareUsersComponent } from './components/buttons/btn-audio-firmware-users/btn-audio-firmware-users.component'
import { BtnAudioLiveStatsComponent } from './components/buttons/btn-audio-live-stats/btn-audio-live-stats.component'
import { BtnBitrateComponent } from './components/buttons/btn-bitrate/btn-bitrate.component'
import { BtnBluetoothComponent } from './components/buttons/btn-bluetooth/btn-bluetooth.component'
import { BtnCallDurationComponent } from './components/buttons/btn-call-duration/btn-call-duration.component'
import { BtnCheckCrossComponent } from './components/buttons/btn-check-cross/btn-check-cross.component'
import { BtnDeviceCameraHealthComponent } from './components/buttons/btn-device-camera-health/btn-device-camera-health.component'
import { BtnDeviceCameraZoomComponent } from './components/buttons/btn-device-camera-zoom/btn-device-camera-zoom.component'
import { BtnDeviceHealthComponent } from './components/buttons/btn-device-health/btn-device-health.component'
import { BtnDeviceLogitechComponent } from './components/buttons/btn-device-logitech/btn-device-logitech.component'
import { BtnDeviceScreenComponent } from './components/buttons/btn-device-screen/btn-device-screen.component'
import { BtnDeviceZoomComponent } from './components/buttons/btn-device-zoom/btn-device-zoom.component'
import { BtnHandsetComponent } from './components/buttons/btn-handset/btn-handset.component'
import { BtnHealthComponent } from './components/buttons/btn-health/btn-health.component'
import { BtnInfoComponent } from './components/buttons/btn-info/btn-info.component'
import { BtnLongMeetingsComponent } from './components/buttons/btn-long-meetings/btn-long-meetings.component'
import { BtnMicComponent } from './components/buttons/btn-mic/btn-mic.component'
import { BtnQosDisabledComponent } from './components/buttons/btn-qos-disabled/btn-qos-disabled.component'
import { BtnQosComponent } from './components/buttons/btn-qos/btn-qos.component'
import { BtnRecordingComponent } from './components/buttons/btn-recording/btn-recording.component'
import { BtnRegAudioUserComponent } from './components/buttons/btn-reg-audio-user/btn-reg-audio-user.component'
import { BtnRegSoftphoneComponent } from './components/buttons/btn-reg-softphone/btn-reg-softphone.component'
import { BtnRegStatsNotComponent } from './components/buttons/btn-reg-stats-not/btn-reg-stats-not.component'
import { BtnRegStatsTwodecComponent } from './components/buttons/btn-reg-stats-twodec/btn-reg-stats-twodec.component'
//buttons components
import { BtnRegStatsComponent } from './components/buttons/btn-reg-stats/btn-reg-stats.component'
import { BtnRoundedComponent } from './components/buttons/btn-rounded/btn-rounded.component'
import { BtnServiceComponent } from './components/buttons/btn-service/btn-service.component'
import { BtnSignalComponent } from './components/buttons/btn-signal/btn-signal.component'
import { BtnStatCallComponent } from './components/buttons/btn-stat-call/btn-stat-call.component'
import { BtnTotalTalkComponent } from './components/buttons/btn-total-talk/btn-total-talk.component'
import { BtnUtilComponent } from './components/buttons/btn-util/btn-util.component'
import { BtnUtilizationComponent } from './components/buttons/btn-utilization/btn-utilization.component'
import { BtnVolumeComponent } from './components/buttons/btn-volume/btn-volume.component'
import { BtnWireComponent } from './components/buttons/btn-wire/btn-wire.component'
import { ChartAudioDnaComponent } from './components/charts/chart-audio-dna/chart-audio-dna.component'
import { ChartAudioTwentyfourhoursDnaComponent } from './components/charts/chart-audio-twentyfourhours-dna/chart-audio-twentyfourhours-dna.component'
import { ChartAudioTwentyfourhoursUserComponent } from './components/charts/chart-audio-twentyfourhours-user/chart-audio-twentyfourhours-user.component'
import { ChartAudioTwentyfourhoursComponent } from './components/charts/chart-audio-twentyfourhours/chart-audio-twentyfourhours.component'
import { ChartAudioComponent } from './components/charts/chart-audio/chart-audio.component'
import { ChartAudio60MinViewComponent } from './components/charts/chart-audio60-min-view/chart-audio60-min-view.component'
import { ChartCloudDnaComponent } from './components/charts/chart-cloud-dna/chart-cloud-dna.component'
import { ChartCloudMbpsComponent } from './components/charts/chart-cloud-mbps/chart-cloud-mbps.component'
import { ChartQosComponent } from './components/charts/chart-qos/chart-qos.component'
import { ChartSiteComponent } from './components/charts/chart-site/chart-site.component'
//charts components
import { ChartTwentyFourComponent } from './components/charts/chart-twenty-four/chart-twenty-four.component'
import { ChartVideoDnaComponent } from './components/charts/chart-video-dna/chart-video-dna.component'
import { ChartVideoPacketlossComponent } from './components/charts/chart-video-packetloss/chart-video-packetloss.component'
import { ClearTeamCacheComponent } from './components/clear-team-cache/clear-team-cache.component'
import { CloudLiveCallListComponent } from './components/cloud-live-call-list/cloud-live-call-list.component'
import { CloudRoomAnalyticsComponent } from './components/cloud-room-analytics/cloud-room-analytics.component'
import { CloudUserConnectedDevicesComponent } from './components/cloud-user-connected-devices/cloud-user-connected-devices.component'
import { DeviceNotesComponent } from './components/device-notes/device-notes.component'
import { FilterTableComponent } from './components/filter-table/filter-table.component'
import { FlushDnsComponent } from './components/flush-dns/flush-dns.component'
import { HealthBarComponent } from './components/health-bar/health-bar.component'
import { InfoStatusComponent } from './components/info-status/info-status.component'
import { InfoUptimeComponent } from './components/info-uptime/info-uptime.component'
import { InfoDetailsComponent } from './components/info/info-details/info-details.component'
import { InfoSiteDetailsComponent } from './components/info/info-site-details/info-site-details.component'
import { IotComponent } from './components/iot/iot.component'
import { PanelToolsComponent } from './components/panel-tools/panel-tools.component'
import { PluginLoggingComponent } from './components/plugin-logging/plugin-logging.component'
import { PluginCloudZoomDisableComponent } from './components/plugin/plugin-cloud-zoom-disable/plugin-cloud-zoom-disable.component'
import { ScheduleRecurrenceComponent } from './components/schedule-recurrence/schedule-recurrence.component'
import { SlackSlideoutComponent } from './components/slack-slideout/slack-slideout.component'
import { SystemHealthComponent } from './components/system-health/system-health.component'
import { SystemTempComponent } from './components/system-temp/system-temp.component'
import { TableCogIconComponent } from './components/table-icons/table-cog-icon/table-cog-icon.component'
import { TableColorRowComponent } from './components/table-icons/table-color-row/table-color-row.component'
// Table filter components
import { TableHealthIconComponent } from './components/table-icons/table-icon/table-health-icon.component'
import { TableOnCallIconComponent } from './components/table-icons/table-oncall-icon/table-oncall-icon.component'
import { TableQosIconComponent } from './components/table-icons/table-qos-icon/table-qos-icon.component'
import { TableSiteTypeIconComponent } from './components/table-icons/table-sitetype-icon/table-sitetype-icon.component'
import { TableStatusIconComponent } from './components/table-icons/table-status-icon/table-status-icon.component'
import { TableZoomOnCallComponent } from './components/table-icons/table-zoom-on-call/table-zoom-on-call.component'
import { SimpleLoaderComponent } from './components/utilities/simple-loader/simple-loader.component'
import { WellnessCheckComponent } from './components/wellness-check/wellness-check.component'
import { ZoomEnableServiceComponent } from './components/zoom/zoom-enable-service/zoom-enable-service.component'
import { ZoomUserListComponent } from './components/zoom/zoom-user-list/zoom-user-list.component'
import { ReAuthorizeComponent } from './dialogs/re-authorize/re-authorize.component'
import { ResetNotificationScheduleComponent } from './dialogs/reset-notification-schedule/reset-notification-schedule.component'
import { UserIdleDialogComponent } from './dialogs/user-idle-dialog/user-idle-dialog.component'
import { AudioMainComponent } from './direct-access/audio/audio-main/audio-main.component'
import { AudioMonitoringComponent } from './direct-access/audio/audio-monitoring/audio-monitoring.component'
import { AudioSystemStatusComponent } from './direct-access/audio/audio-system-status/audio-system-status.component'
import { CloudMainComponent } from './direct-access/cloud/cloud-main/cloud-main.component'
import { ZoomMonitoringComponent } from './direct-access/cloud/zoom-monitoring/zoom-monitoring.component'
import { ZoomStatusComponent } from './direct-access/cloud/zoom-status/zoom-status.component'
import { ZoomSystemStatusComponent } from './direct-access/cloud/zoom-system-status/zoom-system-status.component'
import { DeviceActionComponent } from './direct-access/shared-components/device-action/device-action.component'
import { DeviceCallStatusComponent } from './direct-access/shared-components/device-call-status/device-call-status.component'
import { DeviceStatusComponent } from './direct-access/shared-components/device-status/device-status.component'
import { RecommendedActionsComponent } from './direct-access/shared-components/recommended-actions/recommended-actions.component'
import { SupportContactComponent } from './direct-access/shared-components/support-contact/support-contact.component'
import { VideoMainComponent } from './direct-access/video/video-main/video-main.component'
import { VideoMonitoringComponent } from './direct-access/video/video-monitoring/video-monitoring.component'
import { VideoSystemStatusComponent } from './direct-access/video/video-system-status/video-system-status.component'
import { HighlightDirective } from './directives/highlight.directive'
import { LazyForDirective } from './directives/lazyFor.directive'
import { CompanyDocumentsComponent } from './document-center/company-documents/company-documents.component'
import { DocumentCenterDialogComponent } from './document-center/dialogs/document-center-dialog/document-center-dialog.component'
import { DocumentsComponent } from './document-center/documents/documents.component'
import { ViewDocumentComponent } from './document-center/view-document/view-document.component'
import { HomepageComponent } from './homepage/homepage.component'
import { ChartHistoricPingRangeComponent } from './iot/charts/chart-historic-ping-range/chart-historic-ping-range.component'
import { ChartHistoricPingStatusComponent } from './iot/charts/chart-historic-ping-status/chart-historic-ping-status.component'
import { AddIotDomotzDialogComponentComponent } from './iot/dialogs/add-iot-domotz-dialog-component/add-iot-domotz-dialog-component.component'
import { AddSpaceDomotzDialogComponent } from './iot/dialogs/add-space-domotz-dialog/add-space-domotz-dialog.component'
import { DeleteRoomDialogComponent } from './iot/dialogs/delete-room-dialog/delete-room-dialog.component'
import { DeleteSiteDialogComponent } from './iot/dialogs/delete-site-dialog/delete-site-dialog.component'
import { IotLiteAddDeviceDialogComponent } from './iot/dialogs/iot-lite-add-device-dialog/iot-lite-add-device-dialog.component'
import { IotLiteAddDeviceWithPortsDialogComponent } from './iot/dialogs/iot-lite-add-device-with-ports-dialog/iot-lite-add-device-with-ports-dialog.component'
import { IotLiteDomotzErrorMessageComponent } from './iot/dialogs/iot-lite-domotz-error-message/iot-lite-domotz-error-message.component'
import { LinkDesktopPluginDialogComponent } from './iot/dialogs/link-desktop-plugin-dialog/link-desktop-plugin-dialog.component'
import { LinkDesktopPluginHostDialogComponent } from './iot/dialogs/link-desktop-plugin-host-dialog/link-desktop-plugin-host-dialog.component'
import { LinkDeviceSpaceDialogComponent } from './iot/dialogs/link-device-space-dialog/link-device-space-dialog.component'
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from './iot/dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'
import { IotAdminAlertsComponent } from './iot/iot-admin-alerts/iot-admin-alerts.component'
import { IotAdminPopUpComponent } from './iot/iot-admin-pop-up/iot-admin-pop-up.component'
import { IotAgentListComponent } from './iot/iot-agent-list/iot-agent-list.component'
import { IotAllAgentsComponent } from './iot/iot-all-agents/iot-all-agents.component'
import { IotAllDevicesComponent } from './iot/iot-all-devices/iot-all-devices.component'
import { IotAllSpacePanelComponent } from './iot/iot-all-space-panel/iot-all-space-panel.component'
import { IotDeviceAccountConfigComponent } from './iot/iot-device-account-config/iot-device-account-config.component'
import { IotDeviceConsoleComponent } from './iot/iot-device-console/iot-device-console.component'
import { IotDeviceEnableServiceComponent } from './iot/iot-device-enable-service/iot-device-enable-service.component'
import { IotDeviceHistoricPingComponent } from './iot/iot-device-historic-ping/iot-device-historic-ping.component'
import { IotDeviceHistoryComponent } from './iot/iot-device-history/iot-device-history.component'
import { IotDeviceInfoComponent } from './iot/iot-device-info/iot-device-info.component'
import { IotDeviceLastEventComponent } from './iot/iot-device-last-event/iot-device-last-event.component'
import { IotDeviceLatestEventComponent } from './iot/iot-device-latest-event/iot-device-latest-event.component'
import { IotDeviceListComponent } from './iot/iot-device-list/iot-device-list.component'
import { IotDeviceLiveStatsComponent } from './iot/iot-device-live-stats/iot-device-live-stats.component'
import { IotDeviceNotesComponent } from './iot/iot-device-notes/iot-device-notes.component'
import { IotDevicePanelComponent } from './iot/iot-device-panel/iot-device-panel.component'
import { IotDeviceStatsComponent } from './iot/iot-device-stats/iot-device-stats.component'
import { IotDomotzSummaryListComponent } from './iot/iot-domotz-summary-list/iot-domotz-summary-list.component'
import { IotLiteAddDeviceComponent } from './iot/iot-lite-add-device/iot-lite-add-device.component'
import { IotLiteAdminComponent } from './iot/iot-lite-admin/iot-lite-admin.component'
import { IotLiteDeviceChartComponent } from './iot/iot-lite-device-chart/iot-lite-device-chart.component'
import { IotLiteDeviceHealthHistoryComponent } from './iot/iot-lite-device-health-history/iot-lite-device-health-history.component'
import { IotLiteDeviceHistoricPingComponent } from './iot/iot-lite-device-historic-ping/iot-lite-device-historic-ping.component'
import { IotLiteDeviceInfoComponent } from './iot/iot-lite-device-info/iot-lite-device-info.component'
import { IotLiteDeviceLastEventComponent } from './iot/iot-lite-device-last-event/iot-lite-device-last-event.component'
import { IotLiteDeviceLatestEventComponent } from './iot/iot-lite-device-latest-event/iot-lite-device-latest-event.component'
import { IotLiteDeviceListComponent } from './iot/iot-lite-device-list/iot-lite-device-list.component'
import { IotLiteDeviceLiveStatsComponent } from './iot/iot-lite-device-live-stats/iot-lite-device-live-stats.component'
import { IotLiteDeviceNotesComponent } from './iot/iot-lite-device-notes/iot-lite-device-notes.component'
import { IotLiteDevicePanelComponent } from './iot/iot-lite-device-panel/iot-lite-device-panel.component'
import { IotLiteDeviceStatsComponent } from './iot/iot-lite-device-stats/iot-lite-device-stats.component'
import { IotLiteMainMenuComponent } from './iot/iot-lite-main-menu/iot-lite-main-menu.component'
import { IotLiteSiteSummaryComponent } from './iot/iot-lite-site-summary/iot-lite-site-summary.component'
import { IotLiteSummaryListComponent } from './iot/iot-lite-summary-list/iot-lite-summary-list.component'
import { IotRebootDeviceComponent } from './iot/iot-reboot-device/iot-reboot-device.component'
import { IotSpaceListComponent } from './iot/iot-space-list/iot-space-list.component'
import { IotSpacePanelComponent } from './iot/iot-space-panel/iot-space-panel.component'
import { IotSpaceSummaryListComponent } from './iot/iot-space-summary-list/iot-space-summary-list.component'
import { IotSummaryAgentListComponent } from './iot/iot-summary-agent-list/iot-summary-agent-list.component'
import { IotSummaryDeviceListComponent } from './iot/iot-summary-device-list/iot-summary-device-list.component'
import { IotSummaryDeviceTypesComponent } from './iot/iot-summary-device-types/iot-summary-device-types.component'
import { IotSummaryLiveStatsComponent } from './iot/iot-summary-live-stats/iot-summary-live-stats.component'
import { IotSummaryPanelComponent } from './iot/iot-summary-panel/iot-summary-panel.component'
import { IotSummaryStatsComponent } from './iot/iot-summary-stats/iot-summary-stats.component'
import { AppMaterialModule } from './material.module'
import { AlertsPanelComponent } from './panels/alerts-panel/alerts-panel.component'
import { AudioAllDevicesInfoComponent } from './panels/audio/audio-all-devices-info/audio-all-devices-info.component'
import { AudioAllDevicesListTableComponent } from './panels/audio/audio-all-devices-list-table/audio-all-devices-list-table.component'
import { AudioAllDevicesListComponent } from './panels/audio/audio-all-devices-list/audio-all-devices-list.component'
import { AudioAllDevicesPanelComponent } from './panels/audio/audio-all-devices-panel/audio-all-devices-panel.component'
import { AudioAllDevicesStatsComponent } from './panels/audio/audio-all-devices-stats/audio-all-devices-stats.component'
import { AudioAllsitesInfoPanelComponent } from './panels/audio/audio-allsites-info-panel/audio-allsites-info-panel.component'
import { AudioAllsitesListTableComponent } from './panels/audio/audio-allsites-list-table/audio-allsites-list-table.component'
import { AudioAllsitesListComponent } from './panels/audio/audio-allsites-list/audio-allsites-list.component'
import { AudioAllsitesPanelComponent } from './panels/audio/audio-allsites-panel/audio-allsites-panel.component'
import { AudioAllsitesStatsPanelComponent } from './panels/audio/audio-allsites-stats-panel/audio-allsites-stats-panel.component'
import { AudioDeviceInfoComponent } from './panels/audio/audio-device-info/audio-device-info.component'
import { AudioDeviceListComponent } from './panels/audio/audio-device-list/audio-device-list.component'
import { AudioDevicePanelComponent } from './panels/audio/audio-device-panel/audio-device-panel.component'
import { AudioDeviceStatsComponent } from './panels/audio/audio-device-stats/audio-device-stats.component'
import { AudioSiteDevicesListTableComponent } from './panels/audio/audio-site-devices-list-table/audio-site-devices-list-table.component'
import { AudioSiteInfoPanelComponent } from './panels/audio/audio-site-info-panel/audio-site-info-panel.component'
import { AudioSiteListPanelComponent } from './panels/audio/audio-site-list-panel/audio-site-list-panel.component'
import { AudioSitePanelComponent } from './panels/audio/audio-site-panel/audio-site-panel.component'
import { AudioSiteStatsPanelComponent } from './panels/audio/audio-site-stats-panel/audio-site-stats-panel.component'
import { CloudAllIncidentsListComponent } from './panels/cloud/cloud-all-incidents-list/cloud-all-incidents-list.component'
import { CloudAllRoomsInfoComponent } from './panels/cloud/cloud-all-rooms-info/cloud-all-rooms-info.component'
import { CloudAllRoomsListTableComponent } from './panels/cloud/cloud-all-rooms-list-table/cloud-all-rooms-list-table.component'
import { CloudAllRoomsListComponent } from './panels/cloud/cloud-all-rooms-list/cloud-all-rooms-list.component'
import { CloudAllRoomsPanelComponent } from './panels/cloud/cloud-all-rooms-panel/cloud-all-rooms-panel.component'
import { CloudAllRoomsStatsComponent } from './panels/cloud/cloud-all-rooms-stats/cloud-all-rooms-stats.component'
import { CloudAllUsersInfoComponent } from './panels/cloud/cloud-all-users-info/cloud-all-users-info.component'
import { CloudAllUsersListTableComponent } from './panels/cloud/cloud-all-users-list-table/cloud-all-users-list-table.component'
import { CloudAllUsersListComponent } from './panels/cloud/cloud-all-users-list/cloud-all-users-list.component'
import { CloudAllUsersPanelComponent } from './panels/cloud/cloud-all-users-panel/cloud-all-users-panel.component'
import { CloudAllUsersStatsComponent } from './panels/cloud/cloud-all-users-stats/cloud-all-users-stats.component'
import { CloudRoomInfoComponent } from './panels/cloud/cloud-room-info/cloud-room-info.component'
import { CloudRoomPanelComponent } from './panels/cloud/cloud-room-panel/cloud-room-panel.component'
import { CloudRoomStatsComponent } from './panels/cloud/cloud-room-stats/cloud-room-stats.component'
import { CloudUserInfoComponent } from './panels/cloud/cloud-user-info/cloud-user-info.component'
import { CloudUserPanelComponent } from './panels/cloud/cloud-user-panel/cloud-user-panel.component'
import { CloudUserStatsComponent } from './panels/cloud/cloud-user-stats/cloud-user-stats.component'
import { CloudUserActionsComponent } from './panels/cloud/cloud-user/cloud-user-actions/cloud-user-actions.component'
import { CloudUserConsoleComponent } from './panels/cloud/cloud-user/cloud-user-console/cloud-user-console.component'
import { CloudUserDeviceHealthButtonComponent } from './panels/cloud/cloud-user/cloud-user-device-health-button/cloud-user-device-health-button.component'
import { CloudUserDeviceHealthComponent } from './panels/cloud/cloud-user/cloud-user-device-health/cloud-user-device-health.component'
import { CloudUserLiveStatsComponent } from './panels/cloud/cloud-user/cloud-user-live-stats/cloud-user-live-stats.component'
import { CloudUserNotesComponent } from './panels/cloud/cloud-user/cloud-user-notes/cloud-user-notes.component'
import { CloudUserPathDetectionComponent } from './panels/cloud/cloud-user/cloud-user-path-detection/cloud-user-path-detection.component'
import { CloudUserUtilizationComponent } from './panels/cloud/cloud-user/cloud-user-utilization/cloud-user-utilization.component'
import { LogitechScribePanelComponent } from './panels/cloud/logitech-scribe-panel/logitech-scribe-panel.component'
import { LogitechTapPanelComponent } from './panels/cloud/logitech-tap-panel/logitech-tap-panel.component'
import { ComponentsAllComponent } from './panels/components-all/components-all.component'
import { HealthBlockComponent } from './panels/health-block/health-block.component'
import { HealthCardComponent } from './panels/health-card/health-card.component'
import { AdminComponent } from './panels/login/admin/admin.component'
import { ForgotPasswordComponent } from './panels/login/forgot-password/forgot-password.component'
import { InstantLoginComponent } from './panels/login/instant-login/instant-login.component'
import { LoginComponent } from './panels/login/login/login.component'
import { LogoutComponent } from './panels/login/logout/logout.component'
import { NewForgotPasswordComponent } from './panels/login/new-forgot-password/new-forgot-password.component'
import { NewHomeLoginComponent } from './panels/login/new-home-login/new-home-login.component'
import { NewLoginComponent } from './panels/login/new-login/new-login.component'
import { NewRegisterConfirmComponent } from './panels/login/new-register-confirm/new-register-confirm.component'
import { NewRegisterComponent } from './panels/login/new-register/new-register.component'
import { NewResetPasswordComponent } from './panels/login/new-reset-password/new-reset-password.component'
import { NewUserRegistrationInviteComponent } from './panels/login/new-user-registration-invite/new-user-registration-invite.component'
import { NewUserRegistrationComponent } from './panels/login/newuserregistration/newuserregistration.component'
import { OldForgotPasswordComponent } from './panels/login/old-forgot-password/old-forgot-password.component'
import { OldLoginComponent } from './panels/login/old-login/old-login.component'
import { OldRegisterConfirmComponent } from './panels/login/old-register-confirm/old-register-confirm.component'
import { OldRegisterComponent } from './panels/login/old-register/old-register.component'
import { OldResetPasswordComponent } from './panels/login/old-reset-password/old-reset-password.component'
import { OldUserRegistrationInviteComponent } from './panels/login/old-user-registration-invite/old-user-registration-invite.component'
import { RegistrationConfirmComponent } from './panels/login/registration-confirm/registration-confirm.component'
import { RegistrationComponent } from './panels/login/registration/registration.component'
import { ResetPasswordComponent } from './panels/login/reset-password/reset-password.component'
import { SuccessAnimationComponent } from './panels/login/success-animation/success-animation.component'
import { TokenLoginAdminComponent } from './panels/login/token-login-admin/token-login-admin.component'
import { TokenLoginDirectAccessComponent } from './panels/login/token-login-direct-access/token-login-direct-access.component'
import { TokenLoginComponent } from './panels/login/token-login/token-login.component'
import { NotificationScheduleComponent } from './panels/notification-schedule/notification-schedule.component'
import { ReportsCoverSheetComponent } from './panels/reports/reports-cover-sheet/reports-cover-sheet.component'
import { ReportsIncidentSheetComponent } from './panels/reports/reports-incident-sheet/reports-incident-sheet.component'
import { ReportsInventoryComponent } from './panels/reports/reports-inventory/reports-inventory.component'
import { ReportsMainPanelComponent } from './panels/reports/reports-main-panel/reports-main-panel.component'
import { ReportsTitlePanelComponent } from './panels/reports/reports-title-panel/reports-title-panel.component'
import { ReportsTopFiveListComponent } from './panels/reports/reports-top-five-list/reports-top-five-list.component'
import { ReportsVideoConfDevicesComponent } from './panels/reports/reports-video-conf-devices/reports-video-conf-devices.component'
import { ReportsVideoConfHealthComponent } from './panels/reports/reports-video-conf-health/reports-video-conf-health.component'
import { ReportsVideoConfHuddleComponent } from './panels/reports/reports-video-conf-huddle/reports-video-conf-huddle.component'
import { ReportsVideoConfRoomsComponent } from './panels/reports/reports-video-conf-rooms/reports-video-conf-rooms.component'
import { ReportsVideoConfStatsComponent } from './panels/reports/reports-video-conf-stats/reports-video-conf-stats.component'
import { TableStylesComponent } from './panels/table-styles/table-styles.component'
import { TooltipStatsComponent } from './panels/tooltip-stats/tooltip-stats.component'
import { VideoAllIncidentsListComponent } from './panels/video/video-all-incidents-list/video-all-incidents-list.component'
import { VideoAlldevicesInfoComponent } from './panels/video/video-alldevices-info/video-alldevices-info.component'
import { VideoAlldevicesListTableComponent } from './panels/video/video-alldevices-list-table/video-alldevices-list-table.component'
import { VideoAlldevicesListComponent } from './panels/video/video-alldevices-list/video-alldevices-list.component'
import { VideoAlldevicesPanelComponent } from './panels/video/video-alldevices-panel/video-alldevices-panel.component'
import { VideoAlldevicesStatsComponent } from './panels/video/video-alldevices-stats/video-alldevices-stats.component'
import { VideoAllsitesInfoPanelComponent } from './panels/video/video-allsites-info-panel/video-allsites-info-panel.component'
import { VideoAllsitesListTableComponent } from './panels/video/video-allsites-list-table/video-allsites-list-table.component'
import { VideoAllsitesListComponent } from './panels/video/video-allsites-list/video-allsites-list.component'
import { VideoAllsitesPanelComponent } from './panels/video/video-allsites-panel/video-allsites-panel.component'
import { VideoAllsitesStatsPanelComponent } from './panels/video/video-allsites-stats-panel/video-allsites-stats-panel.component'
import { VideoDeviceInfoComponent } from './panels/video/video-device-info/video-device-info.component'
import { VideoDevicePanelComponent } from './panels/video/video-device-panel/video-device-panel.component'
import { VideoDeviceStatsComponent } from './panels/video/video-device-stats/video-device-stats.component'
import { VideoSiteDevicesListTableComponent } from './panels/video/video-site-devices-list-table/video-site-devices-list-table.component'
import { VideoSiteInfoPanelComponent } from './panels/video/video-site-info-panel/video-site-info-panel.component'
import { VideoSiteListPanelComponent } from './panels/video/video-site-list-panel/video-site-list-panel.component'
import { VideoSitePanelComponent } from './panels/video/video-site-panel/video-site-panel.component'
import { VideoSiteStatsPanelComponent } from './panels/video/video-site-stats-panel/video-site-stats-panel.component'
import { VideoTouchPanelComponent } from './panels/video/video-touch-panel/video-touch-panel.component'
import { DateTimeZonePipe } from './pipes/display-date.pipe'
import { DisplayEmptyPipe } from './pipes/display-empty.pipe'
import { DocumentFileSizePipe } from './pipes/document-file-size.pipe'
import { DocumentsFilterPipe } from './pipes/documents-filter.pipe'
import { FileTypeIconPipe } from './pipes/file-type-icon.pipe'
import { FilterTeamsMeetingsPipe } from './pipes/filter-teams-meetings.pipe'
import { FilterPipe } from './pipes/filter.pipe'
import { LongNumberPipe } from './pipes/long-number.pipe'
import { LongNumbersPipe } from './pipes/long-numbers.pipe'
import { MainMenuFilterPipe } from './pipes/main-menu-filter.pipe'
import { QosRoundPipe } from './pipes/qos-round.pipe'
import { RenameFoldersFilesPipe } from './pipes/rename-folders-files.pipe'
import { ReversePipe } from './pipes/reverse.pipe'
import { SafePipe } from './pipes/safe.pipe'
import { TeamsMainMenuFilterPipe } from './pipes/teams-main-menu-filter.pipe'
import { TransformTimeInGivenZoneToAnotherPipe } from './pipes/transform-time-in-given-zone-to-another.pipe'
import { TwoDecimalsPipe } from './pipes/two-decimals.pipe'
//import { AppIGXModule } from './igx.module';
import { VideoIncidentsFilterPipe } from './pipes/video-incidents-filter.pipe'
import { WholeNumberPipe } from './pipes/whole-number.pipe'
import { AccountComponent } from './popups/account/account.component'
import { AddRoomComponent } from './popups/add-room/add-room.component'
import { AddUserComponent } from './popups/add-user/add-user.component'
import { AdminToolsComponent } from './popups/admin-tools/admin-tools.component'
import { AudioDeviceCallHistoryComponent } from './popups/audio-device-call-history/audio-device-call-history.component'
import { AudioMicListComponent } from './popups/audio-mic-list/audio-mic-list.component'
import { AudioMicPluginStatusComponent } from './popups/audio-mic-plugin-status/audio-mic-plugin-status.component'
import { AudioSpeakerPluginStatusComponent } from './popups/audio-speaker-plugin-status/audio-speaker-plugin-status.component'
import { BluejeansPopupComponent } from './popups/bluejeans-popup/bluejeans-popup.component'
import { CallsThisMonthComponent } from './popups/calls-this-month/calls-this-month.component'
import { CameraPluginInStatusComponent } from './popups/camera-plugin-in-status/camera-plugin-in-status.component'
import { CiscoPopupComponent } from './popups/cisco-popup/cisco-popup.component'
import { CloudCallsMonthComponent } from './popups/cloud-calls-month/cloud-calls-month.component'
import { CloudRebootPlugInButtonComponent } from './popups/cloud-reboot-plug-in-button/cloud-reboot-plug-in-button.component'
import { CloudRecordingsComponent } from './popups/cloud-recordings/cloud-recordings.component'
import { CloudUserRebootComponent } from './popups/cloud-user-reboot/cloud-user-reboot.component'
import { CollectorOfflineNotificationsComponent } from './popups/collector-offline-notifications/collector-offline-notifications.component'
import { CreateSiteComponent } from './popups/create-site/create-site.component'
import { CreateVirtualSiteComponent } from './popups/create-virtual-site/create-virtual-site.component'
import { DesktopPluginDisplayMessageComponent } from './popups/desktop-plugin-display-message/desktop-plugin-display-message.component'
import { DeviceCallHistoryComponent } from './popups/device-call-history/device-call-history.component'
import { DeviceHealthHistoryComponent } from './popups/device-health-history/device-health-history.component'
import { DevicePluginStatusComponent } from './popups/device-plugin-status/device-plugin-status.component'
import { IncidentMessagesComponent } from './popups/dialog/incident-messages/incident-messages.component'
import { DownloadIconsComponent } from './popups/download-icons/download-icons.component'
import { EditProfileComponent } from './popups/edit-profile/edit-profile.component'
import { EditSiteComponent } from './popups/edit-site/edit-site.component'
import { EnterPinComponent } from './popups/enter-pin/enter-pin.component'
import { GetCollectorInfoComponent } from './popups/get-collector-info/get-collector-info.component'
import { InstallZoomPluginComponent } from './popups/install-zoom-plugin/install-zoom-plugin.component'
import { IntegrationsComponent } from './popups/integrations/integrations.component'
import { IotDeviceHealthHistoryComponent } from './popups/iot-device-health-history/iot-device-health-history.component'
import { JabraPopupComponent } from './popups/jabra-popup/jabra-popup.component'
import { LoaderComponent } from './popups/loader/loader.component'
import { LogitechFirmwareUpdateComponent } from './popups/logitech-firmware-update/logitech-firmware-update.component'
import { LogitechRescanComponent } from './popups/logitech-rescan/logitech-rescan.component'
import { MainMenuMembershipComponent } from './popups/main-menu-membership/main-menu-membership.component'
import { MainMenuComponent } from './popups/main-menu/main-menu.component'
import { NotAvailableComponent } from './popups/not-available/not-available.component'
import { PlantronicsPopupComponent } from './popups/plantronics-popup/plantronics-popup.component'
import { PolycomPopupComponent } from './popups/polycom-popup/polycom-popup.component'
import { PopupContainerComponent } from './popups/popup-container/popup-container.component'
import { QosTestComponent } from './popups/qos-test/qos-test.component'
import { RebootDeviceComponent } from './popups/reboot-device/reboot-device.component'
import { ReportsComponent } from './popups/reports/reports.component'
import { RescanComponent } from './popups/rescan/rescan.component'
import { ScheduledMeetingsSiteComponent } from './popups/scheduled-meetings-site/scheduled-meetings-site.component'
import { ScheduledMeetingsZoomComponent } from './popups/scheduled-meetings-zoom/scheduled-meetings-zoom.component'
import { ScheduledMeetingsComponent } from './popups/scheduled-meetings/scheduled-meetings.component'
import { SlackPopupComponent } from './popups/slack-popup/slack-popup.component'
import { SolarwindsPopupComponent } from './popups/solarwinds-popup/solarwinds-popup.component'
import { SpeakerConfirmationComponent } from './popups/speaker-confirmation/speaker-confirmation.component'
import { SpeakerListComponent } from './popups/speaker-list/speaker-list.component'
import { SupportFormComponent } from './popups/support-form/support-form.component'
import { VideoCameraListComponent } from './popups/video-camera-list/video-camera-list.component'
import { VideoDeviceIncidentsComponent } from './popups/video-device-incidents/video-device-incidents.component'
import { VisOnePopupTwoComponent } from './popups/vis-one-popup-two/vis-one-popup-two.component'
import { VisonePopupComponent } from './popups/visone-popup/visone-popup.component'
import { VsMenuAllRoomPluginsComponent } from './popups/vs-menu-all-room-plugins/vs-menu-all-room-plugins.component'
import { VsMenuAllSiteAndDataCollectorsComponent } from './popups/vs-menu-all-site-and-data-collectors/vs-menu-all-site-and-data-collectors.component'
import { VsMenuDesktopClientsComponent } from './popups/vs-menu-desktop-clients/vs-menu-desktop-clients.component'
import { ZoomIncidentComponent } from './popups/zoom-incident/zoom-incident.component'
import { ZoomManageServiceComponent } from './popups/zoom-manage-service/zoom-manage-service.component'
import { ZoomPopupComponent } from './popups/zoom-popup/zoom-popup.component'
import { ZoomRoomsLinkPluginComponent } from './popups/zoom-rooms/dialogs/zoom-rooms-link-plugin/zoom-rooms-link-plugin.component'
import { ZoomRoomsComponent } from './popups/zoom-rooms/zoom-rooms.component'
import { ZoomUsersComponent } from './popups/zoom-users/zoom-users.component'
//other components
import { SearchBarComponent } from './search/search-bar/search-bar.component'
import { SearchResultsComponent } from './search/search-results/search-results.component'
import { NgbDateParserFormatterService } from './services/dateformatter.service'
import { GetAudioService } from './services/get-audio.service'
import { GetCloudService } from './services/get-cloud.service'
import { GetVideoService } from './services/get-video.service'
import { VisoneSiteService } from './services/get-visone-installedsites.service'
import { IncidentMessagesService } from './services/incident-messages.service'
import { MainMenuTypeService } from './services/mainmenuType.service'
import { MembersTypeService } from './services/membersType.service'
//services
import { PopupTypeService } from './services/popupType.service'
import { SSOService } from './services/sso.service'
import { ChartTeamsDnaComponent } from './teams/chart-teams-dna/chart-teams-dna.component'
import { ChartTeamsMbpsComponent } from './teams/chart-teams-mbps/chart-teams-mbps.component'
import { TeamsRoomDeleteRoomComponent } from './teams/dialogs/teams-room-delete-room/teams-room-delete-room.component'
import { TeamsRoomLinkDesktopPluginComponent } from './teams/dialogs/teams-room-link-desktop-plugin/teams-room-link-desktop-plugin.component'
import { TeamsRoomMonitoringDisabledComponent } from './teams/dialogs/teams-room-monitoring-disabled/teams-room-monitoring-disabled.component'
import { TeamsRoomWarningModalComponent } from './teams/dialogs/teams-room-warning-modal/teams-room-warning-modal.component'
import { TeamsUserDeleteUserComponent } from './teams/dialogs/teams-user-delete-user/teams-user-delete-user.component'
import { TeamsAdminComponent } from './teams/teams-admin/teams-admin.component'
import { TeamsAllIncidentListComponent } from './teams/teams-all-incident-list/teams-all-incident-list.component'
import { TeamsCallRecordsComponent } from './teams/teams-call-records/teams-call-records.component'
import { TeamsChartIncidentListComponent } from './teams/teams-chart-incident-list/teams-chart-incident-list.component'
import { TeamsIncidentComponent } from './teams/teams-incident/teams-incident.component'
import { TeamsMainMenuComponent } from './teams/teams-main-menu/teams-main-menu.component'
import { TeamsManagementServiceComponent } from './teams/teams-management-service/teams-management-service.component'
import { TeamsPastMeetingsAndCallsComponent } from './teams/teams-past-meetings-and-calls/teams-past-meetings-and-calls.component'
import { TeamsRebootAdminComponent } from './teams/teams-reboot-admin/teams-reboot-admin.component'
import { TeamsRoomConsoleComponent } from './teams/teams-room-console/teams-room-console.component'
import { TeamsRoomDeviceHealthButtonComponent } from './teams/teams-room-device-health-button/teams-room-device-health-button.component'
import { TeamsRoomDeviceHealthComponent } from './teams/teams-room-device-health/teams-room-device-health.component'
import { TeamsRoomDeviceListPopupComponent } from './teams/teams-room-device-list-popup/teams-room-device-list-popup.component'
import { TeamsRoomIncidentComponent } from './teams/teams-room-incident/teams-room-incident.component'
import { TeamsRoomInfoComponent } from './teams/teams-room-info/teams-room-info.component'
import { TeamsRoomPanelComponent } from './teams/teams-room-panel/teams-room-panel.component'
import { TeamsRoomPathDetectionComponent } from './teams/teams-room-path-detection/teams-room-path-detection.component'
import { TeamsRoomStatsComponent } from './teams/teams-room-stats/teams-room-stats.component'
import { TeamsRoomsComponent } from './teams/teams-rooms/teams-rooms.component'
import { TeamsSpacesManagementServiceComponent } from './teams/teams-spaces-management-service/teams-spaces-management-service.component'
import { TeamsUserInfoComponent } from './teams/teams-user-info/teams-user-info.component'
import { TeamsUserPanelComponent } from './teams/teams-user-panel/teams-user-panel.component'
import { TeamsUserStatsComponent } from './teams/teams-user-stats/teams-user-stats.component'
import { TeamsUserUtilizationComponent } from './teams/teams-user-utilization/teams-user-utilization.component'
import { TeamsUsersComponent } from './teams/teams-users/teams-users.component'
import { TokenInterceptor } from './token.interceptor'
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component'
import { VideoLogitechIncidentsComponent } from './video-logitech-incidents/video-logitech-incidents.component'
import { LogitechAddDeviceDialogComponent } from './video/dialog/logitech-add-device-dialog/logitech-add-device-dialog.component'
import { PolycomAddDeviceDialogComponent } from './video/dialog/polycom-add-device-dialog/polycom-add-device-dialog.component'
import { VideoDeviceUpdateDialogComponent } from './video/dialog/video-device-update-dialog/video-device-update-dialog.component'
import { LogitechAddDeviceComponent } from './video/logitech-add-device/logitech-add-device.component'
import { LogitechAlertPanelComponent } from './video/logitech-alert-panel/logitech-alert-panel.component'
import { LogitechDeviceListComponent } from './video/logitech-device-list/logitech-device-list.component'
import { LogitechMainMenuComponent } from './video/logitech-main-menu/logitech-main-menu.component'
import { PolycomAddDeviceComponent } from './video/polycom-add-device/polycom-add-device.component'
import { PolycomDeviceListComponent } from './video/polycom-device-list/polycom-device-list.component'
import { PolycomMainMenuComponent } from './video/polycom-main-menu/polycom-main-menu.component'
import { VideoAdditionalLogitechComponent } from './video/video-additional-logitech/video-additional-logitech.component'
import { VideoAdditionalComponent } from './video/video-additional/video-additional.component'
import { VideoDeviceHealthComponent } from './video/video-device-health/video-device-health.component'
import { VideoDeviceLiveStatsComponent } from './video/video-device-live-stats/video-device-live-stats.component'
import { VideoDeviceUpdateComponent } from './video/video-device-update/video-device-update.component'
import { VideoDisplayLogitechComponent } from './video/video-display-logitech/video-display-logitech.component'
import { VideoHistoricPingComponent } from './video/video-historic-ping/video-historic-ping.component'
import { VideoLastDisconnectComponent } from './video/video-last-disconnect/video-last-disconnect.component'
import { VideoLifeTimeUsageComponent } from './video/video-life-time-usage/video-life-time-usage.component'
import { VideoLiveStatLogitechComponent } from './video/video-live-stat-logitech/video-live-stat-logitech.component'
import { VideoLogitechUptimeComponent } from './video/video-logitech-uptime/video-logitech-uptime.component'
import { VideoNotesComponent } from './video/video-notes/video-notes.component'
import { VideoRemoteSupportIntegrationComponent } from './video/video-remote-support-integration/video-remote-support-integration.component'
import { VideoRemoteComponent } from './video/video-remote/video-remote.component'
import { VideoServiceProviderStatusComponent } from './video/video-service-provider-status/video-service-provider-status.component'
import { VideoTouchPanelLogitechComponent } from './video/video-touch-panel-logitech/video-touch-panel-logitech.component'
import { VideoWellnessCheckComponent } from './video/video-wellness-check/video-wellness-check.component'
import { VideoAudioSSLDialogComponent } from './video/dialog/video-audio-ssl-dialog/video-audio-ssl-dialog.component'

export function tokenGetter() {
	return localStorage.getItem('access_token')
}

@NgModule({
	entryComponents: [
		IotComponent,
		TableHealthIconComponent,
		TableOnCallIconComponent,
		TableSiteTypeIconComponent,
		TableCogIconComponent,
		TableStatusIconComponent,
		TableQosIconComponent,
		TableZoomOnCallComponent,
		TableColorRowComponent,
		WebportalProtocolConfigDialogComponent,
		AddIotDomotzDialogComponentComponent,
		DeleteSiteDialogComponent,
		LinkDesktopPluginDialogComponent,
		LinkDesktopPluginHostDialogComponent,
		DeleteRoomDialogComponent,
		DocumentCenterDialogComponent,
		VideoDeviceUpdateDialogComponent,
		TeamsRoomWarningModalComponent,
		TeamsRoomLinkDesktopPluginComponent,
		ZoomRoomsLinkPluginComponent,
		TeamsRoomMonitoringDisabledComponent,
		TeamsRoomDeleteRoomComponent,
		TeamsUserDeleteUserComponent,
		IotLiteDomotzErrorMessageComponent,
		IotLiteAddDeviceDialogComponent
	],
	declarations: [
		AppComponent,
		HealthBarComponent,
		NotificationScheduleComponent,
		FilterTableComponent,
		ComponentsAllComponent,
		TableHealthIconComponent,
		TableOnCallIconComponent,
		TableSiteTypeIconComponent,
		VideoSitePanelComponent,
		AudioAllDevicesPanelComponent,
		AudioDevicePanelComponent,
		CloudAllUsersPanelComponent,
		CloudUserPanelComponent,
		CloudRoomPanelComponent,
		CloudAllRoomsPanelComponent,
		BtnRegStatsComponent,
		BtnLongMeetingsComponent,
		BtnQosComponent,
		BtnApiComponent,
		BtnQosDisabledComponent,
		BtnDeviceHealthComponent,
		BtnDeviceZoomComponent,
		BtnDeviceLogitechComponent,
		BtnDeviceScreenComponent,
		BtnVolumeComponent,
		BtnDeviceCameraZoomComponent,
		BtnUtilizationComponent,
		BtnUtilComponent,
		BtnInfoComponent,
		BtnAudioFirmwareUsersComponent,
		BtnAudioFirmwareDevicesComponent,
		BtnStatCallComponent,
		BtnTotalTalkComponent,
		SystemHealthComponent,
		SystemTempComponent,
		ChartTwentyFourComponent,
		ChartAudioTwentyfourhoursComponent,
		InfoStatusComponent,
		BtnRoundedComponent,
		BtnBluetoothComponent,
		InfoUptimeComponent,
		DeviceNotesComponent,
		ScheduledMeetingsComponent,
		ScheduledMeetingsSiteComponent,
		DeviceHealthHistoryComponent,
		DeviceCallHistoryComponent,
		QosTestComponent,
		AddRoomComponent,
		AddUserComponent,
		EditProfileComponent,
		ChartAudioComponent,
		ChartAudioTwentyfourhoursDnaComponent,
		ChartSiteComponent,
		SlackSlideoutComponent,
		BtnAudioLiveStatsComponent,
		PopupContainerComponent,
		TableStylesComponent,
		TableCogIconComponent,
		VideoAllsitesStatsPanelComponent,
		VideoAllsitesPanelComponent,
		VideoAllsitesInfoPanelComponent,
		InfoDetailsComponent,
		VideoAllsitesListComponent,
		RebootDeviceComponent,
		MainMenuComponent,
		PolycomPopupComponent,
		CiscoPopupComponent,
		ZoomPopupComponent,
		BluejeansPopupComponent,
		VisonePopupComponent,
		HealthBlockComponent,
		HomepageComponent,
		VideoSiteStatsPanelComponent,
		VideoSiteInfoPanelComponent,
		VideoSiteListPanelComponent,
		VideoSitePanelComponent,
		VideoDeviceInfoComponent,
		VideoDeviceStatsComponent,
		VideoDevicePanelComponent,
		VideoAlldevicesPanelComponent,
		VideoAlldevicesListComponent,
		VideoAlldevicesInfoComponent,
		VideoAlldevicesStatsComponent,
		PanelToolsComponent,
		BtnHealthComponent,
		MainMenuMembershipComponent,
		JabraPopupComponent,
		PlantronicsPopupComponent,
		SlackPopupComponent,
		SolarwindsPopupComponent,
		ChartAudioTwentyfourhoursUserComponent,
		ChartCloudMbpsComponent,
		CloudAllRoomsInfoComponent,
		CloudAllRoomsListComponent,
		CloudAllIncidentsListComponent,
		VideoAllIncidentsListComponent,
		CloudAllRoomsStatsComponent,
		TableStatusIconComponent,
		AudioAllDevicesListComponent,
		AudioAllDevicesInfoComponent,
		AudioAllDevicesStatsComponent,
		AudioDeviceListComponent,
		AudioDeviceInfoComponent,
		AudioDeviceStatsComponent,
		LoginComponent,
		AdminComponent,
		BtnRegAudioUserComponent,
		CloudRoomInfoComponent,
		CloudRoomStatsComponent,
		CloudUserStatsComponent,
		CloudUserInfoComponent,
		CloudAllUsersInfoComponent,
		CloudAllUsersListComponent,
		CloudAllUsersStatsComponent,
		CloudLiveCallListComponent,
		CloudUserConnectedDevicesComponent,
		CloudRoomAnalyticsComponent,
		CloudRecordingsComponent,
		RegistrationComponent,
		NewUserRegistrationComponent,
		ForgotPasswordComponent,
		CreateSiteComponent,
		CreateVirtualSiteComponent,
		EnterPinComponent,
		SupportFormComponent,
		CloudCallsMonthComponent,
		ChartCloudDnaComponent,
		ChartVideoDnaComponent,
		BtnRegSoftphoneComponent,
		AccountComponent,
		RegistrationConfirmComponent,
		BtnDeviceCameraHealthComponent,
		InfoSiteDetailsComponent,
		NotAvailableComponent,
		ReportsComponent,
		WholeNumberPipe,
		TwoDecimalsPipe,
		LongNumberPipe,
		VideoIncidentsFilterPipe,
		BtnRegStatsTwodecComponent,
		ResetPasswordComponent,
		TableQosIconComponent,
		BtnRegStatsNotComponent,
		RescanComponent,
		AdminToolsComponent,
		ReportsMainPanelComponent,
		ReportsTitlePanelComponent,
		ReportsTopFiveListComponent,
		ReportsVideoConfHealthComponent,
		ReportsVideoConfHuddleComponent,
		ReportsVideoConfStatsComponent,
		ReportsVideoConfRoomsComponent,
		ReportsVideoConfDevicesComponent,
		ReportsCoverSheetComponent,
		ReportsIncidentSheetComponent,
		ReportsInventoryComponent,
		TokenLoginComponent,
		InstantLoginComponent,
		EditSiteComponent,
		TableZoomOnCallComponent,
		BtnBitrateComponent,
		TableColorRowComponent,
		ScheduledMeetingsZoomComponent,
		CallsThisMonthComponent,
		LazyForDirective,
		BtnRecordingComponent,
		TooltipStatsComponent,
		AlertsPanelComponent,
		TokenLoginAdminComponent,
		ReversePipe,
		ChartQosComponent,
		LongNumbersPipe,
		TeamsCallRecordsComponent,
		CloudAllUsersListTableComponent,
		CloudAllRoomsListTableComponent,
		VideoAlldevicesListTableComponent,
		VideoAllsitesListTableComponent,
		VideoSiteDevicesListTableComponent,
		AudioAllsitesInfoPanelComponent,
		AudioAllsitesListComponent,
		AudioAllsitesListTableComponent,
		AudioAllsitesPanelComponent,
		AudioAllsitesStatsPanelComponent,
		AudioSiteDevicesListTableComponent,
		AudioSiteInfoPanelComponent,
		AudioSiteListPanelComponent,
		AudioSitePanelComponent,
		AudioSiteStatsPanelComponent,
		AudioAllDevicesListTableComponent,
		BtnMicComponent,
		BtnWireComponent,
		BtnSignalComponent,
		BtnServiceComponent,
		BtnCheckCrossComponent,
		BtnHandsetComponent,
		BtnCallDurationComponent,
		ChartAudio60MinViewComponent,
		ChartAudioDnaComponent,
		AudioDeviceCallHistoryComponent,
		CollectorOfflineNotificationsComponent,
		DevicePluginStatusComponent,
		CameraPluginInStatusComponent,
		CloudRebootPlugInButtonComponent,
		AudioMicPluginStatusComponent,
		AudioSpeakerPluginStatusComponent,
		AudioMicListComponent,
		VideoCameraListComponent,
		SpeakerListComponent,
		SpeakerConfirmationComponent,
		LoaderComponent,
		InstallZoomPluginComponent,
		PluginCloudZoomDisableComponent,
		VideoMainComponent,
		AudioMainComponent,
		CloudMainComponent,
		TokenLoginDirectAccessComponent,
		LogoutComponent,
		VideoTouchPanelComponent,
		LogitechTapPanelComponent,
		LogitechScribePanelComponent,
		VideoMonitoringComponent,
		AudioMonitoringComponent,
		DeviceStatusComponent,
		VideoSystemStatusComponent,
		DeviceCallStatusComponent,
		DeviceActionComponent,
		SimpleLoaderComponent,
		AudioSystemStatusComponent,
		SupportContactComponent,
		ZoomMonitoringComponent,
		ZoomStatusComponent,
		ZoomSystemStatusComponent,
		RecommendedActionsComponent,
		QosRoundPipe,
		VisOnePopupTwoComponent,
		DateTimeZonePipe,
		DisplayEmptyPipe,
		IotComponent,
		IotDeviceAccountConfigComponent,
		IotDeviceListComponent,
		IotDeviceEnableServiceComponent,
		IotDevicePanelComponent,
		IotDeviceStatsComponent,
		IotDeviceInfoComponent,
		IotDeviceConsoleComponent,
		IotDeviceLiveStatsComponent,
		IotDeviceHistoricPingComponent,
		IotDeviceLastEventComponent,
		IotDeviceLatestEventComponent,
		IotDeviceNotesComponent,
		IotAgentListComponent,
		IotAllAgentsComponent,
		IotAllDevicesComponent,
		IotSummaryPanelComponent,
		IotSummaryStatsComponent,
		IotSummaryLiveStatsComponent,
		IotSummaryDeviceTypesComponent,
		IotSummaryAgentListComponent,
		IotSummaryDeviceListComponent,
		HealthCardComponent,
		BadgeComponent,
		ChartHistoricPingRangeComponent,
		IotRebootDeviceComponent,
		IotDeviceHistoryComponent,
		ChartHistoricPingStatusComponent,
		WebportalProtocolConfigDialogComponent,
		IotAdminPopUpComponent,
		IotAdminAlertsComponent,
		GetCollectorInfoComponent,
		ZoomEnableServiceComponent,
		ZoomUserListComponent,
		OldLoginComponent,
		NewLoginComponent,
		NewRegisterComponent,
		OldRegisterComponent,
		NewForgotPasswordComponent,
		OldForgotPasswordComponent,
		NewHomeLoginComponent,
		NewResetPasswordComponent,
		OldResetPasswordComponent,
		NewRegisterConfirmComponent,
		OldRegisterConfirmComponent,
		NewUserRegistrationInviteComponent,
		OldUserRegistrationInviteComponent,
		CloudUserLiveStatsComponent,
		CloudUserPathDetectionComponent,
		CloudUserDeviceHealthComponent,
		CloudUserDeviceHealthButtonComponent,
		CloudUserUtilizationComponent,
		CloudUserNotesComponent,
		CloudUserConsoleComponent,
		HighlightDirective,
		DesktopPluginDisplayMessageComponent,
		CloudUserActionsComponent,
		IotDeviceHealthHistoryComponent,
		DownloadIconsComponent,
		MainMenuFilterPipe,
		VsMenuAllSiteAndDataCollectorsComponent,
		VsMenuAllRoomPluginsComponent,
		VsMenuDesktopClientsComponent,
		AddIotDomotzDialogComponentComponent,
		DeleteSiteDialogComponent,
		LinkDesktopPluginDialogComponent,
		DeleteRoomDialogComponent,
		CloudUserRebootComponent,
		ZoomManageServiceComponent,
		ZoomRoomsComponent,
		ZoomUsersComponent,
		LinkDesktopPluginHostDialogComponent,
		DocumentsComponent,
		DocumentCenterDialogComponent,
		CompanyDocumentsComponent,
		DocumentFileSizePipe,
		DocumentsFilterPipe,
		TeamsMainMenuComponent,
		ViewDocumentComponent,
		SafePipe,
		FileTypeIconPipe,
		TeamsManagementServiceComponent,
		TeamsUserPanelComponent,
		TeamsUserInfoComponent,
		TeamsUserStatsComponent,
		TeamsRoomsComponent,
		TeamsUsersComponent,
		TeamsMainMenuFilterPipe,
		TeamsUserUtilizationComponent,
		ChartTeamsDnaComponent,
		TeamsPastMeetingsAndCallsComponent,
		VideoDeviceIncidentsComponent,
		VideoDeviceUpdateComponent,
		VideoDeviceUpdateDialogComponent,
		TeamsAdminComponent,
		TeamsRebootAdminComponent,
		ChartTeamsMbpsComponent,
		LogitechFirmwareUpdateComponent,
		TeamsAllIncidentListComponent,
		TeamsChartIncidentListComponent,
		FilterTeamsMeetingsPipe,
		ZoomIncidentComponent,
		TeamsRoomPanelComponent,
		TeamsRoomStatsComponent,
		TeamsRoomInfoComponent,
		TeamsRoomDeviceHealthComponent,
		TeamsRoomDeviceHealthButtonComponent,
		TeamsRoomWarningModalComponent,
		TeamsRoomLinkDesktopPluginComponent,
		TeamsRoomDeviceListPopupComponent,
		TeamsRoomConsoleComponent,
		TeamsRoomPathDetectionComponent,
		ZoomRoomsLinkPluginComponent,
		TeamsRoomMonitoringDisabledComponent,
		TeamsIncidentComponent,
		TwoFactorAuthComponent,
		AddPhoneNumberComponent,
		TeamsSpacesManagementServiceComponent,
		TeamsRoomDeleteRoomComponent,
		TeamsUserDeleteUserComponent,
		IotLiteMainMenuComponent,
		IotLiteAddDeviceComponent,
		IotLiteDeviceListComponent,
		// IotLiteDomotzErrorMessageComponent,
		// IotLiteAddDeviceDialogComponent,
		IotDomotzSummaryListComponent,
		IotLiteSummaryListComponent,
		IotLiteDevicePanelComponent,
		IotLiteDeviceStatsComponent,
		IotLiteDeviceInfoComponent,
		IotLiteDeviceHealthHistoryComponent,
		IotLiteAdminComponent,
		IotLiteDeviceLastEventComponent,
		IotLiteDeviceLatestEventComponent,
		IotLiteDeviceNotesComponent,
		ChartVideoPacketlossComponent,
		TeamsRoomIncidentComponent,
		IotComponent,
		TableHealthIconComponent,
		TableOnCallIconComponent,
		TableSiteTypeIconComponent,
		TableCogIconComponent,
		TableStatusIconComponent,
		TableQosIconComponent,
		TableZoomOnCallComponent,
		TableColorRowComponent,
		WebportalProtocolConfigDialogComponent,
		AddIotDomotzDialogComponentComponent,
		DeleteSiteDialogComponent,
		LinkDesktopPluginDialogComponent,
		LinkDesktopPluginHostDialogComponent,
		DeleteRoomDialogComponent,
		DocumentCenterDialogComponent,
		VideoDeviceUpdateDialogComponent,
		TeamsRoomWarningModalComponent,
		TeamsRoomLinkDesktopPluginComponent,
		ZoomRoomsLinkPluginComponent,
		TeamsRoomMonitoringDisabledComponent,
		TeamsRoomDeleteRoomComponent,
		TeamsUserDeleteUserComponent,
		IotLiteDomotzErrorMessageComponent,
		IotLiteAddDeviceDialogComponent,
		FilterPipe,
		IotLiteAddDeviceWithPortsDialogComponent,
		PolycomMainMenuComponent,
		VideoDeviceLiveStatsComponent,
		PolycomAddDeviceComponent,
		PolycomDeviceListComponent,
		PolycomAddDeviceDialogComponent,
		IotLiteSiteSummaryComponent,
		VideoWellnessCheckComponent,
		IntegrationsComponent,
		SearchBarComponent,
		SearchResultsComponent,
		SuccessAnimationComponent,
		IncidentMessagesComponent,
		WellnessCheckComponent,
		TransformTimeInGivenZoneToAnotherPipe,
		IotLiteDeviceChartComponent,
		IotLiteDeviceHistoricPingComponent,
		IotLiteDeviceLiveStatsComponent,
		UserIdleDialogComponent,
		CollectorPanelComponent,
		CollectorInfoComponent,
		CollectorStatsComponent,
		CollectorLiveStatsComponent,
		CollectorPathTestComponent,
		CollectorHostUtilizationComponent,
		CollectorConsoleComponent,
		CollectorIncidentListComponent,
		CollectorNotesComponent,
		CollectorAdminComponent,
		CollectorRebootComponent,
		CollectorDocumentCenterComponent,
		CollectorListContainerComponent,
		CollectorListComponent,
		CollectorLoggingComponent,
		CollectorEditSiteComponent,
		CollectorAlertPanelComponent,
		CollectorDeleteSiteComponent,
		RenameFoldersFilesPipe,
		VideoLastDisconnectComponent,
		VideoDeviceHealthComponent,
		VideoLifeTimeUsageComponent,
		VideoNotesComponent,
		VideoRemoteComponent,
		VideoTouchPanelLogitechComponent,
		VideoDisplayLogitechComponent,
		VideoLiveStatLogitechComponent,
		VideoHistoricPingComponent,
		VideoServiceProviderStatusComponent,
		VideoRemoteSupportIntegrationComponent,
		VideoLogitechIncidentsComponent,
		LogitechMainMenuComponent,
		LogitechAddDeviceComponent,
		LogitechDeviceListComponent,
		LogitechAddDeviceDialogComponent,
		LogitechRescanComponent,
		VideoAdditionalComponent,
		VideoAdditionalLogitechComponent,
		VideoLogitechUptimeComponent,
		LogitechAlertPanelComponent,
		IotAllSpacePanelComponent,
		IotSpacePanelComponent,
		IotSpaceSummaryListComponent,
		AddSpaceDomotzDialogComponent,
		LinkDeviceSpaceDialogComponent,
		IotSpaceListComponent,
		ScheduleRecurrenceComponent,
		ResetNotificationScheduleComponent,
		FlushDnsComponent,
		ClearTeamCacheComponent,
		ReAuthorizeComponent,
		PluginLoggingComponent,
		VideoAudioSSLDialogComponent,
	],
	imports: [
		NgbModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		ToastContainerModule,
		ToastrModule.forRoot({ closeButton: true, autoDismiss: false, positionClass: 'inline' }),
		AppRoutingModule,
		HttpClientModule,
		CommonModule,
		FontAwesomeModule,
		ProgressBarModule,
		Ng5SliderModule,
		AppMaterialModule,
		AlertModule,
		TooltipModule,
		MatButtonModule,
		MatStepperModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		//    AppIGXModule,
		NgxIntlTelInputModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetter,
				// whitelistedDomains: ['http://127.0.01:8080']
			},
		}),
		MomentTimezonePickerModule,
		UserIdleModule.forRoot({
			// 30 mins = 1800 sec
			idle: 1800,
			timeout: 20,
			// ping: 120
		}),
		InfiniteScrollModule,
	],
	providers: [
		{
			provide: NgbDateParserFormatter,
			useClass: NgbDateParserFormatterService,
		},
		{ provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterService },
		GetVideoService,
		AuthGuard,
		PopupTypeService,
		MainMenuTypeService,
		GetAudioService,
		MembersTypeService,
		VisoneSiteService,
		DatePipe,
		GetCloudService,
		IncidentMessagesService,
		SSOService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
