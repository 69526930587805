import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'

import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: 'app-btn-device-zoom',
  templateUrl: './btn-device-zoom.component.html',
  styleUrls: ['./btn-device-zoom.component.scss'],
})
export class BtnDeviceZoomComponent implements OnChanges, OnInit, OnDestroy {
  @Input() connectedAudioMic: any;
  @Input() pluginId: number;
  @Input() pluginEnabled: number;
  @Input() pluginStatus: any;
  @Input() failOver: boolean;
  @Input() defaultDevice: string;
  @Input() logitechDevice:any[] = [];
  defaultName: string;

  inuseMic = {
    status: -1,
    name: '',
  };
  audioMicBackup: any;

  showSelectForm = false;
  data: any;
  icon: String = '../../assets/img/cloud/icon_microphone.png';

  iconTriangleGreen: String = '../../assets/img/video/triangle_green.png';
  iconTriangleRed: String = '../../assets/img/video/triangle_red.png';
  iconTriangleGrey: String = '../../assets/img/video/triangle_grey.png';
  iconTriangleOrange: String = '../../assets/img/video/traingle_orange.png';

  firmwareVersion: string = '';
  isLogitechData: boolean = false;
  ConnectionState: number = 0;

  constructor(public ds: PopupTypeService) { }

  ngOnInit() {
    this.ds.sendPopupType(0);
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connectedAudioMic && this.connectedAudioMic) {
        this.setAudioMic();
    }
  }
  getOfflineCount(){
    if(this.logitechDevice?.length){
      this.isLogitechData = true;
      for (const device of this.logitechDevice){
        if(this.inuseMic.name === device.Name){
          this.ConnectionState = device.ConnectionState;
          this.getFirmware(device);
          break;
        }
      }
    }
  }
  getFirmware(device) {
    if(device && device?.Information?.length) this.firmwareVersion = device.Information[0]?.FirmwareVersion;
  }

  setAudioMic = () => {
    const copiedArray = [...this.connectedAudioMic];
    this.defaultName = this.checkDefaultIfEmpty(this.defaultDevice);
    if (copiedArray.length !== 0) {
      this.inuseMic = this.getInuseMic(copiedArray);
      if (this.inuseMic.status === 1) {
        this.audioMicBackup = copiedArray.filter((item) => item.backup !== 0);
        this.data = {
          connectedAudioMic: copiedArray,
          pluginId: this.pluginId,
          failOver: this.failOver,
        };
      } else {
        this.inuseMic = {
          status: -1,
          name: this.defaultName,
        };
      }

    } else {
      this.inuseMic = {
        status: -1,
        name: this.defaultName,
      };
    }

  }

  checkDefaultIfEmpty = (name) => {
    if (name === null || typeof name === 'undefined' || name.trim() === '') {
      return 'No Data';
    }
    return name;
  };

  getInuseMic = (mic) => {
    const inuseMic = mic.filter((m) => m.inuse);
    if (!this.isEmpty(inuseMic)) {
      return inuseMic[0];
    }
    return {
      status: -1,
      name: this.defaultDevice,
    };
  };

  showSelectFormFunc = () => {
    this.showSelectForm = !this.showSelectForm;
  };

  iconOnHover() {
    this.icon = '../../assets/img/cloud/icon_microphone_blue.png';
  }

  iconOnLeave() {
    this.icon = '../../assets/img/cloud/icon_microphone.png';
  }

  getStatusIcon(primary) {
    const { status, name } = primary;
    if (status === 1 || name !== 'No Data') {
      return this.iconTriangleGreen;
    } else {
      return this.iconTriangleRed;
    }
  }

  isEmpty = (arr) => {
    return arr.length === 0;
  };
}
