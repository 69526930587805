<div class="pexip">
    <div class="pexip__panel-container">
        <div class="pexip__panel-header">
            <app-panel-tools></app-panel-tools>
        </div>
        <div class="pexip__panel-body">
            <div class="pexip__panel-left">
                <app-pexip-info></app-pexip-info>
            </div>

            <div class="pexip__panel-right">
                <div class="pexip__health-container">
                    <app-pexip-stats></app-pexip-stats>
                </div>
            </div>

        </div>

    </div>

</div>