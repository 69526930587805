import { throwError } from 'rxjs'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators'

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { AuthService } from './services/auth.service'
import { environment } from 'src/environments/environment'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = '';
    let oauthToken = '';
    if (localStorage && localStorage.getItem('token')) {
      token = localStorage.getItem('token');
      const  cdkUrl = environment.apiServicePathBuilder('t')('t').split('/')[2];
      if(!request.url.includes(cdkUrl)) oauthToken = localStorage.getItem('oauth-token')
    }
    //Set Authorization header for every request made
    if (request.url.search('/api/v1/plugin/') !== -1) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          ...(oauthToken && {
            'x-keycloak-token' : oauthToken
          })
        },
      });
    } else if (
      request.url.search('/api/v1/plugin/') === -1 &&
      request.url.search('amazonaws') === -1
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          ...(oauthToken && {
            'x-keycloak-token' : oauthToken
          })
        },
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
				if (error instanceof HttpErrorResponse) {
					switch (error.status) {
            case 401:
              this.auth.logout()
							break
						case 403:
							this.auth.logout()
							break
					}
				}
				return throwError(error)
			})
		)
  }
}