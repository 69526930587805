import * as echarts from 'echarts'
import { AuthService } from 'src/app/services/auth.service'

import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

@Component({
  selector: 'app-echart-video-packet-loss',
  templateUrl: './echart-video-packet-loss.component.html',
  styleUrls: ['./echart-video-packet-loss.component.scss']
})
export class EchartVideoPacketLossComponent implements OnInit {

  @ViewChild('packetlossChart') chartElement: ElementRef
  chartInstance: echarts.ECharts

  ctx: any
  myChartSite: any
  currentChart = '24h';
  id: Number = 0;
  chartApiData: any
  loading: Boolean = false;
  // ... keep other existing properties except chartData ...

  // Replace chartData with ECharts option
  chartOptions: echarts.EChartsOption = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Total Loss', 'RX-Loss', 'TX-Loss']
    },
    grid: {
      left: '12%',
      right: '5%',
      bottom: '24%', // Adjust to give labels more room
      top: '10%'
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: (value) => echarts.time.format(new Date(value), '{yyyy}-{MM}-{dd}', false),
        rotate: 45, // Prevent overlapping labels
        hideOverlap: true
      },
      splitNumber: 6, // Reduces the number of labels displayed
    },
    yAxis: {
      type: 'value',
      min: 0
    },
    series: [
      {
        name: 'Total Loss',
        type: 'line',
        symbolSize: 8,
        itemStyle: {
          color: 'rgba(0, 128, 255, 0.8)'
        },
        data: []
      },
      {
        name: 'RX-Loss',
        type: 'line',
        symbolSize: 8,
        itemStyle: {
          color: 'rgba(3, 173, 20, 0.8)'
        },
        data: []
      },
      {
        name: 'TX-Loss',
        type: 'line',
        symbolSize: 8,
        itemStyle: {
          color: 'rgba(188, 71, 3, 0.8)'
        },
        data: []
      }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    public http: HttpClient,
    private Auth: AuthService,
  ) {
    this.route.params.subscribe((params) => {
      if (+params['id'] == this.id) {
      } else {
        this.id = +params['id']
        if (!this.loading) {
          this.fetchChartData()
        }
      }
    })
  }

  ngOnInit(): void {
  }

  // ngAfterViewInit() {
  //   const chartElement = document.getElementById('myChartSitez');
  //   this.chartInstance = echarts.init(chartElement);
  //   this.chartInstance.setOption(this.chartOptions);
  //   this.currentChart = '24h';
  // }

  // ngAfterViewInit() {
  //   // Initialize the chart using the ElementRef
  //   this.chartInstance = echarts.init(this.chartElement.nativeElement)
  //   this.chartInstance.setOption(this.chartOptions)

  //   // Add window resize listener to make the chart responsive
  //   window.addEventListener('resize', () => {
  //     if (this.chartInstance) {
  //       this.chartInstance.resize()
  //     }
  //   })
  // }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.chartElement?.nativeElement) {
        this.chartInstance = echarts.init(this.chartElement.nativeElement)
        this.chartInstance.setOption(this.chartOptions)

        // Ensure chart resizes with window changes
        window.addEventListener('resize', () => {
          if (this.chartInstance) {
            this.chartInstance.resize()
          }
        })
      } else {
        console.error('Chart element is not available yet.')
      }
    })
  }


  // custom_sort(a, b) {
  //   return new Date(a.x).getTime() - new Date(b.x).getTime()
  // }
  custom_sort(a, b) {
    return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  }

  fetchChartData() {
    this.loading = true
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService()
    const decodedToken = helper.decodeToken(token)

    const deviceDetails = {
      company_id: decodedToken.company_id,
      device_id: this.id,
      duration: 1,
    }

    this.Auth.getPacketlossChartData(deviceDetails).subscribe((data) => {
      if (!data?.response?.length) {
        console.error('Invalid API response:', data)
        this.loading = false
        return
      }

      const packetLossData = data.response[0] // Safe reference
      let packetloss = packetLossData.packetloss_total ?? []
      let packetloss_tx = packetLossData.packetloss_tx ?? []
      let packetloss_rx = packetLossData.packetloss_rx ?? []

      packetloss.sort(this.custom_sort)
      packetloss_tx.sort(this.custom_sort)
      packetloss_rx.sort(this.custom_sort)

      this.chartOptions = {
        ...this.chartOptions,
        series: [
          { ...this.chartOptions.series[0], data: packetloss },
          { ...this.chartOptions.series[1], data: packetloss_rx },
          { ...this.chartOptions.series[2], data: packetloss_tx },
        ]
      }

      console.log('Chart Series Data:', { packetloss, packetloss_rx, packetloss_tx })

      this.loadChart()
      this.loading = false
    })
  }


  // fetchChartData() {
  //   this.loading = true
  //   const token = localStorage.getItem('token')
  //   const helper = new JwtHelperService()
  //   const decodedToken = helper.decodeToken(token)

  //   const deviceDetails = {
  //     company_id: decodedToken.company_id,
  //     device_id: this.id,
  //     duration: 1,
  //   }

  //   this.Auth.getPacketlossChartData(deviceDetails).subscribe((data) => {
  //     let packetloss = data['response'][0].packetloss_total
  //     packetloss.sort(this.custom_sort)
  //     let packetloss_tx = data['response'][0].packetloss_tx
  //     packetloss_tx.sort(this.custom_sort)
  //     let packetloss_rx = data['response'][0].packetloss_rx
  //     packetloss_rx.sort(this.custom_sort)

  //     this.chartOptions.series[0].data = packetloss
  //     this.chartOptions.series[1].data = packetloss_rx
  //     this.chartOptions.series[2].data = packetloss_tx

  //     console.log('Chart Series Data:', {
  //       totalLoss: this.chartOptions.series[0].data,
  //       rxLoss: this.chartOptions.series[1].data,
  //       txLoss: this.chartOptions.series[2].data
  //     })

  //     this.loadChart()
  //     this.loading = false
  //   })
  // }

  // fetchChartData() {
  //   this.loading = true

  //   // Generate timestamps for the last hour (every 5 minutes)
  //   const now = new Date()
  //   const generateTimestamps = (minutesAgo: number) => {
  //     const date = new Date(now)
  //     date.setMinutes(date.getMinutes() - minutesAgo)

  //     // Format the date as "YYYY-MM-DD HH:mm:ssZ"
  //     return date.toISOString().replace("T", " ").replace(/\.\d+Z$/, "Z")
  //   }

  //   // Generate 12 points (last 60 minutes, 5-minute interval)
  //   const dummyPacketLoss = Array.from({ length: 12 }, (_, i) => ({
  //     x: generateTimestamps(i * 5), // Proper UTC timestamp format
  //     y: Math.random() * 10, // Random loss value (0-10)
  //   }))

  //   const dummyPacketLossRx = dummyPacketLoss.map(d => ({ x: d.x, y: d.y * 0.5 }))
  //   const dummyPacketLossTx = dummyPacketLoss.map(d => ({ x: d.x, y: d.y * 0.3 }))

  //   // Convert to ECharts format: [[timestamp, value], ...]
  //   this.chartOptions.series[0].data = dummyPacketLoss.map(d => [d.x, d.y])
  //   this.chartOptions.series[1].data = dummyPacketLossRx.map(d => [d.x, d.y])
  //   this.chartOptions.series[2].data = dummyPacketLossTx.map(d => [d.x, d.y])

  //   console.log('Chart Series Data:', {
  //     totalLoss: this.chartOptions.series[0].data,
  //     rxLoss: this.chartOptions.series[1].data,
  //     txLoss: this.chartOptions.series[2].data
  //   })

  //   this.loadChart()
  //   this.loading = false
  // }

  loadChart() {
    if (this.chartInstance) {
      console.log(this.chartApiData)
      this.chartInstance.setOption(this.chartOptions)
    }
  }
}
