import { AuthService } from 'src/app/services/auth.service'

import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'

import { GetIotService } from '../../services/get-iot.service'

export interface AgentListInterface {
  agent_name: string;
  ip: string;
  mac: string;
  device_count: number;
  impacted_devices: number;
  avg_mbps_down: number;
  avg_mbps_up: number;
  company_name: string;
}

@Component({
  selector: 'app-iot-space-summary-list',
  templateUrl: './iot-space-summary-list.component.html',
  styleUrls: ['./iot-space-summary-list.component.scss']
})
export class IotSpaceSummaryListComponent  implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() iotLiteEnabled: any;
  
  displayedColumns: string[];

  dataSource: MatTableDataSource<AgentListInterface>;
  agentList: any = [];
  pageSizeSelect: any[] = [];
  totalPageLimit = 100;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  pageSelected: any = 0;
  searchKey: String = 'null';
  refreshData: Boolean;
  @Input() filterRoute: String;
  greenIcon = '../../../assets/img/triangle_green.png';
  redIcon = '../../../assets/img/triangle_red.png';

  constructor(private getIot: GetIotService, public Auth: AuthService, private router: Router) {
    this.displayedColumns = this.isShowCompanyName()
      ? [
        'company_name',
        'agent_name',
        'status',
        'ip',
        'mac',
        'device_count',
        // 'impacted_devices',
        // 'avg_mbps_down',
        // 'avg_mbps_up',
      ]
      : [
        'agent_name',
        'status',
        'ip',
        'mac',
        'device_count',
        // 'impacted_devices',
        // 'avg_mbps_down',
        // 'avg_mbps_up',
      ];
  }

  ngOnInit() {
    this.getAllIotSpacesSummaryList();
  }
  getAllIotSpacesSummaryList = () => {
  
    console.log(
       this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        '0')
  
    this.getIot
      .getAllIotSpacesSummaryList(
        this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        '0'
      )
      .subscribe((data:any) => {
        // console.log('this.data: ',data)
      
        this.agentList = data.map( e => {
        return {
          ...e,
          health: e.health ? Math.ceil(e.health) : 0 
        }
        
        });
        
        // console.log('this.agentList: ', this.agentList)
        
        let newArr = this.agentList
        

        if (this.filterRoute == 'healthy') {
         newArr = newArr.filter( e => e.health > 99)    
        }
        
        if (this.filterRoute == 'warning') {
         newArr = newArr.filter( e => e.health > 50 &&  e.health < 99 && e.is_paused == 0)    
        }
      
        if (this.filterRoute == 'impacted') {
         newArr = newArr.filter( e => e.health < 51 && e.is_paused == 0)    
        }
        
        if (this.filterRoute == 'pause') {
         newArr = newArr.filter( e => e.is_paused)    
        }
        
        // if (this.filterRoute == 'pause') {
        
        
        // }
        

        // console.log('this.spaceList: ', newArr) // front end filter for now

        this.dataSource = new MatTableDataSource(newArr);
        this.dataSource.sort = this.sort;
      });
  };

  searchData = (key) => {
    this.searchKey = key;
    this.getAllIotSpacesSummaryList();
  };

  goToAgentDevices(agentId, agentName) {
    this.router.navigate(['iot/spaces/', agentId ]);
  }

  isShowCompanyName = () => {
    return this.Auth.accessLevel === 'SUPERADMIN';
  };
}
