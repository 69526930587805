import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthV2Service } from 'src/app/services/auth-v2.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{ email: string }>();
  @Input() message: any;
  @Input() message2: any;
  setTimer: any;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  email: string = '';

  formControl: any;
  responseMessage = 'Minimum of 8 character password';
  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImageNew2;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };

  startValidateEmail: boolean = false;
  disableBtn: boolean = false;

  getInfoData: any;
  valid = -1;
  id: number;
  sentRequest: any = false;
  resetResponseData: any;
  resetResponseFailed: any;
  resetResponseMessage: any;
  loginLink: any;

  startValidatePassword: boolean = false;
  startValidateConfirm: boolean = false;
  password: any = '';
  confirm: any = '';
  old_password: any = '';
  code:any;
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private AuthV2: AuthV2Service,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.code = params['code'];
    });
  }

  ngOnInit() {
    this.setFormControl();
    this.getInfo();
    // this.Auth.shouldShowCreateNewAccount = true;
    // this.getResetInfo();
    // this.loginLink = this.Auth.isAuthenticated() ? '/logout' : '/login';
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.message) {
    //   if (this.formControl) {
    //     this.setErrorMessageAndStatus('email', this.message.flag, 'error', this.message.text);
    //     this.disableBtn = true;
    //     this.startValidateEmail = true;
    //   }
    // }
  }

  getInfo(){
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(this.code);
    this.getInfoData = helper.decodeToken(this.code);
    if(isExpired){
      this.valid = 0;
    }
    else{
      this.valid = 1;
      this.email = this.getInfoData.email
    }
    
  }

  // getResetInfo() {
  //   this.Auth.getResetCodeInfo(this.id).subscribe((data) => {
  //     this.getInfoData = data;
  //     if (this.getInfoData.response[0].status == 'Ok') {
  //       this.valid = 1;

  //       this.email = this.getInfoData.response[0].email;
  //     } else {
  //       this.valid = 0;
  //     }
  //   });
  // }

  chnagePassword() {
    if (this.valid === 1) {
      const resetData = {
        new_password: this.password,
        old_password: this.old_password,
        code: this.code,
        email: this.getInfoData.email,
      };

      this.AuthV2.changePassword(resetData).subscribe((data) => {
        if (data.error === null) {
          this.sentRequest = true;
          this.resetResponseData = data;
          this.resetResponseFailed = 0;
          setTimeout(()=>{
            this.AuthV2.login()
          },2000)
        } else {          
            this.sentRequest = true;
            this.resetResponseData = data;
            this.resetResponseFailed = 1;
            this.resetResponseMessage = data.error
        }
      },err=>{
        this.sentRequest = true;
        this.resetResponseData = err;
        this.resetResponseFailed = 1;
        this.resetResponseMessage = err.message
      });
    }
  }

  setFormControl = () => {
    const deepCopy = {
      email: {
        flag: false,
        style: 'default',
        message: '',
      },
      password: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      confirm: {
        style: this.password === this.confirm && this.password.length !== 0 ? 'success' : 'default',
        message: ' ',
        flag: false,
      },
      old_password: {
        style: 'default',
        message: ' ',
        flag: false,
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.chnagePassword();
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();

    if (this.isEmpty(this.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Required Field');
      errCount++;
    } else if (this.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Minimum of 8 character password');
      errCount++;
    }
    if (this.isEmpty(this.confirm)) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Required Field');
      errCount++;
    } else if (this.confirm !== this.password) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Password mismatch');
      errCount++;
    }

    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  keyEvent = (event, type) => {
    if (type === 'password') {
      this.validate();
    }
    if (type === 'confirm') {
      this.validate();
    }
  };

  emailKeyEvent = (events) => {
    if (this.startValidateEmail) {
      this.validate();
    }
  };
}
