import { Component, OnInit } from '@angular/core'
import { PexipService } from 'src/app/services/pexip.service'

@Component({
	selector: 'app-pexip-manage-service',
	templateUrl: './pexip-manage-service.component.html',
	styleUrls: ['./pexip-manage-service.component.scss'],
})
export class PexipManageServiceComponent implements OnInit {
	isLoading: boolean = false
	pexipAccount: any

	constructor(private pexipService: PexipService) {}

	ngOnInit(): void {
		this.getPexipAccount()
	}

	getPexipAccount(): void {
		this.isLoading = true
		this.pexipService.getPexipAccount().subscribe(data => {
			this.pexipAccount = data ?? { active: 1 }
			this.isLoading = false
		})
	}

	setEnabled() {
		this.pexipService.activateDeactivatePexipAccount(!this.pexipAccount?.active).subscribe(data => {})
	}
}
