<div class="video-device-incidents">
	<div *ngIf="isShowTableData" id="incident-history-list-container">
		<table class="table table-fixed device-health-history-table">
			<thead>
				<tr>
					<th class="device-health-history-thead actioned-thead">Actioned</th>
					<th class="device-health-history-thead">Incident ID</th>
					<th class="device-health-history-thead">Start Time</th>
					<th class="device-health-history-thead">End Time</th>
					<th class="device-health-history-thead-events">Details</th>
				</tr>
			</thead>
			<tbody *ngFor="let incident of incidentsData; index as idx">
				<tr scope="row">
					<td class="text-center">
						<input type="checkbox" [checked]="incident?.actioned === 1" disabled />
						<i class="fa fa-comments-o m-1" (click)="onShowMessages(incident?.incident_id, idx)"></i>
					</td>
					<td>V-{{ incident.incident_id }}</td>
					<td>{{ incident.start_time | dateTimeZone : 'MM-DD-YYYY hh:mma' }}</td>
					<td>{{ incident.end_time | dateTimeZone : 'MM-DD-YYYY hh:mma' }}</td>
					<td class="details-container">
						<div class="details-container__summary">
							<label>Summary:</label> {{ incident.summary }}
						</div>
						<div class="details-container__trigger" *ngFor="let detail of incident.event_details">
							<div><label>Trigger:</label>{{ detail.trigger }}</div>
							<div>
								<label>Start:</label>{{ detail.start_date | dateTimeZone : 'MM-DD-YYYY hh:mma'}} <label>Failed
									Polls:</label>{{ detail.pulls }}
							</div>
							<div>
								<span *ngIf="detail.end_date !== '--'"><label>End:</label>{{ detail.end_date | dateTimeZone :
									'MM-DD-YYYY hh:mma'}} </span><label>Last
									Failed Poll:</label>{{ detail.last_pull | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div *ngIf="shouldShowLoaderBtn" class="loader">
			<img src="../../../assets/img/vs_img/btn_loader.gif" alt="" />
		</div>
	</div>

	<div *ngIf="!isShowTableData && !shouldShowLoaderBtn" class="device-health-history-nodata">
		<h1>No Data Available</h1>
	</div>
	<div class="print-btn-container" *ngIf="isShowTableData">
		<div class="printer-btn">
			<i class="fa fa-print fa-2x menu-icon" (click)="printIncidentsToPdf()"></i>
		</div>
	</div>
</div>