<div class="iotlite-admin">
    <div class="iotlite-admin__comp-container">
        <div class="iotlite-admin__right-column">
            <div *ngIf="showErrMsg" class="iotlite-admin__error-message">
                <h3>{{errorMsg}}</h3>
            </div>

            <ng-container *ngIf="showPortsForm">
                <div *ngIf="showSearching" class="iotlite-admin__right-column-item">
                    <ul class="bottom">
                        <li>
                            <h3>Searching</h3>
                        </li>
                        <li>
                            <div class="dot1"></div>
                        </li>
                        <li>
                            <div class="dot2"></div>
                        </li>
                        <li>
                            <div class="dot3"></div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="showMsg30Sec && !showErrMsg">
                    <p style="font-size: larger;">It’s taking longer than expected, but we’ll get there as fast as we
                        can...</p>
                </div>
                <div *ngIf="showErrorMessage">
                    <h3 style="color:red;">{{errorMessage}}</h3>
                </div>
                <!-- <div>Note: Updating will repla</div> -->
                <div *ngIf="showAddDevice" class="iotlite-admin__right-column-item">

                    <div class="iotlite-admin__ports-container">
                        <div class="iotlite-admin__ports-container-left" *ngIf="iot_id">
                            <h5>
                                Current Ports
                            </h5>

                            <div class="iot-component">
                                <table class="table">
                                    <thead>
                                        <tr></tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of oldPorts">
                                            <td>
                                                <div class="circle-stat circle-green"></div>
                                            </td>
                                            <td>
                                                <div>
                                                    {{ item.port_name}}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {{ item.port_id}}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="checkbox" [checked]="item.is_monitored === 1"
                                                        [disabled]="true">
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="iotlite-admin__ports-container-left">
                            <h5>
                                New Ports
                            </h5>

                            <div class="iot-component">
                                <table class="table">
                                    <thead>
                                        <tr></tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of devicePorts; let indexOfelement=index;">
                                            <td>
                                                <div class="circle-stat circle-green"></div>
                                            </td>
                                            <td>
                                                <div>
                                                    <!-- {{ item.service.name}} -->
                                                    <input class="iot-lite-add-device__input form-control"
                                                        [(ngModel)]="item?.service.name"
                                                        (ngModelChange)="onChangePortName($event, indexOfelement)"
                                                        type="text" placeholder="Rename Port" />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {{ item.portid}}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="checkbox" [disabled]="data&&data.paused"
                                                        [checked]="item.is_monitored === 1"
                                                        (change)="onChangePortMonitored($event, indexOfelement)">
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <div *ngIf="showAddDevice">
                <h5 class="" [ngClass]="{'iotlite-admin__message': disableUpdatePortButton}">
                    {{showUpdatePortsMessage}}
                </h5>
                <ng-container *ngIf="showPortsForm">
                    <h6>Note: Special characters and spaces are not allowed. You can use "_" ex. port_name </h6>
                    <div class="iotlite-admin__btn2" *ngIf="showPortsForm">
                        <button [disabled]="disableUpdatePortButton"
                            (click)="iot_id?updatePorts():addIotDeviceAndLink()">{{this.iot_id?'Update':'Add'}}
                            Port</button>
                        <button style="background:grey" (click)="cancel()">Cancel</button>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>