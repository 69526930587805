
import * as Chart from 'chart.js'
import * as moment from 'moment-timezone'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { faCog, faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons'

import { EventEmitterGlobalService } from '../../services/event-emitter-global.service'
import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'
import { RefreshApiService } from 'src/app/services/refresh-api.service'
import { NgrokProtocolComponent } from 'src/app/ngrok-protocol/ngrok-protocol.component'

@Component({
  selector: 'app-iot-lite-device-info',
  templateUrl: './iot-lite-device-info.component.html',
  styleUrls: ['./iot-lite-device-info.component.scss']
})
export class IotLiteDeviceInfoComponent implements OnInit, OnChanges {
  @Input() iotDeviceDetails: any = [];
  @Input() iotDevicePortsDetails: any = [];
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  faPauseIcon = faPause;
  faPlayIcon = faPlay;
  make: String = '--';
  agentName: String = '--';
  displayName: String = '--';
  status: any = '';
  model: String = '--';
  ip: String = '--';
  mac: String = '--';
  timezone: String = '--';
  paused: any = '--';
  uptimeDays: any = '--';
  uptimeHours: any = '--';
  uptimeMins: any = '--';
  linkDevice: any;
  iotDeviceID: any;
  statusText: String = 'Offline';
  statusColor: String = 'status__offline';
  powerManagement: any;
  agentId: number;
  accountUrl: String;
  apiKey: String;
  shouldRecallAdminAlert: Boolean = false;
  faIcon = faUser;
  spaces:any[] = [];
  canvas: any;
  ctx: any = 0;
  historicPingRangeChart: any;
  iotDeviceStatus:string;
  showWarningStatus: any = false;
  faCogIcon: any = faCog;
  
  chartData = {
    datasets: [
      {
        label: 'Incident',
        data: [],
        backgroundColor: 'rgb(184, 58, 36, 1)',
        pointBackgroundColor: 'rgb(184, 58, 36, 1)',
        pointBorderColor: 'rgb(184, 58, 36, 1)',
        borderWidth: 1,
        showLine: false,
        hidden: false
      },
      {
        label: 'Ping Rtd',
        data: [],
        backgroundColor: 'rgb(255, 184, 0, 1)',
        pointBackgroundColor: 'rgb(211,211,211, 1)',
        pointBorderColor: 'rgb(211,211,211, 1)',
        borderColor: 'rgb(211,211,211, 1)',
        borderWidth: 1,
        hidden: false,
        fill: false,
        showLine: true,
        
      },
      {
        label: 'Packet Loss',
        data: [],
        backgroundColor: 'rgb(173,216,230, 1)',
        pointBackgroundColor: 'rgb(173,216,230, 1)',
        pointBorderColor: 'rgb(173,216,230, 1)',
        borderColor: 'rgb(173,216,230, 1)',
        borderWidth: 0,
        showLine: true,
        fill: false,
        hidden: false
      },

    ],
    labels: []
  }

  parentName: any;
  siteName: any;
  monitoredPorts: number;
  unmonitoredPorts: number;

  hostName: any;
  pause: any;
  
  darkTheme: any = true;
  
  //
  
    
  historicPingStatusList: any = [];
  isDisplayChart = true;
  HOUR_RANGE = 1;
  DAY_RANGE = 2;
  WEEK_RANGE = 3;
  MEDIAN_VIEW = 'pingRtd';
  PACKET_VIEW = 'packetRtd';
  MEDIAN_TEXT = 'Ping RTD';
  PACKETLOSS_TEXT = 'Packetloss';
  isHideMedianView = true;
  isHideBestView = true;
  isHideWorstView = true;
  isHidePacketlossView = true;
  // canvas: any;
  canvasWidth: number = 1150;
  canvasHeight: number = 300;
  // ctx: any;
  historicPingStatusChart: any;
  dateTo: String;
  dateFrom: String;
  myChartSite: any;
  tempPopupState: any;
  toggleValue: any;
  
  // paused: any;
  viewType: any;
  dateRange: any;
  
  currentChart = "24h";
  activeBtn: any;
  
  
  datasetFirstAllFilter: any;
  
  iotId: any;
  
  test1: any = false;

  constructor(
    private getIot: GetIotService,
    public ds: PopupTypeService,
    public dialog: MatDialog,
    private router: Router,
    private eventEmmit: EventEmitterGlobalService,
    private refreshApiData: RefreshApiService
  ) { }

  ngOnInit() {
    
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("iotDevicePortsDetails :", this.iotDevicePortsDetails)
    this.monitoredPorts = this.iotDevicePortsDetails?.filter(port => port.is_monitored === 1).length
    this.unmonitoredPorts = this.iotDevicePortsDetails?.filter(port => port.is_monitored === 0).length
    if (changes.iotDeviceDetails && this.iotDeviceDetails) {
      this.setData();
      this.setDataChart()
    }
    
    // chart new stuff 

    
  }
  
  setDataChart = () => {
      
    
     if (!this.test1 && this.iotDeviceDetails) {
       this.test1 = true;
      this.canvas = document.getElementById('myChartSite');
      this.ctx = this.canvas.getContext('2d');
      this.myChartSite = new Chart(this.ctx, {
        type: 'line',

        options: {
          responsive: false,
          display: true,
          fontSize: '6px',
          padding: '0px',
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0, // disables bezier curves
            }
          }
        }
      });
      

      const { pause,iot_id } = this.iotDeviceDetails;
      
      console.log('this.data: ', this.iotDeviceDetails)
      
      this.dateRange = 1;
      this.paused = pause;
      this.viewType = 'pingRtd';
      this.iotId = iot_id;
      this.currentChart = "60m";
      this.activeBtn = 0;
      this.toggleValue = 0;
      
      this.fetchChartData(1);
    }
  }
  
    fetchChartData = (duration) => {
    console.log('this iotId: ', this.iotId)
    console.log('this duration: ', duration)
    let datasetFirst;
    let datasetSecond;
    let datasetThird;
    
    this.getIot.getIotLiteDeviceChart(this.iotId, duration).subscribe( (data: any) => {
      const [res] = data.response;
      return;
      console.log('res: ', res)
      
      datasetFirst = res.incidentJson;
      datasetSecond = res.minRttJson;
      datasetThird = res.lostJson;

  
      this.chartData.datasets[0].data = datasetFirst;
      this.chartData.datasets[1].data = datasetSecond
      this.chartData.datasets[2].data = datasetThird
    // this.chartData.labels = test1;
      if(datasetFirst) {
       datasetFirst.sort(this.custom_sort);  
       
         for (let x = 0; x < datasetFirst.length; x++) {
          if (datasetFirst[x].y < 0) {
            datasetFirst[x].y = null;
          }
        }
    
      }
      
      if (datasetSecond) {
       datasetSecond.sort(this.custom_sort);  
       for (let x = 0; x < datasetSecond.length; x++) {
        if (datasetSecond[x].y === 0) {
          datasetSecond[x].y = null;
        }
        }
      }
      
      if (datasetSecond) {
       datasetThird.sort(this.custom_sort);  
       for (let x = 0; x < datasetThird.length; x++) {
        if (datasetSecond[x].y === 0) {
            datasetSecond[x].y = null;
          }
        }
      }
      
      
      
    
    console.log(' this.chartData: ',  this.chartData)
    
    
    
    this.datasetFirstAllFilter = datasetFirst;
  
    if (this.viewType == 'pingRtd') {
      console.log('hide data set for pingRtd')
      // this.chartData.datasets[1].hidden = !('pingRtd' == this.viewType);
      // this.chartData.datasets[2].hidden = !('pingRtd' == this.viewType);
    }
    
    this.loadChart();
      
      //FUNCTION
    }, (err) => {
    } )
    
   
    
  }
  
    loadChart = () => {
  
    this.myChartSite.options.scales = {
      xAxes: [{
        type: "time",
        display: true,
        scaleLabel: {
          display: true,
          labelString: this.currentChart == "7d" ? "Date" : "Time",
        },
        time: {
          unit: this.currentChart == "7d" ? "day" : this.currentChart == "24h" ? "hour" : "minute"
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
    };
    
     this.myChartSite.options.tooltips = {
      callbacks: {
        label: function (tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            return "ID: " + chart.datasets[0].data[tooltipItem.index].incident_id;
          }
          else {
            return tooltipItem.yLabel
          }
        },
        beforeLabel: function (tooltipItem, chart) {
        
        console.log('beforeLabel tooltipItemL:  ', tooltipItem)
        console.log(' beforeLabelchart:  ', chart)
        
          switch (tooltipItem.datasetIndex) {
            case 0:
              return "Incidents";
            case 1:
              return chart.datasets[1].label;
            // return "mbps";
            case 2:
              return chart.datasets[2].label;

            case 3:
              return "Packetloss";
          }
        },
        afterLabel: function (tooltipItem, chart) {
        
        console.log('tooltipItemL:  ', tooltipItem)
        console.log('chart:  ', chart)
        
          if (tooltipItem.datasetIndex == 0 && chart.datasets[0].data[tooltipItem.index].triggers) {
            let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(" - ");
            triggers.splice(0, 1);
            return triggers;
          }
          else {
            return ""
          }
        },
        title: function (tooltipItem, chart) {
          return moment(tooltipItem[0].xLabel).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        },
      }
    }
    
    
    
    this.myChartSite.data.datasets = this.chartData.datasets;
    // this.myChartSite.data.labels = this.chartData.labels;
    
    console.log('myChartSite update: ',this.myChartSite)
    
    this.myChartSite.update({ duration: 500 });
  }
  
  
    
  custom_sort = (a, b) => {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }
  


  



//CHART NEW UP

  setData = () => {
    const {
      make,
      iot_name,
      model,
      iot_ip,
      mac_address,
      uptime,
      timezone,
      paused,
      iot_id,
      status,
      power_management,
      agent_id,
      account_url,
      api_key,
      device_id,
      parentRoomName,
      quadrant_group,
      site_name,
      site_id,
      host_name,
      pause,
      spaces
    } = this.iotDeviceDetails;

    this.pause = pause;
    this.spaces = this.spaces?.length?JSON.parse(spaces):[];
    this.siteName = site_name,
    this.parentName = parentRoomName;
    this.make = make;
    this.displayName = iot_name;
    this.model = model;
    this.ip = iot_ip;
    this.mac = mac_address;
    this.timezone = timezone;
    this.paused = paused;
    this.iotDeviceID = iot_id;
    this.status = status;
    this.powerManagement = power_management;
    this.agentId = agent_id;
    this.accountUrl = account_url;
    this.apiKey = api_key;
    this.hostName = host_name
    this.linkDevice = {
      parentRoomName,
      device_id,
      quadrant_group,
      site_id
    };
    this.calculateUpTime(uptime);
    // this.setDisplayStatus();
    this.setDeviceStatus();

  };

  calculateUpTime = (time) => {
    const dateNow = Date.now();
    const convertedTime = new Date(time).getTime();
    const calculatedDate = dateNow - convertedTime;

    this.uptimeHours = new Date(calculatedDate).getHours();
    this.uptimeMins = new Date(calculatedDate).getMinutes();
    this.uptimeDays = new Date(calculatedDate).getDay();
  };

  setPaused = () => {
    const flag = this.setFlag(this.pause);
    this.pause = flag;
    this.getIot.setIotDevicePaused(this.iotDeviceID, flag).subscribe(
      (data: any) => {
        this.eventEmitter.emit('callGetIotDeviceDetails');
        this.refreshApiData.refreshStatsEvent.emit('iot')
      },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  setDisplayStatus = () => {
    if (this.pause) {
      this.statusText = 'Paused';
      this.statusColor = 'status__paused';
    } else {
      if (this.status == 1) {
        this.statusText = 'Online';
        this.statusColor = 'status__online';
      } else if (this.status == 0) {
        this.statusText = 'Offline';
        this.statusColor = 'status__offline';
      }
    }

  };
  setDeviceStatus = () => {
    const data =  {...this.iotDeviceDetails};
    if( data.status == 1 && data.pause == 0 && data.collector_status != -1 ) {
      this.iotDeviceStatus = 'Online'
    }
    
    if( data.status == -1 && data.pause == 0 && data.collector_status != -1 ) {
      this.iotDeviceStatus = 'Sleep'
    }
    
    if( data.status == 0 && data.pause == 0 && data.collector_status != -1 ) {
      this.iotDeviceStatus = 'Offline'
    }
    
    if( data.collector_status == -1 ) {
      this.iotDeviceStatus = 'Collector Offline'
    }
    
    if(data.collector_status == 2 ) {
      this.iotDeviceStatus = 'Collector Offline'
      this.showWarningStatus = true;
    }
    
    if( data.is_direct_access  == 1 &&  data.direct_access_info?.isEnable == 1 ) {
      this.iotDeviceStatus = 'Teleportivity'
      this.showWarningStatus = true;
    }
    
    if(data.pause === 1) {
      this.iotDeviceStatus = 'Paused'
    }
  
  }

  isShowReboot = () => {
    return false;
  };

  isShowWakeUp = () => {
    return false;
  };

  openRebootModal = () => {
    const data = {
      iotDeviceID: this.iotDeviceID,
      agentId: this.agentId,
      accountUrl: this.accountUrl,
      apiKey: this.apiKey,
    };
    this.ds.sendPopupType(53, {
      data,
    });
  }

  wakeDevice = () => {
    this.getIot
      .wakeIotDevice(this.iotDeviceID, this.agentId, this.accountUrl, this.apiKey)
      .subscribe((data) => { });
  };

  openWebPortalOptions = () => {
    const url = `http://${this.iotDeviceDetails.iot_ip}`;
    if (this.iotDeviceDetails?.is_remote_access === 1) {
      this.dialog.open(NgrokProtocolComponent, {
        data: this.iotDeviceDetails,
      });
    } else {
      //old code
      window.open(url);
    }
  };

  sendPopupType(p) {
    if (p === 75) {
      this.ds.sendPopupType(p, {
        data: this.iotDeviceDetails,
        oldPorts: this.iotDevicePortsDetails,
        popUpEmitter: () => {
          this.eventEmitter.emit('callGetIotDeviceDetails');
        },
      });
      this.shouldRecallAdminAlert = false;
    } else {
      this.ds.sendPopupType(p);
    }
  }

  navigateToDnaPage = () => {
    const { device_id, quadrant_group, site_id } = this.linkDevice;

    if (quadrant_group == 'VIDEO') {
      this.router.navigate(['video/site/', site_id, 'videodevice', device_id]);
    }

    if (quadrant_group == 'AUDIO') {
      this.router.navigate(['audio/site/', site_id, 'audiodevice', device_id]);
    }

    if (quadrant_group == 'CLOUD') {
      this.router.navigate(['cloud/room/', device_id]);
    }
  };

  navigateToSite = () => {
    const { device_id, quadrant_group } = this.linkDevice;

    if (quadrant_group == 'VIDEO') {
      this.router.navigate(['/video/allsites']);
    }

    if (quadrant_group == 'AUDIO') {
      this.router.navigate(['/audio/allsites']);
    }

    if (quadrant_group == 'CLOUD') {
      this.eventEmmit.watcherParams('iotlitecloud');
    }
  }

  portsStatusColor: any;

  getStatus = ({ port_status, is_monitored }) => {
    let color = '';
    if (is_monitored) {
      if (port_status) {
        color = 'circle-green';
      } else {
        color = 'circle-red';
      }
    } else {
      color = 'circle-grey';
    }

    return color;

  };

  setIotPortStatus = ({ iot_port_id, is_monitored }) => {
    const flag = this.setFlag(is_monitored);
    console.log('iot_port_id: ', iot_port_id)
    console.log('flag: ', flag)

    this.getIot.setIotPortStatus(iot_port_id, flag).subscribe(
      (data) => {
        console.log('setIsMonitored: ', data);
      },
      (error) => {
        console.log('setIsMonitored error: ', error);

      }
    );
  };

  checkPortStatusColor = (port) => {
    if (port?.is_monitored === 1) {
      return 'circle-stat circle-green';
    } else if (port?.is_monitored === 0) {
      return 'circle-stat circle-grey';
    } else if (port?.is_monitored === -1) {
      return 'circle-stat circle-red';
    }
  }

 
  
  
  // new 
  
   

}
