import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-rounded',
  templateUrl: './btn-rounded.component.html',
  styleUrls: ['./btn-rounded.component.scss']
})
export class BtnRoundedComponent implements OnInit {
  @Input() typebtn: string = "search";

  constructor() { }

  ngOnInit() {
  }

}
