import { of } from 'rxjs'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class GetCompanyService {
  constructor(private http: HttpClient) {}

  //server global variable;
  // public company: any;
  companyChangeEvent: EventEmitter<any> = new EventEmitter();
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private handleError(error: Response | any) {
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  
  fetchCompany() {
    const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		const companyId = decodedToken.company_id

		const postData = {
			company_id: companyId,
		}

		return this.http.post<any>(this.server_url + '/api/v1/company/getCompanyInfo', postData, this.httpOptions)
  }

  // apiGetCompanyInfo() {
  //   if (this.company)
  //     return of(this.company)
  //   else 
  //     return this.fetchCompany().pipe(map((data) => {
  //       this.setCompany(data)
  //       return data
  //     }))
  // }

  // moving it to auth service
  // setCompany(data) {
  //   this.company = data
  // }

  apiGetCompanySiteInfo(siteId): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    const postData = {
      company_id: companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/company/getSiteInfo', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  apiEditSite(siteData: any) {
    const postData = {
      company_id: siteData.company_id,
      site_id: siteData.site_id,
      data: siteData.data,
      new_user: siteData.new_user
    };
    
    console.log('siteData: ', siteData)

    return this.http.post<any>(
      this.server_url + '/api/v1/company/editSite',
      postData,
      this.httpOptions
    );
  }
}
