

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { NgbCalendar, NgbDate, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { GetAudioService } from '../../services/get-audio.service'
import { GetCloudService } from '../../services/get-cloud.service'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  //  templateUrl: "./reports.component.html?v=" + new Date().getTime(),
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnChanges {
  @Input() popupState: any
  dev_server = environment.dev_server;
  title: any = 'Available on December 1st.';
  report_type: any
  report_user: any
  report_details: any
  allSiteListData: any
  siteApiData: any
  huddleApiData: any = [];
  deviceApiData: any = []
  companyApiData: any
  topFiveSiteApiData: any
  topFiveHuddleApiData: any
  topFiveDeviceApiData: any
  videoConferSiteApiData: any
  videoConferAllSiteApiData: any
  videoConferHuddleApiData: any
  videoConferAllHuddleApiData: any
  videoConferDeviceApiData: any
  inventoryDeviceApiData: any
  incidentDeviceApiData: any
  incidentAllDeviceApiData: any
  incidentSingleDeviceApiData: any
  siteHealthApiData: any
  model: NgbDateStruct
  date: { year: number, month: number }
  hoveredDate: NgbDate
  fromDate: NgbDate
  toDate: NgbDate
  genFromDate: NgbDate
  genToDate: NgbDate
  fromMonth: any
  company_id: any
  loading: any = 0;
  siteId: any = '*';
  huddleId: any = '*';
  deviceId: any = '*';
  spaceId: any = '*';
  sitename: any = "All Sites";
  huddlename: any = "All Huddle Rooms";
  sitetype: any = -1;
  huddletype: any = -1;
  devicename: any = "All Devices";
  genSitename: any = "All Sites";
  genDevicename: any = "All Sites";
  genSitetype: any = -1;
  genHuddleName: any = "All Huddle Rooms";
  genHuddleType: any = -1;
  reportUser: any
  hideService: any = 1;
  date_range_start: any
  date_range_end: any
  showPoweredBy = environment.config.showPoweredBy;
  loader = environment.config.dynamicImage.loader;

  // Cloud
  pluginId: any;
  zoomUserId: any;
  pluginName:any;
  pluginType:any;
  pluginSelection:any;;
  isSinglePlugin:any;


  listAllTitle: any = ['Sites with most incidents',
    'Sites with least incidents',
    'Most utilized sites',
    'Least utilized sites',
    'Sites with most network incidents',
    'Sites with least network incidents',
    'Highest uptime',
    'Lowest uptime'
  ];

  listAllHuddlesTitle: any = ['Huddle rooms with most incidents',
    'Huddle rooms with least incidents',
    'Most utilized huddle rooms',
    'Least utilized huddle rooms',
    'Huddle rooms with most network incidents',
    'Huddle rooms with least network incidents',
    'Highest uptime',
    'Lowest uptime'
  ];

  colAllTitle: any = ['Incident(s)', 'No. of Calls', 'Incident(s)', 'Total time'];

  listSingleTitle: any = ['Devices with most incidents',
    'Devices with least incidents',
    'Most utilized devices',
    'Least utilized devices',
    'Devices with most network incidents',
    'Devices with least network incidents',
    'Highest uptime',
    'Lowest uptime'
  ];

  colSingleTitle: any = ['Incident(s)', 'No. of Calls', 'Incident(s)', 'Total time'];
  listCol1Title: any = ['site(s)', 'Device(s)', 'huddle(s)'];


  isShowCalender: any = 0;
  isManagment: any = false;
  isStaff: any = false;
  isTopFiveTrend: any = false;
  isVideo: any = false;
  isCloud: any = false;
  isIot: any = false;
  isAudio: any = false;
  isInventory: any = false;
  isSites: any = false;
  isPlugin: any = false;
  isPluginSelected: any = false;
  isHuddleRooms: any = false;
  isShowDateRange: any = false;
  isSiteSelected: any = false;
  isSingleSite: any = false;
  isHuddleSelected: any = false;
  siteSelection: any = 0;
  huddleSelected = 0;
  deviceSelected = 0;
  today: any
  huddleHealth: any
  isDateSelected: any = false;
  optionSelected = 0;
  siteNameData = [];
  
  isPrint = 0;
  showRepFlg = 0;
  showMissingMsg = 0;
  deviceNameData = [];
  incidentAllHuddlesApiData: any

  incidentSingleHuddlepiData: any;
  videoConferSingleHuddleApiData: any;

  
    
  constructor(
    private pickerI18n: NgbDatepickerI18n,
    private calendar: NgbCalendar,
    private getCloudDataService: GetCloudService,
    private Auth: AuthService,
    private getAudioDataService: GetAudioService,
  ) {


    this.fromDate = calendar.getToday()
    this.toDate = calendar.getToday() // not used



    console.log(' this.fromDate: ',  this.fromDate)
    console.log(' this.today: ',  this.toDate)
    
     this.model = this.calendar.getToday();

  }

  ngOnChanges(changes: SimpleChanges) {
  
  }
  
  ngOnInit() {
    this.getAllSiteApiData()
    this.getHuddleApiData()

    // this.axedatacompare()



    // this.checkDateRange();
  }

  onSubmit() {
		this.genReport()
  }

  axedatacompare = () => {

      
  }
  

  getAllSiteApiData = () => {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		const companyId = {
			company_id: decodedToken.company_id,
		}

		this.Auth.getAllSiteList().subscribe((data:any) => {
			this.siteApiData = data['response']
			// console.log('this.siteApiData: ',this.siteApiData)
		})

		this.companyApiData = []
		this.Auth.getCompanyInfo().subscribe((data:any) => {
			this.companyApiData = data['response']
			// console.log('this.companyApiData: ',this.companyApiData)
			let arr = []
			for (let d in this.companyApiData) {
				arr.push(this.companyApiData[d])
			}
		})
	}
	
	getHuddleApiData = () => {
		this.huddleApiData = []
		const postData = {
			list_limit: 500,
			off_set: 0,
			search_key: '',
			service_type: 'all',
		}
		this.getCloudDataService.fetchApiCloudAllRooms(postData).subscribe(data => {
			// console.log("THis is cloud data", data);
			this.huddleApiData = data['response']
		})
	}

  // This function Clears the form on popup load
  resetForm = () => {
    this.isPrint = 0;
    this.showRepFlg = 0;
    this.showMissingMsg = 0;

    this.resetReportType();
    this.resetReportDetails();
    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();
    this.resetCalender();
    this.isManagment = false;
    this.isStaff = false;
    // (<HTMLInputElement>document.getElementById('staff')).checked = false;
  }

  resetValueReportType = () => {
    this.isTopFiveTrend = false;
    this.isVideo = false;
    this.isInventory = false;

    this.isSites = false;

  }


  reportUserChange = (event) => {

    this.resetReportType();
    this.resetReportDetails();
    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();
    this.resetCalender();

  
    if (event.target.value == "staff") {
      this.isStaff = true;
    }
  }

  
  reportTypeChange = (event) => {
    //reset everything below Report Type

    const newEvent = event.target.value;

    console.log('reportTypeChange: ',newEvent)


    this.resetReportDetails();
    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();
    this.resetCalender();
    this.resetReportType();
    this.resetValueReportType();

    if (newEvent == "top5") {
      this.isTopFiveTrend = true;

    }

    if (newEvent == "video") {
      this.isVideo = true;
    }

    if (newEvent == "cloudId") {
      this.isCloud = true;
      console.log('this.isCloud: ', this.isCloud)

    }

    if (newEvent == "audioId") {
      this.isAudio = true;
    }

    if (newEvent == "iotId") {
      this.isIot = true;
    }


    if (event.target.value == "inventory") {
      this.isInventory = true;
    }


    // this.isTopFiveTrend = (<HTMLInputElement>document.getElementById('top-five-trends')).checked;
    // this.isVideo = (<HTMLInputElement>document.getElementById('video-conf')).checked;
    // this.isInventory = (<HTMLInputElement>document.getElementById('inventory')) && (<HTMLInputElement>document.getElementById('inventory')).checked;
  }




  reportDetailsChange(event) {

    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();

    // this.isSites = (<HTMLInputElement>document.getElementById('sites')).checked;
    // this.isHuddleRooms = (<HTMLInputElement>document.getElementById('huddle-rooms'))?(<HTMLInputElement>document.getElementById('huddle-rooms')).checked:false;

  
    
    if (event.target.value == "sites") {
      this.isSites = true;

    }

    if (event.target.value == "plugin") {
      this.isPlugin = true
    }
  }

	siteIdIndex = (event) => {
		const newEvents = event.target.value

    this.resetHuddleList();
    this.resetDeviceList();
    this.resetCalender();
    this.resetDateRange();

    this.isShowDateRange = true;
    this.isSiteSelected = true;
    this.siteSelection = 0;

    if (newEvents < 0) {
    
      this.siteId = "*";
      this.sitename = "All Sites";
      this.sitetype = -1;
      this.siteSelection = 1;
      this.isSingleSite = false;
    } else {


      this.isSingleSite = true;
      this.siteId = this.siteApiData[newEvents].site_id;
      this.sitename = this.siteApiData[newEvents].site_name;
      this.sitetype = this.siteApiData[newEvents].virtual;



      if (this.isAudio) {
        console.log('get devices from audio')
        this.apiGetAudioSiteDevicebySite();
        this.siteSelection = 4;

      }

      if (this.isVideo) {
        console.log('get devices from video')
        this.getAllDeviceApiData();
        this.siteSelection = 5;
        
      }

      if (this.isIot) {
        console.log('get devices from iot')
        this.getIotSpaceListbySite()
      }

    }
    console.log('newEvents: ', newEvents)
  }

    deviceIdIndex = (event) => {
		const newEvents = event.target.value;

    this.resetCalender();
    this.resetDateRange();
    this.isShowDateRange = true;
    
    console.log('newEvents: ', newEvents)
    if (newEvents == -1) {
      this.deviceId = "*";
      this.devicename = "All Devices";
      this.deviceSelected = 1;
    } else if (newEvents >= 0) {
      if (this.isVideo) {
        this.deviceId = this.deviceApiData[newEvents].video_device_id;
        this.devicename = this.deviceApiData[newEvents].device_name;
        this.deviceSelected = 2;
      }


      if (this.isCloud) {
        this.deviceId = this.deviceApiData[newEvents].video_device_id;
        this.devicename = this.deviceApiData[newEvents].device_name;
        this.deviceSelected = 2;
      }


      if (this.isAudio) {
        this.deviceId = this.deviceApiData[newEvents].video_device_id;
        this.devicename = this.deviceApiData[newEvents].device_name;
        this.deviceSelected = 2;
      }

      if (this.isIot) {
        this.spaceId = this.deviceApiData[newEvents].space_id;
        this.devicename = this.deviceApiData[newEvents].device_name;
        this.deviceSelected = 2;
      }



    }
  }


    //will edit later
  	pluginIndex =  (event) => {
      // const newEvent = event.target.value
      //not sure why he resets everytime?
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetCalender();
    this.resetDateRange();

    this.isShowDateRange = true;
    this.isSiteSelected = true;
    this.siteSelection = 0;
    this.deviceApiData = [];
      
     console.log('pluginIndex: ',  event.target.value)
      

    
  }



  reportDeviceChange = () => {
    this.resetCalender();
  }


  resetReportType = () => {



    this.isTopFiveTrend = false;
    this.isVideo = false;
    this.isInventory = false;
    this.isCloud = false;
    this.isAudio = false;
    this.isIot = false;
    this.isPlugin = false;

    // console.log('-resetReportType')
    // console.log('-this.isCloud: ', this.isCloud)
    // console.log('-this.isPlugin: ', this.isPlugin)


    // cause ngmodel is getting crazy
    // if ((<HTMLInputElement>document.getElementById('top-five-trends')))
    //   (<HTMLInputElement>document.getElementById('top-five-trends')).checked = false;
    // if ((<HTMLInputElement>document.getElementById('video-conf')))
    //   (<HTMLInputElement>document.getElementById('video-conf')).checked = false;
    // if ((<HTMLInputElement>document.getElementById('cloudId')))
    //   (<HTMLInputElement>document.getElementById('cloudId')).checked = false;
    // if ((<HTMLInputElement>document.getElementById('audioId')))
    //   (<HTMLInputElement>document.getElementById('audioId')).checked = false;
    // if ((<HTMLInputElement>document.getElementById('iotId')))
    //   (<HTMLInputElement>document.getElementById('iotId')).checked = false;
    // if ((<HTMLInputElement>document.getElementById('inventory')))
    //   (<HTMLInputElement>document.getElementById('inventory')).checked = false;

  }

  resetReportDetails = () => {

    this.isSites = false;
    this.isHuddleRooms = false;

    if ((<HTMLInputElement>document.getElementById('sites')))
      (<HTMLInputElement>document.getElementById('sites')).checked = false;
    if ((<HTMLInputElement>document.getElementById('huddle-rooms')))
      (<HTMLInputElement>document.getElementById('huddle-rooms')).checked = false;

  }

  resetDeviceList = () => {

    this.deviceSelected = 0;
    if ((<HTMLInputElement>document.getElementById('device-list1')))
      (<HTMLInputElement>document.getElementById('device-list1')).checked = false;
    if ((<HTMLInputElement>document.getElementById('device-list2')))
      (<HTMLInputElement>document.getElementById('device-list2')).checked = false;

  }

  resetHuddleList = () => {

    this.isHuddleSelected = false;
    if ((<HTMLInputElement>document.getElementById('huddle-list1')))
      (<HTMLInputElement>document.getElementById('huddle-list1')).checked = false;
    if ((<HTMLInputElement>document.getElementById('huddle-list2')))
      (<HTMLInputElement>document.getElementById('huddle-list2')).checked = false;

  }

  resetSiteList = () => {
    this.isSiteSelected = false;
    this.siteSelection = 0;
    this.isSingleSite = false;

    if ((<HTMLInputElement>document.getElementById('site-list1')))
      (<HTMLInputElement>document.getElementById('site-list1')).checked = false;
    if ((<HTMLInputElement>document.getElementById('site-list2')))
      (<HTMLInputElement>document.getElementById('site-list2')).checked = false;

  }

 


  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  errorMessage: any = 'Invalid Dates'

  optionMissing = () => {
    this.loading = 0;
    this.showMissingMsg = 1;
    this.errorMessage = '  Please Select one option under each category before generating report and try again!'
  }

  initializeGenValue = () => {

    this.showMissingMsg = 0;
    this.loading = 1;
    this.genFromDate = this.fromDate;
    this.genToDate = this.toDate;
    this.genSitename = this.sitename;
    this.genSitetype = this.sitetype;
    this.genHuddleName = this.huddlename;
    this.genHuddleType = this.huddletype;
    this.genDevicename = this.devicename;

    console.log('genDevicename: ',this.devicename)

    this.report_user = 0;
    this.report_type = 0;

  }

  runOptionMissing = () => {

    if (this.optionSelected == 0) {
      this.optionSelected = 1;
      this.toDate = this.fromDate;
    }
    

  //  if ((<HTMLInputElement>document.getElementById('staff')).checked == true) {
  //     this.report_user = 2;
  //     this.reportUser = 'Staff Report';
  //   } else {
  //     this.optionMissing();
  //     return;
    //   }

    if (this.isStaff) {
       this.report_user = 2;
      this.reportUser = 'Staff Report';
    } else {
      this.optionMissing();
    }


    
    if (this.isTopFiveTrend) {
       this.report_type = 1;
    }

    if (this.isVideo) {
      this.report_type = 2;
    }

     if (this.isCloud) {
      this.report_type = 3;
      this.pluginId = 1; //change to test
    }

     if (this.isAudio) {
      this.report_type = 4;
    }

     if (this.isIot) {
      this.report_type = 5;
    }


    if (this.isInventory) {
      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);
      this.genFromDate = this.fromDate;
      this.genToDate = this.toDate;
      this.report_type = 18;
    }

    // if ((<HTMLInputElement>document.getElementById('top-five-trends')).checked == true) {
    //   this.report_type = 1;
    // } else if ((<HTMLInputElement>document.getElementById('video-conf')).checked == true) {
    //   this.report_type = 2;
    // } else if ((<HTMLInputElement>document.getElementById('inventory')).checked == true) {
    //   this.fromDate = this.calendar.getToday();
    //   this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);
    //   this.genFromDate = this.fromDate;
    //   this.genToDate = this.toDate;
    //   this.report_type = 3;
    // } else {
    //   this.optionMissing();
    //   return;
    // }
  
    if (this.report_type == 1 || this.report_type == 2) {
      if (this.siteSelection > 0 || this.isHuddleSelected) {
        //site selected
      } else {
        // console.log("report site selection missing");
        this.optionMissing();
        return;
      }
    }
    
    if (this.report_user == 2 && this.report_type == 2 && (this.siteId !== "*")) {
      if (this.deviceSelected > 0 || this.isHuddleSelected) {
        //device selected
      } else {
        // console.log("report device selection missing");
        this.optionMissing();
        return;
      }
    }

  }


  calculateDateDeduction = (dateCalc, dateToDeduct) => {

    // week -7
    // month -1
    // quarter - 4 month

    let deductTime = 0;
    
    if (dateToDeduct == 1) {
      
      deductTime = 7
    }

    if (dateToDeduct == 2) {
      
      deductTime = 30

    }

    if (dateToDeduct == 3) {
      
      deductTime = 120

    }




    return new Date(dateCalc).getTime()  - (deductTime * 24 * 60 * 60 * 1000);;


  }



  checkDateRange = () => {

    const getToday = this.calendar.getToday();

    //create functionms that checks the date
    const d1 = `${this.fromDate.year}/${this.fromDate.month}/${this.fromDate.day}` 
    const d2 = `${getToday.year}/${getToday.month}/${getToday.day}`

    const dateNow = new Date(d2).getTime()
    const date1 = new Date(d1).getTime()
    const date2 = this.calculateDateDeduction(d2,1)
    const date3 = this.calculateDateDeduction(d2,2)
    const date4 = this.calculateDateDeduction(d2,3)

    if (this.optionSelected == 1) {
        if (date1 > dateNow) {

        
        this.showMissingMsg = 1;
        this.errorMessage = `Date Invalid. there are no availalbe reports a head of Today time.

        Select dates that doesnt exceed the date today 
        `
        return true;

      } 
      
    }


    if (this.optionSelected == 2) {
       if (date1 > date2) {

      
      this.showMissingMsg = 1;
      this.errorMessage = `Date Invalid. there are no availalbe reports a head of Today time.

      Select dates that doesnt exceed the date today (ex From: 2024/10/06 - To:${d1} )
      `
      return true;
    } 

    }
      
   

    if (this.optionSelected == 3) {
      
    if (date1 > date3) {
      
     this.showMissingMsg = 1;
      this.errorMessage = `Date Invalid. there are no availalbe reports a head of Today time.

      Select dates that doesnt exceed the date today (ex From: 2024/10/06 - To:${d1} )
      `
      return true;

    }
      
    }

    if (this.optionSelected == 4) {
       
      console.log('here')


      if (date1 > date4) {
      
     this.showMissingMsg = 1;
      this.errorMessage = `Date Invalid. there are no availalbe reports a head of Today time.

      Select dates that doesnt exceed the date today (ex From: 2024/10/06 - To:${d1} )
      `
      return true;

      }
      
    }


    return false;


  }


  genReport = () => {

    this.initializeGenValue();
    this.runOptionMissing();
    this.getHuddleHealth();
    this.genAverageHealth();


    if (this.checkDateRange()) {
      console.log('you shall not pass!')
      return;
    }  

    // console.log('this.showMissingMsg: ', this.showMissingMsg)
    // console.log('this.genFromDate: ', this.genFromDate)
    // console.log('this.genToDate: ', this.genToDate)
    // console.log('this.genSitename: ', this.genSitename)
    // console.log('this.genSitetype: ', this.genSitetype)
    // console.log('this.genHuddleName: ', this.genHuddleName)
    // console.log('this.genHuddleType: ', this.genHuddleType)
    // console.log('this.genDevicename: ', this.genDevicename)
    // console.log('this.report_user: ', this.report_user)
    // console.log('this.report_type: ', this.report_type)
    // console.log('this.isHuddleSelected: ', this.isHuddleSelected)
    // console.log('this.deviceId: ', this.deviceId)
    // console.log('this.siteId: ', this.siteId)
    // console.log('this.isPrint: ', this.isPrint)
    // console.log('------------------------------')
    // console.log('------------------------------')



    
    // TOP 5

    if (this.report_user != 2) {
      this.optionMissing();
      console.log("ERROR");
      return;
    }


    if (this.report_type == 1 && this.siteId == "*") {
      this.genIncidents()
      this.genTopTrendAllSites()
      this.showRepFlg = 1;
      
      console.log('staff -> top 5 -> sites -> all sites')
    }
    
    if ( this.report_type == 1 && this.siteId !== "*") {
      
      console.log('staff -> top 5 -> sites -> single site')
      
      this.genIncidents();
      this.genTopTrendSingleSites();
      this.showRepFlg = 2;
    }
  
    
    // TOP 5


    // VIDEO


    if (this.report_type == 2 && this.siteId == "*") {
    
      console.log('staff -> video -> sites -> all site')
      this.genAllSiteNames();
      this.genIncidentsAllSites();
      this.genVideoConfAllSites();
      this.showRepFlg = 3;
    }
    

    if (this.report_type == 2 && this.siteId !== "*" && this.deviceId == "*") {
      
    console.log('staff -> video -> sites -> single site -> all devices')

      this.genAllDeviceNames();
      this.genIncidentsAllDevices();
      this.genVideoConfDevice();
      this.showRepFlg = 4;
    } 

    if ( this.report_type == 2 && this.siteId !== "*" && this.deviceId !== "*") {
    console.log('staff -> video -> sites -> single site -> single devices')
    
      this.genIncidentsAllDevices();
      this.genVideoConfDevice();
      this.showRepFlg = 5;
    }

    // VIDEO


    // AUDIO

    if (this.report_type == 4  && this.siteId == "*") {
      
      console.log('staff -> audio -> sites -> all site')
    
      this.genAllSiteNames();
      this.getAudioIncidentAllList();
      this.genAudioConfAllSites();
      this.showRepFlg = 9;
      

     }
    
    if (this.report_type == 4 && this.siteId !== "*" && this.deviceId == "*") {
      
    console.log('staff -> audio -> sites -> single site -> all devices')

      this.genAudioAllDeviceName();
      this.getIncidentAudioAllDeviceList();
      this.getAudioConferDevices();
      this.showRepFlg = 10;
    } 

    if ( this.report_type == 4 && this.siteId !== "*" && this.deviceId !== "*") {
    console.log('staff -> audio -> sites -> single site -> single devices')
    
      this.getIncidentAudioAllDeviceList();
      this.getAudioConferDevices();
      this.showRepFlg = 11;
    }
    
    // AUDIO

    // CLOUD

    if (this.report_type == 3 && this.siteId == "*") {
      
      console.log('staff -> cloud -> plugin -> all site')
    
      //change this later
      this.genAllSiteNames(); 
      this.genCloudIncidentsAllList();
      this.getCloudConferAllSite();
      this.showRepFlg = 6;

    }

    if (this.report_type == 3&& this.siteId !== "*" && this.deviceId == "*") {
      
      console.log('staff -> cloud -> single site ->  all room') 

      this.genCloudAllDeviceName();
      this.getIncidentCloudAllDeviceList();
      this.getCloudConferDevices();
      this.showRepFlg = 7;

    }

    if (this.report_type == 3 && this.siteId !== "*" && this.deviceId !== "*") {
      
      console.log('staff -> cloud > cloud -> single site -> single room')
      this.getIncidentAudioAllDeviceList();
      this.getAudioConferDevices();

      this.getIncidentCloudAllDeviceList();
      this.getCloudConferDevices();
      this.showRepFlg = 8;
    }

    // CLOUD

    // IOT

    if (this.report_type == 5  && this.siteId == "*") {
      
      console.log('staff -> iot -> sites -> all site')
    
      this.genAllSiteNames();
      this.genIotIncidentsAllList();
      this.getIotConferAllSite();
      this.showRepFlg = 12;

     }
    
    if (this.report_type == 5 && this.siteId !== "*"  && this.spaceId == "*" && this.deviceId == "*") {
      
    console.log('staff -> iot -> single site -> all space')

      this.genIotAllDeviceNames();
      this.genIncidentsIotAllDevices();
      this.getIotConferDevices();
      this.showRepFlg = 13;
    } 
    

    // will wait for now
    if (this.report_type == 5 && this.siteId !== "*" &&  this.spaceId != "*" && this.deviceId == "*") {
      
    console.log('staff -> audio -> iot -> single site -> single space -> all device')

      this.genIotAllDeviceNames();
      this.genIncidentsIotAllDevices();
      this.getIotConferDevices();
      this.showRepFlg = 14;
    } 



    // will wait for now
    if ( this.report_type == 5 && this.siteId !== "*" && this.deviceId !== "*") {
     console.log('staff -> audio -> iot -> single site -> single space -> single device')
    
      this.genIncidentsAllDevices();
      this.genVideoConfDevice();
      this.showRepFlg = 15;
    }

    // IOT

    // IOT DOMOTZ

    if (this.report_type == 6  && this.siteId == "*") {
      
      console.log('staff -> audio -> sites -> all site')
    
      this.genAllSiteNames();
      this.genIncidentsAllSites();
      this.genVideoConfAllSites();
      this.showRepFlg = 16;
      
     }
    
    if (this.report_type == 6 && this.siteId !== "*" && this.deviceId == "*") {
      
    console.log('staff -> audio -> sites -> single site -> all devices')

      this.genAudioAllDeviceName();
      this.getIncidentAudioAllDeviceList();
      this.genVideoConfDevice();
      this.showRepFlg = 17;
    } 

    if ( this.report_type == 6 && this.siteId !== "*" && this.deviceId !== "*") {
    console.log('staff -> audio -> sites -> single site -> single devices')
    
      this.getIncidentAudioAllDeviceList();
      this.genVideoConfDevice();
      this.showRepFlg = 18;
    }
    
    // IOT DOMOTZ

    // INVENTORY

    if ((this.report_type == 6)) {
    
      console.log('staff -> inventory')
      this.genSitename = "All Sites";
      this.genIncidentsInv();
      this.genInventory();
      this.showRepFlg = 19;
    }

    // INVENTORY
  }

  // INVENTORY API


  genInventory = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodyInventoryDevice = {
      company_id: decodedToken.company_id,
      site_id_list: '*'
    }
    this.Auth.getInventoryDevice(bodyInventoryDevice).subscribe((data) => {
      this.loading = 0;
      this.inventoryDeviceApiData = data['response'];
    });
  }

  genIncidentsInv = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
    }
    this.Auth.getIncidentList(bodyIncident).subscribe((data) => {
      this.incidentDeviceApiData = data['response'][0].data;
    });
  }

  // INVENTORY API
  
 // TO CHECK
  
  getHuddleHealth = () => {


    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.huddleHealth = [];
    this.Auth.getVideoConferAllHuddle(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.huddleHealth = data['response'];
    });

  }

 
 // TO CHECK


  

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(''), ms)).then(() => console.log('fired'));
  }

//
	
	// SITE API
	
	genAllSiteNames =  () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id_list: '*'
    }
    this.Auth.getAllSiteNameList(bodySite).subscribe((data) => {
      const [res] = data.response  
      this.siteNameData = res.data;
      
      console.log('genAllSiteNames: ', this.siteNameData)
      
    });
  }

  

    genIncidents = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

      let tmpToDate;
      
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
      }
      
    console.log('bodyIncident: ', bodyIncident)

    this.Auth.getIncidentList(bodyIncident).subscribe((data: any) => {
    
      const [newRes] = data.response;
      
      this.incidentDeviceApiData = newRes.data;

      console.log("genIncidents: ", this.incidentDeviceApiData )
    });
  }

	
	genTopTrendAllSites = () => {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyTopFiveSite = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day
    }

    this.Auth.getTopFiveSite(bodyTopFiveSite).subscribe((data: any) => {
      this.loading = 0;
      const [res] = data.response;
      this.topFiveSiteApiData = res.data
      
      console.log('genTopTrendAllSites: ',this.topFiveSiteApiData)
   
    });
  }


  genTopTrendSingleSites = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyTopFiveDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
    }



    this.Auth.getTopFiveDevice(bodyTopFiveDevice).subscribe((data: any) => {
      this.loading = 0;
      const [res] = data.response;
      this.topFiveDeviceApiData  = res.data
      
      // console.log('genTopTrendSingleSites: ',this.topFiveDeviceApiData )
    });
  }

  // SITE API
	
  // VIDEO API

   genAverageHealth = () => {
  
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day + " 00:00:00",
      date_range_end: this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day + " 23:59:59",
      site_id_list: this.siteId
    }
    
    this.Auth.getAverageHealth(bodySite).subscribe((data) => {
      const [res] = data.response  
      this.siteHealthApiData = res;
      
      console.log('this.siteHealthApiData: ',this.siteHealthApiData)
    });
  }


  getAllDeviceApiData = () => {
		this.Auth.getAllDeviceBySiteList(this.siteId).subscribe(data => {
      this.deviceApiData = data['response'].map(e => {
        return {
          ...e,
          device_name: e.device_name == "" ? "Unknown Device" : e.device_name
        }

      })
      console.log('this.deviceApiData: ', this.deviceApiData)
		})
  }

  genAllDeviceNames = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id: this.siteId,
      device_id_list: '*'
    }
    this.Auth.genAllDeviceNames(bodySite).subscribe((data) => {
      this.deviceNameData = data['response'][0].data;

      console.log('this.deviceNameData: ', this.deviceNameData)

    });
  }

  
  genIncidentsAllSites = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: '*'
    }
    this.Auth.getIncidentAllList(bodyIncident).subscribe((data) => {
      this.incidentAllDeviceApiData = data['response'];
      
      
      console.log('genIncidentsAllSites: ', this.incidentAllDeviceApiData)
    });
  }


  genIncidentsAllDevices = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }
    this.Auth.getIncidentAllDeviceList(bodyIncident).subscribe((data) => {
      this.incidentSingleDeviceApiData = data['response'];

      console.log('this.incidentSingleDeviceApiData: ', this.incidentSingleDeviceApiData)
    });
  }



  
  genVideoConfAllSites = () => {

    let tmpToDate;
    
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }

    console.log('params: ', params)

    this.Auth.getVideoConferAllSite(params).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllSiteApiData = data['response'];
      
      
      console.log('genVideoConfAllSites: ', this.videoConferAllSiteApiData )
    });
  }

  genVideoConfDevice = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }

    console.log('bodyVideoDevice: ', bodyVideoDevice)

    this.videoConferDeviceApiData = [];
    this.Auth.getVideoConferDevices(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferDeviceApiData = data['response'];

      console.log('this.videoConferDeviceApiData : ', this.videoConferDeviceApiData )

    });
  }

  // VIDEO API


  // AUDIO API

  apiGetAudioSiteDevicebySite = () => {
    this.getAudioDataService.apiGetAudioSiteDevicebySite(this.siteId).subscribe((data: any) => {
      // This is to load data into an array for in order to create a searchable filter table
        this.deviceApiData = data['response'].map(e => {
        return {
          ...e,
          device_name: !e.device_name ? e.model : e.device_name
        }

      })
      console.log('this.deviceApiData: ', this.deviceApiData)
    });

  }

  genAudioAllDeviceName = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id: this.siteId,
      device_id_list: '*'
    }
    this.Auth.genAudioAllDeviceName(bodySite).subscribe((data) => {
      this.deviceNameData = data['response'][0].data;

      console.log('this.deviceNameData: ', this.deviceNameData)

    });
  }




  getAudioConferDevices = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }

    console.log('params: ', params)

    this.videoConferDeviceApiData = [];
    this.Auth.getAudioConferDevices(params).subscribe((data) => {
      this.loading = 0;
      this.videoConferDeviceApiData = data['response'];

      console.log('getAudioConferDevices : ', this.videoConferDeviceApiData )

    });
  }


  getIncidentAudioAllDeviceList = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }


    this.Auth.getIncidentAudioAllDeviceList(params).subscribe((data) => {
      this.incidentSingleDeviceApiData = data['response'];

      console.log('this.incidentSingleDeviceApiData: ', this.incidentSingleDeviceApiData)
    });
  }


  getAudioIncidentAllList = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: '*'
    }

    console.log('getAudioIncidentAllList params: ', params)



    this.Auth.getAudioIncidentAllList(params).subscribe((data) => {
      this.incidentAllDeviceApiData = data['response'];
      
      
      console.log('getAudioIncidentAllList: ', this.incidentAllDeviceApiData)
    });
    }
  
  genAudioConfAllSites = () => {

    let tmpToDate;
    
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }

    console.log('genAudioConfAllSites params: ', params)

    this.Auth.getAudioConferAllSite(params).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllSiteApiData = data['response'];
      
      
      console.log('genAudioConfAllSites: ', this.videoConferAllSiteApiData )
    });
  }


  // AUDIO API


  // CLOUD API

   getCloudListbySite = () => {
     this.Auth.getCloudListbySite(this.siteId).subscribe((data: any) => {
       const newRes = data.response;
       this.deviceApiData = newRes;

      console.log('this.getCloudListbySite: ', this.deviceApiData)
		})
   }
  
  
  /*
    
    this.genCloudIncidentsAllList();
    this.getCloudConferAllSite();

  */
  
  genCloudIncidentsAllList = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: '*'
    }

    this.Auth.genCloudIncidentsAllList(params).subscribe((data) => {
      this.incidentAllDeviceApiData = data['response'];
      
      console.log('genCloudIncidentsAllList: ', this.incidentAllDeviceApiData)
    });
  }
  
  
  getCloudConferAllSite = () => {

    let tmpToDate;
    
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }

    this.Auth.getCloudConferAllSite(params).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllSiteApiData = data['response'];
      console.log('getCloudConferAllSite: ', this.videoConferAllSiteApiData )
    });
  }


  // this.genCloudAllDeviceName();
  // this.getIncidentCloudAllDeviceList();
  // this.getCloudConferDevices();


  genCloudAllDeviceName = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id: this.siteId,
      device_id_list: '*'
    }
    this.Auth.genCloudAllDeviceName(bodySite).subscribe((data) => {
      this.deviceNameData = data['response'][0].data;

      console.log('this.genCloudAllDeviceName: ', this.deviceNameData)

    });
  }


  getIncidentCloudAllDeviceList = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }


    console.log('this.getIncidentCloudAllDeviceList params: ', params)

    this.Auth.getIncidentCloudAllDeviceList(params).subscribe((data) => {
      this.incidentSingleDeviceApiData = data['response'];

      console.log('this.getIncidentCloudAllDeviceList: ', this.incidentSingleDeviceApiData)
    });
  }


  getCloudConferDevices = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }

    console.log('getIotConferDevices params : ', params)


    this.videoConferDeviceApiData = [];
    this.Auth.getCloudConferDevices(params).subscribe((data) => {
    this.loading = 0;
    this.videoConferDeviceApiData = data['response'];

      console.log('getCloudConferDevices : ', this.videoConferDeviceApiData )

    });
  }

  
  // getSpaceList = () => {
  //   this.getAudioDataService.apiGetAudioSiteDevicebySite(this.siteId).subscribe((data: any) => {
  //     // This is to load data into an array for in order to create a searchable filter table
  //       this.deviceApiData = data['response'].map(e => {
  //       return {
  //         ...e,
  //         device_name: !e.device_name ? e.model : e.device_name
  //       }

  //     })
  //     console.log('this.getSpaceList: ', this.deviceApiData)
  //   });
  // }


  // getSpaceDevice = () => {
  //   this.getAudioDataService.apiGetAudioSiteDevicebySite(this.siteId).subscribe((data: any) => {
  //     // This is to load data into an array for in order to create a searchable filter table
  //       this.deviceApiData = data['response'].map(e => {
  //       return {
  //         ...e,
  //         device_name: !e.device_name ? e.model : e.device_name
  //       }

  //     })
  //     console.log('this.deviceApiData: ', this.deviceApiData)
  //   });

  // }


  // CLOUD API


  // IOT API

   getIotSpaceListbySite = () => {
     this.Auth.getIotSpaceListbySite(this.siteId).subscribe((data: any) => {
       const newRes = data.response;
       this.deviceApiData = newRes;

      console.log('this.getIotSpaceListbySite: ', this.deviceApiData)
		})
   }
  
  
  /*
    
    this.genIotIncidentsAllList();
    this.getIotConferAllSite();

  */
  
  genIotIncidentsAllList = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: '*'
    }

    this.Auth.genIotIncidentsAllList(params).subscribe((data) => {
      this.incidentAllDeviceApiData = data['response'];
      
      console.log('genIotIncidentsAllList: ', this.incidentAllDeviceApiData)
    });
  }
  
  
  getIotConferAllSite = () => {

    let tmpToDate;
    
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }

    this.Auth.getIotConferAllSite(params).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllSiteApiData = data['response'];
      console.log('getIotConferAllSite: ', this.videoConferAllSiteApiData )
    });
  }


  // this.genIotAllDeviceNames();
  // this.genIncidentsIotAllDevices();
  // this.getIotConferDevices();


  genIotAllDeviceNames = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id: this.siteId,
      device_id_list: '*'
    }
    this.Auth.genIotAllDeviceName(bodySite).subscribe((data) => {
      this.deviceNameData = data['response'][0].data;

      console.log('this.genIotAllDeviceName: ', this.deviceNameData)

    });
  }


  genIncidentsIotAllDevices = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }


      console.log('this.getIncidentIotAllDeviceList params: ', params)


    this.Auth.getIncidentIotAllDeviceList(params).subscribe((data) => {
      this.incidentSingleDeviceApiData = data['response'];

      console.log('this.getIncidentIotAllDeviceList: ', this.incidentSingleDeviceApiData)
    });
  }


  getIotConferDevices = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const params = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }

      console.log('getIotConferDevices params : ', params)


    this.videoConferDeviceApiData = [];
    this.Auth.getIotConferDevices(params).subscribe((data) => {
    this.loading = 0;
    this.videoConferDeviceApiData = data['response'];

      console.log('getIotConferDevices : ', this.videoConferDeviceApiData )

    });
  }

  

  getSpaceList = () => {
    this.getAudioDataService.apiGetAudioSiteDevicebySite(this.siteId).subscribe((data: any) => {
      // This is to load data into an array for in order to create a searchable filter table
        this.deviceApiData = data['response'].map(e => {
        return {
          ...e,
          device_name: !e.device_name ? e.model : e.device_name
        }

      })
      console.log('this.getSpaceList: ', this.deviceApiData)
    });
  }


  getSpaceDevice = () => {
    this.getAudioDataService.apiGetAudioSiteDevicebySite(this.siteId).subscribe((data: any) => {
      // This is to load data into an array for in order to create a searchable filter table
        this.deviceApiData = data['response'].map(e => {
        return {
          ...e,
          device_name: !e.device_name ? e.model : e.device_name
        }

      })
      console.log('this.deviceApiData: ', this.deviceApiData)
    });

  }


  // IOT API
  
  


  //  DATE RANGE

 resetDateRange = () => {
    this.isShowDateRange = false;
    this.isDateSelected = false;
    if ((<HTMLInputElement>document.getElementById('date-range')))
      (<HTMLInputElement>document.getElementById('date-range')).value = "";
  }

  resetCalender = () => {
    this.isShowCalender = 0;
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);
  }

  setShowCalender = (event) => {


    const n = event.target.value
    
    console.log('setShowCalender: ', n)

    this.isDateSelected = true;
    this.isShowCalender = 1;
    this.isDateSelected = true;
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);


    console.log(' this.fromDate: ', this.fromDate)
    console.log(' this.toDate: ', this.toDate)

    if (n == 'day') {
      this.optionSelected = 1;
      this.toDate = this.fromDate;
    }
    
    
    if (n == 'week') {
      this.optionSelected = 2;
      if ((this.fromDate.day + 7) > 30) {
        this.toDate.day = ((this.fromDate.day + 7) - 30);
        if (this.fromDate.month == 12) {
          this.toDate.month = 1;
          this.toDate.year = this.fromDate.year + 1;
        } else {
          this.toDate.month = this.fromDate.month + 1;
          this.toDate.year = this.fromDate.year;
        }
      } else {
        this.toDate.day = this.fromDate.day + 7;
        this.toDate.month = this.fromDate.month;
        this.toDate.year = this.fromDate.year;
      }
    }
    
    if (n == 'month') {
      this.optionSelected = 3;
      // this.toDate.day = this.fromDate.day;
      this.toDate.day = this.fromDate.day;
      if (this.fromDate.month == 12) {
        this.toDate.month = 1;
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month - 1 ;
        this.toDate.year = this.fromDate.year;
      }
    }
    
    
    if (n == 'quarter') {
      this.optionSelected = 4;
      this.toDate.day = this.fromDate.day;
      if ((this.fromDate.month + 4) > 12) {
        this.toDate.month = ((this.fromDate.month + 4) - 12);
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 4;
        this.toDate.year = this.fromDate.year;
      }
    }

  }



  onDateSelection = (date) => {

    console.log('onDateSelection: ', date)

    if (this.optionSelected == 1) {

      this.fromDate = date;
      this.toDate = this.fromDate;

    }
    
    if (this.optionSelected == 2) {
      this.fromDate = date;
      if ((this.fromDate.day + 7) > 30) {
        this.toDate.day = ((this.fromDate.day + 7) - 30);
        if (this.fromDate.month == 12) {
          this.toDate.month = 1;
          this.toDate.year = this.fromDate.year + 1;
        } else {
          this.toDate.month = this.fromDate.month + 1;
          this.toDate.year = this.fromDate.year;
        }
      } else {
        this.toDate.day = this.fromDate.day + 7;
        this.toDate.month = this.fromDate.month;
        this.toDate.year = this.fromDate.year;
      }
    }

    if (this.optionSelected == 3) {
      this.fromDate = date;
      this.toDate.day = this.fromDate.day;
      if (this.fromDate.month == 12) {
        this.toDate.month = 1;
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 1;
        this.toDate.year = this.fromDate.year;
      }
    }
    
    if (this.optionSelected == 4) {
      this.fromDate = date;
      this.toDate.day = this.fromDate.day;
      if ((this.fromDate.month + 4) > 12) {
        this.toDate.month = ((this.fromDate.month + 4) - 12);
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 4;
        this.toDate.year = this.fromDate.year;
      }
    }
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  // DATE RANGE



  // PRINT 

    setPrintMode = () => {
    this.isPrint = 1;
    this.delay(1000).then(any => {
      this.isPrint = 0;
      this.printDivV2('report-main-panel');
      this.delay(1000).then(any => {
        this.isPrint = 0;
      });
    });
  }


	// Helper for printDivV2 - used to preserve styles and links for printed div
	private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
		const htmlStr: string[] = []
		const elements = document.getElementsByTagName(tagName)

		for (let idx = 0; idx < elements.length; idx++) {
			htmlStr.push(elements[idx].outerHTML)
		}

		return htmlStr.join('\r\n')
	}



    printDiv(divID) {
    //Get the HTML of div
    var divElements = document.getElementById(divID).innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;

    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
      "<html><head><title></title></head><body>" +
      divElements + "</body>";

    //Print Page
    window.print();

    //Restore orignal HTML
    document.body.innerHTML = oldPage;

    location.reload();
  }

  printDivV2(divId) {
    let printContents, stylesHtml, linksHtml, popupWin;
    printContents = document.getElementById(divId).innerHTML;
    stylesHtml = this.getTagsHtml('style');
    linksHtml = this.getTagsHtml('link');

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        ${stylesHtml}
        ${linksHtml}
        <title>Print Report</title>
        </head>
        <body onload="window.print(); window.close()">
          ${printContents}
        </body>
      </html>
    `)
		popupWin.document.close()
		this.delay(1000).then(any => {
			this.isPrint = 0
		})
  }
  
  //NOT SURE


  genVideoConfAllHuddles() {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.videoConferAllHuddleApiData = [];
    this.Auth.getVideoConferAllHuddle(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllHuddleApiData = data['response'];
    });
  }


  genVideoConfSingleHuddle() {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      room_id: this.huddleId
    }
    this.videoConferAllHuddleApiData = [];
    this.Auth.getVideoConferSingleHuddle(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferSingleHuddleApiData = data['response'];
    });
  }

    genTopTrendHuddles() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;

    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyTopFiveHuddle = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.topFiveHuddleApiData = [];
    this.Auth.getTopFiveHuddle(bodyTopFiveHuddle).subscribe((data) => {
      this.loading = 0;
      this.topFiveHuddleApiData = data['response'];
    });
  }
    
  genIncidentsSingleHuddle() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      room_id: this.huddleId
    }
    this.incidentSingleHuddlepiData = [];

    this.Auth.getIncidentSingleHuddles(bodyIncident).subscribe((data) => {
      this.incidentSingleHuddlepiData = data['response'];
    });
  }

  
  genIncidentsAllHuddles() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }

    this.Auth.getIncidentAllHuddles(bodyIncident).subscribe((data) => {
      this.incidentAllHuddlesApiData = data['response'];
    });
  }



  // VIDEO NOT USE
  
    genVideoConf = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
    }
    
    
    this.Auth.getVideoConferSite(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferSiteApiData = data['response'];
    });
  }

  // VIDEO NOT USE
}
