// remote-session.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-remote-session',
  templateUrl: './remote-session.component.html',
  styleUrls: ['./remote-session.component.scss']
})
export class RemoteSessionComponent {
  sessionUrl: string;
  sessionStartTime: Date;
  forward_url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<RemoteSessionComponent>,
    private clipboard: Clipboard
  ) {
    if(this.data){
      this.sessionUrl = this.data?.ngres?.public_url;
      this.sessionStartTime =  new Date();
      this.forward_url = this.data?.ngres?.config.addr
    }
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.sessionUrl);
  }

  handleClose(): void {
    this.dialogRef.close();
  }
}