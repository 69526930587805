<div class="reports-video-conf-health">

  <div class="reports-video-conf-health__container-left-panel">
    <h4>Total number of incidents</h4>

    <div class="reports-video-conf-health__container reports-video-conf-health__border-bottom">
      <div class="reports-video-conf-health__container-item-top">
        <h1>{{videoConferSite?.total_incidents.total_incidents}}</h1>
      </div>

      <div class="reports-video-conf-health__container-item-bottom">
        <div *ngIf="videoConferSite?.total_incidents.difference <= -100" class="report-oval float-right text-success">
          <h5 class="text-center report-oval-number">{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div
          *ngIf="videoConferSite?.total_incidents.difference<0 && videoConferSite?.total_incidents.difference > -100 "
          class="report-circle float-right text-success">
          <h5 class="text-center report-circle-number">{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference>0 && videoConferSite?.total_incidents.difference < 100"
          class="report-circle float-right text-danger">
          <h5 class="text-center report-circle-number">+{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference >= 100" class="report-oval float-right text-danger">
          <h5 class="text-center report-oval-number">+{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference==0" class="report-circle float-right text-dark">
          <h5 class="text-center report-circle-number">--</h5>
        </div>
      </div>

    </div>

    <h4>Avg. time to restore service</h4>

    <div class="reports-video-conf-health__container">
      <div class="reports-video-conf-health__container-item-top">
        <h1 class="report-bold">{{videoConferSite?.avg_restore_time}}</h1>
        <h6 class="font-weight-bold">min</h6>
      </div>

      <div class="reports-video-conf-health__container-item-bottom">
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend <= -100"
          class="report-circle float-right text-success">
          <h5 class="text-center report-circle-number">{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div
          *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend < 0 && videoConferSite?.restore_time_trend  >-100"
          class="report-circle float-right text-success">
          <h5 class="text-center report-circle-number">{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div
          *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend > 0 && videoConferSite?.restore_time_trend < 100"
          class="report-circle float-right text-danger">
          <h5 class="text-center report-circle-number">+{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend >= 100"
          class="report-oval float-right text-danger">
          <h5 class="text-center report-oval-number">+{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend == 0"
          class="report-circle float-right text-dark">
          <h5 class="text-center report-circle-number">--</h5>
        </div>
      </div>

    </div>
  </div>



  <div class="reports-video-conf-health__container-right-panel">
    <h4>Health SLA score</h4>


    <div class="reports-video-conf-health__container">

      <div class="reports-video-conf-health__container-item-top-health">
        <h2>Calls: {{videoConferSite?.total_calls.calls}}</h2>
        <h2>Usage (d/h/m): {{videoConferSite?.total_usage.usage}}</h2>
      </div>

      <div class="reports-video-conf-health__container-item-body-health">
        <h4 *ngIf="videoConferSite?.total_calls?.difference > 0"
          class="reports-video-conf-health__border-circle reports-video-conf-health__border-circle--green">
          {{videoConferSite?.total_calls?.difference}}
        </h4>
        <h4 *ngIf="videoConferSite?.total_calls?.difference < 0" class="text-center"
          class="reports-video-conf-health__border-circle reports-video-conf-health__border-circle--red">
          {{videoConferSite?.total_calls?.difference}}
        </h4>
        <h4 *ngIf="videoConferSite?.total_calls?.difference == 0" class="text-center"
          class="reports-video-conf-health__border-circle reports-video-conf-health__border-circle--black">--</h4>
        <h4
          *ngIf="videoConferSite?.total_usage?.difference.indexOf('+') !== -1 && videoConferSite?.total_usage?.difference != '+00:00:00'"
          class="reports-video-conf-health__border-circle reports-video-conf-health__border-circle--green">
          {{videoConferSite?.total_usage?.difference}}</h4>
        <h4 *ngIf="videoConferSite?.total_usage?.difference.indexOf('-') !== -1" class="text-center"
          class="reports-video-conf-health__border-circle reports-video-conf-health__border-circle--red">
          {{videoConferSite?.total_usage?.difference}}
        </h4>
        <h4 *ngIf="videoConferSite?.total_usage?.difference == '+00:00:00'" class="text-center"
          class="reports-video-conf-health__border-circle reports-video-conf-health__border-circle--black">--</h4>
      </div>

      <div class="reports-video-conf-health__container-item-bottom-health">
        <h1
          *ngIf="videoConferSite?.avg_health >= 90 || (videoConferSite?.avg_health === 0 && videoConferSite?.total_incidents.total_incidents === 0)"
          class="grade-letter text-success">A {{videoConferSite?.videoConferSite}}</h1>
        <h1 *ngIf="videoConferSite?.avg_health >= 41 && videoConferSite?.avg_health <= 89"
          class="grade-letter text-warning">B {{videoConferSite?.videoConferSite}}</h1>
        <h1 *ngIf="videoConferSite?.avg_health <= 40 && videoConferSite?.total_incidents.total_incidents !== 0"
          class="grade-letter text-danger">C {{videoConferSite?.videoConferSite}}</h1>
      </div>

    </div>

    <!-- I WILL GET BACK ON THIS -->
    <div class="progress">
      <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <ng-container>
      <div *ngIf="videoConferSite?.avg_health >= 95" class="pnt-group bar-move"
        [style.left]="videoConferSite?.avg_health-8 + '%'">
        <div class="pnt">
          <div class="pnt-n">{{videoConferSite?.avg_health | wholeNumber }}%</div>
        </div>
      </div>
      <div *ngIf="videoConferSite?.avg_health <= 10" class="pnt-group bar-move"
        [style.left]="videoConferSite?.avg_health+1 + '%'">
        <div class="pnt">
          <div class="pnt-n">{{videoConferSite?.avg_health | wholeNumber }}%</div>
        </div>
      </div>
      <div *ngIf="videoConferSite?.avg_health > 10 && videoConferSite?.avg_health < 95" class="pnt-group bar-move"
        [style.left]="videoConferSite?.avg_health + '%'">
        <div class="pnt">
          <div class="pnt-n">{{videoConferSite?.avg_health | wholeNumber }}%</div>
        </div>
      </div>
    </ng-container>
  </div>



</div>