import { GetCompanyService } from 'src/app/services/get-company.service'

import { HttpClient, HttpEventType } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'

// import { jsonpCallbackContext } from '@angular/common/http/src/module';
// import * as moment from 'moment-timezone';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  setoverlay: String = ''; //create

  server_url: string = environment.serverUrl;
  selectedFile: File = null;
  imageSrc: any;

  companyname: any = '';
  address1: any = '';
  address2: any = '';
  city: any = '';
  state: any = '';
  state2: any = '';
  zip: any = '';
  country: any;
  countryName: any;
  companyphone: any = '';
  compphonecode: any = '';
  userphone: any = '';
  firstname: any = '';
  lastname: any = '';
  email: any = '';
  links = environment.config.links;
  control: FormControl;
  submitted = false;
  model: any = {};
  allCountryListData: any = [];
  allSiteListData = [];
  userInfoDtls = [];
  companyInfoDtls = [];
  allUserListData = [];
  acclev;

  showStatConfirmation: number = 0;
  delUserID = 0;
  user_firstName;
  user_lastName;
  isDeleteButtonPressed;

  compId = '';
  userId: any;
  logo = '';
  errorMessage = '';
  resent = '';
  passwordresetmessage = '';
  accessLevel: any;
  isError: number = 0;
  errorMsg = '';
  allStatesListData: any = [];
  highlightNum: number = 1;
  isdisable: number = 0;
  isShowDiableServiceConfirmation: number = 0;

  isCompanyVisible = 0;
  isUserListVisible = 0;
  isBillingVisible = 0;
  isIntegrationVisible = 0;
  isgdprVisible = 0;

  sentInvite = 0;
  sentRequest: any = false;
  resetResponseData: any;
  messPasswordReset: any;

  isEdit: number = 0;
  selectedUser: any;
  firstName;
  isSaved: number = 0;
  uploadProgress: string;
  userAlertDtls = [];
  envTitle: any = environment.config.title;
  showGDPR: any = false;
  showLegal: any = false;
  userALerts: any
  @ViewChild('fileReset') fileReset: ElementRef;
  isNoftificationSchedule:boolean = false;
  // timezones: { value: string; utcOffset: string }[] = moment.tz.names().map((zone: string) => ({
  //   value: zone,
  //   utcOffset: moment.tz(zone).format('Z'),
  // }));
  // selectedTimezone: { value: string; utcOffset: string };

  userAcctForm = this.fb.group({
    firstname: ['', [Validators.required, Validators.maxLength(30)]],
    lastname: ['', [Validators.required, Validators.maxLength(30)]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
    userphone: ['', [Validators.required, Validators.maxLength(25)]],
    timezone: ['', [Validators.required]],
  });

  compAcctForm = this.fbc.group({
    companyname: ['', [Validators.required, Validators.maxLength(30)]],
    address1: ['', [Validators.required, Validators.maxLength(50)]],
    address2: [''],
    city: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.maxLength(50)]],
    state: ['', [Validators.required, Validators.maxLength(50)]],
    zip: ['', [Validators.required]],
    country: [''],
    compphonecode: [''],
    companyphone: ['', [Validators.required, Validators.maxLength(25)]],
  });

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private fbc: FormBuilder,
    private companyService: GetCompanyService
  ) { }

  ngOnInit() {
    this.getCountryData();
    this.getAllSiteDetail();
    this.checkAccessPermissions();
    this.getUserAlertStatus();
    this.checkForEnvironment();
    
    // this.isGamEnabled();
  }
  
  gamEnabed: any = false;
  
  isGamEnabled = () => {
  
    this.Auth.isGamEnabled().subscribe((data: any) => {
      console.log('isGamEnabled: ',data)
      this.gamEnabed = data.uses_gam;
    }, (err => {
      console.log('here')
      // this.gamEnabed = false;
    }));
  }
  
  checkForEnvironment = () => {
    if (this.envTitle === 'VisibilityOne' || this.envTitle === 'CXDetect') {
      this.showGDPR = true;
    }
    if (this.envTitle === 'VisibilityOne' || this.envTitle === 'CXDetect' || this.envTitle === 'vCare') {
      this.showLegal = true;
    }
  }

  get f() {
    return this.userAcctForm.controls;
  }
  get fc() {
    return this.compAcctForm.controls;
  }

  getCountryData() {
    this.Auth.getAllCountryList().subscribe((data) => {
      this.allCountryListData = data['response'];
      if (this.country > 0) {
        this.countryName = this.allCountryListData[this.country - 1].name;
        this.getStatesData(this.country);
      } else {
        this.delay(3000).then((any) => {
          this.countryName = this.allCountryListData[this.country - 1].name;
          this.getStatesData(this.country);
        });
      }
    });
  }

  setCountry(n) {
    this.getStatesData(n);
    this.compphonecode = this.allCountryListData[n - 1].phonecode;
    this.compAcctForm.patchValue({
      compphonecode: this.compphonecode,
      zip: '',
    });
  }

  getStatesData(n) {
    this.Auth.getAllStatesList(n).subscribe((data) => {
      this.allStatesListData = data['response'];
    });
  }
  onUserSubmit() {
    this.reset();
    this.submitted = true;
    if (this.userAcctForm.invalid) {
      this.changeIsSavedflg(0);
      return;
    } else {
      this.updateUserInfo();
    }
  }

  onCompSubmit() {
    this.reset();
    this.submitted = true;
    this.compAcctForm.updateValueAndValidity;
    if (this.compAcctForm.invalid) {
      this.changeIsSavedflg(0);
      return;
    } else {
      this.isSaved = 1;
      this.delay(3000).then((any) => {
        this.isSaved = 0;
      });
      this.updateCompInfo();
    }
  }

  onFileSelected(event,isProfile?:boolean) {
    this.selectedFile = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
      this.reset();
      isProfile?this.onUploadProfile():this.onUpload();
    }
  }
  onUpload() {
    // this.changeIsSavedflg(1);
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;
    const fd = new FormData();
    fd.append('companyImage', this.selectedFile, 'image');
    fd.append('company_id', company_id);
    this.http
      .post(this.server_url + '/api/v1/user/uploadTest', fd, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = 'Upload Progress: ' + Math.round((event.loaded / event.total) * 100) + '%';
        } else if (event.type == HttpEventType.Response) {
          this.companyService.companyChangeEvent.emit({ logo: event?.body?.filename })
          setTimeout(() => {
            this.reset();
          }, 3000)
				}
      }, (err) => {
        this.uploadProgress = null;
        this.errorMsg = 'Something went wrong while uploading';
        this.isError = 1;
        setTimeout(() => {
            this.reset();
          }, 3000)
      });
  }
  onUploadProfile() {
    const fd = new FormData();
    fd.append('profilePicture', this.selectedFile, 'image');
    this.http
			.post(this.server_url + '/api/v1/user/uploadProfile', fd, {
				reportProgress: true,
				observe: 'events',
			})
			.subscribe(
				(event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.uploadProgress = 'Upload Progress: ' + Math.round((event.loaded / event.total) * 100) + '%'
          } else if (event.type == HttpEventType.Response) {
						this.companyService.companyChangeEvent.emit({ ...event?.body?.data })
						setTimeout(() => {
							this.reset()
						}, 3000)
					}
				},
				err => {
					this.uploadProgress = null
					this.errorMsg = 'Something went wrong while uploading'
					this.isError = 1
					setTimeout(() => {
						this.reset()
					}, 3000)
				}
			)
  }
  getAllSiteDetail() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    this.compId = decodedToken.company_id;
    this.userId = decodedToken.user_id;
    const companyId = {
      company_id: decodedToken.company_id,
    };
    // const userData = {
    //   company_id: decodedToken.company_id,
    //   user_id: decodedToken.user_id,
    // };

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data.response;
    });

    this.Auth.getUserInfo().subscribe((data) => {
      this.userInfoDtls = data['response'];

      let arr = [];
      for (let d in this.userInfoDtls) {
        arr.push(this.userInfoDtls[d]);
      }

      if (arr[0].accesslevel === 'ADMIN') {
        this.accessLevel = 3;
        this.isCompanyVisible = 1;
        this.isUserListVisible = 1;
        this.isBillingVisible = 1;
        this.isgdprVisible = 1;
      } else if (arr[0].accesslevel === 'MANAGER') {
        this.accessLevel = 2;
        this.isCompanyVisible = 1;
        this.isUserListVisible = 1;
        this.isBillingVisible = 0;
        this.isgdprVisible = 1;
      } else {
        this.accessLevel = 1;
        this.isCompanyVisible = 0;
        this.isUserListVisible = 0;
        this.isBillingVisible = 0;
        this.isgdprVisible = 0;
      }

      this.acclev = arr[0].accesslevel;
      this.Auth.firstName = arr[0].first_name;
      this.firstname = arr[0].first_name;
      this.lastname = arr[0].last_name;
      this.email = arr[0].email;
      this.userphone = arr[0].phone;
      // this.selectedTimezone = this.timezones.find(zone => zone.value === arr[0].timezone)
      
      this.userAcctForm.patchValue({
        acclev: arr[0].accesslevel,
        firstname: arr[0].first_name,
        lastname: arr[0].last_name,
        email: arr[0].email,
        userphone: this.userphone,
        timezone: arr[0].timezone
      });
    });

    this.Auth.getCompanyInfo().subscribe((data) => {
      this.companyInfoDtls = data['response'];
      let arr1 = [];
      for (let d1 in this.companyInfoDtls) {
        arr1.push(this.companyInfoDtls[d1]);
      }
      this.companyname = arr1[0].company_name;
      this.address1 = arr1[0].address1;
      this.address2 = arr1[0].address2;
      this.city = arr1[0].city;
      this.state = arr1[0].state;
      this.zip = arr1[0].zip;
      this.country = arr1[0].country;
      if (arr1[0].phone) {
        let tmpDbCphn = arr1[0].phone;
        let spCPhnArr = tmpDbCphn.split('$$');
        this.compphonecode = spCPhnArr[1];
        this.companyphone = spCPhnArr[2];
      }
      this.logo = arr1[0].logo;
      this.Auth.logo = this.companyInfoDtls[0].logo;
      this.Auth.companyName = this.companyInfoDtls[0].company_name;

      this.compAcctForm.patchValue({
        companyname: arr1[0].company_name,
        address1: arr1[0].address1,
        address2: arr1[0].address2,
        city: arr1[0].city,
        state: arr1[0].state,
        zip: arr1[0].zip,
        country: arr1[0].country,
        compphonecode: this.compphonecode,
        companyphone: this.companyphone,
      });
    });

    this.Auth.getUserList(companyId).subscribe((data) => {
      this.allUserListData = data['response'];

      let arr2 = [];
      for (let d2 in this.allUserListData) {
        if (this.allUserListData[d2].accesslevel == 'ADMIN') {
          this.allUserListData[d2].accessLevel = 3;
          this.allUserListData[d2].listOrder = 3;
        } else if (this.allUserListData[d2].accesslevel == 'MANAGER') {
          this.allUserListData[d2].accessLevel = 2;
          this.allUserListData[d2].listOrder = 2;
        } else {
          this.allUserListData[d2].accessLevel = 1;
          this.allUserListData[d2].listOrder = 1;
        }

        if (
          this.allUserListData[d2].first_name == null &&
          this.allUserListData[d2].last_name == null
        ) {
          this.allUserListData[d2].listOrder = 0;
        }

        arr2.push(this.allUserListData[d2]);

        // if (this.allUserListData[d2].last_login != null) {
        //   var ldate = new Date(this.allUserListData[d2].last_login);
        //   var mySQLDate = [ldate.getFullYear(), ldate.getMonth() + 1, ldate.getDate()].join('-');
        //   var mySQLTime = [ldate.getHours(), ldate.getMinutes(), ldate.getSeconds()].join(':');
        //   arr2[d2].last_login = [mySQLDate, mySQLTime].join(' ');
        // } else {
        //   arr2[d2].last_login = '-- -- --';
        // }

        arr2[d2].last_login = this.allUserListData[d2].last_login

      }
      this.allUserListData = arr2;
      function compare(a, b) {
        if (a.listOrder < b.listOrder) return 1;
        if (a.listOrder > b.listOrder) return -1;
        return 0;
      }
      this.allUserListData.sort(compare);
    });
  }

  getUserList() {
    this.Auth.getUserList({ company_id: this.compId }).subscribe((data) => {
      this.allUserListData = data['response'];
      let arr2 = [];
      for (let d2 in this.allUserListData) {
        arr2.push(this.allUserListData[d2]);
      }
    });
  }

  updateCompInfo() {
    let updCTmpPhn = (<HTMLInputElement>document.getElementById('companyphone')).value;
    let updCTmpPhncode = (<HTMLInputElement>document.getElementById('compphonecode')).value;
    let newCUpdDbPhn = '$$' + updCTmpPhncode + '$$' + updCTmpPhn;

    const compData = {
      data: {
        company_id: this.compId,
        company_name: (<HTMLInputElement>document.getElementById('companyname')).value,
        country: (<HTMLInputElement>document.getElementById('country')).value,
        phone: newCUpdDbPhn,
        address1: (<HTMLInputElement>document.getElementById('address1')).value,
        address2: (<HTMLInputElement>document.getElementById('address2')).value,
        city: (<HTMLInputElement>document.getElementById('city')).value,
        state: (<HTMLInputElement>document.getElementById('state')).value,
        zip: (<HTMLInputElement>document.getElementById('zip')).value,
      },
    };
    this.Auth.updateProfile(compData).subscribe((data) => {
      if (data.response[0].status === 'OK') {
        this.isSaved = 1;
        this.Auth.setCompany(data)
        // this.companyService.setCompany(data)
        // this.getAllSiteDetail();
      } else {
        if (data.response[0].status === 'Error') {
          this.errorMsg = data.response[0].message;
          this.isError = 1;
          this.delay(3000).then((any) => {
            this.isError = 0;
          });
        }
      }
    });
  }
  // printTime(t) {
  //   if (t === '-- -- --') {
  //     return '';
  //   } else {
  //     let a = moment(t + 'Z').utc();
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  //   }
  // }

  updateUserInfo() {
    let updTmpPhn = (<HTMLInputElement>document.getElementById('userphone')).value;
    let newUpdDbPhn = updTmpPhn;
    const userData = {
			data: {
				company_id: this.compId,
				user_id: this.userId,
				first_name: (<HTMLInputElement>document.getElementById('firstname')).value,
				last_name: (<HTMLInputElement>document.getElementById('lastname')).value,
				email: (<HTMLInputElement>document.getElementById('email')).value,
				phone: newUpdDbPhn,
				// timezone: this.selectedTimezone.value
				timezone: this.userAcctForm.value.timezone.nameValue,
			},
		}
    this.Auth.updateUserProfile(userData).subscribe((data) => {
      if (data.response[0].status === 'OK') {
        this.isSaved = 1;
        this.getAllSiteDetail();
      } else {
        if (data.response[0].status === 'Error') {
          this.isSaved = 0;
          this.errorMsg = data.response[0].message;
          this.isError = 1;
          this.delay(3000).then((any) => {
            this.isError = 0;
          });
        }
      }
    });
  }

  updateSelectedUserInfo() {
    const userData = {
      data: {
        company_id: this.compId,
        user_id: this.userId,
        first_name: (<HTMLInputElement>document.getElementById('firstname')).value,
        last_name: (<HTMLInputElement>document.getElementById('lastname')).value,
        email: (<HTMLInputElement>document.getElementById('email')).value,
        phone: (<HTMLInputElement>document.getElementById('userphone')).value,
      },
    };
    this.Auth.updateUserProfile(userData).subscribe((data) => {
      if (data.response[0].status === 'OK') {
        this.isSaved = 1;
        this.getAllSiteDetail();
      } else {
        if (data.response[0].status === 'Error') {
          this.isSaved = 0;
          this.errorMsg = data.response[0].message;
          this.isError = 1;
          this.delay(3000).then((any) => {
            this.isError = 0;
          });
        }
      }
    });
  }

  sendResetPassword() {
    this.resetPassword();
  }
  resetPassword() {
    const userEmail = this.selectedUser.email;

    this.Auth.sendPasswordReset(userEmail).subscribe((data) => {
      this.sentRequest = true;
      this.resetResponseData = data;
    });
  }

  checkAccessPermissions() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const accesslevel = decodedToken.accesslevel;

    if (accesslevel === 'ADMIN') {
      this.isCompanyVisible = 1;
      this.isUserListVisible = 1;
      this.isBillingVisible = 1;
      this.isIntegrationVisible = 1;
      this.isgdprVisible = 1;
    } else if (accesslevel === 'MANAGER') {
      this.isCompanyVisible = 1;
      this.isUserListVisible = 1;
      this.isBillingVisible = 0;
      this.isgdprVisible = 1;
    } else if (accesslevel === 'USER') {
      this.isCompanyVisible = 0;
      this.isUserListVisible = 0;
      this.isBillingVisible = 0;
      this.isgdprVisible = 0;
    }
  }

  setHighlight(n) {
    this.reset();
    this.highlightNum = n;
    this.changeIsDisabledflg(0);
    this.changeIsSavedflg(0);
  }

  showDisableSerivceConfirmation() {
    this.isShowDiableServiceConfirmation = 1;
    event.stopPropagation();
  }
  disableSerivce() {
    const companyData = {
      data: {
        company_id: this.compId,
      },
    };
    this.Auth.companySetInactive(companyData.data).subscribe((data) => {
      this.Auth.logout();
    });
  }

  // logOut() {
  //   this.router.navigate(['login']);
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('phone');
  //   localStorage.setItem('verified', 'false');
  //   localStorage.setItem('keyDownload', '0');
  //   localStorage.setItem('reloadSiteData', '0');
  //   localStorage.setItem('showCongrats', '0');
  //   localStorage.setItem('runTut', '0');
  //   localStorage.setItem('successReg', '0');
  //   localStorage.setItem('overLayMembersMove', '0');
  // }
  
  changeIsDisabledflg(n) {
    if (n === 1) {
      if (this.isSaved === 1) {
        this.changeIsSavedflg(0);
      }
    }
    this.isdisable = n;
    this.delay(3000).then((any) => {
      this.isdisable = 0;
    });
  }

  toEdit(n, s) {
    this.selectedUser = s;
    this.isEdit = n;
    event.stopPropagation();
  }

  changeIsSavedflg(n) {
    this.isSaved = n;
    this.delay(3000).then((any) => {
      this.isSaved = 0;
    });
  }

  setDisabled() {
    this.changeIsDisabledflg(0);
  }

  changePassword() {
    this.Auth.sendPasswordReset(this.email).subscribe((data) => {
      this.sentRequest = true;
      this.resetResponseData = data;
      this.messPasswordReset = 'Sending email to ' + this.email;
      setTimeout(() => {
        this.messPasswordReset = '';
      }, 3000);
    });
  }

  toggleActiveUser(uid, activeflg) {
    const usrData = {
      company_id: this.compId,
      user_id: uid,
    };

    if (activeflg === 0) {
      this.Auth.setUserInActive(usrData).subscribe((data) => { });
    } else if (activeflg === 1) {
      this.Auth.setUserActive(usrData).subscribe((data) => { });
    }
  }

  toggleUserNotify(enableflg) {
    const usrData = {
      company_id: this.compId,
      user_id: this.userId,
    };

    if (enableflg === 1) {
      this.Auth.setUserNotifyOn(usrData).subscribe((data) => {
        this.getUserAlertStatus();
      });
    } else if (enableflg === 0) {
      this.Auth.setUserNotifyOff(usrData).subscribe((data) => {
        this.getUserAlertStatus();
      });
    }
  }

  // previewFile() {}

  isInviteSent() {
    if (this.sentInvite) {
      return true;
    } else {
      return false;
    }
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(''), ms));
  }

  sendEmailInvite() {
    const inviteData = {
      company_id: this.compId,
      email: (<HTMLInputElement>document.getElementById('reg-email')).value,
      accesslevel: (<HTMLInputElement>document.getElementById('user-access')).value,
      tenant_admin: this.companyInfoDtls[0].tenant_admin,
    };

    this.Auth.sendRegisterInvite(inviteData).subscribe((data) => {
      if (data['response'][0].status === 'OK') {
        this.sentInvite = 1;
        this.getAllSiteDetail();
      } else if (this.companyInfoDtls[0].additional_domain !== null) {
        let companyOtherDomains = this.companyInfoDtls[0].additional_domain.replace(/,/g, ';');
        let flag = 0;
        let indexOfDomain = inviteData.email.indexOf("@");
        let emailSubString = inviteData.email.substr(indexOfDomain + 1, (inviteData.email.length - 1));
        companyOtherDomains = companyOtherDomains.split(';').map((e) => e.trim());
        for (let x = 0; x < companyOtherDomains.length && flag !== 1; x++) {
          if (companyOtherDomains[x] === emailSubString) {
            flag = 1;
          }
        }
        if (flag === 1) {
          this.Auth.sendRegisterInviteOtherDomain(inviteData).subscribe((data2) => {
            // insert if here if response.status is = ok
            this.sentInvite = 1;
            this.getAllSiteDetail();
          });

        } else {
          this.sentInvite = -1;
          // insert error message here saying must match domain of company
          this.errorMessage = data['response'][0].message;
        }
      } else {
        this.sentInvite = -1;
        this.errorMessage = data['response'][0].message;
      }

      this.delay(3000).then((any) => {
        this.sentInvite = 0;
        (<HTMLInputElement>document.getElementById('reg-email')).value = '';
        (<HTMLInputElement>document.getElementById('user-access')).value = 'ADMIN';
        this.updateUserInfo();
      });
    });
  }

  resendEmailInvite(e) {
    const inviteData = {
      email: e,
    };
    this.Auth.resendRegisterInvite(inviteData).subscribe((data) => {
      if (data['response'][0].status === 'OK') {
        this.resent = e;
      } else {
        this.resent = '';
      }

      this.delay(3000).then((any) => {
        this.resent = '';
      });
    });
  }

  changeUserAccessLevel(a) {
    const level = a;
    const levelData = {
      company_id: this.selectedUser.company_id,
      user_id: this.selectedUser.user_id,
      access: level,
    };
    this.Auth.updateAccesslevel(levelData).subscribe((data) => {
      if (data['response'][0].status === 'OK') {
      } else {
      }
      this.getAllSiteDetail();
    });
  }

  
  getUserAlertStatus = () => {
    const usrData = {
      company_id: this.compId,
      user_id: this.userId,
    };
    
    this.Auth.getUserAlertStatus(usrData).subscribe((data) => {
      // this.userAlertDtls = data['response'];
      const [res] = data.response
      
      const { alerts } = res
      this.userALerts = alerts;
      
      console.log('this.userALerts: ', this.userALerts)
    });
  }
  
  setNotification = (event) => {
    const flag = event.target.checked ? '1': '0';
    this.Auth.userSetAccountNotification(this.userId, flag).subscribe((data) => {
      const [res] = data.response
    });

  };

  toDeleteUser(uid) {
    this.delUserID = uid;
    this.showStatConfirmation = 1;
    event.stopPropagation();
  }
  toggleDeleteForm() {
    this.showStatConfirmation = 0;
  }

  deleteUser() {
    const usrData = {
      company_id: this.compId,
      user_id: this.delUserID,
    };
    this.Auth.delUser(usrData).subscribe((data) => {
      if (data['response'][0]['status'] === 'OK') {
        this.showStatConfirmation = 0;
        this.delUserID = 0;
        this.user_firstName = '';
        this.user_lastName = '';
        this.getAllSiteDetail();
      }
    });
  }
  reset() {
    if(this.fileReset) this.fileReset.nativeElement.value = "";
    this.isError = 0;
    this.isSaved = 0;
    this.errorMsg = '';
    this.uploadProgress = null;
    this.isNoftificationSchedule = false;
  }
  maintenance(){
    this.isNoftificationSchedule = !this.isNoftificationSchedule;
  }
}
