import { GetDocumentCenterService } from 'src/app/services/get-document-center.service'
import { GetPluginService } from 'src/app/services/get-plugin.service'
import { ZoomPluginActionService } from 'src/app/services/zoom-plugin-action.service'
import { environment } from 'src/environments/environment'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-plugin-logging',
  templateUrl: './plugin-logging.component.html',
  styleUrls: ['./plugin-logging.component.scss']
})
export class PluginLoggingComponent implements OnInit, OnChanges {
  @Input('pluginId') pluginId: any;
  @Input('data') data: any;
  @Input('refresh') refresh: any;
  constructor(private _pluginService: GetPluginService,
              private _pluginAction: ZoomPluginActionService,
              private _documentCenter: GetDocumentCenterService,
  ) { }
  message: string = '';
  showLoader: boolean = false;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.refresh && this.pluginId){
      this.downloadLogs();
    }
  }
  setLog(loader:boolean,message?:string){
    this.message = this.data?.service_type === 'teams' ? message?.replace('zoom','teams') : message || `<h5>Something went wrong. Please try again later</h5>`;
    this.showLoader= loader
  }

  downloadLogs = () => {
    const actionParams = {};
    const actionName = 'DownloadLatestLogs';
    this.setLog(true,'<h5>Plugin Logging<h5/>')
    this._pluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
      if (result.status === 200) {
        this._pluginAction.checkActionStatus(result.response[0].action_id, this.processActionResults)
        this._pluginAction.setActionCount();
      }
      else {
        this.setLog(false,result?.error);
      }
    }, err => {
      this.setLog(false);
    });
  }
  processActionResults = (status, results) => {
    if (status === 'COMPLETE'){
      this.getDocumentsByDevice();
    }
    else {
      this.setLog(false);
    }
  }
  getDocumentsByDevice = () => {
  	const propData = {
			quadrant_group: 'CLOUD',
			device_id: this.data.zoom_user_id,
			description: '',
      offset: 0,
      root_id: 0,
      isFiles: true

		}
		
    this._documentCenter.getDocumentsByDevice(propData).subscribe((data: any) => {
      const { document_list} = data;
      
      const [latest] = document_list;
      
      const message = latest ? `<h5>Plugin Log successfully uploaded to the Document Centre</h5>
       You can download it here: <a href="${this.docPath+latest?.document_id+'/'+1}">${latest?.file_name}</a>` : '<h5> Something went wrong while retrieving logs </h5>'
      this.setLog(false,message)
      
    });
  };

}

