import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';
import { DeleteSiteDialogComponent } from '../../iot/dialogs/delete-site-dialog/delete-site-dialog.component';
import { AuthService } from '../../services/auth.service';
import { GetIotService } from '../../services/get-iot.service';
import { PopupTypeService } from '../../services/popupType.service';

@Component({
  selector: 'app-vs-menu-all-site-and-data-collectors',
  templateUrl: './vs-menu-all-site-and-data-collectors.component.html',
  styleUrls: ['./vs-menu-all-site-and-data-collectors.component.scss'],
})
export class VsMenuAllSiteAndDataCollectorsComponent
  implements OnInit, OnChanges
{
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();
  @Output() objectEmitter = new EventEmitter<{}>();

  @Input() siteList: any;
  @Input() siteSummary: any;
  @Input() siteSummaryLoading: boolean;
  @Input() siteDetailsLoading: boolean;
  @Input() isShowCollectorInfo: any;
  @Input() isShowCollectorEdit: any;
  @Input() runTutorial: any;
  @Input() keyDown: any;
  @Input() searchKey: any;
  @Input() domotzEnabled: any;
  @Input() unlinkedIotDevice: any;
  @Input() unlinkedPlugin: any;

  //-a
  viewTutorial = environment.config.dynamicImage.viewTutorial;
  downloadCollector = environment.config.dynamicImage.downloadCollector;
  createNewSite = environment.config.dynamicImage.createNewSite;
  createVirtualSite = environment.config.dynamicImage.createVirtualSite;
  pluginZoom = environment.config.dynamicImage.pluginZoom;
  desktopPlugin = environment.config.dynamicImage.desktopPlugin;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  iconPlugin = environment.config.dynamicImage.iconPlugin;
  vsOnePopup = environment.config.dynamicText.vsOnePopup;
  dCollector = environment.config.dynamicText.dCollector;

  collectorParams: any;
  collectorLatestVersion: any;
  confirmationInput: any = '';

  collectorVersionData: any;

  cid: any = 0;
  sid: any = 0;
  zoomRoom: any;
  popUpCollectorData: any;

  zoomRoomName = '';

  showStatConfirmation: boolean = false;
  showUnVerified: boolean = false;
  showPluginConfirmation: Boolean = false;
  showPluginConfirmationError: Boolean = false;
  showScheduleModal: boolean = false;
  showUpdateCenter: boolean = false;
  // iotDomotAssigned: any;
  // iotLiteAssigned: any;

  enableInProduction: any = environment.config.enableInProduction;
  showWellnessInProd: any = false;
  companyId: any;

  constructor(
    private GetIot: GetIotService,
    private Auth: AuthService,
    public ds: PopupTypeService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.siteList && this.siteList.length !== 0) {
      this.getCollectorVersionData();
      // this.getTotalAssignedIot(this.siteList);
      this.getColletorUpdateStatus();
      this.checkForUnverifiedSites();

      this.getCompanyId();
    }
  }

  setWellnessProd = (enableInProd, companyId) => {
    return enableInProd && (companyId == 15 || companyId == 36);
  };

  checkForUnverifiedSites = () => {
    this.showUnVerified = this.siteList.filter(
      (e) => !e.verified
    ).length;
  };

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
    return decodedToken.company_id;
  };

  getColletorUpdateStatus = () => {
    const company_id = this.getCompanyId();

    this.Auth.collectorCheckScheduleUpdateByCompany(company_id).subscribe(
      (data) => {
        if (data.response) {
          const { count } = data.response;
          this.showUpdateCenter = parseInt(count) !== 0;
        }
      }
    );
  };

  // getTotalAssignedIot = (items) => {
  //   this.iotDomotAssigned = items
  //     .map((e) => e.iot_domotz_count)
  //     .reduce((a, b) => a + b);
  //   this.iotLiteAssigned = items
  //     .map(
  //       (e) =>
  //         e.iot_lite_audio_count +
  //         e.iot_lite_cloud_count +
  //         e.iot_lite_video_count
  //     )
  //     .reduce((a, b) => a + b);
  // };

  getCollectorVersionData = () => {
    this.Auth.getCollectorLatestVersion('collector').subscribe((data) => {
      if (data.response) {
        this.collectorVersionData = data.response[0];
        this.collectorLatestVersion = data.response[0].version;
      }
    });
  };

  getSiteTypeIcon = (bool) => {
    return bool ? this.iconAddSite : this.iconVirtual;
  };

  toggleCollectorInfo = (site_id) => {
    this.collectorParams = {
      eventEmitter: 'showCollectorInfo',
      company_id: this.companyId,
      site_id,
      showScheduleModal: false,
      // isShowCollectorInfo: true,
    };

    this.objectEmitter.emit(this.collectorParams);
  };

  openNewUpdateModal = (site_id, site_name, _update_schedule = '') => {
		// this.collectorParams = {
		//   eventEmitter: 'showCollectorInfo',
		//   company_id: this.companyId,
		//   site_id: siteId,
		//   showScheduleModal: false,
		//   isShowCollectorInfo: true,
		// };
		this.popUpCollectorData = {
			eventEmitter: 'showScheduleModal',
			site_id,
			site_name,
			date: _update_schedule,
		}
		this.showScheduleModal = true
		// this.isShowCollectorInfo = false;

		this.objectEmitter.emit(this.popUpCollectorData)
	};

  siteNameConditions = (items) => {
    return items.site_type === 0 && items.verified == 1;
  };

  getCollectorStatusClass = (items) => {
    if (items.verified == 1 && items.collector_status == 1) {
      return 'all-sites-collectors__status--green';
    }

    if (items.verified == 1 && items.collector_status == -1) {
      return 'all-sites-collectors__status--red';
    }

    if (items.verified == 1 && items.collector_status == 2) {
      return 'all-sites-collectors__status--yellow';
    }

    if (items.verified == 0 || items.collector_status == 0) {
      return '';
    }
  };

  calculateIotDeviceCount = (audio, video, cloud) => +audio + +video + +cloud;

  toggleSiteAlerts = (bool, site_id) => {
    const siteData1 = {
      company_id: this.companyId,
      site_id,
    };

    if (!bool) {
      this.Auth.setSiteNotifyOff(siteData1).subscribe((data) => {});
    } else {
      this.Auth.setSiteNotifyOn(siteData1).subscribe((data) => {});
    }
  };

  sendPopupTypeEmitter(number) {
    this.sendPopupType.emit(number);
  }

  checkCollectorVersionThatNeedUpdates = ({ collector_version, site_type }) => {
    if (site_type === 1) {
      return false;
    }
    return collector_version < this.collectorLatestVersion;
  };

  // toDeleteSite = (site_id, zoomPluginCount = 0, zoomRoom = 0) => {
  openDeleteModal = ({ site_id, cloud_rooms_count, zoom_room, site_name }) => {
    const config = {
      company_id: this.getCompanyId(),
      site_id,
      cloud_rooms_count,
      zoom_room,
      site_name,
      sendPopupType: this.sendPopupType,
    };

    const webportal = this.dialog.open(DeleteSiteDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
      },
    });
  };

  sendData = (p, n) => {
    this.sendPopupTypeEmitter(11);
    //review
    //site_id should be pass as props
    localStorage.setItem('site_id', p);
    localStorage.setItem('reloadSiteData', n);
  };

  cancelDeleteSiteWithPlugin = () => {
    this.showPluginConfirmation = false;
    this.zoomRoom = null;
    this.zoomRoomName = null;
    this.cid = 0;
    this.sid = 0;
  };
}
