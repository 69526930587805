import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { IncidentType } from 'src/app/services/incident-messages.service'
import { PrintDataService } from 'src/app/services/print-data.service'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { JwtHelperService } from '@auth0/angular-jwt'

import { RefreshApiService } from '../../services/refresh-api.service'
import { IncidentMessagesComponent } from '../dialog/incident-messages/incident-messages.component'

@Component({
  selector: 'app-video-device-incidents',
  templateUrl: './video-device-incidents.component.html',
  styleUrls: ['./video-device-incidents.component.scss'],
})

export class VideoDeviceIncidentsComponent implements OnInit, OnChanges {
  @Input() popData: any;
  @Input() popupState: any;
  isShowTableData: boolean = true;
  shouldShowLoaderBtn: boolean = false;
  filterListner: Subscription;

  incidentsData = [];
  constructor(
    private Auth: AuthService,
    private printData: PrintDataService,
    private refreshApiService: RefreshApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.popData && changes.popData) {
      let isFirstLoad = true;
      this.getData();
    } 
  }

  getData = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.shouldShowLoaderBtn = true;


    this.Auth.getDeviceIncidentList({
      'company_id': decodedToken.company_id,
      'device_id': this.popData.deviceId,
      'htype': this.popData.hType,
    }).subscribe((data: any) => {
      if (data.response.length < 1) {
        this.isShowTableData = false;
      }
      
      console.log('data: ', data)


      this.incidentsData = data.response.map((i) => {
        const issue = i.notification_info.message.split(':');
        const eventsIssues = { ...i.events };
        const eventDetails = [];

        for (const event in eventsIssues) {
          eventDetails.push({
						trigger: this.translateTrigger(event),
						pulls: eventsIssues[event].pulls,
						// last_pull: this.printTime(eventsIssues[event].last_pull),
						// end_time: this.printTime(eventsIssues[event].end_date),
						// start_date: this.printTime(eventsIssues[event].start_date),
						last_pull: eventsIssues[event].last_pull,
						end_date: eventsIssues[event].end_date,
						start_date: eventsIssues[event].start_date,
					})
        }
        const incident = {
          incident_id: i.video_incident_id,
          actioned: i.actioned,
					// start_time: this.printTime(i.start_time),
					start_time: i.start_time,
					end_time: i.end_time,
					summary: this.formatIssueName(issue[issue.length - 1].trim()),
					event_details: eventDetails,
				}
        return incident;
      });
      this.shouldShowLoaderBtn = false;
    });
  };


  translateTrigger = (trigger) => {
    switch (trigger) {
      case 'camera':
        return 'Camera(s) disconnected.';
      case 'mic':
        return 'Microphone(s) disconnected.';
      case 'speaker':
        return 'Speaker(s) disconnected.';
      case 'offline':
        return 'Device offline.';
      case 'iot_component_offline':
        return 'Iot not resonding.';
      case 'iot_webservice_offline':
        return `Iot webservice not responding.`;
      case 'latency':
        return 'Exceeded Latency alert threshold.';
      case 'jitter':
        return 'Exceeded Jitter alert threshold.';
      case 'temperature':
        return 'Exceeded Temperature alert threshold.';
      case 'packetsloss':
        return 'Exceeded Packetloss alert threshold.';
      case 'call_disconnect':
        return 'Call disconnected.';
        
      //new switch
      case 'videoRxJitter':
        return 'Video RX Jitter.';
      case 'videoTxJitter':
        return 'Video TX Jitter.';
      
      case 'audioRxJitter':
        return 'Audio RX Jitter.';
        
      case 'audioTxJitter':
        return 'Audio TX Jitter.';
        
      case 'contentRxJitter':
        return 'Content RX Jitter.';
      case 'contentTxJitter':
        return 'Content TX Jitter.';  
        
      case 'videoRxLatency':
        return 'Video RX Latency.';
      case 'videoTxLatency':
        return 'Video TX Latency.';
      
      case 'audioRxLatency':
        return 'Audio RX Latency.';
        
      case 'audioTxLatency':
        return 'Audio TX Latency.';
        
      case 'contentRxLatency':
        return 'Content RX Latency.';
      case 'contentTxLatency':
        return 'Content TX Latency.';  
      
      
      case 'videoRxPacketsloss':
        return 'Video RX Packetloss.';
      case 'videoTxPacketsloss':
        return 'Video TX Packetloss.';
      
      case 'audioRxPacketsloss':
        return 'Audio RX Packetloss.';
        
      case 'audioTxPacketsloss':
        return 'Audio TX Packetloss.';
        
      case 'contentRxPacketsloss':
        return 'Content RX Packetloss.';
      case 'contentTxPacketsloss':
        return 'Content TX Packetloss.';  
      
      case 'SSLCertExpiry':
        return 'SSL certificate will expire soon'
      case 'SSLCertSelfSigned':
        return 'SSL certificate is self-signed'
      
      default:
        return 'Trigger Unknown';
    }
  }
  
  
  formatIssueName = (trigger) => {
    
    const triggerString = trigger;
    
    
    let formatString = triggerString.split(" ")
    
    return formatString.map( e => 
        this.translateTriggerForRxTx(e)
    ).join(' ');
  }
  
  translateTriggerForRxTx = (trigger) => {
    switch (trigger) {
      //new switch
      case 'videoRxJitter':
        return 'Video RX Jitter.';
      case 'videoTxJitter':
        return 'Video TX Jitter.';
      case 'audioRxJitter':
        return 'Audio RX Jitter.';
      case 'audioTxJitter':
        return 'Audio TX Jitter.';
      case 'contentRxJitter':
        return 'Content RX Jitter.';
      case 'contentTxJitter':
        return 'Content TX Jitter.';  
      case 'videoRxLatency':
        return 'Video RX Latency.';
      case 'videoTxLatency':
        return 'Video TX Latency.';
      case 'audioRxLatency':
        return 'Audio RX Latency.';
      case 'audioTxLatency':
        return 'Audio TX Latency.';
      case 'contentRxLatency':
        return 'Content RX Latency.';
      case 'contentTxLatency':
        return 'Content TX Latency.';  
      case 'videoRxPacketsloss':
        return 'Video RX Packetloss.';
      case 'videoTxPacketsloss':
        return 'Video TX Packetloss.';
      case 'audioRxPacketsloss':
        return 'Audio RX Packetloss.';
      case 'audioTxPacketsloss':
        return 'Audio TX Packetloss.';
      case 'contentRxPacketsloss':
        return 'Content RX Packetloss.';
      case 'contentTxPacketsloss':
        return 'Content TX Packetloss.';  
      
      default:
        return trigger;
    }
  }

  // printTime(t) {
  //   if (t == null || !t) {
  //     return '--';
  //   } else {
  //     return moment(t)
  //       .utc()
  //       .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       .format('MM-DD-YYYY hh:mma');
  //   }
    
  //   console.log('yeh')
  // }

  printIncidentsToPdf = () => {
    this.printData.printToPdf('incident-history-list-container', 'Incident Report');
  };

  onShowMessages = (incidentId, idx) => {
    const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
        incidentId,
        incidentType: IncidentType.VIDEO
				// showButton: true,
			},
			// height: '80%',
      // width: '60%',
			// minHeight: 'calc(100vh - 90px)',
      width: '80%',
			height: 'auto',
		})

    dialogRef.afterClosed().subscribe(isActioned => {
      if (isActioned) {
        this.incidentsData[idx].actioned = 1
      }
    })
  }
}
