<div class="video-device-info">

  <div class="video-device-info__site-img">
    <ng-container *ngIf="videoDeviceData?.device_type === 'cisco'">
      <img src="../../assets/img/cisco.png" alt="video-device-brand" />
    </ng-container>
    <ng-container *ngIf="
          videoDeviceData?.device_type === 'polycom' ||
          videoDeviceData?.device_type === 'hdx' ||
          videoDeviceData?.device_type === 'polystudio'
        ">
      <img src="../../assets/img/poly_logo.png" alt="video-device-brand" />
    </ng-container>

    <ng-container *ngIf="videoDeviceData?.device_type === 'logitech'">
      <img src="../../assets/img/logitech.png" alt="video-device-brand" />
    </ng-container>
  </div>


  <div class="video-device-info__text">
    <div *ngIf="isSuperUser" class="video-device-info__text-item">
      <strong>Company Name:</strong>{{ videoDeviceData.company_name }}
    </div>
    <div class="video-device-info__text-item">
      <strong>Site:</strong><a *ngIf="videoDeviceData" [routerLink]="['/video/site/', videoDeviceData?.site_id]">{{
        videoDeviceData?.site_name }}</a>
      <a *ngIf="!videoDeviceData">{{ videoDeviceData?.location }}</a>
    </div>
    <div class="video-device-info__text-item">
      <strong>Room Name:</strong>{{ videoDeviceData?.room_name | displayEmpty }}
    </div>
    <div class="video-device-info__text-item">
      <strong>Hostname:</strong>{{ videoDeviceData?.device_name | displayEmpty }}
    </div>
    <div class="video-device-info__text-item">
      <strong>Model:</strong>{{ videoDeviceData?.model | displayEmpty }}
    </div>
    <div class="video-device-info__text-item">
      <strong>IP address:</strong>{{ videoDeviceData?.ipv4 | displayEmpty }}
      <span class="fav-cog-icon">
        <fa-icon [icon]="faCogIcon" size="2x" class="cog-icon" (click)="openRemoteIp(videoDeviceData?.ipv4)"></fa-icon>
      </span>
    </div>
    <div *ngIf="showIpWithPort(videoDeviceData?.ipv4_lan)" class="video-device-info__text-item">
      <strong>LAN IP address:</strong>{{ videoDeviceData?.ipv4_lan | displayEmpty }}
      <span class="fav-cog-icon">
        <fa-icon [icon]="faCogIcon" size="2x" class="cog-icon"
          (click)="openRemoteIp(videoDeviceData?.ipv4_lan)"></fa-icon>
      </span>
    </div>
    <div class="video-device-info__text-item">
      <strong>Serial no:</strong>{{ videoDeviceData?.serialnumber | displayEmpty }}
    </div>
  </div>


  <div class="video-device-info__button-container">
    <div class="video-device-info__uptime-button-container">
      <div class="video-device-info__uptime">
        <app-info-uptime *ngIf="videoDeviceType != 'logitech'" [uptimeDays]="days" [uptimeHours]="hrs"
          [uptimeMins]="mins"></app-info-uptime>
        <app-video-logitech-uptime *ngIf="videoDeviceType == 'logitech'"
          [logitechData]="logitechData"></app-video-logitech-uptime>

      </div>
      <div class="video-device-info__gray-button-container" *ngIf="hasAccess">
        <app-btn-info label="reboot" [faName]="faIcon" (click)="sendPopupType(7)"></app-btn-info>
        <app-btn-info label="{{ videoDeviceData?.paused == 1 ? 'unpause' : 'pause' }}"
          [faName]="videoDeviceData?.paused == 1 ? faPauseIcon : faPlayIcon"
          (click)="setDevicePause(videoDeviceData?.paused)">
        </app-btn-info>
        <app-btn-info label="admin" (click)="
                    ds.sendPopupType(32, {
                      alertsId: videoDeviceData.video_device_id,
                      alertType: 'video',
                      siteId: videoDeviceData.site_id,
                      data: videoDeviceData,
                      deviceId: videoDeviceData.video_device_id,
                      deviceType: videoDeviceData.device_type
                    })
                  " [faName]="faIcon">
        </app-btn-info>
        <app-btn-info *ngIf="
                    videoDeviceData?.online == -1 &&
                     (videoDeviceData?.collector_status == 1 || videoDeviceData?.collector_status == 2)
                  " label="wakeup" [ngClass]="{ 'disable-wakeup': showWakeupLoader == '1' }"
          showLoader="{{ showWakeupLoader }}" [faName]="faIcon" (click)="wakeupDevice()">
        </app-btn-info>
      </div>

    </div>

    <div class="video-device-info__button--" [ngClass]="{
            'video-device-info__button-online': videoDeviceStatus === 'Online' || videoDeviceStatus === 'Sleep',
            'video-device-info__button-offline': videoDeviceStatus === 'Offline' ||  videoDeviceStatus === 'Collector Offline',
            'video-device-info__button-warning': showWarningStatus && videoDeviceStatus === 'Collector Offline',
            'video-device-info__button-loading': videoDeviceStatus === 'Teleportivity',
            'video-device-info__button-pause': videoDeviceStatus === 'Paused'
          }">
      {{ videoDeviceStatus }}
    </div>

    <hr />

    <app-video-touch-panel *ngIf="shouldShowTouchControl && videoDeviceType != 'logitech'"
      [pairingsData]="pairingsData"></app-video-touch-panel>
    <hr *ngIf="shouldShowTouchControl && videoDeviceType != 'logitech'" />




    <!-- later -->
    <app-iot [iotData]="iotData" [iotEvent]="videoEvent"></app-iot>
    <hr *ngIf="shouldShowTouchControlLogitech || shouldShowTouchControl" />

    <app-video-touch-panel-logitech *ngIf="shouldShowTouchControlLogitech && videoDeviceType == 'logitech' "
      [logitechData]="logitechData" [isDevicePaused]="videoDeviceData?.paused == 1"></app-video-touch-panel-logitech>
    <hr *ngIf="videoDeviceType == 'logitech' && isRemoteControl" />

    <!-- WAITING FOR OTHER API -->
    <!-- <app-video-display-logitech *ngIf="false" [logitechData]="logitechData"></app-video-display-logitech> -->

    <app-video-remote *ngIf="videoDeviceType == 'logitech' && isRemoteControl" [logitechData]="logitechData"
      [isDevicePaused]="videoDeviceData?.paused == 1"></app-video-remote>

    <h5 *ngIf="videoDeviceType != 'logitech'">Packetloss</h5>
    <app-chart-video-packetloss *ngIf="videoDeviceType != 'logitech'"></app-chart-video-packetloss>
  </div>

</div>

<div class="video-device-info-outer">

  <div *ngIf="videoDeviceType == 'logitech'" class="video-device-info-outer__additional-item">
    <strong>Monitored ports:</strong> {{ monitoredPorts }}
    <div>
      <div class="video-device-info-outer__additional-item">
        <strong>Unmonitored ports:</strong> {{ unmonitoredPorts }}
        <div>
          <div class="iot-component">
            <table class="table">
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of iotDevicePortsDetails">
                  <td>
                    <div class=" circle-stat {{ getStatus(item) }}"></div>
                  </td>
                  <td>
                    <div>
                      {{ item.port_name }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ item.port_id }}
                    </div>
                  </td>
                  <td>
                    <div class="checkbox_switch">
                      <label class="switch">
                        <input type="checkbox" [checked]="item.is_monitored == 1" (click)="setIotPortStatus(item)" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="videoDeviceData">
    <h4>Additional</h4>
    <app-video-additional *ngIf="videoDeviceType != 'logitech'"
      [videoDeviceData]="videoDeviceData"></app-video-additional>
    <app-video-additional-logitech *ngIf="videoDeviceType == 'logitech'"
      [videoDeviceData]="videoDeviceData"></app-video-additional-logitech>
  </ng-container>

</div>