<div class="report-cover-sheet">

  <div class="report-cover-sheet__logo">
    <img [src]="dashboardLogo">
  </div>


  <div class="report-cover-sheet__container">
    <div class="report-cover-sheet__container-item">
      <h3>{{siteTitle}}</h3>
      <h3>{{siteSubTitle}}</h3>
      <h3>{{months[fromdate.month-1]}} {{fromdate.day}}, {{fromdate.year}} - {{months[todate.month-1]}} {{todate.day}},
        {{todate.year}}</h3>
      <br>
      <h3>{{companyInfo[0].company_name}}</h3>
      <h3>{{companyInfo[0].address1}}, {{companyInfo[0].address2}}</h3>
      <h3>{{companyInfo[0].city}}, {{companyInfo[0].state}}. {{companyInfo[0].zip}}</h3>
    </div>
    <div class="report-cover-sheet__container-item">
      <div *ngIf="siteSubTitle != 'Inventory Report'" class="report-cover-sheet__letter">
        <h1 *ngIf="letterGrade.avg_health >= 90 || letterGrade.avg_health === 0"
          class="report-cover-sheet__grade-letter text-success">A
          {{letterGrade.videoConferSite}}</h1>
        <h1 *ngIf="letterGrade.avg_health >= 41 && letterGrade.avg_health <= 89"
          class="report-cover-sheet__grade-letter text-warning">B
          {{letterGrade.videoConferSite}}</h1>
        <h1 *ngIf="letterGrade.avg_health <= 40 && letterGrade.avg_health !== 0"
          class="report-cover-sheet__grade-letter text-danger">C
          {{letterGrade.videoConferSite}}</h1>
      </div>
    </div>
  </div>


  <div class="report-cover-sheet__container">
    <div class="report-cover-sheet__container-item">
      <h3>Report Includes</h3>
      <h3 *ngIf="isAllList==false">{{siteName}}</h3>
      <div *ngIf="isAllList">
        <h3>All Sites</h3>
        <p>{{siteSubTitle}}</p>
        <p>Incidents</p>
        <div *ngFor="let sname of siteName; let i = index">
          <h3>{{sname.site_name}}</h3>
          <p>{{siteSubTitle}}</p>
          <p>Incidents</p>
        </div>
      </div>
      <div *ngIf="isAllDeviceList">
        <h3>All Devices</h3>
        <p>{{siteSubTitle}}</p>
        <p>Incidents</p>
        <div *ngFor="let dname of deviceName; let i = index">
          <h3>{{dname}}</h3>
          <p>{{siteSubTitle}}</p>
          <p>Incidents</p>
        </div>
      </div>
      <div *ngIf="isSingleDevice">
        <h3>{{deviceName}}</h3>
        <p>{{siteSubTitle}}</p>
        <p>Incidents</p>
      </div>
    </div>
  </div>
</div>