<div class="info" [ngClass]="{'global-darktheme': darkTheme}">
  <div class="info__title text-break">
    <h2>{{ make }}</h2>
  </div>
  <div class="info__text">
    <div class="info__text_item" *ngIf="spaces&&spaces.length">
      <strong>Space: </strong>
      <span *ngFor="let s of spaces;let i=index;">
       <a  [routerLink]="['/iot/spaces/',  s.space_id]">{{
          s.space_name }}</a>
          <span *ngIf="spaces.length-1 != i">,</span>
      </span>
    </div>
    <div class="info__text_item">
      <strong>Device Name:</strong> {{ displayName | displayEmpty }}
    </div>
    <div class="info__text_item">
      <strong>Model:</strong> {{ model | displayEmpty }}
    </div>
    <div class="info__text_item">
      <strong>IP Address:</strong>{{ ip | displayEmpty }}
      <span class="fav-cog-icon" *ngIf="iotDeviceDetails?.iot_ip">
        <fa-icon [icon]="faCogIcon" size="2x" class="cog-icon" (click)="openWebPortalOptions()"></fa-icon>
      </span>
    </div>
    <div class="info__text_item" (click)="navigateToSite()">
      <strong>Site Name:</strong>
      <span class="info__text-link"> {{ siteName | displayEmpty }}</span>
    </div>
    <!-- <div *ngIf="hostName != 'null' && hostName != null" class="info__text_item">
      <strong>Host Name:</strong>
      <span class="info__text-link"> {{ hostName | displayEmpty }}</span>
    </div> -->
    <div class="info__text_item">
      <strong>MAC:</strong> {{ mac | displayEmpty }}
    </div>
    <div *ngIf="parentName != null" class="info__text_item" (click)="navigateToDnaPage()">
      <strong>Associated with:</strong>
      <span class="info__text-link"> {{ parentName | displayEmpty }}</span>
    </div>
  </div>

  <div class="info__uptime_btn_ctr">
    <div class="info__uptime">
      <!-- <div class="info__{{ statusColor }}">{{ statusText }}</div> -->
      <div [ngClass]="{
        'info__status__online': iotDeviceStatus === 'Online' || iotDeviceStatus === 'Sleep',
        'info__status__offline': iotDeviceStatus === 'Offline' ||  iotDeviceStatus === 'Collector Offline',
        'info__status__warning': showWarningStatus && iotDeviceStatus === 'Collector Offline',
        'info__status__loading': iotDeviceStatus === 'Teleportivity',
        'info__status__paused': iotDeviceStatus === 'Paused'
      }">
      {{ iotDeviceStatus }}
</div>
    </div>
    <div class="info__uptime info__btn">
      <app-btn-info label="reboot" [faName]="faIcon" *ngIf="isShowReboot()" (click)="openRebootModal()">
      </app-btn-info>
      <app-btn-info label="{{ pause == 1 ? 'unpause' : 'pause' }}" [faName]="pause == 1 ? faPauseIcon : faPlayIcon"
        (click)="setPaused()">
      </app-btn-info>
      <app-btn-info label="admin" *ngIf="this.iotDeviceDetails" [faName]="faIcon"
        (click)="sendPopupType(75)"></app-btn-info>
      <app-btn-info label="wakeup" [faName]="faIcon" *ngIf="isShowWakeUp()" (click)="wakeDevice()"></app-btn-info>
    </div>
  </div>

  <hr />
  <div class="info__chart_title">
    <h4>Live Stats Range</h4>
  </div>
  <div class="chart-historic-ping-range">
    <canvas id="myChartSite" width="300px" height="210px"></canvas>
  </div>
</div>

<div class="outer-info">
  <div class="outer-info__additional">
    <h4>Additional</h4>
    <div class="outer-info__additional_item">
      <strong>Monitored ports:</strong> {{ monitoredPorts }}
      <div>
        <div class="outer-info__additional_item">
          <strong>Unmonitored ports:</strong> {{ unmonitoredPorts }}
          <div>
            <div class="iot-component">
              <table class="table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of iotDevicePortsDetails">
                    <td>
                      <div class=" circle-stat {{ getStatus(item) }}"></div>
                    </td>
                    <td>
                      <div>
                        {{ item.port_name }}
                      </div>
                    </td>
                    <td>
                      <div>
                        {{ item.port_id }}
                      </div>
                    </td>
                    <td>
                      <div class="checkbox_switch">
                        <label class="switch">
                          <input type="checkbox" [checked]="item.is_monitored == 1" (click)="setIotPortStatus(item)" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>