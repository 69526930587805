import * as echarts from 'echarts'
import * as moment from 'moment-timezone'
import { AuthService } from 'src/app/services/auth.service'
import { GetVideoService } from 'src/app/services/get-video.service'

import { DatePipe } from '@angular/common'
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

@Component({
	selector: 'app-echart-video-dna',
	templateUrl: './echart-video-dna.component.html',
	styleUrls: ['./echart-video-dna.component.scss'],
})
export class EchartVideoDnaComponent implements OnChanges {
	toggleValue: any = 0
	activeBtn: any = 1
	areNumbersRounded = true
	printMode: boolean = false
	isLoading: boolean = false
	printTableData = []
	currentChart = '24h'
	currentEChart = 'minutes'
	@Input() deviceId: any
	@Input() popupState: any
	@Input() data: any
	@Input() deviceType: any
	public constructor(
		private _detector: ChangeDetectorRef,
		private Auth: AuthService,
		private datePipe: DatePipe,
		private _videoService: GetVideoService
	) { }
	filterType: string = 'all'
	@ViewChild('valueTooltip', { static: true })
	public valueTooltip: TemplateRef<object>
	includedProperties = []
	includedProperties_EChart = []
	canvas: any
	tempPopupState: any
	myChartSite: any
	ctx: any
	chartApiData: any
	graphData: any
	datasetFirstAllFilter: any
	datasetSecondAllFilter: any
	datasetThirdAllFilter: any
	chartData = {
		datasets: [
			{
				label: 'incident',
				data: [],
				backgroundColor: 'rgb(184, 58, 36, 1)',
				pointBackgroundColor: 'rgb(184, 58, 36, 1)',
				pointBorderColor: 'rgb(184, 58, 36, 1)',
				borderWidth: 1,
				showLine: false,
				hidden: false,
			},
			{
				label: 'mbps (mb/s)',
				data: [],
				backgroundColor: 'rgb(255, 184, 0, 1)',
				pointBackgroundColor: 'rgb(211,211,211, 1)',
				pointBorderColor: 'rgb(211,211,211, 1)',
				borderColor: 'rgb(211,211,211, 1)',
				borderWidth: 1,
				hidden: false,
				fill: false,
			},
			{
				label: 'jitter (msec)',
				data: [],
				backgroundColor: 'rgb(173,216,230, 1)',
				pointBackgroundColor: 'rgb(173,216,230, 1)',
				pointBorderColor: 'rgb(173,216,230, 1)',
				borderColor: 'rgb(173,216,230, 1)',
				borderWidth: 0,
				showLine: true,
				fill: false,
				hidden: false,
			},
			{
				label: 'latency (msec)',
				data: [],
				backgroundColor: 'rgb(144,238,144, 1)',
				pointBackgroundColor: 'rgb(144,238,144, 1)',
				pointBorderColor: 'rgb(144,238,144, 1)',
				borderColor: 'rgb(142, 204, 80, 1)',
				borderWidth: 1,
				showLine: true,
				fill: false,
				hidden: false,
			},
		],
	}
	// --- E-Chart Configuration --------------------------------//
	@ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef

	chart!: echarts.ECharts
	fullData: any[] = []
	timeMode: string = '60m'

	dummyData: any = {
		datasets: [
			{
				label: 'Incident',
				color: 'rgb(184, 58, 36)',
				data: this.generateData(60),
			},
			{
				label: 'Mbps (mb/s)',
				color: 'rgb(255, 184, 0)',
				data: this.generateData(60),
			},
			{
				label: 'Jitter (msec)',
				color: 'rgb(173, 216, 230)',
				data: this.generateData(60),
			},
			{
				label: 'Latency (msec)',
				color: 'rgb(144, 238, 144)',
				data: this.generateData(60),
			},
		],
	}

	eChartData = {
		tooltip: {
			trigger: 'axis',
		},
		legend: {
			data: ['Incident', 'Mbps (mb/s)', 'Jitter (msec)', 'Latency (msec)'],
			bottom: 10,
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: [], // Time or X-axis data will be populated here
		},
		yAxis: {
			type: 'value',
			axisLabel: {
				formatter: '{value}'
			}
		},
		series: [
			{
				name: 'Incident',
				type: 'line',
				data: [],
				symbol: 'circle',
				symbolSize: 8,
				itemStyle: {
					color: 'red'
				},
				lineStyle: {
					color: 'red'
				},
				showSymbol: true,
				tooltip: {
					valueFormatter: function (value) {
						return value !== undefined && value !== null
							? value + ' incidents'
							: 'No Data'
					},
				},
			},
			{
				name: 'Mbps (mb/s)',
				type: 'line',
				data: [],
				symbol: 'none',
				symbolSize: 8,
				itemStyle: {
					color: 'rgb(255, 184, 0)',
				},
				lineStyle: {
					color: 'rgb(255, 184, 0)',
				},
				tooltip: {
					valueFormatter: function (value) {
						return value !== undefined && value !== null
							? value + ' mb/s'
							: 'No Data'
					},
				},
			},
			{
				name: 'Jitter (msec)',
				type: 'line',
				data: [],
				symbol: 'none',
				symbolSize: 8,
				itemStyle: {
					color: 'rgb(173,216,230)',
				},
				lineStyle: {
					color: 'rgb(173,216,230)',
				},
				tooltip: {
					valueFormatter: function (value) {
						return value !== undefined && value !== null
							? value + ' ms'
							: 'No Data'
					},
				},
			},
			{
				name: 'Latency (msec)',
				type: 'line',
				data: [],
				symbol: 'none',
				itemStyle: {
					color: 'rgb(144,238,144)',
				},
				lineStyle: {
					color: 'rgb(144,238,144)',
				},
				tooltip: {
					valueFormatter: function (value) {
						return value !== undefined && value !== null
							? value + ' ms'
							: 'No Data'
					},
				},
			}
		],
	};

	eChartAPIData = []


	ngAfterViewInit() {
		// this.chart = echarts.init(this.chartContainer.nativeElement)
		// this.fullData = this.data.datasets
		// this.updateChart(this.timeMode)
		// this.loadEChart()
		// this.initializeEChart();
	}

	// 	ngOnDestroy() {
	//     try {
	//         if (this.chart) {
	//             console.log('Cleaning up chart in ngOnDestroy');
	//             this.chart.dispose();
	//             this.chart = null;  // Clear the reference
	//         }

	//         // Clean up any subscriptions if you have them
	//         // if (this.subscriptions) {
	//         //     this.subscriptions.unsubscribe();
	//         // }

	//         // Clear any stored data
	//         this.eChartData.series = [];
	//         this.eChartData.xAxis.data = [];
	//         this.graphData = null;
	//         this.eChartAPIData = null;
	//     } catch (e) {
	//         console.warn('Error during cleanup:', e);
	//     }
	// }

	updateChart(mode: string) {
		this.timeMode = mode
		const now = new Date()
		let filteredData = this.fullData.map((dataset: any) => ({
			...dataset,
			data: dataset.data.filter(([timestamp]: [string, number]) => {
				const time = new Date(timestamp)
				if (mode === '60m') return time >= new Date(now.getTime() - 60 * 60 * 1000)
				if (mode === '24h') return time >= new Date(now.getTime() - 24 * 60 * 60 * 1000)
				if (mode === '7d') return time >= new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
				return false
			}),
		}))

		let timeFormat = '{HH}:{mm}'
		if (mode === '24h') timeFormat = '{HH}:00'
		if (mode === '7d') timeFormat = '{MM}-{dd}'

		const series = filteredData.map((dataset: any) => ({
			name: dataset.label,
			type: 'scatter', // Ensure scatter plot
			data: dataset.data,
			symbolSize: 8,
			itemStyle: { color: dataset.color },
		}))

		const options = {
			tooltip: { trigger: 'axis' },
			xAxis: { type: 'time', axisLabel: { formatter: timeFormat } },
			legend: { data: this.data.datasets.map((d: any) => d.label) },
			yAxis: { type: 'value' },
			series,
		}

		this.chart.setOption(options)
	}

	// renderEchart() {
	// 	if (!this.chart) {
	// 		this.chart = echarts.init(this.chartContainer.nativeElement)
	// 	}

	// 	const option = {
	// 		xAxis: {
	// 			type: 'category',
	// 			data: this.fullData[0].data.map(point => point.time),
	// 		},
	// 		yAxis: {
	// 			type: 'value',
	// 		},
	// 		series: this.fullData.map(seriesItem => ({
	// 			name: seriesItem.label,
	// 			type: 'line',
	// 			data: seriesItem.data.map(point => point.value),
	// 			color: seriesItem.color,
	// 		})),
	// 	}

	// 	this.chart.setOption(option)
	// }

	// renderEchart() { // Current
	// 	if (!this.chart) {
	// 		this.chart = echarts.init(this.chartContainer.nativeElement)
	// 	}

	// 	const option = {
	// 		xAxis: {
	// 			type: 'category',
	// 			data: this.fullData[0].data.map(point => point.time),
	// 		},
	// 		yAxis: {
	// 			type: 'value',
	// 		},
	// 		series: this.fullData.map(seriesItem => ({
	// 			name: seriesItem.label,
	// 			type: 'scatter',
	// 			data: seriesItem.data.map(point => point.value),
	// 			color: seriesItem.color,
	// 		})),
	// 	}

	// 	this.chart.setOption(option)
	// }

	renderEchart() {
		if (!this.chart) {
			this.chart = echarts.init(this.chartContainer.nativeElement)
		}

		// Extract date from timestamp for x-axis
		const extractDate = (timestamp: string) => moment(timestamp).format('YYYY-MM-DD') // Extract date in YYYY-MM-DD format

		// Populate xAxis data (dates)
		this.eChartData.xAxis.data = this.graphData.mbps_total.map(item => extractDate(item.x)) // Extract dates for x-axis

		// Populate series data
		this.eChartData.series[0].data = this.graphData.incidents ? this.graphData.incidents.map(item => item.value) : [] // Incident data
		this.eChartData.series[1].data = this.graphData.mbps_total.map(item => item.y) // Mbps data
		this.eChartData.series[2].data = this.graphData.total_jitter.map(item => item.y) // Jitter data
		this.eChartData.series[3].data = this.graphData.total_latency.map(item => item.y) // Latency data

		// Set the chart option using the updated eChartData
		this.chart.setOption(this.eChartData)
	}



	// updateChartData(range: string) {
	// 	// For testing with dummy data
	// 	if (range === '60m') {
	// 		this.fullData = this.generateDummyData(60, 'minutes')
	// 	} else if (range === '24h') {
	// 		this.fullData = this.generateDummyData(24, 'hours')
	// 	} else if (range === '7d') {
	// 		this.fullData = this.generateDummyData(7, 'days')
	// 	}
	// }

	updateChartData(range: '60m' | '24h' | '7d') {
		const count = range === '60m' ? 60 : range === '24h' ? 24 : 7
		const unit = range === '60m' ? 'minutes' : range === '24h' ? 'hours' : 'days'

		console.log('updateChartData - count, unit', count, unit)

		// Call fetch_echart_API_data method to fetch and update the chart data
		this.fetch_echart_API_data(count, unit)
	}

	generateDummyData(count: number, unit: 'minutes' | 'hours' | 'days') {
		const now = moment()

		return [
			{
				label: 'Incident',
				color: 'rgb(184, 58, 36)',
				data: Array.from({ length: count }, (_, i) => ({
					time: now
						.clone()
						.subtract(count - i, unit)
						.format('YYYY-MM-DD HH:mm:ss'),
					value: Math.floor(Math.random() * 100), // Random incident count
				})),
			},
			{
				label: 'Mbps (mb/s)',
				color: 'rgb(255, 184, 0)',
				data: Array.from({ length: count }, (_, i) => ({
					time: now
						.clone()
						.subtract(count - i, unit)
						.format('YYYY-MM-DD HH:mm:ss'),
					value: Math.random() * 50, // Random speed
				})),
			},
			// Add jitter and latency as needed
		]
	}

	setTimeRange(range: '60m' | '24h' | '7d') {
		this.activeBtn = range === '60m' ? 1 : range === '24h' ? 2 : 3
		this.timeMode = range

		// Fetch or generate new data
		this.updateChartData(range)
		console.log('SetTimeRange:', this.timeMode)

		// Refresh chart with new data
		// this.renderEchart() -> Current
		// this.initChart()
	}

	// initChart() {
	// 	this.chart = echarts.init(this.chartContainer.nativeElement)

	// 	// Initial empty option (can be updated later with real data)
	// 	const chartOption = {
	// 		title: {
	// 			text: '',
	// 		},
	// 		tooltip: {
	// 			trigger: 'axis',
	// 		},
	// 		legend: {
	// 			data: ['Incident', 'Mbps (mb/s)', 'Jitter/Latency/PacketLoss'],
	// 		},
	// 		xAxis: {
	// 			type: 'category',
	// 			data: [], // Placeholder for time data, to be updated
	// 		},
	// 		yAxis: {
	// 			type: 'value',
	// 		},
	// 		series: [
	// 			{
	// 				name: 'Incident',
	// 				type: 'scatter',
	// 				data: [], // Placeholder for incident data
	// 				color: 'rgb(184, 58, 36)', // Incident color
	// 			},
	// 			{
	// 				name: 'Mbps (mb/s)',
	// 				type: 'scatter',
	// 				data: [], // Placeholder for Mbps data
	// 				color: 'rgb(255, 184, 0)', // Mbps color
	// 			},
	// 			{
	// 				name: 'Jitter/Latency/PacketLoss',
	// 				type: 'scatter',
	// 				data: [], // Placeholder for jitter/latency/packetloss data
	// 				color: 'rgb(0, 255, 255)', // Jitter color
	// 			},
	// 		],
	// 	}

	// 	// Set the initial option to the chart
	// 	this.chart.setOption(chartOption)
	// }
	initChart() {
		this.chart = echarts.init(this.chartContainer.nativeElement)

		// Initial empty option (can be updated later with real data)
		const chartOption = {
			title: {
				text: '',
			},
			tooltip: {
				trigger: 'axis',
			},
			legend: {
				data: ['Incident', 'Mbps (mb/s)', 'Jitter/Latency/PacketLoss'],
			},
			xAxis: {
				type: 'category',
				data: [], // Placeholder for time data, to be updated
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					name: 'Incident',
					type: 'scatter',
					data: [], // Placeholder for incident data
					color: 'rgb(184, 58, 36)', // Incident color
				},
				{
					name: 'Mbps (mb/s)',
					type: 'scatter',
					data: [], // Placeholder for Mbps data
					color: 'rgb(255, 184, 0)', // Mbps color
				},
				{
					name: 'Jitter/Latency/PacketLoss',
					type: 'scatter',
					data: [], // Placeholder for jitter/latency/packetloss data
					color: 'rgb(0, 255, 255)', // Jitter color
				},
			],
		}

		// Extract time from graphData for x-axis
		const extractTime = (timestamp: string) => moment(timestamp).format('HH:mm:ss') // Extract time as hh:mm:ss
		const xAxisData = this.graphData.mbps_total.map(item => extractTime(item.x)) // Use the same time format for other sections

		// Map series data for incidents, Mbps, jitter, latency, and packet loss
		const incidentData = this.graphData.incidents ? this.graphData.incidents.map(item => item) : [] // Incident data (can be null or an array)
		const mbpsData = this.graphData.mbps_total.map(item => item.y) // Mbps data
		const jitterData = this.graphData.total_jitter.map(item => item.y) // Jitter data
		const latencyData = this.graphData.total_latency.map(item => item.y) // Latency data
		const packetLossData = this.graphData.total_packetloss.map(item => item.y) // PacketLoss data

		// Update chart option with new xAxis and series data
		chartOption.xAxis.data = xAxisData // Set x-axis data to the formatted time
		chartOption.series[0].data = incidentData // Set incident data
		chartOption.series[1].data = mbpsData // Set Mbps data
		chartOption.series[2].data = jitterData.concat(latencyData).concat(packetLossData) // Set Jitter, Latency, PacketLoss data

		// Set the updated option to the chart
		this.chart.setOption(chartOption)
	}


	// fetch_echart_API_data(count: number, unit: 'minutes' | 'hours' | 'days') {
	// 	const now = moment()
	// 	const token = localStorage.getItem('token')
	// 	const helper = new JwtHelperService()
	// 	const decodedToken = helper.decodeToken(token)

	// 	const deviceDetails = {
	// 		company_id: decodedToken.company_id,
	// 		device_id: this.data.deviceId,
	// 		duration: unit,
	// 	}

	// 	// Fetch data from the API
	// 	this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
	// 		const graphData = data['response'][0] // Assuming the structure of the data
	// 		let datasetFirst = [],
	// 			datasetSecond = [],
	// 			datasetThird = []

	// 		// Transform data into the structure required for eChart
	// 		const transformData = (dataset: any, label: string, color: string) => {
	// 			return {
	// 				label,
	// 				color,
	// 				data: Array.from({ length: count }, (_, i) => ({
	// 					time: now
	// 						.clone()
	// 						.subtract(count - i, unit)
	// 						.format('YYYY-MM-DD HH:mm:ss'),
	// 					value: dataset[i] || Math.random() * 100, // Use real data or random if missing
	// 				})),
	// 			}
	// 		}

	// 		// Map API data into datasets
	// 		switch (this.data.section) {
	// 			case 'mbps_out':
	// 			case 'mbps_in':
	// 				datasetFirst = graphData.mbps_total
	// 				datasetSecond = graphData.mbps_in
	// 				datasetThird = graphData.mbps_out
	// 				break
	// 			case 'jitter_out':
	// 			case 'jitter_in':
	// 				datasetFirst = graphData.total_jitter
	// 				datasetSecond = graphData.jitter_in
	// 				datasetThird = graphData.jitter_out
	// 				break
	// 			case 'packetloss_out':
	// 			case 'packetloss_in':
	// 				datasetFirst = graphData.total_packetloss
	// 				datasetSecond = graphData.packetloss_in
	// 				datasetThird = graphData.packetloss_out
	// 				break
	// 			case 'latency_out':
	// 			case 'latency_in':
	// 				datasetFirst = graphData.total_latency
	// 				datasetSecond = graphData.latency_in
	// 				datasetThird = graphData.latency_out
	// 				break
	// 			default:
	// 				datasetFirst = graphData.mbps_total
	// 				datasetSecond = graphData.mbps_in
	// 				datasetThird = graphData.mbps_out
	// 		}

	// 		// Transform the datasets using the helper function
	// 		this.fullData = [
	// 			transformData(datasetFirst, 'Incident', 'rgb(184, 58, 36)'), // Example of Incident dataset
	// 			transformData(datasetSecond, 'Mbps (mb/s)', 'rgb(255, 184, 0)'), // Mbps dataset
	// 			transformData(datasetThird, 'Jitter/Latency/PacketLoss', 'rgb(0, 255, 255)'), // Example of third dataset (adjust label)
	// 		]

	// 		// Update the chart's x-axis and series data
	// 		const times = this.fullData[0].data.map(item => item.time) // Get the time labels from the first dataset

	// 		// Prepare the new chart option with updated data
	// 		const updatedChartOption = {
	// 			xAxis: {
	// 				data: times, // Set time as x-axis labels
	// 			},
	// 			series: this.fullData.map((dataset, index) => ({
	// 				name: dataset.label,
	// 				type: 'scatter',
	// 				data: dataset.data.map(item => item.value),
	// 				color: dataset.color,
	// 			})),
	// 		}

	// 		// Apply the updated option to the chart
	// 		this.chart.setOption(updatedChartOption)
	// 	})
	// }

	//*Current Method*/
	// fetch_echart_API_data(count: number, unit: 'minutes' | 'hours' | 'days') {
	// 	const now = moment()
	// 	const token = localStorage.getItem('token')
	// 	const helper = new JwtHelperService()
	// 	const decodedToken = helper.decodeToken(token)

	// 	const deviceDetails = {
	// 		company_id: decodedToken.company_id,
	// 		device_id: this.data.deviceId,
	// 		duration: count,
	// 	}

	// 	this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {


	// 		this.eChartAPIData = data['response']?.[0]
	// 		this.graphData = data['response']?.[0]
	// 		console.log('this.chartApiData:', this.eChartAPIData)
	// 		console.log('fetch_echart_api_data:', data)
	// 		console.log('this.data.section:', this.data.section)

	// 		let datasetFirst, datasetSecond, datasetThird
	// 		const firstRecord = this.graphData
	// 		console.log('firstRecord:', firstRecord)

	// 		if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
	// 			this.eChartData.series[0].name = 'Incident';
	// 			this.eChartData.series[1].name = 'Mbps Total';
	// 			this.eChartData.series[2].name = 'Mbps RX';
	// 			this.eChartData.series[3].name = 'Mbps TX';
	// 			datasetFirst = firstRecord.mbps_total
	// 			datasetSecond = firstRecord.mbps_in
	// 			datasetThird = firstRecord.mbps_out
	// 		} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
	// 			this.eChartData.series[0].name = 'Incident';
	// 			this.eChartData.series[1].name = 'Jitter (msec) Total';
	// 			this.eChartData.series[2].name = 'Jitter (msec) RX';
	// 			this.eChartData.series[3].name = 'Jitter (msec) TX';
	// 			datasetFirst = firstRecord.total_jitter
	// 			datasetSecond = firstRecord.jitter_in
	// 			datasetThird = firstRecord.jitter_out
	// 		} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
	// 			this.eChartData.series[0].name = 'Incident';
	// 			this.eChartData.series[1].name = 'Packet Loss(%) Total';
	// 			this.eChartData.series[2].name = 'Packet Loss(%) RX';
	// 			this.eChartData.series[3].name = 'Packet Loss(%) TX';
	// 			datasetFirst = firstRecord.total_packetloss
	// 			datasetSecond = firstRecord.packetloss_in
	// 			datasetThird = firstRecord.packetloss_out
	// 		} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
	// 			this.eChartData.series[0].name = 'Incident';
	// 			this.eChartData.series[1].name = 'Latency Total';
	// 			this.eChartData.series[2].name = 'Latency RX';
	// 			this.eChartData.series[3].name = 'Latency TX';
	// 			datasetFirst = firstRecord.total_latency
	// 			datasetSecond = firstRecord.latency_in
	// 			datasetThird = firstRecord.latency_out
	// 		}

	// 		// Sort the datasets
	// 		datasetFirst?.sort(this.custom_sort)
	// 		datasetSecond?.sort(this.custom_sort)
	// 		datasetThird?.sort(this.custom_sort)

	// 		// Sort other metrics
	// 		const latency = firstRecord.total_latency?.sort(this.custom_sort) || []
	// 		const jitter = firstRecord.total_jitter?.sort(this.custom_sort) || []
	// 		const mbps = firstRecord.mbps_total?.sort(this.custom_sort) || []
	// 		const packetloss = firstRecord.total_packetloss?.sort(this.custom_sort) || []
	// 		const incidents = firstRecord.incidents || []

	// 		for (let x = 0; x < datasetFirst.length; x++) {
	// 			if (datasetFirst[x].y === 0) {
	// 				datasetFirst[x].y = null
	// 			}
	// 		}
	// 		for (let x = 0; x < datasetSecond.length; x++) {
	// 			if (datasetSecond[x].y === 0) {
	// 				datasetSecond[x].y = null
	// 			}
	// 		}
	// 		for (let x = 0; x < datasetThird.length; x++) {
	// 			if (datasetThird[x].y === 0) {
	// 				datasetThird[x].y = null
	// 			}
	// 		}
	// 		// You can now use these sorted arrays...



	// 		// -- X-Axis configuration --//
	// 		// const extractDate = (timestamp: string) => moment(timestamp).format('YYYY-MM-DD') // Extract date as YYYY-MM-DD
	// 		// const xAxisData = this.graphData.mbps_total.map(item => extractDate(item.x)) // Use the date format
	// 		// Use the same time format for other sections

	// 		// const extractDate = (timestamp: string) => moment(timestamp).format('YYYY-MM-DD HH:mm:ss') // Keep date + time

	// 		const extractDate = (timestamp: string) => {
	//   // Parse the date, telling moment to try different formats
	// 				const parsed = moment(timestamp, [
	// 						'MM/DD/YYYY HH:mm:ssZ',  // Format from incidents
	// 						'YYYY-MM-DD HH:mm:ss'    // Original format
	// 				]);

	// 				// For debugging
	// 				// console.log('Parsing timestamp:', {
	// 				// 		original: timestamp,
	// 				// 		parsed: parsed.format('YYYY-MM-DD HH:mm:ss'),
	// 				// 		isValid: parsed.isValid()
	// 				// });

	// 				return parsed.format('YYYY-MM-DD HH:mm:ss');
	// 		}

	// 		const allDataPoints = [ // -> Current
	// 			...datasetFirst.map(item => item.x),
	// 			...datasetSecond.map(item => item.x),
	// 			...datasetThird.map(item => item.x),
	// 			...incidents.map(item => item.x)
	// 		]

	// 		// const allDataPoints = incidents.map(item => item.x);


	// 		// const allDataPoints = [
	// 		// 	...datasetFirst.map(item => moment(item.x).format('YYYY-MM-DD HH:mm:ss')),
	// 		// 	...datasetSecond.map(item => moment(item.x).format('YYYY-MM-DD HH:mm:ss')),
	// 		// 	...datasetThird.map(item => moment(item.x).format('YYYY-MM-DD HH:mm:ss')),
	// 		// 	...incidents.map(item => moment(item.x, 'MM/DD/YYYY HH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss'))
	// 		// ];

	// 		// Get unique x values sorted in ascending order
	// 		// const uniqueXAxisData = [...new Set(allDataPoints)].sort((a, b) => moment(a).isBefore(moment(b)) ? -1 : 1)

	// 		// // Map the unique x values into the desired date-time format
	// 		// const xAxisData = uniqueXAxisData.map(item => extractDate(item))

	// 		// // Get unique x values sorted in ascending order -> Current
	// 		const uniqueXAxisData = [...new Set(allDataPoints)].sort((a, b) =>
	// 				moment(a).isBefore(moment(b)) ? -1 : 1
	// 		);

	// 		// Get unique x values sorted in ascending order
	// 		// const uniqueXAxisData = [...new Set(allDataPoints)].sort((a, b) =>
	// 		// 		moment(a, 'MM/DD/YYYY HH:mm:ssZ').isBefore(moment(b, 'MM/DD/YYYY HH:mm:ssZ')) ? -1 : 1
	// 		// );

	// 		// Map the unique x values into the desired date-time format -> Current
	// 		let xAxisData = uniqueXAxisData.map(timestamp =>
	// 				moment(timestamp, 'MM/DD/YYYY HH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss')
	// 		);

	// 		// // Get timestamps from the first dataset (datasetFirst)
	// 		// const allDataPoints = datasetFirst.map(item => moment(item.x).format('YYYY-MM-DD HH:mm:ss'));

	// 		// // Get unique x values sorted in ascending order
	// 		// const uniqueXAxisData = [...new Set(allDataPoints)].sort((a, b) =>
	// 		// 		moment(a).isBefore(moment(b)) ? -1 : 1
	// 		// );
	// 		// const xAxisData = uniqueXAxisData;

	// 		// const mapDataToXAxis = (dataset, xAxisData, isIncident = false) => {
	// 		// 		return xAxisData.map(xVal => {
	// 		// 				const foundItem = dataset.find(item => extractDate(item.x) === xVal)
	// 		// 				if (!foundItem) return null;

	// 		// 				if (isIncident) {
	// 		// 						// Special handling for incidents
	// 		// 						return {
	// 		// 								value: foundItem.y,
	// 		// 								incident_id: foundItem.incident_id,
	// 		// 								triggers: foundItem.triggers,
	// 		// 								time: moment(foundItem.x).valueOf()
	// 		// 						};
	// 		// 				}
	// 		// 				return foundItem.y;  // Normal data handling
	// 		// 		})
	// 		// }

	// 		const mapDataToXAxis = (dataset, xAxisData, isIncident = false) => {
	// 				return xAxisData.map(xVal => {
	// 						const foundItem = dataset.find(item => {
	// 								const itemDate = extractDate(item.x);
	// 								const xValDate = xVal; // xVal is already formatted
	// 								return itemDate === xValDate;
	// 						});

	// 					//  console.log('mapDataToXAxis - foundItem:', foundItem)

	// 						if (!foundItem) return null;

	// 						if (isIncident) {
	// 								return {
	// 										value: foundItem.y,
	// 										incident_id: foundItem.incident_id,
	// 										triggers: foundItem.triggers,
	// 										time: moment(foundItem.x, 'MM/DD/YYYY HH:mm:ssZ').valueOf() // Use specific format for incidents
	// 								};
	// 						}
	// 						return foundItem.y;
	// 				});
	// 		}

	// 		// --- //

	// 		// Prepare series data for ECharts
	// 		const seriesData = [
	// 				// {
	// 				// 		name: this.eChartData.series[0].name,  // Use dynamic name instead of hardcoded 'Incident'
	// 				// 		type: 'line',
	// 				// 		data: mapDataToXAxis(incidents, xAxisData),
	// 				// 		symbol: 'circle',
	// 				// 		symbolSize: 8,
	// 				// 		itemStyle: { color: 'red' },
	// 				// 		lineStyle: { color: 'red' },
	// 				// 		showSymbol: true,
	// 				// 		tooltip: {
	// 				// 				valueFormatter: function (value) {
	// 				// 						return value !== undefined && value !== null
	// 				// 								? value + ' incidents'
	// 				// 								: 'No Data';
	// 				// 				},
	// 				// 		}
	// 			// },
	// 				{
	// 						name: this.eChartData.series[0].name,
	// 						type: 'line',
	// 						data: mapDataToXAxis(incidents, xAxisData, true),  // Add true parameter for incidents
	// 						symbol: 'circle',
	// 						symbolSize: 8,
	// 						itemStyle: { color: 'red' },
	// 						lineStyle: { color: 'red' },
	// 						showSymbol: true,
	// 						tooltip: {
	// 								valueFormatter: function (value) {
	// 										return value !== undefined && value !== null
	// 												? value + ' incidents'
	// 												: 'No Data';
	// 								},
	// 						}
	// 				},
	// 				{
	// 						name: this.eChartData.series[1].name,  // Use dynamic name instead of hardcoded 'Mbps (mb/s)'
	// 						type: 'line',
	// 						data: mapDataToXAxis(datasetFirst, xAxisData),
	// 						symbol: 'none',
	// 						symbolSize: 8,
	// 						itemStyle: { color: 'rgb(255, 184, 0)' },
	// 						lineStyle: { color: 'rgb(255, 184, 0)' },
	// 						tooltip: {
	// 								valueFormatter: function (value) {
	// 										return value !== undefined && value !== null
	// 												? value + ' mb/s'
	// 												: 'No Data';
	// 								},
	// 						}
	// 				},
	// 				{
	// 						name: this.eChartData.series[2].name,  // Use dynamic name instead of hardcoded 'Jitter (msec)'
	// 						type: 'line',
	// 						data: mapDataToXAxis(datasetSecond, xAxisData),
	// 						symbol: 'none',
	// 						symbolSize: 8,
	// 						itemStyle: { color: 'rgb(173, 216, 230)' },
	// 						lineStyle: { color: 'rgb(173, 216, 230)' },
	// 						tooltip: {
	// 								valueFormatter: function (value) {
	// 										return value !== undefined && value !== null
	// 												? value + ' ms'
	// 												: 'No Data';
	// 								},
	// 						}
	// 				},
	// 				{
	// 						name: this.eChartData.series[3].name,  // Use dynamic name instead of hardcoded 'Latency (msec)'
	// 						type: 'line',
	// 						data: mapDataToXAxis(datasetThird, xAxisData),
	// 						symbol: 'none',
	// 						symbolSize: 8,
	// 						itemStyle: { color: 'rgb(144, 238, 144)' },
	// 						lineStyle: { color: 'rgb(144, 238, 144)' },
	// 						tooltip: {
	// 								valueFormatter: function (value) {
	// 										return value !== undefined && value !== null
	// 												? value + ' ms'
	// 												: 'No Data';
	// 								},
	// 						}
	// 				}
	// 		];

	// 		// const times = this.graphData.incidents.map(item => item.x)
	// 		// console.log('times:', times)
	// 		// xAxisData = times

	// 		// Update only the necessary parts
	// 		this.eChartData.xAxis.data = xAxisData
	// 		this.eChartData.series = seriesData

	// 		// If you need to update legend data
	// 		// this.eChartData.legend.data = ['Incidents', 'Mbps (mb/s)', 'Jitter (msec)', 'Latency (msec)']

	// 		// Debug final chart data
	// 		console.log('EChart data - this.eChartData:', {
	// 				xAxisPoints: this.eChartData.xAxis.data.length,
	// 				seriesPoints: this.eChartData.series.map(s => ({
	// 						name: s.name,
	// 						dataPoints: s.data.filter(d => d !== null).length
	// 				}))
	// 		});

	// 		// this.chart.setOption(this.eChartData) // Assuming chartInstance is your ECharts instance
	// 		this.loadEChart();
	// 		// this.initializeEChart();
	// 		// if (this.chart) {
	// 		// 		this.loadEChart();  // Use loadEChart instead of direct setOption
	// 		// } else {
	// 		// 	this.initializeEChart();
	// 		// }
	// 	})
	// }

	fetch_echart_API_data(count: number, unit: 'minutes' | 'hours' | 'days') {
		const now = moment()
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)

		const deviceDetails = {
			company_id: decodedToken.company_id,
			device_id: this.data.deviceId,
			duration: count,
		}

		this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
			this.eChartAPIData = data['response']?.[0]
			this.graphData = data['response']?.[0]

			console.log('Raw API Response:', data)
			console.log('Parsed GraphData:', this.graphData)

			let datasetFirst, datasetSecond, datasetThird
			const firstRecord = this.graphData
			const incidents = firstRecord.incidents || []

			if (!firstRecord) {
				console.error('No data received from API')
				return
			}

			if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
				this.eChartData.series[0].name = 'Incident'
				this.eChartData.series[1].name = 'Mbps Total'
				this.eChartData.series[2].name = 'Mbps RX'
				this.eChartData.series[3].name = 'Mbps TX'
				datasetFirst = firstRecord.mbps_total || []
				datasetSecond = firstRecord.mbps_in || []
				datasetThird = firstRecord.mbps_out || []
			} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
				this.eChartData.series[0].name = 'Incident'
				this.eChartData.series[1].name = 'Jitter (msec) Total'
				this.eChartData.series[2].name = 'Jitter (msec) RX'
				this.eChartData.series[3].name = 'Jitter (msec) TX'
				datasetFirst = firstRecord.total_jitter || []
				datasetSecond = firstRecord.jitter_in || []
				datasetThird = firstRecord.jitter_out || []
			} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
				this.eChartData.series[0].name = 'Incident'
				this.eChartData.series[1].name = 'Packet Loss(%) Total'
				this.eChartData.series[2].name = 'Packet Loss(%) RX'
				this.eChartData.series[3].name = 'Packet Loss(%) TX'
				datasetFirst = firstRecord.total_packetloss || []
				datasetSecond = firstRecord.packetloss_in || []
				datasetThird = firstRecord.packetloss_out || []
			} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
				this.eChartData.series[0].name = 'Incident'
				this.eChartData.series[1].name = 'Latency Total'
				this.eChartData.series[2].name = 'Latency RX'
				this.eChartData.series[3].name = 'Latency TX'
				datasetFirst = firstRecord.total_latency || []
				datasetSecond = firstRecord.latency_in || []
				datasetThird = firstRecord.latency_out || []
			}

			// Use raw incident timestamps for x-axis
			const xAxisData = incidents
				.map(item => item.x)
				.sort((a, b) => moment(a, 'MM/DD/YYYY HH:mm:ssZ').isBefore(moment(b, 'MM/DD/YYYY HH:mm:ssZ')) ? -1 : 1)

			console.log('X-axis data from incidents:', xAxisData)

			// const mapDataToXAxis = (dataset, xAxisData, isIncident = false) => { -- Current
			// 	return xAxisData.map(xVal => {
			// 		const foundItem = dataset.find(item => {
			// 			if (isIncident) {
			// 				return item.x === xVal
			// 			} else {
			// 				// For metrics, find the closest matching timestamp within 1 second
			// 				const xValMoment = moment(xVal, 'MM/DD/YYYY HH:mm:ssZ')
			// 				const itemMoment = moment(item.x)
			// 				return Math.abs(itemMoment.diff(xValMoment, 'seconds')) <= 1
			// 			}
			// 		})

			// 		// console.log('mapDataToXAxis:', {
			// 		// 	timestamp: xVal,
			// 		// 	dataType: isIncident ? 'incident' : 'metric',
			// 		// 	found: !!foundItem,
			// 		// 	value: foundItem?.y
			// 		// })

			// 		console.log('datasetFirst:', datasetFirst)
			// 		console.log('datasetSecond:', datasetSecond)
			// 		console.log('datasetThird:', datasetThird)

			// 		if (!foundItem) return null

			// 		if (isIncident) {
			// 			return {
			// 				value: foundItem.y,
			// 				incident_id: foundItem.incident_id,
			// 				triggers: foundItem.triggers,
			// 				time: moment(foundItem.x, 'MM/DD/YYYY HH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss')
			// 			}
			// 		}
			// 		return foundItem.y
			// 	})
			// }

			const mapDataToXAxis = (dataset, xAxisData, isIncident = false) => {
				console.log('Mapping data:', { datasetLength: dataset?.length });
				
					// Flatten the array if it's chunked
					const flattenedData = Array.isArray(dataset[0]) ? dataset.flat() : dataset;
					
					return flattenedData.map(item => {
							if (isIncident) {
									return {
											value: item.y,
											time: moment(item.x, 'MM/DD/YYYY HH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss'),
											incident_id: item.incident_id,
											triggers: item.triggers
									};
							}
							
							// For regular metrics (like mbps_in)
							return [
									moment(item.x, 'MM/DD/YYYY HH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss'),
									item.y
							];
					});
			};

			const seriesData = [
				{
					name: this.eChartData.series[0].name,
					type: 'line',
					// data: mapDataToXAxis(incidents, xAxisData, true),
					data: mapDataToXAxis(incidents, xAxisData, true).map(item => ({
						value: [item.time, item.value], // Format as [x, y] coordinates
						incident_id: item.incident_id,
						triggers: item.triggers
					})),
					symbol: 'circle',
					symbolSize: 8,
					itemStyle: { color: 'red' },
					lineStyle: { color: 'red' },
					showSymbol: true,
					tooltip: {
						valueFormatter: function (value) {
							if (value && typeof value === 'object') {
								return `Incident ID: ${value.incident_id}\nTriggers: ${value.triggers}`
							}
							return 'No Data'
						},
					}
				},
				{
					name: this.eChartData.series[1].name,
					type: 'line',
					data: mapDataToXAxis(datasetFirst, xAxisData, false),
					symbol: 'none',
					symbolSize: 8,
					itemStyle: { color: 'rgb(255, 184, 0)' },
					lineStyle: { color: 'rgb(255, 184, 0)' },
					tooltip: {
						valueFormatter: function (value) {
							return value !== undefined && value !== null
								? `${value} ${this.data.section.includes('mbps') ? 'mb/s' : 'ms'}`
								: 'No Data'
						}.bind(this)
					}
				},
				{
					name: this.eChartData.series[2].name,
					type: 'line',
					data: mapDataToXAxis(datasetSecond, xAxisData, false),
					symbol: 'none',
					symbolSize: 8,
					itemStyle: { color: 'rgb(173, 216, 230)' },
					lineStyle: { color: 'rgb(173, 216, 230)' },
					tooltip: {
						valueFormatter: function (value) {
							return value !== undefined && value !== null
								? `${value} ${this.data.section.includes('mbps') ? 'mb/s' : 'ms'}`
								: 'No Data'
						}.bind(this)
					}
				},
				{
					name: this.eChartData.series[3].name,
					type: 'line',
					data: mapDataToXAxis(datasetThird, xAxisData, false),
					symbol: 'none',
					symbolSize: 8,
					itemStyle: { color: 'rgb(144, 238, 144)' },
					lineStyle: { color: 'rgb(144, 238, 144)' },
					tooltip: {
						valueFormatter: function (value) {
							return value !== undefined && value !== null
								? `${value} ${this.data.section.includes('mbps') ? 'mb/s' : 'ms'}`
								: 'No Data'
						}.bind(this)
					}
				}
			]

			// Update chart data
			this.eChartData.xAxis.data = xAxisData.map(x => moment(x, 'MM/DD/YYYY HH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss'))
			this.eChartData.series = seriesData

			console.log('this.eChartData.xAxis.data', this.eChartData.xAxis.data)
			console.log('this.eChartData.series', this.eChartData.series)

			console.log('Final chart configuration:', {
				xAxisPoints: this.eChartData.xAxis.data.length,
				seriesPoints: this.eChartData.series.map(s => ({
					name: s.name,
					dataPoints: s.data.filter(d => d !== null).length
				}))
			})

			this.loadEChart()
		})
	}


	// generateData(minutes: number) {
	// 	const now = new Date()
	// 	return Array.from({ length: minutes }, (_, i) => {
	// 		const time = new Date(now.getTime() - i * 60 * 1000)
	// 		return [time.toISOString(), Math.floor(Math.random() * 100)]
	// 	}).reverse()
	// }

	generateData(count: number): number[] {
		return Array.from({ length: count }, () => Math.floor(Math.random() * 100))
	}

	generateTimestamps(count: number): string[] {
		const now = moment()
		return Array.from({ length: count }, (_, i) =>
			now
				.clone()
				.subtract(count - i, 'minutes')
				.format('HH:mm')
		)
	}

	loadChartData() {
		const timestamps = this.generateTimestamps(60) // 60 timestamps for 60 minutes (example)

		const option = {
			tooltip: {
				trigger: 'axis',
			},
			legend: {
				data: ['Incident', 'Mbps (mb/s)', 'Jitter (msec)', 'Latency (msec)'],
			},
			xAxis: {
				type: 'category',
				data: timestamps,
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					name: 'Incident',
					type: 'scatter',
					data: this.generateData(60),
					color: 'rgb(184, 58, 36)',
				},
				{
					name: 'Mbps (mb/s)',
					type: 'scatter',
					data: this.generateData(60),
					color: 'rgb(255, 184, 0)',
				},
				{
					name: 'Jitter (msec)',
					type: 'scatter',
					data: this.generateData(60),
					color: 'rgb(173, 216, 230)',
				},
				{
					name: 'Latency (msec)',
					type: 'scatter',
					data: this.generateData(60),
					color: 'rgb(144, 238, 144)',
				},
			],
		}

		this.chart.setOption(option)
	}

	// loadRealData(apiData: any) {
	// 	const timestamps = apiData.map((item: any) => item.timestamp)
	// 	const incidentData = apiData.map((item: any) => item.incidentCount)
	// 	const mbpsData = apiData.map((item: any) => item.mbps)
	// 	const jitterData = apiData.map((item: any) => item.jitter)
	// 	const latencyData = apiData.map((item: any) => item.latency)

	// 	const option = {
	// 		xAxis: { type: 'category', data: timestamps },
	// 		series: [
	// 			{ name: 'Incident', type: 'line', data: incidentData, color: 'rgb(184, 58, 36)' },
	// 			{ name: 'Mbps (mb/s)', type: 'line', data: mbpsData, color: 'rgb(255, 184, 0)' },
	// 			{ name: 'Jitter (msec)', type: 'line', data: jitterData, color: 'rgb(173, 216, 230)' },
	// 			{ name: 'Latency (msec)', type: 'line', data: latencyData, color: 'rgb(144, 238, 144)' },
	// 		],
	// 	}

	// 	this.chart.setOption(option)
	// }

	// fetchEChartData(d) {
	// 	const token = localStorage.getItem('token')
	// 	const helper = new JwtHelperService()
	// 	const decodedToken = helper.decodeToken(token)

	// 	let deviceDetails = {
	// 		company_id: decodedToken.company_id,
	// 		device_id: this.data.deviceId,
	// 		duration: d,
	// 	}

	// 	// this.chartApiData = [];
	// 	this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
	// 		this.chartApiData = data['response']

	// 		// this.chartApiData = [];
	// 		this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
	// 			console.log('EChart API call:', data['response'])
	// 			this.graphData = data.response[0]
	// 			let datasetFirst
	// 			let datasetSecond
	// 			let datasetThird
	// 			if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
	// 				datasetFirst = data['response'][0].mbps_total
	// 				datasetSecond = data['response'][0].mbps_in
	// 				datasetThird = data['response'][0].mbps_out
	// 			} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
	// 				datasetFirst = data['response'][0].total_jitter
	// 				datasetSecond = data['response'][0].jitter_in
	// 				datasetThird = data['response'][0].jitter_out
	// 			} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
	// 				datasetFirst = data['response'][0].total_latency
	// 				datasetSecond = data['response'][0].latency_in
	// 				datasetThird = data['response'][0].latency_out
	// 			}
	// 			datasetFirst.sort(this.custom_sort)
	// 			datasetSecond.sort(this.custom_sort)
	// 			datasetThird.sort(this.custom_sort)
	// 			let latency = data['response'][0].total_latency
	// 			latency.sort(this.custom_sort)
	// 			let jitter = data['response'][0].total_jitter
	// 			jitter.sort(this.custom_sort)
	// 			let mbps = data['response'][0].mbps_total
	// 			mbps.sort(this.custom_sort)
	// 			let packetloss = data['response'][0].total_packetloss
	// 			packetloss.sort(this.custom_sort)
	// 			let incidents = data['response'][0].incidents
	// 			// incidents.sort(this.custom_sort);

	// 			for (let x = 0; x < datasetFirst.length; x++) {
	// 				if (datasetFirst[x].y === 0) {
	// 					datasetFirst[x].y = null
	// 				}
	// 			}
	// 			for (let x = 0; x < datasetSecond.length; x++) {
	// 				if (datasetSecond[x].y === 0) {
	// 					datasetSecond[x].y = null
	// 				}
	// 			}
	// 			for (let x = 0; x < datasetThird.length; x++) {
	// 				if (datasetThird[x].y === 0) {
	// 					datasetThird[x].y = null
	// 				}
	// 			}
	// 			// this.chartData.datasets[0].data = data['response'][0].incident;
	// 			this.chartData.datasets[0].data = incidents
	// 			this.chartData.datasets[1].data = datasetFirst
	// 			this.chartData.datasets[2].data = datasetSecond
	// 			this.chartData.datasets[3].data = datasetThird
	// 			this.datasetFirstAllFilter = datasetFirst
	// 			this.datasetSecondAllFilter = datasetSecond
	// 			this.datasetThirdAllFilter = datasetThird
	// 			// OLD IMPLEMENTATION START
	// 			// this.chartData.datasets[1].data = mbps;
	// 			// this.chartData.datasets[2].data = jitter;
	// 			// this.chartData.datasets[3].data = latency;
	// 			// this.chartData.datasets[4].data = packetloss;
	// 			// OLD IMPLEMENTATION END
	// 			if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
	// 				this.chartData.datasets[1].hidden = !('mbps' == this.data.section)
	// 				this.chartData.datasets[2].hidden = !('mbps_in' == this.data.section)
	// 				this.chartData.datasets[3].hidden = !('mbps_out' == this.data.section)
	// 			} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
	// 				this.chartData.datasets[1].hidden = !('jitter' == this.data.section)
	// 				this.chartData.datasets[2].hidden = !('jitter_in' == this.data.section)
	// 				this.chartData.datasets[3].hidden = !('jitter_out' == this.data.section)
	// 			} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
	// 				this.chartData.datasets[1].hidden = !('packetloss' == this.data.section)
	// 				this.chartData.datasets[2].hidden = !('packetloss_in' == this.data.section)
	// 				this.chartData.datasets[3].hidden = !('packetloss_out' == this.data.section)
	// 			} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
	// 				this.chartData.datasets[1].hidden = !('latency' == this.data.section)
	// 				this.chartData.datasets[2].hidden = !('latency_in' == this.data.section)
	// 				this.chartData.datasets[3].hidden = !('latency_out' == this.data.section)
	// 			}
	// 			// OLD IMPLEMENTATION START
	// 			// this.chartData.datasets[1].hidden = !('mbps' == this.data.section);
	// 			// this.chartData.datasets[2].hidden = !('jitter' == this.data.section);
	// 			// this.chartData.datasets[3].hidden = !('latency' == this.data.section);
	// 			// this.chartData.datasets[4].hidden = !('packetloss' == this.data.section);
	// 			// OLD IMPLEMENTATION END
	// 			this.loadChart()
	// 		})
	// 	})
	// 	// this.allInsListData = [];
	// 	// this.Auth.getAllInsChartListData(deviceDetails).subscribe((data) => {
	// 	//   let myResponse = data['response'];
	// 	//   let arr3 = [];
	// 	//   for (let k = 0; k < myResponse.length; k++) {
	// 	//     let sectionData = this.getSectionDataForTable(myResponse[k]);
	// 	//     arr3.push({
	// 	//       timestamp: new Date(myResponse[k].timestamp),
	// 	//       totalSectionData: sectionData,
	// 	//       totalRx: this.getRxForTable(myResponse[k]),
	// 	//       totalTx: this.getTxForTable(myResponse[k]),
	// 	//       videoTotal: this.getTypeContentTotal(myResponse[k], 'video_'),
	// 	//       videoRx: this.getTypeContent(myResponse[k], 'video_', 'rx'),
	// 	//       videoTx: this.getTypeContent(myResponse[k], 'video_', 'tx'),
	// 	//       audioTotal: this.getTypeContentTotal(myResponse[k], 'audio_'),
	// 	//       audioRx: this.getTypeContent(myResponse[k], 'audio_', 'rx'),
	// 	//       audioTx: this.getTypeContent(myResponse[k], 'audio_', 'tx'),
	// 	//       contentTotal: this.getTypeContentTotal(myResponse[k], 'content_'),
	// 	//       contentRx: this.getTypeContent(myResponse[k], 'content_', 'rx'),
	// 	//       contentTx: this.getTypeContent(myResponse[k], 'content_', 'tx'),
	// 	//       incident_id: myResponse[k].video_incident_id
	// 	//     });
	// 	//   }
	// 	//   //Loads the API json data into the company list data source
	// 	//   this.printTableData = arr3;
	// 	// });
	// }

	formatTimestamp(isoString: string): string {
		const date = new Date(isoString)
		return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}` // Example: 12:05
	}

	// loadChartDataFromApi(apiData: any) {
	// 	const timestamps = apiData.incidents.map((point: any) => this.formatTimestamp(point.x))

	// 	const option = {
	// 		tooltip: {
	// 			trigger: 'axis',
	// 		},
	// 		legend: {
	// 			data: ['Incident', 'Mbps (mb/s)', 'Jitter (msec)', 'Latency (msec)'],
	// 		},
	// 		xAxis: {
	// 			type: 'category',
	// 			data: timestamps, // Use timestamps directly from the API data
	// 		},
	// 		yAxis: {
	// 			type: 'value',
	// 		},
	// 		series: [
	// 			{
	// 				name: 'Incident',
	// 				type: 'scatter',
	// 				data: apiData.incidents.map((p: any) => p.y),
	// 				color: 'rgb(184, 58, 36)',
	// 			},
	// 			{
	// 				name: 'Mbps (mb/s)',
	// 				type: 'scatter',
	// 				data: apiData.mbps_total.map((p: any) => p.y),
	// 				color: 'rgb(255, 184, 0)',
	// 			},
	// 			{
	// 				name: 'Jitter (msec)',
	// 				type: 'scatter',
	// 				data: apiData.jitter_total.map((p: any) => p.y),
	// 				color: 'rgb(173, 216, 230)',
	// 			},
	// 			{
	// 				name: 'Latency (msec)',
	// 				type: 'scatter',
	// 				data: apiData.latency_total.map((p: any) => p.y),
	// 				color: 'rgb(144, 238, 144)',
	// 			},
	// 		],
	// 	}

	// 	this.chart.setOption(option)
	// }

	// -- EChart Device Filters -- //
	filteredData = []
	applyAllFilter() {
		this.filteredData = [...this.fullData]
	}

	applyDeviceSpecificFilter(filterType: string, dataSection: string) {
		this.filteredData = this.fullData
			.map(item => {
				if (item.label.toLowerCase().includes(filterType.toLowerCase())) {
					return this.processDeviceData(item, dataSection)
				}
				return null // Remove devices that don't match
			})
			.filter(Boolean) // Remove nulls
	}

	processDeviceData(item, dataSection) {
		const processedData = item.data.map(e => {
			let total = 0,
				rx = 0,
				tx = 0

			if (e.rx && e.tx) {
				if (dataSection === 'mbps') {
					total = (e.rx[dataSection] + e.tx[dataSection]) / 1048576
					rx = e.rx[dataSection] / 1048576
					tx = e.tx[dataSection] / 1048576
				} else {
					total = e.rx[dataSection] + e.tx[dataSection]
					rx = e.rx[dataSection]
					tx = e.tx[dataSection]
				}
			}

			return { x: e.x, total, rx, tx }
		})

		return { ...item, data: processedData }
	}

	getDataSection(section: string) {
		if (section.includes('mbps')) return 'mbps'
		if (section.includes('jitter')) return 'jitter'
		if (section.includes('packetloss')) return 'packetsloss'
		if (section.includes('latency')) return 'latency'
		return ''
	}

	loadChartWithFilteredData() {
		const times = this.filteredData[0]?.data.map(d => d.x) || []

		const series = []
		this.filteredData.forEach(device => {
			series.push(
				{ name: `${device.label} Total`, type: 'line', data: device.data.map(d => d.total) },
				{ name: `${device.label} Rx`, type: 'line', data: device.data.map(d => d.rx) },
				{ name: `${device.label} Tx`, type: 'line', data: device.data.map(d => d.tx) }
			)
		})

		const updatedChartOption = {
			xAxis: { data: times },
			series: series,
		}

		this.chart.setOption(updatedChartOption)
	}

	updateChartDataForFilter() {
		const dataSection = this.getDataSection(this.data.section)

		if (this.filterType === 'all') {
			this.applyAllFilter()
		} else {
			this.applyDeviceSpecificFilter(this.filterType, dataSection)
		}

		this.loadChartWithFilteredData() // Finally, update the chart
	}

	setNewFilterEChart(filterType: string) {
		this.filterType = filterType // Store the selected filter type
		this.updateChartDataForFilter() // Prepare the filtered data
		this.loadChartWithFilteredData() // Update the chart

		if (filterType === 'all') {
			// If the filter is 'all', initialize chart and then call the API after a delay
			this.initChart()
			this.setTimeRange(this.timeMode as '60m' | '24h' | '7d') // Dynamically checking time mode using explicit type casting.
		}
	}

	setNewFilter_EChart(filterType: string) {
		// Initialize the EChart
		// this.initializeEChart()

		this.filterType = filterType
		if (filterType === 'all')
			this.includedProperties_EChart = ['x', this.data.section, 'incident', 'video', 'audio', 'content']
		else if (filterType === 'video') this.includedProperties_EChart = ['x', this.data.section, 'video', 'incident']
		else if (filterType === 'audio') this.includedProperties_EChart = ['x', this.data.section, 'audio', 'incident']
		else if (filterType === 'content') this.includedProperties_EChart = ['x', this.data.section, 'content', 'incident']

		let dataSection = ''
		if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
			dataSection = 'mbps'
		} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
			dataSection = 'jitter'
		} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
			dataSection = 'packetsloss'
		} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
			dataSection = 'latency'
		}

		if (filterType !== 'all') {
			const filteredData = {
				timestamps: [],
				total: [],
				rx: [],
				tx: []
			}

			this.graphData[filterType].forEach(e => {
				let total = 0
				let rx = 0
				let tx = 0

				if (e[filterType + 'Rx'] !== null && e[filterType + 'Tx'] !== null) {
					if (dataSection === 'mbps') {
						total = (e[filterType + 'Rx'][dataSection] + e[filterType + 'Tx'][dataSection]) / 1048576
						rx = e[filterType + 'Rx'][dataSection] / 1048576
						tx = e[filterType + 'Tx'][dataSection] / 1048576
						total = parseFloat(total.toFixed(2))
						rx = parseFloat(rx.toFixed(2))
						tx = parseFloat(tx.toFixed(2))
					} else {
						total = e[filterType + 'Rx'][dataSection] + e[filterType + 'Tx'][dataSection]
						rx = e[filterType + 'Rx'][dataSection]
						tx = e[filterType + 'Tx'][dataSection]
					}

					filteredData.timestamps.push(e.x)
					filteredData.total.push(total !== 0 ? total : null)
					filteredData.rx.push(rx !== 0 ? rx : null)
					filteredData.tx.push(tx !== 0 ? tx : null)
				} else {
					filteredData.timestamps.push(e.x)
					filteredData.total.push(null)
					filteredData.rx.push(null)
					filteredData.tx.push(null)
				}
			})

			// Update series data for EChart
			this.eChartData.xAxis.data = filteredData.timestamps
			this.eChartData.series[1].data = filteredData.total
			this.eChartData.series[2].data = filteredData.rx
			this.eChartData.series[3].data = filteredData.tx

			// Update series names based on filter and section
			this.eChartData.series[0].name = 'Incident'
			if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
				this.eChartData.series[1].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Mbps Total`
				this.eChartData.series[2].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Mbps RX`
				this.eChartData.series[3].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Mbps TX`
			} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
				this.eChartData.series[1].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Jitter Total`
				this.eChartData.series[2].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Jitter RX`
				this.eChartData.series[3].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Jitter TX`
			} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
				this.eChartData.series[1].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Packet Loss Total`
				this.eChartData.series[2].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Packet Loss RX`
				this.eChartData.series[3].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Packet Loss TX`
			} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
				this.eChartData.series[1].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Latency Total`
				this.eChartData.series[2].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Latency RX`
				this.eChartData.series[3].name = `${filterType.charAt(0).toUpperCase() + filterType.slice(1)} Latency TX`
			}

			// Update the chart with new data
			// if (this.chart) {
			// 	this.chart.setOption(this.eChartData, {
			// 		notMerge: true,
			// 		lazyUpdate: true
			// 	})
			// }
			this.loadEChart()
		} else {
			// Reset to original data
			this.fetch_echart_API_data(
				this.currentChart === '60m' ? 60 : this.currentChart === '24h' ? 24 : 7,
				this.currentChart === '60m' ? 'minutes' : this.currentChart === '24h' ? 'hours' : 'days'
			)
			// this.loadEChart()
		}
	}

	// ----------------------------------------------------------------//
	ngOnInit(): void {
		// this.data = this.dummyData
		// this.initChart() // -> Current
		// this.generatePlaceholderChart()
		// this.renderEchart()
		// this.fetch_echart_API_data(7, 'days') // -> Current
		// this.loadChartData() // For testing with dummy data.
		// this.fetchEChartData(1)
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.popupState) {
			console.log('changes.popupState:', changes.popupState)
			this.tempPopupState = changes
			console.log('this.tempPopupState:', this.tempPopupState)
		} else {
			changes = this.tempPopupState
			console.log('changes:', changes)
			// this.myChartSite.destroy()
			this.chart.dispose()
			this.chart = null
		}
		if (changes.popupState.currentValue == 'open') {
			console.log('changes.popupState.currentValue == open - EchartVideoDnaComponent')
			// this.canvas = document.getElementById('myChartSite')
			// this.ctx = this.canvas.getContext('2d')
			// this.myChartSite = new Chart(this.ctx, {
			// 	type: 'line',
			// 	// data: this.data24h,
			// 	options: {
			// 		responsive: false,
			// 		display: true,
			// 		fontSize: '6px',
			// 		padding: '0px',
			// 		maintainAspectRatio: false,
			// 		elements: {
			// 			line: {
			// 				tension: 0, // disables bezier curves
			// 			},
			// 		},
			// 	},
			// })

			// this.currentChart = '60m'
			// this.activeBtn = 0
			// this.toggleValue = 0
			// this.toggleBtn(1)
			// this.includedProperties = ['x', this.data.section, 'incident', 'video', 'audio', , 'content']
			// if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
			// 	this.chartData.datasets[1].label = 'mbps Total'
			// 	this.chartData.datasets[2].label = 'mbps RX'
			// 	this.chartData.datasets[3].label = 'mbps TX'
			// } else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
			// 	this.chartData.datasets[1].label = 'Jitter (msec) Total'
			// 	this.chartData.datasets[2].label = 'Jitter (msec) RX'
			// 	this.chartData.datasets[3].label = 'Jitter (msec) TX'
			// } else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
			// 	this.chartData.datasets[1].label = 'Packet Loss(%) Total'
			// 	this.chartData.datasets[2].label = 'Packet Loss(%) RX'
			// 	this.chartData.datasets[3].label = 'Packet Loss(%) TX'
			// } else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
			// 	this.chartData.datasets[1].label = 'Latency Total'
			// 	this.chartData.datasets[2].label = 'Latency RX'
			// 	this.chartData.datasets[3].label = 'Latency TX'
			// }
			// this.fetchChartData(1)

			// --- EChart Configuration --- //
			// First, dispose of any existing chart
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }

        // Initialize new chart with your existing structure
			// this.initializeEChart();
			this.currentEChart = 'days'
			console.log('this.data.section:', this.data.section)
			console.log('this.data:', this.data)
			this.includedProperties_EChart = ['x', this.data.section, 'incident', 'video', 'audio', , 'content']
			if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
				// this.initializeEChart();
				this.eChartData.series[1].name = 'Mbps Total'
				this.eChartData.series[2].name = 'Mbps RX'
				this.eChartData.series[3].name = 'Mbps TX'
				this.eChartData.legend.data = ['Incident', 'Mbps Total', 'Mbps RX', 'Mbps TX']
				// Update tooltips
				this.eChartData.series[1].tooltip.valueFormatter = (value) => `${value} mb/s`
				this.eChartData.series[2].tooltip.valueFormatter = (value) => `${value} mb/s`
				this.eChartData.series[3].tooltip.valueFormatter = (value) => `${value} mb/s`
				this.loadEChart()
			} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
				// this.initializeEChart();
				this.eChartData.series[1].name = 'Jitter (msec) Total'
				this.eChartData.series[2].name = 'Jitter (msec) RX'
				this.eChartData.series[3].name = 'Jitter (msec) TX'
				this.eChartData.legend.data = ['Incident', 'Jitter (msec) Total', 'Jitter (msec) RX', 'Jitter (msec) TX']
				// Update tooltips
				this.eChartData.series[1].tooltip.valueFormatter = (value) => `${value} ms`
				this.eChartData.series[2].tooltip.valueFormatter = (value) => `${value} ms`
				this.eChartData.series[3].tooltip.valueFormatter = (value) => `${value} ms`
				this.loadEChart()
			} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
				this.eChartData.series[1].name = 'Packet Loss(%) Total'
				this.eChartData.series[2].name = 'Packet Loss(%) RX'
				this.eChartData.series[3].name = 'Packet Loss(%) TX'
				this.eChartData.legend.data = ['Incident', 'Packet Loss(%) Total', 'Packet Loss(%) RX', 'Packet Loss(%) TX']
				// Update tooltips
				this.eChartData.series[1].tooltip.valueFormatter = (value) => `${value}%`
				this.eChartData.series[2].tooltip.valueFormatter = (value) => `${value}%`
				this.eChartData.series[3].tooltip.valueFormatter = (value) => `${value}%`
				this.loadEChart()
			} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
				// this.initializeEChart();
				this.eChartData.series[1].name = 'Latency Total'
				this.eChartData.series[2].name = 'Latency RX'
				this.eChartData.series[3].name = 'Latency TX'
				this.eChartData.legend.data = ['Incident', 'Latency Total', 'Latency RX', 'Latency TX']
				// Update tooltips
				this.eChartData.series[1].tooltip.valueFormatter = (value) => `${value} ms`
				this.eChartData.series[2].tooltip.valueFormatter = (value) => `${value} ms`
				this.eChartData.series[3].tooltip.valueFormatter = (value) => `${value} ms`
				this.loadEChart()
			}

			// Keep incident tooltip formatter consistent
			this.eChartData.series[0].tooltip.valueFormatter = (value) => `${value} incidents`

			// this.fetch_echart_API_data(60, "minutes")
			this.resetEChart();
			// -- //
		} else {
			// this.resetEChart();
			// setTimeout(() => {
			// 	this.myChartSite.destroy()
			// }, 500)
			// setTimeout(() => {
			// 	if (this.chart) {
			// 		console.log('this.chart has been disposed - this.chart.dispose()')
			// 		this.chart.dispose()
			// 	}
			// })
		}
	}
	setNewFilter(filterType: string) {
		this.filterType = filterType
		if (filterType === 'all')
			this.includedProperties = ['x', this.data.section, 'incident', 'video', 'audio', 'content']
		else if (filterType === 'video') this.includedProperties = ['x', this.data.section, 'video', 'incident']
		else if (filterType === 'audio') this.includedProperties = ['x', this.data.section, , 'audio', 'incident']
		else if (filterType === 'content') this.includedProperties = ['x', this.data.section, , 'content', 'incident']

		let dataSection = ''
		if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
			dataSection = 'mbps'
		} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
			dataSection = 'jitter'
		} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
			dataSection = 'packetsloss'
		} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
			dataSection = 'latency'
		}
		if (filterType !== 'all') {
			let datasetFirstFiltered: any[] = []
			let datasetSecondFiltered: any[] = []
			let datasetThirdFiltered: any[] = []
			this.graphData[filterType].forEach(e => {
				let total = 0
				let rx = 0
				let tx = 0
				if (e[filterType + 'Rx'] !== null && e[filterType + 'Tx'] !== null) {
					if (dataSection === 'mbps') {
						total = (e[filterType + 'Rx'][dataSection] + e[filterType + 'Tx'][dataSection]) / 1048576
						rx = e[filterType + 'Rx'][dataSection] / 1048576
						tx = e[filterType + 'Tx'][dataSection] / 1048576
						total = parseFloat(total.toFixed(2))
						rx = parseFloat(rx.toFixed(2))
						tx = parseFloat(tx.toFixed(2))
					} else {
						total = e[filterType + 'Rx'][dataSection] + e[filterType + 'Tx'][dataSection]
						rx = e[filterType + 'Rx'][dataSection]
						tx = e[filterType + 'Tx'][dataSection]
					}
					datasetFirstFiltered.push({
						x: e.x,
						y: total !== 0 ? total : null,
					})
					datasetSecondFiltered.push({
						x: e.x,
						y: rx !== 0 ? rx : null,
					})
					datasetThirdFiltered.push({
						x: e.x,
						y: tx !== 0 ? tx : null,
					})
				} else {
					datasetFirstFiltered.push({
						x: e.x,
						y: null,
					})
					datasetSecondFiltered.push({
						x: e.x,
						y: null,
					})
					datasetThirdFiltered.push({
						x: e.x,
						y: null,
					})
				}
			})

			this.chartData.datasets[1].data = datasetFirstFiltered
			this.chartData.datasets[2].data = datasetSecondFiltered
			this.chartData.datasets[3].data = datasetThirdFiltered
			this.loadChart()

			// });
		} else {
			this.chartData.datasets[1].data = this.datasetFirstAllFilter
			this.chartData.datasets[2].data = this.datasetSecondAllFilter
			this.chartData.datasets[3].data = this.datasetThirdAllFilter
			this.loadChart()
		}
	}
	toggleView(n) {
		if (this.toggleValue == n) {
			this.toggleValue = 0
		} else {
			this.toggleValue = n
		}
	}
	toggleBtn(n) {
		if (this.activeBtn == n) {
			this.activeBtn = 0
		} else {
			this.activeBtn = n
		}
	}

	toggleNumberView() {
		this.areNumbersRounded = !this.areNumbersRounded
		this._videoService.showFullNumbers.next(!this.areNumbersRounded)
	}
	getPrintData(data) {
		this.printTableData = data
	}
	fetchChartData(d) {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)

		let deviceDetails = {
			company_id: decodedToken.company_id,
			device_id: this.data.deviceId,
			duration: d,
		}

		// this.chartApiData = [];
		this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
			this.chartApiData = data['response']

			// this.chartApiData = [];
			this.Auth.getAllChartDataNoRound(deviceDetails).subscribe(data => {
				this.graphData = data.response[0]
				let datasetFirst
				let datasetSecond
				let datasetThird
				if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
					datasetFirst = data['response'][0].mbps_total
					datasetSecond = data['response'][0].mbps_in
					datasetThird = data['response'][0].mbps_out
				} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
					datasetFirst = data['response'][0].total_jitter
					datasetSecond = data['response'][0].jitter_in
					datasetThird = data['response'][0].jitter_out
				} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
					datasetFirst = data['response'][0].total_packetloss
					datasetSecond = data['response'][0].packetloss_in
					datasetThird = data['response'][0].packetloss_out
				} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
					datasetFirst = data['response'][0].total_latency
					datasetSecond = data['response'][0].latency_in
					datasetThird = data['response'][0].latency_out
				}
				datasetFirst.sort(this.custom_sort)
				datasetSecond.sort(this.custom_sort)
				datasetThird.sort(this.custom_sort)
				let latency = data['response'][0].total_latency
				latency.sort(this.custom_sort)
				let jitter = data['response'][0].total_jitter
				jitter.sort(this.custom_sort)
				let mbps = data['response'][0].mbps_total
				mbps.sort(this.custom_sort)
				let packetloss = data['response'][0].total_packetloss
				packetloss.sort(this.custom_sort)
				let incidents = data['response'][0].incidents
				// incidents.sort(this.custom_sort);

				for (let x = 0; x < datasetFirst.length; x++) {
					if (datasetFirst[x].y === 0) {
						datasetFirst[x].y = null
					}
				}
				for (let x = 0; x < datasetSecond.length; x++) {
					if (datasetSecond[x].y === 0) {
						datasetSecond[x].y = null
					}
				}
				for (let x = 0; x < datasetThird.length; x++) {
					if (datasetThird[x].y === 0) {
						datasetThird[x].y = null
					}
				}
				// this.chartData.datasets[0].data = data['response'][0].incident;
				this.chartData.datasets[0].data = incidents
				this.chartData.datasets[1].data = datasetFirst
				this.chartData.datasets[2].data = datasetSecond
				this.chartData.datasets[3].data = datasetThird
				this.datasetFirstAllFilter = datasetFirst
				this.datasetSecondAllFilter = datasetSecond
				this.datasetThirdAllFilter = datasetThird
				// OLD IMPLEMENTATION START
				// this.chartData.datasets[1].data = mbps;
				// this.chartData.datasets[2].data = jitter;
				// this.chartData.datasets[3].data = latency;
				// this.chartData.datasets[4].data = packetloss;
				// OLD IMPLEMENTATION END
				if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
					this.chartData.datasets[1].hidden = !('mbps' == this.data.section)
					this.chartData.datasets[2].hidden = !('mbps_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('mbps_out' == this.data.section)
				} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
					this.chartData.datasets[1].hidden = !('jitter' == this.data.section)
					this.chartData.datasets[2].hidden = !('jitter_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('jitter_out' == this.data.section)
				} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
					this.chartData.datasets[1].hidden = !('packetloss' == this.data.section)
					this.chartData.datasets[2].hidden = !('packetloss_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('packetloss_out' == this.data.section)
				} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
					this.chartData.datasets[1].hidden = !('latency' == this.data.section)
					this.chartData.datasets[2].hidden = !('latency_in' == this.data.section)
					this.chartData.datasets[3].hidden = !('latency_out' == this.data.section)
				}
				// OLD IMPLEMENTATION START
				// this.chartData.datasets[1].hidden = !('mbps' == this.data.section);
				// this.chartData.datasets[2].hidden = !('jitter' == this.data.section);
				// this.chartData.datasets[3].hidden = !('latency' == this.data.section);
				// this.chartData.datasets[4].hidden = !('packetloss' == this.data.section);
				// OLD IMPLEMENTATION END
				this.loadChart()
			})
		})
		// this.allInsListData = [];
		// this.Auth.getAllInsChartListData(deviceDetails).subscribe((data) => {
		//   let myResponse = data['response'];
		//   let arr3 = [];
		//   for (let k = 0; k < myResponse.length; k++) {
		//     let sectionData = this.getSectionDataForTable(myResponse[k]);
		//     arr3.push({
		//       timestamp: new Date(myResponse[k].timestamp),
		//       totalSectionData: sectionData,
		//       totalRx: this.getRxForTable(myResponse[k]),
		//       totalTx: this.getTxForTable(myResponse[k]),
		//       videoTotal: this.getTypeContentTotal(myResponse[k], 'video_'),
		//       videoRx: this.getTypeContent(myResponse[k], 'video_', 'rx'),
		//       videoTx: this.getTypeContent(myResponse[k], 'video_', 'tx'),
		//       audioTotal: this.getTypeContentTotal(myResponse[k], 'audio_'),
		//       audioRx: this.getTypeContent(myResponse[k], 'audio_', 'rx'),
		//       audioTx: this.getTypeContent(myResponse[k], 'audio_', 'tx'),
		//       contentTotal: this.getTypeContentTotal(myResponse[k], 'content_'),
		//       contentRx: this.getTypeContent(myResponse[k], 'content_', 'rx'),
		//       contentTx: this.getTypeContent(myResponse[k], 'content_', 'tx'),
		//       incident_id: myResponse[k].video_incident_id
		//     });
		//   }
		//   //Loads the API json data into the company list data source
		//   this.printTableData = arr3;
		// });
	}
	loadChart() {
		// this.myChartSite.reset();
		this.myChartSite.options.scales = {
			xAxes: [
				{
					type: 'time',
					display: true,
					scaleLabel: {
						display: true,
						labelString: this.currentChart == '7d' ? 'Date' : 'Time',
					},
					time: {
						unit: this.currentChart == '7d' ? 'day' : this.currentChart == '24h' ? 'hour' : 'minute',
					},
				},
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		}

		this.myChartSite.options.tooltips = {
			callbacks: {
				label: function (tooltipItem, chart) {
					if (tooltipItem.datasetIndex == 0) {
						return 'ID: ' + chart.datasets[0].data[tooltipItem.index].incident_id
					} else {
						return tooltipItem.yLabel
					}
				},
				beforeLabel: function (tooltipItem, chart) {
					switch (tooltipItem.datasetIndex) {
						case 0:
							return 'Incidents'
						case 1:
							return chart.datasets[1].label
						// return "mbps";
						case 2:
							return chart.datasets[2].label
						// return "Jitter";
						case 3:
							return chart.datasets[3].label
						// return "Latency";
						case 3:
							return 'Packetloss'
					}
				},
				afterLabel: function (tooltipItem, chart) {
					if (tooltipItem.datasetIndex == 0) {
						let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(' - ')
						triggers.splice(0, 1)
						return triggers
					} else {
						return ''
					}
				},
				title: function (tooltipItem, chart) {
					return moment(tooltipItem[0].xLabel)
						.utc()
						.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
						.format('MM-DD-YYYY hh:mma')
				},
			},
		}
		this.myChartSite.data.datasets = this.chartData.datasets
		this.myChartSite.update({ duration: 500 })
	}
	custom_sort(a, b) {
		return new Date(a.x).getTime() - new Date(b.x).getTime()
	}
	getMax(arr, prop) {
		var max
		for (var i = 0; i < arr.length; i++) {
			if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop])) max = arr[i]
		}
		return max
	}
	public onSeriesAdded(e: any) {
		if (e.args.series) {
			e.args.series.tooltipTemplate = this.valueTooltip
		}
	}
	getDateObject(object: string, chartData: string) {
		let date = new Date(object)
		if (chartData === '7d') return this.datePipe.transform(date, 'MMM d')
		else if (chartData === '24h') return this.datePipe.transform(date, 'hh:mm a')
		else return this.datePipe.transform(date, 'hh:mm a')
	}
	set60m() {
		if (this.currentChart != '60m') {
			this.currentChart = '60m'
			this.toggleBtn(1)
			this.fetchChartData(1)
		}
	}

	// Add this helper method
	// private initializeEChart() { // -> E-Chart
	// 	if (!this.chart || !this.chart.getOption()) {
	// 		if (this.chartContainer) {
	// 			this.chart = echarts.init(this.chartContainer.nativeElement)
	// 		}
	// 	}
	// }

	// private initializeEChart() {
	// 	try {
	// 		// If chart exists, check if it's disposed
	// 		if (this.chart) {
	// 			try {
	// 				// A more reliable way to check if chart is disposed
	// 				if (!(this.chart as any).id) {
	// 					console.log('Chart was disposed, creating new instance');
	// 					this.chart = echarts.init(this.chartContainer.nativeElement);
	// 				} else {
	// 					console.log('Chart instance exists and is valid');
	// 				}
	// 			} catch (e) {
	// 				console.log('Error checking chart, creating new instance');
	// 				this.chart = echarts.init(this.chartContainer.nativeElement);
	// 			}
	// 		} else if (this.chartContainer) {
	// 			// No chart instance exists, create new one
	// 			console.log('No chart instance exists, creating new one');
	// 			this.chart = echarts.init(this.chartContainer.nativeElement);
	// 		}
	// 	} catch (e) {
	// 		console.warn('Error initializing chart:', e);
	// 	}
	// }

	// private initializeEChart() {
	// 	try {
	// 		// Only initialize if chart doesn't exist or is disposed
	// 		if (!this.chart || !(this.chart as any).id) {
	// 			console.log('Chart needs initialization - creating new instance')
	// 			if (this.chartContainer) {
	// 				this.chart = echarts.init(this.chartContainer.nativeElement)
	// 			}
	// 		}
	// 	} catch (e) {
	// 		console.warn('Error initializing chart:', e)
	// 		// If any error occurs during check, try to reinitialize
	// 		if (this.chartContainer) {
	// 			this.chart = echarts.init(this.chartContainer.nativeElement)
	// 		}
	// 	}
	// }

	// initializeEChart() {
  //   const chartContainer = document.getElementById('echartContainer');
  //   if (!chartContainer) {
  //       console.error('Chart container not found');
  //       return;
  //   }
	// 	this.chart = echarts.init(chartContainer);
	// 	if (this.chart) {
	// 		this.fetch_echart_API_data(60, 'minutes');
	// 		this.toggleBtn(1);  // Set 60m button as active
	// 		this.currentEChart = 'minutes';
	// 		this.currentChart = '60m';
	// 	}
	// }

	// Remove this method
// toggleBtn(n) {
//     if (this.activeBtn == n) {
//         this.activeBtn = 0
//     } else {
//         this.activeBtn = n
//     }
// }

// In initializeEChart(), directly set activeBtn
initializeEChart() {
		const chartContainer = document.getElementById('echartContainer');
		console.log('Initializing EChart ... - initializeEChart()')
    if (!chartContainer) {
        console.error('Chart container not found');
        return;
		}
		console.log('Container found, initializing chart - initializeEChart()');
    this.chart = echarts.init(chartContainer);
		console.log('Chart initialized - initializeEChart()');
    if (this.chart) {
        switch(this.currentEChart) {
            case 'minutes':
                this.fetch_echart_API_data(60, 'minutes');
                this.activeBtn = 1;  // Directly set active button
                this.currentEChart = 'minutes';
								this.currentChart = '60m';
								console.log('fetching 60m data - initializeEChart()')
                break;
            case 'hours':
                this.fetch_echart_API_data(24, 'hours');
                this.activeBtn = 2;  // Directly set active button
                this.currentEChart = 'hours';
								this.currentChart = '24h';
								console.log('fetching 24h data - initializeEChart()')
                break;
            case 'days':
                this.fetch_echart_API_data(7, 'days');
                this.activeBtn = 3;  // Directly set active button
                this.currentEChart = 'days';
						this.currentChart = '7d';
								console.log('fetching 7d data - initializeEChart()')
                break;
            default:
                this.fetch_echart_API_data(60, 'minutes');
                this.activeBtn = 1;  // Directly set active button
                this.currentEChart = 'minutes';
								this.currentChart = '60m';
								console.log('fetching 60m data - initializeEChart()')
        }
    }
}

	resetEChart() {
    console.log('Resetting EChart to 60m view');
    // Dispose existing chart
    if (this.chart) {
        this.chart.dispose();
        this.chart = null;
    }

    this.currentEChart = 'minutes';
    this.currentChart = '60m';
		// this.toggleBtn(1)  // Reset to 60m button
		// this.activeBtn = 1
    this.initializeEChart();
    // this.fetch_echart_API_data(60, 'minutes');
}

	// set60m_EChart() { // -> E-Chart
	// 	console.log('set60m_EChart invoked')
	// 	if (this.currentEChart != 'minutes') {
	// 		this.currentEChart = 'minutes'
	// 		this.currentChart = '60m'
	// 		this.toggleBtn(1)
	// 		this.initializeEChart()
	// 		this.fetch_echart_API_data(60, 'minutes')
	// 	}
	// }

	set24h() {
		if (this.currentChart != '24h') {
			this.currentChart = '24h'
			this.toggleBtn(2)
			this.fetchChartData(24)
		}
	}

	// set24h_EChart() { // -> E-Chart
	// 	console.log('set24h_EChart invoked')
	// 	if (this.currentEChart != 'hours') {
	// 		this.currentEChart = 'hours'
	// 		this.currentChart = '24h'
	// 		this.toggleBtn(2)
	// 		this.initializeEChart()
	// 		this.fetch_echart_API_data(24, 'hours')
	// 	}
	// }

	set7d() {
		if (this.currentChart != '7d') {
			this.currentChart = '7d'
			this.toggleBtn(3)
			this.initializeEChart()
			this.fetchChartData(7)
		}
	}

	// set7d_EChart() { // -> E-Chart
	// 	console.log('set7h_EChart invoked')
	// 	if (this.currentEChart != 'days') {
	// 		this.currentEChart = 'days'
	// 		this.currentChart = '7d'
	// 		this.toggleBtn(3)
	// 		this.fetch_echart_API_data(7, 'days')
	// 	}
	// }
	
set60m_EChart() {
    console.log('set60m_EChart triggered');
    if (this.currentEChart != 'minutes') {
        // Dispose existing chart
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
        this.currentEChart = 'minutes';
        this.initializeEChart();  // This will handle everything else
    }
}

set24h_EChart() {
    console.log('set24h_EChart triggered');
    if (this.currentEChart != 'hours') {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
        this.currentEChart = 'hours';
        this.initializeEChart();
    }
}

set7d_EChart() {
    console.log('set7d_EChart triggered');
    if (this.currentEChart != 'days') {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
        this.currentEChart = 'days';
        this.initializeEChart();
    }
}	

// 	set60m_EChart() {
//     console.log('set60m_EChart invoked');
//     if (this.currentEChart != 'minutes') {
//         // Dispose existing chart
//         if (this.chart) {
//             this.chart.dispose();
//             this.chart = null;
//         }

//         this.currentEChart = 'minutes';
//         this.currentChart = '60m';
//         this.toggleBtn(1);
//         this.initializeEChart();
//         // this.fetch_echart_API_data(60, 'minutes');
//     }
// }

// set24h_EChart() {
//     console.log('set24h_EChart invoked');
//     if (this.currentEChart != 'hours') {
//         // Dispose existing chart
//         if (this.chart) {
//             this.chart.dispose();
//             this.chart = null;
//         }

//         this.currentEChart = 'hours';
//         this.currentChart = '24h';
//         this.toggleBtn(2);
//         this.initializeEChart();
//         // this.fetch_echart_API_data(24, 'hours');
//     }
// }

// set7d_EChart() {
//     console.log('set7d_EChart invoked');
//     if (this.currentEChart != 'days') {
//         // Dispose existing chart
//         if (this.chart) {
//             this.chart.dispose();
//             this.chart = null;
//         }

//         this.currentEChart = 'days';
//         this.currentChart = '7d';
//         this.toggleBtn(3);
//         this.initializeEChart();
//         // this.fetch_echart_API_data(7, 'days');
// 		}
// 	}

	print() {
		var divId
		if (this.toggleValue == 0) {
			// chart
			this.printCanvas()
		} else {
			// list
			this.printList()
		}
	}

	printCanvas() {
		let printContents = (<HTMLCanvasElement>document.getElementById('myChartSite')).toDataURL()
		let popupWin
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
		popupWin.document.open()
		popupWin.document.write(`
				<html>
					<head>
						<title>Print Chart</title>
					</head>
					<body onload="window.print(); window.close()">
						<img style='width: 100%;' src='${printContents}'/>
					</body>
				</html>
			`)
		popupWin.document.close()
	}

	printList() {
		this.printMode = true
		setTimeout(() => {
			let printContents, stylesHtml, linksHtml, popupWin
			printContents = document.getElementById('printTable').innerHTML
			popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
						<head>
							<title>Print Report</title>
							${stylesHtml}
							${linksHtml}
						</head>
						<body onload="window.print(); window.close()">
							${printContents}
						</body>
					</html>
				`)
			popupWin.document.close()
			this.printMode = false
		}, 500)
	}

	// loadEChart() {
	// 	this.initializeEChart()

	// 	// Safety check
	// 	if (!this.chart) {
	// 		console.warn('Chart initialization failed')
	// 		return
	// 	}

	// 	const option = {
	// 		tooltip: {
	// 			trigger: 'axis',
	// 			formatter: (params) => {
	// 				let result = moment(params[0].axisValue)
	// 					.utc()
	// 					.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
	// 					.format('MM-DD-YYYY hh:mma') + '<br/>'

	// 				params.forEach(param => {
	// 					if (param.seriesIndex === 0 && param.data) { // Incidents
	// 						result += 'Incidents<br/>'
	// 						result += 'ID: ' + param.data.incident_id + '<br/>'
	// 						if (param.data.triggers) {
	// 							const triggers = param.data.triggers.split(' - ')
	// 							triggers.splice(0, 1)
	// 							result += triggers.join('<br/>') + '<br/>'
	// 						}
	// 					} else {
	// 						// result += param.seriesName + ': ' + param.value + '<br/>'
	// 						const value = param.value !== undefined && param.value !== null
	//                           ? param.value
	//                           : 'No Data';
	// 						result += `${param.seriesName}: ${value}<br/>`
	// 					}
	// 				})
	// 				return result
	// 			}
	// 		},
	// 		// xAxis: {
	// 		// 	type: 'time',
	// 		// 	axisLabel: {
	// 		// 		formatter: (value) => {
	// 		// 			if (this.currentEChart === 'days') {
	// 		// 				return moment(value).format('MM-DD')
	// 		// 			} else if (this.currentEChart === 'hours') {
	// 		// 				return moment(value).format('HH:mm')
	// 		// 			} else {
	// 		// 				return moment(value).format('HH:mm')
	// 		// 			}
	// 		// 		}
	// 		// 	}
	// 		// },
	// 		// yAxis: {
	// 		// 	type: 'value',
	// 		// 	scale: true,
	// 		// 	axisLabel: {
	// 		// 		formatter: '{value}'
	// 		// 	}
	// 		// },
	// 		legend: this.eChartData.legend,  // Add this
	// 		xAxis: this.eChartData.xAxis,    // Use existing config
	// 		yAxis: this.eChartData.yAxis,
	// 		series: this.eChartData.series
	// 	}

	// 	// Update the chart with new options
	// 	if (this.chart) {
	// 		this.chart.setOption(option, {
	// 			notMerge: true,
	// 			lazyUpdate: true
	// 		})
	// 	} else {

	// 	}
	// }
	loadEChart() {
		// this.initializeEChart()

		if (!this.chart) {
			console.warn('Chart initialization failed')
			return
		}

		const option = {
			tooltip: {
				trigger: 'axis',
				formatter: (params) => {
					let result = moment(params[0].axisValue)
						.utc()
						.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
						.format('MM-DD-YYYY hh:mma') + '<br/>'

					params.forEach(param => {
						if (param.seriesIndex === 0 && param.data) { // Incidents
							result += 'Incidents<br/>'
							result += 'ID: ' + param.data.incident_id + '<br/>'
							if (param.data.triggers) {
								const triggers = param.data.triggers.split(' - ')
								triggers.splice(0, 1)
								result += triggers.join('<br/>') + '<br/>'
							}
						} else {
							const value = param.value !== undefined && param.value !== null
								? param.value
								: 'No Data'
							result += `${param.seriesName}: ${value}<br/>`
						}
					})
					return result
				}
			},
			legend: {
				data: this.eChartData.series.map(s => s.name),  // Dynamic legend data
				bottom: 10
			},
			xAxis: {
				type: 'time',
				axisLabel: {
					formatter: (value) => {
						if (this.currentEChart === 'days') {
							return moment(value).format('MM-DD')
						} else if (this.currentEChart === 'hours') {
							return moment(value).format('HH:mm')
						} else {
							return moment(value).format('HH:mm')
						}
					}
				}
			},
			yAxis: {
				type: 'value',
				scale: true,
				axisLabel: {
					formatter: '{value}'
				}
			},
			series: this.eChartData.series
		}

		// Update the chart with complete new options
		if (this.chart) {
			this.chart.setOption(option, {
				notMerge: true,  // Force complete refresh
				lazyUpdate: true
			})
		}
	}

	private updateSeriesNames() {
		// First series is always Incident
		this.eChartData.series[0].name = 'Incident'

		if (this.data.section === 'mbps_out' || this.data.section === 'mbps_in') {
			this.eChartData.series[1].name = 'Mbps Total'
			this.eChartData.series[2].name = 'Mbps RX'
			this.eChartData.series[3].name = 'Mbps TX'

			// Update tooltips for this section
			this.eChartData.series[1].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} mb/s` : 'No Data'
			this.eChartData.series[2].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} mb/s` : 'No Data'
			this.eChartData.series[3].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} mb/s` : 'No Data'

		} else if (this.data.section === 'jitter_out' || this.data.section === 'jitter_in') {
			this.eChartData.series[1].name = 'Jitter (msec) Total'
			this.eChartData.series[2].name = 'Jitter (msec) RX'
			this.eChartData.series[3].name = 'Jitter (msec) TX'

			// Update tooltips for this section
			this.eChartData.series[1].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} ms` : 'No Data'
			this.eChartData.series[2].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} ms` : 'No Data'
			this.eChartData.series[3].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} ms` : 'No Data'

		} else if (this.data.section === 'packetloss_out' || this.data.section === 'packetloss_in') {
			this.eChartData.series[1].name = 'Packet Loss(%) Total'
			this.eChartData.series[2].name = 'Packet Loss(%) RX'
			this.eChartData.series[3].name = 'Packet Loss(%) TX'

			// Update tooltips for this section
			this.eChartData.series[1].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value}%` : 'No Data'
			this.eChartData.series[2].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value}%` : 'No Data'
			this.eChartData.series[3].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value}%` : 'No Data'

		} else if (this.data.section === 'latency_out' || this.data.section === 'latency_in') {
			this.eChartData.series[1].name = 'Latency Total'
			this.eChartData.series[2].name = 'Latency RX'
			this.eChartData.series[3].name = 'Latency TX'

			// Update tooltips for this section
			this.eChartData.series[1].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} ms` : 'No Data'
			this.eChartData.series[2].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} ms` : 'No Data'
			this.eChartData.series[3].tooltip.valueFormatter = (value) =>
				value !== undefined && value !== null ? `${value} ms` : 'No Data'
		}

		// Keep incident tooltip formatter consistent
		this.eChartData.series[0].tooltip.valueFormatter = (value) =>
			value !== undefined && value !== null ? `${value} incidents` : 'No Data'
	}
}
