import { Component, Input, OnInit } from '@angular/core'

import { environment } from '../../../../environments/environment'

// import { NgbDate,NgbDateStruct, NgbCalendar, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reports-title-panel',
  templateUrl: './reports-title-panel.component.html',
  styleUrls: ['./reports-title-panel.component.scss']
})
export class ReportsTitlePanelComponent implements OnInit {
  @Input() fromdate: any;
  @Input() todate: any;
  @Input() siteTitle: any;
  @Input() siteSubTitle: any;
  @Input() siteName: any;
  @Input() siteType: any;
  @Input() deviceName: any;
  @Input() isDevice:boolean = false;


  months:any = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July ',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];
  // logo = "../../../assets/img/logo/" + environment.config.logo;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;

  constructor(
    // private pickerI18n: NgbDatepickerI18n
  ) { }

  ngOnInit() {
    // console.log("%c NG FROMDAT, month: " + this.months[this.fromdate.month - 1] + ", day" + this.fromdate.day, 'background:purple;color:white;');
  }

}
