import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { RemoteSessionComponent } from "../panels/remote/remote-session/remote-session.component";
import { AuthService } from "../services/auth.service";
import { RemoteAccessService } from "../services/remote-access.service";
enum RemotePort {
  HTTP = 80,
  HTTPS = 443,
  SSH = 22,
  RDP = 3389
}
type RemotePortType = {
  name: string;
  port: number;
};
@Component({
  selector: "app-ngrok-protocol",
  templateUrl: "./ngrok-protocol.component.html",
  styleUrls: ["./ngrok-protocol.component.scss"],
})
export class NgrokProtocolComponent implements OnInit {
  RemotePortDetails = {
    [RemotePort.HTTP]: { name: "http", port: RemotePort.HTTP },
    [RemotePort.HTTPS]: { name: "https", port: RemotePort.HTTPS },
    [RemotePort.SSH]: { name: "ssh", port: RemotePort.SSH },
    [RemotePort.RDP]: { name: "rdp", port: RemotePort.RDP },
  } as const;
  deviceId: number;
  isLoading = false;
  isShowConnectingText = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  enableSsh = false;
  enableRdp = false;
  enableHttp = false;
  enableHttps = false;
  iotEnabled = false;
  ipV4: any;
  snapshotMessage: string = "";
  showDownloading: boolean = false;
  setTimer: any;
  actionCount: number = 0;
  tunnel_id: string = "";
  isClose:boolean = false;
  tunnel_url: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NgrokProtocolComponent>,
    public dialog: MatDialog,
    private Auth: AuthService,
    private _remoteService: RemoteAccessService
  ) {}
  checkPort(ports){
    if(ports?.length > 0){
      const portMap = {
        [RemotePort.HTTP]: 'enableHttp',
        [RemotePort.HTTPS]: 'enableHttps',
        [RemotePort.RDP]: 'enableRdp',
        [RemotePort.SSH]: 'enableSsh',
      };
    
      ports.forEach((x) => {
        const key = portMap[x.portid];
        if (key) {
          this[key] = true;
        }
      });
    }
  }
  ngOnInit(): void {
    if(['zoom','teams'].includes(this.data?.service_type)){
      this.enableRdp = true;
    }
    else if(this.data?.other_data){
      const ports = typeof this.data.other_data === 'string' ? JSON.parse(this.data.other_data) :this.data.other_data;
      this.checkPort(ports);
    }
    if(this.data&&this.data?.tunnel_info){
      const tunnel = typeof this.data.tunnel_info === 'string' ? JSON.parse(this.data.tunnel_info) : this.data.tunnel_info;
      
      this.tunnel_id = tunnel[0].tunnel_id;
      this.isClose = false;
      this.tunnel_url = tunnel[0].public_url;
      this.stopTunnel();
    }
  }
  onHttps = () => {
    if (this.enableHttps) {
      this.isLoading = true;
      this.getDomain(this.RemotePortDetails[RemotePort.HTTPS]);
    }
  };

  onHttp = () => {
    if (this.enableHttp) {
      this.isLoading = true;
      this.getDomain(this.RemotePortDetails[RemotePort.HTTP]);
    }
  };

  onSsh = () => {
    if (this.enableSsh) {
      this.isLoading = true;
      this.getDomain(this.RemotePortDetails[RemotePort.SSH]);
    }
  };

  onRdp = () => {
    if (this.enableRdp) {
      this.isLoading = true;
      this.getDomain(this.RemotePortDetails[RemotePort.RDP]);
    }
  };
  onCancel = () => {
    this.dialogRef.close(true);
  };
  get deviceName(){
    const rtype = this.data.rdevice_type;
    const type = (rtype === 'VIDEO')?'v':(rtype === 'AUDIO')?'a':(rtype === 'IOT')?'i': (rtype ==='zoom')?'z':'t';
    return 'ruId'+this.Auth.userId+type+this.data.rdevice_id+'c'+this.data.collector_id;
  }
  stopTunnel(){
    const action = "stop devices tunnel";
    const body = {
      collector_id: this.data?.collector_id,
      label: action,
      action: action,
      params: {
        "url": this.tunnel_url
    },
    };
    this._remoteService.collectorAction(body)
    .subscribe((data) => {
      if(data.success){
        this.checkStatus2(data?.result?.action_id)
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  getDomain(remote:RemotePortType){
    const name = [RemotePort.HTTP,RemotePort.HTTPS].includes(remote.port)?'http':'tcp';
    if(name === 'tcp'){
      this.startTunnel(remote,{});
    }
    else{
      this._remoteService.getDomain()
      .subscribe((resp) => {
        if(resp.success){
        this.startTunnel(remote,resp.data)
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
      });
    }
  }
  startTunnel(remote:RemotePortType,info) {
    const action = "start devices tunnel";
    const name = [RemotePort.HTTP,RemotePort.HTTPS].includes(remote.port)?'http':'tcp';
    info.proto = remote.name;
    // this.data.ipv4 = '91.108.120.99';
    let ip = this.data.ipv4 || this.data.iot_ip || this.data.ip
    // if(name === 'tcp') ip + `:${remote.port}`
    let params = {
      ip: `http://${ip}`,
      // name: this.deviceName,
      port: remote.port.toString(),
      proto: name,
      hostheader: ip,
      domain: info?.domain
    }
    if(name === 'tcp') {
      params.ip = ip;
      delete params.domain;
      delete params.hostheader;
    }
    const body = {
      collector_id: this.data?.collector_id,
      label: action,
      action: action,
      params: {...params},
    };
    this._remoteService.collectorAction(body)
    .subscribe((data) => {
      if(data.success){
        this.checkStatus(data?.result?.action_id,info)
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  checkStatus2 = (action_id) => {
    console.log("action_id: ", action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log("postDeviceActionId: ", actionData);
      console.log("actionCount: ", this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
         this.resetFields("Something went wrong. Please try again.");

        return;
      }
      if (
        actionData.response[0].status !== "COMPLETE" &&
        actionData.response[0].status !== "FAILED"
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus2(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === "FAILED") {
         this.resetFields("Something went wrong. Please try again.");
        return;
      } else if (actionData.response[0].status === "COMPLETE") {
        const [newData] = actionData.response;
        this.updateTunnel();
        console.log('Tunnel closed')
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  };
  checkStatus = (action_id,info) => {
    console.log("action_id: ", action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log("postDeviceActionId: ", actionData);
      console.log("actionCount: ", this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
         this.resetFields("Something went wrong. Please try again.");

        return;
      }
      if (
        actionData.response[0].status !== "COMPLETE" &&
        actionData.response[0].status !== "FAILED"
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id,info);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === "FAILED") {
         this.resetFields("Something went wrong. Please try again.");
        return;
      } else if (actionData.response[0].status === "COMPLETE") {
        const [newData] = actionData.response;
        const ngrokData = this.getTunnelInfo(newData?.results);
        if(!ngrokData?.ngres?.tunnel?.url){
          this.resetFields("Something went wrong while start the tunnel");
          return;
        }
        const {
          domain,
          cname_target,
          domain_id
        } = info;
        const proto = this.RemotePortDetails[+newData.params.port].name;
        ngrokData.tunnel_name = this.deviceName;
        ngrokData.proto = proto;
        const ip = newData?.params?.ip_restriction_allow_cidrs?.[0]?.split('/')[0] || 'dont allow to tcp';
        this.tunnel_url = ngrokData?.ngres?.tunnel?.url;
        this.addingTunnel({
          device_id : this.data?.rdevice_id,
          device_type: this.data?.rdevice_type,
          collector_id : this.data?.collector_id,
          tunnel_name : this.deviceName,
          tunnel_id : ngrokData?.ngres?.tunnel?.id,
          tunnel_url : ngrokData?.ngres?.tunnel?.url,
          public_ip : ip,
          proto: proto,
          cname_target : cname_target || 'abcd',
          domain_id : domain_id || 'abcd'
        },ngrokData);
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  };
  getTunnelInfo(tunnel) {
    try{
      const data = typeof tunnel === 'string' ? JSON.parse(tunnel) : tunnel;
      return data;
    }
    catch(e){
      return null;
    }
  }
  resetFields = (message) => {
    this.actionCount = 0;
    this.showDownloading = false;
    this.snapshotMessage = message;
  };
  addingTunnel(body,ngrokData){
    this._remoteService.addingTunnel(body)
    .subscribe((resp)=>{
      if(resp.success){
        this.tunnel_id = resp.data.id;
        ngrokData.tunnel_id = this.tunnel_id;
        this.onCancel();
        const dialogRef =this.dialog.open(RemoteSessionComponent, {
          data : {
            ...ngrokData,
          },
          // width: "100%"
        });
        dialogRef.afterClosed().subscribe(result => {
          this.isClose = true;
          this.stopTunnel();
        });
      }
      else{
      }
    });
    
  }
  updateTunnel(){
    this._remoteService.updateTunnel({tunnel_id:this.tunnel_id,token:localStorage.getItem('guac_token')})
    .subscribe((resp)=>{
      if(resp.success){
        if(this.isClose) this.onCancel();
      }
      else{
      }
    });
    
  }
}
