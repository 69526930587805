import { Component, OnInit, OnDestroy, Input ,SimpleChanges} from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { finalize } from 'rxjs/operators';

export interface AgentListInterface {
  agent_name: string;
  ip: string;
  mac: string;
  device_count: number;
  status: string;
  is_monitored: number;
}

@Component({
  selector: 'app-iot-agent-list',
  templateUrl: './iot-agent-list.component.html',
  styleUrls: ['./iot-agent-list.component.scss'],
})
export class IotAgentListComponent implements OnInit, OnDestroy {
  @Input() mspDomotzEnabled: any;
  @Input() companyDetails: any;
  message:string = '';
  iotAgentList: any = [];
  /* pagination variables */
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  pageSelected: any = 0;
  searchKey: any = 'null';
  filterListner: Subscription;
  refreshData: Boolean;
  shouldShowLoaderBtn: Boolean = false;
  displayedColumns: any[] = ['agent_name', 'space_name', 'ip', 'mac', 'device_count', 'status', 'is_monitored'];
  dataSource: MatTableDataSource<AgentListInterface>;
  loader = environment.config.dynamicImage.loader;
  isGreyFilledOut:boolean = false;
  searchTimeout: any;

  constructor(private GetIot: GetIotService, private refreshApiService: RefreshApiService) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
  }
  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      this.setData();
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companyDetails && this.companyDetails) {
     if(changes.mspDomotzEnabled) {
        this.setData();
     }
    }
  }

  setData = () => {
    const { company_parent } = this.companyDetails;
    if(this.mspDomotzEnabled && company_parent !=null) {
      this.getAllDomotzAgentsSubVendor();
    } else {
      this.getIotAgentList();
    }
  }

  getAllDomotzAgentsSubVendor = () => {
    this.GetIot.getAllDomotzAgentsSubVendor(
      this.searchKey,
      this.totalPageLimit,
      this.paginateOjb.startIndex
    ).subscribe((data) => {
      // // console.log('getAllDomotzAgentsSubVendor: ', data)
      this.iotAgentList = data;
      this.dataSource = new MatTableDataSource(this.iotAgentList);
      this.shouldShowLoaderBtn = false;
    });
  }

  getIotAgentList = () => {
    this.shouldShowLoaderBtn = true;
    this.GetIot.getAllDomotzAgents(
      this.searchKey,
      this.totalPageLimit,
      this.paginateOjb.startIndex,
      this.mspDomotzEnabled
    ).subscribe((data) => {
      // // console.log('getIotAgentList: ', data)
      this.iotAgentList = data;
      this.dataSource = new MatTableDataSource(this.iotAgentList);
      this.shouldShowLoaderBtn = false;
    });
  };

  refreshIotAgentList = () => {
    this.GetIot.getAllDomotzAgents(
      this.searchKey,
      this.totalPageLimit,
      this.paginateOjb.startIndex,
      this.mspDomotzEnabled
    ).subscribe((data) => {
      this.iotAgentList = data;
      this.dataSource = new MatTableDataSource(this.iotAgentList);
    });
  };

  getStatusColor = (status) => {
    let color = 'iot-status-grey';
    switch (status) {
      case 'ONLINE':
        color = 'iot-status-green';
        break;
      default:
        color = 'iot-status-red';
        break;
    }
    return color;
  };

  setIsMonitored = (row) => {
    this.isGreyFilledOut = true;
    const flag = this.setFlag(row.is_monitored);
    this.GetIot.setIotAgentIsMonitored(row.agent_id, flag)
    .pipe(finalize(()=>this.isGreyFilledOut = false))
    .subscribe(
      (data) => { 
        if(typeof data == 'string') {
          this.message = data;
          this.refreshIotAgentList();
          setTimeout(()=>{
            this.message = '';
          },2000)
        }
      },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  searchData = (key) => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.searchKey = key?.trim() ? encodeURIComponent(key) : 'null';
      this.refreshIotAgentList();
    }, 1000);
  };
}
