import { InputValidationService } from 'src/app/services/input-validation.service'

import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { JwtHelperService } from '@auth0/angular-jwt'

import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  constructor
    (public http: HttpClient,
    private Auth: AuthService,
    private fb: FormBuilder,
    private inputValidationService: InputValidationService) { }

  control: FormControl;
  submitted = false;
  model: any = {};
  fc: any;
  get f() {
    return this.editProfileForm.controls;
  }

  pass1: any = '';
  pass2: any = '';
  matched: any = false;
  companyProfileData: any = [];
  companyname: any = '';
  address1: any = '';
  address2: any = '';
  city: any = '';
  state: any = '';
  state2: any = '';
  zip: any = '';
  country: any;
  countryName: any;
  companyphone: any = '';
  compphonecode: any = '';
  successMsg: string;
  errorMsg: string

  ngOnInit() {
    this.getCountryData();
    this.getCompanyData();
  }
  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(''), ms));
  }

  allCountryListData: any = [];
  getCountryData() {
    this.Auth.getAllCountryList().subscribe((data) => {
      this.allCountryListData = data['response'];
      if (this.country > 0) {
        this.countryName = this.allCountryListData[this.country - 1].name;
        this.getStatesData(this.country);
      } else {
        this.delay(3000).then((any) => {
          this.countryName = this.allCountryListData[this.country - 1].name;
          this.getStatesData(this.country);
        });
      }
    });
  }

  setCountry(n) {
    this.getStatesData(n);
    this.compphonecode = this.allCountryListData[n - 1].phonecode;
    this.editProfileForm.patchValue({
      compphonecode: this.compphonecode,
      zip: '',
    });
  }

  allStatesListData: any = [];
  getStatesData(n) {
    this.Auth.getAllStatesList(n).subscribe((data) => {
      this.allStatesListData = data['response'];
    });
  }

  editProfileForm = this.fb.group({
    companyname: ['', [Validators.required, Validators.maxLength(30)]],
    address1: ['', [Validators.required, Validators.maxLength(50)]],
    address2: [''],
    city: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
    state: ['', [Validators.required]],
    zip: ['', [Validators.required , Validators.maxLength(11) , Validators.minLength(3), this.inputValidationService.postalCodeValidator]],
    country: ['', [Validators.required]],
    compphonecode: [''],
    companyphone: ['', [Validators.required, Validators.maxLength(25), this.inputValidationService.customPhoneValidator]],
  });

  getCompanyData() {
    this.Auth.getCompanyInfo().subscribe((data) => {
      this.companyProfileData = data['response'];

      let arr = [];
      for (let d in this.companyProfileData) {
        arr.push(this.companyProfileData[d]);
      }

      this.companyname = arr[0].company_name;
      this.address1 = arr[0].address1;
      this.address2 = arr[0].address2;
      this.city = arr[0].city;
      this.state = arr[0].state;
      this.zip = arr[0].zip;
      this.country = arr[0].country;
      let tmpDbCphn = arr[0].phone;
      let spCPhnArr = tmpDbCphn.split('$$');
      this.compphonecode = spCPhnArr[1];
      this.companyphone = spCPhnArr[2];

      this.editProfileForm.patchValue({
        companyname: arr[0].company_name,
        address1: arr[0].address1,
        address2: arr[0].address2,
        city: arr[0].city,
        state: arr[0].state,
        zip: arr[0].zip,
        country: arr[0].country,
        compphonecode: this.compphonecode,
        companyphone: this.companyphone,
      });
    });
  }

  onSubmit() {
    this.clearFeedback()
    this.submitted = true;
    this.editProfileForm.updateValueAndValidity;
    if (this.editProfileForm.invalid) {
      return;
    } else {
      this.registerCompanyInfo();
    }
  }

  registerCompanyInfo() {
    let updCTmpPhn = (<HTMLInputElement>document.getElementById('companyphone')).value;
    let updCTmpPhncode = (<HTMLInputElement>document.getElementById('compphonecode')).value;
    let newCUpdDbPhn = '$$' + updCTmpPhncode + '$$' + updCTmpPhn;

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    const profileData = {
      data: {
        company_id: companyId,
        company_name: (<HTMLInputElement>document.getElementById('companyname')).value,
        country: (<HTMLInputElement>document.getElementById('country')).value,
        phone: newCUpdDbPhn,
        address1: (<HTMLInputElement>document.getElementById('address1')).value,
        address2: (<HTMLInputElement>document.getElementById('address2')).value,
        city: (<HTMLInputElement>document.getElementById('city')).value,
        state: (<HTMLInputElement>document.getElementById('state')).value,
        zip: (<HTMLInputElement>document.getElementById('zip')).value,
      },
    };

    this.Auth.updateProfile(profileData).subscribe((data) => {
      if (data.response[0]?.status === 'OK') 
        this.successMsg =  data.response[0].message;
      else if (data.response[0]?.status === 'Error')
        this.errorMsg = data.response[0].message;
    });
  }

  clearFeedback(): void {
    this.successMsg = null;
    this.errorMsg = null;
  }
}
