

import { Component, OnInit, Input ,OnChanges, SimpleChanges} from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { LinkDeviceSpaceDialogComponent } from '../dialogs/link-device-space-dialog/link-device-space-dialog.component'
import { MatDialog } from '@angular/material/dialog'

export interface DeviceListInterface {
  mac_address: string;
  iot_name: string;
  iot_ip: string;
  device_id: number;
  collector_id: number;
  quadrant_group: string;
  make: string;
  is_monitored: string;
}

@Component({
  selector: 'app-iot-lite-device-list',
  templateUrl: './iot-lite-device-list.component.html',
  styleUrls: ['./iot-lite-device-list.component.scss']
})
export class IotLiteDeviceListComponent implements OnInit, OnChanges {
  iotDeviceList: any = [];
  @Input() displayOnlyMonitored: any;
  @Input() iotLiteEnabled: any;

  /* pagination variables */
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalIotLiteDeviceCount = 0;	
  totalCount: number;
  pageSelected: any = 0;
  searchKey: any = 'null';
  shouldShowLoaderBtn: Boolean = false;
  filterListner: Subscription;
  refreshData: Boolean;

  monitoredColums: any[] = [
    'mac_address',
    'spaces',
    'iot_name',
    'iot_ip',
    'collector_name',
    'quadrant_group',
    'make',
    'status',
    'is_monitored',
  ];

  nonMonitoredColums: any[] = [
    'mac_address',
    'spaces',
    'iot_name',
    'iot_ip',
    'collector_name',
    'quadrant_group',
    'make',
    'status',
    'is_monitored',
    'link_to_space'
  ];

  displayedColumns: any[] = [];

  dataSource: MatTableDataSource<DeviceListInterface>;
  loader = environment.config.dynamicImage.loader;

  constructor(private getIot: GetIotService, 
              private refreshApiService: RefreshApiService,
              private dialog: MatDialog

  ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
  }
  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      // this.refreshtIotDeviceList();
    }
  }

  ngOnInit() {
    // this.getIotLiteDeviceList();
  }

  ngOnChanges(changes: SimpleChanges) {
    let trigger = false
    if(changes.iotLiteEnabled && this.iotLiteEnabled) {
      this.checkIfIotDisabled();
      trigger = true
    }
    if(changes.displayOnlyMonitored){
      const columns = this.displayOnlyMonitored ? this.monitoredColums : this.nonMonitoredColums;
      this.displayedColumns = [...columns];
      if(!trigger) this.checkIfIotDisabled();
    }
  }

  checkIfIotDisabled = () => {
    this.reset();
    if(this.iotLiteEnabled) {
      this.getIotLiteDeviceList();
    } 
  }
  reset(){
    this.iotDeviceList = [];
    this.searchKey = '';
    this.dataSource = new MatTableDataSource([]);

  }

  getIotLiteDeviceList = () => {
    this.shouldShowLoaderBtn = true;
    this.getIot
      .getIotLiteDeviceList(
        'all',
        1
      )
      .subscribe((data) => {
        this.shouldShowLoaderBtn = false;
        const res = data.response
        // console.log(res,'res')
        this.iotDeviceList = res;
        if(this.displayOnlyMonitored) this.getAllMonitoredDevices();
        this.totalIotLiteDeviceCount =this.iotDeviceList .length;	
        this.dataSource = new MatTableDataSource(this.iotDeviceList);
        this.dataSource.filterPredicate = function (record: any, filter) {
          return (record.iot_name || '' ).toLocaleLowerCase().includes( (filter || '').toLocaleLowerCase());
        }
      },
       (err) => {
        this.shouldShowLoaderBtn = false;
        }
      );
  };

 
  getStatusColor = ({ status }) => {
      if (status === 1) {
        return 'iot-device-list__status-color--green';
      } else {
        return 'iot-device-list__status-color--red';
      }
  };

  setIsMonitored = (_id, _flag) => {
    const flag = this.setFlag(_flag);
    this.getIot.iotLiteSetMonitor(_id, flag).subscribe(
      (data) => {
        // console.log('setIsMonitored: ', data)
       },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  searchData = () => {
    this.dataSource.filter = this.searchKey || ''.trim().toLowerCase();  
  };
  linkUnlinkSpace(row){
    const dialog = this.dialog.open(LinkDeviceSpaceDialogComponent,{
      panelClass: 'custom-dialog-container',
      data:{
        ...row,
        isDomotz:false
      }
    })
    dialog.componentInstance.refresh.subscribe((x)=>{
      const device_id = row.iot_id;
      const index = this.iotDeviceList.findIndex((x)=>(x.iot_id) == device_id);
      this.iotDeviceList[index].spaces = x;
    })
  }
  getSpaceName(spaces) {
    if (!spaces) return "--";
    try {
      const s = JSON.parse(spaces);
      return Array.isArray(s) && s.length ? s.map(x => x.space_name) : "--";
    } catch {
      return "--";
    }
  }
  getAllMonitoredDevices(){
    if(this.iotDeviceList?.length){
      this.iotDeviceList = this.iotDeviceList.filter((x)=>x.is_monitored === 1)
    } 
  }
  

}
