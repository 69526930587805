import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEye, faShield, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { finalize } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { GetCollectorService } from "src/app/services/get-collector.service";
import { RemoteAccessService } from "src/app/services/remote-access.service";
type CollectorAction = {
  collector_id: number;
  label: string;
  action: string;
  params?: any;
};
@Component({
  selector: "app-remote-access",
  templateUrl: "./remote-access.component.html",
  styleUrls: ["./remote-access.component.scss"],
})
export class RemoteAccessComponent implements OnInit,OnChanges {
  isAccess: boolean = false;
  snapshotMessage: string = "";
  showDownloading: boolean = false;
  setTimer: any;
  actionCount: number = 0;
  credential_id:string = '';
  faShield = faShield as IconDefinition;
  faShieldHalved = faShieldHalved as IconDefinition;
  faEye = faEye as IconDefinition;
  showPopup = false;
  logList = [];
  @Input("data") data: any;
  constructor(
    private _remoteService: RemoteAccessService,
    private Auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getTunnelLog();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data && this.data){
      this.isAccess = !!this.data?.is_remote_access
    }
  }
  remoteAccess(){
    this.showDownloading = true;
    const body = {
      collector_id: this.data?.collector_id,
      is_remote_access: this.isAccess
    }
    this._remoteService.allowAccess(body)
    .subscribe((data) => {
      if(data.success){
        this.showDownloading = false;
        this.snapshotMessage = data.message;
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  resetFields = (message) => {
    this.actionCount = 0;
    this.showDownloading = false;
    this.isAccess = !this.isAccess;
    this.snapshotMessage = message;
    this.credential_id = '';
  };
  togglePopup() {
    this.showPopup = !this.showPopup;
  }
  getTunnelLog(){
    this._remoteService.getTunnelLog(this.data.collector_id)
    .subscribe((resp) => {
      if(resp.success){
        this.logList = [...resp.data]
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
}
