<div class="device-health-history-container">
  <div *ngIf="isShowTableData" id="incident-history-list-container">
    <table class="table table-fixed device-health-history-table">
      <thead>
        <tr>
          <th class="device-health-history-thead actioned-thead">Actioned</th>
          <th class="device-health-history-thead">Incident ID</th>
          <th class="device-health-history-thead">Start Time</th>
          <th class="device-health-history-thead">End Time</th>
          <th class="device-health-history-thead-events">Details</th>
        </tr>
      </thead>
      <tbody *ngFor="let incident of incidentsData; index as idx">
        <tr scope="row">
          <td class="text-center">
						<input type="checkbox" [checked]="incident?.actioned === 1" disabled />
						<i class="fa fa-comments-o m-1" (click)="onShowMessages(incident?.incident_id, idx)"></i>
					</td>
          <td>{{ incident.incident_id }}</td>
          <td>{{ incident.start_time | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
          <td>{{ incident.end_time | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
          <!-- <td class="device-prelined">{{ incident.events }}</td> -->
          <td class="details-container">
            <div class="details-container__trigger" *ngFor="let data of incident.events">
              <label>Trigger:</label>{{ data.trigger }}
              <br />
              <label>Start:</label>{{ data?.stamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
              <span *ngIf="data?.end">
                <br />
                <label>End:</label>{{ data?.end | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
              </span>
              <br />
              <label>Failed Polls:</label>{{ data?.polls }}
              <br />
              <label>Last Failed Poll:</label>{{ data?.last_poll | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="shouldShowLoaderBtn" class="loader">
      <img src="../../../assets/img/vs_img/btn_loader.gif" alt="" />
    </div>
  </div>

  <div *ngIf="!isShowTableData" class="device-health-history-nodata">
    <h1>No Data Available</h1>
  </div>
  <div class="print-btn-container" *ngIf="isShowTableData">
    <div class="printer-btn">
      <i class="fa fa-print fa-2x menu-icon" (click)="printIncidentsToPdf()"></i>
    </div>
  </div>
</div>
