import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-pexip-popup',
	templateUrl: './pexip-popup.component.html',
	styleUrls: ['./pexip-popup.component.scss'],
})
export class PexipPopupComponent implements OnInit {
	searchKey: string
	tableSelected: any = 'service'

	constructor() {}

	ngOnInit(): void {}

	radioChange(event) {
		// this.zoomDynamicProps = event;
		// this.reset();
		// if(event === 'service') this.getZoomInfo();
		// else if(event === 'rooms') this.fetchApiCloudAllRooms();
		// else if(event === 'users') this.fetchApiCloudUsers();
	}

	search() {
		// if (this.timer) clearTimeout(this.timer)
		// this.timer = setTimeout(() => {
		// 	this.resetPagination()
		// 	if (this.tableSelected === 'rooms') this.fetchApiCloudAllRooms()
		// 	else if (this.tableSelected === 'users') this.fetchApiCloudUsers()
		// }, 1500)
	}
}
