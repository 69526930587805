import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { GetAudioService } from 'src/app/services/get-audio.service'
import { GetVideoService } from 'src/app/services/get-video.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-video-audio-ssl-dialog',
	templateUrl: './video-audio-ssl-dialog.component.html',
	styleUrls: ['./video-audio-ssl-dialog.component.scss'],
})
export class VideoAudioSSLDialogComponent implements OnInit {
	btnLoader: string = environment.config.dynamicImage.loader
	isLoading: boolean = false
	deviceId: number
	deviceType: 'audio' | 'video'
	sslCertificate: any

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<VideoAudioSSLDialogComponent>,
		private getVideoService: GetVideoService,
		private getAudioService: GetAudioService
	) {
		if (data) {
			this.deviceId = data.deviceId
			this.deviceType = data.deviceType
		}
	}

	ngOnInit(): void {
		this.isLoading = true
		const selectedService = this.deviceType === 'video' ? this.getVideoService : this.getAudioService
		selectedService.getSSLCertificate(this.deviceId).subscribe(
			async data => {
				this.isLoading = false
				const publicKeyHash = await this.createHash(data?.response?.sslCertificatePublicKey)
				const certificateHash = await this.createHash(data?.response?.sslCertificateText)
				this.sslCertificate = {
					publicKeyHash,
					certificateHash,
				}
				// this.sslCertificate = JSON.parse(JSON.stringify(data.response)
				// 	.replace(/\\n/g, ' ')
				// 	.replace(/\s+/g, ' '))
				// console.log(this.sslCertificate)
			},
			err => {
				this.isLoading = false
			}
		)
	}

	// Function to create SHA-256 hash
	async createHash(text) {
		// Encode the text as a Uint8Array
		const msgUint8 = new TextEncoder().encode(text)

		// Hash the text using SHA-256
		const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)

		// Convert the hash buffer to byte array
		const hashArray = Array.from(new Uint8Array(hashBuffer))

		// Convert byte array to hex string
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')

		return hashHex
	}

	closeDialog = () => {
		this.isLoading = false
		this.dialogRef.close(true)
	}
}
