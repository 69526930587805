import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { RemoteSessionComponent } from "../panels/remote/remote-session/remote-session.component";
import { AuthService } from "../services/auth.service";
import { RemoteAccessService } from "../services/remote-access.service";
enum RemotePort {
  HTTP = 80,
  HTTPS = 443,
  SSH = 22,
  RDP = 3389
}
type RemotePortType = {
  name: string;
  port: number;
};
@Component({
  selector: "app-ngrok-protocol",
  templateUrl: "./ngrok-protocol.component.html",
  styleUrls: ["./ngrok-protocol.component.scss"],
})
export class NgrokProtocolComponent implements OnInit {
  RemotePortDetails = {
    [RemotePort.HTTP]: { name: "http", port: RemotePort.HTTP },
    [RemotePort.HTTPS]: { name: "https", port: RemotePort.HTTPS },
    [RemotePort.SSH]: { name: "ssh", port: RemotePort.SSH },
    [RemotePort.RDP]: { name: "rdp", port: RemotePort.RDP },
  } as const;
  deviceId: number;
  isLoading = false;
  isShowConnectingText = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  enableSsh = true;
  enableRdp = true;
  enableHttp = true;
  enableHttps = true;
  iotEnabled = false;
  ipV4: any;
  snapshotMessage: string = "";
  showDownloading: boolean = false;
  setTimer: any;
  actionCount: number = 0;
  tunnel_id: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NgrokProtocolComponent>,
    public dialog: MatDialog,
    private Auth: AuthService,
    private _remoteService: RemoteAccessService
  ) {}

  ngOnInit(): void {
    if(this.data&&this.data?.tunnel_info){
      const tunnel = typeof this.data.tunnel_info === 'string' ? JSON.parse(this.data.tunnel_info) : this.data.tunnel_info;
      this.tunnel_id = tunnel.tunnel_id;
      this.stopTunnel();
    }
  }
  onHttps = () => {
    if (this.enableHttps) {
      this.isLoading = true;
      this.startTunnel(this.RemotePortDetails[RemotePort.HTTPS]);
    }
  };

  onHttp = () => {
    if (this.enableHttp) {
      this.isLoading = true;
      this.startTunnel(this.RemotePortDetails[RemotePort.HTTP]);
    }
  };

  onSsh = () => {
    if (this.enableSsh) {
      this.isLoading = true;
      this.startTunnel(this.RemotePortDetails[RemotePort.SSH]);
    }
  };

  onRdp = () => {
    if (this.enableRdp) {
      this.isLoading = true;
      this.startTunnel(this.RemotePortDetails[RemotePort.RDP]);
    }
  };
  onCancel = () => {
    this.dialogRef.close(true);
  };
  get deviceName(){
    return 'ruId'+this.Auth.userId+'v'+this.data.video_device_id+'c'+this.data.collector_id;
  }
  stopTunnel(){
    const action = "stop devices tunnel";
    const body = {
      collector_id: this.data?.collector_id,
      label: action,
      action: action,
      params: {
        "name": this.deviceName
    },
    };
    this._remoteService.collectorAction(body)
    .subscribe((data) => {
      if(data.success){
        this.checkStatus2(data?.result?.action_id)
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  startTunnel(remote:RemotePortType) {
    const action = "start devices tunnel";
    const body = {
      collector_id: this.data?.collector_id,
      label: action,
      action: action,
      params: {
        "ip": this.data.ipv4,
        "name": this.deviceName,
        "port": remote.port,
        "proto": remote.name
    },
    };
    this._remoteService.collectorAction(body)
    .subscribe((data) => {
      if(data.success){
        this.checkStatus(data?.result?.action_id)
      }
      else{
        this.resetFields("Something went wrong. Please try again.");
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  }
  checkStatus2 = (action_id) => {
    console.log("action_id: ", action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log("postDeviceActionId: ", actionData);
      console.log("actionCount: ", this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
         this.resetFields("Something went wrong. Please try again.");

        return;
      }
      if (
        actionData.response[0].status !== "COMPLETE" &&
        actionData.response[0].status !== "FAILED"
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === "FAILED") {
         this.resetFields("Something went wrong. Please try again.");
        return;
      } else if (actionData.response[0].status === "COMPLETE") {
        const [newData] = actionData.response;
        console.log('Tunnel closed')
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  };
  checkStatus = (action_id) => {
    console.log("action_id: ", action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log("postDeviceActionId: ", actionData);
      console.log("actionCount: ", this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
         this.resetFields("Something went wrong. Please try again.");

        return;
      }
      if (
        actionData.response[0].status !== "COMPLETE" &&
        actionData.response[0].status !== "FAILED"
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === "FAILED") {
         this.resetFields("Something went wrong. Please try again.");
        return;
      } else if (actionData.response[0].status === "COMPLETE") {
        const [newData] = actionData.response;
        const ngrokData = this.getTunnelInfo(newData?.results);
        if(!ngrokData?.ngres?.public_url){
          this.resetFields("Something went wrong while start the tunnel");
          return;
        }
        this.addingTunnel({
          device_id : this.data?.video_device_id,
          device_type: 'VIDEO',
          collector_id : this.data?.collector_id,
          tunnel_name : this.deviceName,
          tunnel_url : ngrokData?.ngres?.public_url,
          public_ip : newData.params.public_ip
        },ngrokData);
      }
    },(err)=>{
      this.resetFields("Something went wrong. Please try again.");
    });
  };
  getTunnelInfo(tunnel) {
    try{
      const data = typeof tunnel === 'string' ? JSON.parse(tunnel) : tunnel;
      return data;
    }
    catch(e){
      return null;
    }
  }
  resetFields = (message) => {
    this.actionCount = 0;
    this.showDownloading = false;
    this.snapshotMessage = message;
  };
  addingTunnel(body,ngrokData){
    this._remoteService.addingTunnel(body)
    .subscribe((resp)=>{
      if(resp.success){
        this.tunnel_id = resp.data.id;
        this.onCancel();
        const dialogRef =this.dialog.open(RemoteSessionComponent, {
          data : {
            ...ngrokData
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.stopTunnel();
        });
      }
      else{
      }
    });
    
  }
  updateTunnel(){
    this._remoteService.updateTunnel({tunnel_id:this.tunnel_id})
    .subscribe((resp)=>{
      if(resp.success){
        this.onCancel();
      }
      else{
      }
    });
    
  }
}
